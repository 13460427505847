import { BackgroundJobsConstants, ProductsConstants } from '../constants';
import { ApiError, getErrorMessage } from '../helpers';
import { ProductService, FileManagerService } from '../services';
import { AlertActions } from './alert.actions';
import { createAsyncThunk } from './helpers';
import { AppAction, ProductsAction, ProductsThunkAction } from './types';

const uploadCoverImage = (
  productId: string,
  file: File | Blob,
  shouldShowMessage: boolean
): ProductsThunkAction => async dispatch => {
  try {
    dispatch({ type: ProductsConstants.COVER_IMAGE_UPLOAD_START });

    const { status, payload } = await ProductService.uploadCoverImage(
      productId,
      file,
      uploadProgress => {
        if (uploadProgress === 100) {
          return;
        }

        // 5% for server processing time
        dispatch({
          type: ProductsConstants.COVER_IMAGE_UPLOAD_PROGRESS,
          payload: {
            uploadProgress: Math.max(5, uploadProgress - 5),
          },
        });
      }
    );

    if (status !== 200 || payload.status !== 'Success') {
      throw new ApiError(payload);
    }

    dispatch({
      type: ProductsConstants.COVER_IMAGE_SUCCESS,
      payload: {
        productId,
        blobUrl: URL.createObjectURL(file),
        file,
      },
    });

    if (shouldShowMessage) {
      dispatch({
        type: ProductsConstants.EDIT_PRODUCT_CANCEL,
      });
      dispatch(AlertActions.success('NewProductForm.submit.edit.success'));
    }
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: ProductsConstants.COVER_IMAGE_FAILURE,
      payload: {
        error: msg,
      },
    });
  }
};

const increaseUploadProductExecuteProgress = (executeProgress: number) => {
  if (executeProgress >= 90) {
    return {};
  }

  return {
    type: ProductsConstants.UPLOAD_PRODUCT_EXECUTE_PROGRESS,
    payload: {
      executeProgress: executeProgress + 10,
    },
  };
};

const getHtmlFileUrl = (
  product: string,
  file: string,
  type: string
): ProductsThunkAction<string> => (_, getState) => {
  const {
    auth: { userAuth },
  } = getState();

  if (!userAuth) {
    return '';
  }

  const { accessToken } = userAuth;

  return ProductService.getHtmlFileUrl(product, file, accessToken, type);
};

const getProducts = (
  data: DTO.GetProductsRequest
): ProductsThunkAction => async dispatch => {
  try {
    dispatch({
      type: ProductsConstants.GET_PRODUCTS_REQUEST,
      payload: { ...data },
    });

    const { payload, status } = await ProductService.getProducts(data);

    if (status !== 200 || payload.status === 'Error') {
      throw new ApiError(payload);
    }

    dispatch({
      type: ProductsConstants.GET_PRODUCTS_SUCCESS,
      payload: {
        ...data,
        products: payload.data,
        total: payload.count,
      },
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: ProductsConstants.GET_PRODUCTS_FAILURE,
      payload: {
        error: msg,
      },
    });
  }
};

const getAllProducts = (
  data: DTO.GetAllProductsRequest
): ProductsThunkAction => async dispatch => {
  try {
    const productRequest = { ...data, page: 1, pageSize: 3 };
    dispatch({
      type: ProductsConstants.GET_ALL_PRODUCTS_REQUEST,
    });
    const { payload, status } = await ProductService.getProducts(
      productRequest
    );

    if (status !== 200 || payload.status === 'Error') {
      throw new ApiError(payload);
    } else {
      const {
        payload: secondPayload,
        status: secondStatus,
      } = await ProductService.getProducts({
        ...productRequest,
        pageSize: payload.count < 3 ? 3 : payload.count,
      });
      if (secondStatus !== 200 || secondPayload.status === 'Error') {
        throw new ApiError(secondPayload);
      }
      dispatch({
        type: ProductsConstants.GET_ALL_PRODUCTS_SUCCESS,
        payload: {
          allProducts: secondPayload.data,
        },
      });
    }
  } catch (error) {
    await dispatch(AlertActions.error(error));
    dispatch({
      type: ProductsConstants.GET_ALL_PRODUCTS_FAILURE,
    });
  }
};

const resetProduct = (): ProductsThunkAction => dispatch => {
  dispatch({
    type: ProductsConstants.RESET_PRODUCTS,
  });
};

const newProduct = (): ProductsAction => ({
  type: ProductsConstants.NEW_PRODUCT,
});

const cancelNewProduct = (): ProductsAction => ({
  type: ProductsConstants.NEW_PRODUCT_CANCEL,
});

const clearSelectedProduct = (): ProductsThunkAction => dispatch => {
  dispatch({
    type: ProductsConstants.CLEAR_SELECTED_PRODUCT,
  });
};

const cloneProduct = (product: DTO.Product): ProductsAction => ({
  type: ProductsConstants.CLONE_PRODUCT,
  payload: { product },
});

const getCloneProductName = (
  productName: string
): ProductsThunkAction => async dispatch => {
  try {
    dispatch({ type: ProductsConstants.GET_CLONE_PRODUCTNAME_REQUEST });

    const { status, payload } = await ProductService.getCloneProductName(
      productName
    );

    if (status !== 200 || payload.status === 'Error') {
      throw new ApiError(payload);
    }
    dispatch({
      type: ProductsConstants.GET_CLONE_PRODUCTNAME_SUCCESS,
      payload: {
        selectedProductName: payload.data,
      },
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: ProductsConstants.GET_CLONE_PRODUCTNAME_FAILURE,
      payload: {
        error: msg,
      },
    });
  }
};

const cancelCloneProduct = (): ProductsAction => ({
  type: ProductsConstants.CLONE_PRODUCT_CANCEL,
});

const updateProduct = (
  productId: string,
  product: Partial<DTO.ProductDetail>
): ProductsThunkAction => async dispatch => {
  try {
    dispatch({ type: ProductsConstants.UPDATE_PRODUCT_REQUEST });

    const { status, payload } = await ProductService.updateProduct(
      productId,
      product
    );

    if (status !== 200 || payload.status === 'Error') {
      throw new ApiError(payload);
    }

    dispatch({
      type: ProductsConstants.UPDATE_PRODUCT_SUCCESS,
      payload: {
        selectedProductName: product.name || productId,
        changes: product,
      },
    });

    dispatch(AlertActions.success('NewProductForm.submit.edit.success'));
  } catch (error) {
    let msg = '';
    if (
      error instanceof ApiError &&
      error.reason === ProductsConstants.UNAUTHORIZED
    ) {
      msg = await dispatch(AlertActions.error(error.reason));
    } else {
      msg = await dispatch(AlertActions.error(error));
    }

    dispatch({
      type: ProductsConstants.UPDATE_PRODUCT_FAILURE,
      payload: {
        error: msg,
      },
    });
  }
};

const saveProduct = (
  product: DTO.ProductDetail
): ProductsThunkAction<string | null> => async dispatch => {
  try {
    dispatch({ type: ProductsConstants.CREATE_PRODUCT_REQUEST });
    product.name = product.name.trim();

    const { status, payload } = await ProductService.createProducts(product);

    if (status !== 200 || payload.status === 'Error') {
      throw new ApiError(payload);
    }

    dispatch({
      type: ProductsConstants.CREATE_PRODUCT_SUCCESS,
      payload: { selectedProductName: product.name },
    });

    return product.name;
  } catch (error) {
    let msg = '';
    if (error instanceof ApiError && error.reason === 'ENTITY_EXISTS_ALREADY') {
      msg = await dispatch(
        AlertActions.error('NewProductForm.name.alreadyExists')
      );
    } else {
      msg = await dispatch(AlertActions.error(error));
    }
    dispatch({
      type: ProductsConstants.CREATE_PRODUCT_FAILURE,
      payload: {
        error: msg,
      },
    });

    return null;
  }
};

const cloneProductRequest = (
  product: DTO.CloneProductRequest
): ProductsThunkAction => async dispatch => {
  try {
    dispatch({ type: ProductsConstants.CLONE_PRODUCT_REQUEST });

    const { status, payload } = await ProductService.cloneProduct(product);

    if (status !== 200 || payload.status === 'Error') {
      throw new ApiError(payload);
    }

    dispatch({
      type: ProductsConstants.CLONE_PRODUCT_SUCCESS,
      payload: { newProductName: product.name },
    });

    dispatch(AlertActions.success('NewProductForm.submit.close.success'));
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: ProductsConstants.CLONE_PRODUCT_FAILURE,
      payload: {
        error: msg,
      },
    });
  }
};

const setFavoriteProduct = (
  productId: string,
  favorite: boolean
): ProductsThunkAction => async dispatch => {
  try {
    dispatch({
      type: ProductsConstants.FAVORITE_PRODUCT_REQUEST,
      payload: { productId, favorite },
    });

    const { status, payload } = await ProductService.setFavoriteProduct(
      productId,
      favorite
    );

    if (status !== 200 || payload.status !== 'Success') {
      throw new ApiError(payload);
    }

    dispatch({
      type: ProductsConstants.FAVORITE_PRODUCT_SUCCESS,
      payload: { productId },
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: ProductsConstants.FAVORITE_PRODUCT_FAILURE,
      payload: {
        productId,
        error: msg,
      },
    });
  }
};

const cancelUploadEngine = (): ProductsAction => ({
  type: ProductsConstants.UPLOAD_ENGINE_CANCEL,
});

const editProduct = (product: DTO.Product): ProductsAction => ({
  type: ProductsConstants.EDIT_PRODUCT,
  payload: { product },
});

const cancelEditProduct = (): ProductsAction => ({
  type: ProductsConstants.EDIT_PRODUCT_CANCEL,
});

const editCoverImage = (product: DTO.Product): ProductsAction => ({
  type: ProductsConstants.EDIT_COVER_IMAGE,
  payload: { product },
});

const resetCoverUpload = (): ProductsAction => ({
  type: ProductsConstants.COVER_IMAGE_UPLOAD_RESET,
});

const cancelEditCoverImage = (): ProductsAction => ({
  type: ProductsConstants.EDIT_COVER_IMAGE_CANCEL,
});

const cleanSelectedProduct = (): ProductsAction => ({
  type: ProductsConstants.CLEAN_SELETED_PRODUCT,
});

const getProductCoverImage = (
  productName: string,
  imagePath: string
): ProductsThunkAction<string> => async dispatch => {
  try {
    dispatch({
      type: ProductsConstants.COVER_IMAGE_GET_URL,
      payload: { imagePath },
    });

    const { status, payload } = await ProductService.getCoverImage(
      productName,
      imagePath
    );

    if (status !== 200) {
      throw new ApiError(payload);
    }

    const imageUrl = URL.createObjectURL(payload.blob);

    dispatch({
      type: ProductsConstants.COVER_IMAGE_GET_URL_SUCCESS,
      payload: {
        imagePath,
        imageUrl,
        imageData: payload.blob,
      },
    });

    return imageUrl;
  } catch (error) {
    dispatch({
      type: ProductsConstants.COVER_IMAGE_GET_URL_FAILURE,
      payload: {
        imagePath,
        error: error.toString(),
      },
    });

    return '';
  }
};

const getProductDetail = (
  productName: string,
  fetchDetailWOLoading = false
): ProductsThunkAction => async (dispatch, getState) => {
  try {
    const {
      products: { isLoadingProductDetail, selectedProductName },
    } = getState();
    if (
      isLoadingProductDetail &&
      selectedProductName === productName &&
      !fetchDetailWOLoading
    ) {
      return;
    }
    dispatch({
      type: ProductsConstants.GET_PRODUCT_DETAIL_REQUEST,
      payload: { productName, fetchDetailWOLoading },
    });

    const { status, payload } = await ProductService.getProductDetails(
      productName
    );

    if (status !== 200 || payload.status !== 'Success') {
      const error = getErrorMessage(payload, getState());
      if (error) {
        throw new ApiError({
          message: error,
        });
      }
      throw new ApiError(payload);
    }

    dispatch({
      type: ProductsConstants.GET_PRODUCT_DETAIL_SUCCESS,
      payload: {
        productName,
        product: payload.data,
      },
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: ProductsConstants.GET_PRODUCT_DETAIL_FAILURE,
      payload: {
        productName,
        error: msg,
      },
    });
  }
};

const changeProductStatus = (
  productId: string,
  productStatus: string,
  fromStatus: string
): ProductsThunkAction => async dispatch => {
  try {
    dispatch({
      type: ProductsConstants.CHANGE_PRODUCT_STATUS_REQUEST,
      payload: { productId, status: productStatus },
    });

    const { status, payload } = await ProductService.updateProduct(productId, {
      status: productStatus,
    });

    if (status !== 200 || payload.status !== 'Success') {
      throw new ApiError(payload);
    }

    dispatch({
      type: ProductsConstants.CHANGE_PRODUCT_STATUS_SUCCESS,
      payload: {
        productId,
        status: productStatus,
        fromStatus,
      },
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: ProductsConstants.CHANGE_PRODUCT_STATUS_FAILURE,
      payload: {
        productId,
        error: msg,
      },
    });
  }
};

const uploadDocumentModal = (documentType: string): ProductsAction => ({
  type: ProductsConstants.UPLOAD_DOCUMENT,
  payload: { documentType },
});

const cancelUploadDocumentModal = (): ProductsAction => ({
  type: ProductsConstants.UPLOAD_DOCUMENT_CANCEL,
});

const getProductRecentChanges = (
  productId: string,
  data: DTO.GetRecentChangeRequest
): ProductsThunkAction => async dispatch => {
  try {
    dispatch({ type: ProductsConstants.GET_RECENT_CHANGES_REQUEST });

    const { payload, status } = await ProductService.getRecentChanges(
      productId,
      data
    );

    if (status !== 200 || payload.status === 'Error') {
      throw new ApiError(payload);
    }

    dispatch({
      type: ProductsConstants.GET_RECENT_CHANGES_SUCCESS,
      payload: {
        nextPageToken: payload.next,
        data: payload.data ?? [],
      },
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: ProductsConstants.GET_RECENT_CHANGES_FAILURE,
      payload: { error: msg },
    });
  }
};

const clearRecentChanges = (): ProductsAction => ({
  type: ProductsConstants.CLEAR_RECENT_CHANGES,
});

const downloadProduct = (data: {
  name: string;
  isAllVersions: boolean;
}) => async dispatch => {
  try {
    dispatch({
      type: ProductsConstants.DOWNLOAD_PRODUCT_REQUEST,
      payload: data,
    });
    const { payload } = await FileManagerService.downloadFolder(
      data.name,
      data.isAllVersions
    );

    if (payload.status === 'Success') {
      dispatch({
        type: ProductsConstants.DOWNLOAD_PRODUCT_SUCCESS,
        payload: {
          ...data,
          jobId: payload.response_data.job_id,
        },
      });
    } else {
      dispatch({
        type: ProductsConstants.DOWNLOAD_PRODUCT_FAILURE,
        payload: {
          ...data,
          error: payload.error.message,
        },
      });
      return payload;
    }

    dispatch({
      type: BackgroundJobsConstants.TRIGGER_REFRESH_GBJOBS,
    });

    return payload;
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: ProductsConstants.DOWNLOAD_PRODUCT_FAILURE,
      payload: {
        ...data,
        error: msg,
      },
    });

    return {
      status: 'error',
      error: {
        message: msg,
      },
    };
  }
};

const uploadProductZip = (file: File) =>
  createAsyncThunk(
    async (dispatch, getState) => {
      dispatch({
        type: ProductsConstants.UPLOAD_PRODUCT_START,
      });

      const { status, payload } = await ProductService.uploadProductZip(
        file,
        uploadProgress => {
          if (uploadProgress === 100) {
            dispatch({
              type: ProductsConstants.UPLOAD_PRODUCT_EXECUTE_START,
            });
            return;
          }

          // 5% for server processing time
          dispatch({
            type: ProductsConstants.UPLOAD_PRODUCT_PROGRESS,
            payload: {
              uploadProgress: Math.max(5, uploadProgress - 5),
            },
          });
        },
        xhrRef => {
          dispatch({
            type: ProductsConstants.UPLOAD_PRODUCT_XHR_REF,
            payload: { xhrRef },
          });
        }
      );

      if (status !== 200 || payload.status !== 'Success') {
        const {
          language: { intl },
        } = getState();

        if (payload.errorCode === 'UPLOAD_FILE_FILESIZE_EXCEEDED') {
          dispatch({
            type: ProductsConstants.UPLOAD_PRODUCT_FAILURE,
            payload: {
              error: intl.formatMessage({ id: payload.errorCode }),
            },
          });
        } else {
          dispatch({
            type: ProductsConstants.UPLOAD_PRODUCT_FAILURE,
            payload: {
              error: intl.formatMessage({ id: 'INVALID_PRODUCT_ZIP' }),
            },
          });
        }

        return;
      }

      dispatch({
        type: ProductsConstants.UPLOAD_PRODUCT_SUCCESS,
        payload: {
          result: payload.response_data,
        },
      });
    },
    (_, msg, dispatch) => {
      dispatch({
        type: ProductsConstants.UPLOAD_PRODUCT_FAILURE,
        payload: { error: msg },
      });
    }
  );

const createProductByZip = (
  data: DTO.CreateProductByZipRequest
) => async dispatch => {
  const productName = data.Name?.trim();

  try {
    dispatch({
      type: ProductsConstants.CREATE_PRODUCT_BY_ZIP_FILE_REQUEST,
      payload: { productName },
    });

    const { payload } = await ProductService.createProductByZipFile({
      ...data,
    });

    if (payload.status === 'Success') {
      dispatch({
        type: ProductsConstants.CREATE_PRODUCT_BY_ZIP_FILE_SUCCESS,
        payload: { productName },
      });
    } else {
      const msg = await dispatch(AlertActions.error(payload.error.message));
      dispatch({
        type: ProductsConstants.CREATE_PRODUCT_BY_ZIP_FILE_FAILURE,
        payload: {
          error: msg,
          productName,
        },
      });
    }

    dispatch({
      type: BackgroundJobsConstants.TRIGGER_REFRESH_GBJOBS,
    });

    return payload;
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: ProductsConstants.CREATE_PRODUCT_BY_ZIP_FILE_FAILURE,
      payload: {
        error: msg,
        productName,
      },
    });
  }
};

const resetUploadProduct = (): AppAction => ({
  type: ProductsConstants.UPLOAD_PRODUCT_RESET,
});

const getActiveServiceCount = (): ProductsThunkAction => async dispatch => {
  dispatch({ type: ProductsConstants.GET_ACTIVESERVICE_COUNT_REQUEST });

  try {
    const { payload } = await ProductService.getActiveServiceCount();
    if (payload.status !== 'Success') {
      throw new ApiError(payload);
    }

    const { data } = payload;
    dispatch({
      type: ProductsConstants.GET_ACTIVESERVICE_COUNT_SUCCESS,
      payload: {
        data,
      },
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: ProductsConstants.GET_ACTIVESERVICE_COUNT_FAILURE,
      payload: { error: msg },
    });
  }
};

const getActiveServices = (
  request: DTO.PagedRequest
): ProductsThunkAction => async dispatch => {
  dispatch({ type: ProductsConstants.GET_ACTIVESERVICE_LIST_REQUEST });

  try {
    const { payload } = await ProductService.getActiveServices(request);
    if (payload.status !== 'Success') {
      throw new ApiError(payload);
    }
    const { data, count } = payload;
    dispatch({
      type: ProductsConstants.GET_ACTIVESERVICE_LIST_SUCCESS,
      payload: {
        data,
        count,
      },
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: ProductsConstants.GET_ACTIVESERVICE_LIST_FAILURE,
      payload: { error: msg },
    });
  }
};

const deleteFolder = (
  folderId: string
): ProductsThunkAction<boolean> => async dispatch => {
  try {
    const { payload, status } = await ProductService.deleteFolder(folderId);
    if (status !== 200 || payload.status !== 'Success') {
      throw new ApiError(payload);
    }
    return true;
  } catch (error) {
    if (
      error instanceof ApiError &&
      error.reason === ProductsConstants.UNAUTHORIZED
    ) {
      await dispatch(AlertActions.error(error.reason));
    } else {
      await dispatch(AlertActions.error(error));
    }
    return false;
  }
};

const isProductExists = (
  productName: string
): ProductsThunkAction<boolean> => async dispatch => {
  dispatch({ type: ProductsConstants.CHECK_PRODUCT_NAME_REQUEST });

  try {
    const { payload, status } = await ProductService.isProductExists(
      productName
    );

    if (status !== 200 || payload.status !== 'Success') {
      throw new ApiError(payload);
    }

    if (payload.data) {
      throw new ApiError(payload);
    }

    dispatch({
      type: ProductsConstants.CHECK_PRODUCT_NAME_SUCCESS,
    });
    return false;
  } catch (error) {
    dispatch({
      type: ProductsConstants.CHECK_PRODUCT_NAME_FAILURE,
      payload: { error: error?.toString() },
    });
    return true;
  }
};

export const ProductsActions = {
  getProducts,
  resetProduct,
  getCloneProductName,
  newProduct,
  cancelNewProduct,
  cloneProduct,
  cancelCloneProduct,
  saveProduct,
  cancelUploadEngine,
  setFavoriteProduct,
  editProduct,
  cancelEditProduct,
  editCoverImage,
  resetCoverUpload,
  cancelEditCoverImage,
  updateProduct,
  uploadCoverImage,
  getProductCoverImage,
  getProductDetail,
  changeProductStatus,
  cleanSelectedProduct,
  uploadDocumentModal,
  cancelUploadDocumentModal,
  cloneProductRequest,
  getProductRecentChanges,
  clearRecentChanges,
  getHtmlFileUrl,
  downloadProduct,
  uploadProductZip,
  resetUploadProduct,
  createProductByZip,
  increaseUploadProductExecuteProgress,
  clearSelectedProduct,
  getActiveServiceCount,
  getActiveServices,
  deleteFolder,
  isProductExists,
  getAllProducts,
};
