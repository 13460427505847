import React from 'react';
import { Typography, Button } from 'antd';
import { FormattedMessage } from 'react-intl';
import { FileUploadDefaultContentContainer } from '../index.styles';

const { Paragraph } = Typography;

interface FileUploadDefaultContent {
  btnText?: string;
  desc?: string;
  dragEnter?: boolean;
  hasError?: boolean;
  id?: string;
  onErrorShowDesc?: boolean;
  dataTestId?: string;
}

const FileUploadDefaultContent: React.FC<FileUploadDefaultContent> = ({
  btnText,
  desc,
  dragEnter,
  hasError,
  id,
  onErrorShowDesc = true,
  dataTestId,
}) => (
  <FileUploadDefaultContentContainer>
    {desc && (
      <>
        {(onErrorShowDesc || (!onErrorShowDesc && !hasError)) && (
          <>
            <Paragraph className="desc">
              <FormattedMessage id={desc} />
            </Paragraph>

            <Paragraph className="desc">
              <FormattedMessage id="UploadForm.upload.dragZone.or" />
            </Paragraph>
          </>
        )}
      </>
    )}

    {!dragEnter && (
      <Button
        className={`mw-200 ${hasError ? 'has-error' : ''} ${
          !(onErrorShowDesc || (!onErrorShowDesc && !hasError))
            ? 'has-error-top-space'
            : ''
        }`}
        type="primary"
        size="middle"
        shape="round"
        data-testid={dataTestId}
        id={id}
      >
        <FormattedMessage id={btnText || 'FileUpload.browse'} />
      </Button>
    )}
  </FileUploadDefaultContentContainer>
);

export default React.memo(FileUploadDefaultContent);
