import React, { useMemo, useEffect } from 'react';
import { Prompt } from 'react-router-dom';

const useHistoryPrompt = (
  shouldPrompt: boolean,
  promptId: string,
  onPrompt: (callback: (ok: boolean) => void) => void,
  ignoreRoutes: string[] = [],
  whichActionNeedPrevent?: string
) => {
  useEffect(() => {
    if (shouldPrompt) {
      window[promptId] = onPrompt;

      return () => {
        delete window[promptId];
      };
    }

    return () => {};
  }, [shouldPrompt, promptId, onPrompt]);

  const shouldIgnoreRouts = (
    ignoreRoutes: string[],
    pathname: string,
    promptId: string
  ) => {
    if (ignoreRoutes.length) {
      const matchRoute = ignoreRoutes.find(str => pathname.includes(str));
      if (matchRoute && matchRoute.length) {
        return '';
      }
    }
    return promptId;
  };

  return useMemo(() => {
    return {
      prompt: (
        <Prompt
          when={shouldPrompt}
          message={(location, actions) => {
            if (whichActionNeedPrevent && whichActionNeedPrevent === actions) {
              return shouldIgnoreRouts(
                ignoreRoutes,
                location.pathname,
                promptId
              );
            }
            if (!whichActionNeedPrevent) {
              return shouldIgnoreRouts(
                ignoreRoutes,
                location.pathname,
                promptId
              );
            }
            return '';
          }}
        />
      ),
      cancelPrompt: () => delete window[promptId],
    };
  }, [shouldPrompt, ignoreRoutes, promptId, whichActionNeedPrevent]);
};

export { useHistoryPrompt };
