import { SystematicTestcaseGenerationAction } from '../actions/types/SystematicTestcaseGeneration.types';
import { SystematicTestcaseGenerationConstants } from '../constants';
import { arrayReplaceFirst } from '../helpers';

export const initialSystematicTestcaseGenerationState: STATES.SystematicTestcaseGenerationState = {
  highlightedRow: undefined,
  selectedRowKeys: undefined,
  data: [],
  isDataListUpdated: false,
  SystematicTestCaseGeneration: {
    generationData: {
      isLoading: false,
      queueId: undefined,
      testbedId: undefined,
      testbedVersionId: undefined,
    },
    generationProgress: {
      isLoading: false,
      progress: undefined,
    },
  },
};

export const systematicTestcaseGeneration = (
  state = initialSystematicTestcaseGenerationState,
  action: SystematicTestcaseGenerationAction
): STATES.SystematicTestcaseGenerationState => {
  switch (action.type) {
    case SystematicTestcaseGenerationConstants.UPDATE_SELECTED_ROW_KEYS: {
      const { data } = action.payload;

      return {
        ...state,
        selectedRowKeys: data,
      };
    }

    case SystematicTestcaseGenerationConstants.RESET_SELECTED_ROW_KEYS: {
      return {
        ...state,
        selectedRowKeys:
          initialSystematicTestcaseGenerationState.selectedRowKeys,
      };
    }

    case SystematicTestcaseGenerationConstants.UPDATE_TEST_CASE_GENERATION_DATA_LIST: {
      const { data } = action.payload;

      return {
        ...state,
        data,
        isDataListUpdated: false,
      };
    }

    case SystematicTestcaseGenerationConstants.RESET_TEST_CASE_GENERATION_DATA_LIST: {
      return {
        ...state,
        data: initialSystematicTestcaseGenerationState.data,
        isDataListUpdated: false,
      };
    }

    case SystematicTestcaseGenerationConstants.UPDATE_TEST_CASE_GENERATION_DATA: {
      const { data } = action.payload;
      const test = arrayReplaceFirst(
        state.data,
        item => {
          return item.inputName === data.inputName;
        },
        data
      );

      return {
        ...state,
        highlightedRow: data.inputName,
        selectedRowKeys:
          initialSystematicTestcaseGenerationState.selectedRowKeys,
        data: test,
        isDataListUpdated: true,
      };
    }

    case SystematicTestcaseGenerationConstants.REMOVE_HIGHLIGHT_TEST_CASE_GENERATION_DATA: {
      return {
        ...state,
        highlightedRow: undefined,
      };
    }

    case SystematicTestcaseGenerationConstants.QUEUE_SYSTEMATIC_GENERATE_TEST_CASES_REQUEST: {
      return {
        ...state,
        SystematicTestCaseGeneration: {
          ...initialSystematicTestcaseGenerationState.SystematicTestCaseGeneration,
          generationData: {
            ...initialSystematicTestcaseGenerationState
              .SystematicTestCaseGeneration.generationData,
            isLoading: true,
          },
        },
      };
    }
    case SystematicTestcaseGenerationConstants.QUEUE_SYSTEMATIC_GENERATE_TEST_CASES_SUCCESS: {
      const { queueId } = action.payload;
      return {
        ...state,
        SystematicTestCaseGeneration: {
          ...state.SystematicTestCaseGeneration,
          generationData: {
            ...state.SystematicTestCaseGeneration.generationData,
            isLoading: false,
            queueId,
          },
        },
      };
    }
    case SystematicTestcaseGenerationConstants.QUEUE_SYSTEMATIC_GENERATE_TEST_CASES_FAILURE: {
      return {
        ...state,
        SystematicTestCaseGeneration: {
          ...state.SystematicTestCaseGeneration,
          generationData: {
            ...state.SystematicTestCaseGeneration.generationData,
            isLoading: false,
          },
        },
      };
    }

    case SystematicTestcaseGenerationConstants.GET_SYSTEMATIC_GENERATE_TEST_CASE_STATUS_REQUEST: {
      return {
        ...state,
        SystematicTestCaseGeneration: {
          ...state.SystematicTestCaseGeneration,
          generationProgress: {
            ...state.SystematicTestCaseGeneration.generationProgress,
            isLoading: true,
          },
        },
      };
    }

    case SystematicTestcaseGenerationConstants.GET_SYSTEMATIC_GENERATE_TEST_CASE_STATUS_SUCCESS: {
      const { progress } = action.payload;
      let testbedVersionId: string | undefined;
      let testbedId: string | undefined;
      if (progress.outputData) {
        const opData = JSON.parse(progress.outputData ?? '');
        testbedVersionId = opData?.testbedVersionId;
        testbedId = opData?.testbedId;

        return {
          ...state,
          highlightedRow: testbedVersionId,
          SystematicTestCaseGeneration: {
            ...state.SystematicTestCaseGeneration,
            generationData: {
              ...state.SystematicTestCaseGeneration.generationData,
              testbedId:
                testbedId ??
                state.SystematicTestCaseGeneration.generationData.testbedId,
              testbedVersionId:
                testbedVersionId ??
                state.SystematicTestCaseGeneration.generationData
                  .testbedVersionId,
            },
            generationProgress: {
              ...state.SystematicTestCaseGeneration.generationProgress,
              isLoading: false,
              progress,
            },
          },
        };
      }

      return {
        ...state,
        SystematicTestCaseGeneration: {
          ...state.SystematicTestCaseGeneration,
          generationProgress: {
            ...state.SystematicTestCaseGeneration.generationProgress,
            isLoading: false,
            progress,
          },
        },
      };
    }
    case SystematicTestcaseGenerationConstants.GET_SYSTEMATIC_GENERATE_TEST_CASE_STATUS_FAILURE: {
      return {
        ...state,
        SystematicTestCaseGeneration: {
          ...state.SystematicTestCaseGeneration,
          generationProgress: {
            ...state.SystematicTestCaseGeneration.generationProgress,
            isLoading: false,
          },
        },
      };
    }

    case SystematicTestcaseGenerationConstants.RESET_SYSTEMATIC_GENERATE_TEST_CASES_MODAL: {
      return {
        ...initialSystematicTestcaseGenerationState,
        highlightedRow: state.highlightedRow,
      };
    }

    default:
      return state;
  }
};
