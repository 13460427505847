import React, { memo, NamedExoticComponent } from 'react';
import ReactJson, { ReactJsonViewProps } from 'react-json-view';

interface JsonViewerProps extends ReactJsonViewProps {
  className?: string;
}

const JsonViewer: NamedExoticComponent<JsonViewerProps> = memo(
  ({ className, src, ...rest }) => {
    return (
      <div className={className}>
        <ReactJson src={src} theme="monokai" {...rest} />
      </div>
    );
  }
);

export { JsonViewer };
