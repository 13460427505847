export const TOGGLE_UPLOADMODAL_REQUEST = 'TOGGLE_UPLOADMODAL_REQUEST';
export const TOGGLE_UPLOADMODAL_SUCCESS = 'TOGGLE_UPLOADMODAL_SUCCESS';
export const TOGGLE_UPLOADMODAL_FAILURE = 'TOGGLE_UPLOADMODAL_FAILURE';

export const CHECK_SERVICE_EXISTS_REQUEST = 'CHECK_SERVICE_EXISTS_REQUEST';
export const CHECK_SERVICE_EXISTS_SUCCESS = 'CHECK_SERVICE_EXISTS_SUCCESS';
export const CHECK_SERVICE_EXISTS_FAILURE = 'CHECK_SERVICE_EXISTS_FAILURE';

export const DRAGGED_FILE_REQUEST = 'DRAGGED_FILE_REQUEST';
export const DRAGGED_FILE_SUCCESS = 'DRAGGED_FILE_SUCCESS';
export const DRAGGED_FILE_FAILURE = 'DRAGGED_FILE_FAILURE';

export const UPLOAD_SERVICE_REQUEST = 'UPLOAD_SERVICE_REQUEST';
export const UPLOAD_SERVICE_SUCCESS = 'UPLOAD_SERVICE_SUCCESS';
export const UPLOAD_SERVICE_FAILURE = 'UPLOAD_SERVICE_FAILURE';

export const UPLOAD_SERVICE_CANCEL_REQUEST = 'UPLOAD_SERVICE_CANCEL_REQUEST';
export const UPLOAD_SERVICE_CANCEL_SUCCESS = 'UPLOAD_SERVICE_CANCEL_SUCCESS';
export const UPLOAD_SERVICE_CANCEL_FAILURE = 'UPLOAD_SERVICE_CANCEL_FAILURE';

export const GET_UPOAD_LOGS_REQUEST = 'GET_UPOAD_LOGS_REQUEST';
export const GET_UPOAD_LOGS_SUCCESS = 'GET_UPOAD_LOGS_SUCCESS';
export const GET_UPOAD_LOGS_FAILURE = 'GET_UPOAD_LOGS_FAILURE';

export const GET_COMPILATION_PROGESS_REQUEST =
  'GET_COMPILATION_PROGESS_REQUEST';
export const GET_COMPILATION_PROGESS_SUCCESS =
  'GET_COMPILATION_PROGESS_SUCCESS';
export const GET_COMPILATION_PROGESS_FAILURE =
  'GET_COMPILATION_PROGESS_FAILURE';

export const PROGRESS_BAR_UPDATE = 'PROGRESS_BAR_UPDATE';
export const RESET_ALL = 'RESET_ALL';

export const PUBLISH_SERVICE_REQUEST = 'PUBLISH_SERVICE_REQUEST';
export const PUBLISH_SERVICE_SUCCESS = 'PUBLISH_SERVICE_SUCCESS';
export const PUBLISH_SERVICE_FAILURE = 'PUBLISH_SERVICE_FAILURE';

export const ADD_SERVICE_UPLOAD_XHR_REF = 'ADD_SERVICE_UPLOAD_XHR_REF';
