import moment from 'moment';
import {
  ApiError,
  addTagToData,
  calculatePercentageIncrease,
  getTagWiseCount,
  getTagWiseServiceList,
  groupDataByDate,
  splitDataByDates,
  splitDates,
} from '../helpers';
import { InsightsService, OptionServices } from '../services';
import { AlertActions } from './alert.actions';
import { DashboardConstants, SystemConstants } from '../constants';
import { DashboardThunkAction } from './types';

const getServicesListAPIcall = async (
  search: DTO.SearchV3[] = [],
  requestFields: { [key: string]: string[] } = {}
): Promise<DTO.OptionServices[]> => {
  const request: DTO.RequestV3<DTO.PagedRequestV3> = {
    request_data: {
      page: 1,
      page_size: -1,
      sort: 'name1_co',
      search,
      ...requestFields,
    },
  };
  const { payload, status } = await OptionServices.getOptionsServicesList(
    request
  );

  if (status !== 200 || payload.status !== 'Success') {
    throw new ApiError(payload);
  }

  return payload.response_data;
};

const getDashboardAggregateData = async (
  request: DTO.RequestV3<DTO.DashboardAggregatedDataRequestPayload>
): Promise<DTO.DashboardAggregatedData[]> => {
  const { payload, status } = await InsightsService.dashboardAggregateData(
    request
  );

  if (status !== 200 || payload.status !== 'Success') {
    throw new ApiError(payload);
  }

  return payload.response_data;
};

const getServiceCount = (
  list: DTO.OptionServices[]
): DashboardThunkAction<{ name: string; value: number }[]> => (_, getState) => {
  const {
    system: {
      appConfig: { TagsSelected },
    },
    language: { intl },
  } = getState();

  if (!TagsSelected) {
    return [
      {
        name: intl.formatMessage({
          id: 'Dashboard.chart.serviceCount.tag.total',
        }),
        value: list.length,
      },
    ];
  }

  const tagWiseServiceList: {
    [key: string]: DTO.OptionServices[];
  } = getTagWiseServiceList(TagsSelected.split(',').sort(), list);

  return [
    ...TagsSelected.split(',')
      .sort()
      .map(item => {
        return {
          name: item,
          value: tagWiseServiceList[item]?.length ?? 0,
        };
      }),
    {
      name: intl.formatMessage({
        id: 'Dashboard.chart.serviceCount.tag.total',
      }),
      value: list.length,
    },
  ];
};

const getServiceCountChartData = (
  list: DTO.OptionServices[]
): DashboardThunkAction => async dispatch => {
  dispatch({
    type: DashboardConstants.GET_SERVICE_COUNT_DATA_REQUEST,
  });

  const data = await dispatch(getServiceCount(list));

  dispatch({
    type: DashboardConstants.GET_SERVICE_COUNT_DATA_SUCCESS,
    payload: {
      list: data,
    },
  });
};

const getProductServiceCountChartData = (
  list: DTO.OptionServices[]
): DashboardThunkAction => async dispatch => {
  dispatch({
    type: DashboardConstants.GET_FOLDER_DETAIL_SERVICE_COUNT_DATA_REQUEST,
  });

  const data = await dispatch(getServiceCount(list));

  dispatch({
    type: DashboardConstants.GET_FOLDER_DETAIL_SERVICE_COUNT_DATA_SUCCESS,
    payload: {
      list: data,
    },
  });
};

const getServiceUpdateChartData = (
  request: DTO.RequestV3<DTO.DashboardAggregatedDataRequestPayload>
): DashboardThunkAction => async dispatch => {
  try {
    dispatch({
      type: DashboardConstants.GET_SERVICE_UPDATE_DATA_REQUEST,
    });

    const data = await getDashboardAggregateData(request);
    const groupData = groupDataByDate(
      data,
      true,
      request.request_data.start_date,
      request.request_data.end_date
    );
    dispatch({
      type: DashboardConstants.GET_SERVICE_UPDATE_DATA_SUCCESS,
      payload: {
        list: groupData,
      },
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));
    dispatch({
      type: DashboardConstants.GET_DASHBOARD_SERVICES_LIST_FAILURE,
      payload: {
        error: msg,
      },
    });
  }
};

const getServicesList = (): DashboardThunkAction => async dispatch => {
  try {
    dispatch({
      type: DashboardConstants.GET_DASHBOARD_SERVICES_LIST_REQUEST,
    });

    const list = await getServicesListAPIcall([], {
      fields: [
        'id',
        'foldername',
        'filename',
        'version',
        'tags',
        'modifiedDate',
      ],
    });

    const tempList = list.map(item => {
      if (typeof item.tags === 'string') {
        return {
          ...item,
          key: item.id,
          tags: item.tags.split(',').filter(item => item),
        };
      }
      return {
        ...item,
        key: item.id,
      };
    });

    const requestList = tempList.map(item => {
      return {
        folder: item.folderName,
        service: item.fileName,
      };
    });

    dispatch(getServiceCountChartData(tempList));
    dispatch(
      getServiceUpdateChartData({
        request_data: {
          services: requestList,
          counters:
            DashboardConstants.DashboardMetricWiseCounter['By service update'],
          start_date: moment()
            .subtract(29, 'days')
            .format(SystemConstants.SHORT_DATE_FORMAT),
          end_date: moment().format(SystemConstants.SHORT_DATE_FORMAT),
        },
      })
    );

    dispatch(
      getOtherChartData(
        {
          request_data: {
            services: requestList,
            counters: DashboardConstants.DashboardMetricWiseCounter['By calls'],
            start_date: moment()
              .subtract(13, 'days')
              .format(SystemConstants.SHORT_DATE_FORMAT),
            end_date: moment().format(SystemConstants.SHORT_DATE_FORMAT),
          },
        },
        tempList,
        'By calls'
      )
    );

    dispatch({
      type: DashboardConstants.GET_DASHBOARD_SERVICES_LIST_SUCCESS,
      payload: {
        list: tempList,
        requestList,
      },
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));
    dispatch({
      type: DashboardConstants.GET_DASHBOARD_SERVICES_LIST_FAILURE,
      payload: {
        error: msg,
      },
    });
  }
};

const getProductServicesList = (
  folder: string
): DashboardThunkAction => async dispatch => {
  try {
    dispatch({
      type: DashboardConstants.GET_FOLDER_DETAIL_SERVICES_LIST_REQUEST,
    });

    const list = await getServicesListAPIcall([
      {
        fieldName: 'productname_co',
        fieldValue: folder,
      },
    ]);

    const tempList = list.map(item => {
      if (typeof item.tags === 'string') {
        return {
          ...item,
          key: item.id,
          tags: item.tags.split(',').filter(item => item),
        };
      }
      return {
        ...item,
        key: item.id,
      };
    });

    const requestList = tempList.map(item => {
      return {
        folder: item.folderName,
        service: item.fileName,
      };
    });

    dispatch(getProductServiceCountChartData(tempList));
    dispatch(
      getFolderDetailOtherChartData(
        {
          request_data: {
            services: requestList,
            counters: DashboardConstants.DashboardMetricWiseCounter['By calls'],
            start_date: moment()
              .subtract(13, 'days')
              .format(SystemConstants.SHORT_DATE_FORMAT),
            end_date: moment().format(SystemConstants.SHORT_DATE_FORMAT),
          },
        },
        tempList,
        'By calls'
      )
    );

    dispatch({
      type: DashboardConstants.GET_FOLDER_DETAIL_SERVICES_LIST_SUCCESS,
      payload: {
        list: tempList,
        requestList,
      },
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: DashboardConstants.GET_FOLDER_DETAIL_SERVICES_LIST_FAILURE,
      payload: {
        error: msg,
      },
    });
  }
};

export const getFolderDetailOtherChartData = (
  request: DTO.RequestV3<DTO.DashboardAggregatedDataRequestPayload>,
  list: DTO.OptionServices[],
  metric: string
): DashboardThunkAction => async (dispatch, getState) => {
  try {
    const {
      system: {
        appConfig: { TagsSelected },
      },
    } = getState();

    dispatch({
      type: DashboardConstants.GET_FOLDER_DETAIL_OTHER_CHART_DATA_REQUEST,
    });

    const splitDateRange = splitDates(
      request.request_data.start_date || '',
      request.request_data.end_date || ''
    );

    const data = await getDashboardAggregateData(request);
    let splitData = splitDataByDates(data, splitDateRange);
    const tagWiseServiceList: {
      [key: string]: DTO.OptionServices[];
    } = getTagWiseServiceList(
      TagsSelected ? TagsSelected?.split(',').sort() : [],
      list
    );

    splitData = addTagToData(splitData, tagWiseServiceList);
    dispatch(
      getUsageChartData(splitData, splitDateRange, metric, 'folderDetail')
    );
    dispatch(getServiceListData(splitData, list, 'folderDetail'));
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: DashboardConstants.GET_FOLDER_DETAIL_OTHER_CHART_DATA_FAILURE,
      payload: {
        error: msg,
      },
    });
  }
};

export const getOtherChartData = (
  request: DTO.RequestV3<DTO.DashboardAggregatedDataRequestPayload>,
  list: DTO.OptionServices[],
  metric: string
): DashboardThunkAction => async (dispatch, getState) => {
  try {
    const {
      system: {
        appConfig: { TagsSelected },
      },
    } = getState();

    dispatch({
      type: DashboardConstants.GET_OTHER_CHART_DATA_REQUEST,
    });

    const splitDateRange = splitDates(
      request.request_data.start_date || '',
      request.request_data.end_date || ''
    );

    const data = await getDashboardAggregateData(request);
    let splitData = splitDataByDates(data, splitDateRange);
    const tagWiseServiceList: {
      [key: string]: DTO.OptionServices[];
    } = getTagWiseServiceList(
      TagsSelected ? TagsSelected?.split(',').sort() : [],
      list
    );

    splitData = addTagToData(splitData, tagWiseServiceList);
    dispatch(getUsageChartData(splitData, splitDateRange, metric));
    dispatch(getFolderListData(splitData[1], splitDateRange[1], metric));
    dispatch(getServiceListData(splitData, list));
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: DashboardConstants.GET_OTHER_CHART_DATA_FAILURE,
      payload: {
        error: msg,
      },
    });
  }
};

export const getUsageChartData = (
  splitData: DTO.DashboardAggregatedData[][],
  splitDateRange: {
    startDate: string;
    endDate: string;
  }[],
  metric: string,
  type: 'dashboard' | 'folderDetail' = 'dashboard'
): DashboardThunkAction => async (dispatch, getState) => {
  try {
    const {
      system: {
        appConfig: { TagsSelected },
      },
      language: { intl },
    } = getState();

    const tagWiseCount = getTagWiseCount(
      splitData,
      TagsSelected ? TagsSelected?.split(',').sort() : [],
      intl
    );

    const tagList = calculatePercentageIncrease(tagWiseCount);

    const tagsSeries: { [key: string]: { x: number; y: number }[] } = {};
    if (TagsSelected) {
      TagsSelected.split(',')
        .sort()
        .forEach(element => {
          const data = splitData[1].filter(data => data.tag === element);
          const groupByDateData = groupDataByDate(
            data,
            true,
            splitDateRange[1].startDate,
            splitDateRange[1].endDate
          ).map(item => {
            return {
              x:
                moment(item.date, SystemConstants.SHORT_DATE_FORMAT).valueOf() +
                moment().utcOffset() * 60 * 1000, // Added timezone offset to show point on x-axis tick mark
              y: item.count,
            };
          });
          tagsSeries[element] = groupByDateData;
        });
    } else {
      const groupByDateData = groupDataByDate(
        splitData[1],
        true,
        splitDateRange[1].startDate,
        splitDateRange[1].endDate
      ).map(item => {
        return {
          x:
            moment(item.date, SystemConstants.SHORT_DATE_FORMAT).valueOf() +
            moment().utcOffset() * 60 * 1000, // Added timezone offset to show point on x-axis tick mark
          y: item.count,
        };
      });
      tagsSeries[
        intl.formatMessage({
          id: 'Dashboard.chart.serviceCount.tag.total',
        })
      ] = groupByDateData;
    }

    const tagsSeriesList = Object.entries(tagsSeries).map(([name, items]) => ({
      name,
      data: items.map(item => {
        return {
          ...item,
          y:
            metric !== 'By CPU hours'
              ? parseFloat(item.y.toString())
              : parseFloat(
                  intl.formatNumber(item.y, {
                    maximumFractionDigits: 1,
                  })
                ),
        };
      }),
    }));

    if (type === 'dashboard') {
      dispatch({
        type: DashboardConstants.GET_USAGE_DATA_SUCCESS,
        payload: {
          list: tagsSeriesList,
          tagList,
        },
      });
    } else {
      dispatch({
        type: DashboardConstants.GET_FOLDER_DETAIL_USAGE_DATA_SUCCESS,
        payload: {
          list: tagsSeriesList,
          tagList,
        },
      });
    }
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));
    if (type === 'dashboard') {
      dispatch({
        type: DashboardConstants.GET_USAGE_DATA_FAILURE,
        payload: {
          error: msg,
        },
      });
    } else {
      dispatch({
        type: DashboardConstants.GET_FOLDER_DETAIL_USAGE_DATA_FAILURE,
        payload: {
          error: msg,
        },
      });
    }
  }
};

export const getFolderListData = (
  data: DTO.DashboardAggregatedData[],
  dates: {
    startDate: string;
    endDate: string;
  },
  metric: string
): DashboardThunkAction => async (dispatch, getState) => {
  try {
    const {
      language: { intl },
    } = getState();

    const groupedByFolder: DTO.FolderListChartData = data.reduce(
      (acc, item) => {
        const { folder, service, date, count, tag } = item;

        if (!acc[folder]) {
          acc[folder] = {
            folder,
            uniqueServices: [],
            data: [],
            tags: {},
          };
        }

        const folderData = acc[folder];

        if (
          !folderData.uniqueServices.some(
            serviceItem => serviceItem.name === service
          )
        ) {
          folderData.uniqueServices.push({
            name: service,
            tag,
          });
        }

        const existingDateData = folderData.data.find(
          dataItem => dataItem.date === date
        );

        if (existingDateData) {
          existingDateData.count += count;
        } else {
          folderData.data.push({
            date,
            count,
          });
        }

        return acc;
      },
      {} as DTO.FolderListChartData
    );

    const formattedData = Object.values(groupedByFolder).map(folder => {
      const tags: {
        [key: string]: number;
      } = folder.uniqueServices.reduce((acc, item) => {
        if (item.tag) {
          acc[item.tag] = acc[item.tag] ? acc[item.tag] + 1 : 1;
        }
        return acc;
      }, {});

      const totalCount = Object.values(tags).reduce(
        (total, tag) => total + tag,
        0
      );

      Object.keys(tags).forEach(tag => {
        tags[tag] = parseFloat(((tags[tag] / totalCount) * 100).toFixed(2));
      });

      const groupData = groupDataByDate(
        folder.data as { date: string; count: number }[],
        true,
        dates.startDate,
        dates.endDate
      ).map(item => {
        return {
          x:
            moment(item.date, SystemConstants.SHORT_DATE_FORMAT).valueOf() +
            moment().utcOffset() * 60 * 1000, // Added timezone offset to show point on x-axis tick mark
          y:
            metric !== 'By CPU hours'
              ? parseFloat(item.count.toString())
              : parseFloat(
                  intl.formatNumber(item.count, {
                    maximumFractionDigits: 1,
                  })
                ),
        };
      });

      return {
        folder: folder.folder,
        servicesCount: folder.uniqueServices.length,
        data: groupData,
        tags,
        totalServicesUsageCount: groupData.reduce(
          (total, data) => total + data.y,
          0
        ),
      } as DTO.FolderListChartData;
    });
    dispatch({
      type: DashboardConstants.GET_FOLDER_LIST_DATA_SUCCESS,
      payload: {
        list: formattedData,
      },
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));
    dispatch({
      type: DashboardConstants.GET_DASHBOARD_SERVICES_LIST_FAILURE,
      payload: {
        error: msg,
      },
    });
  }
};

export const getServiceListData = (
  data: DTO.DashboardAggregatedData[][],
  list: DTO.OptionServices[],
  type: 'dashboard' | 'folderDetail' = 'dashboard'
): DashboardThunkAction => async dispatch => {
  try {
    const transformed: DTO.ServiceListChartData[][] = [];

    data.forEach(indexData => {
      const transformedData: DTO.ServiceListChartData[] = [];

      indexData.forEach(item => {
        const dataFound = transformedData.find(
          el => el.service === item.service && el.folder === item.folder
        );
        if (!dataFound) {
          transformedData.push({
            service: item.service,
            folder: item.folder,
            tag: item.tag,
            count: item.count,
          });
        } else {
          dataFound.count += item.count;
        }
      });
      transformed.push(transformedData);
    });

    const prevData = transformed[0];
    const currentData = transformed[1];
    currentData.forEach(currItem => {
      const listItem = list.find(
        listItem =>
          listItem.folderName === currItem.folder &&
          listItem.fileName === currItem.service
      );
      if (listItem) {
        currItem.modifiedDate = listItem.modifiedDate ?? undefined;
        currItem.revision = listItem.version;
      }

      // Calculate the percentage
      const findResult = prevData.find(
        prevItem =>
          prevItem.folder === currItem.folder &&
          prevItem.service === currItem.service
      );
      if (findResult) {
        if (findResult.count !== 0) {
          const percentage =
            ((currItem.count - findResult.count) / findResult.count) * 100;
          currItem.percentage = percentage;
          return;
        }

        if (findResult.count === 0 && currItem.count === 0) {
          currItem.percentage = 0;
          return;
        }
        currItem.percentage = 'Infinity';
      } else {
        currItem.percentage = 'Infinity';
      }
    });

    if (type === 'dashboard') {
      dispatch({
        type: DashboardConstants.GET_SERVICE_LIST_DATA_SUCCESS,
        payload: {
          list: currentData,
        },
      });
    } else {
      dispatch({
        type: DashboardConstants.GET_FOLDER_DETAIL_SERVICE_LIST_DATA_SUCCESS,
        payload: {
          list: currentData,
        },
      });
    }
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));
    if (type === 'dashboard') {
      dispatch({
        type: DashboardConstants.GET_SERVICE_LIST_DATA_FAILURE,
        payload: {
          error: msg,
        },
      });
    } else {
      dispatch({
        type: DashboardConstants.GET_FOLDER_DETAIL_SERVICE_LIST_DATA_FAILURE,
        payload: {
          error: msg,
        },
      });
    }
  }
};

export const DashboardActions = {
  getServicesList,
  getProductServicesList,
  getOtherChartData,
  getFolderDetailOtherChartData,
};
