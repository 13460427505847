export const UPDATE_PAGE_REQUEST = 'UPDATE_PAGE_REQUEST';
export const RESET_APPLY_REQUEST = 'RESET_APPLY_REQUEST';
export const RESET_OPTION_SERVICES_TO_INITIAL_STATE = 'RESET_OPTION_SERVICES_TO_INITIAL_STATE';
export const APPLY_SAVED_FILTER_REQUEST = 'APPLY_SAVED_FILTER_REQUEST';

export const REMOVE_HIGHLIGHT_SAVED_FILTER_LIST =
  'REMOVE_HIGHLIGHT_SAVED_FILTER_LIST';

export const GET_OPTIONS_SERVICES_LIST_REQUEST =
  'GET_OPTIONS_SERVICES_LIST_REQUEST';
export const GET_OPTIONS_SERVICES_LIST_SUCCESS =
  'GET_OPTIONS_SERVICES_LIST_SUCCESS';
export const GET_OPTIONS_SERVICES_LIST_FAILURE =
  'GET_OPTIONS_SERVICES_LIST_FAILURE';

export const CREATE_OR_UPDATE_OPTIONS_SERVICES_FILTER_REQUEST =
  'CREATE_OR_UPDATE_OPTIONS_SERVICES_FILTER_REQUEST';
export const CREATE_OR_UPDATE_OPTIONS_SERVICES_FILTER_SUCCESS =
  'CREATE_OR_UPDATE_OPTIONS_SERVICES_FILTER_SUCCESS';
export const CREATE_OR_UPDATE_OPTIONS_SERVICES_FILTER_FAILURE =
  'CREATE_OR_UPDATE_OPTIONS_SERVICES_FILTER_FAILURE';

export const GET_OPTIONS_SERVICES_FILTERS_LIST_REQUEST =
  'GET_OPTIONS_SERVICES_FILTERS_LIST_REQUEST';
export const GET_OPTIONS_SERVICES_FILTERS_LIST_SUCCESS =
  'GET_OPTIONS_SERVICES_FILTERS_LIST_SUCCESS';
export const GET_OPTIONS_SERVICES_FILTERS_LIST_FAILURE =
  'GET_OPTIONS_SERVICES_FILTERS_LIST_FAILURE';

export const GET_OPTIONS_SERVICES_FILTER_DETAILS_REQUEST =
  'GET_OPTIONS_SERVICES_FILTER_DETAILS_REQUEST';
export const GET_OPTIONS_SERVICES_FILTER_DETAILS_FAILURE =
  'GET_OPTIONS_SERVICES_FILTER_DETAILS_FAILURE';

export const DELETE_OPTIONS_SERVICES_FILTER_REQUEST =
  'DELETE_OPTIONS_SERVICES_FILTER_REQUEST';
export const DELETE_OPTIONS_SERVICES_FILTER_SUCCESS =
  'DELETE_OPTIONS_SERVICES_FILTER_SUCCESS';
export const DELETE_OPTIONS_SERVICES_FILTER_FAILURE =
  'DELETE_OPTIONS_SERVICES_FILTER_FAILURE';

export const GET_OPTIONS_SERVICES_PRODUCTS_REQUEST =
  'GET_OPTIONS_SERVICES_PRODUCTS_REQUEST';
export const GET_OPTIONS_SERVICES_PRODUCTS_SUCCESS =
  'GET_OPTIONS_SERVICES_PRODUCTS_SUCCESS';
export const GET_OPTIONS_SERVICES_PRODUCTS_FAILURE =
  'GET_OPTIONS_SERVICES_PRODUCTS_FAILURE';
