const serviceNameText = ':serviceName';
export const ProductHeaderRoutes = {
  '/shared/products/:productId': ':productId',
  '/shared/products/:productId/:serviceName/serviceDocumentation/uploadSummary': serviceNameText,
  '/shared/products/:productId/:serviceName/serviceDocumentation/properties': serviceNameText,
  '/shared/products/:productId/:serviceName/serviceDocumentation/outputs': serviceNameText,
  '/shared/products/:productId/:serviceName/serviceDocumentation/inputs': serviceNameText,
  '/shared/products/:productId/:serviceName/serviceDocumentation/summary': serviceNameText,
  '/shared/products/:productId/:serviceName/apiTester/test/:versionId?': serviceNameText,
  '/shared/products/:productId/:serviceName/apiTester/integrate': serviceNameText,
  '/shared/products/:productId/:serviceName/apiTester/docs': serviceNameText,
  '/shared/products/:productId/:serviceName/versionOverview/timeline': serviceNameText,
  '/shared/products/:productId/:serviceName/versionOverview/history': serviceNameText,
  '/shared/products/:productId/:serviceName/logs/:versionId?': serviceNameText,
  '': 'ProductDashboard.breadcrumb.catalogue',
  '/products/:productId': ':productId',
  '/products/:productId/:serviceName/serviceDocumentation/uploadSummary': serviceNameText,
  '/products/:productId/:serviceName/serviceDocumentation/properties': serviceNameText,
  '/products/:productId/:serviceName/serviceDocumentation/outputs': serviceNameText,
  '/products/:productId/:serviceName/serviceDocumentation/inputs': serviceNameText,
  '/products/:productId/:serviceName/serviceDocumentation/summary': serviceNameText,
  '/products/:productId/:serviceName/apiTester/test/:versionId?': serviceNameText,
  '/products/:productId/:serviceName/apiTester/integrate': serviceNameText,
  '/products/:productId/:serviceName/apiTester/docs': serviceNameText,
  '/products/:productId/:serviceName/versionOverview/timeline': serviceNameText,
  '/products/:productId/:serviceName/versionOverview/history': serviceNameText,
  '/products/:productId/:serviceName/logs/:versionId?': serviceNameText,
};

export const NewProductHeaderRoutes = {
  '/shared/folders': 'Folder',
  '/folders': 'Folder',
  '/shared/folders/:productId': ':productId',
  '/folders/:productId': ':productId',
};

export const TestbedDetailRoutes = {
  '/shared/products/:productId': ':productId',
  '/shared/products/:productId/:serviceName/testcenter/testbeds':
    ':serviceName',
  '/shared/products/:productId/:serviceName/testcenter/testbeds/:refId':
    ':serviceName',
  '/shared/products/:productId/:serviceName/testcenter/testbeds/:testbedId/:testbedName/:refType?/:refId?/:sourceTestRunId?/:targetTestRunId?':
    ':testbedName',
  '': 'ProductDashboard.breadcrumb.catalogue',
  '/products/:productId': ':productId',
  '/products/:productId/:serviceName/testcenter/testbeds': ':serviceName',
  '/products/:productId/:serviceName/testcenter/testbeds/:refId':
    ':serviceName',
  '/products/:productId/:serviceName/testcenter/testbeds/:testbedId/:testbedName/:refType?/:refId?/:sourceTestRunId?/:targetTestRunId?':
    ':testbedName',
};

export const TestingCenterRoutes = {
  '/shared/products/:productId': ':productId',
  '/shared/products/:productId/:serviceName/testcenter/testbeds/:refId?/:refType?/:serviceId?':
    ':serviceName',
  '': 'ProductDashboard.breadcrumb.catalogue',
  '/products/:productId': ':productId',
  '/products/:productId/:serviceName/testcenter/testbeds/:refId?/:refType?/:serviceId?':
    ':serviceName',
};

export const RouteMappings = {
  '': { name: 'ProductCatalogue' },
  '/shared/products/:productId': { name: 'ProductDashboard' },
  '/products/:productId': { name: 'ProductDashboard' },
  '/shared/products/:productId/:serviceName/serviceDocumentation/uploadSummary': {
    name: 'ServiceDocUploadSummary',
  },
  '/products/:productId/:serviceName/serviceDocumentation/uploadSummary': {
    name: 'ServiceDocUploadSummary',
  },
  '/shared/products/:productId/:serviceName/serviceDocumentation/properties': {
    name: 'ServiceDocServiceDetails',
  },
  '/products/:productId/:serviceName/serviceDocumentation/properties': {
    name: 'ServiceDocServiceDetails',
  },
  '/shared/products/:productId/:serviceName/serviceDocumentation/outputs': {
    name: 'ServiceDocOutputs',
  },
  '/products/:productId/:serviceName/serviceDocumentation/outputs': {
    name: 'ServiceDocOutputs',
  },
  '/shared/products/:productId/:serviceName/serviceDocumentation/inputs': {
    name: 'ServiceDocInputs',
  },
  '/products/:productId/:serviceName/serviceDocumentation/inputs': {
    name: 'ServiceDocInputs',
  },
  '/shared/products/:productId/:serviceName/serviceDocumentation/summary': {
    name: 'ServiceDocVersionDetail',
  },
  '/products/:productId/:serviceName/serviceDocumentation/summary': {
    name: 'ServiceDocVersionDetail',
  },
  '/shared/products/:productId/:serviceName/apiTester/test': {
    name: 'ApiTester',
  },
  '/products/:productId/:serviceName/apiTester/test': { name: 'ApiTester' },
  '/shared/products/:productId/:serviceName/apiTester/test/:versionId?': {
    name: 'ApiTester',
  },
  '/products/:productId/:serviceName/apiTester/test/:versionId?': {
    name: 'ApiTester',
  },
  '/shared/products/:productId/:serviceName/apiTester/integrate': {
    name: 'ApiTesterIntegration',
  },
  '/products/:productId/:serviceName/apiTester/integrate': {
    name: 'ApiTesterIntegration',
  },
  '/shared/products/:productId/:serviceName/apiTester/docs': {
    name: 'ApiTesterDocumentation',
  },
  '/products/:productId/:serviceName/apiTester/docs': {
    name: 'ApiTesterDocumentation',
  },
  '/shared/products/:productId/:serviceName/versionOverview/timeline': {
    name: 'VersionOverviewTimeline',
  },
  '/products/:productId/:serviceName/versionOverview/timeline': {
    name: 'VersionOverviewTimeline',
  },
  '/shared/products/:productId/:serviceName/versionOverview/history': {
    name: 'VersionOverviewHistory',
  },
  '/products/:productId/:serviceName/versionOverview/history': {
    name: 'VersionOverviewHistory',
  },
  '/shared/products/:productId/:serviceName/logs/:versionId?': {
    name: 'ApiCallHistory',
  },
  '/products/:productId/:serviceName/logs/:versionId?': {
    name: 'ApiCallHistory',
  },
  '/shared/products/:productId/:serviceName/testcenter/testbeds': {
    name: 'Testbed',
  },
  '/products/:productId/:serviceName/testcenter/testbeds': { name: 'Testbed' },
  '/shared/products/:productId/:serviceName/testcenter/testbeds/:testbedId/:testbedName': {
    name: 'TestbedResult',
  },
  '/products/:productId/:serviceName/testcenter/testbeds/:testbedId/:testbedName': {
    name: 'TestbedResult',
  },
  '/admin/tenant-configuration/general': {
    name: 'TenantConfigurationGeneral',
  },
  '/admin/tenant-configuration/explainer': {
    name: 'TenantConfigurationExplainer',
  },
  '/admin/tenant-configuration/features': {
    name: 'TenantConfigurationFeaturesPermissions',
  },
  '/admin/tenant-configuration/ip': {
    name: 'TenantConfigurationIPAllowlisting',
  },
  '/admin/tenant-configuration/tags': {
    name: 'TenantConfigurationTags',
  },
  '/admin/tenant-configuration/webhook': {
    name: 'TenantConfigurationWebhook',
  },
  '/admin/explorer/home': {
    name: 'Files',
  },
  '/admin/explorer/home/*': {
    name: 'Files',
  },
  '/admin/systems/usage': {
    name: 'UsageDetails',
  },
  '/admin/categories': {
    name: 'FolderCategories',
  },
  '/admin/insights': {
    name: 'Insights',
  },
  '/admin/templates': {
    name: 'ModelTempates',
  },
  '/admin/services/services-list': {
    name: 'ServiceConsole',
  },
  '/admin/configuration': {
    name: 'ConfigurationFiles',
  },
  '/admin/deployment/request': {
    name: 'DeploymentRequest',
  },
};
