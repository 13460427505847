export const GET_DASHBOARD_SERVICES_LIST_REQUEST =
  'GET_DASHBOARD_SERVICES_LIST_REQUEST';
export const GET_DASHBOARD_SERVICES_LIST_SUCCESS =
  'GET_DASHBOARD_SERVICES_LIST_SUCCESS';
export const GET_DASHBOARD_SERVICES_LIST_FAILURE =
  'GET_DASHBOARD_SERVICES_LIST_FAILURE';

export const GET_FOLDER_DETAIL_SERVICES_LIST_REQUEST =
  'GET_FOLDER_DETAIL_SERVICES_LIST_REQUEST';
export const GET_FOLDER_DETAIL_SERVICES_LIST_SUCCESS =
  'GET_FOLDER_DETAIL_SERVICES_LIST_SUCCESS';
export const GET_FOLDER_DETAIL_SERVICES_LIST_FAILURE =
  'GET_FOLDER_DETAIL_SERVICES_LIST_FAILURE';

export const GET_DASHBOARD_AGGREGATE_DATA_REQUEST =
  'GET_DASHBOARD_AGGREGATE_DATA_REQUEST';
export const GET_DASHBOARD_AGGREGATE_DATA_SUCCESS =
  'GET_DASHBOARD_AGGREGATE_DATA_SUCCESS';
export const GET_DASHBOARD_AGGREGATE_DATA_FAILURE =
  'GET_DASHBOARD_AGGREGATE_DATA_FAILURE';

export const GET_SERVICE_COUNT_DATA_REQUEST = 'GET_SERVICE_COUNT_DATA_REQUEST';
export const GET_SERVICE_COUNT_DATA_SUCCESS = 'GET_SERVICE_COUNT_DATA_SUCCESS';

export const GET_FOLDER_DETAIL_SERVICE_COUNT_DATA_REQUEST =
  'GET_FOLDER_DETAIL_SERVICE_COUNT_DATA_REQUEST';
export const GET_FOLDER_DETAIL_SERVICE_COUNT_DATA_SUCCESS =
  'GET_FOLDER_DETAIL_SERVICE_COUNT_DATA_SUCCESS';

export const GET_SERVICE_UPDATE_DATA_REQUEST =
  'GET_SERVICE_UPDATE_DATA_REQUEST';
export const GET_SERVICE_UPDATE_DATA_SUCCESS =
  'GET_SERVICE_UPDATE_DATA_SUCCESS';
export const GET_SERVICE_UPDATE_DATA_FAILURE =
  'GET_SERVICE_UPDATE_DATA_FAILURE';

export const GET_USAGE_DATA_SUCCESS = 'GET_USAGE_DATA_SUCCESS';
export const GET_USAGE_DATA_FAILURE = 'GET_USAGE_DATA_FAILURE';

export const GET_FOLDER_LIST_DATA_SUCCESS = 'GET_FOLDER_LIST_DATA_SUCCESS';
export const GET_FOLDER_LIST_DATA_FAILURE = 'GET_FOLDER_LIST_DATA_FAILURE';

export const GET_SERVICE_LIST_DATA_SUCCESS = 'GET_SERVICE_LIST_DATA_SUCCESS';
export const GET_SERVICE_LIST_DATA_FAILURE = 'GET_SERVICE_LIST_DATA_FAILURE';

export const GET_OTHER_CHART_DATA_REQUEST = 'GET_OTHER_CHART_DATA_REQUEST';
export const GET_OTHER_CHART_DATA_FAILURE = 'GET_OTHER_CHART_DATA_FAILURE';

export const GET_FOLDER_DETAIL_OTHER_CHART_DATA_REQUEST =
  'GET_FOLDER_DETAIL_OTHER_CHART_DATA_REQUEST';
export const GET_FOLDER_DETAIL_OTHER_CHART_DATA_FAILURE =
  'GET_FOLDER_DETAIL_OTHER_CHART_DATA_FAILURE';

export const GET_FOLDER_DETAIL_USAGE_DATA_SUCCESS =
  'GET_FOLDER_DETAIL_USAGE_DATA_SUCCESS';
export const GET_FOLDER_DETAIL_USAGE_DATA_FAILURE =
  'GET_FOLDER_DETAIL_USAGE_DATA_FAILURE';

export const GET_FOLDER_DETAIL_SERVICE_LIST_DATA_SUCCESS =
  'GET_FOLDER_DETAIL_SERVICE_LIST_DATA_SUCCESS';
export const GET_FOLDER_DETAIL_SERVICE_LIST_DATA_FAILURE =
  'GET_FOLDER_DETAIL_SERVICE_LIST_DATA_FAILURE';

export const DashboardMetric = [
  'By calls',
  'By batch',
  'By tests run',
  'By CPU hours',
];

export const DashboardMetricWiseCounter = {
  'By calls': ['ApiCalls'],
  'By batch': ['BatchRecordsExecuted'],
  'By tests run': ['TestCasesExecuted'],
  'By CPU hours': ['ApiCallsCalcTime', 'TestBedRunsCalcTime', 'BatchCalcTime'],
  'By service update': ['ServiceAdded', 'ServicesUpdated'],
};

export const TagsColors = ['#6700F6', '#4DBFD0', '#FAC557', '#D9D9D9'];
