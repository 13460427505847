function arrayRemoveFirst<T>(
  array: T[],
  findIndex: (value: T, index: number) => boolean
): T[] {
  const idx = array.findIndex(findIndex);

  if (idx === -1) {
    return array;
  }

  const newArray = [...array];
  newArray.splice(idx, 1);

  return newArray;
}

function arrayReplaceFirst<T>(
  array: T[],
  findIndex: (value: T, index: number) => boolean,
  replaceBy: T extends Function ? (value: T) => T : ((value: T) => T) | T
): T[] {
  if (!array) {
    return array;
  }

  const idx = array.findIndex(findIndex);

  if (idx === -1) {
    return array;
  }

  const replacedByNew =
    typeof replaceBy === 'function' ? replaceBy(array[idx]) : replaceBy;

  const newArray = [...array];

  newArray[idx] = {
    ...newArray[idx],
    ...replacedByNew,
  };
  const result = newArray.sort((a: any, b: any) => {
    return (
      (new Date(b.lastModifiedDate) as any) -
      (new Date(a.lastModifiedDate) as any)
    );
  });
  return result;
}

function arrayInsertAt<T = object>(array: T[], index: number, value: T): T[] {
  if (!array) {
    return array;
  }

  const newArray = [...array];

  newArray.splice(index, 0, value);

  return newArray;
}

const buildOptionsValueArr = (result = []) => {
  return result.map((item, index) => ({
    key: index,
    value: item,
    title: item,
  }));
};

export {
  arrayRemoveFirst,
  arrayReplaceFirst,
  arrayInsertAt,
  buildOptionsValueArr,
};
