import _ from 'lodash';
import { BackgroundJobsConstants, EngineLogsConstant } from '../constants';
import { ApiError, downloadBlob } from '../helpers';
import { EngineService, FileManagerService } from '../services';
import { AlertActions } from './alert.actions';
import {
  AppThunkActionSync,
  EngineLogsAction,
  ViewLogsThunkAction,
} from './types';

const getProductEngineLogs = (
  productName: string,
  serviceName: string,
  versionId: string,
  pagedRequest: DTO.PagedRequest
): ViewLogsThunkAction => async dispatch => {
  try {
    dispatch({
      type: EngineLogsConstant.GET_ENGINE_VIEW_LOGS,
      payload: {
        request: pagedRequest,
      },
    });
    const queryParams = _.cloneDeep(pagedRequest);
    if (pagedRequest.search?.length) {
      queryParams.search = queryParams.search?.filter(
        i => !(i.value === '' || i.value === undefined)
      );
      queryParams.search = queryParams.search?.map(item => {
        if (item.value === null) {
          return {
            ...item,
            value: '',
            id: '',
          };
        }
        const field = item.field.toLowerCase();
        if (field === 'enddate') {
          const endDate = new Date(item.value);
          endDate.setSeconds(59, 999);
          item.value = endDate.toISOString();
        } else if (field === 'startdate') {
          const startDate = new Date(item.value);
          startDate.setSeconds(0, 0);
          item.value = startDate.toISOString();
        }
        return item;
      });
    }
    const { payload, status } = await EngineService.getProductEngineLogs(
      productName,
      serviceName,
      versionId,
      queryParams
    );

    if (status !== 200 || payload.status === 'Error') {
      throw new ApiError(payload);
    }

    dispatch({
      type: EngineLogsConstant.GET_ENGINE_VIEW_LOGS_SUCCESS,
      payload: {
        logs: payload.data,
        total: payload.count,
      },
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: EngineLogsConstant.GET_ENGINE_VIEW_LOGS_FAILURE,
      payload: {
        error: msg,
      },
    });
  }
};

const getProductEngineLogsCount = (
  productName: string,
  serviceName: string,
  versionId: string,
  pagedRequest: DTO.PagedRequest
): ViewLogsThunkAction => async dispatch => {
  try {
    dispatch({
      type: EngineLogsConstant.GET_ENGINE_VIEW_LOGS_COUNT,
      payload: {
        request: pagedRequest,
      },
    });
    const queryParams = _.cloneDeep(pagedRequest);
    if (queryParams.search?.length) {
      queryParams.search = queryParams.search?.filter(
        i => !(i.value === '' || i.value === undefined)
      );
      queryParams.search = queryParams.search?.map(item => {
        if (item.value === null) {
          return {
            ...item,
            value: '',
            id: '',
          };
        }
        const field = item.field.toLowerCase();
        if (field === 'enddate') {
          const endDate = new Date(item.value);
          endDate.setSeconds(59, 999);
          item.value = endDate.toISOString();
        } else if (field === 'startdate') {
          const startDate = new Date(item.value);
          startDate.setSeconds(0, 0);
          item.value = startDate.toISOString();
        }
        return item;
      });
    }
    const {
      payload,
      status,
    } = await EngineService.getProductEngineLogsTotalCount(
      productName,
      serviceName,
      versionId,
      queryParams
    );

    if (status !== 200 || payload.status === 'Error') {
      throw new ApiError(payload);
    }

    dispatch({
      type: EngineLogsConstant.GET_ENGINE_VIEW_LOGS_COUNT_SUCCESS,
      payload: {
        total_count: payload.response_data.total_count,
      },
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: EngineLogsConstant.GET_ENGINE_VIEW_LOGS_COUNT_FAILURE,
      payload: {
        error: msg,
      },
    });
  }
};

const resetEngineLogs = (): EngineLogsAction => ({
  type: EngineLogsConstant.ENGINE_LOGS_RESET,
});
const downloadEngineLogFile = (
  productName: string,
  serviceName: string,
  engineCallId: string,
  useXmlWriter: boolean
): ViewLogsThunkAction => async dispatch => {
  try {
    dispatch({
      type: EngineLogsConstant.DOWNLOAD_ENGINE_LOGS_REQUEST,
    });
    const url = EngineService.getDownloadEngineLogFileUrl(
      productName,
      serviceName,
      engineCallId,
      useXmlWriter
    );

    const blob = await FileManagerService.downloadBlob(url);
    if (blob.payload.blob) {
      downloadBlob(blob.payload.blob, blob.payload.blobName);
    }
    dispatch({
      type: EngineLogsConstant.DOWNLOAD_ENGINE_LOGS_SUCCESS,
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: EngineLogsConstant.DOWNLOAD_ENGINE_LOGS_FAILURE,
      payload: {
        error: msg,
      },
    });
  }
};

const getLogByExecutionHistoryId = (
  executionHistoryId: string
): ViewLogsThunkAction<{
  isSuccess: boolean;
  data: string;
}> => async dispatch => {
  try {
    dispatch({
      type: EngineLogsConstant.GET_LOG_BY_HISTORYID_REQUEST,
    });

    const { status, payload } = await EngineService.getLogByExecutionHistoryId(
      executionHistoryId
    );

    if (status !== 200 || payload.status !== 'Success') {
      throw new ApiError(payload);
    }

    dispatch({
      type: EngineLogsConstant.GET_LOG_BY_HISTORYID_SUCCESS,
      payload: payload.response_data,
    });
    return {
      isSuccess: true,
      data: payload.response_data,
    };
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));
    dispatch({
      type: EngineLogsConstant.GET_LOG_BY_HISTORYID_FAILURE,
      payload: {
        error: msg,
      },
    });
    return {
      isSuccess: false,
      data: '',
    };
  }
};

const getProductEngineLogTotalInputCountsByExecutionHistoryId = (
  executionHistoryId: string
): ViewLogsThunkAction => async dispatch => {
  try {
    dispatch({
      type: EngineLogsConstant.GET_TOTAL_INPUTS_BY_EXECUTIONHISTORYID_REQUEST,
    });

    const {
      status,
      payload,
    } = await EngineService.getProductEngineLogTotalInputCountsByExecutionHistoryId(
      executionHistoryId
    );

    if (status !== 200 || payload.status !== 'Success') {
      throw new ApiError(payload);
    }

    dispatch({
      type: EngineLogsConstant.GET_TOTAL_INPUTS_BY_EXECUTIONHISTORYID_SUCCESS,
      payload,
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));
    dispatch({
      type: EngineLogsConstant.GET_TOTAL_INPUTS_BY_EXECUTIONHISTORYID_FAILURE,
      payload: {
        error: msg,
      },
    });
  }
};

const resetTotalInputCount = (): ViewLogsThunkAction => async dispatch => {
  dispatch({ type: EngineLogsConstant.RESET_TOTAL_INPUTS });
};

const downloadmultipleLogs = (
  productName: string,
  serviceName: string,
  versionId: string,
  outputFormat: string,
  request: {
    v1: DTO.DownloadMultipleLogsRequest;
    v3: DTO.DownloadMultipleLogsRequestV3;
  }
): AppThunkActionSync => async dispatch => {
  dispatch({ type: EngineLogsConstant.DOWNLOAD_MULTIPLE_LOGS_REQUEST });

  try {
    let jobid = '';
    if (outputFormat === 'EXCEL') {
      const { payload } = await EngineService.DownloadMultipleLogs(
        productName,
        serviceName,
        versionId,
        outputFormat,
        request.v1
      );
      if (payload.status !== 'Success') {
        throw new ApiError(payload);
      }
      jobid = payload.data || '';
    } else {
      const { payload, status } = await EngineService.DownloadMultipleLogsV3(
        productName,
        serviceName,
        outputFormat,
        request.v3
      );
      if (status !== 200) {
        throw new ApiError(payload);
      }
      jobid = payload.response_data.job_id || '';
    }

    dispatch({
      type: EngineLogsConstant.DOWNLOAD_MULTIPLE_LOGS_SUCCESS,
      payload: {
        list: jobid,
      },
    });
    dispatch({
      type: EngineLogsConstant.ADD_DOWNLOAD_LOGS_IN_QUEUE,
      payload: {
        jobId: jobid,
      },
    });
    dispatch({
      type: BackgroundJobsConstants.TRIGGER_REFRESH_GBJOBS,
    });
    await dispatch(AlertActions.info('TestLogs.download.successMessage'));
    return true;
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));
    dispatch({
      type: EngineLogsConstant.DOWNLOAD_MULTIPLE_LOGS_FAILURE,
      payload: { error: msg },
    });
    return false;
  }
};

export const EngineLogsActions = {
  getProductEngineLogs,
  resetEngineLogs,
  downloadEngineLogFile,
  downloadmultipleLogs,
  getProductEngineLogsCount,
  getLogByExecutionHistoryId,
  getProductEngineLogTotalInputCountsByExecutionHistoryId,
  resetTotalInputCount,
};
