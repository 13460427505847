import { useState, useEffect } from 'react';
import moment, { Moment } from 'moment';

export type RangeValue = [Moment | null, Moment | null] | null;

const useDashboardDateRangePicker = () => {
  const [dates, setDates] = useState<RangeValue>(null);
  const [whichDateInputFocus, setWhichDateInputFocus] = useState<
    'startDate' | 'endDate' | undefined
  >(undefined);

  const onOpenChange = (open: boolean) => {
    if (open) {
      setDates([null, null]);
    } else {
      setDates(null);
    }
  };

  const disabledDate = (current: Moment) => {
    if (!dates || !current) {
      return false;
    }

    const isFutureDate = current.diff(moment(), 'days', true) > 0;

    const bothDateNullFocusStartDate =
      !dates?.[0] &&
      !dates?.[1] &&
      whichDateInputFocus === 'startDate' &&
      current.diff(moment(), 'days', true) > -6;

    const endDateNullFocusEndDate =
      dates?.[0] &&
      !dates?.[1] &&
      whichDateInputFocus === 'endDate' &&
      ((current.diff(dates[0], 'days', true) < 6 &&
        current.diff(dates[0], 'days', true) >= 0) ||
        current.diff(dates[0], 'days', true) > 29);

    const endDateNullFocusStartDate =
      dates?.[0] &&
      !dates?.[1] &&
      whichDateInputFocus === 'startDate' &&
      current.diff(moment(), 'days', true) > -6;

    const startDateNullFocusStartDate =
      !dates?.[0] &&
      dates?.[1] &&
      whichDateInputFocus === 'startDate' &&
      ((current.diff(dates[1], 'days', true) > -6 &&
        current.diff(dates[1], 'days', true) <= 0) ||
        current.diff(dates[1], 'days', true) < -29);

    return (
      isFutureDate ||
      bothDateNullFocusStartDate ||
      !!endDateNullFocusEndDate ||
      !!endDateNullFocusStartDate ||
      !!startDateNullFocusStartDate
    );
  };

  useEffect(() => {
    const inputElements = document.querySelectorAll('.ant-picker-input input');

    const focusHandler = (event: Event) => {
      const index = Array.from(inputElements).indexOf(event.target as Element);
      const focusedInput = index === 0 ? 'startDate' : 'endDate';
      setWhichDateInputFocus(focusedInput);
    };

    inputElements.forEach(inputElement => {
      inputElement.addEventListener('focus', focusHandler);
    });

    // Cleanup: remove event listeners when the component unmounts
    return () => {
      inputElements.forEach(inputElement => {
        inputElement.removeEventListener('focus', focusHandler);
      });
    };
  }, [dates]);

  return {
    dates,
    whichDateInputFocus,
    onOpenChange,
    disabledDate,
    setDates,
  };
};

export { useDashboardDateRangePicker };
