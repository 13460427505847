import React from 'react';
import { createPortal } from 'react-dom';
import { useIntl } from 'react-intl';
import { EditorBlock } from 'draft-js';
import { camelCase, isNil } from 'lodash';
import { Map, OrderedSet } from 'immutable';
import styled from 'styled-components';
import { defaultPreTagStyling } from '../../constants/richTextEditor.constants';

export const HorizontalRule = () => <hr />;

export const Pagebreak = () => {
  const intl = useIntl();
  return (
    <div style={{ color: '#BBBBBB', margin: '1em 0' }}>
      {intl.formatMessage({ id: 'ApiTester.documentation.editor.pagebreak' })}
    </div>
  );
};

export const ListItem = props => {
  const { block } = props;
  let blockStyles = block
    .getData()
    .filter(
      (v, k) =>
        v !== 'class' && !['depth', 'listStyles', 'listStart'].includes(k)
    )
    .reduce((styles, v, k) => {
      return styles.set(camelCase(k), v);
    }, Map());
  const listType = block.getData().get('listStart') > 0 ? 'ol' : 'ul';
  const depth = block.getDepth() ?? 0;
  const styleMap = {
    0: 'decimal',
    1: 'lower-alpha',
    2: 'lower-roman',
    3: 'upper-alpha',
    4: 'upper-roman',
  };
  const listStyleType =
    blockStyles.get('listStyleType') ??
    (listType === 'ol' ? styleMap[depth] : 'disc');
  blockStyles = blockStyles
    .set('marginLeft', `${depth * 2.5}em`)
    .set('listStyleType', listStyleType);
  let dataAttr = {};
  if (!isNil(block.getData().get('listStart'))) {
    const listStyles = Map(block.getData().get('listStyles'))
      .reduce((styles, v, k) => {
        return (styles as OrderedSet<unknown>).add(`${k}: ${v}`);
      }, OrderedSet())
      .toArray()
      .join('; ');
    dataAttr = {
      'data-start': block.getData().get('listStart'),
      'data-list-style': listStyles,
      'data-pasted-list': true,
    };
  }
  return (
    <ol>
      <li
        className="list-style-type"
        style={blockStyles.toJS()}
        {...dataAttr}
        data-type="list-item"
      >
        <EditorBlock {...props} />
      </li>
    </ol>
  );
};
const StyledTH = styled.td`
  span[data-offset-key] {
    font-size: 10pt;
    font-family: 'GT Walsheim';
  }
`;
const StyledTD = styled.td`
  span[data-offset-key] {
    font-size: 10pt;
    font-family: 'GT Walsheim';
  }
`;

export const Table = props => {
  const {
    block,
    blockProps: { editor },
  } = props;

  if (
    block.getData().get('tablePosition') &&
    !block.getData().get('tableShape')
  ) {
    const position = block.getData().get('tablePosition');
    const target = editor?.editor?.querySelector(
      `[data-position='${position}']`
    );
    if (target) {
      return createPortal(<EditorBlock {...props} />, target);
    }
    return null;
  }

  const data = block.getData();
  const tableKey = data.get('tableKey');
  const tableStyle = Map(data.get('tableStyle'))
    .mapKeys(k => camelCase(k as string))
    .toJS();
  const tableShape = data.get('tableShape');
  const colgroup = data.get('tableColgroup');
  if (Array.isArray(tableShape)) {
    return (
      <table key={tableKey} style={tableStyle} id={tableKey}>
        {colgroup && (
          <colgroup dangerouslySetInnerHTML={{ __html: colgroup }} />
        )}
        <tbody>
          {tableShape.map((row, i) => (
            <tr
              key={i}
              style={
                data.get('rowStyle')[i] &&
                Map(data.get('rowStyle')[i])
                  .mapKeys(k => camelCase(k as string))
                  .toJS()
              }
            >
              {row.map((cell, j) => {
                const cellStyle = Map(cell.style)
                  .mapKeys(k => camelCase(k as string))
                  .toJS();
                if (cell.element === 'th') {
                  return (
                    <StyledTH
                      key={j}
                      style={cellStyle}
                      colSpan={cell.colspan}
                      rowSpan={cell.rowspan}
                      data-position={`${tableKey}-${i}-${j}`}
                    >
                      {!!(i === 0 && j === 0) && <EditorBlock {...props} />}
                    </StyledTH>
                  );
                }
                return (
                  <StyledTD
                    key={j}
                    style={cellStyle}
                    colSpan={cell.colspan}
                    rowSpan={cell.rowspan}
                    data-position={`${tableKey}-${i}-${j}`}
                  >
                    {!!(i === 0 && j === 0) && <EditorBlock {...props} />}
                  </StyledTD>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
    );
  }
  return <EditorBlock {...props} />;
};

export const StyledBlock = props => {
  const { block } = props;
  let blockStyles = block
    .getData()
    .filter(
      (v, k) =>
        v !== 'class' && !['depth', 'listStyles', 'listStart'].includes(k)
    )
    .reduce((styles, v, k) => {
      return styles.set(camelCase(k), v);
    }, Map());
  if (block.getType() === 'code-block') {
    const defaultStyle = Map(defaultPreTagStyling);
    blockStyles = defaultStyle.merge(blockStyles);
  }
  if (block.getDepth()) {
    blockStyles = blockStyles.set('marginLeft', `${block.getDepth() * 2.5}em`);
  }
  return (
    <div style={blockStyles.toJS()}>
      <EditorBlock {...props} />
    </div>
  );
};
