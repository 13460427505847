import { v4 as uuidv4 } from 'uuid';
import { ServiceAction } from '../actions/types/service.actions.types';
import { ServiceConstants } from '../constants';
import {
  checkDifference,
  getLeftTime,
  getUploadLogs,
} from '../helpers/service-helper';

export const initialState: STATES.ServiceState = {
  isLoading: false,
  isFileExistsLoading: false,
  isFileUploadLoading: false,
  isFilePublishLoading: false,
  isModalOpen: false,
  isUploadLogsLoading: false,
  isFileExists: null,
  flowTriggered: 0,
  draggedFile: null,
  fileName: null,
  fileNameTruncated: null,
  virtualFileName: null,
  newPublishVersionId: null,
  randomValue: null,
  progressPercent: 0,
  progressStatus: 'active',
  progressTimeLeft: 0,
  xhrRef: null,
  uploadLogs: {
    errors: [],
    warnings: [],
    infos: [],
    tests: [],
  },
  uploadFailLogs: {
    errors: [],
    warnings: [],
    infos: [],
    tests: [],
  },
  nodegenFailLogs: {
    errors: [],
    warnings: [],
    infos: [],
    tests: [],
  },
  updateFileDetails: {
    id: null,
    name: null,
    latestRevision: null,
    tags: null,
    latestVersionId: null,
    lastModifiedDate: null,
    effectiveStartDate: null,
    effectiveEndDate: null,
  },
  uploadedServiceDetails: {
    current_statistics: null,
    upload_file_name: null,
    compiler_type: '',
    engine_file_documentid: '',
    hours_saved: 0,
    lines_of_code: 0,
    no_of_cellswithdata: 0,
    no_of_formulas: 0,
    no_of_inputs: 0,
    no_of_outputs: 0,
    no_of_sheets: 0,
    nodegen_compilation_jobid: '',
    original_file_documentid: '',
    warnings: [],
  },
  showVersionDiff: {
    isDifference: false,
    noOfSheets: { type: 'neutral', count: 0 },
    noOfInputs: { type: 'neutral', count: 0 },
    noOfOutputs: { type: 'neutral', count: 0 },
    noOfFormulas: { type: 'neutral', count: 0 },
    noOfCellswithdata: { type: 'neutral', count: 0 },
  },
  uploadedServiceFailDetails: {
    isFailed: false,
  },
  nodegenCompilationDetails: {
    isFailed: false,
    status: null,
    last_error_message: null,
    progress: 0,
  },
};

export const service = (
  state = initialState,
  action: ServiceAction
): STATES.ServiceState => {
  switch (action.type) {
    case ServiceConstants.DRAGGED_FILE_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case ServiceConstants.DRAGGED_FILE_SUCCESS: {
      const {
        flowTriggered,
        updateFileDetails,
        draggedFile,
        isModalOpen,
        fileName,
      } = action.payload;
      const timeLeft = draggedFile ? getLeftTime(draggedFile[0]['size']) : 0;
      const finalFileName = (
        fileName ?? draggedFile[0]['name'].replace(/\.[^/.]+$/, '')
      )
        .toString()
        .trim();
      const newState = {
        ...state,
        isModalOpen,
        flowTriggered,
        draggedFile,
        fileName: finalFileName,
        progressTimeLeft: timeLeft,
      };
      if (finalFileName.length > 50) {
        newState['fileNameTruncated'] = finalFileName.substring(0, 50).trim();
      }
      if (updateFileDetails) {
        newState['updateFileDetails'] = updateFileDetails;
      }
      return newState;
    }

    case ServiceConstants.TOGGLE_UPLOADMODAL_SUCCESS: {
      const { isModalOpen, resetFile, flowTriggered, random } = action.payload;
      const newState = {
        ...state,
        draggedFile: resetFile ? null : state.draggedFile,
        isModalOpen,
      };
      if (resetFile) {
        newState['draggedFile'] = null;
      }
      if (typeof flowTriggered === 'number') {
        newState['flowTriggered'] = flowTriggered;
      }
      if (flowTriggered === 5) {
        newState['virtualFileName'] = `${state.fileName}_${random ?? uuidv4()}`;
        newState['updateFileDetails'] = initialState.updateFileDetails;
      }
      return newState;
    }

    case ServiceConstants.CHECK_SERVICE_EXISTS_REQUEST: {
      return {
        ...state,
        isFileExists: null,
        isFileExistsLoading: true,
      };
    }

    case ServiceConstants.CHECK_SERVICE_EXISTS_SUCCESS: {
      const {
        id,
        name,
        is_exists,
        version,
        tags,
        last_modified_date,
        latest_version_id,
        effective_start_date,
        effective_end_date,
      } = action.payload;
      const newState = {
        ...state,
        isFileExists: is_exists,
        isFileExistsLoading: false,
      };
      if (is_exists) {
        newState['updateFileDetails'] = {
          id,
          name,
          tags,
          latestRevision: version,
          latestVersionId: latest_version_id,
          lastModifiedDate: last_modified_date,
          effectiveStartDate: effective_start_date,
          effectiveEndDate: effective_end_date,
        };
      }
      return newState;
    }

    case ServiceConstants.CHECK_SERVICE_EXISTS_FAILURE: {
      return {
        ...state,
        isFileExistsLoading: false,
        fileName: null,
      };
    }

    case ServiceConstants.UPLOAD_SERVICE_REQUEST: {
      return {
        ...state,
        isFileUploadLoading: true,
      };
    }
    case ServiceConstants.UPLOAD_SERVICE_SUCCESS: {
      const res = action.payload;
      const newState = {
        ...state,
        isFileUploadLoading: false,
        uploadedServiceDetails: res,
      };
      if (res.current_statistics) {
        newState['showVersionDiff'] = checkDifference(
          res.current_statistics,
          res
        );
      }
      return newState;
    }
    case ServiceConstants.UPLOAD_SERVICE_FAILURE: {
      const { uploadLogs } = action.payload;
      return {
        ...state,
        isFileUploadLoading: false,
        progressStatus: 'exception',
        progressPercent: 100,
        uploadedServiceFailDetails: {
          isFailed: true,
        },
        uploadFailLogs: uploadLogs,
        uploadLogs: getUploadLogs(state.nodegenFailLogs, uploadLogs),
      };
    }

    case ServiceConstants.GET_UPOAD_LOGS_REQUEST: {
      state.xhrRef?.abort();
      return {
        ...state,
        isUploadLogsLoading: true,
      };
    }

    case ServiceConstants.GET_UPOAD_LOGS_SUCCESS: {
      const { uploadLogs } = action.payload;
      return {
        ...state,
        isUploadLogsLoading: false,
        nodegenFailLogs: uploadLogs,
        uploadLogs: getUploadLogs(state.uploadFailLogs, uploadLogs),
        uploadedServiceDetails: {
          ...state.uploadedServiceDetails,
          hours_saved: uploadLogs.hours_saved,
          lines_of_code: uploadLogs.lines_of_code,
        },
      };
    }

    case ServiceConstants.GET_UPOAD_LOGS_FAILURE: {
      return {
        ...state,
        isUploadLogsLoading: false,
      };
    }

    case ServiceConstants.GET_COMPILATION_PROGESS_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case ServiceConstants.GET_COMPILATION_PROGESS_SUCCESS: {
      const { status, progress } = action.payload;
      const newState = {
        ...state,
        nodegenCompilationDetails: action.payload,
      };
      if (status === 'Fail') {
        newState['progressPercent'] = 100;
        newState['progressStatus'] = 'exception';
        newState['nodegenCompilationDetails']['isFailed'] = true;
      } else if (status === 'Success') {
        newState['progressPercent'] = 100;
        newState['progressStatus'] = '';
        newState['nodegenCompilationDetails']['isFailed'] = false;
      } else if (state.progressPercent < 90) {
        newState['progressPercent'] = state.progressPercent + progress;
      }

      return newState;
    }

    case ServiceConstants.GET_COMPILATION_PROGESS_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case ServiceConstants.PROGRESS_BAR_UPDATE: {
      const { progress, status } = action.payload;
      return {
        ...state,
        progressPercent: progress ?? state.progressPercent,
        progressStatus: status ?? state.progressStatus,
      };
    }

    case ServiceConstants.PUBLISH_SERVICE_REQUEST: {
      return {
        ...state,
        isFilePublishLoading: true,
      };
    }

    case ServiceConstants.PUBLISH_SERVICE_SUCCESS: {
      const { version_id, random } = action.payload;
      return {
        ...state,
        isFilePublishLoading: false,
        newPublishVersionId: version_id,
        randomValue: random ?? +new Date(),
      };
    }

    case ServiceConstants.PUBLISH_SERVICE_FAILURE: {
      return {
        ...state,
        isFilePublishLoading: false,
      };
    }

    case ServiceConstants.ADD_SERVICE_UPLOAD_XHR_REF: {
      const { xhrRef } = action.payload;
      return {
        ...state,
        xhrRef,
      };
    }

    case ServiceConstants.RESET_ALL: {
      state.xhrRef?.abort();
      return initialState;
    }

    default:
      return state;
  }
};
