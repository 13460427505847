import { useParams, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { ProductsActions } from '../actions';

interface ProductRouteParams {
  productId: string;
}

const productParamsSelector = ({
  products: { selectedProduct, selectedProductName, isLoadingProductDetail },
}: STATES.AppState) => ({
  selectedProduct,
  selectedProductName,
  isLoadingProductDetail,
});

const useProductParams = () => {
  const dispatch = useDispatch();
  const pathname = useLocation().pathname;
  const isShared = pathname.startsWith('/shared');
  const { productId } = useParams<ProductRouteParams>();
  const {
    selectedProduct,
    selectedProductName,
    isLoadingProductDetail,
  } = useSelector(productParamsSelector);

  useEffect(() => {
    if (
      !productId ||
      (isLoadingProductDetail && selectedProductName === productId)
    ) {
      return;
    }

    if (!selectedProduct || selectedProduct.name !== productId) {
      dispatch(ProductsActions.getProductDetail(productId));
    }
  }, [
    selectedProduct,
    productId,
    dispatch,
    isShared,
    isLoadingProductDetail,
    selectedProductName,
  ]);

  return {
    productId,
    isShared,
    selectedProduct,
  };
};

export { useProductParams };
