import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { UserConstants } from '../constants';
import { ApiError, getValidRoleAndGroup } from '../helpers';
import {
  decodeToken,
  getDisplayName,
  getLogoutUrl,
  getUserAuth,
  getUserEmailId,
  getUserName,
  isAdmin,
  isSuperuser,
} from '../helpers/user-auth';
import { InsightsService, UserService } from '../services';
import { AlertActions } from './alert.actions';
import { UserAction, UserThunkAction, UserThunkActionSync } from './types';
import {
  getChallenge,
  getRandomString,
  getVerifier,
} from '../helpers/crypto-helper';

const redirectToSingleSignOnPage = (
  issuerConfig: DTO.IssuerConfiguration
): UserThunkActionSync => _ => {
  const userAuth = getUserAuth(issuerConfig);
  const nonce = uuidv4();
  const codeVerifier = getVerifier();
  sessionStorage.setItem('current_code_verifier', codeVerifier);
  const codeChallenge = getChallenge(codeVerifier);
  sessionStorage.setItem('current_nonce', nonce);
  // https://github.com/mulesoft-labs/js-client-oauth2/issues/91 => PKCE flow
  const uri = userAuth[issuerConfig.authFlow ?? 'token'].getUri({
    state: getRandomString(),
    query: {
      code_challenge: codeChallenge,
      code_challenge_method: 'S256',
    },
  });
  try {
    const url = new URL(uri); // Replace with your URI
    url.searchParams.append('nonce', nonce); // Replace with your parameter name and value
    const modifiedURI = url.toString();
    window.location.href = modifiedURI;
  } catch (e) {
    console.error(e);
  }
};

const exchangeToken = (
  urlWithAuthorizationCode: string,
  issuerConfiguration: DTO.IssuerConfiguration
): UserThunkAction<{
  accessToken?: string;
  idToken?: string;
  refreshToken?: string;
  error?: string;
  errorDesc?: string;
}> => async _ => {
  try {
    const userAuth = getUserAuth(issuerConfiguration);
    const user = await userAuth[
      issuerConfiguration.authFlow ?? 'token'
    ].getToken(urlWithAuthorizationCode, {
      body: {
        code_verifier: sessionStorage.getItem('current_code_verifier'),
      },
    });
    sessionStorage.removeItem('current_code_verifier');
    if (!user) {
      throw new ApiError({ error_code: 'LOGIN_FAILURE' });
    }

    const { accessToken, refreshToken, data } = user;
    const { id_token: idToken } = data;

    return {
      accessToken,
      idToken,
      refreshToken,
    };
  } catch (error) {
    return { errorDesc: error.error_description, error: error.message };
  }
};

const validateTokens = (
  accessToken: string,
  idToken: string,
  refreshToken: string
): UserThunkAction => async dispatch => {
  try {
    dispatch({
      type: UserConstants.LOGIN_REQUEST,
      payload: { remember: false },
    });

    let decodedJwt = await decodeToken(idToken);

    let issuerConfig: DTO.IssuerConfiguration | null = null;
    if (decodedJwt && decodedJwt.iss) {
      issuerConfig = await UserService.getIssuerConfiguration(decodedJwt.iss);
    }

    if (decodedJwt && issuerConfig && !issuerConfig.verificationEnabled) {
      decodedJwt = { ...decodedJwt, groups: issuerConfig.groups };
    }

    const nonce = sessionStorage.getItem('current_nonce');
    if (decodedJwt && decodedJwt.nonce !== nonce) {
      dispatch({
        type: UserConstants.LOGIN_FAILURE,
        payload: { reason: 'InvalidNonce' },
      });
      return;
    }

    const aed = Number(decodedJwt?.account_end_date) || false;
    if (aed && moment(aed).isValid()) {
      if (aed < +new Date()) {
        dispatch({
          type: UserConstants.LOGIN_FAILURE,
          payload: { reason: 'InactiveUser' },
        });
        return;
      }
    }
    if (
      decodedJwt &&
      decodedJwt.groups &&
      getValidRoleAndGroup(decodedJwt.groups).group
    ) {
      sessionStorage.removeItem('current_nonce');
      dispatch({
        type: UserConstants.LOGIN_SUCCESS,
        payload: {
          groups: decodedJwt.groups,
          userAuth: {
            accessToken,
            idToken,
            refresh_token: refreshToken,
            username: getUserName(decodedJwt, issuerConfig),
          },
          userInfo: {
            displayName: getDisplayName(decodedJwt, issuerConfig),
            userEmailID: getUserEmailId(decodedJwt, issuerConfig),
          },
          userId: decodedJwt.sub,
          isAdmin: isAdmin(decodedJwt),
          isSuperuser: isSuperuser(decodedJwt, issuerConfig),
          tenant: issuerConfig?.tenant ?? decodedJwt.realm,
          tenantRelation: decodedJwt?.relation,
        },
      });
      await InsightsService.incrementLoginCounter();
      return;
    }

    dispatch({
      type: UserConstants.LOGIN_FAILURE,
      payload: { reason: 'UnauthorizedUserGroup' },
    });
  } catch (error) {
    dispatch({
      type: UserConstants.LOGIN_FAILURE,
      payload: { reason: 'Other' },
    });
  }
};

const skipLoginFlow = (): UserThunkAction => async dispatch => {
  try {
    dispatch({
      type: UserConstants.LOGIN_REQUEST,
      payload: { remember: false },
    });

    const { payload } = await UserService.getUserInformation();
    dispatch({
      type: UserConstants.LOGIN_SUCCESS,
      payload: {
        groups: [],
        userAuth: {
          accessToken: '',
          idToken: '',
          refresh_token: '',
          username: payload.data?.username ?? 'Admin',
        },
        userInfo: {
          displayName: payload.data?.username ?? 'Admin',
          userEmailID: payload.data?.groups.join(',') ?? 'Admin',
        },
        userId: payload.data?.username ?? 'Admin',
        isAdmin: true,
        isSuperuser: true,
        tenant: '',
        tenantRelation: '',
      },
    });

    return;
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: UserConstants.LOGIN_FAILURE,
      payload: { error: msg, reason: 'Other' },
    });
  }
};

const logout = (issuerConfig: DTO.IssuerConfiguration | null): UserAction => {
  const logOutUrl = getLogoutUrl(issuerConfig);
  localStorage.removeItem('Tenant');
  localStorage.removeItem('enableTagsInfoAlertIsShown');
  if ('serviceWorker' in navigator) {
    (navigator as any).serviceWorker.controller.postMessage(
      'logoutFromAllTabs'
    );
  }
  InsightsService.incrementFeatureCounter({
      folder_name: '',
      service_name: '',
      counter_event: 'Logout',
      total_counter_event_name: '',
      count: 1,
      batch_id: '',
    });
  return {
    type: UserConstants.LOGOUT,
    payload: { logOutUrl: logOutUrl ?? '' },
  };
};

const loggedOut = (): UserAction => {
  return { type: UserConstants.LOGGED_OUT };
};

const loginClean = (): UserAction => {
  return { type: UserConstants.LOGIN_CLEAN };
};

const getIssuerConfiguration = (
  issuer?: string
): UserThunkAction => async dispatch => {
  try {
    dispatch({ type: UserConstants.ISSUER_CONFIGURATIONS_REQUEST });
    const issuerConfig = await UserService.getIssuerConfiguration(issuer);
    dispatch({
      type: UserConstants.ISSUER_CONFIGURATIONS,
      payload: { issuerConfig },
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: UserConstants.ISSUER_CONFIGURATIONS_FAILURE,
      payload: { error: msg, reason: 'Other' },
    });
  }
};

export const UserActions = {
  logout,
  loggedOut,
  loginClean,
  validateTokens,
  exchangeToken,
  getIssuerConfiguration,
  skipLoginFlow,
  redirectToSingleSignOnPage,
};
