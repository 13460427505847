import { EngineConstants, ProductsConstants } from '../constants';
import {
  ApiError,
  arrayRemoveFirst,
  downloadBlob,
  getErrorMessage,
  getExcelObject,
  isCustomErrorCode,
  jsonParse,
} from '../helpers';
import { getUploadWarnings } from '../helpers/service-helper';
import {
  EngineService,
  FileManagerService,
  serviceFileService,
} from '../services';
import { AlertActions } from './alert.actions';
import { ProductsActions } from './products.actions';
import {
  EngineAction,
  EngineThunkAction,
  EngineThunkActionSync,
} from './types';

const getProductEngines = (
  productName: string,
  data: DTO.GetProductEnginesRequest,
  fetechProductDetail = false
): EngineThunkAction => async dispatch => {
  try {
    dispatch({
      type: EngineConstants.GET_PRODUCT_ENGINES_REQUEST,
      payload: { productName, ...data },
    });

    const { payload, status } = await EngineService.getProductEngines(
      productName,
      data
    );

    if (status !== 200 || payload.status === 'Error') {
      throw new ApiError(payload);
    }

    dispatch({
      type: EngineConstants.GET_PRODUCT_ENGINES_SUCCESS,
      payload: {
        productName,
        ...data,
        engines: payload.data,
        total: payload.count,
      },
    });

    if (fetechProductDetail) {
      dispatch(ProductsActions.getProductDetail(productName, true));
    }
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: EngineConstants.GET_PRODUCT_ENGINES_FAILURE,
      payload: {
        productName,
        error: msg,
      },
    });
  }
};

const getProductEngineDetails = (
  productName: string,
  serviceName: string,
  versionId?: string,
  revision?: string,
  makeSelectedEngine = true,
  firstTimeApiTesterLoad = false,
  enableActiveService = true
): EngineThunkAction => async (dispatch, getState) => {
  try {
    const {
      productEngines: {
        isLoadingDetails,
        selectedEngineName,
        selectedVersionId,
      },
      apiTester: { persistServiceParameters },
    } = getState();

    if (
      isLoadingDetails &&
      selectedEngineName === serviceName &&
      selectedVersionId === versionId
    ) {
      return;
    }

    dispatch({
      type: EngineConstants.GET_PRODUCT_ENGINE_DETAILS_REQUEST,
      payload: {
        productName,
        serviceName,
        versionId,
        revision,
      },
    });

    const { payload, status } = await EngineService.getProductEngineDetails(
      productName,
      serviceName,
      versionId,
      enableActiveService
    );

    if (status !== 200 || payload.status === 'Error') {
      const error = getErrorMessage(payload, getState());
      if (error) {
        throw new ApiError({
          message: error,
        });
      }
      throw new ApiError(payload);
    }

    if (makeSelectedEngine) {
      dispatch({
        type: EngineConstants.GET_PRODUCT_ENGINE_DETAILS_SUCCESS,
        payload: {
          productName,
          serviceName,
          engine: payload.data,
          versionId,
        },
      });
    } else {
      dispatch({
        type: EngineConstants.GET_PRODUCT_ENGINE_DETAILS_SUCCESS_NO_SELECT,
        payload: {
          productName,
          serviceName,
          engine: payload.data,
          versionId,
        },
      });
    }

    if (firstTimeApiTesterLoad) {
      const engine = payload.data;
      const requestedServiceCategories =
        engine &&
        engine?.serviceCategories &&
        engine?.serviceCategories.length > 0
          ? persistServiceParameters.requestedServiceCategory || '#Default#'
          : '';
      const requestedOutput = persistServiceParameters.requestedOutput;
      // SP-421 : API Tester Test Xinput 1xN>1 malformed JSON array
      const modifiedEngine = engine;
      modifiedEngine.xlInputs = modifiedEngine.xlInputs.map(input => {
        if (input.rows === 1 && input.columns > 1) {
          return {
            ...input,
            value: jsonParse(input.value),
          };
        }
        return input;
      });
      const result = getExcelObject(
        modifiedEngine,
        productName,
        serviceName,
        requestedServiceCategories,
        requestedOutput || ''
      );
      dispatch({
        type: EngineConstants.API_TEST_EXECUTE_SUCCESS_NO_APICALL,
        payload: {
          result,
          executApiVersion: 'V1',
        },
      });
    }
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: EngineConstants.GET_PRODUCT_ENGINE_DETAILS_FAILURE,
      payload: {
        productName,
        serviceName,
        error: msg,
        versionId,
      },
    });
  }
};

const getProductEngineDetailsByVersionId = (
  productName: string,
  serviceName: string,
  versionId?: string,
  revision?: string,
  makeSelectedEngine = true,
  firstTimeApiTesterLoad = false
): EngineThunkAction => async (dispatch, getState) => {
  try {
    const {
      productEngines: {
        isLoadingDetails,
        selectedEngineName,
        selectedVersionId,
      },
      apiTester: { persistServiceParameters },
    } = getState();

    if (
      isLoadingDetails &&
      selectedEngineName === serviceName &&
      selectedVersionId === versionId
    ) {
      return;
    }

    dispatch({
      type: EngineConstants.GET_PRODUCT_ENGINE_DETAILS_REQUEST,
      payload: {
        productName,
        serviceName,
        versionId,
        revision,
      },
    });

    const {
      payload,
      status,
    } = await EngineService.getProductEngineDetailsByVersionId(
      productName,
      serviceName,
      versionId
    );

    if (status !== 200 || payload.status === 'Error') {
      const error = getErrorMessage(payload, getState());
      if (error) {
        throw new ApiError({
          message: error,
        });
      }
      throw new ApiError(payload);
    }

    if (makeSelectedEngine) {
      dispatch({
        type: EngineConstants.GET_PRODUCT_ENGINE_DETAILS_SUCCESS,
        payload: {
          productName,
          serviceName,
          engine: payload.data,
          versionId,
        },
      });
    } else {
      dispatch({
        type: EngineConstants.GET_PRODUCT_ENGINE_DETAILS_SUCCESS_NO_SELECT,
        payload: {
          productName,
          serviceName,
          engine: payload.data,
          versionId,
        },
      });
    }

    if (firstTimeApiTesterLoad) {
      const engine = payload.data;
      const requestedServiceCategories =
        engine &&
        engine?.serviceCategories &&
        engine?.serviceCategories.length > 0
          ? persistServiceParameters.requestedServiceCategory || '#Default#'
          : '';
      const requestedOutput = persistServiceParameters.requestedOutput;
      // SP-421 : API Tester Test Xinput 1xN>1 malformed JSON array
      const modifiedEngine = engine;
      modifiedEngine.xlInputs = modifiedEngine.xlInputs.map(input => {
        if (input.rows === 1 && input.columns > 1) {
          return {
            ...input,
            value: jsonParse(input.value),
          };
        }
        return input;
      });
      const result = getExcelObject(
        modifiedEngine,
        productName,
        serviceName,
        requestedServiceCategories,
        requestedOutput || ''
      );
      dispatch({
        type: EngineConstants.API_TEST_EXECUTE_SUCCESS_NO_APICALL,
        payload: {
          result,
          executApiVersion: 'V1',
        },
      });
    }
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: EngineConstants.GET_PRODUCT_ENGINE_DETAILS_FAILURE,
      payload: {
        productName,
        serviceName,
        error: msg,
        versionId,
      },
    });
  }
};

const updateProperties = (
  productName: string,
  serviceName: string,
  data: DTO.UpdateEngineRequest,
  version_difference: string
): EngineThunkAction<boolean> => async (dispatch, getState) => {
  try {
    dispatch({
      type: EngineConstants.UPDATE_ENGINE_PROPS_REQUEST,
    });

    const getInputOutput = inputOutput => {
      return inputOutput.map(({ name, description }) => ({
        originalName: name,
        newName: name,
        description,
      }));
    };
    const updateData: DTO.UpdateProductEnginePropsRequest = {
      BookProperties: data.properties,
      effectiveEndDate: data.effectiveEndDate,
      effectiveStartDate: data.effectiveStartDate,
      XlInputs: getInputOutput(data.inputs),
      XlOutputs: getInputOutput(data.outputs),
      Id: data.id,
      Version: data.version,
      tags: data.tags,
    };

    updateData.BookProperties = arrayRemoveFirst(
      updateData.BookProperties,
      prop => prop.name === 'ServiceName'
    );
    const { payload, status } = await EngineService.updateProductEngine(
      productName,
      serviceName,
      updateData,
      version_difference
    );

    if (status !== 200 || payload.status === 'Error') {
      if (payload.error?.message) {
        const {
          language: { intl },
        } = getState();

        throw new ApiError({
          message: intl.formatMessage({ id: payload.error.message }),
        });
      }
      throw new ApiError(payload);
    }

    dispatch({
      type: EngineConstants.UPDATE_ENGINE_PROPS_SUCCESS,
      payload: {
        serviceName,
        jobId: payload?.response_data?.jobid,
      },
    });

    payload.message && dispatch(AlertActions.success(payload.message));
    return payload?.response_data?.jobid;
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: EngineConstants.UPDATE_ENGINE_PROPS_FAILURE,
      payload: {
        serviceName,
        error: msg,
      },
    });
    return false;
  }
};

const recompileNodegen = (
  productName: string,
  serviceName: string,
  data: DTO.RecompileNodegenRequest
): EngineThunkAction<boolean | null> => async dispatch => {
  try {
    dispatch({
      type: EngineConstants.RECOMPILE_NODEGEN_REQUEST,
    });

    const recompileData: DTO.RecompileNodegenRequestV3 = {
      request_data: data,
    };
    const { payload, status } = await EngineService.recompileNodegen(
      productName,
      serviceName,
      recompileData
    );

    if (status !== 200 || payload.status === 'Error') {
      if (payload.error?.message?.includes(ProductsConstants.UNAUTHORIZED)) {
        throw new Error(ProductsConstants.UNAUTHORIZED);
      }
      throw new ApiError(payload);
    }
    dispatch({
      type: EngineConstants.RECOMPILE_NODEGEN_SUCCESS,
      payload: {
        jobId: payload?.response_data?.jobId || null,
      },
    });
    return true;
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: EngineConstants.RECOMPILE_NODEGEN_FAILURE,
      payload: {
        serviceName,
        error: msg,
      },
    });
    return false;
  }
};

const getUploadLogs = (
  folder: string,
  service: string
): EngineThunkAction => async (dispatch, getState) => {
  dispatch({ type: EngineConstants.GET_RECOMPILE_LOGS_REQUEST });
  try {
    const { status, payload } = await serviceFileService.getUploadLogs(
      folder,
      service,
      true
    );
    const logPayload: DTO.UploadLogs = payload?.response_data;
    if (payload?.response_data && payload?.response_data?.warnings) {
      const {
        language: { intl },
      } = getState();
      const formattedWarns = payload.response_data.warnings.map(c => {
        return { key: c.key, value: [...(c.values as string[])] };
      });
      const warns = getUploadWarnings(formattedWarns, intl);
      logPayload['warnings'] = warns;
    }
    dispatch({
      type: EngineConstants.GET_RECOMPILE_LOGS_SUCCESS,
      payload: {
        uploadLogs: logPayload,
      },
    });
    if (status !== 200) {
      throw new ApiError({ errorCode: payload.error.message });
    }
    return logPayload;
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));
    dispatch({
      type: EngineConstants.GET_RECOMPILE_LOGS_FAILURE,
      payload: { error: msg },
    });
  }
};

const getCompilationProgess = (
  folderName: string,
  serviceName: string,
  nodegenCompilationJobid: string
): EngineThunkAction => async dispatch => {
  dispatch({ type: EngineConstants.GET_RECOMPILE_PROGESS_REQUEST });
  try {
    const { status, payload } = await serviceFileService.getCompilationProgess(
      folderName,
      serviceName,
      nodegenCompilationJobid
    );
    if (status !== 200) {
      throw new ApiError({ errorCode: payload.error.message });
    }
    dispatch({
      type: EngineConstants.GET_RECOMPILE_PROGESS_SUCCESS,
      payload: {
        ...payload.response_data,
        progress: Math.floor(Math.random() * 10 + 1),
      },
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));
    dispatch({
      type: EngineConstants.GET_RECOMPILE_PROGESS_FAILURE,
      payload: { error: msg },
    });
  }
};

const updateEngineFavorite = (
  productName: string,
  engine: string,
  favorite: boolean
): EngineThunkAction => async dispatch => {
  try {
    dispatch({
      type: EngineConstants.UPDATE_ENGINE_FAVORITE_REQUEST,
      payload: {
        serviceName: engine,
        favorite,
      },
    });

    const { status, payload } = await EngineService.markFavorite(
      productName,
      engine,
      favorite
    );

    if (status !== 200 || payload.status !== 'Success') {
      throw new ApiError(payload);
    }

    dispatch({
      type: EngineConstants.UPDATE_ENGINE_FAVORITE_SUCCESS,
      payload: {
        serviceName: engine,
        favorite,
      },
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: EngineConstants.UPDATE_ENGINE_FAVORITE_FAILURE,
      payload: {
        serviceName: engine,
        error: msg,
      },
    });
  }
};

const updateReleaseNote = (releaseNote: string): EngineAction => ({
  type: EngineConstants.UPDATE_RELEASE_NOTE,
  payload: {
    releaseNote,
  },
});

const increaseExecuteProgress = () => {
  return {
    type: EngineConstants.ADD_ENGINE_EXECUTE_PROGRESS,
  };
};

const resetAddEngine = (): EngineAction => ({
  type: EngineConstants.ADD_ENGINE_RESET,
});

const cancelPublish = async (product: string, service: string) => {
  try {
    await EngineService.cancelPublish(product, service);
  } catch {
    // blank catch, fire and forget
  }
};

const updateEngineProps = (data: DTO.UpdateEngineRequest): EngineAction => ({
  type: EngineConstants.UPDATE_ENGINE_PROPS,
  payload: { data },
});
const cancelPublishEngine = (): EngineAction => ({
  type: EngineConstants.CANCEL_PUBLISH_ENGINE,
});

const cancelUpdateConfirm = (): EngineAction => ({
  type: EngineConstants.CANCEL_UPDATE_CONFIRM,
});

const resetProductEngines = (): EngineAction => ({
  type: EngineConstants.PRODUCT_ENGINES_RESET,
});

const resetProductEngineSelection = (): EngineAction => ({
  type: EngineConstants.PRODUCT_ENGINE_SELECTION_RESET,
});

const deleteEngine = (
  productName: string,
  serviceName: string,
  isActiveService: boolean | undefined = undefined
): EngineThunkAction => async dispatch => {
  dispatch({
    type: EngineConstants.DELETE_ENGINE_REQUEST,
    payload: { productName, serviceName },
  });

  try {
    const { status, payload } = await EngineService.deleteEngine(
      productName,
      serviceName
    );

    if (status !== 200 || payload.status !== 'Success') {
      throw new ApiError(payload);
    }

    dispatch({
      type: EngineConstants.DELETE_ENGINE_SUCCESS,
      payload: {
        productName,
        serviceName,
        isActiveService,
      },
    });

    dispatch(
      AlertActions.success('ProductEngines.menu.deleteEngine.success', {
        serviceName,
      })
    );
  } catch (error) {
    let msg = '';
    if (
      error instanceof ApiError &&
      error.reason === ProductsConstants.UNAUTHORIZED
    ) {
      msg = await dispatch(AlertActions.error(error.reason));
    } else {
      msg = await dispatch(AlertActions.error(error));
    }

    dispatch({
      type: EngineConstants.DELETE_ENGINE_FAILURE,
      payload: {
        productName,
        serviceName,
        error: msg,
      },
    });
  }
};

const executeProductEngine = (
  productName: string,
  serviceName: string,
  data: DTO.ExcelEngineRequest | DTO.ExcelEngineRequestV3,
  apiVersion = 'V1'
): EngineThunkAction => async dispatch => {
  try {
    dispatch({
      type: EngineConstants.API_TEST_EXECUTE,
      payload: {
        apiVersion,
      },
    });

    const { status, payload } = await EngineService.executeProductEngine(
      productName,
      serviceName,
      data,
      apiVersion
    );

    if (apiVersion === 'V3') {
      if (status !== 200 || payload.status === 'Error') {
        if (payload.error) {
          throw new ApiError({ error_code: payload.error.message });
        } else {
          throw new ApiError(payload);
        }
      } else {
        dispatch({
          type: EngineConstants.API_TEST_EXECUTE_SUCCESS,
          payload: {
            result: {
              response_data: {
                ...((payload as DTO.ApiTestResponseV3)?.response_data || {}),
                inputs: {
                  ...((data as DTO.ExcelEngineRequestV3)?.request_data
                    ?.inputs || {}),
                },
              },
              response_meta: (payload as DTO.ApiTestResponseV3).response_meta,
            },
            apiVersion,
          },
        });
        if (payload.status === 'Warning') {
          throw new ApiError({ errorCode: EngineConstants.NO_ACTIVE_VERSION });
        }
      }
    } else if (apiVersion === 'V1') {
      if (status !== 200 || payload.status === 'Error') {
        if (payload.errorCode === EngineConstants.NO_ACTIVE_VERSION) {
          throw new ApiError({ errorCode: payload.errorCode });
        } else {
          throw new ApiError(payload);
        }
      } else {
        dispatch({
          type: EngineConstants.API_TEST_EXECUTE_SUCCESS,
          payload: {
            result: {
              ...(payload as DTO.ExecuteProductEngineResponse).data,
              Inputs: (data as DTO.ExcelEngineRequest).Inputs,
              SolveInputs: (data as DTO.ExcelEngineRequest).Solves,
              ReportInput: (data as DTO.ExcelEngineRequest).Reports,
              ImageInput: (data as DTO.ExcelEngineRequest).Images,
              ServiceCategories:
                (payload as DTO.ExecuteProductEngineResponse).data
                  .ServiceCategories ||
                ((data as DTO.ExcelEngineRequest)?.ServiceCategories &&
                (data as DTO.ExcelEngineRequest)?.ServiceCategories !==
                  undefined &&
                (data as DTO.ExcelEngineRequest)?.ServiceCategories?.length ===
                  0
                  ? (data as DTO.ExcelEngineRequest)?.ServiceCategories
                  : undefined),
            } as DTO.ApiTestResponse,
            apiVersion,
          },
        });
        if (
          payload.status === 'Warning' &&
          payload.errorCode === EngineConstants.ENGINE_INACTIVE_WARNING
        ) {
          throw new ApiError({ errorCode: EngineConstants.NO_ACTIVE_VERSION });
        }
      }
    }
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error, 5));
    dispatch({
      type: EngineConstants.API_TEST_EXECUTE_FAILURE,
      payload: {
        error: msg,
      },
    });
  }
};

const apiTesterExecuteInputsTest = (
  productName: string,
  serviceName: string,
  data: DTO.ExcelEngineRequest
): EngineThunkAction => async dispatch => {
  try {
    dispatch({ type: EngineConstants.API_TEST_EXECUTE_INPUTS });
    const { status, payload } = await EngineService.executeProductEngineInputs(
      productName,
      serviceName,
      data
    );

    if (status !== 200 || payload.status === 'Error') {
      if (payload.errorCode === EngineConstants.NO_ACTIVE_VERSION) {
        throw new ApiError({ errorCode: payload.errorCode });
      } else {
        throw new ApiError(payload);
      }
    } else {
      dispatch({
        type: EngineConstants.API_TEST_EXECUTE_INPUTS_SUCCESS,
        payload: {
          xlInputs: payload.data.xlInputs,
        },
      });
    }
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));
    dispatch({
      type: EngineConstants.API_TEST_EXECUTE_INPUTS_FAILURE,
      payload: {
        error: msg,
      },
    });
  }
};

const apiTesterReset = (): EngineAction => ({
  type: EngineConstants.API_TEST_RESET,
});

const apiTesterXlInputReset = (): EngineAction => ({
  type: EngineConstants.API_TESTER_XLINPUT_RESET,
});

const selectedEngineVersionsReset = (): EngineAction => ({
  type: EngineConstants.SELECTED_ENGINE_VERSIONS_RESET,
});

const getEngineVersions = (
  productName: string,
  serviceName: string
): EngineThunkAction<DTO.EngineVersion[]> => async dispatch => {
  try {
    dispatch({
      type: EngineConstants.GET_ENGINE_VERSIONS_REQUEST,
    });

    const { status, payload } = await EngineService.getVersions(
      productName,
      serviceName
    );

    if (status !== 200 || payload.status !== 'Success') {
      throw new ApiError(payload);
    }

    dispatch({
      type: EngineConstants.GET_ENGINE_VERSIONS_SUCCESS,
      payload: {
        serviceName,
        versions: payload.data,
      },
    });

    return payload.data;
  } catch (error) {
    dispatch(AlertActions.error(error));

    dispatch({
      type: EngineConstants.GET_ENGINE_VERSIONS_FAILURE,
    });

    return [];
  }
};

const downloadEngineFile = (
  productName: string,
  serviceName: string,
  version: string,
  type: string,
  fileName: string
): EngineThunkActionSync => async dispatch => {
  try {
    dispatch({
      type: EngineConstants.DOWNLOAD_ENGINE_REQUEST,
    });

    const downloadUrl =
      type === 'wasm'
        ? EngineService.getEngineWasmUrl(version, fileName)
        : EngineService.getDownloadEngineUrl(
            productName,
            serviceName,
            version,
            fileName,
            type
          );

    const blob = await FileManagerService.downloadBlob(
      downloadUrl,
      type === 'wasm' ? 'POST' : 'GET'
    );
    if (blob.status !== 200) {
      throw new ApiError({ error_code: 'ProductEngines.download.error' });
    }
    if (blob.payload.blob) {
      downloadBlob(blob.payload.blob, blob.payload.blobName ?? fileName);
    }
    dispatch({
      type: EngineConstants.DOWNLOAD_ENGINE_SUCCESS,
    });

    return true;
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: EngineConstants.DOWNLOAD_ENGINE_FAILURE,
      payload: {
        error: msg,
      },
    });
    return false;
  }
};

const downloadOutput = (
  product: string,
  engine: string,
  data: DTO.ExcelEngineRequest | DTO.ExcelEngineRequestV3,
  apiVersion = 'V1'
) => async (dispatch, getState) => {
  try {
    dispatch({
      type: EngineConstants.GET_EXECUTE_OUTPUT_REQUEST,
    });

    if (apiVersion === 'V1') {
      const { status, payload } = await EngineService.getExecuteOutputFile(
        product,
        engine,
        data as DTO.ExcelEngineRequest
      );
      if (status !== 200) {
        if (isCustomErrorCode(payload.errorCode)) {
          const {
            language: { intl },
          } = getState();

          const errMsg = intl.formatMessage(
            { id: 'ProductEngine.Output.Download.Fail.Generic' },
            {
              errorCode: payload.errorCode,
            }
          );
          throw new ApiError({
            message: errMsg,
          });
        }
        throw new ApiError(payload);
      }
      const blob = await FileManagerService.downloadBlob(payload.data.url);
      if (blob.payload.blob) {
        downloadBlob(blob.payload.blob, payload.data.fileName);
      }
    } else {
      const { status, payload } = await EngineService.downloadOutputTemplate(
        product,
        engine,
        data as DTO.ExcelEngineRequestV3
      );
      if (status !== 200) {
        throw new ApiError({ error_code: payload.error?.message });
      }
      if (payload?.blob) {
        downloadBlob(payload.blob, payload.blobName);
      }
    }

    dispatch({
      type: EngineConstants.GET_EXECUTE_OUTPUT_SUCCESS,
    });
    return true;
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: EngineConstants.GET_EXECUTE_OUTPUT_FAILURE,
      payload: {
        error: msg,
      },
    });
    return false;
  }
};

const getProxyUrl = (
  productName: string,
  serviceName: string,
  apiVersion: string
): EngineThunkAction => async dispatch => {
  try {
    dispatch({
      type: EngineConstants.CUSTOM_URL_REQUEST,
    });

    const { status, payload } = await EngineService.getSurfixUrl(
      productName,
      serviceName,
      apiVersion
    );

    if (status !== 200 || payload.status !== 'Success') {
      if (payload.errorCode === 'PROXY_URL_NOT_FOUND') {
        dispatch({
          type: EngineConstants.CUSTOM_URL_SUCCESS,
          payload: {
            data: { urlSuffix: '', productName, serviceName, apiVersion },
          },
        });
        return;
      }
      throw new ApiError(payload);
    }

    dispatch({
      type: EngineConstants.CUSTOM_URL_SUCCESS,
      payload: {
        data: payload.data,
        productName,
        serviceName,
        apiVersion,
      },
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));
    dispatch({
      type: EngineConstants.CUSTOM_URL_FAILURE,
      payload: {
        error: msg,
      },
    });
  }
};

const createProxyUrl = (
  productName: string,
  serviceName: string,
  urlSuffix: string,
  apiVersion: string,
  oldUrlSuffix: string
): EngineThunkAction<boolean> => async dispatch => {
  try {
    dispatch({
      type: EngineConstants.CUSTOM_URL_REQUEST,
    });

    const { status, payload } = await EngineService.saveProxyUrl({
      ProductName: productName,
      ServiceName: serviceName,
      UrlSuffix: urlSuffix,
      ApiVersion: apiVersion,
      OldUrlSuffix: oldUrlSuffix,
    });

    if (status !== 200 || payload.status !== 'Success') {
      throw new ApiError({ errorCode: payload.errorCode });
    }

    dispatch(getProxyUrl(productName, serviceName, apiVersion));
    return true;
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));
    dispatch({
      type: EngineConstants.CUSTOM_URL_FAILURE,
      payload: {
        error: msg,
      },
    });
    return false;
  }
};

const deleteProxyUrl = (
  proxyUrl: string,
  apiVersion: string
): EngineThunkAction<boolean> => async dispatch => {
  try {
    dispatch({
      type: EngineConstants.CUSTOM_URL_REQUEST,
    });

    const { status, payload } = await EngineService.deleteProxyUrl(
      proxyUrl,
      apiVersion
    );
    if (status !== 200 || payload.status !== 'Success') {
      throw new ApiError({ errorCode: payload.errorCode });
    }

    dispatch({
      type: EngineConstants.CUSTOM_URL_DELETE_SUCCESS,
    });
    return true;
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));
    dispatch({
      type: EngineConstants.CUSTOM_URL_FAILURE,
      payload: {
        error: msg,
      },
    });
    return false;
  }
};

const restoreVersion = (
  productName: string,
  serviceName: string,
  versionId: string,
  revision: string
): EngineThunkAction => async dispatch => {
  dispatch({
    type: EngineConstants.RESTORE_VERSION_REQUEST,
  });

  try {
    const { status, payload } = await EngineService.restoreVersion(
      productName,
      serviceName,
      versionId
    );

    if (status !== 200 || payload.status !== 'Success') {
      throw new ApiError(payload);
    }

    const { data } = payload;

    dispatch({
      type: EngineConstants.RESTORE_VERSION_SUCCESS,
      payload: {
        latestVersionId: data,
        selectedEngineName: serviceName,
      },
    });

    dispatch(
      AlertActions.success('ProductEngines.menu.restoreVersion.success', {
        serviceName,
        revision,
      })
    );
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: EngineConstants.RESTORE_VERSION_FAILURE,
      payload: {
        error: msg,
      },
    });
  }
};

const executeProductEngineWithChain = (
  productName: string,
  serviceName: string,
  data: DTO.ExcelEngineRequest
): EngineThunkAction => async dispatch => {
  try {
    dispatch({
      type: EngineConstants.EXCEXCUTE_PRODUCT_ENGINCE_CHAIN_REQUEST,
      payload: {
        apiVersion: 'V1',
      },
    });
    const {
      status,
      payload,
    } = await EngineService.executeProductEngineWithChain(
      productName,
      serviceName,
      data
    );

    if (status !== 200 || payload.status === 'Error') {
      if (payload.errorCode === EngineConstants.NO_ACTIVE_VERSION) {
        throw new ApiError({ errorCode: payload.errorCode });
      } else {
        throw new ApiError(payload);
      }
    } else {
      dispatch({
        type: EngineConstants.EXCEXCUTE_PRODUCT_ENGINCE_CHAIN_SUCCESS,
        payload: {
          result: payload.data,
          apiVersion: 'V1',
        },
      });
    }
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));
    dispatch({
      type: EngineConstants.EXCEXCUTE_PRODUCT_ENGINCE_CHAIN_FAILURE,
      payload: {
        error: msg,
      },
    });
  }
};

const generateTesterApiDocumentation = (
  productName: string,
  serviceName: string,
  isDownLoad: boolean,
  category: string,
  engineType: string,
  version?: string,
  apiVersion = 'V1'
): EngineThunkAction<unknown> => async dispatch => {
  try {
    dispatch({
      type: EngineConstants.API_TESTER_DOCUMENTATION_REQUEST,
    });
    const data: {
      productName: string;
      serviceName: string;
      version?: string;
      category: string;
      isDownLoad: boolean;
      engineType: string;
      apiVersion?: string;
    } = {
      productName,
      serviceName,
      category,
      isDownLoad,
      engineType,
      apiVersion,
    };

    if (version) {
      data.version = version;
    }

    const {
      payload,
      status,
    } = await EngineService.generateTesterApiDocumentation(data);

    if (status !== 200 || payload?.status === 'Error') {
      throw new ApiError(payload);
    }

    dispatch({
      type: EngineConstants.API_TESTER_DOCUMENTATION_SUCCESS,
      payload: {
        productName,
        serviceName,
        version,
        category,
        isDownLoad,
        documentation: payload as string,
      },
    });
    /* eslint consistent-return: "off" */
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));
    dispatch({
      type: EngineConstants.API_TESTER_DOCUMENTATION_FAILED,
      payload: {
        productName,
        error: msg,
      },
    });
  }
};

const downloadTesterApiDocumentationAsPDF = (
  HtmlBase64String: string,
  fileName: string,
  productName?: string,
  serviceName?: string,
  apiVersion?: string
): EngineThunkAction => async (dispatch, getState) => {
  try {
    dispatch({
      type: EngineConstants.API_DOCS_DOWNLOAD_REQUEST,
    });
    const {
      status,
      payload,
    } = await EngineService.downloadTesterApiDocumentationAsPDF(
      HtmlBase64String,
      productName,
      serviceName,
      apiVersion
    );

    const {
      language: { intl },
    } = getState();

    if (status !== 200 || payload.status === 'Error') {
      throw new ApiError(payload);
    }

    if (payload?.blob) {
      dispatch({
        type: EngineConstants.API_DOCS_DOWNLOAD_SUCCESS,
      });
      downloadBlob(payload.blob, fileName);
      dispatch(
        AlertActions.success(
          intl.formatMessage(
            {
              id: 'ApiTesterDocumentation.file.downloaded',
            },
            {
              fileName,
            }
          )
        )
      );
    }

    /* eslint consistent-return: "off" */
  } catch (error) {
    dispatch(AlertActions.error(error));
    dispatch({
      type: EngineConstants.API_DOCS_DOWNLOAD_FAILURE,
    });
  }
};

const downloadTesterApiDocumentationAsHTML = (
  HtmlBase64String: string,
  fileName = 'ApiDoc'
): EngineThunkAction => (dispatch, getState) => {
  try {
    const blobName = fileName;
    const blob = new Blob([HtmlBase64String], { type: 'text/html' });

    const {
      language: { intl },
    } = getState();

    if (blob) {
      downloadBlob(blob, blobName ?? fileName);
      dispatch(
        AlertActions.success(
          intl.formatMessage(
            {
              id: 'ApiTesterDocumentation.file.downloaded',
            },
            {
              fileName: `${fileName}.html`,
            }
          )
        )
      );
    }

    /* eslint consistent-return: "off" */
  } catch (error) {
    dispatch(AlertActions.error(error));
  }
};

const resetEngineUpload = (): EngineThunkAction => dispatch => {
  dispatch({
    type: EngineConstants.RESET_ENGINE_UPLOAD,
  });
};

const activateService = (
  product: string,
  engine: string,
  isActive: boolean,
  isActiveServiceFilterApplied: boolean | undefined = undefined,
  refreshServiceList = false
): EngineThunkAction<string | null> => async (dispatch, getState) => {
  try {
    dispatch({
      type: EngineConstants.ACIVATE_SERVICE_REQUEST,
    });

    const { payload, status } = await EngineService.activateService(
      product,
      engine,
      isActive
    );

    if (
      status !== 200 ||
      (payload.status === 'Error' &&
        payload.errorCode !== 'EXCEED_ACTIVE_SERVICE_LIMIT')
    ) {
      throw new ApiError(payload);
    }

    if (
      payload.status === 'Error' &&
      payload.errorCode === 'EXCEED_ACTIVE_SERVICE_LIMIT'
    ) {
      dispatch({
        type: EngineConstants.ACIVATE_SERVICE_FAILURE,
        payload: {
          error: payload.errorCode || '',
        },
      });
      return payload.errorCode;
    }

    await dispatch(
      AlertActions.success(
        `ActiveService.activateDeactive.${
          isActive ? 'active' : 'deactive'
        }.success.message`
      )
    );
    dispatch({
      type: EngineConstants.ACIVATE_SERVICE_SUCCESS,
      payload: {
        productName: product,
        serviceName: engine,
        isActive,
        isActiveServiceFilterApplied: !!isActiveServiceFilterApplied,
        refreshServiceList,
      },
    });
    return '';
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: EngineConstants.ACIVATE_SERVICE_FAILURE,
      payload: {
        error: msg,
      },
    });
    return msg || 'Error';
  }
};

const apiTesterPersistServiceParametersChange = (
  data: Partial<DTO.PersistServiceParameters>
): EngineAction => ({
  type: EngineConstants.API_TESTER_PERSIST_SERVICE_PARAMETERS_CHANGE,
  payload: { ...data },
});

const apiTesterPersistServiceParametersReset = (): EngineAction => ({
  type: EngineConstants.API_TESTER_PERSIST_SERVICE_PARAMETERS_RESET,
});

const readDataValidation = (
  productName: string,
  serviceName: string,
  data: DTO.ExcelEngineRequestV3
): EngineThunkAction => async dispatch => {
  try {
    dispatch({
      type: EngineConstants.READ_DATA_VALIDATION_REQUEST,
    });
    const { status, payload } = await EngineService.readDataValidation(
      productName,
      serviceName,
      data
    );

    if (status === 200 && payload?.status !== 'Error') {
      dispatch({
        type: EngineConstants.READ_DATA_VALIDATION_SUCCESS,
        payload: {
          result: {
            response_data: {
              ...(payload as DTO.ApiTestResponseV3).response_data,
            },
            response_meta: (payload as DTO.ApiTestResponseV3).response_meta,
          },
        },
      });
    } else if (payload && payload?.status === 'Error') {
      dispatch({
        type: EngineConstants.READ_DATA_VALIDATION_FAILURE,
        payload: {
          error: payload?.error?.message || '',
        },
      });
    }
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error, 5));
    dispatch({
      type: EngineConstants.READ_DATA_VALIDATION_FAILURE,
      payload: {
        error: msg,
      },
    });
  }
};

const getLanguagesList = (): EngineThunkAction => async dispatch => {
  dispatch({
    type: EngineConstants.GET_ALL_CODE_LANGUAGE_REQUEST,
  });

  try {
    const { payload } = await EngineService.getLanguagesList();
    if (payload.status !== 'Success') {
      throw new ApiError(payload);
    }

    const { response_data } = payload;
    const languages: DTO.CodeLanguageItem[] = [];
    if (response_data?.outputs?.supportedCodegens) {
      response_data?.outputs?.supportedCodegens.forEach(language => {
        language.variants.forEach(varient => {
          languages.push({
            key: language.key,
            label: `${language.key} - ${varient.key}`,
            variant: varient.key,
          });
        });
      });
    }
    dispatch({
      type: EngineConstants.GET_ALL_CODE_LANGUAGE_SUCCESS,
      payload: {
        codeLangugeList: languages,
      },
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: EngineConstants.GET_ALL_CODE_LANGUAGE_FAILURE,
      payload: {
        error: msg,
      },
    });
  }
};

const getCodeContentLanguage = (
  request: DTO.GetLanguageContentRequest,
  language: string,
  variant: string
): EngineThunkAction => async dispatch => {
  dispatch({
    type: EngineConstants.GET_CODE_CONTENT_REQUEST,
  });

  try {
    const { payload } = await EngineService.getLanguageContent(
      request,
      language,
      variant
    );
    if (payload.status !== 'Success') {
      throw new ApiError({ error_code: payload.error?.message });
    }

    const { response_data } = payload;
    dispatch({
      type: EngineConstants.GET_CODE_CONTENT_SUCCESS,
      payload: {
        codeContent: response_data?.outputs?.snippet,
      },
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: EngineConstants.GET_CODE_CONTENT_FAILURE,
      payload: {
        error: msg,
      },
    });
  }
};

const getSwaggerContent = (
  request: DTO.GetLanguageContentRequest
): EngineThunkAction => async dispatch => {
  try {
    dispatch({
      type: EngineConstants.GET_CODE_CONTENT_REQUEST,
    });

    const { payload } = await EngineService.getSwaggerCotentUrl(request);
    if (!payload || payload.status === 'Error') {
      throw new ApiError({
        error_code: payload.error?.message ?? payload.errorCode,
      });
    }
    dispatch({
      type: EngineConstants.GET_CODE_CONTENT_SUCCESS,
      payload: {
        codeContent: JSON.stringify(payload, null, 3),
      },
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));
    dispatch({
      type: EngineConstants.GET_CODE_CONTENT_FAILURE,
      payload: {
        error: msg,
      },
    });
  }
};

export const EngineActions = {
  getProductEngines,
  getProductEngineDetails,
  cancelUpdateConfirm,
  updateProperties,
  updateEngineFavorite,
  cancelPublishEngine,
  resetAddEngine,
  increaseExecuteProgress,
  deleteEngine,
  resetProductEngines,
  executeProductEngine,
  apiTesterExecuteInputsTest,
  executeProductEngineWithChain,
  apiTesterReset,
  downloadEngineFile,
  downloadOutput,
  getEngineVersions,
  getProxyUrl,
  createProxyUrl,
  deleteProxyUrl,
  updateReleaseNote,
  updateEngineProps,
  restoreVersion,
  resetProductEngineSelection,
  generateTesterApiDocumentation,
  downloadTesterApiDocumentationAsPDF,
  downloadTesterApiDocumentationAsHTML,
  resetEngineUpload,
  activateService,
  cancelPublish,
  getProductEngineDetailsByVersionId,
  apiTesterPersistServiceParametersChange,
  apiTesterPersistServiceParametersReset,
  selectedEngineVersionsReset,
  readDataValidation,
  recompileNodegen,
  getLanguagesList,
  getCodeContentLanguage,
  getSwaggerContent,
  getCompilationProgess,
  getUploadLogs,
  apiTesterXlInputReset,
};
