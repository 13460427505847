import { v4 as uuidv4 } from 'uuid';
import { getbaseUrl } from '../helpers';
import { getConfig } from '../helpers/config';
import makeRequest from '../helpers/make-request';

const getReleaseDetails = () => {
  const url = `${getConfig().excelEngineDomain}/api/v1/release`;
  return makeRequest<DTO.ReleaseDetailsResponse>('GET', url);
};

const getLookupData = (isCached = true) => {
  const url = `${getConfig().excelEngineDomain}/api/v1/lookup/${
    isCached ? 'GetLookupData' : 'GetLookupDataNonCached'
  }`;
  return makeRequest<DTO.GetLookupDataResponse>('GET', url);
};

const getProductDocumentMetadata = () => {
  const url = `${
    getConfig().excelEngineDomain
  }/api/v1/config/GetProductDocumentMetadata`;
  return makeRequest<DTO.GetProductDocMetadataResponse>('GET', url);
};

const getDiagnoseDetails = () => {
  const url = `${getConfig().excelEngineDomain}/api/Diagnostic/get`;
  return makeRequest<DTO.DiagnoseDetailsResponse>('GET', url);
};

const clearCacheMemory = (pattern: string) => {
  if (!pattern || pattern.length === 0) {
    pattern = '*';
  }
  const url = `${
    getConfig().excelEngineDomain
  }/api/Diagnostic/ClearCache/${uuidv4()}/${pattern}`;
  return makeRequest<DTO.DiagnoseDetailsResponse>('POST', url);
};

const clearApplicationMemory = () => {
  const url = `${
    getConfig().excelEngineDomain
  }/api/Diagnostic/ClearGC/${uuidv4()}`;
  return makeRequest<DTO.DiagnoseDetailsResponse>('POST', url);
};

const getAppConfig = () => {
  const url = `${
    getConfig().excelEngineDomain
  }/api/v1/config/GetSparkConfiguration`;
  return makeRequest<{ data: DTO.AppConfiguration }>('GET', url);
};

const getCustomBrandingImage = (fullUrl: string) => {
  return makeRequest<{ blob: Blob }>(
    'GET',
    fullUrl,
    undefined,
    'application/octet-stream'
  );
};

export const SystemService = {
  getDiagnoseDetails,
  getReleaseDetails,
  getLookupData,
  clearCacheMemory,
  clearApplicationMemory,
  getProductDocumentMetadata,
  getAppConfig,
  getCustomBrandingImage,
};
