import _ from 'lodash';
import moment from 'moment';
import { OptionsServicesConstants, SystemConstants } from '../constants';
import { ApiError } from '../helpers';
import { OptionServices, ProductService } from '../services';
import { AlertActions } from './alert.actions';
import { OptionsServicesThunkAction } from './types/optionsServices.actions.types';

const updatePageRequest = (
  pageRequest: DTO.PagedRequestV3
): OptionsServicesThunkAction => async dispatch => {
  dispatch({
    type: OptionsServicesConstants.UPDATE_PAGE_REQUEST,
    payload: {
      data: pageRequest,
    },
  });
};

const removeHighlightColor = (
  id: string
): OptionsServicesThunkAction => async dispatch => {
  dispatch({
    type: OptionsServicesConstants.REMOVE_HIGHLIGHT_SAVED_FILTER_LIST,
    payload: {
      id,
    },
  });
};

const getOptionsServicesFilterDetails = async (filterId: string) => {
  const { payload } = await OptionServices.getOptionsServicesFilterDetails(
    filterId
  );
  if (payload.status !== 'Success') {
    throw payload;
  }
  const { response_data } = payload;
  if (response_data.latest_version_added_from) {
    response_data.latest_version_added_from = moment(response_data.latest_version_added_from, 'YYYY-MM-DDTHH:mm:ss[Z]').format(SystemConstants.UTC_DATE_TIME_FORMAT);
  }
  return response_data;
};

const applySavedFilter = (
  id: string
): OptionsServicesThunkAction<boolean> => async dispatch => {
  try {
    dispatch({
      type:
        OptionsServicesConstants.GET_OPTIONS_SERVICES_FILTER_DETAILS_REQUEST,
    });
    const data = await getOptionsServicesFilterDetails(id);
    dispatch({
      type: OptionsServicesConstants.APPLY_SAVED_FILTER_REQUEST,
      payload: {
        data: {
          ...data,
          id,
        },
      },
    });
    return true;
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));
    dispatch({
      type:
        OptionsServicesConstants.GET_OPTIONS_SERVICES_FILTER_DETAILS_FAILURE,
      payload: {
        error: msg,
      },
    });
    return false;
  }
};

const resetApplyFilter = (): OptionsServicesThunkAction => async dispatch => {
  dispatch({
    type: OptionsServicesConstants.RESET_APPLY_REQUEST,
  });
};

const resetOptionServicesToInitialState = (): OptionsServicesThunkAction => async dispatch => {
  dispatch({
    type: OptionsServicesConstants.RESET_OPTION_SERVICES_TO_INITIAL_STATE,
  });
};

const getOptionsServicesList = (
  request_data: DTO.RequestV3<DTO.PagedRequestV3>
): OptionsServicesThunkAction => async dispatch => {
  dispatch({
    type: OptionsServicesConstants.GET_OPTIONS_SERVICES_LIST_REQUEST,
  });
  try {
    const { payload } = await OptionServices.getOptionsServicesList(
      request_data
    );
    if (payload.status !== 'Success') {
      throw new ApiError(payload);
    }
    const { response_data, response_meta } = payload;
    dispatch({
      type: OptionsServicesConstants.GET_OPTIONS_SERVICES_LIST_SUCCESS,
      payload: {
        list: response_data,
        total: response_meta.count,
      },
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));
    dispatch({
      type: OptionsServicesConstants.GET_OPTIONS_SERVICES_LIST_FAILURE,
      payload: { error: msg },
    });
  }
};

const createOrUpdateOptionsServicesFilter = (
  request_data: DTO.RequestV3<DTO.OptionsServiceFilter>,
  shouldApplySavedFilter = true,
  shouldHighlightRow = false
): OptionsServicesThunkAction<boolean> => async dispatch => {
  dispatch({
    type:
      OptionsServicesConstants.CREATE_OR_UPDATE_OPTIONS_SERVICES_FILTER_REQUEST,
  });
  try {
    const isCreateFilter = !request_data.request_data?.id;
    const {
      payload,
    } = await OptionServices.createOrUpdateOptionsServicesFilter(request_data);
    if (payload.status !== 'Success') {
      throw new ApiError(payload);
    }
    dispatch(
      AlertActions.success(
        'OptionServices.tab.savedFilters.saveFilter.success.toastMessage'
      )
    );
    const { response_data } = payload;
    dispatch({
      type:
        OptionsServicesConstants.CREATE_OR_UPDATE_OPTIONS_SERVICES_FILTER_SUCCESS,
      payload: {
        id: isCreateFilter
          ? response_data
          : request_data.request_data?.id || '',
        filterName: request_data.request_data.filter_name,
        shouldHighlightRow,
      },
    });

    if (shouldApplySavedFilter) {
      const data = JSON.parse(JSON.stringify(request_data.request_data));
      if (isCreateFilter) {
        data.id = response_data;
      }
      dispatch({
        type: OptionsServicesConstants.APPLY_SAVED_FILTER_REQUEST,
        payload: {
          data,
        },
      });
    }
    return true;
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));
    dispatch({
      type:
        OptionsServicesConstants.CREATE_OR_UPDATE_OPTIONS_SERVICES_FILTER_FAILURE,
      payload: { error: msg },
    });
    return false;
  }
};

const getOptionsServicesFiltersList = (): OptionsServicesThunkAction => async dispatch => {
  dispatch({
    type: OptionsServicesConstants.GET_OPTIONS_SERVICES_FILTERS_LIST_REQUEST,
  });
  try {
    const { payload } = await OptionServices.getOptionsServicesFiltersList();
    if (payload.status !== 'Success') {
      throw new ApiError(payload);
    }
    const { response_data } = payload;
    const data: DTO.OptionsServiceFilter[] = [];
    const ids = Object.keys(response_data);
    ids.forEach(id => data.push({ id, filter_name: response_data[id] }));
    dispatch({
      type: OptionsServicesConstants.GET_OPTIONS_SERVICES_FILTERS_LIST_SUCCESS,
      payload: {
        list: data,
      },
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));
    dispatch({
      type: OptionsServicesConstants.GET_OPTIONS_SERVICES_FILTERS_LIST_FAILURE,
      payload: { error: msg },
    });
  }
};

const isServicesFilterExist = (
  filterName: string,
  filterId: string
): OptionsServicesThunkAction<boolean> => async () => {
  try {
    const { status, payload } = await OptionServices.isServicesFilterExist(
      filterName,
      filterId
    );
    if (status !== 200) {
      throw new ApiError({ errorCode: payload.error.message });
    }
    return payload.response_data;
  } catch (error) {
    return false;
  }
};

const deleteOptionsServicesFilter = (
  filterId: string
): OptionsServicesThunkAction => async dispatch => {
  dispatch({
    type: OptionsServicesConstants.DELETE_OPTIONS_SERVICES_FILTER_REQUEST,
  });
  try {
    const { payload } = await OptionServices.deleteOptionsServicesFilter(
      filterId
    );
    if (payload.status !== 'Success') {
      throw new ApiError(payload);
    }
    dispatch({
      type: OptionsServicesConstants.DELETE_OPTIONS_SERVICES_FILTER_SUCCESS,
      payload: {
        id: filterId,
      },
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));
    dispatch({
      type: OptionsServicesConstants.DELETE_OPTIONS_SERVICES_FILTER_FAILURE,
      payload: { error: msg },
    });
  }
};

const getProductsListPartial = async (
  page,
  pageSize
): Promise<{ data: DTO.Product[]; count: number }> => {
  const data: DTO.GetProductsRequest = {
    page,
    pageSize,
    sortBy: 'productName',
    sort: 'asc',
    filterCategory: [],
  };
  const { payload, status } = await ProductService.getProducts(data);

  if (status !== 200 || payload.status === 'Error') {
    throw new ApiError(payload);
  }

  return {
    count: payload.count,
    data: payload.data,
  };
};

const getProductsList = (): OptionsServicesThunkAction => async dispatch => {
  dispatch({
    type: OptionsServicesConstants.GET_OPTIONS_SERVICES_PRODUCTS_REQUEST,
  });
  try {
    const { count } = await getProductsListPartial(1, 3);
    const product = await getProductsListPartial(1, count < 3 ? 3 : count);
    dispatch({
      type: OptionsServicesConstants.GET_OPTIONS_SERVICES_PRODUCTS_SUCCESS,
      payload: {
        list: product.data,
      },
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: OptionsServicesConstants.GET_OPTIONS_SERVICES_PRODUCTS_FAILURE,
      payload: {
        error: msg,
      },
    });
  }
};
export const OptionsServicesActions = {
  updatePageRequest,
  applySavedFilter,
  resetApplyFilter,
  getOptionsServicesList,
  createOrUpdateOptionsServicesFilter,
  getOptionsServicesFiltersList,
  getOptionsServicesFilterDetails,
  isServicesFilterExist,
  deleteOptionsServicesFilter,
  getProductsList,
  removeHighlightColor,
  resetOptionServicesToInitialState,
};
