export const GET_PROXYOBJECT_REQUEST = 'GET_PROXYOBJECT_REQUEST';
export const GET_PROXYOBJECT_SUCCESS = 'GET_PROXYOBJECT_SUCCESS';
export const GET_PROXYOBJECT_FAILURE = 'GET_PROXYOBJECT_FAILURE';

export const GET_PROXYOBJECTLIST_REQUEST = 'GET_PROXYOBJECTLIST_REQUEST';
export const GET_PROXYOBJECTLIST_SUCCESS = 'GET_PROXYOBJECTLIST_SUCCESS';
export const GET_PROXYOBJECTLIST_FAILURE = 'GET_PROXYOBJECTLIST_FAILURE';

export const ASSIGN_USERGROUPTOPROXYOBJECT_REQUEST =
  'ASSIGN_USERGROUPTOPROXYOBJECT_REQUEST';
export const ASSIGN_USERGROUPTOPROXYOBJECT_SUCCESS =
  'ASSIGN_USERGROUPTOPROXYOBJECT_SUCCESS';
export const ASSIGN_USERGROUPTOPROXYOBJECT_FAILURE =
  'ASSIGN_USERGROUPTOPROXYOBJECT_FAILURE';

export const REMOVE_USERGROUPFROMPROXYOBJECT_REQUEST =
  'REMOVE_USERGROUPFROMPROXYOBJECT_REQUEST';
export const REMOVE_USERGROUPFROMPROXYOBJECT_SUCCESS =
  'REMOVE_USERGROUPFROMPROXYOBJECT_SUCCESS';
export const REMOVE_USERGROUPFROMPROXYOBJECT_FAILURE =
  'REMOVE_USERGROUPFROMPROXYOBJECT_FAILURE';

export const GET_ALL_SPARKCONFIG_REQUEST = 'GET_ALL_SPARKCONFIG_REQUEST';
export const GET_ALL_SPARKCONFIG_SUCCESS = 'GET_ALL_SPARKCONFIG_SUCCESS';
export const GET_ALL_SPARKCONFIG_FAILURE = 'GET_ALL_SPARKCONFIG_FAILURE';

export const SAVE_SPARKCONFIG_REQUEST = 'SAVE_SPARKCONFIG_REQUEST';
export const SAVE_SPARKCONFIG_SUCCESS = 'SAVE_SPARKCONFIG_SUCCESS';
export const SAVE_SPARKCONFIG_FAILURE = 'SAVE_SPARKCONFIG_FAILURE';

export const UPDATE_NAVIGATION = 'UPDATE_NAVIGATION';

export const MAX_WEBHOOKS_ADD_LIMIT = 5;
