import { Button, Input, Row, Tag, Tooltip } from 'antd';
import { Gantt } from 'frappe-gantt';
import React, { FC, useMemo, useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { CustomSelect, FavoritesSwitch, IconSvg, ScrollCss } from '..';
import { colorHexToRgba } from '../../../helpers/styled-color-opacity';
import { CategoryIcon } from '../ProductCategoryIcon';

const StyledFilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.color.pearl40};
  padding: 8px 24px 0px 24px;

  .row {
    display: flex;
    justify-content: space-between;
  }
`;

const StyledSearchInput = styled(props => <Input {...props} />)`
  width: 240px !important;
  margin-bottom: 8px !important;
  margin-right: 10px;

  && {
    border-radius: 4px;
  }
`;

const StyledWrap = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 1390px) {
    flex-wrap: wrap;
  }
`;

const StyledCustomSelect = styled(props => <CustomSelect {...props} />)`
  && {
    margin-bottom: 8px;

    &.right-margin-10 {
      margin-right: 10px;
    }

    &.rc-virtual-list-holder {
      ${ScrollCss}
    }

    @media (max-width: 1600px) {
      width: 190px;
      min-width: 190px;
      .ant-select-selection {
        width: 190px;
        min-width: 190px;
      }
    }
  }
`;

const StyledFavoritToggleWrap = styled.div<{ isChecked: boolean }>`
  display: flex;
  align-items: center;
  margin-left: 8px;
  margin-bottom: 8px;

  button {
    margin-left: 8px;
  }
`;

const StyledViewTypeWrap = styled.div`
  display: flex;
  margin-bottom: 8px;

  .radio-wrap {
    display: flex;
    align-items: center;
    margin-left: 40px;
    cursor: pointer;
  }
`;

export const StyledViewIconSvg = styled(props => <IconSvg {...props} />)<{
  selected?: boolean;
}>`
  background: ${({ theme, selected }) =>
    selected ? theme.color.purple : theme.color.white} !important;
  border: 1px solid ${({ theme }) => theme.color.purple} !important;
  width: 40px;
  padding: 12px;
  border-radius: 4px;
  margin-left: 8px;

  svg {
    color: ${({ theme, selected }) =>
      !selected ? theme.color.purple : theme.color.white} !important;
    margin: 0 !important;
    font-size: 15px !important;
  }

  :hover {
    background: ${({ theme }) => theme.color.purple20} !important;
    border: 1px solid ${({ theme }) => theme.color.purpleDarkened1} !important;

    svg {
      color: ${({ theme }) => theme.color.purpleDarkened1} !important;
    }
  }

  :active {
    background: ${({ theme }) => theme.color.purple} !important;
    border: 1px solid ${({ theme }) => theme.color.purple} !important;

    svg {
      color: ${({ theme }) => theme.color.white} !important;
    }
  }
`;

export const StyledTagContainer = styled.div`
  float: right;
  display: flex;
  align-items: center;
`;

export const StyledTag = styled(props => <Tag {...props} />)<{
  type: 'status' | 'category';
}>`
  && {
    background: ${({ type, theme }) =>
      type === 'category'
        ? theme.color.purple20
        : colorHexToRgba(theme.color.purple, 0.3)};
  }
`;

const mapStateToProps = ({ system: { appConfig } }: STATES.AppState) => ({
  appConfig,
});

export type viewtypeType = 'list' | 'grid';
interface SortByOptionsTypes {
  [keys: string]: {
    key: string;
    sortBy: DTO.GetProductsRequest['sortBy'] | '-productName';
    sort: DTO.GetProductsRequest['sort'];
    label: string;
  };
}

export interface FilterBarProps {
  selectedItems: string[];
  searchTerm: string;
  productCategories: DTO.ProductCategory[];
  categoryFilterText?: string;
  showFavorites: boolean;
  categoryIcons?: object;
  viewtype: 'list' | 'grid';
  className?: string;
  showFolderWithActiveServiceType: boolean;
  SortByOptions: SortByOptionsTypes;
  sortByText?: string;
  filterRoadmapBy: Gantt.viewMode;
  handlelSetFilterRoadmapBy: (prop: Gantt.viewMode) => void;
  handleSortByChange: (prop: string) => void;
  onSelectFilterCategory: (prop: string) => void;
  openNewProduct?: () => void;
  handleSetSelectedItems: (props: string[]) => void;
  handleSetSearchTerm: (prop: string) => void;
  handleFavToggle: () => void;
  handleSetViewType: (prop: viewtypeType) => void;
  handleFolderWithActiveServiceTypeToggle: () => void;
}

const FilterBar: FC<FilterBarProps> = ({
  selectedItems,
  handleSetSelectedItems,
  searchTerm,
  handleSetSearchTerm,
  productCategories,
  categoryFilterText,
  showFavorites,
  handleFavToggle,
  showFolderWithActiveServiceType,
  handleFolderWithActiveServiceTypeToggle,
  categoryIcons,
  viewtype,
  handleSetViewType,
  sortByText,
  SortByOptions,
  handleSortByChange,
  onSelectFilterCategory,
  className,
  openNewProduct,
}) => {
  const intl = useIntl();
  const { appConfig } = useSelector(mapStateToProps);
  const filterBarRef = useRef<HTMLDivElement>(null);

  const filterTags = useMemo(() => {
    if (!selectedItems) {
      return null;
    }
    // Map selected category keys to their corresponding values
    const selectedCategoryValues = selectedItems.map(key => {
      const category = productCategories.find(cat => cat.key === key);
      return category ? category.value : key;
    });

    return selectedCategoryValues.map((value, index) => (
      <StyledTagContainer>
        <StyledTag
          className="active-filter-tag"
          closable
          type="category"
          visible={!!selectedItems}
          onClose={() => {
            const categories = [...selectedItems];
            categories.splice(index, 1);
            handleSetSelectedItems(categories);
          }}
        >
          {value}
        </StyledTag>
      </StyledTagContainer>
    ));
  }, [selectedItems]);

  return (
    <StyledFilterContainer ref={filterBarRef} className={className || ''}>
      <Row className="row">
        <StyledWrap>
          <StyledSearchInput
            data-testid="search-input"
            placeholder={intl.formatMessage({
              id: 'ProductCatalogue.search.placeholder.productName',
            })}
            value={searchTerm}
            prefix={<SearchOutlined />}
            onChange={e => handleSetSearchTerm(e.target.value)}
            size="middle"
            allowClear
          />
          {viewtype === 'grid' && (
            <StyledCustomSelect
              suffixIcon={<IconSvg type="CaretDownFilled" />}
              data-testid="sortby-select"
              className="right-margin-10"
              size="middle"
              width={202}
              value={sortByText}
              optionLabelProp="label"
              optionFilterProp="children"
              onChange={value => handleSortByChange(value as string)}
              placeholder="ProductCatalogue.dropdown.sort.placeholder"
              options={Object.values(SortByOptions).map(
                ({ key, sortBy: value, label }) => {
                  return {
                    key,
                    value: value || '',
                    title: key,
                    label,
                  };
                }
              )}
            />
          )}

          <StyledCustomSelect
            suffixIcon={
              <IconSvg type="CaretDownFilled" className="filter-icon" />
            }
            showCheckbox
            dropdownMatchSelectWidth={false}
            showIcon
            size="middle"
            width={216}
            value={categoryFilterText}
            className="multi-dropdown-select"
            dropdownClassName="multiple-select select-scroll-style"
            onChange={value => onSelectFilterCategory(value as string)}
            placeholder="ProductCatalogue.dropdown.sort.placeholder.category"
            options={productCategories.map(({ key, value, icon }) => ({
              key,
              value: key,
              title: value,
              icon: (
                <CategoryIcon
                  width="16px"
                  height="16px"
                  icon={icon}
                  categoryIcons={categoryIcons}
                  productCategories={productCategories}
                />
              ),
              selected: selectedItems?.includes(value),
            }))}
            categoryIcons={categoryIcons}
            productCategories={productCategories}
            disabled={showFavorites}
          />
          {appConfig?.EnableActiveService?.toString()?.toLowerCase() ===
            'true' && (
            <StyledFavoritToggleWrap
              isChecked={showFolderWithActiveServiceType}
            >
              {intl.formatMessage({
                id: 'ProductCatalogue.toggle.FoldersWithActiveService',
              })}
              <FavoritesSwitch
                checked={showFolderWithActiveServiceType}
                showStar={false}
                onChange={() =>
                  handleFolderWithActiveServiceTypeToggle &&
                  handleFolderWithActiveServiceTypeToggle()
                }
              />
            </StyledFavoritToggleWrap>
          )}

          <StyledFavoritToggleWrap isChecked={showFavorites}>
            {intl.formatMessage({ id: 'ProductCatalogue.favorite.products' })}
            <FavoritesSwitch
              checked={showFavorites}
              onChange={() => handleFavToggle()}
            />
          </StyledFavoritToggleWrap>
        </StyledWrap>
        <StyledViewTypeWrap>
          <>
            {openNewProduct && (
              <Button
                shape="round"
                type="default"
                size="large"
                className="mx-8"
                onClick={openNewProduct}
                id="add-new-folder"
                icon={<PlusOutlined />}
                data-testid="add-new-folder"
              >
                <span>
                  <FormattedMessage id="ProductDashboard.menu.addNewProduct" />
                </span>
              </Button>
            )}
            {viewtype === 'grid' && (
              <Tooltip
                placement="bottom"
                title={<FormattedMessage id="ProductCatalogue.listView" />}
              >
                <StyledViewIconSvg
                  onClick={() => handleSetViewType('list')}
                  type="ListView"
                  data-testid="list-view-icon"
                />
              </Tooltip>
            )}
            {viewtype === 'list' && (
              <Tooltip
                placement="bottom"
                title={<FormattedMessage id="ProductCatalogue.cardView" />}
              >
                <StyledViewIconSvg
                  onClick={() => handleSetViewType('grid')}
                  type="GridView"
                  data-testid="grid-view-icon"
                />
              </Tooltip>
            )}
          </>
        </StyledViewTypeWrap>
      </Row>
      {!!selectedItems?.length && (
        <div className="filter-select-item">
          <div className="currentfilter">
            {intl.formatMessage({ id: 'ProductCatalogue.activeFilter' })}
          </div>
          <div className="filtertags">{filterTags}</div>
          <div
            role="button"
            onClick={() => handleSetSelectedItems([])}
            className="clear-all"
            tabIndex={0}
            onKeyDown={e => {
              e.preventDefault();
            }}
          >
            <p>{intl.formatMessage({ id: 'ProductCatalogue.clearAll' })}</p>
            <IconSvg type="CloseIcon" />
          </div>
        </div>
      )}
    </StyledFilterContainer>
  );
};

export { FilterBar };
