import styled from 'styled-components';
import { Upload, Card } from 'antd';

const { Dragger } = Upload;

export const StyledDragger = styled(Dragger)<{
  dragEnter?: boolean;
  width: number;
}>`
  &.ant-upload-drag {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: transparent !important;
    color: ${({ theme }) => theme.color.gray1};
    border-radius: 10px !important;
    border-color: ${({ theme, dragEnter }) =>
      dragEnter ? theme.color.purple : theme.color.dark} !important;
    border-width: ${({ dragEnter }) => (dragEnter ? '3px' : '1px')} !important;
    border-style: dashed;
    // eslint-disable-next-line prefer-template
    width: ${({ width }) => width}px !important;

    .ant-btn:hover {
      color: ${({ theme }) => theme.color.purple};
    }

    .ant-upload-drag-container {
      pointer-events: none;
    }

    &:focus {
      background: black !important;
    }

    .ant-btn-link:hover,
    .ant-btn-link:focus {
      color: ${({ theme }) => theme.color.purple};
    }

    .ant-upload-btn {
      flex: 1;
      display: flex !important;
      justify-content: center;
      align-items: center;

      .ant-upload-drag-container {
        width: 100%;
        height: 100%;
        position: relative;
      }
    }
  }
`;

export const StyledCard = styled(Card)`
  && {
    flex: 1;
    background-color: transparent;
    border: none !important;
    height: fit-content !important;
    border: none !important;
    border-radius: 5px;
    margin-bottom: 20px;

    h1 {
      text-align: center;
      margin-bottom: 0;
    }

    h4 {
      text-align: center;
      margin-bottom: 15px;
    }
    .ant-card-body {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .ant-card-head {
      border-radius: 0 !important;

      .ant-card-head-title {
        padding: 10px 0;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: normal;
        font-size: 16px;
        white-space: normal;
        text-align: center;
      }
    }

    .ant-typography.ant-typography-danger {
      color: ${({ theme }) => theme.color.flamingo};
    }

    .ant-progress {
      width: 100%;
    }
  }
`;

export const handleFileDrag = (setDragEnter: (dragEnter: boolean) => void) => {
  const draggerElement = document.querySelector('.ant-upload-drag');
  if (draggerElement) {
    draggerElement.addEventListener('dragenter', () => {
      setDragEnter(true);
    });
    draggerElement.addEventListener('dragover', () => {
      setDragEnter(true);
    });
    draggerElement.addEventListener('dragleave', () => setDragEnter(false));
    draggerElement.addEventListener('drop', () => setDragEnter(false));
  }
};
