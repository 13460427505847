import { EntityPermissionAction } from '../actions/types';
import { EntityPermissionConstant } from '../constants';

export const initialEntityPermissionState: STATES.EntityPermissionState = {
  entityPermissions: [],
  gettingEntityPermissions: false,
  updatingEntityPermission: false,
  highlightedRows: [],
  keycloakUsers: {
    list: [],
    isloading: false,
  },
  keycloakUserGroups: {
    list: [],
    isloading: false,
  },
};

export const entityPermission = (
  state = initialEntityPermissionState,
  action: EntityPermissionAction
): STATES.EntityPermissionState => {
  switch (action.type) {
    case EntityPermissionConstant.GET_ENTITY_PERMISSION_REQUEST: {
      return {
        ...state,
        gettingEntityPermissions: true,
      };
    }
    case EntityPermissionConstant.GET_ENTITY_PERMISSION_SUCCESS: {
      const { entityPermissions } = action.payload;

      return {
        ...state,
        entityPermissions: entityPermissions.sort((a, b) => {
          if (a?.principal.toLowerCase() < b?.principal.toLowerCase()) {
            return -1;
          }
          if (a?.principal.toLowerCase() > b?.principal.toLowerCase()) {
            return 1;
          }
          return 0;
        }),
        gettingEntityPermissions: false,
      };
    }
    case EntityPermissionConstant.GET_ENTITY_PERMISSION_FAILURE: {
      return {
        ...state,
        gettingEntityPermissions: false,
      };
    }
    case EntityPermissionConstant.SET_ENTITY_PERMISSION_FAILURE: {
      const { updatedPrincipalName } = action.payload;
      if (updatedPrincipalName) {
        return {
          ...state,
          updatingEntityPermission: false,
          entityPermissions: state.entityPermissions.map(principal => {
            if (principal.principal === updatedPrincipalName) {
              return {
                ...principal,
                isSaving: false,
              };
            }
            return {
              ...principal,
            };
          }),
        };
      }
      return {
        ...state,
        updatingEntityPermission: false,
      };
    }
    case EntityPermissionConstant.SET_ENTITY_PERMISSION_REQUEST: {
      const { updatedPrincipalName } = action.payload;

      if (updatedPrincipalName) {
        return {
          ...state,
          updatingEntityPermission: true,
          entityPermissions: state.entityPermissions.map(principal => {
            if (principal.principal === updatedPrincipalName) {
              return {
                ...principal,
                isSaving: true,
              };
            }
            return {
              ...principal,
            };
          }),
        };
      }
      return {
        ...state,
        updatingEntityPermission: true,
      };
    }
    case EntityPermissionConstant.SET_ENTITY_PERMISSION_SUCCESS: {
      const {
        newPrincipalDetail,
        deletedPrincipalName,
        updatedPrincipalDetail,
      } = action.payload;

      if (newPrincipalDetail) {
        return {
          ...state,
          updatingEntityPermission: false,
          entityPermissions: [newPrincipalDetail, ...state.entityPermissions],
          highlightedRows: [
            ...state.highlightedRows,
            newPrincipalDetail.principal,
          ],
        };
      }

      if (deletedPrincipalName) {
        return {
          ...state,
          updatingEntityPermission: false,
          entityPermissions: state.entityPermissions.filter(
            principal => principal.principal !== deletedPrincipalName
          ),
        };
      }

      if (updatedPrincipalDetail) {
        return {
          ...state,
          updatingEntityPermission: false,
          entityPermissions: state.entityPermissions.map(principal => {
            if (principal.principal === updatedPrincipalDetail.members) {
              return {
                ...principal,
                isSaving: false,
                Delegate: updatedPrincipalDetail.Delegate,
                download: updatedPrincipalDetail.download,
                read: updatedPrincipalDetail.read,
                remove: updatedPrincipalDetail.remove,
                create: updatedPrincipalDetail.create,
                update: updatedPrincipalDetail.update,
                execute: updatedPrincipalDetail.execute,
              };
            }
            return {
              ...principal,
            };
          }),
        };
      }

      return {
        ...state,
        updatingEntityPermission: false,
      };
    }
    case EntityPermissionConstant.RESET_ENTITY_PERMISSION: {
      return {
        ...initialEntityPermissionState,
      };
    }

    case EntityPermissionConstant.REMOVE_HIGHLIGHTS: {
      const { principalName } = action.payload;

      return {
        ...state,
        highlightedRows: state.highlightedRows.filter(
          item => item !== principalName
        ),
      };
    }

    case EntityPermissionConstant.GET_KEYCLOAK_USERS_REQUEST: {
      return {
        ...state,
        keycloakUsers: {
          ...state.keycloakUsers,
          isloading: true,
        },
      };
    }
    case EntityPermissionConstant.GET_KEYCLOAK_USERS_SUCCESS: {
      const { list } = action.payload;

      return {
        ...state,
        keycloakUsers: {
          ...state.keycloakUsers,
          list,
          isloading: false,
        },
      };
    }
    case EntityPermissionConstant.GET_KEYCLOAK_USERS_FAILURE: {
      return {
        ...state,
        keycloakUsers: {
          ...state.keycloakUsers,
          isloading: false,
        },
      };
    }

    case EntityPermissionConstant.GET_KEYCLOAK_USER_GROUPS_REQUEST: {
      return {
        ...state,
        keycloakUserGroups: {
          ...state.keycloakUserGroups,
          isloading: true,
        },
      };
    }
    case EntityPermissionConstant.GET_KEYCLOAK_USER_GROUPS_SUCCESS: {
      const { list } = action.payload;

      return {
        ...state,
        keycloakUserGroups: {
          ...state.keycloakUserGroups,
          list,
          isloading: false,
        },
      };
    }
    case EntityPermissionConstant.GET_KEYCLOAK_USER_GROUPS_FAILURE: {
      return {
        ...state,
        keycloakUserGroups: {
          ...state.keycloakUserGroups,
          isloading: false,
        },
      };
    }

    default:
      return state;
  }
};
