import { getConfig } from '../helpers/config';
import makeRequest from '../helpers/make-request';

const getOptionsServicesList = (
  request_data: DTO.RequestV3<DTO.PagedRequestV3>
) => {
  const searchItem = request_data?.request_data?.search?.find(item => item.fieldName === 'name1_co');
  if (searchItem && typeof searchItem.fieldValue === 'string') {
    searchItem.fieldValue = searchItem.fieldValue.replace(/"/g, '');
  }
  const config = getConfig();
  const tenant = localStorage.getItem('Tenant');
  const url = `${config.excelEngineDomain}/${tenant ||
    config.defaultTenant}/api/v3/services/search`;
  return makeRequest<
    DTO.ResponseV3<DTO.OptionServices[], DTO.OptionsServicesResponseMeta>
  >('POST', url, request_data);
};

const createOrUpdateOptionsServicesFilter = (
  request_data: DTO.RequestV3<DTO.OptionsServiceFilter>
) => {
  const config = getConfig();
  const tenant = localStorage.getItem('Tenant');
  const url = `${config.excelEngineDomain}/${tenant ||
    config.defaultTenant}/api/v3/services/savefilter`;
  return makeRequest<DTO.ResponseV3<string>>('POST', url, request_data);
};

const getOptionsServicesFiltersList = () => {
  const config = getConfig();
  const tenant = localStorage.getItem('Tenant');
  const url = `${config.excelEngineDomain}/${tenant ||
    config.defaultTenant}/api/v3/services/filterlist`;
  return makeRequest<DTO.ResponseV3<{ [key: string]: string }>>(
    'POST',
    url,
    undefined
  );
};

const getOptionsServicesFilterDetails = (filterId: string) => {
  const config = getConfig();
  const tenant = localStorage.getItem('Tenant');
  const url = `${config.excelEngineDomain}/${tenant ||
    config.defaultTenant}/api/v3/services/filter/${filterId}/get`;
  return makeRequest<DTO.ResponseV3<DTO.OptionsServiceFilter>>(
    'POST',
    url,
    undefined
  );
};

const isServicesFilterExist = (filterName: string, filterId = '') => {
  const config = getConfig();
  const tenant = localStorage.getItem('Tenant');
  const url = `${config.excelEngineDomain}/${tenant ||
    config.defaultTenant}/api/v3/services/filter/${filterName}/exist/${filterId}`;
  return makeRequest<DTO.ResponseV3<boolean>>('GET', url);
};

const deleteOptionsServicesFilter = (filterId: string) => {
  const config = getConfig();
  const tenant = localStorage.getItem('Tenant');
  const url = `${config.excelEngineDomain}/${tenant ||
    config.defaultTenant}/api/v3/services/filter/${filterId}/delete`;
  return makeRequest<DTO.ResponseV3<boolean>>('DELETE', url);
};

export const OptionServices = {
  getOptionsServicesList,
  createOrUpdateOptionsServicesFilter,
  getOptionsServicesFiltersList,
  getOptionsServicesFilterDetails,
  isServicesFilterExist,
  deleteOptionsServicesFilter,
};
