import moment from 'moment';
import { getbaseUrl } from '../helpers';
import { getConfig } from '../helpers/config';
import makeRequest from '../helpers/make-request';
import uploadRequest from '../helpers/upload-request';

const getProductEngines = (
  productName: string,
  request: DTO.GetProductEnginesRequest
) => {
  const {
    page = 1,
    pageSize = 11,
    sortBy = 'updated',
    sort = 'desc',
    searchText = '',
    fakeSortingPage = null,
    fakeSortingPageSize = null,
    fileterActiveService = false,
  } = request;

  const search: { field: string; value: string }[] = [];
  if (searchText) {
    search.push({ field: 'name1_co', value: searchText.replace(/"/g, '') });
  }

  if (fileterActiveService) {
    search.push({ field: 'ACTIVESERVICE', value: 'true' });
  }

  const url = `${
    getConfig().excelEngineDomain
  }/api/v1/product/${productName}/engines`;
  return makeRequest<DTO.GetProductEnginesResponse>('POST', url, {
    page: fakeSortingPage || page,
    pageSize: fakeSortingPageSize || pageSize,
    search,
    sort: sortBy ? `${sort === 'desc' ? '-' : ''}${sortBy}` : '',
  });
};

const updateProductEngine = (
  productName: string,
  serviceName: string,
  request: DTO.UpdateProductEnginePropsRequest,
  version_difference: string
) => {
  const url = `${getbaseUrl()}folders/${productName}/services/${serviceName}/update`;

  return makeRequest<DTO.ResponseV3<DTO.UpdateServiceResponse>>('PUT', url, {
    request_data: {
      version: request.Version,
      effective_start_date: request.effectiveStartDate,
      effective_end_date: request.effectiveEndDate,
      version_difference,
      book_properties: request.BookProperties,
      input_output_xl_inputs: request.XlInputs,
      input_output_xl_outputs: request.XlOutputs,
      tags: request.tags,
    },
  });
};

const recompileNodegen = (
  productName: string,
  serviceName: string,
  request: DTO.RecompileNodegenRequestV3
) => {
  const url = `${getbaseUrl()}folders/${productName}/services/${serviceName}/recompileNodgen`;
  return makeRequest<DTO.ResponseV3<DTO.UpdateServiceResponse>>(
    'POST',
    url,
    request
  );
};

const getProductEngineDetails = (
  productName: string,
  serviceName: string,
  versionId?: string,
  enableActiveService?: boolean
) => {
  let url = `${
    getConfig().excelEngineDomain
  }/api/v1/product/${productName}/engines`;

  if (versionId && !enableActiveService) {
    url += `/getbyversionid/${serviceName}/${versionId}`;
  } else if (versionId && enableActiveService) {
    url += `/getbyversionidwithoutcache/${serviceName}/${versionId}`;
  } else {
    url += `/get/${serviceName}`;
  }

  return makeRequest<{ data: DTO.EngineData }>('GET', url);
};

const getProductEngineDetailsByVersionId = (
  productName: string,
  serviceName: string,
  versionId?: string
) => {
  let url = `${
    getConfig().excelEngineDomain
  }/api/v1/product/${productName}/engines`;

  url += `/getbyversionid/${serviceName}/${versionId}`;
  return makeRequest<{ data: DTO.EngineData }>('GET', url);
};

const deleteEngine = (productName: string, serviceName: string) => {
  const url = `${
    getConfig().excelEngineDomain
  }/api/v1/product/${productName}/engines/delete/${serviceName}`;

  return makeRequest('DELETE', url);
};

const uploadRegressionTestInput = (
  productName: string,
  file: File,
  onUploadProgress: (percent: number) => void,
  xhrRef: (xhr: XMLHttpRequest) => void
) => {
  const url = `${
    getConfig().excelEngineDomain
  }/api/v1/product/${productName}/regression`;
  const formData = new FormData();

  formData.append('file', file);

  return uploadRequest<DTO.RegressionTestResponse>(
    'POST',
    url,
    formData,
    onUploadProgress,
    xhrRef
  );
};

const markFavorite = (
  productName: string,
  serviceName: string,
  isFavorite: boolean
) => {
  const url = `${
    getConfig().excelEngineDomain
  }/api/v1/product/${productName}/engines/markFavorite/${serviceName}/${isFavorite}`;

  return makeRequest<unknown>('POST', url);
};

const getProductEngineLogs = (
  productName: string,
  serviceName: string,
  versionId: string,
  request: DTO.PagedRequest
) => {
  const url = `${
    getConfig().excelEngineDomain
  }/api/v1/Product/${productName}/engines/${serviceName}/logs/${versionId}`;

  return makeRequest<DTO.GetProductEngineLogsResponse>('POST', url, request);
};

const getProductEngineLogTotalInputCountsByExecutionHistoryId = (
  executionHistoryId: string
) => {
  const config = getConfig();
  const tenant = localStorage.getItem('Tenant');

  const url = `${config.excelEngineDomain}/${tenant ||
    config.defaultTenant}/api/v3/log/gettotalinputcounts/${executionHistoryId}`;

  return makeRequest<DTO.ProductEngineLogsTotalInputCountResponse>('GET', url);
};

const getProductEngineLogsTotalCount = (
  productName: string,
  serviceName: string,
  versionId: string,
  request: DTO.PagedRequest
) => {
  const config = getConfig();
  const tenant = localStorage.getItem('Tenant');

  const url = `${config.excelEngineDomain}/${tenant ||
    config.defaultTenant}/api/v3/folders/${productName}/services/${serviceName}/log/gettotalcount/${versionId}`;

  return makeRequest<DTO.ProductEngineLogsTotalCountResponse>('POST', url, {
    request_data: request,
  });
};

const executeProductEngine = (
  productName: string,
  serviceName: string,
  data: DTO.ExcelEngineRequest | DTO.ExcelEngineRequestV3,
  apiVersion: string
) => {
  const config = getConfig();
  const tenant = localStorage.getItem('Tenant');
  let url = '';
  if (apiVersion === 'V3') {
    url = `${config.excelEngineDomain}/${tenant ||
      config.defaultTenant}/api/${apiVersion}/folders/${productName}/services/${serviceName}/Execute`;
  } else {
    url = `${config.excelEngineDomain}/api/v1/product/${productName}/engines/Execute/${serviceName}`;
  }
  return makeRequest<DTO.ExecuteProductEngineResponse | DTO.ApiTestResponseV3>(
    'POST',
    url,
    data
  );
};

const executeProductEngineInputs = (
  productName: string,
  serviceName: string,
  data: DTO.ExcelEngineRequest
) => {
  const url = `${
    getConfig().excelEngineDomain
  }/api/v1/product/${productName}/engines/ExecuteInputs/${serviceName}`;
  return makeRequest<DTO.ExecuteEngineInputsResponse>('POST', url, data);
};

const executeProductEngineWithChain = (
  productName: string,
  serviceName: string,
  data: DTO.ExcelEngineRequest
) => {
  const url = `${
    getConfig().excelEngineDomain
  }/api/v1/product/${productName}/engines/ExecuteChain/${serviceName}`;
  return makeRequest<DTO.ExecuteProductEngineResponse>('POST', url, data);
};

const getDownloadEngineLogFileUrl = (
  productName: string,
  serviceName: string,
  engineCallId: string,
  useXmlWriter: boolean
) => {
  let url = '';
  if (useXmlWriter) {
    url = `${
      getConfig().excelEngineDomain
    }/api/v1/product/${productName}/engines/${serviceName}/logs/Download/xml/${engineCallId}`;
  } else {
    url = `${
      getConfig().excelEngineDomain
    }/api/v1/product/${productName}/engines/${serviceName}/logs/Download/${engineCallId}`;
  }
  return url;
};

const getDownloadEngineUrl = (
  productName: string,
  serviceName: string,
  version: string,
  fileName: string,
  type: string
) => {
  return `${
    getConfig().excelEngineDomain
  }/api/v1/product/${productName}/engines/${serviceName}/Download/${version}?fileName=${fileName}&type=${type}`;
};

const getEngineWasmUrl = (version: string, fileName: string) => {
  return `${getbaseUrl()}nodegen/getnodegenzipbyId/version/${version}/${fileName}.zip`;
};

const getVersions = (productName: string, serviceName: string) => {
  const url = `${
    getConfig().excelEngineDomain
  }/api/v1/product/${productName}/engines/getversions/${serviceName}`;

  return makeRequest<DTO.GetEngineVersionsResponse>('GET', url);
};

const getLogByExecutionHistoryId = async (executionHistoryId: string) => {
  const url = `${getbaseUrl()}log/getexecutionhistorybyid/${executionHistoryId}`;
  return makeRequest<DTO.ResponseV3<string>>('GET', url);
};

const getExecuteOutputFile = (
  productName: string,
  engine: string,
  data: DTO.ExcelEngineRequest
) => {
  const url = `${
    getConfig().excelEngineDomain
  }/api/v1/product/${productName}/engines/Downloadoutput/${engine}/${moment(
    new Date()
  ).utcOffset()}`;
  return makeRequest<DTO.DownloadExecuteOutputResponse>('POST', url, data);
};

const getSurfixUrl = (
  productName: string,
  engineName: string,
  apiVersion: string
) => {
  const url = `${
    getConfig().excelEngineDomain
  }/api/v1/proxy/GetProxyRule/${productName}/${engineName}/${apiVersion}`;

  return makeRequest<DTO.GetProxyUrlResponse>('GET', url);
};

const saveProxyUrl = (data: DTO.SaveProxyUrlRequest) => {
  const url = `${getConfig().excelEngineDomain}/api/v1/proxy/SaveProxyRule`;

  return makeRequest<DTO.SaveProxyUrlResponse>('POST', url, data);
};

const deleteProxyUrl = (proxyUrl: string, apiVersion: string) => {
  const url = `${
    getConfig().excelEngineDomain
  }/api/v1/proxy/DeleteProxyRule/${apiVersion}/${proxyUrl}`;

  return makeRequest<DTO.ApiResponse>('DELETE', url);
};

const restoreVersion = (
  productName: string,
  engineName: string,
  versionId: string
) => {
  const url = `${
    getConfig().excelEngineDomain
  }/api/v1/product/${productName}/engines/restoreversion/${engineName}/${versionId}`;

  return makeRequest<{
    data: string;
  }>('POST', url);
};

const getEngineChain = (
  productName: string,
  engineName: string,
  revision: string
) => {
  const url = `${
    getConfig().excelEngineDomain
  }/api/v1/product/${productName}/engines/${engineName}/chain/${revision}`;

  return makeRequest<DTO.GetEngineChainResponse>('GET', url);
};

const getUnchainedEngines = (
  productName: string,
  engineName: string,
  revision: string
) => {
  const url = `${
    getConfig().excelEngineDomain
  }/api/v1/product/${productName}/engines/${engineName}/unchained/${revision}`;

  return makeRequest<{
    data: string[];
  }>('GET', url);
};

const createEngineChain = (
  data: DTO.CreateEngineChainRequest,
  serviceName: string
) => {
  const url = `${getConfig().excelEngineDomain}/api/v1/product/${
    data.Product
  }/engines/${serviceName}/chain`;

  return makeRequest('POST', url, data);
};

const deleteEngineChain = (
  productName: string,
  engineName: string,
  selectedEngine: string,
  revision: string
) => {
  const url = `${
    getConfig().excelEngineDomain
  }/api/v1/product/${productName}/engines/${engineName}/chain/${revision}/${selectedEngine}`;

  return makeRequest<{
    data: string;
  }>('DELETE', url);
};

const DownloadMultipleLogs = (
  productName: string,
  serviceName: string,
  versionId: string,
  outputFormat: string,
  request: DTO.DownloadMultipleLogsRequest
) => {
  const url = `${
    getConfig().excelEngineDomain
  }/api/v1/product/${productName}/engines/${serviceName}/logs/Download/${moment(
    new Date()
  ).utcOffset()}/${versionId}?fileType=${outputFormat}`;

  return makeRequest<DTO.DownloadMultipleLogsResponse>('POST', url, request);
};

const DownloadMultipleLogsV3 = (
  productName,
  serviceName,
  outputFormat,
  request: DTO.DownloadMultipleLogsRequestV3
) => {
  const url = `${getbaseUrl()}folders/${productName}/services/${serviceName}/log/download${
    outputFormat === 'CSV' ? 'csv' : 'json'
  }`;

  return makeRequest<DTO.ResponseV3<{ job_id: string }>>('POST', url, request);
};

const generateTesterApiDocumentation = ({
  productName,
  serviceName,
  category,
  version,
  isDownLoad,
  apiVersion,
  engineType,
}: DTO.GenerateApiDocumentationRequest) => {
  let body: {
    productName: string;
    serviceName: string;
    category: string;
    engineType: string;
    version?: string;
  } = {
    productName,
    serviceName,
    category,
    engineType,
  };
  if (version) {
    body = {
      ...body,
      version,
    };
  }
  const config = getConfig();
  const tenant = localStorage.getItem('Tenant');
  let url = '';
  if (apiVersion === 'V3') {
    url = `${config.excelEngineDomain}/${tenant ||
      config.defaultTenant}/api/${apiVersion}/folders/${productName}/services/${serviceName}/GenerateApiDocumentation/${category}/${isDownLoad}/${version}/${moment(
      new Date()
    ).utcOffset()}?engineType=${engineType}`;
    return makeRequest<unknown>('GET', url);
  }
  url = `${
    config.excelEngineDomain
  }/api/${apiVersion}/apidocumentation/generateapidoc/${isDownLoad}/${moment(
    new Date()
  ).utcOffset()}`;
  return makeRequest<unknown>('POST', url, body);
};

const downloadTesterApiDocumentationAsPDF = (
  HtmlBase64String: string,
  productName?: string,
  serviceName?: string,
  apiVersion = 'V1'
) => {
  const body: {
    HtmlBase64String: string;
    productName?: string;
    serviceName?: string;
  } = {
    HtmlBase64String,
    productName,
    serviceName,
  };

  const config = getConfig();
  const tenant = localStorage.getItem('Tenant');
  let url = '';
  if (apiVersion === 'V3') {
    url = `${config.excelEngineDomain}/${tenant ||
      config.defaultTenant}/api/${apiVersion}/folders/${productName}/services/${serviceName}/exportaspdf`;
  } else {
    url = `${config.excelEngineDomain}/api/${apiVersion}/apidocumentation/exportaspdf`;
  }

  return makeRequest<{ blob: Blob; blobName: string }>('POST', url, body);
};

const activateService = (
  productName: string,
  service: string,
  isActive: boolean
) => {
  const url = `${
    getConfig().excelEngineDomain
  }/api/v1/product/${productName}/engines/activeDeactiveSerivce/${service}/${isActive}`;

  return makeRequest<{ data: DTO.ActivateServiceResponse }>('POST', url);
};

const cancelPublish = (product: string, service: string) => {
  const url = `${
    getConfig().excelEngineDomain
  }/api/v1/product/${product}/engines/cancel/${service}`;

  return makeRequest('DELETE', url);
};

const downloadOutputTemplate = (
  productName: string,
  serviceName: string,
  data: DTO.ExcelEngineRequestV3
) => {
  const timeZoneOffset = moment(new Date()).utcOffset();
  const url = `${getbaseUrl()}folders/${productName}/services/${serviceName}/DownloadOutput/${timeZoneOffset}`;
  return makeRequest<{ blob: Blob; blobName: string }>('POST', url, data);
};

const readDataValidation = (
  productName: string,
  serviceName: string,
  data: DTO.ExcelEngineRequestV3
) => {
  const url = `${getbaseUrl()}folders/${productName}/services/${serviceName}/validation`;
  return makeRequest<DTO.ApiTestResponseV3>('POST', url, data);
};

const getLanguagesList = () => {
  const url = `${getbaseUrl()}snippet/getLanguagesList`;
  return makeRequest<DTO.ResponseV3<{ outputs: DTO.GetLanguagesListResponse }>>(
    'GET',
    url
  );
};

const getLanguageContent = (
  request: DTO.GetLanguageContentRequest,
  language: string,
  variant: string
) => {
  const url = `${getbaseUrl()}snippet/generateSnippet`;
  return makeRequest<DTO.ResponseV3<{ outputs: { snippet: string } }>>(
    'POST',
    url,
    {
      request_data: {
        language_code: language,
        variant,
        api_version: request.apiVersion,
      },
      request_meta: {
        version_id: request.version,
        compiler_type: request.engineType,
        service_category: request.category,
        parameterset_version_id: request.parameterSetVersionId,
        requested_output: request.requestedOutput
          ? [request.requestedOutput]
          : null,
      },
    }
  );
};

const getSwaggerCotentUrl = (request: DTO.GetLanguageContentRequest) => {
  const {
    productName,
    serviceName,
    version,
    isDownLoad,
    apiVersion,
    category,
    engineType,
    parameterSetVersionId,
    requestedOutput,
  } = request;

  const config = getConfig();
  const uri = request.isFormData
    ? 'downloadswaggerformdata'
    : 'downloadswagger';
  let url = '';
  if (apiVersion === 'V3') {
    url = `${getbaseUrl()}folders/${productName}/services/${serviceName}/${uri}/${encodeURIComponent(
      category
    ) || 'All'}/${isDownLoad ? `${isDownLoad}` : 'false'}/${
      version ? `${version}` : ''
    }?engineType=${engineType}&parameterSetVersionId=${
      parameterSetVersionId === undefined ? '' : parameterSetVersionId
    }&requestedOutput=${requestedOutput ?? ''}`;
  } else {
    url = `${
      config.excelEngineDomain
    }/api/${apiVersion}/Product/${productName}/engines/${serviceName}/${uri}/${encodeURIComponent(
      category
    ) || 'All'}/${isDownLoad ? `${isDownLoad}` : 'false'}/${
      version ? `${version}` : ''
    }?engineType=${engineType}&parameterSetVersionId=${
      parameterSetVersionId === undefined ? '' : parameterSetVersionId
    }&requestedOutput=${requestedOutput ?? ''}`;
  }
  return makeRequest<unknown>('GET', url);
};

export const EngineService = {
  deleteEngineChain,
  createEngineChain,
  getEngineChain,
  getUnchainedEngines,
  getProductEngines,
  getProductEngineDetails,
  updateProductEngine,
  markFavorite,
  deleteEngine,
  uploadRegressionTestInput,
  cancelPublish,
  getProductEngineLogs,
  executeProductEngine,
  executeProductEngineInputs,
  executeProductEngineWithChain,
  getDownloadEngineLogFileUrl,
  getDownloadEngineUrl,
  getVersions,
  getSurfixUrl,
  saveProxyUrl,
  deleteProxyUrl,
  getExecuteOutputFile,
  restoreVersion,
  DownloadMultipleLogs,
  DownloadMultipleLogsV3,
  generateTesterApiDocumentation,
  downloadTesterApiDocumentationAsPDF,
  activateService,
  getProductEngineDetailsByVersionId,
  getEngineWasmUrl,
  downloadOutputTemplate,
  readDataValidation,
  recompileNodegen,
  getLanguagesList,
  getLanguageContent,
  getSwaggerCotentUrl,
  getProductEngineLogsTotalCount,
  getLogByExecutionHistoryId,
  getProductEngineLogTotalInputCountsByExecutionHistoryId,
};
