const html5String = (html: string) => `<!DOCTYPE html>
<html lang="en">

<head>
    <meta charset="UTF-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Document</title>
    <style>
        @import url('https://fonts.googleapis.com/css2?family=Comfortaa&family=EB+Garamond&family=Merriweather&family=Oswald&family=Poiret+One&family=Roboto&family=Roboto+Mono:ital@1&display=swap');
    </style>
</head>

<body>
  ${html}
</body>
</html>`;

export default html5String;
