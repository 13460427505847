import { AppAction } from '../actions/types';
import {
  BackgroundJobsConstants,
  EngineLogsConstant,
  ProductsConstants,
  SystematicTestcaseGenerationConstants,
  TestbedConstants,
  VersionOverviewConstants,
} from '../constants';

export const backgroundJobsInitialState: STATES.BackgroundJobsState = {
  jobs: [],
  intervalTime: BackgroundJobsConstants.INTERVAL_NORMAL,
  refreshTrigger: 0,
  currentActive: 'none',
  jobsToDownload: {},
  count: 0,
  triggerJobDownload: false,
};

const getBgCurrentActive = (
  jobs: DTO.BackgroundJob[]
): STATES.BackgroundJobsState['currentActive'] => {
  const runningJobs = jobs.filter(job =>
    ['InProgress', 'Pending'].includes(job.status)
  );

  const hasDownloadJobs = runningJobs.some(
    job => job.type === 'DownloadFolder'
  );
  const hasUploadJobs = runningJobs.some(job => job.type === 'UploadFolder');

  const hasDownloadLogsJobs = runningJobs.some(
    job => job.type === 'DownloadLogs'
  );

  const hasDownloadLogsCSVJobs = runningJobs.some(
    job => job.type === 'DownloadLogsCSV'
  );

  const hasTestbedResultCSV = runningJobs.some(
    job => job.type === 'TestbedResultCSV'
  );

  const hasTestbedResultExcel = runningJobs.some(
    job => job.type === 'TestbedResultExcel'
  );

  const hasGenerateTestCases = runningJobs.some(
    job => job.type === 'GenerateTestCases'
  );

  const hasGenerateSystematicTestCases = runningJobs.some(
    job => job.type === 'GenerateSystematicTestCases'
  );

  const hasRunTest = runningJobs.some(job => job.type === 'RunTest');

  const hasTestRunComparer = runningJobs.some(
    job => job.type === 'TestRunComparer'
  );

  const hasTestbedExcel = runningJobs.some(job => job.type === 'TestbedExcel');
  const hasTestbedCSV = runningJobs.some(job => job.type === 'TestbedCSV');

  const hasCompareServiceVersion = runningJobs.some(
    job => job.type === 'CompareService'
  );

  const hasDownloadLogsJsonJobs = runningJobs.some(
    job => job.type === 'DownloadLogsJson'
  );

  if (hasDownloadJobs && hasUploadJobs) {
    return 'downloadAndUpload';
  }

  if (hasDownloadJobs) {
    return 'download';
  }

  if (hasUploadJobs) {
    return 'upload';
  }

  if (hasDownloadLogsJobs || hasDownloadLogsCSVJobs || hasDownloadLogsJsonJobs) {
    return 'downloadLog';
  }

  if (hasTestbedResultCSV) {
    return 'TestbedResultCSV';
  }

  if (hasTestbedResultExcel) {
    return 'TestbedResultExcel';
  }

  if (hasTestbedExcel) {
    return 'TestbedExcel';
  }

  if (hasTestbedCSV) {
    return 'TestbedCSV';
  }

  if (hasGenerateTestCases) {
    return 'GenerateTestCases';
  }

  if (hasGenerateSystematicTestCases) {
    return 'GenerateSystematicTestCases';
  }

  if (hasRunTest) {
    return 'RunTest';
  }

  if (hasTestRunComparer) {
    return 'TestRunComparer';
  }

  if (hasCompareServiceVersion) {
    return 'CompareService';
  }

  return 'none';
};

export const backgroundJobsReducer = (
  state = backgroundJobsInitialState,
  action: AppAction
): STATES.BackgroundJobsState => {
  switch (action.type) {
    case BackgroundJobsConstants.GET_BGJOBS_SUCCESS: {
      const { jobs } = action.payload;

      const currentActive = getBgCurrentActive(jobs);
      return {
        ...state,
        jobs,
        currentActive,
        count: jobs.length,
        intervalTime:
          currentActive !== 'none'
            ? BackgroundJobsConstants.INTERVAL_HAS_RUNNING
            : BackgroundJobsConstants.INTERVAL_NORMAL,
      };
    }
    case BackgroundJobsConstants.TRIGGER_REFRESH_GBJOBS: {
      return {
        ...state,
        refreshTrigger: state.refreshTrigger + 1,
      };
    }
    case ProductsConstants.DOWNLOAD_PRODUCT_SUCCESS:
    case TestbedConstants.DOWNLOAD_TESTBED_TESTCASES_SUCCESS:
    case EngineLogsConstant.ADD_DOWNLOAD_LOGS_IN_QUEUE:
    case TestbedConstants.GENERATED_TESTBED_RESULT_SUCCESS: {
      const { jobId } = action.payload;

      return {
        ...state,
        jobsToDownload: {
          ...state.jobsToDownload,
          [jobId]: true,
        },
      };
    }
    case VersionOverviewConstants.COMPARE_SERVICE_VERSIONS_SUCCESS: {
      const { taskQueueId } = action.payload;

      return {
        ...state,
        jobsToDownload: {
          ...state.jobsToDownload,
          [taskQueueId]: true,
        },
      };
    }
    case TestbedConstants.QUEUE_GENERATE_TESTCASES_SUCCESS:
    case TestbedConstants.QUEUE_COMPARE_RUN_SUCCESS: {
      const { taskQueueId } = action.payload;
      return {
        ...state,
        jobsToDownload: {
          ...state.jobsToDownload,
          [taskQueueId]: true,
        },
      };
    }

    case SystematicTestcaseGenerationConstants.QUEUE_SYSTEMATIC_GENERATE_TEST_CASES_SUCCESS: {
      const { queueId } = action.payload;
      return {
        ...state,
        jobsToDownload: {
          ...state.jobsToDownload,
          [queueId]: true,
        },
      };
    }

    case BackgroundJobsConstants.CANCEL_TASK_QUEUE_SUCCESS: {
      const { id } = action.payload;

      const jobs = state.jobsToDownload;
      delete jobs[id];
      return { ...state, jobsToDownload: jobs };
    }
    case BackgroundJobsConstants.TRIGGER_DOWNLOAD_BGJOBS: {
      const { jobs } = action.payload;

      const entries = Object.entries(jobs);

      if (entries.length === 0) {
        return state;
      }

      const jobsToDownload = { ...state.jobsToDownload };

      return {
        ...state,

        jobsToDownload: entries.reduce((prev, [jobId]) => {
          delete prev[jobId];

          return prev;
        }, jobsToDownload),
      };
    }

    case BackgroundJobsConstants.REFRESH_ENTITY: {
      const { data } = action.payload;
      return {
        ...state,
        triggerJobDownload: !state.triggerJobDownload,
        triggerJobData: data,
      };
    }

    case BackgroundJobsConstants.DELETE_BGJOBS_SUCCESS: {
      const { id } = action.payload;
      const newJobList = state.jobs.filter(job => job.id !== id);
      return {
        ...state,
        jobs: [...newJobList],
        count: newJobList.length,
      };
    }
    default:
      return state;
  }
};
