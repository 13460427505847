import { DatePicker } from 'antd';
import moment from 'moment';
import React, { FC, useRef } from 'react';
import styled from 'styled-components';
import { SystemConstants } from '../../../constants';
import { colorHexToRgba } from '../../../helpers/styled-color-opacity';

type formatFunctionType = (value: moment.Moment) => string;
interface CustomDatePickerProps {
  disabled?: boolean;
  disabledDate?: (currentDate: moment.Moment) => boolean;
  disabledTime?: (date: moment.Moment) => unknown;
  getPopupContainer?: ((node: HTMLElement) => HTMLElement) | undefined;
  onChange?:
    | ((value: moment.Moment | null, dateString: string) => void)
    | undefined;
  onOk?: (date: moment.Moment) => void | undefined;
  onOpenChange?: (open: boolean) => void;
  defaultValue?: moment.Moment;
  defaultPickerValue?: moment.Moment;
  showTime?: object | boolean;
  placeholder?: string | undefined;
  value?: moment.Moment;
  format?: string | formatFunctionType;
  name?: string;
  suffixIcon?: React.ReactNode;
  allowClear?: boolean;
  showNow?: boolean;
  suffixContent?: boolean;
  popupClassName?: string;
  dataTestId?: string;
  dropdownAlign?: any;
}

const StyledDatePicker = styled(props => <DatePicker {...props} />)`
  && {
    width: 100%;
    border: 1px solid ${({ theme }) => theme.color.gray2};
    background: ${({ theme }) => theme.color.white};
    height: ${({ suffixContent }) => (suffixContent ? '30px' : '36px')};

    margin-right: 20px;
    border-radius: 4px;
    border-top-left-radius: ${({ suffixContent }) =>
      suffixContent ? '0px' : '4px'};
    border-bottom-left-radius: ${({ suffixContent }) =>
      suffixContent ? '0px' : '4px'};
    :hover {
      border: 1px solid ${({ theme }) => theme.color.purple};
    }

    .ant-picker-input[disabled] {
      background: ${({ theme }) => theme.color.gray6};
    }

    .anticon-calendar {
      color: ${({ theme }) => theme.color.purple};
    }
  }
`;

const PickerContainer = styled.div`
  .ant-picker-dropdown {
    background: ${({ theme }) => theme.color.white};
    border-radius: 4px;
  }
  .ant-picker-footer {
    .ant-picker-today-btn {
      color: ${({ theme }) => theme.color.purple};
    }
  }
  .ant-calendar-disabled-cell.ant-calendar-today .ant-calendar-date::before {
    top: 2px;
    left: 2px;
    width: 26px;
    height: 26px;
  }

  .ant-calendar-disabled-cell.ant-calendar-today .ant-calendar-date::before {
    top: 2px;
    left: 2px;
    width: 26px;
    height: 26px;
  }

  .ant-calendar-disabled-cell.ant-calendar-selected-day
    .ant-calendar-date::before {
    top: 2px;
    left: 2px;
    width: 26px;
    height: 26px;
  }

  .ant-picker-panel-container {
    background: ${({ theme }) => colorHexToRgba(theme.color.white, 0.4)};
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    border: 1px solid ${({ theme }) => theme.color.white};

    .ant-calendar-disabled-cell .ant-calendar-date {
      background-color: ${({ theme }) => colorHexToRgba(theme.color.dark, 0.4)};
    }
  }

  .ant-picker-header {
    .ant-picker-header-next-btn,
    .ant-picker-header-super-next-btn,
    .ant-picker-header-super-prev-btn,
    .ant-picker-header-prev-btn {
      color: ${({ theme }) => theme.color.dark};
    }
    .ant-picker-year-panel {
      .ant-picker-header {
        background: ${({ theme }) => theme.color.purple};

        .ant-picker-header-super-prev-btn:hover,
        .ant-picker-header-super-prev-btn,
        .ant-picker-header-super-next-btn:hover,
        .ant-picker-header-super-next-btn {
          ::before,
          ::after {
            border-color: ${({ theme }) => theme.color.white};
          }
        }
      }
    }
    .ant-picker-header-view {
      button {
        color: ${({ theme }) => theme.color.dark};
      }
    }
    .ant-picker-month-panel {
      .ant-picker-header {
        background: ${({ theme }) => theme.color.purple};

        .ant-picker-header-super-prev-btn:hover,
        .ant-picker-header-super-prev-btn,
        .ant-picker-header-super-next-btn:hover,
        .ant-picker-header-super-next-btn {
          ::before,
          ::after {
            border-color: ${({ theme }) => theme.color.white};
          }
        }
      }
    }

    .ant-picker-header-super-prev-btn,
    .ant-picker-header-super-next-btn {
      ::before,
      ::after {
        border-color: ${({ theme }) => theme.color.white};
      }
    }

    .ant-picker-header-super-prev-btn:hover,
    .ant-picker-header-super-next-btn:hover {
      ::before,
      ::after {
        border-color: ${({ theme }) => theme.color.white};
      }
    }

    a:hover {
      color: ${({ theme }) => theme.color.white};
    }
  }

  .ant-picker-content {
    thead tr th {
      font-weight: bold;
      font-size: 12px;
    }
    tbody tr td {
      padding: 0px;
    }
    tbody tr td .ant-picker-cell-inner {
      font-size: 12px;
      color: ${({ theme }) => theme.color.dark};
      height: 30px;
      line-height: 30px;
      border-radius: 4px;
      border: none;

      &:active {
        background: ${({ theme }) => theme.color.white};
      }
      &:hover {
        background: ${({ theme }) => theme.color.gray10};
        color: ${({ theme }) => theme.color.dark};
      }
    }

    .ant-picker-cell-selected .ant-picker-cell-inner {
      background-color: ${({ theme }) => theme.color.purpleDarkened2};
      border-radius: 2px;
      color: ${({ theme }) => theme.color.white};
    }
    .ant-picker-cell-in-view.ant-picker-cell-today
      .ant-picker-cell-inner::before {
      border: 1px solid ${({ theme }) => theme.color.purpleDarkened2};
      border-radius: 2px;
    }
    .ant-calendar-disabled-cell {
      .ant-calendar-date {
        background-color: transparent;
        color: ${({ theme }) => colorHexToRgba(theme.color.dark, 0.4)};
      }
    }
  }

  .ant-picker-year-panel {
    .ant-picker-cell-selected .ant-picker-cell-inner,
    .ant-picker-cell-selected .ant-picker-cell-inner:hover {
      background-color: ${({ theme }) =>
        colorHexToRgba(theme.color.purple, 0.4)};
      color: ${({ theme }) => theme.color.dark};
    }
  }

  .ant-picker-month-panel {
    .ant-picker-cell-selected .ant-picker-cell-inner,
    .ant-picker-cell-selected .ant-picker-cell-inner:hover {
      background-color: ${({ theme }) =>
        colorHexToRgba(theme.color.purple, 0.4)};
      color: ${({ theme }) => theme.color.dark};
    }
  }

  .ant-picker-year-panel,
  .ant-picker-month-panel {
    .ant-picker-cell-selected .ant-picker-cell-inner:hover {
      background-color: ${({ theme }) =>
        colorHexToRgba(theme.color.purple, 0.4)};
      color: ${({ theme }) => theme.color.dark};
    }
  }

  tr td {
    font-weight: normal !important;
  }

  .ant-picker-time-panel-column {
    > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
      background-color: ${({ theme }) => theme.color.purple20};
      font-weight: 400;
      color: ${({ theme }) => theme.color.dark};
    }
    .ant-picker-time-panel-cell-selected {
      color: ${({ theme }) => theme.color.purpleDarkened2};
    }
  }

  .ant-picker-time-panel-cell-seleced div:hover {
    background-color: ${({ theme }) => theme.color.gray6};
  }

  .ant-picker-footer {
    .ant-picker-ranges {
      .ant-btn {
        min-width: auto;
        color: ${({ theme }) => theme.color.white};
        background-color: ${({ theme }) => theme.color.purpleDarkened2};
        border: none;
        :hover {
          font-weight: normal !important;
          background-color: ${({ theme }) => theme.color.purpleDarkened1};
        }
        :active {
          background-color: ${({ theme }) => theme.color.purpleDarkened3};
        }
      }
      .ant-picker-now {
        color: ${({ theme }) => theme.color.purpleDarkened2};
      }
    }

    border-radius: 0 0 5px 5px;

    .ant-calendar-today-btn {
      color: ${({ theme }) => theme.color.white};
    }
    .ant-calendar-time-picker-btn {
      font-weight: 500;
      color: ${({ theme }) => theme.color.white};
    }
    .ant-calendar-ok-btn,
    .ant-calendar-ok-btn:hover {
      background-color: ${({ theme }) => theme.color.dark};
      color: ${({ theme }) => theme.color.white};
      border: none;
      font-size: 12px;
      vertical-align: top;
      margin-top: 7px;
    }
  }

  .ant-picker-cell:hover:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end)
    .ant-picker-cell-inner {
    background-color: ${({ theme }) => theme.color.gray10};
  }

  .ant-picker-time-panel {
    .ant-picker-header {
      .ant-picker-header-view {
        font-size: 12px;
      }
    }
  }
`;

const CustomDatePicker: FC<CustomDatePickerProps> = ({
  disabled,
  disabledDate,
  disabledTime,
  onChange,
  onOk,
  defaultValue,
  defaultPickerValue,
  showTime,
  placeholder,
  value,
  format,
  allowClear = false,
  showNow,
  suffixContent,
  popupClassName,
  dataTestId,
  dropdownAlign,
  getPopupContainer,
}) => {
  const calendarContainerRef = useRef<HTMLDivElement>(null);

  return (
    <div className="custom-date-picker-wrapper">
      <PickerContainer
        className="custom-date-picker-container"
        ref={calendarContainerRef}
      />
      <StyledDatePicker
        data-testid={dataTestId || 'custom-date-picker'}
        showNow={showNow}
        disabled={disabled}
        disabledDate={disabledDate}
        disabledTime={disabledTime}
        onChange={onChange}
        onOk={onOk}
        defaultValue={defaultValue}
        defaultPickerValue={defaultPickerValue}
        showTime={showTime}
        placeholder={placeholder}
        value={value}
        allowClear={allowClear}
        suffixContent={suffixContent}
        format={format || SystemConstants.SHORT_DATE_TIME_FORMAT}
        popupClassName={popupClassName}
        onOpenChange={(open: boolean) => {
          if (open) {
            document.addEventListener('keydown', e => {
              if (e.key !== undefined && e.key === 'Escape') {
                const target = e.target as HTMLTextAreaElement;
                target.blur();
              }
            });
          }
        }}
        getPopupContainer={triggerNode =>
          (getPopupContainer && getPopupContainer(triggerNode)) ||
          calendarContainerRef.current ||
          document.body
        }
        dropdownAlign={dropdownAlign}
      />
    </div>
  );
};

export { CustomDatePicker };
