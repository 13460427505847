import { ErrorCodeConstants } from '../constants';

type ErrorCodeType = typeof ErrorCodeConstants[keyof typeof ErrorCodeConstants];

export const getErrorMessage = (
  errorPayload: DTO.ApiErrorResponse,
  appState: STATES.AppState
): string | undefined => {
  if (errorPayload.errorCode && errorPayload.Data) {
    const { Error } = errorPayload.Data;
    const {
      language: { intl },
    } = appState;

    let error: string | undefined;
    if (errorPayload.errorCode === 'INTERNAL_SERVER_ERROR' && Error) {
      const errorData = Error as { errors: string[] };
      if (errorData?.errors?.length > 0) {
        error = [
          ...errorData.errors.map(key => intl.formatMessage({ id: key })),
        ].join('<br />');
      }
    }

    if (error) {
      return error;
    }
  }

  return undefined;
};

export const isCustomErrorCode = (errorCode: string | undefined | null) => {
  if (errorCode) {
    return Object.values(ErrorCodeConstants).includes(
      errorCode as ErrorCodeType
    );
  }
  return false;
};
