import { EngineConstants } from '../constants';
import { EngineAction } from '../actions/types';

const persistServiceParametersInitialState: DTO.PersistServiceParameters = {
  apiVersion: undefined,
  versionId: undefined,
  revisionNumber: undefined,
  requestedOutput: undefined,
  requestedServiceCategory: undefined,
  engineType: undefined,
  parametersetVersionId: '',
};
export const apiTesterInitialState: STATES.ApiTesterState = {
  error: false,
  isExecuting: false,
  executApiVersion: '',
  result: null,
  resultV3: null,
  customEndpoint: '',
  apiTesterInputLoading: false,
  ApiTesterDocumentation: {
    productName: '',
    serviceName: '',
    version: '',
    category: '',
    documentation: '',
    isLoadingDocs: false,
  },
  cancelGenerate: false,
  persistServiceParameters: persistServiceParametersInitialState,
  dataValidationV3: null,
};

export const apiTester = (
  state = apiTesterInitialState,
  action: EngineAction
): STATES.ApiTesterState => {
  switch (action.type) {
    case EngineConstants.API_TEST_EXECUTE:
    case EngineConstants.EXCEXCUTE_PRODUCT_ENGINCE_CHAIN_REQUEST: {
      const { apiVersion } = action.payload;
      return {
        ...state,
        isExecuting: true,
        executApiVersion: apiVersion,
      };
    }
    case EngineConstants.READ_DATA_VALIDATION_REQUEST:
    case EngineConstants.GET_EXECUTE_OUTPUT_REQUEST: {
      return {
        ...state,
        isExecuting: true,
        error: false,
      };
    }

    case EngineConstants.EXCEXCUTE_PRODUCT_ENGINCE_CHAIN_SUCCESS:
    case EngineConstants.API_TEST_EXECUTE_SUCCESS: {
      const { result, apiVersion } = action.payload;

      if (apiVersion === 'V3') {
        const formattedResult = {
          ...result,
          response_meta: (result as DTO.ApiTestResponseV3).response_meta,
        };
        return {
          ...state,
          result: null,
          resultV3: formattedResult as DTO.ApiTestResponseV3,
          isExecuting: false,
        };
      }
      const formattedResult = {
        ...result,
      };
      return {
        ...state,
        resultV3: null,
        result: formattedResult as DTO.XlObj,
        isExecuting: false,
      };
    }
    case EngineConstants.API_TEST_EXECUTE_SUCCESS_NO_APICALL: {
      const { result, executApiVersion } = action.payload;
      return {
        ...state,
        result,
        executApiVersion,
      };
    }
    case EngineConstants.API_TEST_EXECUTE_FAILURE: {
      return {
        ...state,
        isExecuting: false,
        error: true,
      };
    }
    case EngineConstants.GET_EXECUTE_OUTPUT_SUCCESS:
    case EngineConstants.EXCEXCUTE_PRODUCT_ENGINCE_CHAIN_FAILURE:
    case EngineConstants.GET_EXECUTE_OUTPUT_FAILURE: {
      return {
        ...state,
        isExecuting: false,
      };
    }
    case EngineConstants.API_TEST_RESET: {
      return {
        ...apiTesterInitialState,
        persistServiceParameters: state.persistServiceParameters,
      };
    }
    case EngineConstants.API_TESTER_PERSIST_SERVICE_PARAMETERS_RESET: {
      return {
        ...state,
        persistServiceParameters: persistServiceParametersInitialState,
      };
    }
    case EngineConstants.API_TESTER_PERSIST_SERVICE_PARAMETERS_CHANGE: {
      return {
        ...state,
        persistServiceParameters: {
          ...state.persistServiceParameters,
          ...action.payload,
        },
      };
    }
    case EngineConstants.CUSTOM_URL_REQUEST: {
      return {
        ...state,
        apiTesterInputLoading: true,
      };
    }
    case EngineConstants.CUSTOM_URL_SUCCESS: {
      const { data } = action.payload;

      return {
        ...state,
        apiTesterInputLoading: false,
        customEndpoint: data.urlSuffix,
      };
    }
    case EngineConstants.CUSTOM_URL_DELETE_SUCCESS: {
      return {
        ...state,
        apiTesterInputLoading: false,
        customEndpoint: '',
      };
    }
    case EngineConstants.CUSTOM_URL_FAILURE: {
      return {
        ...state,
        apiTesterInputLoading: false,
      };
    }

    case EngineConstants.API_TESTER_DOCUMENTATION_SUCCESS: {
      const { payload } = action;
      const documentation = payload.documentation?.replace(/ {4}/g, '');
      return {
        ...state,
        ApiTesterDocumentation: {
          isLoadingDocs: false,
          productName: payload.productName,
          serviceName: payload.serviceName,
          version: payload.version || '',
          category: payload.category,
          documentation: documentation || '',
        },
      };
    }

    case EngineConstants.API_TESTER_DOCUMENTATION_REQUEST:
    case EngineConstants.API_DOCS_DOWNLOAD_REQUEST: {
      return {
        ...state,
        ApiTesterDocumentation: {
          ...state.ApiTesterDocumentation,
          isLoadingDocs: true,
        },
      };
    }

    case EngineConstants.API_TESTER_DOCUMENTATION_FAILED:
    case EngineConstants.API_DOCS_DOWNLOAD_FAILURE:
    case EngineConstants.API_DOCS_DOWNLOAD_SUCCESS: {
      return {
        ...state,
        ApiTesterDocumentation: {
          ...state.ApiTesterDocumentation,
          isLoadingDocs: false,
        },
      };
    }

    case EngineConstants.READ_DATA_VALIDATION_SUCCESS: {
      const { result } = action.payload;

      return {
        ...state,
        dataValidationV3: result as DTO.ApiTestResponseV3,
        isExecuting: false,
      };
    }
    case EngineConstants.READ_DATA_VALIDATION_FAILURE: {
      return {
        ...state,
        isExecuting: false,
        error: true,
      };
    }
    default:
      return state;
  }
};
