export const UPDATE_SELECTED_ROW_KEYS = 'UPDATE_SELECTED_ROW_KEYS';
export const RESET_SELECTED_ROW_KEYS = 'RESET_SELECTED_ROW_KEYS';

export const UPDATE_TEST_CASE_GENERATION_DATA_LIST =
  'UPDATE_TEST_CASE_GENERATION_DATA_LIST';

export const RESET_TEST_CASE_GENERATION_DATA_LIST =
  'RESET_TEST_CASE_GENERATION_DATA_LIST';

export const UPDATE_TEST_CASE_GENERATION_DATA =
  'UPDATE_TEST_CASE_GENERATION_DATA';

export const REMOVE_HIGHLIGHT_TEST_CASE_GENERATION_DATA =
  'REMOVE_HIGHLIGHT_TEST_CASE_GENERATION_DATA';

export const QUEUE_SYSTEMATIC_GENERATE_TEST_CASES_REQUEST =
  'QUEUE_SYSTEMATIC_GENERATE_TEST_CASES_REQUEST';
export const QUEUE_SYSTEMATIC_GENERATE_TEST_CASES_SUCCESS =
  'QUEUE_SYSTEMATIC_GENERATE_TEST_CASES_SUCCESS';
export const QUEUE_SYSTEMATIC_GENERATE_TEST_CASES_FAILURE =
  'QUEUE_SYSTEMATIC_GENERATE_TEST_CASES_FAILURE';

export const GET_SYSTEMATIC_GENERATE_TEST_CASE_STATUS_REQUEST =
  'GET_SYSTEMATIC_GENERATE_TEST_CASE_STATUS_REQUEST';
export const GET_SYSTEMATIC_GENERATE_TEST_CASE_STATUS_SUCCESS =
  'GET_SYSTEMATIC_GENERATE_TEST_CASE_STATUS_SUCCESS';
export const GET_SYSTEMATIC_GENERATE_TEST_CASE_STATUS_FAILURE =
  'GET_SYSTEMATIC_GENERATE_TEST_CASE_STATUS_FAILURE';

export const RESET_SYSTEMATIC_GENERATE_TEST_CASES_MODAL =
  'RESET_SYSTEMATIC_GENERATE_TEST_CASES_MODAL';

export const MAX_TEST_CASES = 2147483647;
