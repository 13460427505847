import { TestbedAction } from '../actions/types';
import { TestbedConstants } from '../constants';

export const initialState: STATES.TestbedState = {
  generatingTestTemplate: false,
  templateFileName: null,
  templateUrl: null,
  fetchingTestbeds: false,
  testbedList: [],
  totalTestbeds: 0,
  testbedQueueProgress: null,
  testbedResult: null,
  testbedQueueId: null,
  savingTesbedQueue: false,
  fetchingTestbedQueue: false,
  fetchingTestbedResult: false,
  generatingTestResult: false,
  testbedResultUrl: null,
  testbedResultFileName: null,
  deletingTestbedRecord: false,
  deleteTestbedSuccesss: false,
  selectedTestbed: null,
  uploadError: null,
  uploadProgress: 0,
  xhrRef: null,
  testbedVersionId: '',
  savingTaskQueue: false,
  taskQueueId: null,
  taskQueueIdExists: null,
  taskQueueProgress: null,
  fetchingTaskQueue: false,
  testCompareResult: null,
  latestTestbedVersionId: null,
  savingTestbed: false,
  downloadingTestbedTestCases: false,
  testRunCount: 0,
  uploadTestbedResults: null,
  testbedTestRunId: '',
  updateTestbed: {
    fileupload: {
      xhrRef: null,
      uploadProgress: null,
      uploadError: null,
      uploadResult: null,
    },
    publishTestbedTestcases: false,
    highLightTestbedDetailOnPublish: false,
  },
  testbedId: null,
  totalTime: 0,
  selectedTestrun: null,
  uploadTestrun: {
    fileupload: {
      xhrRef: null,
      uploadProgress: null,
      uploadError: null,
      uploadTestrunResult: null,
    },
    updateTestrunResult: false,
  },
  bgdownload: false,
};

export const testbeds = (
  state = initialState,
  action: TestbedAction
): STATES.TestbedState => {
  switch (action.type) {
    case TestbedConstants.GENERATED_TEST_TEMPLATE_REQUEST: {
      return {
        ...state,
        generatingTestTemplate: true,
      };
    }
    case TestbedConstants.GENERATED_TEST_TEMPLATE_SUCCESS: {
      const { url, fileName } = action.payload;

      return {
        ...state,
        generatingTestTemplate: false,
        templateUrl: url,
        templateFileName: fileName,
      };
    }
    case TestbedConstants.GENERATED_TEST_TEMPLATE_FAILURE: {
      return {
        ...state,
        generatingTestTemplate: false,
      };
    }
    case TestbedConstants.RESET_TESTBED_TEMPLATE: {
      return {
        ...state,
        generatingTestTemplate: false,
        templateUrl: null,
        templateFileName: null,
      };
    }
    case TestbedConstants.GET_TEST_BEDS_REQUEST: {
      return {
        ...state,
        fetchingTestbeds: true,
      };
    }
    case TestbedConstants.GET_TEST_BEDS_SUCCESS: {
      const { testbedList, totalTestbeds } = action.payload;
      return {
        ...state,
        fetchingTestbeds: false,
        testbedList,
        totalTestbeds,
      };
    }
    case TestbedConstants.GET_TESTBEDID_REQUEST: {
      return {
        ...state,
        fetchingTestbeds: true,
      };
    }
    case TestbedConstants.GET_TESTBEDID_SUCCESS: {
      const { testbed } = action.payload;
      return {
        ...state,
        fetchingTestbeds: false,
        selectedTestbed: testbed,
      };
    }
    case TestbedConstants.GET_TESTBEDID_FAILURE: {
      return {
        ...state,
        fetchingTestbeds: false,
      };
    }
    case TestbedConstants.GET_TEST_BEDS_FAILURE: {
      return {
        ...state,
        fetchingTestbeds: false,
      };
    }
    case TestbedConstants.GET_TEST_BED_QUEUE_REQUEST: {
      return {
        ...state,
        fetchingTestbedQueue: true,
      };
    }
    case TestbedConstants.GET_TEST_BED_QUEUE_SUCCESS: {
      const { testbedQueueProgress, testbedQueueId } = action.payload;

      return {
        ...state,
        testbedQueueProgress:
          state.testbedQueueProgress &&
          state.testbedQueueProgress.processed > testbedQueueProgress.processed
            ? state.testbedQueueProgress
            : testbedQueueProgress,
        fetchingTestbedQueue: false,
        testbedQueueId,
      };
    }
    case TestbedConstants.GET_TEST_BED_QUEUE_FAILURE: {
      return {
        ...state,
        fetchingTestbedQueue: false,
      };
    }
    case TestbedConstants.GET_TEST_BED_QUEUE_RESET: {
      return {
        ...state,
        testbedQueueProgress: null,
        fetchingTestbedQueue: false,
        testbedQueueId: null,
      };
    }
    case TestbedConstants.GET_TESTRUNS_REQUEST: {
      return {
        ...state,
        fetchingTestbeds: true,
      };
    }
    case TestbedConstants.GET_TESTRUNS_SUCCESS: {
      const { data, count } = action.payload;

      return {
        ...state,
        fetchingTestbeds: false,
        testRunList: data,
        testRunCount: count,
      };
    }
    case TestbedConstants.GET_TESTRUNS_FAILURE: {
      return {
        ...state,
        fetchingTestbedQueue: false,
      };
    }
    case TestbedConstants.SAVE_TEST_BED_QUEUE_REQUEST: {
      return {
        ...state,
        savingTesbedQueue: true,
        testbedQueueId: null,
        testbedQueueProgress: null,
        testbedResult: null,
      };
    }
    case TestbedConstants.SAVE_TEST_BED_QUEUE_SUCCESS: {
      const { testbedQueueId } = action.payload;
      return {
        ...state,
        testbedQueueId,
        savingTesbedQueue: false,
      };
    }
    case TestbedConstants.SAVE_TEST_BED_QUEUE_FAILURE: {
      return {
        ...state,
        savingTesbedQueue: false,
      };
    }
    case TestbedConstants.GET_TEST_BED_RESULT_REQUEST: {
      return {
        ...state,
        fetchingTestbedResult: true,
      };
    }
    case TestbedConstants.GET_TEST_BED_RESULT_SUCCESS: {
      const { testbedResult } = action.payload;
      return {
        ...state,
        testbedResult,
        fetchingTestbedResult: false,
      };
    }
    case TestbedConstants.GET_TEST_BED_RESULT_FAILURE: {
      return {
        ...state,
        fetchingTestbedResult: false,
      };
    }
    case TestbedConstants.REMOVE_TEST_BED_REQUEST: {
      return {
        ...state,
        deletingTestbedRecord: true,
        deleteTestbedSuccesss: false,
      };
    }
    case TestbedConstants.REMOVE_TEST_BED_SUCCESS: {
      return {
        ...state,
        deletingTestbedRecord: false,
        deleteTestbedSuccesss: true,
      };
    }
    case TestbedConstants.REMOVE_TEST_BED_FAILURE: {
      return {
        ...state,
        deletingTestbedRecord: false,
      };
    }
    case TestbedConstants.TEST_BED_UPLOAD_START: {
      return {
        ...state,
        xhrRef: null,
        uploadProgress: 5,
        uploadError: null,
        uploadTestbedResults: null,
      };
    }
    case TestbedConstants.TEST_BED_UPLOAD_XHR_REF: {
      const { xhrRef } = action.payload;
      return {
        ...state,
        xhrRef,
      };
    }
    case TestbedConstants.TEST_BED_UPLOAD_PROGRESS: {
      const { uploadProgress } = action.payload;

      return {
        ...state,
        uploadProgress,
      };
    }
    case TestbedConstants.TEST_BED_UPLOAD_SUCCESS: {
      const { uploadTestbedResults, hasWarnings } = action.payload;
      return {
        ...state,
        uploadProgress: 100,
        uploadTestbedResults,
        hasWarnings,
      };
    }
    case TestbedConstants.TEST_BED_UPLOAD_ERROR: {
      const { error } = action.payload;

      return {
        ...state,
        uploadError: error,
      };
    }
    case TestbedConstants.TEST_BED_UPLOAD_RESET: {
      return {
        ...initialState,
        testbedList: state.testbedList,
        testbedVersionId: state.testbedVersionId,
      };
    }
    case TestbedConstants.TEST_BED_LIST_HIGHLIGHT_RESET: {
      return {
        ...state,
        testbedVersionId: '',
      };
    }
    case TestbedConstants.GENERATED_TEST_COMPARE_REQUEST: {
      return {
        ...state,
        generatingTestResult: true,
      };
    }
    case TestbedConstants.GENERATED_TEST_COMPARE_SUCCESS: {
      const { url, fileName } = action.payload;

      return {
        ...state,
        generatingTestResult: false,
        testbedResultUrl: url,
        testbedResultFileName: fileName,
      };
    }
    case TestbedConstants.GENERATED_TEST_COMPARE_FAILURE: {
      return {
        ...state,
        generatingTestResult: false,
      };
    }
    case TestbedConstants.UPDATE_TEST_BED_REQUEST: {
      return {
        ...state,
        savingTestbed: true,
        latestTestbedVersionId: null,
      };
    }
    case TestbedConstants.UPDATE_TEST_BED_SUCCESS: {
      const { latestTestbedVersionId } = action.payload;
      return {
        ...state,
        latestTestbedVersionId,
        savingTestbed: false,
      };
    }
    case TestbedConstants.UPDATE_TEST_BED_FAILURE: {
      return {
        ...state,
        savingTestbed: false,
      };
    }
    case TestbedConstants.GET_GENERATE_TESTCASE_STATUS_REQUEST: {
      return {
        ...state,
        fetchingTaskQueue: true,
      };
    }
    case TestbedConstants.GET_GENERATE_TESTCASE_STATUS_SUCCESS: {
      const { taskQueueProgress, taskQueueId } = action.payload;
      let testbedVersionId: string | null = null;
      let testbedId: string | null = null;
      if (taskQueueProgress.outputData) {
        const opData = JSON.parse(taskQueueProgress.outputData ?? '');
        testbedVersionId = opData?.testbedVersionId;
        testbedId = opData?.testbedId;
      }

      return {
        ...state,
        taskQueueProgress,
        fetchingTaskQueue: false,
        testbedVersionId: testbedVersionId ?? state.testbedVersionId,
        testbedId: testbedId ?? state.testbedId,
        totalTime: taskQueueProgress.totalTime,
        taskQueueId,
      };
    }
    case TestbedConstants.GET_GENERATE_TESTCASE_STATUS_FAILURE: {
      return {
        ...state,
        fetchingTaskQueue: false,
      };
    }
    case TestbedConstants.QUEUE_GENERATE_TESTCASES_REQUEST: {
      return {
        ...state,
        savingTaskQueue: true,
        taskQueueId: null,
        taskQueueProgress: null,
      };
    }
    case TestbedConstants.QUEUE_GENERATE_TESTCASES_SUCCESS: {
      const { taskQueueId } = action.payload;
      return {
        ...state,
        taskQueueId,
        savingTaskQueue: false,
      };
    }
    case TestbedConstants.QUEUE_GENERATE_TESTCASES_FAILURE: {
      return {
        ...state,
        savingTaskQueue: false,
      };
    }
    case TestbedConstants.RESET_TASK_QUEUE_ID: {
      return {
        ...state,
        taskQueueId: null,
        taskQueueIdExists: null,
        taskQueueProgress: null,
      };
    }
    case TestbedConstants.RESET_TASK_QUEUE: {
      return {
        ...state,
        taskQueueId: null,
        taskQueueIdExists: null,
        taskQueueProgress: null,
        savingTaskQueue: false,
        selectedTestbed: null,
        fetchingTaskQueue: false,
        testbedResultUrl: null,
        testbedResultFileName: null,
      };
    }
    case TestbedConstants.RESET_FILE_URLS: {
      return {
        ...state,
        testbedResultUrl: null,
        testbedResultFileName: null,
      };
    }
    case TestbedConstants.RESET_TEST_BED_RUN: {
      return {
        ...state,
        testbedQueueId: null,
        testbedQueueProgress: null,
        savingTestbed: false,
        testbedResult: null,
        testbedResultFileName: null,
        selectedTestrun: null,
      };
    }
    case TestbedConstants.DOWNLOAD_TESTBED_TESTCASES_REQUEST: {
      return {
        ...state,
        bgdownload: true,
        downloadingTestbedTestCases: true,
      };
    }
    case TestbedConstants.BG_DOWNLOAD_TESTBED_TESTCASES_SUCCESS: {
      return {
        ...state,
        bgdownload: false,
      };
    }
    case TestbedConstants.DOWNLOAD_TESTBED_TESTCASES_SUCCESS: {
      return {
        ...state,
        downloadingTestbedTestCases: false,
      };
    }
    case TestbedConstants.DOWNLOAD_TESTBED_TESTCASES_FAILURE: {
      return {
        ...state,
        downloadingTestbedTestCases: false,
      };
    }

    case TestbedConstants.PUBLISH_TESTBED_REQUEST: {
      return {
        ...state,
        savingTestbed: true,
      };
    }

    case TestbedConstants.PUBLISH_TESTBED_SUCCESS: {
      const { testbedvId } = action.payload;
      return {
        ...state,
        savingTestbed: false,
        testbedVersionId: testbedvId,
        uploadTestbedResults: null,
      };
    }

    case TestbedConstants.PUBLISH_TESTBED_FAILURE: {
      return {
        ...state,
        savingTestbed: false,
      };
    }
    case TestbedConstants.FAVOURITE_TESTBED_SUCCESS: {
      return {
        ...state,
        selectedTestbed: state.selectedTestbed
          ? {
              ...state.selectedTestbed,
              isFavourite: !state.selectedTestbed.isFavourite,
            }
          : state.selectedTestbed,
      };
    }
    case TestbedConstants.UPDATE_TESTBED_START: {
      return {
        ...state,
        updateTestbed: {
          ...state.updateTestbed,
          fileupload: {
            ...initialState.updateTestbed.fileupload,
            uploadProgress: 5,
          },
        },
      };
    }
    case TestbedConstants.UPDATE_TESTBED_XHR_REF: {
      const { xhrRef } = action.payload;
      return {
        ...state,
        updateTestbed: {
          ...state.updateTestbed,
          fileupload: {
            ...state.updateTestbed.fileupload,
            xhrRef: xhrRef || null,
          },
        },
      };
    }
    case TestbedConstants.UPDATE_TESTBED_PROGRESS: {
      const { uploadProgress } = action.payload;

      return {
        ...state,
        updateTestbed: {
          ...state.updateTestbed,
          fileupload: {
            ...state.updateTestbed.fileupload,
            uploadProgress,
          },
        },
      };
    }
    case TestbedConstants.UPDATE_TESTBED_SUCCESS: {
      const { uploadResult } = action.payload;
      return {
        ...state,
        updateTestbed: {
          ...state.updateTestbed,
          fileupload: {
            ...state.updateTestbed.fileupload,
            uploadProgress: 100,
            uploadResult,
          },
        },
      };
    }
    case TestbedConstants.UPDATE_TESTBED_ERROR: {
      const { error } = action.payload;
      return {
        ...state,
        updateTestbed: {
          ...state.updateTestbed,
          fileupload: {
            ...state.updateTestbed.fileupload,
            uploadProgress: 100,
            uploadError: error,
          },
        },
      };
    }
    case TestbedConstants.UPDATE_TESTBED_RESET: {
      return {
        ...state,
        updateTestbed: {
          ...state.updateTestbed,
          fileupload: {
            ...initialState.updateTestbed.fileupload,
          },
        },
      };
    }

    case TestbedConstants.PUBLISH_TESTBED_TESTCASES_REQUEST: {
      return {
        ...state,
        updateTestbed: {
          ...state.updateTestbed,
          publishTestbedTestcases: true,
        },
      };
    }
    case TestbedConstants.PUBLISH_TESTBED_TESTCASES_SUCCESS: {
      return {
        ...state,
        updateTestbed: {
          ...state.updateTestbed,
          publishTestbedTestcases: false,
          highLightTestbedDetailOnPublish: true,
        },
      };
    }
    case TestbedConstants.PUBLISH_TESTBED_TESTCASES_FAILURE: {
      return {
        ...state,
        updateTestbed: {
          ...state.updateTestbed,
          publishTestbedTestcases: false,
        },
      };
    }
    case TestbedConstants.PUBLISH_TESTBED_TESTCASES_RESET: {
      return {
        ...state,
        updateTestbed: {
          ...state.updateTestbed,
          publishTestbedTestcases: false,
          highLightTestbedDetailOnPublish: false,
        },
      };
    }
    case TestbedConstants.HIGHLIGHT_TESTBEDLIST_ROW_REQUEST: {
      const { testbedVersionId, testbedTestRunId } = action.payload;
      return {
        ...state,
        testbedVersionId,
        testbedTestRunId,
      };
    }
    case TestbedConstants.HIGHLIGHT_TESTBEDLIST_ROW_RESET: {
      return {
        ...state,
        testbedVersionId: '',
        testbedTestRunId: '',
      };
    }

    case TestbedConstants.UPLOAD_TESTRUNRESULT_START: {
      return {
        ...state,
        uploadTestrun: {
          ...state.uploadTestrun,
          fileupload: {
            ...initialState.uploadTestrun.fileupload,
            uploadProgress: 5,
          },
        },
      };
    }
    case TestbedConstants.UPLOAD_TESTRUNRESULT_XHR_REF: {
      const { xhrRef } = action.payload;
      return {
        ...state,
        uploadTestrun: {
          ...state.uploadTestrun,
          fileupload: {
            ...state.uploadTestrun.fileupload,
            xhrRef: xhrRef || null,
          },
        },
      };
    }
    case TestbedConstants.UPLOAD_TESTRUNRESULT_PROGRESS: {
      const { uploadProgress } = action.payload;

      return {
        ...state,
        uploadTestrun: {
          ...state.uploadTestrun,
          fileupload: {
            ...state.uploadTestrun.fileupload,
            uploadProgress,
          },
        },
      };
    }
    case TestbedConstants.UPLOAD_TESTRUNRESULT_SUCCESS: {
      const { uploadTestrunResult, hasWarnings } = action.payload;
      return {
        ...state,
        uploadTestrun: {
          ...state.uploadTestrun,
          fileupload: {
            ...state.uploadTestrun.fileupload,
            uploadProgress: 100,
            uploadTestrunResult,
          },
        },
        hasWarnings,
      };
    }
    case TestbedConstants.UPLOAD_TESTRUNRESULT_ERROR: {
      const { error } = action.payload;
      return {
        ...state,
        uploadTestrun: {
          ...state.uploadTestrun,
          fileupload: {
            ...state.uploadTestrun.fileupload,
            uploadProgress: 100,
            uploadError: error,
          },
        },
      };
    }
    case TestbedConstants.UPLOAD_TESTRUNRESULT_RESET: {
      return {
        ...state,
        uploadTestrun: {
          ...state.uploadTestrun,
          fileupload: {
            ...initialState.uploadTestrun.fileupload,
          },
        },
      };
    }
    case TestbedConstants.UPDATE_TESTRUN_RESULT_REQUEST: {
      return {
        ...state,
        uploadTestrun: {
          ...state.uploadTestrun,
          updateTestrunResult: true,
        },
      };
    }
    case TestbedConstants.UPDATE_TESTRUN_RESULT_SUCCESS: {
      return {
        ...state,
        uploadTestrun: {
          ...state.uploadTestrun,
          updateTestrunResult: false,
        },
      };
    }
    case TestbedConstants.UPDATE_TESTRUN_RESULT_FAILURE: {
      return {
        ...state,
        uploadTestrun: {
          ...state.uploadTestrun,
          updateTestrunResult: false,
        },
      };
    }
    case TestbedConstants.GET_TEST_COMPARE_RESULT_REQUEST: {
      return {
        ...state,
        fetchingTestbedResult: true,
      };
    }
    case TestbedConstants.GET_TEST_COMPARE_RESULT_SUCCESS: {
      const { testCompareResult } = action.payload;
      return {
        ...state,
        testCompareResult,
        fetchingTestbedResult: false,
      };
    }

    case TestbedConstants.SCHEDULE_COMPARE_TEST: {
      const { selectedTestruns } = action.payload;
      const tmpCompareResult: DTO.TestCompareResultResponse = {
        sourceServiceType: selectedTestruns[0]['serviceType'],
        sourceName: selectedTestruns[0]['name'],
        sourceRevision: selectedTestruns[0]['serviceVersion'],
        sourceSystem: selectedTestruns[0]['source'],
        targetServiceType: selectedTestruns[1]['serviceType'],
        targetName: selectedTestruns[1]['name'],
        targetRevision: selectedTestruns[1]['serviceVersion'],
        targetSystem: selectedTestruns[1]['source'],
      };
      return {
        ...state,
        testCompareResult: tmpCompareResult,
        fetchingTestbedResult: false,
      };
    }
    case TestbedConstants.GET_TEST_COMPARE_RESULT_FAILURE: {
      return {
        ...state,
        fetchingTestbedResult: false,
      };
    }
    case TestbedConstants.QUEUE_COMPARE_RUN_REQUEST: {
      return {
        ...state,
        savingTaskQueue: true,
        taskQueueId: null,
        taskQueueIdExists: null,
        taskQueueProgress: null,
      };
    }
    case TestbedConstants.QUEUE_COMPARE_RUN_SUCCESS: {
      const { taskQueueId, isExists } = action.payload;
      return {
        ...state,
        taskQueueId,
        taskQueueIdExists: isExists,
        savingTaskQueue: false,
      };
    }
    case TestbedConstants.QUEUE_COMPARE_RUN_FAILURE: {
      return {
        ...state,
        savingTesbedQueue: false,
        taskQueueIdExists: null,
      };
    }
    case TestbedConstants.GET_TEST_COMPARE_STATUS_REQUEST: {
      return {
        ...state,
        fetchingTaskQueue: true,
      };
    }
    case TestbedConstants.GET_TEST_COMPARE_STATUS_SUCCESS: {
      const { taskQueueProgress } = action.payload;
      return {
        ...state,
        taskQueueProgress,
        fetchingTaskQueue: false,
      };
    }
    case TestbedConstants.GET_TEST_COMPARE_STATUS_FAILURE: {
      return {
        ...state,
        fetchingTaskQueue: false,
      };
    }

    case TestbedConstants.GET_TESTRUN_REQUEST: {
      return {
        ...state,
        fetchingTestbedResult: true,
      };
    }

    case TestbedConstants.GET_TESTRUN_SUCCESS: {
      const { testrun } = action.payload;

      return {
        ...state,
        fetchingTestbedResult: false,
        selectedTestrun: testrun,
      };
    }

    case TestbedConstants.GET_TESTRUN_FAILURE: {
      return {
        ...state,
        fetchingTestbedResult: false,
      };
    }
    default:
      return state;
  }
};
