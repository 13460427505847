import { getConfig } from '../helpers/config';
import httpClient from '../helpers/http-client';
import makeRequest from '../helpers/make-request';
import uploadRequest from '../helpers/upload-request';

const trimSplash = (path: string) => path.replace(/^\/+|\/+$/g, '');

const getFolders = (folderPath: string) => {
  const url = `${
    getConfig().excelEngineDomain
  }/api/filemanager/GetFolderDocs?folderPath=${folderPath}`;

  return makeRequest<DTO.GetFoldersResponse>('GET', url);
};

const getVersions = (documentId: string) => {
  const config = getConfig();
  const tenant = localStorage.getItem('Tenant');
  const url = `${config.excelEngineDomain}/${tenant ||
    config.defaultTenant}/api/v3/files/document/${documentId}/versions`;

  return makeRequest<DTO.GetVersionsResponseV3>('GET', url);
};

const getFiles = (folderPath: string) => {
  const url =
    `${getConfig().excelEngineDomain}/api/filemanager/getdocumentlist` +
    `?path=${trimSplash(folderPath)}`;

  return makeRequest<DTO.GetFilesResponse>('POST', url);
};

const uploadFileToFolder = (
  folderPath: string,
  documentId: string | null,
  metadata: object | null,
  file: File,
  privacyLevel: string,
  onUploadProgress: (precent: number) => void,
  xhrRef: (xhr: XMLHttpRequest) => void
) => {
  const url = `${
    getConfig().excelEngineDomain
  }/api/filemanager/SaveDocument?folderPath=${documentId || folderPath}`;

  const formData = new FormData();

  formData.append('file', file);
  formData.append('privacyLevel', privacyLevel);

  if (metadata != null) {
    formData.append('metadata', JSON.stringify(metadata));
  }

  return uploadRequest('POST', url, formData, onUploadProgress, xhrRef);
};

const updateFile = (documentId: string, file: DTO.EntityDetail) => {
  const url = `${
    getConfig().excelEngineDomain
  }/api/filemanager/UpdateDocument/${documentId}`;

  return makeRequest('POST', url, file);
};

const updateFolder = (folderId: string, folder: DTO.EntityDetail) => {
  const url = `${
    getConfig().excelEngineDomain
  }/api/filemanager/UpdateFolder/${folderId}`;

  return makeRequest('POST', url, folder);
};

const createFolder = (absoluteFolderPath: string, privacyLevel: string) => {
  const url = `${
    getConfig().excelEngineDomain
  }/api/filemanager/createfolder/${privacyLevel}/${trimSplash(
    absoluteFolderPath
  )}`;

  return makeRequest<DTO.CreateNewFolderResponse>('POST', url);
};

const deleteFolder = (id: string) => {
  const url = `${
    getConfig().excelEngineDomain
  }/api/filemanager/deletefolder/${id}`;

  return makeRequest('DELETE', url);
};

const deleteFile = (fileID: string) => {
  const url = `${
    getConfig().excelEngineDomain
  }/api/filemanager/DeleteDocument/${fileID}`;

  return makeRequest('DELETE', url);
};

const getUserGroups = (folderPath: string) => {
  const url = `${
    getConfig().excelEngineDomain
  }/api/filemanager/getusergroups/${trimSplash(folderPath)}`;

  return makeRequest<DTO.GetUserGroupsResponse>('GET', url);
};

const getFileDetails = (fileId: string) => {
  const url = `${
    getConfig().excelEngineDomain
  }/api/filemanager/GetDocument/${fileId}`;

  return makeRequest<DTO.GetFileDetailsResponse>('GET', url);
};

const getFolder = (id: string) => {
  const url = `${
    getConfig().excelEngineDomain
  }/api/filemanager/GetFolder/${id}`;

  return makeRequest<DTO.GetFileDetailsResponse>('GET', url);
};

const getVersionMetadata = (versionId: string) => {
  const config = getConfig();
  const tenant = localStorage.getItem('Tenant');
  const url = `${config.excelEngineDomain}/${tenant ||
    config.defaultTenant}/api/v3/files/getdocument/versions/${versionId}`;
  return makeRequest<DTO.GetFileDetailsResponseV3>('GET', url);
};

const uploadProductZipFile = (
  data: {
    file: File;
    path: string;
  },
  onUploadProgress: (precent: number) => void,
  xhrRef: (xhr: XMLHttpRequest) => void
) => {
  const url = '/api/filemanager/uploadfolder';

  const formData = new FormData();

  const { file, path } = data;

  formData.append('file', file);
  formData.append('path', path);

  return uploadRequest<{ data: DTO.UploadProductZipFileResponse }>(
    'POST',
    url,
    formData,
    onUploadProgress,
    xhrRef
  );
};

const downloadFolder = (folderName: string, isAllVersions: boolean) => {
  const config = getConfig();
  const tenant = localStorage.getItem('Tenant');
  let url = `${config.excelEngineDomain}/${tenant ||
    config.defaultTenant}/api/v3/folders/${folderName}/download`;

  if (!isAllVersions) {
    url = `${url}/latest`;
  }

  return makeRequest<DTO.ResponseV3<DTO.DownloadUploadFolderAPIResponse>>('GET', url);
};

const getDownloadFileUrl = (
  fileId: string,
  fileName: string,
  token: string
) => {
  return `${
    getConfig().excelEngineDomain
  }/api/filemanager/DownloadDocument/${fileId}/${fileName}?token=${encodeURIComponent(
    token
  )}`;
};

const getDownloadFileByVersionUrl = (versionId: string, fileName: string) => {
  const url = `${
    getConfig().excelEngineDomain
  }/api/filemanager/DownloadDocumentByVersion/${versionId}/${fileName}`;
  return makeRequest<{ blob: Blob }>(
    'GET',
    url,
    undefined,
    undefined,
    undefined,
    undefined,
    true
  );
};

const getDownloadBlob = (fileId: string, fileName: string) => {
  const url = `${
    getConfig().excelEngineDomain
  }/api/filemanager/DownloadDocument/${fileId}/${fileName}`;
  return makeRequest<{ blob: Blob }>(
    'GET',
    url,
    undefined,
    undefined,
    undefined,
    undefined,
    true
  );
};

const downloadBlob = (url: string, type?: string) => {
  return makeRequest<{ blob: Blob; blobName: string }>(
    type ?? 'GET',
    url,
    undefined,
    undefined,
    undefined,
    undefined,
    true
  );
};

export const FileManagerService = {
  getFolders,
  getFiles,
  uploadFileToFolder,
  createFolder,
  updateFolder,
  getFileDetails,
  deleteFolder,
  deleteFile,
  updateFile,
  getUserGroups,
  uploadProductZipFile,
  downloadFolder,
  getFolder,
  getDownloadFileUrl,
  getDownloadFileByVersionUrl,
  getDownloadBlob,
  downloadBlob,
  getVersions,
  getVersionMetadata,
};
