import { OptionsServicesAction } from '../actions/types';
import { OptionsServicesConstants } from '../constants';
import { arrayRemoveFirst } from '../helpers';
import { buildPageRequestSearchFromApplyFilter } from '../helpers/optionsService';

export const initialState: STATES.OptionsServicesState = {
  services: {
    isLoading: false,
    error: '',
    list: [],
    total: 0,
  },

  createOrUpdateFilter: {
    isLoading: false,
    error: '',
  },

  getAppliedFilterDetail: {
    isLoading: false,
    error: '',
  },

  deleteSavedFilter: {
    isLoading: false,
    error: '',
  },

  savedFilter: {
    isLoading: false,
    error: '',
    list: [],
  },

  filterBar: {
    pagedRequest: {
      page: 1,
      page_size: 10,
      search: [],
    },
    appliedFilter: undefined,
  },

  products: {
    isLoading: false,
    error: '',
    list: [],
  },
};

export const optionsServices = (
  state = initialState,
  action: OptionsServicesAction
): STATES.OptionsServicesState => {
  switch (action.type) {
    case OptionsServicesConstants.UPDATE_PAGE_REQUEST: {
      const { data } = action.payload;
      return {
        ...state,
        filterBar: {
          ...state.filterBar,
          pagedRequest: data,
        },
      };
    }
    case OptionsServicesConstants.GET_OPTIONS_SERVICES_FILTER_DETAILS_REQUEST: {
      return {
        ...state,
        getAppliedFilterDetail: {
          ...state.getAppliedFilterDetail,
          isLoading: true,
          error: '',
        },
      };
    }
    case OptionsServicesConstants.APPLY_SAVED_FILTER_REQUEST: {
      const { data } = action.payload;
      const pageRequestSearchData: DTO.SearchV3[] = buildPageRequestSearchFromApplyFilter(
        data
      );
      return {
        ...state,
        getAppliedFilterDetail: {
          ...state.getAppliedFilterDetail,
          isLoading: false,
        },
        filterBar: {
          ...state.filterBar,
          pagedRequest: {
            ...initialState.filterBar.pagedRequest,
            search: pageRequestSearchData,
          },
          appliedFilter: {
            ...data,
          },
        },
      };
    }

    case OptionsServicesConstants.GET_OPTIONS_SERVICES_FILTER_DETAILS_FAILURE: {
      const { error } = action.payload;
      return {
        ...state,
        getAppliedFilterDetail: {
          ...state.getAppliedFilterDetail,
          isLoading: false,
          error,
        },
      };
    }

    case OptionsServicesConstants.RESET_APPLY_REQUEST: {
      return {
        ...state,
        filterBar: {
          ...initialState.filterBar,
        },
      };
    }

    case OptionsServicesConstants.RESET_OPTION_SERVICES_TO_INITIAL_STATE: {
      return {
        ...initialState,
      };
    }

    case OptionsServicesConstants.GET_OPTIONS_SERVICES_LIST_REQUEST: {
      return {
        ...state,
        services: {
          ...state.services,
          isLoading: true,
          error: '',
        },
      };
    }

    case OptionsServicesConstants.GET_OPTIONS_SERVICES_LIST_SUCCESS: {
      const { list, total } = action.payload;
      list.forEach(item => {
        item.key = item.id;
        if (typeof item.tags === 'string') {
          item.tags = item.tags.split(',').filter(item => item);
        }
      });
      return {
        ...state,
        services: {
          ...state.services,
          isLoading: false,
          list,
          total,
        },
      };
    }

    case OptionsServicesConstants.GET_OPTIONS_SERVICES_LIST_FAILURE: {
      const { error } = action.payload;
      return {
        ...state,
        services: {
          ...state.services,
          list: [],
          total: 0,
          isLoading: false,
          error,
        },
      };
    }

    case OptionsServicesConstants.CREATE_OR_UPDATE_OPTIONS_SERVICES_FILTER_REQUEST: {
      return {
        ...state,
        createOrUpdateFilter: {
          ...state.createOrUpdateFilter,
          isLoading: true,
          error: '',
        },
      };
    }

    case OptionsServicesConstants.REMOVE_HIGHLIGHT_SAVED_FILTER_LIST: {
      const { id } = action.payload;
      const oldSavedFilterList: DTO.OptionsServiceFilter[] =
        state.savedFilter.list;
      const updateFilterIndex = oldSavedFilterList.findIndex(
        filter => filter.id === id
      );
      if (updateFilterIndex !== -1) {
        oldSavedFilterList[updateFilterIndex] = {
          ...oldSavedFilterList[updateFilterIndex],
          id,
          shouldHighlightRow: false,
        };
      }
      return {
        ...state,
        savedFilter: {
          ...state.savedFilter,
          list: [...oldSavedFilterList],
        },
      };
    }

    case OptionsServicesConstants.CREATE_OR_UPDATE_OPTIONS_SERVICES_FILTER_SUCCESS: {
      const { id, filterName, shouldHighlightRow } = action.payload;
      const oldSavedFilterList: DTO.OptionsServiceFilter[] =
        state.savedFilter.list;
      const updateFilterIndex = oldSavedFilterList.findIndex(
        filter => filter.id === id
      );
      if (updateFilterIndex !== -1) {
        oldSavedFilterList[updateFilterIndex] = {
          id,
          filter_name: filterName,
          shouldHighlightRow,
        };
      } else {
        oldSavedFilterList.push({ id, filter_name: filterName });
        oldSavedFilterList.sort((a, b) =>
          a.filter_name.localeCompare(b.filter_name)
        );
      }

      return {
        ...state,
        savedFilter: {
          ...state.savedFilter,
          list: [...oldSavedFilterList],
        },
        createOrUpdateFilter: {
          ...state.createOrUpdateFilter,
          isLoading: false,
        },
      };
    }

    case OptionsServicesConstants.CREATE_OR_UPDATE_OPTIONS_SERVICES_FILTER_FAILURE: {
      const { error } = action.payload;
      return {
        ...state,
        createOrUpdateFilter: {
          ...state.createOrUpdateFilter,
          isLoading: false,
          error,
        },
      };
    }

    case OptionsServicesConstants.GET_OPTIONS_SERVICES_FILTERS_LIST_REQUEST: {
      return {
        ...state,
        savedFilter: {
          ...state.savedFilter,
          isLoading: true,
          error: '',
        },
      };
    }

    case OptionsServicesConstants.GET_OPTIONS_SERVICES_FILTERS_LIST_SUCCESS: {
      const { list } = action.payload;
      return {
        ...state,
        savedFilter: {
          ...state.savedFilter,
          isLoading: false,
          list,
        },
      };
    }

    case OptionsServicesConstants.GET_OPTIONS_SERVICES_FILTERS_LIST_FAILURE: {
      const { error } = action.payload;
      return {
        ...state,
        savedFilter: {
          ...state.savedFilter,
          list: [],
          isLoading: false,
          error,
        },
      };
    }

    case OptionsServicesConstants.DELETE_OPTIONS_SERVICES_FILTER_REQUEST: {
      return {
        ...state,
        deleteSavedFilter: {
          ...state.deleteSavedFilter,
          isLoading: true,
          error: '',
        },
      };
    }

    case OptionsServicesConstants.DELETE_OPTIONS_SERVICES_FILTER_SUCCESS: {
      const { id } = action.payload;
      const savedFilter: DTO.OptionsServiceFilter[] = JSON.parse(
        JSON.stringify(state.savedFilter.list)
      );
      const newSavedFilter = arrayRemoveFirst(
        savedFilter,
        item => item.id === id
      );

      return {
        ...state,
        savedFilter: {
          ...state.savedFilter,
          list: [...newSavedFilter],
        },
        deleteSavedFilter: {
          ...state.deleteSavedFilter,
          isLoading: false,
        },
      };
    }

    case OptionsServicesConstants.DELETE_OPTIONS_SERVICES_FILTER_FAILURE: {
      const { error } = action.payload;
      return {
        ...state,
        deleteSavedFilter: {
          ...state.deleteSavedFilter,
          isLoading: false,
          error,
        },
      };
    }

    case OptionsServicesConstants.GET_OPTIONS_SERVICES_PRODUCTS_REQUEST: {
      return {
        ...state,
        products: {
          ...state.products,
          isLoading: true,
          error: '',
        },
      };
    }

    case OptionsServicesConstants.GET_OPTIONS_SERVICES_PRODUCTS_SUCCESS: {
      const { list } = action.payload;

      return {
        ...state,
        products: {
          ...state.products,
          isLoading: false,
          list,
        },
      };
    }

    case OptionsServicesConstants.GET_OPTIONS_SERVICES_PRODUCTS_FAILURE: {
      const { error } = action.payload;

      return {
        ...state,
        products: {
          ...state.products,
          isLoading: false,
          error,
        },
      };
    }

    default:
      return state;
  }
};
