import { Button, Col, Input, Row } from 'antd';
import React, { FC, useState } from 'react';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { CustomAlert } from '../components/Common';
import {
  copyToClipboard,
  formatToLongDateTime,
  getValidUserAuth,
} from '../helpers';
import { store } from '../helpers/store';
import { StyledTokenModal } from './index.styles';

interface BearerTokensModalProps {
  visible: boolean;
  onClose: () => void;
}

const BearerTokensModal: FC<BearerTokensModalProps> = ({
  visible,
  onClose,
}) => {
  const { userAuth } = store.getState().auth;
  const [copied, setCopied] = useState(false);

  const getExpiryDateTime = () => {
    let data;
    if (userAuth?.accessToken) {
      data = getValidUserAuth({
        accessToken: userAuth?.accessToken,
      }).decodedJwt?.exp;
    }
    return data ? data * 1000 : null;
  };

  return (
    <>
      <StyledTokenModal
        className="Bearertokenmodal"
        visible={visible}
        closable
        getContainer={document.body}
        title={<FormattedMessage id="BearerTokensModal.heading" />}
        content={
          <>
            <Row className="Bearertokensdetails">
              <Col span={24}>
                <span className="title">
                  <FormattedMessage id="BearerTokensModal.lable" />
                  <b>
                    {userAuth?.accessToken &&
                      getValidUserAuth({
                        accessToken: userAuth?.accessToken || '',
                      }).decodedJwt?.preferred_username}
                  </b>
                </span>
              </Col>
              <Col span={20}>
                <Input
                  className="copy-input"
                  value={`Bearer ${userAuth?.accessToken || undefined}`}
                />
              </Col>
              <Col span={3}>
                <Button
                  type="default"
                  shape="round"
                  size="middle"
                  className="mw-100 ml-8"
                  key="BearerTokens_copy_btn"
                  onClick={e => {
                    e.preventDefault();
                    copyToClipboard(
                      `Bearer ${userAuth?.accessToken || undefined}`
                    );
                    setCopied(true);
                  }}
                >
                  <FormattedMessage id="Global.copy" />
                </Button>
              </Col>
              <Col span={24}>
                {copied && (
                  <CustomAlert
                    showIcon
                    type="success"
                    message={
                      <FormattedMessage id="BearerTokensModal.copy.clipboard" />
                    }
                  />
                )}
              </Col>
              <Col span={24}>
                <div className="BearerTokensModalhint">
                  <span data-testid="bearertokenshinttxt">
                    <FormattedMessage id="BearerTokensModal.hint" />
                  </span>
                  <span className="expireToken">
                    <FormattedHTMLMessage
                      id="BearerTokensModal.expireToken.date"
                      values={{
                        values: formatToLongDateTime(getExpiryDateTime()),
                      }}
                    />
                  </span>
                </div>
              </Col>
            </Row>
          </>
        }
        footer={
          <Button
            data-testid="about-modal-ok-btn"
            type="primary"
            shape="round"
            size="large"
            className="mw-200"
            onClick={() => {
              setCopied(false);
              onClose();
            }}
          >
            <FormattedMessage id="Global.ok" />
          </Button>
        }
        destroyOnClose
        onCancel={() => {
          setCopied(false);
          onClose();
        }}
      />
    </>
  );
};

export default BearerTokensModal;
