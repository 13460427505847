import { getConfig } from '../helpers';
import { getbaseUrl } from '../helpers/generic';
import makeRequest from '../helpers/make-request';

const getProxyObject = async (name: string) => {
  const url = `${getbaseUrl()}config/GetProxyObjectById/${name}`;
  return makeRequest<DTO.ResponseV3<DTO.ProxyObject>>('GET', url);
};

const getProxyObjectList = async () => {
  const url = `${getbaseUrl()}config/GetProxyObjectList`;
  return makeRequest<DTO.ResponseV3<DTO.ProxyObjectList[]>>('GET', url);
};

const assignUserGroupToProxyObject = async (
  entityId: string,
  userGroup: string
) => {
  const url = `${getbaseUrl()}config/AssignUserGroupToProxyObject`;
  return makeRequest<DTO.ResponseV3<{ success: boolean }>>('POST', url, {
    entityId,
    userGroup,
  });
};

const removeUserGroupFromProxyObject = async (
  entityId: string,
  userGroup: string
) => {
  const url = `${getbaseUrl()}config/RemoveUserGroupFromProxyObject`;
  return makeRequest<DTO.ResponseV3<{ success: boolean }>>('POST', url, {
    entityId,
    userGroup,
  });
};

const saveSparkConfig = async (config: { Configurations: DTO.SparkConfig }) => {
  const url = `${getbaseUrl()}config/SaveSparkConfig`;
  return makeRequest<DTO.ResponseV3<{ success: boolean }>>(
    'POST',
    url,
    config
  );
};

const getAllSparkConfig = async () => {
  const config = getConfig();
  const url = `${config.excelEngineDomain}/api/v1/config/GetAllSparkConfiguration`;
  return makeRequest<{ data: DTO.SparkConfig }>('GET', url);
};

const tenantConfigService = {
  getProxyObject,
  getProxyObjectList,
  assignUserGroupToProxyObject,
  removeUserGroupFromProxyObject,
  saveSparkConfig,
  getAllSparkConfig,
};

export { tenantConfigService };
