import { Empty, Popover, Row } from 'antd';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { matchPath, useHistory, useLocation } from 'react-router-dom';
import { createSelector } from 'reselect';
import {
  AlertActions,
  BackgroundJobsActions,
  TestbedActions,
} from '../actions';
import { BackgroundJobItem } from '../components/BackgroundJobs';
import { CustomButton, IconSvg } from '../components/Common';
import { TestbedConstants } from '../constants';
import {
  CLICKABLE_BACKGROUND_JOBS,
  CLICKABLE_BACKGROUND_JOBS_STATUS,
} from '../constants/backgroundJobs.constants';
import { useAppDispatch } from '../hooks';
import {
  PopoverStyle,
  StyledBGBackgroundLoader,
  StyledBGButton,
  StyledBGEmptyContainer,
  StyledBGFooter,
  StyledBGGroupHeading,
  StyledBGItemContainer,
  StyledBGJobContainer,
  StyledBGJobHeader,
  StyledBGJobList,
} from './index.styles';

interface JobGroup {
  key: string;
  value: DTO.BackgroundJob[];
}

export const backgroundJobSelector = createSelector<
  STATES.AppState,
  boolean,
  STATES.BackgroundJobsState,
  STATES.BackgroundJobsState & { loggedIn: boolean }
>(
  ({ auth }) => auth.loggedIn,
  ({ backgroundJobs }) => backgroundJobs,
  (loggedIn, backgroundJobs) => ({ ...backgroundJobs, loggedIn })
);

const BackgroundJobsPopover: FC = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const location = useLocation();

  const {
    jobs,
    refreshTrigger,
    intervalTime,
    loggedIn,
    jobsToDownload,
    count,
  } = useSelector(backgroundJobSelector);
  const timeoutRef = useRef(0);

  const [bgListVisible, setBgListVisible] = useState(false);
  const [groupedTasks, setGroupedTasks] = useState<JobGroup[]>([]);
  const backgroundJobsContainerRef = useRef<HTMLDivElement>(null);

  const [inprogressTasks, setInProgressTasks] = useState<DTO.BackgroundJob[]>(
    []
  );

  useEffect(() => {
    const jobGroups: JobGroup[] = [];
    jobs.forEach(job => {
      const found = jobGroups.some(el => el.key === job.type);
      if (!found) {
        jobGroups.push({ key: job.type, value: [job] });
      } else {
        jobGroups.forEach(jobGroup => {
          if (jobGroup.key === job.type) {
            jobGroup.value.push(job);
          }
        });
      }
    });

    // Find GenerateTestCases and GenerateSystematicTestCases objects
    const generateTestCases = jobGroups.find(
      obj => obj.key === 'GenerateTestCases'
    );
    const generateSystematicTestCases = jobGroups.find(
      obj => obj.key === 'GenerateSystematicTestCases'
    );

    // Concatenate value arrays if objects exist
    if (generateTestCases && generateSystematicTestCases) {
      generateTestCases.value = generateTestCases.value.concat(
        generateSystematicTestCases.value
      );

      // Remove GenerateSystematicTestCases object from data
      jobGroups.splice(jobGroups.indexOf(generateSystematicTestCases), 1);
    } else if (generateSystematicTestCases) {
      jobGroups.push({
        key: 'GenerateTestCases',
        value: generateSystematicTestCases.value,
      });

      // Remove GenerateSystematicTestCases object from data
      jobGroups.splice(jobGroups.indexOf(generateSystematicTestCases), 1);
    } else if (generateTestCases) {
      // No GenerateSystematicTestCases object, no action needed
    } else {
      // Neither GenerateTestCases nor GenerateSystematicTestCases objects exist, no action needed
    }

    setGroupedTasks(jobGroups);

    setInProgressTasks(
      jobs.filter(job => ['InProgress', 'Pending'].includes(job.status))
    );
  }, [jobs]);

  useEffect(() => {
    if (loggedIn) {
      dispatch(BackgroundJobsActions.getAll());
    }
  }, [loggedIn, refreshTrigger]);

  useEffect(() => {
    if (loggedIn) {
      timeoutRef.current = setInterval(() => {
        dispatch(BackgroundJobsActions.getAll());
      }, intervalTime);
    }

    return () => {
      clearInterval(timeoutRef.current);
    };
  }, [dispatch, refreshTrigger, intervalTime, loggedIn]);

  useEffect(() => {
    const jobsToTriggerDownload = Object.entries(jobsToDownload).reduce<{
      [jobId: string]: string | null;
    }>((prev, [jobId, isWaiting]) => {
      if (isWaiting) {
        const job = jobs.find(
          j =>
            j.id === jobId &&
            (j.type === 'DownloadFolder' ||
              j.type === 'DownloadLogs' ||
              j.type === 'DownloadLogsCSV' ||
              j.type === 'TestbedResultCSV' ||
              j.type === 'TestbedResultExcel' ||
              j.type === 'GenerateTestCases' ||
              j.type === 'GenerateSystematicTestCases' ||
              j.type === 'TestbedCSV' ||
              j.type === 'TestbedExcel' ||
              j.type === 'TestRunComparer' ||
              j.type === 'CompareService' ||
              j.type === 'DownloadLogsJson') &&
            j.status === 'Success'
        );
        if (job && job.status === 'Success') {
          if (job.type === 'TestbedExcel' || job.type === 'TestbedCSV') {
            if (job.type === 'TestbedExcel') {
              dispatch({
                type: TestbedConstants.BG_DOWNLOAD_TESTBED_TESTCASES_SUCCESS,
              });
            }
            dispatch(
              AlertActions.success('TestingCenter.testbeds.download.success')
            );
          } else if (
            job.type === 'GenerateTestCases' ||
            job.type === 'GenerateSystematicTestCases'
          ) {
            if (job.type === 'GenerateTestCases') {
              dispatch(
                AlertActions.success('TestingCenter.testbed.publish.success')
              );
            }
            const match = matchPath(location.pathname, {
              exact: true,
              path:
                '(/shared)?/products/:productId/:serviceName?/testcenter/testbeds',
            });
            if (match) {
              dispatch(BackgroundJobsActions.refreshEntity(job.data));
            }
          }
          prev[jobId] = job.result;
        }
      }

      return prev;
    }, {});

    dispatch(BackgroundJobsActions.triggerDownloadJobs(jobsToTriggerDownload));
  }, [jobsToDownload, jobs, dispatch]);

  const onCancelJob = useCallback(
    (job: DTO.BackgroundJob) => {
      if (job.type === 'RunTest') {
        TestbedActions.cancelTestbedQueue(dispatch, job.id);
      } else {
        BackgroundJobsActions.cancelTaskQueue(dispatch, job.id);
      }
    },
    [dispatch]
  );

  const onDeleteJob = useCallback(
    (job: DTO.BackgroundJob) => {
      dispatch(
        BackgroundJobsActions.deleteBackgroundJob(
          job.id,
          job.type === 'RunTest' ? 'TestbedQueueBatch' : 'TaskQueue'
        )
      );
    },
    [dispatch]
  );

  const isOpenableJob = (job: DTO.BackgroundJob) => {
    if (
      CLICKABLE_BACKGROUND_JOBS.includes(job.type) &&
      CLICKABLE_BACKGROUND_JOBS_STATUS.includes(job.status)
    ) {
      if (
        (job.type === 'GenerateTestCases' ||
          job.type === 'GenerateSystematicTestCases') &&
        job.status !== 'Fail' &&
        job.status !== 'Success'
      ) {
        return false;
      }
      return true;
    }

    return false;
  };

  const handleBackgroundJobItemClick = (job: DTO.BackgroundJob) => {
    if (
      CLICKABLE_BACKGROUND_JOBS.includes(job.type) &&
      CLICKABLE_BACKGROUND_JOBS_STATUS.includes(job.status) &&
      ((job.data.Product && job.data.ServiceName) ||
        (job.data.ProductName && job.data.EngineName))
    ) {
      switch (job.type) {
        case 'GenerateTestCases':
          if (job.status === 'Fail' || job.status === 'Success') {
            history.push(
              `/products/${job.data.Product}/${job.data.ServiceName}/testcenter/testbeds/${job.id}/GenerateTestCases/${job.data.Version}`
            );
          }
          break;
        case 'GenerateSystematicTestCases':
          if (job.status === 'Fail' || job.status === 'Success') {
            history.push(
              `/products/${job.data.Product}/${job.data.ServiceName}/testcenter/testbeds/${job.id}/GenerateSystematicTestCases/${job.data.Version}`
            );
          }
          break;
        case 'RunTest':
          history.push(
            `/products/${job.data.Product}/${job.data.ServiceName}/testcenter/testbeds/${job.data.TestbedId}/${job.data.TestBedName}/testrun/${job.id}`
          );
          break;
        case 'TestRunComparer':
          history.push(
            `/products/${job.data.Product}/${job.data.ServiceName}/testcenter/testbeds/${job.data.TestbedId}/${job.data.TestBedName}/compare/${job.id}/${job.data.SourceTestRunId}/${job.data.TargetTestRunId}`
          );
          break;
        case 'CompareService':
          history.push(
            `/products/${job.data.ProductName}/${job.data.EngineName}/versionOverview/history?id=${job.id}&SourceVersionId=${job.data.SourceVersionId}&DestinationVersionId=${job.data.DestinationVersionId}`
          );
          break;
        default:
          break;
      }

      setBgListVisible(false);
    }
  };

  const content = (
    <>
      <StyledBGJobHeader>
        {groupedTasks.length > 0 ? (
          <FormattedMessage
            id="BackgroundJobsPopover.popup.title"
            values={{ count }}
          />
        ) : (
          <FormattedMessage id="BackgroundJobsPopover.popup.title.empty" />
        )}
      </StyledBGJobHeader>
      <StyledBGJobList>
        {groupedTasks.length > 0 ? (
          groupedTasks.map(c => (
            <>
              <StyledBGGroupHeading>
                <FormattedMessage
                  id={`BackgroundJobsPopover.popup.group.${c.key}`}
                />
              </StyledBGGroupHeading>
              {c.value.map(job => (
                <StyledBGItemContainer
                  isPointer={isOpenableJob(job)}
                  role="link"
                  tabIndex={0}
                  onClick={() => handleBackgroundJobItemClick(job)}
                  onKeyDown={() => handleBackgroundJobItemClick(job)}
                >
                  <BackgroundJobItem
                    key={job.id}
                    job={job}
                    onCancel={onCancelJob}
                    onDelete={onDeleteJob}
                  />
                </StyledBGItemContainer>
              ))}
            </>
          ))
        ) : (
          <StyledBGEmptyContainer>
            <FormattedMessage id="BackgroundJobsPopover.popup.norecords" />
            <Empty description={false} image={Empty.PRESENTED_IMAGE_SIMPLE} />
          </StyledBGEmptyContainer>
        )}
      </StyledBGJobList>
      <StyledBGFooter>
        <CustomButton type="link" label="BackgroundJobsPopover.popup.goto" />
      </StyledBGFooter>
    </>
  );

  const bgListVisibleOnChange = (next: boolean) => {
    setBgListVisible(next);
  };

  return (
    <>
      <PopoverStyle />
      <Row align="middle">
        <div ref={backgroundJobsContainerRef} />
        <Popover
          overlayClassName="background-jobs"
          content={content}
          placement="bottomRight"
          visible={bgListVisible}
          trigger="click"
          onVisibleChange={bgListVisibleOnChange}
          getPopupContainer={() =>
            backgroundJobsContainerRef.current || document.body
          }
        >
          <StyledBGJobContainer>
            {inprogressTasks.length > 0 && <StyledBGBackgroundLoader />}
            <StyledBGButton type="default" data-testid="background-activity">
              <FormattedMessage id="BackgroundJobsPopover.button.title" />
              <IconSvg
                type={bgListVisible ? 'CaretUpFilled' : 'CaretDownFilled'}
              />
            </StyledBGButton>
          </StyledBGJobContainer>
        </Popover>
      </Row>
    </>
  );
};

export default BackgroundJobsPopover;
