export const XCSV_INPUT = 'XCSVInput';
export const WARNING_CODE_ENGINE_EXISTS_ALREADY = 'ENGINE_EXISTS_ALREADY';

export const ADD_ENGINE_UPLOAD_START = 'ADD_ENGINE_UPLOAD_START';
export const ADD_ENGINE_UPLOAD_XHR_REF = 'ADD_ENGINE_UPLOAD_XHR_REF';
export const ADD_ENGINE_UPLOAD_PROGRESS = 'ADD_ENGINE_UPLOAD_PROGRESS';
export const ADD_ENGINE_EXECUTE_START = 'ADD_ENGINE_EXECUTE_START';
export const ADD_ENGINE_EXECUTE_PROGRESS = 'ADD_ENGINE_EXECUTE_PROGRESS';
export const ADD_ENGINE_SUBMIT_RELEASE_NOTE = 'ADD_ENGINE_SUBMIT_RELEASE_NOTE';
export const UPDATE_ACTIVE_PERIOD = 'UPDATE_ACTIVE_PERIOD';
export const UPDATE_RELEASE_NOTE = 'UPDATE_RELEASE_NOTE';
export const ADD_ENGINE_SUCCESS = 'ADD_ENGINE_SUCCESS';
export const ADD_ENGINE_ERROR = 'ADD_ENGINE_ERROR';
export const ADD_ENGINE_RESET = 'ADD_ENGINE_RESET';
export const CANCEL_UPLOAD_ENGINE = 'CANCEL_UPLOAD_ENGINE';
export const PRODUCT_ENGINES_RESET = 'PRODUCT_ENGINES_RESET';
export const PRODUCT_ENGINE_SELECTION_RESET = 'PRODUCT_ENGINE_SELECTION_RESET';

export const UPDATE_ENGINE_FAVORITE_REQUEST = 'UPDATE_ENGINE_FAVORITE_REQUEST';
export const UPDATE_ENGINE_FAVORITE_SUCCESS = 'UPDATE_ENGINE_FAVORITE_SUCCESS';
export const UPDATE_ENGINE_FAVORITE_FAILURE = 'UPDATE_ENGINE_FAVORITE_FAILURE';

export const GET_PRODUCT_ENGINES_REQUEST = 'GET_PRODUCT_ENGINES_REQUEST';
export const GET_PRODUCT_ENGINES_SUCCESS = 'GET_PRODUCT_ENGINES_SUCCESS';
export const GET_PRODUCT_ENGINES_FAILURE = 'GET_PRODUCT_ENGINES_FAILURE';

export const GET_PRODUCT_ENGINE_DETAILS_REQUEST =
  'GET_PRODUCT_ENGINE_DETAILS_REQUEST';
export const GET_PRODUCT_ENGINE_DETAILS_SUCCESS =
  'GET_PRODUCT_ENGINE_DETAILS_SUCCESS';
export const GET_PRODUCT_ENGINE_DETAILS_FAILURE =
  'GET_PRODUCT_ENGINE_DETAILS_FAILURE';
export const GET_PRODUCT_ENGINE_DETAILS_SUCCESS_NO_SELECT =
  'GET_PRODUCT_ENGINE_DETAILS_SUCCESS_NO_SELECT';

export const UPDATE_ENGINE_PROPS_REQUEST = 'UPDATE_ENGINE_PROPS_REQUEST';
export const UPDATE_ENGINE_PROPS_SUCCESS = 'UPDATE_ENGINE_PROPS_SUCCESS';
export const UPDATE_ENGINE_PROPS_FAILURE = 'UPDATE_ENGINE_PROPS_FAILURE';

export const RECOMPILE_NODEGEN_REQUEST = 'RECOMPILE_NODEGEN_REQUEST';
export const RECOMPILE_NODEGEN_SUCCESS = 'RECOMPILE_NODEGEN_SUCCESS';
export const RECOMPILE_NODEGEN_FAILURE = 'RECOMPILE_NODEGEN_FAILURE';

export const UPDATE_ENGINE_PROPS = 'UPDATE_ENGINE_PROPS';
export const SHOW_UPDATE_CONFIRM = 'SHOW_UPDATE_CONFIRM';
export const CANCEL_UPDATE_CONFIRM = 'CANCEL_UPDATE_CONFIRM';

export const SHOW_ENGINE_UPLOAD_SUMMARY = 'SHOW_ENGINE_UPLOAD_SUMMARY';
export const CANCEL_PUBLISH_ENGINE = 'CANCEL_PUBLISH_ENGINE';

export const DELETE_ENGINE_REQUEST = 'DELETE_ENGINE_REQUEST';
export const DELETE_ENGINE_SUCCESS = 'DELETE_ENGINE_SUCCESS';
export const DELETE_ENGINE_FAILURE = 'DELETE_ENGINE_FAILURE';

export const API_TEST_EXECUTE = 'API_TEST_EXECUTE';
export const API_TEST_EXECUTE_SUCCESS = 'API_TEST_EXECUTE_SUCCESS';
export const API_TEST_EXECUTE_SUCCESS_NO_APICALL =
  'API_TEST_EXECUTE_SUCCESS_NO_APICALL';
export const API_TEST_EXECUTE_FAILURE = 'API_TEST_EXECUTE_FAILURE';
export const API_TEST_RESET = 'API_TEST_RESET';
export const API_TESTER_XLINPUT_RESET = 'API_TESTER_XLINPUT_RESET';
export const API_TEST_SHOW_ED_WARNING_MODAL = 'API_TEST_SHOW_ED_WARNING_MODAL';
export const API_TEST_HIDE_ED_WARNING_MODAL = 'API_TEST_HIDE_ED_WARNING_MODAL';

export const API_TEST_EXECUTE_INPUTS = 'API_TEST_EXECUTE_INPUTS';
export const API_TEST_EXECUTE_INPUTS_SUCCESS =
  'API_TEST_EXECUTE_INPUTS_SUCCESS';
export const API_TEST_EXECUTE_INPUTS_FAILURE =
  'API_TEST_EXECUTE_INPUTS_FAILURE';

export const GET_EXECUTE_OUTPUT_REQUEST = 'GET_EXECUTE_OUTPUT_REQUEST';
export const GET_EXECUTE_OUTPUT_SUCCESS = 'GET_EXECUTE_OUTPUT_SUCCESS';
export const GET_EXECUTE_OUTPUT_FAILURE = 'GET_EXECUTE_OUTPUT_FAILURE';

export const GET_ENGINE_VERSIONS_REQUEST = 'GET_ENGINE_VERSIONS_REQUEST';
export const GET_ENGINE_VERSIONS_SUCCESS = 'GET_ENGINE_VERSIONS_SUCCESS';
export const GET_ENGINE_VERSIONS_FAILURE = 'GET_ENGINE_VERSIONS_FAILURE';
export const SELECTED_ENGINE_VERSIONS_RESET = 'SELECTED_ENGINE_VERSIONS_RESET';

export const CUSTOM_URL_REQUEST = 'CUSTOM_URL_REQUEST';
export const CUSTOM_URL_SUCCESS = 'CUSTOM_URL_SUCCESS';
export const CUSTOM_URL_FAILURE = 'CUSTOM_URL_FAILURE';

export const CUSTOM_URL_DELETE_SUCCESS = 'CUSTOM_URL_DELETE_SUCCESS';

export const RESTORE_VERSION_REQUEST = 'RESTORE_VERSION_REQUEST';
export const RESTORE_VERSION_SUCCESS = 'RESTORE_VERSION_SUCCESS';
export const RESTORE_VERSION_FAILURE = 'RESTORE_VERSION_FAILURE';

export const CREATE_ENGINE_CHAIN_REQUEST = 'CREATE_ENGINE_CHAIN_REQUEST';
export const CREATE_ENGINE_CHAIN_SUCCESS = 'CREATE_ENGINE_CHAIN_SUCCESS';
export const CREATE_ENGINE_CHAIN_FAILURE = 'CREATE_ENGINE_CHAIN_FAILURE';

export const DELETE_ENGINE_CHAIN_REQUEST = 'DELETE_ENGINE_CHAIN_REQUEST';
export const DELETE_ENGINE_CHAIN_SUCCESS = 'DELETE_ENGINE_CHAIN_SUCCESS';
export const DELETE_ENGINE_CHAIN_FAILURE = 'DELETE_ENGINE_CHAIN_FAILURE';

export const GET_ENGINE_CHAIN_REQUEST = 'GET_ENGINE_CHAIN_REQUEST';
export const GET_ENGINE_CHAIN_SUCCESS = 'GET_ENGINE_CHAIN_SUCCESS';
export const GET_ENGINE_CHAIN_FAILURE = 'GET_ENGINE_CHAIN_FAILURE';

export const GET_ENGINE_UNCHAIN_CHAIN_REQUEST =
  'GET_ENGINE_UNCHAIN_CHAIN_REQUEST';
export const GET_ENGINE_UNCHAIN_CHAIN_SUCCESS =
  'GET_ENGINE_UNCHAIN_CHAIN_SUCCESS';
export const GET_ENGINE_UNCHAIN_CHAIN_FAILURE =
  'GET_ENGINE_UNCHAIN_CHAIN_FAILURE';

export const ENGINE_CHAIN_MISMATCH_ERROR = 'ENGINE_CHAIN_MISMATCH_ERROR';

export const EXCEXCUTE_PRODUCT_ENGINCE_CHAIN_SUCCESS =
  'EXCEXCUTE_PRODUCT_ENGINCE_CHAIN_SUCCESS';
export const EXCEXCUTE_PRODUCT_ENGINCE_CHAIN_REQUEST =
  'EXCEXCUTE_PRODUCT_ENGINCE_CHAIN_REQUEST';
export const EXCEXCUTE_PRODUCT_ENGINCE_CHAIN_FAILURE =
  'EXCEXCUTE_PRODUCT_ENGINCE_CHAIN_FAILURE';

export const DEFAULT_VALUE_WARNING = 'Input parameter missing.';

export const DOWNLOAD_ENGINE_REQUEST = 'DOWNLOAD_ENGINE_REQUEST';
export const DOWNLOAD_ENGINE_SUCCESS = 'DOWNLOAD_ENGINE_SUCCESS';
export const DOWNLOAD_ENGINE_FAILURE = 'DOWNLOAD_ENGINE_FAILURE';

export const API_TESTER_DOCUMENTATION_SUCCESS =
  'API_TESTER_DOCUMENTATION_SUCCESS';
export const API_TESTER_DOCUMENTATION_FAILED =
  'API_TESTER_DOCUMENTATION_FAILED';
export const API_TESTER_DOCUMENTATION_REQUEST =
  'API_TESTER_DOCUMENTATION_FAILED';

export const API_DOCS_DOWNLOAD_SUCCESS = 'API_DOCS_DOWNLOAD_SUCCESS';
export const API_DOCS_DOWNLOAD_REQUEST = 'API_DOCS_DOWNLOAD_REQUEST';
export const API_DOCS_DOWNLOAD_FAILURE = 'API_DOCS_DOWNLOAD_FAILURE';

export const RESET_ENGINE_UPLOAD = 'RESET_ENGINE_UPLOAD';

export const EXECUTE_API_CALL_PURPOSE_API_TESTER = 'Spark - API Tester';
export const VALIDATION_API_CALL_PURPOSE_API_TESTER = 'Spark - API Tester';
export const VALIDATION_API_CALL_PURPOSE_TESTING_CENTER =
  'Spark - Testing Center';

export const ACIVATE_SERVICE_REQUEST = 'ACIVATE_SERVICE_REQUEST';
export const ACIVATE_SERVICE_SUCCESS = 'ACIVATE_SERVICE_SUCCESS';
export const ACIVATE_SERVICE_FAILURE = 'ACIVATE_SERVICE_FAILURE';

export const NO_ACTIVE_VERSION = 'NO_ACTIVE_VERSION';
export const ENGINE_INACTIVE_WARNING = 'ENGINE_INACTIVE_WARNING';
export const PROXY_URL_ALREADY_EXISTS = 'PROXY_URL_ALREADY_EXISTS';

export const API_FORMATE = [
  {
    key: 'V1',
    value: 'V1',
    title: 'V1',
  },
  {
    key: 'V3',
    value: 'V3',
    title: 'V3',
  },
];

export const API_TESTER_PERSIST_SERVICE_PARAMETERS_CHANGE =
  'API_TESTER_PERSIST_SERVICE_PARAMETERS_CHANGE';
export const API_TESTER_PERSIST_SERVICE_PARAMETERS_RESET =
  'API_TESTER_PERSIST_SERVICE_PARAMETERS_RESET';

export const ENGINE_PROPERTY_CONTINUOS_VERSION_OFF = 'Off';
export const ENGINE_PROPERTY_CONTINUOS_VERSION_WARNING = 'Warning';
export const ENGINE_PROPERTY_CONTINUOS_VERSION_ERROR = 'Error';

export const ENGINE_PROPERTIES_METADATA: DTO.EnginePropertyInfo[] = [
  { type: 'text', name: 'Author' },
  {
    type: 'select',
    name: 'AllowToStoreHistory',
    options: [
      { key: 'True', value: 'True', title: 'True' },
      { key: 'False', value: 'False', title: 'False' },
    ],
  },
  {
    type: 'select',
    name: 'PublicAPI',
    options: [
      { key: 'True', value: 'True', title: 'True' },
      { key: 'False', value: 'False', title: 'False' },
    ],
  },
  { type: 'link', name: 'PublicAPIURI' },
  {
    type: 'select',
    name: 'DirectAddressingOutputsEnabled',
    options: [
      { key: 'True', value: 'True', title: 'True' },
      { key: 'False', value: 'False', title: 'False' },
    ],
  },
  {
    type: 'select',
    name: 'VersionUpgradeAssert',
    options: [
      {
        value: 'OFF',
        key: ENGINE_PROPERTY_CONTINUOS_VERSION_OFF,
        title: ENGINE_PROPERTY_CONTINUOS_VERSION_OFF,
      },
      {
        value: 'WARN',
        key: ENGINE_PROPERTY_CONTINUOS_VERSION_WARNING,
        title: ENGINE_PROPERTY_CONTINUOS_VERSION_WARNING,
      },
      {
        value: 'ERROR',
        key: ENGINE_PROPERTY_CONTINUOS_VERSION_ERROR,
        title: ENGINE_PROPERTY_CONTINUOS_VERSION_ERROR,
      },
    ],
  },
  { type: 'link', name: 'ExplainerType' },
  { type: 'explainerButton', name: 'ExplainerTypeQrCode' },
  { type: 'link', name: 'ExplainerCodePackage' },
  { type: 'link', name: 'SparkFormUrl' },
  { type: 'explainerButton', name: 'SparkFormQrCode' },
  { type: 'link', name: 'SparkFormEditUrl' },
  { type: 'text', name: 'XConnectorFullUrl' },
  { type: 'text', name: 'ServiceUuid' },
  { type: 'number', name: 'BatchSizeOverride' },
];

export const READ_DATA_VALIDATION_REQUEST = 'READ_DATA_VALIDATION_REQUEST';
export const READ_DATA_VALIDATION_SUCCESS = 'READ_DATA_VALIDATION_SUCCESS';
export const READ_DATA_VALIDATION_FAILURE = 'READ_DATA_VALIDATION_FAILURE';

export const NEURON_BETA = 'Neuron_Beta';
export const NEURON = 'Neuron';
export const NODEGEN = 'NodeGen';
export const APICONNECTOR = 'APIConnector';
export const XCONNECTOR = 'XConnector';
export const NODEGEN_BETA = 'NodeGen_Beta';
export const XInput_BlankOption = '#SPARKBLANKOPTION#';

export const GET_ALL_CODE_LANGUAGE_REQUEST = 'GET_ALL_CODE_LANGUAGE_REQUEST';
export const GET_ALL_CODE_LANGUAGE_SUCCESS = 'GET_ALL_CODE_LANGUAGE_SUCCESS';
export const GET_ALL_CODE_LANGUAGE_FAILURE = 'GET_ALL_CODE_LANGUAGE_FAILURE';

export const GET_CODE_CONTENT_REQUEST = 'GET_CODE_CONTENT_REQUEST';
export const GET_CODE_CONTENT_SUCCESS = 'GET_CODE_CONTENT_SUCCESS';
export const GET_CODE_CONTENT_FAILURE = 'GET_CODE_CONTENT_FAILURE';

export const GET_RECOMPILE_LOGS_REQUEST = 'GET_RECOMPILE_LOGS_REQUEST';
export const GET_RECOMPILE_LOGS_SUCCESS = 'GET_RECOMPILE_LOGS_SUCCESS';
export const GET_RECOMPILE_LOGS_FAILURE = 'GET_RECOMPILE_LOGS_FAILURE';

export const GET_RECOMPILE_PROGESS_REQUEST = 'GET_RECOMPILE_PROGESS_REQUEST';
export const GET_RECOMPILE_PROGESS_SUCCESS = 'GET_RECOMPILE_PROGESS_SUCCESS';
export const GET_RECOMPILE_PROGESS_FAILURE = 'GET_RECOMPILE_PROGESS_FAILURE';

export const RESET_RECOMPILE_PROGESS = 'RESET_RECOMPILE_PROGESS';
export const SET_HIGHLIGHT_DATA = 'SET_HIGHLIGHT_DATA';
export const CLEAR_HIGHLIGHT_DATA = 'CLEAR_HIGHLIGHT_DATA';

export const mapBetweenSummaryAndProperties = {
  bookDescription: 'Description',
  effectiveStartDate: 'Effective-Start-Date',
  effectiveEndDate: 'Effective-End-Date',
  bookAuthor: 'Author',
  bookUploaderEmail: 'UploaderEmail',
  bookReleaseDate: 'ReleaseDate',
  bookVersionReleaseNotes: 'ReleaseNote',
  versionLabel: 'VersionLabel',
  compilerVersionServiceUpdate: 'CompilerVersionServiceUpdate',
};

export const defaultCompilerVersionForNewlyUploadedServices = [
  { key: 'StableLatest', value: 'StableLatest', title: 'Stable Latest' },
  {
    key: 'ReleaseCandidate',
    value: 'ReleaseCandidate',
    title: 'Release Candidate',
  },
];

export const neuronCompilationTypes = [
  { key: 'TenantDefault', value: 'TenantDefault', title: 'Tenant Default' },
  {
    key: 'MaintainVersion',
    value: 'MaintainVersion',
    title: 'Maintain Version',
  },
  ...defaultCompilerVersionForNewlyUploadedServices,
];
