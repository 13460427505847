import { SystemAction, UserAction } from '../actions/types';
import {
  SystemConstants,
  UserConstants,
  CategoryManagerConstants,
} from '../constants';

export const initialState: STATES.SystemState = {
  isLoading: false,
  diagnoseDetails: null,
  isLoadingReleaseDetails: false,
  releaseDetails: null,
  isLoadingLookupData: false,
  lookupData: null,
  productDocMetadata: [],
  isLoadingProductDocMetadata: false,
  isFetchingConfig: false,
  updateBannerVisiblity: false,
  cachedlogoURL: { filePath: '' },
  appConfig: {
    GitBookUrl: '',
    ShowUsageDetails: '',
    ShowIngeniumConfiguration: '',
    ShowConfigurationFiles: '',
    ShowTemplates: '',
    ShowCategories: '',
    ShowInsights: '',
    EnableActiveService: '',
    MaxActiveServieCount: 0,
    ServiceDownloadFileTypesGitbookUrl: '',
    SupportedEngineTypes_UI: '',
    ApiFormatGitbookUrl: '',
    ServiceTypeGitbookUrl: '',
    ReleaseNoteGitbookUrl: '',
    UploadFileSizeLimit_InBytes: 0,
    ShowFiles: '',
    IsPrivateSharingTenant: '',
    MaintenanceEndDateTime: '',
    MaintenanceStartDateTime: '',
    NodeGenCompileMode: '',
    ShowMaintenanceBanner: false,
    TimeZoneOffset: 0,
    EnableCustomBranding: false,
    CustomBrandingImageGetURL: '',
    ShowTenantConfigPage: 'false',
    EnableTags: 'false',
    CompilerVersions_Neuron: '',
    CompilerVersion_NeuronStableLatest: '',
    CompilerVersion_NeuronRC: '',
    SparkInputOutputFormSpecMode: 'Off',
    Tags: [],
    APICallHistory_DownloadLimit: 0,
    KeycloakIntegration: '',
    TagsSelected: '',
    EnableWebhooks: '',
    ImpExSizeLimit_InBytes: '',
  },
};

export const system = (
  state = initialState,
  action: SystemAction | UserAction
): STATES.SystemState => {
  switch (action.type) {
    case SystemConstants.GET_LOOKUP_DATA_REQUEST:
    case CategoryManagerConstants.DELETE_CATEGORY_REQUEST:
    case CategoryManagerConstants.SAVE_CATEGORY_REQUEST: {
      return {
        ...state,
        isLoadingLookupData: true,
      };
    }
    case CategoryManagerConstants.SAVE_CATEGORY_SUCCESS:
    case CategoryManagerConstants.DELETE_CATEGORY_SUCCESS:
    case SystemConstants.GET_LOOKUP_DATA_SUCCESS: {
      const { lookupData } = action.payload;

      return {
        ...state,
        lookupData,
        isLoadingLookupData: false,
      };
    }
    case SystemConstants.GET_LOOKUP_DATA_FAILURE: {
      return {
        ...state,
        isLoadingLookupData: false,
      };
    }

    case SystemConstants.GET_PRODUCT_DOCUMENT_METADATA_REQUEST: {
      return {
        ...state,
        isLoadingProductDocMetadata: true,
      };
    }
    case SystemConstants.GET_PRODUCT_DOCUMENT_METADATA_SUCCESS: {
      const { metadata } = action.payload;

      return {
        ...state,
        productDocMetadata: metadata,
        isLoadingProductDocMetadata: false,
      };
    }
    case SystemConstants.GET_PRODUCT_DOCUMENT_METADATA_FAILURE: {
      return {
        ...state,
        isLoadingProductDocMetadata: false,
      };
    }

    case SystemConstants.GET_RELEASE_DETAILS_REQUEST: {
      return {
        ...state,
        isLoadingReleaseDetails: true,
      };
    }
    case SystemConstants.GET_RELEASE_DETAILS_SUCCESS: {
      const { releaseDetails } = action.payload;
      return {
        ...state,
        releaseDetails,
        isLoadingReleaseDetails: false,
      };
    }
    case SystemConstants.GET_RELEASE_DETAILS_FAILURE: {
      return {
        ...state,
        isLoadingReleaseDetails: false,
      };
    }
    case SystemConstants.CLEAR_CACHE_MEMORY_REQUEST:
    case SystemConstants.CLEAR_APPLICATION_MEMORY_REQUEEST:
    case SystemConstants.GET_DIAGNOSE_DETAILS_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case SystemConstants.CLEAR_CACHE_MEMORY_SUCCESS:
    case SystemConstants.CLEAR_APPLICATION_MEMORY_SUCCESS:
    case SystemConstants.GET_DIAGNOSE_DETAILS_SUCCESS: {
      const { diagnoseDetails } = action.payload;
      return {
        ...state,
        isLoading: false,
        diagnoseDetails,
      };
    }
    case SystemConstants.CLEAR_CACHE_MEMORY_FAILURE:
    case SystemConstants.CLEAR_APPLICATION_MEMORY_FAILURE:
    case SystemConstants.GET_DIAGNOSE_DETAILS_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case UserConstants.LOGGED_OUT:
    case UserConstants.LOGIN_SUCCESS: {
      return {
        ...initialState,
      };
    }

    case SystemConstants.GET_APPCONFIG_REQUEST: {
      return {
        ...state,
        isFetchingConfig: true,
      };
    }

    case SystemConstants.GET_APPCONFIG_SUCCESS: {
      const { config } = action.payload;

      if (config.Tags) {
        config.Tags = Object.keys(config.Tags).map(key => {
          return { Key: key, Value: key };
        });
      }
      return {
        ...state,
        isFetchingConfig: false,
        appConfig: config,
      };
    }

    case SystemConstants.GET_APPCONFIG_FAILURE: {
      return {
        ...state,
        isFetchingConfig: false,
      };
    }
    case SystemConstants.UPDATE_BANNER_VISIBLITY: {
      return {
        ...state,
        updateBannerVisiblity: !state.updateBannerVisiblity,
      };
    }

    case SystemConstants.GET_CUSTOMBRANDINGIMAGE_SUCCESS: {
      const { imageUrl } = action.payload;

      const cachedlogoURL = {
        ...state.cachedlogoURL,
        filePath: imageUrl,
      };

      return {
        ...state,
        cachedlogoURL,
      };
    }

    case SystemConstants.GET_CUSTOMBRANDINGIMAGE_FAILURE: {
      const cachedlogoURL = {
        ...state.cachedlogoURL,
        filePath: '',
      };

      return {
        ...state,
        cachedlogoURL,
      };
    }
    default:
      return state;
  }
};
