import { addDocument } from './adddocument.reducer';
import { addEngine } from './addengine.reducer';
import { addFile } from './addFile.reducer';
import { alertMessage } from './alert.reducers';
import { apiTester } from './apiTester.reducer';
import { auth } from './auth.reducer';
import { backgroundJobsReducer } from './backgroundJobs.reducer';
import { categoryManager } from './categoryManager.reducer';
import { bookmark } from './bookmark.reducer';
import { dmlFile } from './dmlFile.reducer';
import { downloadLogs } from './downloadLogs.reducer';
import { engineViewLogs } from './engineViewLogs.reducer';
import { entityPermission } from './entityPermission.reducer';
import { fileManager } from './fileManager.reducer';
import { ingenium } from './ingenium.reducer';
import { insights } from './insights.reducer';
import { language } from './language.reducer';
import { productChanges } from './productChanges.reducer';
import { productDocs } from './productDocs.reducer';
import { productEngines } from './productEngines.reducer';
import { productsState as products } from './products.reducer';
import { shareProducts } from './shareProducts.reducer';
import { system } from './system.reducer';
import { templateManager } from './templateManager.reducer';
import { testbeds } from './testBed.reducer';
import { theme } from './theme.reducer';
import { service } from './service.reducer';
import { userState as user } from './user.reducer';
import { tenantConfig } from './tenantConfig.reducer';
import { versionOverview } from './versionOverview.reducer';
import { optionsServices } from './optionsServices.reducer';
import { systematicTestcaseGeneration } from './systematicTestcaseGeneration.reducer';
import { deployment } from './deployment.reducer';
import { dashboard } from './dashboard.reducer';

const rootReducer = {
  auth,
  user,
  language,
  theme,
  addEngine,
  products,
  shareProducts,
  productEngines,
  engineViewLogs,
  apiTester,
  addDocument,
  productDocs,
  productChanges,
  fileManager,
  addFile,
  system,
  alertMessage,
  backgroundJobs: backgroundJobsReducer,
  dmlFile,
  categoryManager,
  testbeds,
  templateManager,
  bookmark,
  ingenium,
  downloadLogs,
  entityPermission,
  insights,
  service,
  tenantConfig,
  versionOverview,
  optionsServices,
  systematicTestcaseGeneration,
  deployment,
  dashboard
};

export default rootReducer;
