import React from 'react';
import { Typography } from 'antd';
import { FormattedHTMLMessage } from 'react-intl';
import { WarningOutlined } from '@ant-design/icons';
import { FileUploadErrorContainer } from '../index.styles';

const { Text } = Typography;

interface FileUploadErrorProps {
  title?: string;
  error?: string;
  errorValue?: string;
}

const FileUploadError: React.FC<FileUploadErrorProps> = ({
  title,
  error,
  errorValue,
}) => (
  <FileUploadErrorContainer className="upload-error">
    <WarningOutlined />
    <Text>
      <FormattedHTMLMessage
        tagName="div"
        id={error || 'FileUpload.error.default' || title}
        values={{ values: errorValue }}
      />
    </Text>
  </FileUploadErrorContainer>
);

export default React.memo(FileUploadError);
