import { Button } from 'antd';
import React, { ReactNode, useEffect, useState, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { handleFileDrag } from '../../helpers/file-upload';
import { StyledCard, StyledDragger } from '../index.styles';
import FileUploadDefaultContent from './FileUploadDefaultContent';
import FileUploadError from './FileUploadError';
import FileUploadProgress from './FileUploadProgress';

type FileUploadProps = {
  className?: string;
  accept?: string;
  uploadProgress?: number | null;
  disabled?: boolean;
  executeProgress?: number | null;
  error?: string | null;
  onFileSelect: (file: File) => void;
  onFileBeforeUpload?: (file: File) => void;
  previewView?: ReactNode | (() => ReactNode);
  defaultAddBtnText?: string;
  defaultAddBtnDesc?: string;
  uploadProgressTitle?: string;
  uploadSuccessTitle?: string;
  uploadErrorTitle?: string;
  executionProgressTitle?: string;
  executionSuccessTitle?: string;
  executionErrorTitle?: string;
  width?: number;
  isImage?: boolean;
  onCancelButtonClick?: () => void;
  displayErrorMsgOutside?: boolean;
  resetUpload?: () => void;
  allowBrowseOnError?: boolean;
  id?: string;
  onErrorShowDesc?: boolean;
  dataTestId?: string;
  maxUploadSize?: number;
};

const mapStateToProps = ({ system: { appConfig } }: STATES.AppState) => ({
  appConfig,
});

const FileUpload: React.FC<FileUploadProps> = ({
  className,
  uploadProgress,
  executeProgress,
  onFileSelect,
  onFileBeforeUpload,
  error,
  defaultAddBtnDesc,
  defaultAddBtnText,
  disabled,
  previewView,
  accept = '.xlsx,.xlsm',
  isImage,
  executionProgressTitle,
  executionSuccessTitle,
  executionErrorTitle,
  uploadProgressTitle,
  uploadSuccessTitle,
  uploadErrorTitle,
  width = 400,
  onCancelButtonClick,
  displayErrorMsgOutside,
  allowBrowseOnError,
  resetUpload,
  id,
  onErrorShowDesc,
  dataTestId,
  maxUploadSize,
}) => {
  const { appConfig } = useSelector(mapStateToProps);

  const [dragEnter, setDragEnter] = useState<boolean>(false);
  const [showInvalidFileNameError, setShowInvalidFileNameError] = useState<
    boolean
  >(false);
  const [showUploadFileSizeError, setShowUploadFileSizeError] = useState<
    boolean
  >(false);
  useEffect(() => {
    handleFileDrag(setDragEnter);
  }, []);

  if (
    uploadProgress &&
    uploadProgress > 0 &&
    (executeProgress == null || executeProgress === 0)
  ) {
    const uploadEventSelector = document.querySelector('.ant-upload-drag');
    if (uploadEventSelector) {
      uploadEventSelector.addEventListener('dragenter', () => {
        if (resetUpload) {
          resetUpload();
        }
      });
      uploadEventSelector.addEventListener('click', () => {
        if (resetUpload) {
          resetUpload();
        }
      });
    }
  }

  const onFileSelection = useCallback(
    (file: File) => {
      setShowInvalidFileNameError(false);
      setShowUploadFileSizeError(false);
      if (file && file.name) {
        const fileNameWithoutExt = file.name
          .split('.')
          .slice(0, -1)
          .join('.');
        if (fileNameWithoutExt.endsWith(' ')) {
          setShowInvalidFileNameError(true);
          return false;
        }

        if (
          appConfig.UploadFileSizeLimit_InBytes > 0 &&
          file.size > (maxUploadSize ?? appConfig.UploadFileSizeLimit_InBytes)
        ) {
          setShowUploadFileSizeError(true);
          return false;
        }
      }
      onFileSelect(file);
      return true;
    },
    [
      onFileSelect,
      setShowInvalidFileNameError,
      setShowUploadFileSizeError,
      appConfig,
      maxUploadSize,
    ]
  );

  const preview =
    typeof previewView === 'function' ? previewView() : previewView;

  const renderContent = () => {
    const defaultContent =
      showInvalidFileNameError || showUploadFileSizeError || !preview ? (
        <FileUploadDefaultContent
          desc={defaultAddBtnDesc}
          btnText={defaultAddBtnText}
          dragEnter={dragEnter}
          hasError={!!error}
          id={id}
          onErrorShowDesc={onErrorShowDesc}
          dataTestId={dataTestId}
        />
      ) : (
        preview
      );

    if (
      uploadProgress === 0 &&
      (executeProgress == null || executeProgress === 0)
    ) {
      return (
        <StyledDragger
          showUploadList={false}
          multiple={false}
          accept={isImage ? 'image/png, image/jpeg, image/webp' : accept}
          disabled={disabled}
          customRequest={options => onFileSelection(options.file as File)}
          dragEnter={dragEnter as boolean | undefined}
          width={width}
          beforeUpload={file => {
            if (
              onFileBeforeUpload &&
              typeof onFileBeforeUpload === 'function'
            ) {
              onFileBeforeUpload(file);
            }
            return true;
          }}
          onDrop={e => {
            if (
              onFileBeforeUpload &&
              typeof onFileBeforeUpload === 'function'
            ) {
              onFileBeforeUpload(e.dataTransfer.files[0]);
            }
          }}
        >
          {showInvalidFileNameError && (
            <FileUploadError error="FILE_NAME_CANNOT_END_WITH_SPACE" />
          )}
          {showUploadFileSizeError && (
            <FileUploadError
              error="UPLOAD_FILE_FILESIZE_EXCEEDED"
              errorValue={(
                (maxUploadSize ?? appConfig.UploadFileSizeLimit_InBytes) /
                (1024 * 1024)
              ).toFixed(0)}
            />
          )}
          {defaultContent}
        </StyledDragger>
      );
    }

    if (allowBrowseOnError) {
      return (
        <StyledDragger
          accept={isImage ? 'image/png, image/jpeg, image/webp' : accept}
          disabled={disabled}
          showUploadList={false}
          width={width}
          dragEnter={dragEnter as boolean | undefined}
          customRequest={options => onFileSelection(options.file as File)}
        >
          {error && !displayErrorMsgOutside && (
            <FileUploadError error={error} />
          )}
          <div className="uploadLoadingContainer">
            {!!uploadProgress && (
              <>
                <FileUploadProgress
                  progress={uploadProgress ?? 0}
                  hasError={!!error}
                  progressTitle={uploadProgressTitle}
                  successTitle={uploadSuccessTitle}
                  errorTitle={uploadErrorTitle}
                />
                {defaultContent}
              </>
            )}
            {!!uploadProgress && executeProgress != null && (
              <>
                <FileUploadProgress
                  progress={executeProgress}
                  hasError={!!error}
                  progressTitle={executionProgressTitle}
                  successTitle={executionSuccessTitle}
                  errorTitle={executionErrorTitle}
                />
              </>
            )}
          </div>
        </StyledDragger>
      );
    }

    return (
      <StyledDragger
        accept={isImage ? 'image/png, image/jpeg, image/webp' : accept}
        disabled={disabled}
        showUploadList={false}
        width={width}
      >
        {error && !displayErrorMsgOutside && <FileUploadError error={error} />}
        <div className="uploadLoadingContainer">
          {!!uploadProgress && (
            <FileUploadProgress
              progress={uploadProgress ?? 0}
              hasError={!!error}
              progressTitle={uploadProgressTitle}
              successTitle={uploadSuccessTitle}
              errorTitle={uploadErrorTitle}
            />
          )}
          {!!uploadProgress && executeProgress != null && (
            <>
              <FileUploadProgress
                progress={executeProgress}
                hasError={!!error}
                progressTitle={executionProgressTitle}
                successTitle={executionSuccessTitle}
                errorTitle={executionErrorTitle}
              />
            </>
          )}
        </div>
      </StyledDragger>
    );
  };

  return (
    <>
      <StyledCard
        className={`${className} upload-card`}
        data-testid="file-upload"
        error={error}
        uploadProgress={uploadProgress}
        executeProgress={executeProgress}
        showInvalidFileNameError={showInvalidFileNameError}
        showUploadFileSizeError={showUploadFileSizeError}
      >
        {renderContent()}
        {!!uploadProgress &&
          executeProgress !== null &&
          executeProgress !== undefined &&
          executeProgress > 0 && (
            <Button
              type="link"
              onClick={onCancelButtonClick}
              className="upload-cancel"
            >
              <FormattedMessage id="FileUpload.cancel" />
            </Button>
          )}
      </StyledCard>
    </>
  );
};

export default FileUpload;
