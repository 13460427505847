export const INTERVAL_NORMAL = 60000;
export const INTERVAL_HAS_RUNNING = 5000;

export const GET_BGJOBS_REQUEST = 'GET_BGJOBS_REQUEST';
export const GET_BGJOBS_SUCCESS = 'GET_BGJOBS_SUCCESS';
export const GET_BGJOBS_FAILURE = 'GET_BGJOBS_FAILURE';

export const TRIGGER_REFRESH_GBJOBS = 'TRIGGER_REFRESH_GBJOBS';
export const TRIGGER_DOWNLOAD_BGJOBS = 'TRIGGER_DOWNLOAD_BGJOBS';

export const FILE_SIZE_LIMIT_EXCEEDED = 'FILE_SIZE_LIMIT_EXCEEDED';

export const CANCEL_TASK_QUEUE_REQUEST = 'CANCEL_TASK_QUEUE_REQUEST';
export const CANCEL_TASK_QUEUE_SUCCESS = 'CANCEL_TASK_QUEUE_SUCCESS';
export const CANCEL_TASK_QUEUE_FAILURE = 'CANCEL_TASK_QUEUE_FAILURE';

export const DELETE_BGJOBS_REQUEST = 'DELETE_BGJOBS_REQUEST';
export const DELETE_BGJOBS_SUCCESS = 'DELETE_BGJOBS_SUCCESS';
export const DELETE_BGJOBS_FAILURE = 'DELETE_BGJOBS_FAILURE';

export const REFRESH_ENTITY = 'REFRESH_ENTITY';

export const CLICKABLE_BACKGROUND_JOBS = [
  'GenerateTestCases',
  'GenerateSystematicTestCases',
  'RunTest',
  'TestRunComparer',
  'CompareService',
];

export const CLICKABLE_BACKGROUND_JOBS_STATUS = [
  'InProgress',
  'Fail',
  'Success',
];
