export const GET_ENTITY_PERMISSION_REQUEST = 'GET_ENTITY_PERMISSION_REQUEST';
export const GET_ENTITY_PERMISSION_SUCCESS = 'GET_ENTITY_PERMISSION_SUCCESS';
export const GET_ENTITY_PERMISSION_FAILURE = 'GET_ENTITY_PERMISSION_FAILURE';

export const SET_ENTITY_PERMISSION_REQUEST = 'SET_ENTITY_PERMISSION_REQUEST';
export const SET_ENTITY_PERMISSION_SUCCESS = 'SET_ENTITY_PERMISSION_SUCCESS';
export const SET_ENTITY_PERMISSION_FAILURE = 'SET_ENTITY_PERMISSION_FAILURE';

export const RESET_ENTITY_PERMISSION = 'RESET_ENTITY_PERMISSION';

export const GET_KEYCLOAK_USERS_REQUEST = 'GET_KEYCLOAK_USERS_REQUEST';
export const GET_KEYCLOAK_USERS_SUCCESS = 'GET_KEYCLOAK_USERS_SUCCESS';
export const GET_KEYCLOAK_USERS_FAILURE = 'GET_KEYCLOAK_USERS_FAILURE';

export const GET_KEYCLOAK_USER_GROUPS_REQUEST =
  'GET_KEYCLOAK_USER_GROUPS_REQUEST';
export const GET_KEYCLOAK_USER_GROUPS_SUCCESS =
  'GET_KEYCLOAK_USER_GROUPS_SUCCESS';
export const GET_KEYCLOAK_USER_GROUPS_FAILURE =
  'GET_KEYCLOAK_USER_GROUPS_FAILURE';

export const REMOVE_HIGHLIGHTS = 'REMOVE_HIGHLIGHTS';
