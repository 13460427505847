import ClientOAuth2 from 'client-oauth2';
import jwt from 'jsonwebtoken';
import { UserConstants } from '../constants';
import { getConfig } from './config';
import { ApiError } from './errors';
import { getValidRoleAndGroup } from './get-valid-role-group';
import { getPathNameWithoutBaseUrlString } from './pathName';
import { store } from './store';

export const getRedirectUrl = (issuerConfig: DTO.IssuerConfiguration) => {
  const params = new URLSearchParams(
    window.location.search.replace('+', '%2B')
  );
  const redirectUrlParam = params.get('return_url');
  let redirectUri = `${window.location.origin}${getConfig().redirectUrl}`;
  if (issuerConfig.authType !== 'AD') {
    redirectUri += `?return_url=${(
      redirectUrlParam ?? getPathNameWithoutBaseUrlString(issuerConfig.tenant)
    ).replace(/ /g, '@!@')}`;
  }
  return redirectUri;
};

export const getUserAuth = (issuerConfiguration: DTO.IssuerConfiguration) => {
  const redirectUrl = getRedirectUrl(issuerConfiguration);
  const oAuthSetting = {
    clientId: issuerConfiguration.clientId,
    clientSecret: undefined,
    authorizationUri: issuerConfiguration.authUri,
    accessTokenUri: issuerConfiguration.tokenUri,
    redirectUri: redirectUrl,
    scopes: issuerConfiguration.scope?.split(' ') ?? [],
  };
  return new ClientOAuth2(oAuthSetting);
};

export const decodeToken = (token: string) => {
  return jwt.decode(token) as DTO.UserJWTData;
};

export const isAdmin = (decodedJwt: DTO.UserJWTData) => {
  const role =
    decodedJwt &&
    decodedJwt.groups &&
    getValidRoleAndGroup(decodedJwt.groups).role;

  return (!!role && role === UserConstants.ROLE_SUPERVISOR) || true;
};

export const isSuperuser = (
  decodedJwt: DTO.UserJWTData,
  issuerConfig: DTO.IssuerConfiguration | null
) => {
  return (
    decodedJwt &&
    ((issuerConfig &&
      issuerConfig.superUserKey &&
      decodedJwt[issuerConfig.superUserKey]) ||
      decodedJwt.is_realm_admin)
  );
};

export const getDisplayName = (
  decodedJwt: DTO.UserJWTData,
  issuerConfig: DTO.IssuerConfiguration | null
) => {
  return (
    decodedJwt &&
    ((issuerConfig &&
      issuerConfig.displayNameKey &&
      decodedJwt[issuerConfig.displayNameKey]) ||
      decodedJwt.name)
  );
};

export const getUserName = (
  decodedJwt: DTO.UserJWTData,
  issuerConfig: DTO.IssuerConfiguration | null
) => {
  return (
    decodedJwt &&
    ((issuerConfig &&
      issuerConfig.userNameKey &&
      decodedJwt[issuerConfig.userNameKey]) ||
      decodedJwt.preferred_username)
  );
};

export const getUserEmailId = (
  decodedJwt: DTO.UserJWTData,
  issuerConfig: DTO.IssuerConfiguration | null
) => {
  return (
    decodedJwt &&
    ((issuerConfig &&
      issuerConfig.emailKey &&
      decodedJwt[issuerConfig.emailKey]) ||
      decodedJwt.preferred_username)
  );
};

export const getLogoutUrl = (
  issuerConfig: DTO.IssuerConfiguration | null,
  _returnUrl?: string
) => {
  const { userAuth } = store.getState().auth;
  const idToken = userAuth && userAuth.idToken;
  const decodedJwt = idToken && decodeToken(idToken);

  // If token has idp then append it to logoutUrl as query param
  let tokenIDPForLogout = '';
  if (decodedJwt && decodedJwt.idp) {
    tokenIDPForLogout = `&initiating_idp=${decodedJwt.idp}`;
  }

  const tenant = localStorage.getItem('Tenant') || getConfig().defaultTenant;

  const redirectUrl = `${window.location.origin}${process.env.REACT_APP_PUBLIC_URL}/${tenant}`;

  if (issuerConfig && issuerConfig.logoutUri) {
    if (issuerConfig.authType === 'AD') {
      return `${issuerConfig.logoutUri}?redirectUrl=${encodeURIComponent(
        redirectUrl
      )}`;
    }
    return `${
      issuerConfig.logoutUri
    }?post_logout_redirect_uri=${encodeURIComponent(
      redirectUrl
    )}&client_id=${encodeURIComponent(
      issuerConfig.clientId
    )}&id_token_hint=${encodeURIComponent(idToken || '')}${tokenIDPForLogout}`;
  }
  return undefined;
};

export const refresh = async (store): Promise<string | undefined> => {
  const state = store.getState();
  const { userAuth } = store.getState().auth;
  const refreshToken = userAuth && userAuth.refresh_token;
  const issuerConfig = state.auth.issuerConfig;
  if (refreshToken && issuerConfig && userAuth) {
    const userOAuth = getUserAuth(issuerConfig);
    const token = userOAuth.createToken(userAuth.accessToken, refreshToken, {});
    let updatedUser: ClientOAuth2.Token | null = null;
    try {
      updatedUser = await token.refresh();
    } catch (error) {
      // in case of error when refresh token is already used (happens when after token expiration UI calls multiple APIs at the same time.)
      updatedUser = null;
    }
    if (!updatedUser) {
      const logOutUrl = getLogoutUrl(issuerConfig);
      localStorage.removeItem('Tenant');
      store.dispatch({
        type: UserConstants.LOGOUT,
        payload: {
          logOutUrl: logOutUrl ?? '',
        },
      });

      throw new ApiError({
        error_code: UserConstants.REFRESH_TOKEN_LIMIT_REACHED,
      });
    }
    const newIdToken = updatedUser.accessToken;
    const newRefreshToken = updatedUser.refreshToken;
    store.dispatch({
      type: UserConstants.SET_ID_TOKEN,
      payload: {
        accessToken: newIdToken,
        idToken: updatedUser?.data?.id_token || '',
        refresh_token: newRefreshToken,
      },
    });

    return newIdToken;
  }

  return undefined;
};

export const getCreatedBy = (
  decodedJwt: DTO.UserJWTData,
  issuerConfig: DTO.IssuerConfiguration | null
) => {
  if (issuerConfig?.userIdKey && decodedJwt) {
    return decodedJwt[issuerConfig.userIdKey];
  }
  return decodedJwt && decodedJwt.sub;
};

export const getCreatedByEmail = (
  decodedJwt: DTO.UserJWTData,
  issuerConfig: DTO.IssuerConfiguration | null
) => {
  if (issuerConfig?.emailKey && decodedJwt) {
    return decodedJwt[issuerConfig.emailKey];
  }
  return decodedJwt && decodedJwt.sub;
};
