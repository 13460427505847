import { init } from '@elastic/apm-rum';
import { withTransaction as apmLogger } from '@elastic/apm-rum-react';
import { getConfig } from '../helpers';

declare global {
  interface Window {
    apm: any;
  }
}
const loginListener = () => {
  // Listen for custom login event
  const logUserEmail = (e: CustomEventInit) => {
    const {
      detail: { email },
    } = e;
    if (window.apm) {
      window.apm.setUserContext({ email });
    }
  };

  window.addEventListener('user_session_established', logUserEmail, false);
};

const logoutListener = () => {
  // Listen for custom logout event
  window.addEventListener(
    'logout',
    () => {
      if (window.apm) {
        window.apm.setUserContext({ email: undefined });
      }
    },
    false
  );
};

export const apmInit = (): void => {
  const config = getConfig();
  const { elasticApm, version } = config;
  if (elasticApm) {
    window.apm = init({
      ...elasticApm,
      serviceVersion: elasticApm.serviceVersion ?? version,
    });

    loginListener();
    logoutListener();
  }
};

export default apmLogger;
