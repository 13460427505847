import moment from 'moment';
import { VersionOverviewConstants } from '../constants';
import { ApiError } from '../helpers';
import { BackgroundJobsService, VersionOverviewService } from '../services';
import { AlertActions } from './alert.actions';
import { BackgroundJobsActions } from './backgroundJobs.action';
import { VersionOverviewThunkAction } from './types';

const compareServiceVersions = (
  folderName: string,
  serviceName: string,
  selectedVersion: DTO.EngineVersion[]
): VersionOverviewThunkAction<boolean> => async dispatch => {
  try {
    dispatch({
      type: VersionOverviewConstants.COMPARE_SERVICE_VERSIONS_REQUEST,
      payload: {
        folderName,
        serviceName,
        selectedVersion,
      },
    });

    const request: DTO.CompareServiceRequest = {
      sourceVersionId: selectedVersion[1].id,
      destinationVersionId: selectedVersion[0].id,
      fileName: `${serviceName}.xlsx`,
      productName: folderName,
      engineName: serviceName,
      scope: 'workbook',
      scopeParam: [],
      timeZoneOffset: moment(new Date()).utcOffset(),
    };

    const {
      payload,
      status,
    } = await VersionOverviewService.compareServicesVersion(
      folderName,
      serviceName,
      request
    );

    if (status !== 200 || payload.status !== 'Success') {
      throw new ApiError(payload);
    }

    dispatch({
      type: VersionOverviewConstants.COMPARE_SERVICE_VERSIONS_SUCCESS,
      payload: {
        taskQueueId: payload.response_data.id,
      },
    });
    dispatch(BackgroundJobsActions.getAll());
    return true;
  } catch (error) {
    await dispatch(AlertActions.error(error));
    return false;
  }
};

const resetCompareServiceVersions = (): VersionOverviewThunkAction => async dispatch => {
  dispatch({
    type: VersionOverviewConstants.COMPARE_SERVICE_VERSIONS_RESET,
  });
};

const getCompareServiceVersionsResult = (
  taskQueueId: string
): VersionOverviewThunkAction<boolean> => async dispatch => {
  try {
    dispatch({
      type:
        VersionOverviewConstants.GET_COMPARE_SERVICE_VERSIONS_RESULT_REQUEST,
    });

    const { payload } = await BackgroundJobsService.getRunStatus(taskQueueId);

    if (payload.status === 'Error') {
      throw new ApiError(payload);
    }

    dispatch({
      type:
        VersionOverviewConstants.GET_COMPARE_SERVICE_VERSIONS_RESULT_SUCCESS,
      payload: {
        compareServiceVersionsResult: JSON.parse(payload.data.outputData || ''),
      },
    });
    return true;
  } catch (error) {
    await dispatch(AlertActions.error(error));
    return false;
  }
};

export const VersionOverviewActions = {
  compareServiceVersions,
  resetCompareServiceVersions,
  getCompareServiceVersionsResult,
};
