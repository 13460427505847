import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { createBrowserHistory } from 'history';
import { getConfig } from '../helpers';
import { decodeToken, getUserEmailId } from '../helpers/user-auth';
import { UserService } from '../services';

const history = createBrowserHistory();

export const sentrySetUserScope = async userAuth => {
  const decodedJwt = await decodeToken(userAuth.idToken);

  let issuerConfig: DTO.IssuerConfiguration | null = null;
  if (decodedJwt && decodedJwt.iss) {
    issuerConfig = await UserService.getIssuerConfiguration(decodedJwt.iss);
  }

  Sentry.configureScope(scope => {
    scope.setUser({
      email: getUserEmailId(decodedJwt, issuerConfig),
      tenant: issuerConfig?.tenant ?? decodedJwt?.realm,
    });
  });
};

export const sentryInit = (userAuth): void => {
  const config = getConfig();
  const { sentry, version } = config;
  if (sentry) {
    Sentry.init({
      ...sentry,
      release: sentry.release ?? version,
      integrations: [
        new Integrations.BrowserTracing({
          routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
        }),
      ],
    });
    sentrySetUserScope(userAuth);
  }
};
