import React, { FC } from 'react';
import { Spin } from 'antd';
import { SpinProps } from 'antd/lib/spin';
import { FormattedHTMLMessage } from 'react-intl';
import { SpinSize } from 'antd/es/spin';
import styled from 'styled-components';

interface LoadingIndicatorProps extends SpinProps {
  text?: string;
  showText?: boolean;
  size?: SpinSize;
}

interface Loading {
  text?: string;
  showText?: boolean;
  size?: SpinSize | 'xSmall' | 'xxSmall' | 'xxxSmall';
}

const StyledSpin = styled(props => <Spin {...props} />)`
  max-height: none !important;
  min-height: fit-content;

  > div {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -36px !important;
    margin-top: -36px !important;

    &.xxxSmall {
      margin-left: -12px !important;
      margin-top: -12px !important;
    }
    &.xxSmall {
      margin-left: -18px !important;
      margin-top: -18px !important;
    }
    &.xSmall {
      margin-left: -24px !important;
      margin-top: -24px !important;
    }
    &.small {
      margin-left: -30px !important;
      margin-top: -30px !important;
    }
    &.large {
      margin-left: -42px !important;
      margin-top: -42px !important;
    }
  }
`;

const Loading: FC<Loading> = ({ text, showText, size }) => {
  return (
    <div className={`custom-spinner-wrapper ${size}`}>
      <div className={`custom-spinner-content ${size}`}>
        <div className="custom-spinner" />
        {showText && (
          <div className="custom-spinner-text">
            <FormattedHTMLMessage id={text} />
          </div>
        )}
      </div>
    </div>
  );
};

const InlineLoadingGif: FC<Loading> = ({
  showText = false,
  text = 'Global.loading',
  size = 'default',
}) => <Loading text={text} showText={showText} size={size} />;

const LoadingIndicator: FC<LoadingIndicatorProps> = ({
  showText = false,
  text = 'Global.loading',
  size = 'default',
  ...spinProps
}) => {
  return (
    <StyledSpin
      {...spinProps}
      indicator={<Loading text={text} showText={showText} size={size} />}
    />
  );
};

if (Spin?.setDefaultIndicator) {
  Spin.setDefaultIndicator(<LoadingIndicator />);
}

export { LoadingIndicator, InlineLoadingGif };
