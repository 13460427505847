import { getConfig } from '../helpers/config';
import makeRequest from '../helpers/make-request';

const runutility = (
  product: string,
  engine: string,
  version: string,
  smartLevel: string
) => {
  const url = `${
    getConfig().excelEngineDomain
  }/api/v1/ingenium/${product}/${engine}/${version}`;
  const formData = new FormData();
  smartLevel && formData.append('smartLevel', smartLevel);
  return makeRequest<{ data: string }>('POST', url, formData);
};

const getDownloadIngeniumLogFileUrl = (fileId: string, fileName: string) => {
  return `${
    getConfig().excelEngineDomain
  }/api/v1/ingenium/download/${fileId}/${encodeURIComponent(fileName)}`;
};

const getLogs = (request: DTO.GetIngeniumLogRequest) => {
  const {
    page = 1,
    pageSize = 10,
    sortBy = 'updated',
    sort = 'desc',
  } = request;

  const search: {
    field: string;
    value: string;
    operator: string;
  }[] = [];

  if (request.searchText) {
    search.push({
      field: 'name1',
      operator: 'like',
      value: `%${request.searchText}%`,
    });
    search.push({
      field: 'productName',
      operator: 'like',
      value: `%${request.searchText}%`,
    });
    search.push({
      field: 'name2',
      operator: 'like',
      value: `%${request.searchText}%`,
    });
  }

  if (request.statuses && request.statuses.length > 0) {
    request.statuses.forEach(val => {
      search.push({
        field: 'otherString1',
        operator: '=',
        value: val,
      });
    });
  }

  if (request.fromDate) {
    search.push({
      field: 'updated',
      operator: '>=',
      value: request.fromDate,
    });
  }

  if (request.toDate) {
    search.push({
      field: 'updated',
      operator: '<=',
      value: request.toDate,
    });
  }

  const url = `${getConfig().excelEngineDomain}/api/v1/ingenium/getlogs`;
  return makeRequest<DTO.GetIngeniumLogResponse>('POST', url, {
    page,
    pageSize,
    search,
    sort: sortBy ? `${sort === 'desc' ? '-' : ''}${sortBy}` : '',
  });
};

export const IngeniumService = {
  runutility,
  getDownloadIngeniumLogFileUrl,
  getLogs,
};
