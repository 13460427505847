/* eslint-disable @typescript-eslint/no-explicit-any */
import { useRef, useState, useCallback } from 'react';
import {
  ContentBlock,
  EditorState,
  Modifier,
  RichUtils,
  SelectionState,
  ContentState,
  genKey,
  KeyBindingUtil,
  getDefaultKeyBinding,
  AtomicBlockUtils,
  convertFromHTML,
} from 'draft-js';

import { Map, OrderedSet } from 'immutable';

import { unionWith, isNil } from 'lodash';
import { getSelectionEntity, getSelectionText } from 'draftjs-utils';

import {
  toggleInlineStyle,
  toggleListType,
  removeSizeDataFromBlock,
  handleTabInTable,
  handleKeypressWhenSelectionNotCollapsed,
  handleDeleteKey,
  setIndent,
  toggleBlockData,
  convertHtmlToEditorState,
  moveSelectionToStart,
  setAlignmentInTable,
} from '../helpers';

import { Keys, MAX_LIST_DEPTH } from '../constants/richTextEditor.constants';

const { hasCommandModifier } = KeyBindingUtil;
function useApiTesterDocumentation({
  props: { maxLength, disabled, defaultStyles, onUpload, onChange, ...props },
  html,
  clickOutsideRef,
  updateFormData,
  editor,
  newImgFile,
}: {
  props: {
    maxLength?: number;
    disabled?: boolean;
    defaultStyles?: string[];
    onUpload?: (file: any, func: (arg, arg2) => void) => void;
    height?: string;
    onChange?: (value: any) => void;
  };
  html?: any;
  clickOutsideRef?: any;
  updateFormData?: any;
  editor?: any;
  newImgFile?: any;
}) {
  const [richMode, setRichMode] = useState(true);
  const [isImageActive, setIsImageActive] = useState(false);
  const [showLinkPopover, setShowLinkPopover] = useState(false);
  const [activeStyles, setActiveStyles] = useState({});
  const [height, setHeight] = useState(props.height ?? 'auto');
  const [editorState, setEditorState] = useState<EditorState>(
    EditorState.createEmpty()
  );
  const [previousEditorState, setPreviousEditorState] = useState<EditorState>(
    EditorState.createEmpty()
  );
  const plaintext = useRef<any>(null);
  const editorDOMRef = useRef<any>(null);
  const inTextMode = useRef<any>(false);
  const codeviewRef = useRef<any>(null);

  const onEditorStateChange: (editorState: EditorState) => void = useCallback(
    newEditorState => {
      const selection = newEditorState.getSelection();
      let content = newEditorState.getCurrentContent();
      if (
        selection.isCollapsed() &&
        RichUtils.getCurrentBlockType(newEditorState) === 'table'
      ) {
        const block: ContentBlock = content.getBlockForKey(
          selection.getAnchorKey()
        );
        if (!block.getLength()) {
          content = Modifier.insertText(content, selection, ' ');
          newEditorState = EditorState.push(
            newEditorState,
            content,
            'insert-characters'
          );
        }
      }
      let blockMap = content.getBlockMap();
      const activeBlock = blockMap.find(
        block => block && block.getData && block.getData().get('isActive')
      );
      if (activeBlock) {
        if (isImageActive) {
          let data = activeBlock.getData();
          data = data.delete('isActive');
          const inactiveBlock = activeBlock.set('data', data);
          blockMap = blockMap.set(activeBlock.getKey(), inactiveBlock as any);
          const newContent = content.set('blockMap', blockMap);
          newEditorState = EditorState.push(
            newEditorState,
            newContent as any,
            'change-block-data'
          );
          newEditorState = EditorState.forceSelection(
            newEditorState,
            selection
          );
        }
        setIsImageActive(isImageActive => !isImageActive);
      }
      let linkPopoverOpen = false;
      if (selection.isCollapsed()) {
        const blockKey = selection.getAnchorKey();
        const offset = selection.getAnchorOffset();
        const contentState = newEditorState.getCurrentContent();
        const block = contentState.getBlockForKey(blockKey);
        const entityKey = block?.getEntityAt(offset);
        if (entityKey && !clickOutsideRef.current) {
          const entity: any = contentState.getEntity(entityKey);
          const { url, target } = entity.getData();
          if (
            entity.get('type') === 'LINK' &&
            !disabled &&
            Boolean(url) &&
            Boolean(target)
          ) {
            linkPopoverOpen = true;
          }
        }
      }

      updateFormData.current(newEditorState);
      const styleList = newEditorState.getCurrentInlineStyle().toList();
      const activeStyles = { fontFamily: 'Calibri' };
      styleList.forEach(s => {
        if (typeof s === 'string') {
          const [type, value] = s.split('.');
          activeStyles[type] = value;
        }
      });
      plaintext.current = newEditorState.getCurrentContent().getPlainText();
      setShowLinkPopover(linkPopoverOpen);
      setActiveStyles(activeStyles);
      setEditorState(newEditorState);
    },
    [isImageActive, disabled, clickOutsideRef, setEditorState, updateFormData]
  );

  const handleBeforeInput = (chars, newEditorState, onEditorStateChange) => {
    if (maxLength && html.current?.length >= maxLength) {
      return 'handled';
    }
    const selection = newEditorState.getSelection();
    if (!selection.isCollapsed()) {
      return handleKeypressWhenSelectionNotCollapsed(
        newEditorState,
        onEditorStateChange,
        chars
      );
    }
    return 'not-handled';
  };

  const handleKeyCommand = (command, newEditorState) => {
    switch (command) {
      case 'backspace': {
        // removing images ("atomic" blocktype) with backspace requires special handling or the image tag and dataUrl can be left in the content but not visible.
        let contentState = newEditorState.getCurrentContent();
        let selectionState = newEditorState.getSelection();
        const startKey = selectionState.getStartKey();
        const offset = selectionState.getStartOffset();
        const collapsed = selectionState.isCollapsed();
        const blockBefore = contentState.getBlockBefore(startKey);
        const currentBlockType = RichUtils.getCurrentBlockType(newEditorState);
        if (
          collapsed &&
          offset === 0 &&
          blockBefore &&
          blockBefore.getType() === 'atomic'
        ) {
          newEditorState = removeSizeDataFromBlock(newEditorState, blockBefore);
          newEditorState = EditorState.acceptSelection(
            newEditorState,
            selectionState
          );
          onEditorStateChange(
            RichUtils.onBackspace(newEditorState) as EditorState
          );
          return 'handled';
        }
        if (currentBlockType === 'atomic') {
          const currentBlock = contentState.getBlockForKey(startKey);
          newEditorState = removeSizeDataFromBlock(
            newEditorState,
            currentBlock
          );
          newEditorState = EditorState.acceptSelection(
            newEditorState,
            selectionState
          );
          newEditorState = RichUtils.onBackspace(newEditorState);
          contentState = newEditorState.getCurrentContent();
          selectionState = newEditorState.getSelection();
          const key = selectionState.getAnchorKey();
          let selection = SelectionState.createEmpty(key);
          selection = selection.set('focusOffset', 1) as SelectionState;
          contentState = Modifier.removeRange(
            contentState,
            selection,
            'backward'
          );
          onEditorStateChange(
            EditorState.push(
              newEditorState,
              contentState,
              'backspace-character'
            )
          );
          return 'handled';
        }
        if (currentBlockType === 'table' && collapsed && offset === 0) {
          return 'handled';
        }
        if (
          currentBlockType !== 'table' &&
          collapsed &&
          blockBefore?.getType() === 'table' &&
          offset === 0
        ) {
          handleTabInTable(editorState, onEditorStateChange, 'previous', true);
          return 'handled';
        }
        if (
          collapsed &&
          offset === 0 &&
          blockBefore?.getType() === 'page-break'
        ) {
          let selection = SelectionState.createEmpty(blockBefore.getKey());
          contentState = Modifier.setBlockData(
            contentState,
            selection,
            Map({})
          );
          contentState = Modifier.setBlockType(
            contentState,
            selection,
            'unstyled'
          );
          selection = selection.merge({
            focusKey: selectionState.getFocusKey(),
            focusOffset: 0,
            hasFocus: true,
          });
          contentState = Modifier.removeRange(
            contentState,
            selection,
            'backward'
          );
          onEditorStateChange(
            EditorState.push(newEditorState, contentState, 'remove-range')
          );
          return 'handled';
        }
        if (currentBlockType === 'page-break') {
          contentState = Modifier.setBlockData(
            contentState,
            selectionState,
            Map({})
          );
          contentState = Modifier.setBlockType(
            contentState,
            selectionState,
            'unstyled'
          );
          let selection = selectionState;
          if (collapsed && contentState.getBlockAfter(startKey)) {
            selection = selection.merge({
              focusKey: contentState.getBlockAfter(startKey).getKey(),
              focusOffset: 0,
              hasFocus: true,
            });
          }
          contentState = Modifier.removeRange(
            contentState,
            selection,
            'backward'
          );
          onEditorStateChange(
            EditorState.push(newEditorState, contentState, 'remove-range')
          );
          return 'handled';
        }
        if (
          collapsed &&
          offset === 0 &&
          [
            'pasted-list-item',
            'ordered-list-item',
            'unordered-list-item',
          ].includes(currentBlockType)
        ) {
          contentState = Modifier.setBlockType(
            contentState,
            selectionState,
            'unstyled'
          );
          onEditorStateChange(
            EditorState.push(newEditorState, contentState, 'change-block-type')
          );
          return 'handled';
        }
        if (!collapsed) {
          return handleKeypressWhenSelectionNotCollapsed(
            newEditorState,
            onEditorStateChange
          );
        }
        return 'not-handled';
      }
      case 'BOLD':
        toggleInlineStyle('BOLD', editorState, onEditorStateChange);
        return 'handled';
      case 'bullet_list':
        toggleListType('BULLETLIST', editorState, onEditorStateChange);
        return 'handled';
      case 'delete':
        return handleDeleteKey(editorState, onEditorStateChange);
      case 'float_left':
        toggleBlockData({ float: 'left' }, editorState, onEditorStateChange);
        return 'handled';
      case 'float_right':
        toggleBlockData({ float: 'right' }, editorState, onEditorStateChange);
        return 'handled';
      case 'INDENT':
        setIndent('INDENT', editorState, onEditorStateChange);
        return 'handled';
      case 'ITALIC':
        toggleInlineStyle('ITALIC', editorState, onEditorStateChange);
        return 'handled';
      case 'ordered_list':
        toggleListType('ORDEREDLIST', editorState, onEditorStateChange);
        return 'handled';
      case 'OUTDENT':
        setIndent('OUTDENT', editorState, onEditorStateChange);
        return 'handled';
      case 'UNDERLINE':
        toggleInlineStyle('UNDERLINE', editorState, onEditorStateChange);
        return 'handled';
      case 'shiftTab':
        return 'handled';
      case 'tab':
        return 'handled';
      default:
        return 'not-handled';
    }
  };

  const syncContenteditable = disabled => {
    if (disabled) {
      const editableDivs =
        editor.current?.editor.querySelectorAll('[contenteditable="true"]') ??
        [];
      editableDivs.forEach(div => {
        div.setAttribute('contenteditable', 'false');
        div.setAttribute('data-editable-disabled', 'true');
      });
    } else if (!disabled) {
      const editableDivs =
        editor.current?.editor.querySelectorAll(
          '[data-editable-disabled="true"]'
        ) ?? [];
      editableDivs.forEach(div => {
        div.setAttribute('contenteditable', 'true');
        div.removeAttribute('data-editable-disabled');
      });
    }
  };

  const reset = newHtml => {
    if (newHtml === html.current) {
      return;
    }

    let newEditorState = convertHtmlToEditorState(newHtml);
    newEditorState = moveSelectionToStart(newEditorState);
    onEditorStateChange(newEditorState);
    setPreviousEditorState(newEditorState);
    const hasFocus = editorState.getSelection().getHasFocus();
    const currentEditor = editor?.current.editor;

    setTimeout(() => {
      syncContenteditable(disabled);
      currentEditor && currentEditor.blur();
      setTimeout(() => {
        if (hasFocus) {
          currentEditor && currentEditor.focus();
        }
      });
    });
  };

  // used for pasting or dropping content into the editor
  const addHtmlToDocument = (newHtml, currentEditorState = editorState) => {
    if (!newHtml) {
      return false;
    }
    if (!currentEditorState.getCurrentContent().hasText()) {
      reset(newHtml);
      return true;
    }
    let newEditorState = convertHtmlToEditorState(newHtml);
    const newBlockMap = newEditorState.getCurrentContent().getBlockMap();

    const newContent = Modifier.replaceWithFragment(
      currentEditorState.getCurrentContent(),
      currentEditorState.getSelection(),
      newBlockMap
    );
    newEditorState = EditorState.push(
      currentEditorState,
      newContent,
      'insert-fragment'
    );
    onEditorStateChange(newEditorState);
    const currentEditor = editor.current;
    const hasFocus = editorState.getSelection().getHasFocus();
    setTimeout(() => {
      syncContenteditable(disabled);
      currentEditor && currentEditor.blur();
      setTimeout(() => {
        if (hasFocus) {
          currentEditor && currentEditor.focus();
        }
      });
    });
    return true;
  };

  const tableBlocksInSelection = (
    content = editorState.getCurrentContent()
  ) => {
    const selection = editorState.getSelection();
    const startKey = selection.getStartKey();
    const endKey = selection.getEndKey();
    const nextKey = content.getKeyAfter(endKey);
    const blockMap = content.getBlockMap();
    const blocks = blockMap
      .toSeq()
      .skipUntil((_, k) => k === startKey)
      .takeUntil((_, k) => k === nextKey)
      .filter(block => block?.getType() === 'table')
      .toOrderedMap();
    if (!blocks.size) {
      return null;
    }
    return blocks;
  };

  const setBlockType = blockType => {
    let contentState = editorState.getCurrentContent();
    const selectionState = editorState.getSelection();
    if (tableBlocksInSelection()) {
      return null;
    }
    const anchorKey = selectionState.getAnchorKey();
    let block = contentState.getBlockForKey(anchorKey);
    // for page-break block type we want to insert a new block rather than just convert the current block type
    if (blockType === 'page-break') {
      const newKey = genKey();
      const newBlock = new ContentBlock({
        key: newKey,
        type: 'page-break',
        text: '\n',
      });
      let offset = selectionState.getAnchorOffset();
      if (offset > 0) {
        contentState = Modifier.splitBlock(contentState, selectionState);
      }
      block = contentState.getBlockForKey(anchorKey);
      offset = selectionState.getAnchorOffset();
      const blockArray = contentState.getBlocksAsArray();
      const index = blockArray.findIndex(b => b === block);
      blockArray.splice(index + (offset && 1), 0, newBlock);
      const entityMap = contentState.getEntityMap();
      contentState = ContentState.createFromBlockArray(blockArray, entityMap);
      let newEditorState = EditorState.push(
        editorState,
        contentState,
        'insert-fragment'
      );
      let selection = SelectionState.createEmpty(anchorKey);
      selection = selection.merge({
        anchorOffset: offset,
        focusKey: anchorKey,
        focusOffset: offset,
        hasFocus: true,
      });
      newEditorState = EditorState.acceptSelection(newEditorState, selection);
      onEditorStateChange(newEditorState);
    } else {
      const depth = block.getDepth();
      const newContentState = Modifier.setBlockType(
        contentState,
        selectionState,
        blockType
      );
      const newEditorState = EditorState.push(
        editorState,
        newContentState,
        'change-block-type'
      );
      setIndent(undefined, newEditorState, onEditorStateChange, 4, 20, depth);
    }
    return null;
  };

  const mapKeyToEditorCommand = (
    e,
    handleHasCommandModifier = hasCommandModifier
  ) => {
    if (
      !editorState.getCurrentContent().hasText() &&
      ['unstyled', 'paragraph'].includes(
        editorState
          .getCurrentContent()
          .getFirstBlock()
          .getType()
      )
    ) {
      (() => {
        const currentStyle = editorState.getCurrentInlineStyle().toArray();
        // merge user selected styles with defaults, overriding defaults where they conflict
        const styles = unionWith(
          currentStyle,
          defaultStyles,
          (v1, v2) => v1.split('.')[0] === v2.split('.')[0]
        );
        onEditorStateChange(
          EditorState.setInlineStyleOverride(editorState, OrderedSet(styles))
        );
      })();
    }
    if (e.keyCode === Keys.B && e.shiftKey && handleHasCommandModifier(e)) {
      return 'bullet_list';
    }
    if (e.keyCode === Keys.B && handleHasCommandModifier(e)) {
      return 'BOLD';
    }
    if (e.keyCode === Keys.L && e.shiftKey && handleHasCommandModifier(e)) {
      return 'ordered_list';
    }
    if (e.keyCode === Keys.L && handleHasCommandModifier(e)) {
      return 'float_left';
    }
    if (e.keyCode === Keys.R && handleHasCommandModifier(e)) {
      return 'float_right';
    }
    if (e.keyCode === Keys.I && handleHasCommandModifier(e)) {
      return 'ITALIC';
    }
    if (e.keyCode === Keys[']'] && handleHasCommandModifier(e)) {
      return 'INDENT';
    }
    if (e.keyCode === Keys.U && handleHasCommandModifier(e)) {
      return 'UNDERLINE';
    }
    if (e.keyCode === Keys['['] && handleHasCommandModifier(e)) {
      return 'OUTDENT';
    }
    if (
      e.keyCode === Keys.Backspace &&
      !handleHasCommandModifier(e) &&
      !e.altKey
    ) {
      return 'backspace';
      // Tab & shift+Tab handled here instead of handleKeyCommand because RichUtils.onTab requires event reference
    }
    if (e.keyCode === Keys.Delete) {
      return 'delete';
    }
    if (e.keyCode === Keys.Tab && e.shiftKey) {
      const currentBlockType = RichUtils.getCurrentBlockType(editorState);
      if (currentBlockType.includes('list-item')) {
        onEditorStateChange(RichUtils.onTab(e, editorState, MAX_LIST_DEPTH));
      } else if (currentBlockType === 'table') {
        handleTabInTable(editorState, onEditorStateChange, 'previous');
      }
      return 'shiftTab';
    }
    if (e.keyCode === Keys.Tab) {
      const currentBlockType = RichUtils.getCurrentBlockType(editorState);
      if (RichUtils.getCurrentBlockType(editorState).includes('list-item')) {
        onEditorStateChange(RichUtils.onTab(e, editorState, MAX_LIST_DEPTH));
      } else if (currentBlockType === 'table') {
        handleTabInTable(editorState, onEditorStateChange, 'next');
      } else {
        const newContentState = Modifier.replaceText(
          editorState.getCurrentContent(),
          editorState.getSelection(),
          '     '
        );
        onEditorStateChange(
          EditorState.push(editorState, newContentState, 'insert-characters')
        );
      }
      return 'tab';
    }
    return getDefaultKeyBinding(e);
  };

  const handleReturn = (e, editorState) => {
    if (maxLength && html.current?.length >= maxLength) {
      return 'handled';
    }
    if (e.shiftKey) {
      const newEditorState = RichUtils.insertSoftNewline(editorState);
      const contentState = Modifier.replaceText(
        newEditorState.getCurrentContent(),
        newEditorState.getSelection(),
        ' '
      );
      onEditorStateChange(
        EditorState.push(newEditorState, contentState, 'insert-characters')
      );
      return 'handled';
    }
    if (RichUtils.getCurrentBlockType(editorState) === 'table') {
      onEditorStateChange(RichUtils.insertSoftNewline(editorState));
      return 'handled';
    }
    if (
      RichUtils.getCurrentBlockType(editorState) === 'pasted-list-item' &&
      editorState.getSelection().isCollapsed()
    ) {
      let content = editorState.getCurrentContent();
      let selection = editorState.getSelection();
      let currentBlock = content.getBlockForKey(selection.getAnchorKey());
      content = Modifier.splitBlock(content, selection);
      let newEditorState = EditorState.push(
        editorState,
        content,
        'split-block'
      );
      let nextBlock = content.getBlockAfter(selection.getAnchorKey());
      const key = nextBlock.getKey();
      while (nextBlock?.getType() === 'pasted-list-item') {
        let data = currentBlock.getData();
        data = data.merge({
          listStart: +data.get('listStart') > 0 ? data.get('listStart') + 1 : 0,
        });
        content = Modifier.setBlockData(
          newEditorState.getCurrentContent(),
          SelectionState.createEmpty(nextBlock.getKey()),
          data
        );
        newEditorState = EditorState.push(
          newEditorState,
          content,
          'change-block-data'
        );
        currentBlock = content.getBlockForKey(nextBlock.getKey());
        nextBlock = content.getBlockAfter(nextBlock.getKey());
      }
      selection = selection.merge({
        anchorKey: key,
        focusKey: key,
        anchorOffset: 0,
        focusOffset: 0,
        hasFocus: true,
      });
      newEditorState = EditorState.forceSelection(newEditorState, selection);
      onEditorStateChange(newEditorState);
      return 'handled';
    }
    return 'not-handled';
  };

  const insertImageHandleResponse = (valid, resp) => {
    if (valid) {
      insertImage({ imgUrl: resp });
    } else {
      const fileReader = new window.FileReader();
      fileReader.readAsDataURL(newImgFile.current);
      fileReader.onloadend = e => {
        insertImage({ imgUrl: e.target?.result });
      };
    }
    return null;
  };

  const insertImage = ({
    imgUrl,
    imgFile,
  }: {
    imgUrl?: string | ArrayBuffer | null;
    imgFile?: File | Blob | Blob[];
  }) => {
    if (RichUtils.getCurrentBlockType(editorState) === 'table') {
      return null;
    }
    if (!imgUrl && imgFile) {
      newImgFile.current = imgFile;
      onUpload && onUpload(newImgFile, insertImageHandleResponse);
      return null;
    }
    newImgFile.current = null;
    let contentState = editorState.getCurrentContent();
    contentState = contentState.createEntity('IMAGE', 'IMMUTABLE', {
      src: imgUrl,
    });
    const entityKey = contentState.getLastCreatedEntityKey();
    const newEditorState = AtomicBlockUtils.insertAtomicBlock(
      editorState,
      entityKey,
      ' '
    );
    onEditorStateChange(newEditorState);
    return null;
  };

  const handlePastedText = (text, pastedHtml, editorState): any => {
    if (maxLength && html.current?.length >= maxLength) {
      return true;
    }

    // when pasting into a table cell only allow plain text
    // to be inserted or the table will become corrupted
    let content = editorState.getCurrentContent();
    const selection = editorState.getSelection();
    const block = content.getBlockForKey(selection.getStartKey());
    if (block.getType() === 'table') {
      if (selection.isCollapsed()) {
        content = Modifier.insertText(content, selection, text);
      } else {
        content = Modifier.replaceText(content, selection, text);
      }
      onEditorStateChange(
        EditorState.push(editorState, content, 'insert-characters')
      );
      return true;
    }
    return addHtmlToDocument(pastedHtml, editorState);
  };

  const handlePastedFiles = (files: File | Blob | Blob[]): any => {
    insertImage({ imgFile: files[0] });
  };

  const setAlignment = alignment => {
    const contentState = editorState.getCurrentContent();
    const selectionState = editorState.getSelection();
    let newContentState = Modifier.mergeBlockData(
      contentState,
      selectionState,
      Map({ 'text-align': alignment })
    );
    const tableBlocks = tableBlocksInSelection(newContentState);
    if (tableBlocks) {
      newContentState = setAlignmentInTable(
        alignment,
        newContentState,
        tableBlocks
      );
    }
    onEditorStateChange(
      EditorState.push(editorState, newContentState, 'change-block-data')
    );
  };

  const handleDrop = (selection, data): any => {
    // when dropping text into a table cell only allow plain text
    // to be inserted or the table will become corrupted
    const text = data.data.getData('text');
    let content = editorState.getCurrentContent();
    const block = content.getBlockForKey(selection.getStartKey());
    if (block.getType() === 'table') {
      content = Modifier.insertText(content, selection, text);
      onEditorStateChange(
        EditorState.push(editorState, content, 'insert-characters')
      );
      return true;
    }
    return addHtmlToDocument(data.data.getData('text/html'));
  };

  const insertLink = ({ linkUrl, displayText, newTab, entityKey }) => {
    const isNew = isNil(entityKey);
    let contentState = editorState.getCurrentContent();
    let selectionState = editorState.getSelection();
    let currentStyles: any = editorState.getCurrentInlineStyle();
    let data: {
      [key: string]: string;
    } = { url: linkUrl };
    if (newTab) {
      data = {
        ...data,
        target: '_blank',
        // noreferrer includes noopener behavior and also supresses the referrer header information
        // https://html.spec.whatwg.org/multipage/links.html#link-type-noreferrer
        // Though many articles suggest using rel="noreferrer noopener", it is actually redundant.
        rel: 'noreferrer',
      };
    }
    // different handling if inserting a new link vs replacing content of existing link
    if (isNew) {
      contentState = contentState.createEntity('LINK', 'SEGMENTED', data);
      entityKey = contentState.getLastCreatedEntityKey();
    } else {
      const block = contentState.getBlockForKey(selectionState.getAnchorKey());
      const priorBlock = contentState.getBlockBefore(
        selectionState.getAnchorKey()
      );
      block.findEntityRanges(
        (metadata: any) => {
          return metadata.includes(entityKey);
        },
        (start, end) => {
          selectionState = selectionState.merge({
            anchorOffset: start,
            focusOffset: end,
          });
          if (start > 0) {
            currentStyles = block.getInlineStyleAt(+start - 1);
          } else if (priorBlock) {
            currentStyles = priorBlock.getInlineStyleAt(
              +priorBlock.getLength() - 1
            );
          } else {
            currentStyles = [];
          }
        }
      );
      contentState = contentState.replaceEntityData(entityKey, data);
    }
    const inlineStyles = OrderedSet(['UNDERLINE', 'color.#0088FF']).union(
      currentStyles
    );
    contentState = Modifier.replaceText(
      contentState,
      selectionState,
      displayText || linkUrl,
      inlineStyles,
      entityKey
    );
    let newEditorState = EditorState.push(
      editorState,
      contentState,
      'insert-characters'
    );
    // after inserting the link text, set the selection point to the end of the link text
    const key = selectionState.getFocusKey();
    let newSelection = SelectionState.createEmpty(key);
    const offset = +selectionState.getAnchorOffset() + displayText.length;
    newSelection = newSelection.merge({
      focusOffset: offset,
      anchorOffset: offset,
    });
    // refocus on the editor content, update editorState with the selection point set to the end of the link text,
    // & styling for next inserted characters to match non-link text
    editor.current.focus();
    newEditorState = EditorState.forceSelection(newEditorState, newSelection);
    newEditorState = EditorState.setInlineStyleOverride(
      newEditorState,
      OrderedSet(currentStyles)
    );
    onEditorStateChange(newEditorState);
  };

  const addTextToDocument = text => {
    let contentState = editorState.getCurrentContent();
    let selectionState = editorState.getSelection();
    const currentStyle = editorState.getCurrentInlineStyle();
    if (typeof text !== 'string') {
      text = text.props?.children;
    }
    contentState = Modifier.replaceText(
      contentState,
      selectionState,
      text,
      currentStyle
    );
    let newEditorState = EditorState.push(
      editorState,
      contentState,
      'insert-characters'
    );
    const selectionFocus = selectionState.getFocusOffset();
    const selectionAnchor = selectionState.getAnchorOffset();
    selectionState = selectionState.merge({
      focusOffset: selectionFocus + text.length,
      anchorOffset: selectionAnchor + text.length,
    });
    newEditorState = EditorState.forceSelection(newEditorState, selectionState);
    onEditorStateChange(newEditorState);
  };

  const toggleEditMode = () => {
    if (richMode) {
      const currentHeight = editorDOMRef.current.getBoundingClientRect().height;
      setRichMode(false);
      setHeight(currentHeight);
      inTextMode.current = true;
    } else {
      onChange && onChange(codeviewRef.current?.textContent);
      setRichMode(true);
      setHeight(props.height ? height : 'auto');
    }
  };

  const insertTable = size => {
    let selection = editorState.getSelection();

    if (!selection.isCollapsed()) {
      return null;
    }
    // don't insert a table within a table
    if (
      editorState
        .getCurrentContent()
        .getBlockForKey(selection.getAnchorKey())
        .getType() === 'table'
    ) {
      return null;
    }

    const defaultCellStyle = {
      border: '1px solid rgba(0, 0, 0, 0.2)',
      padding: '6px',
      'text-align': 'center',
    };
    const cols = Array(size.cols).fill(1);
    const tableShape: any = Array(size.rows)
      .fill(cols)
      .map(row =>
        row.map(() => ({ element: 'td', style: { ...defaultCellStyle } }))
      );

    const tableKey = genKey();
    const newBlocks: any = [];
    tableShape.forEach((row, i) => {
      row.forEach((_, j) => {
        let data = Map({
          tableKey,
          tablePosition: `${tableKey}-${i}-${j}`,
          'text-align': 'center',
        });
        if (i === 0 && j === 0) {
          data = data
            .set('tableShape', tableShape)
            .set('tableStyle', {
              'border-collapse': 'collapse',
              margin: '15px 0',
              width: '100%',
            } as any)
            .set('rowStyle', [] as any);
        }
        const newBlock = new ContentBlock({
          key: genKey(),
          type: 'table',
          text: ' ',
          data,
        });
        newBlocks.push(newBlock);
      });
    });
    const selectionKey = selection.getAnchorKey();
    let contentState = editorState.getCurrentContent();
    contentState = Modifier.splitBlock(contentState, selection);
    const blockArray = contentState.getBlocksAsArray();
    const currBlock = contentState.getBlockForKey(selectionKey);
    const index = blockArray.findIndex(block => block === currBlock);
    const isEnd = index === blockArray.length - 1;
    if (blockArray[index]?.getType() === 'table') {
      newBlocks.unshift(new ContentBlock({ key: genKey() }));
    }
    if (blockArray[index + 1]?.getType() === 'table') {
      newBlocks.push(new ContentBlock({ key: genKey() }));
    }
    blockArray.splice(index + 1, 0, ...newBlocks);
    if (isEnd) {
      blockArray.push(new ContentBlock({ key: genKey() }));
    }
    const entityMap = contentState.getEntityMap();
    contentState = ContentState.createFromBlockArray(blockArray, entityMap);
    let newEditorState = EditorState.push(
      editorState,
      contentState,
      'insert-fragment'
    );
    const key = newBlocks[0].getKey();
    selection = SelectionState.createEmpty(key);
    newEditorState = EditorState.acceptSelection(newEditorState, selection);
    onEditorStateChange(newEditorState);
    return null;
  };

  const breakLink = () => {
    const selection = editorState.getSelection();
    const anchorKey = selection.getAnchorKey();
    const contentState = editorState.getCurrentContent();
    const currentContentBlock = contentState.getBlockForKey(anchorKey);
    const start = selection.getStartOffset();
    const end = selection.getEndOffset();
    const currentStyles = OrderedSet(
      editorState.getCurrentInlineStyle()
    ).subtract(['UNDERLINE', 'color.#0088FF']);

    const selectedText =
      currentContentBlock.getText().slice(start, end) ||
      getSelectionText(editorState);

    const newContentState = Modifier.replaceText(
      contentState,
      selection,
      selectedText,
      currentStyles
    );

    const resultEntity = getSelectionEntity(editorState);
    if (resultEntity) {
      const entity = contentState.getEntity(resultEntity);
      const { url, target } = entity.getData();
      if (entity.getType() === 'LINK' && Boolean(url) && Boolean(target)) {
        onEditorStateChange(
          EditorState.push(editorState, newContentState, 'insert-characters')
        );
      }
    }
  };

  const fetchEditorState = (documentation = '', LoadingText = '.....') => {
    if (documentation === '') {
      const blocksFromHTML = convertFromHTML(LoadingText);
      const state = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap
      );

      setEditorState(EditorState.createWithContent(state));
      return 'empty';
    }

    reset(documentation);
    return 'reset';
  };

  const refreshEditorState = (callback = () => {}, LoadingText = '....') => {
    const blocksFromHTML = convertFromHTML(LoadingText);

    const state = ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      blocksFromHTML.entityMap
    );
    if (typeof callback === 'function') {
      callback();
    }
    setEditorState(EditorState.createWithContent(state));
    return null;
  };

  return {
    state: {
      showLinkPopover,
      setShowLinkPopover,
      isImageActive,
      setIsImageActive,
      activeStyles,
      setActiveStyles,
      editorState,
      setEditorState,
      richMode,
      setRichMode,
      previousEditorState,
    },
    handleKeyCommand,
    handleBeforeInput,
    onEditorStateChange,
    reset,
    addHtmlToDocument,
    tableBlocksInSelection,
    setBlockType,
    mapKeyToEditorCommand,
    handleReturn,
    insertImage,
    handlePastedText,
    handlePastedFiles,
    setAlignment,
    handleDrop,
    insertLink,
    addTextToDocument,
    toggleEditMode,
    insertTable,
    breakLink,
    fetchEditorState,
    refreshEditorState,
  };
}

export { useApiTesterDocumentation };
