import { getbaseUrl } from '../helpers';
import { getConfig } from '../helpers/config';
import makeRequest from '../helpers/make-request';

const getEntityPermissions = (entityId: string) => {
  const url = `${
    getConfig().excelEngineDomain
  }/api/v1/entitypermission/getentitypermission/${entityId}`;

  return makeRequest<{
    data: DTO.EntityPermissionResponse[];
  }>('GET', url);
};

const setEntityPermissions = (
  entityPermissionRequest: DTO.EntityPermissionRequest
) => {
  const url = `${
    getConfig().excelEngineDomain
  }/api/v1/entitypermission/setentitypermission`;

  return makeRequest('POST', url, entityPermissionRequest);
};

const getKeycloakUsers = () => {
  const url = `${getbaseUrl()}keycloak/users`;
  return makeRequest<DTO.ResponseV3<DTO.KeycloakUsers[]>>('GET', url);
};

const getKeycloakUserGroups = () => {
  const url = `${getbaseUrl()}keycloak/groups`;
  return makeRequest<DTO.ResponseV3<DTO.KeycloakUserGroups[]>>('GET', url);
};

export const EntityPermissionService = {
  getEntityPermissions,
  setEntityPermissions,
  getKeycloakUsers,
  getKeycloakUserGroups,
};
