const fontFamily = 'GT Walsheim';
const MainDefaultTheme = {
  appName: 'Spark',
  CoherentBrandMarkDarkBgColoredLogo: './assets/img/coherent-logo/Coherent_brandmark_darkbg_colored_logo.svg',
  CoherentBrandMarkDarkBgWhiteLogo: './assets/img/coherent-logo/Coherent_brandmark_darkbg_white_logo.svg',
  CoherentBrandMarkWhiteBgColoredLogo: './assets/img/coherent-logo/Coherent_brandmark_whitebg_colored_logo.svg',
  CoherentDarkBgColoredLogo: './assets/img/coherent-logo/Coherent_darkbg_colored_logo.svg',
  CoherentDarkBgWhiteLogo: './assets/img/coherent-logo/Coherent_darkbg_white_logo.svg',
  CoherentWhiteBgColoredLogo: './assets/img/coherent-logo/Coherent_whitebg_colored_logo.svg',
  CoherentV1DarkBgColoredLogo: './assets/img/coherent-logo/Coherent_v1_darkbg_colored_logo.svg',
  CoherentV1DarkBgWhiteLogo: './assets/img/coherent-logo/Coherent_v1_darkbg_white_logo.svg',
  CoherentV1WhiteBgColoredLogo: './assets/img/coherent-logo/Coherent_v1_whitebg_colored_logo.svg',
  CoherentSparkDarkBgColoredLogo: './assets/img/coherent-logo/Coherent_spark_darkbg_colored_logo.svg',
  CoherentSparkDarkBgWhiteLogo: './assets/img/coherent-logo/Coherent_spark_darkbg_white_logo.svg',
  CoherentSparkWhiteBgColoredLogo: './assets/img/coherent-logo/Coherent_spark_whitebg_colored_logo.svg',
  CoherentSparkV1DarkBgColoredLogo: './assets/img/coherent-logo/Coherent_spark_v1_darkbg_colored_logo.svg',
  CoherentSparkV1DarkBgWhiteLogo: './assets/img/coherent-logo/Coherent_spark_v1_darkbg_white_logo.svg',
  CoherentSparkV1WhiteBgColoredLogo: './assets/img/coherent-logo/Coherent_spark_v1_whitebg_colored_logo.svg',
  fontLink: '',
  fontFaces: [
    {
      family: fontFamily,
      weight: '300',
      style: 'nomal',
      fileName: 'GT-Walsheim-Light',
    },
    {
      family: fontFamily,
      weight: 'normal',
      style: 'nomal',
      fileName: 'GT-Walsheim-Regular',
    },
    {
      family: fontFamily,
      weight: '500',
      style: 'nomal',
      fileName: 'GT-Walsheim-Medium',
    },
    {
      family: fontFamily,
      weight: 'bold',
      style: 'nomal',
      fileName: 'GT-Walsheim-Bold',
    },
    {
      family: 'Hiragino Kaku Gothic Pro',
      weight: 'normal',
      style: 'nomal',
      fileName: 'hiragino-kaku-gothic-pro-w3',
    },
  ],
  vars: {
    fontFamily,
  },
  color: {
    white: '#ffffff',
    black: '#000000',
    dark: '#201A3D',
    dark60: '#79768B',
    gray1: '#333333',
    gray4: '#d9d9d9',
    gray3: '#828282',
    gray6: '#F2F2F2',
    gray2: '#A6A3B1',
    gray5: '#E0E0E0',
    gray11: '#E5E5E5',
    gray7: '#BDBDBD',
    pearl40: '#F7F7F7',
    gray9: '#262626',
    gray10: '#ECECEB',
    grayDC: '#DCDCDC',
    dark20: '#D2D1D8',
    grey8: '#C2C2C2',
    dark80: '#4D4864',
    darkOverlay: '#908D9E',
    lightAqua: '#D3F3F5',
    azure: '#3B8CFF',
    azure2: '#A0C6F6',
    red: '#DC5034',
    attention20: '#FAE5E1',
    success: '#3ECB0C',
    warning: '#FFD00D',
    warning20: '#FFF6CF',
    boxShadow: '#c4c4c4',
    success15: '#e2f7db',
    lvory: '#F6F6F5',
    lightgray: '#727272',
    flamingo: '#eb5757',
    purple: '#9C43FF',
    purple20: '#EBD9FF',
    purple40: '#D7B4FF',
    purple60: '#C48EFF',
    purpleDarkened1: '#833BD8',
    purpleDarkened2: '#6B32B1',
    purpleDarkened3: '#512B8B',
    yellow: '#FFA800',
    pearl60: '#F4F4F3',
    azureBlue20: '#D8E8FF',
    azureBlue60: '#89BAFF',
    blue6: '#1890FF',
    failed: '#D31E47',
    lightwhitesmoke: '#F4F4F4',
  },
};

export default MainDefaultTheme;
