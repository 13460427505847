export const COMPARE_SERVICE_VERSIONS_REQUEST =
  'COMPARE_SERVICE_VERSIONS_REQUEST';
export const COMPARE_SERVICE_VERSIONS_SUCCESS =
  'COMPARE_SERVICE_VERSIONS_SUCCESS';
export const COMPARE_SERVICE_VERSIONS_FAILURE =
  'COMPARE_SERVICE_VERSIONS_FAILURE';
export const COMPARE_SERVICE_VERSIONS_RESET = 'COMPARE_SERVICE_VERSIONS_RESET';

export const GET_COMPARE_SERVICE_VERSIONS_RESULT_REQUEST =
  'GET_COMPARE_SERVICE_VERSIONS_RESULT_REQUEST';
export const GET_COMPARE_SERVICE_VERSIONS_RESULT_SUCCESS =
  'GET_COMPARE_SERVICE_VERSIONS_RESULT_SUCCESS';
export const GET_COMPARE_SERVICE_VERSIONS_RESULT_FAILURE =
  'GET_COMPARE_SERVICE_VERSIONS_RESULT_FAILURE';
