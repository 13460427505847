export const GET_PRODUCT_DOCS_REQUEST = 'GET_PRODUCT_DOCS_REQUEST';
export const GET_PRODUCT_DOCS_SUCCESS = 'GET_PRODUCT_DOCS_SUCCESS';
export const GET_PRODUCT_DOCS_FAILURE = 'GET_PRODUCT_DOCS_FAILURE';

export const MOVE_PRODUCT_DOCS_REQUEST = 'MOVE_PRODUCT_DOCS_REQUEST';
export const MOVE_PRODUCT_DOCS_SUCCESS = 'MOVE_PRODUCT_DOCS_SUCCESS';
export const MOVE_PRODUCT_DOCS_FAILURE = 'MOVE_PRODUCT_DOCS_FAILURE';

export const ADD_DOCUMENT_UPLOAD_START = 'ADD_DOCUMENT_UPLOAD_START';
export const ADD_DOCUMENT_UPLOAD_PROGRESS = 'ADD_DOCUMENT_UPLOAD_PROGRESS';
export const ADD_DOCUMENT_UPLOAD_XHR_REF = 'ADD_DOCUMENT_UPLOAD_XHR_REF';
export const ADD_DOCUMENT_SUCCESS = 'ADD_DOCUMENT_SUCCESS';
export const ADD_DOCUMENT_ERROR = 'ADD_DOCUMENT_ERROR';

export const ADD_DOCUMENT_RESET = 'ADD_DOCUMENT_RESET';
export const DOCUMENT_LIST_RESET = 'DOCUMENT_LIST_RESET';

export const DELETE_DOC_REQUEST = 'DELETE_DOC_REQUEST';
export const DELETE_DOC_SUCCESS = 'DELETE_DOC_SUCCESS';
export const DELETE_DOC_FAILURE = 'DELETE_DOC_FAILURE';

export const CREATE_SECTION_REQUEST = 'CREATE_SECTION_REQUEST';
export const CREATE_SECTION_SUCCESS = 'CREATE_SECTION_SUCCESS';
export const CREATE_SECTION_FAILURE = 'CREATE_SECTION_FAILURE';

export const UPDATE_SECTION_REQUEST = 'UPDATE_SECTION_REQUEST';
export const UPDATE_SECTION_SUCCESS = 'UPDATE_SECTION_SUCCESS';
export const UPDATE_SECTION_FAILURE = 'UPDATE_SECTION_FAILURE';

export const DELETE_SECTION_REQUEST = 'DELETE_SECTION_REQUEST';
export const DELETE_SECTION_SUCCESS = 'DELETE_SECTION_SUCCESS';
export const DELETE_SECTION_FAILURE = 'DELETE_SECTION_FAILURE';

export const GET_PRODUCT_DOCUMENT_CONTENT_REQUEST =
  'GET_PRODUCT_DOCUMENT_CONTENT_REQUEST';
export const GET_PRODUCT_DOCUMENT_CONTENT_SUCCESS =
  'GET_PRODUCT_DOCUMENT_CONTENT_SUCCESS';
export const GET_PRODUCT_DOCUMENT_CONTENT_FAILURE =
  'GET_PRODUCT_DOCUMENT_CONTENT_FAILURE';

export const UPDATE_PRODUCT_SECTIONS = 'UPDATE_PRODUCT_SECTIONS';

export const DOWNLOAD_PRODUCT_DOCS_REQUEST = 'DOWNLOAD_PRODUCT_DOCS_REQUEST';
export const DOWNLOAD_PRODUCT_DOCS_SUCCESS = 'DOWNLOAD_PRODUCT_DOCS_SUCCESS';
export const DOWNLOAD_PRODUCT_DOCS_FAILURE = 'DOWNLOAD_PRODUCT_DOCS_FAILURE';

export const RESET_LAST_UPLOADED_REQUEST = 'RESET_LAST_UPLOADED_REQUEST';

export const RESET_DOC_UPLOAD = 'RESET_DOC_UPLOAD';
