import { SearchOutlined } from '@ant-design/icons';
import { Checkbox, Select } from 'antd';
import { RefSelectProps, SelectProps, SelectValue } from 'antd/lib/select';
import React, { FC, RefObject, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

interface OptGroup {
  title?: string;
  options?: DTO.OptionProps[];
}
interface CustomSelectProps extends SelectProps<SelectValue> {
  forwardRef?: React.Ref<RefSelectProps> | RefObject<RefSelectProps> | null;
  placeholder?: string | JSX.Element;
  isOptGroup?: boolean;
  options?: DTO.OptionProps[];
  optGroupOptions?: OptGroup[];
  showCheckbox?: boolean;
  showIcon?: boolean;
  width?: number;
  getPopupContainer?: unknown;
  disabled?: boolean;
}

const StyledSelect = styled(props => <Select {...props} />)`
  ${({ width = 150 }) => (width ? `width: ${width}px` : '')};
  text-align: left;
`;

export const StyledCheckbox = styled(props => <Checkbox {...props} />)`
  &.ant-checkbox-wrapper {
    margin-right: 10px;
  }
`;

const CustomSelect: FC<CustomSelectProps> = ({
  placeholder,
  onBlur,
  onFocus,
  onChange,
  onDeselect,
  onSearch,
  onSelect,
  isOptGroup = false,
  options,
  optGroupOptions,
  showCheckbox,
  showIcon,
  suffixIcon,
  showSearch,
  width,
  getPopupContainer,
  ...rest
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  return (
    <>
      <StyledSelect
        {...rest}
        placeholder={
          typeof placeholder === 'string' ? (
            <FormattedMessage id={placeholder} />
          ) : (
            placeholder
          )
        }
        getPopupContainer={trigger => getPopupContainer || trigger.parentNode}
        onBlur={onBlur}
        onFocus={onFocus}
        onChange={onChange}
        onSelect={onSelect}
        onDeselect={onDeselect}
        onSearch={onSearch}
        showSearch={showSearch}
        onDropdownVisibleChange={isVisible => setIsDropdownOpen(isVisible)}
        suffixIcon={
          showSearch && isDropdownOpen ? <SearchOutlined /> : suffixIcon
        }
        width={width}
      >
        {!isOptGroup &&
          options &&
          options.map(item => (
            <>
              {showIcon && item.icon && (
                <Select.Option
                  data-testid={item.data_testid}
                  key={item.key}
                  value={item.value}
                  label={item.label}
                  disabled={item.disabled}
                  className={item.selected ? 'selected-item' : ''}
                >
                  <div
                    style={{
                      display: 'flex',
                      verticalAlign: 'middle',
                      alignItems: 'center',
                    }}
                  >
                    {showCheckbox && <StyledCheckbox checked={item.selected} />}
                    <span className="mr-8">{item.icon}</span>
                    {item.title}
                  </div>
                </Select.Option>
              )}
              {!(showIcon && item.icon) && (
                <Select.Option
                  data-testid={item.data_testid}
                  key={item.key}
                  value={item.value}
                  label={item.label}
                  disabled={item.disabled}
                  className={item.selected ? 'selected-item' : ''}
                >
                  {showCheckbox && <StyledCheckbox checked={item.selected} />}
                  {item.title}
                </Select.Option>
              )}
            </>
          ))}
        {isOptGroup &&
          optGroupOptions &&
          optGroupOptions.map(optGroup => (
            <Select.OptGroup key={optGroup.title} label={optGroup.title}>
              {optGroup.options &&
                optGroup.options.map(item => (
                  <Select.Option
                    data-testid={item.data_testid}
                    key={item.key}
                    value={item.value}
                    label={item.label}
                    disabled={item.disabled}
                  >
                    {item.title}
                  </Select.Option>
                ))}
            </Select.OptGroup>
          ))}
      </StyledSelect>
    </>
  );
};

const CustomSelectForwardRef = React.forwardRef(
  (props: CustomSelectProps, ref: React.Ref<RefSelectProps>) => (
    <CustomSelect forwardRef={ref} {...props} />
  )
);

export { CustomSelectForwardRef as CustomSelect };
