import queryString from 'query-string';
import React, { Suspense, FC } from 'react';
import { RawIntlProvider } from 'react-intl';
import { BrowserRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { SuspenseLoading } from '../components/Layout';
import { getConfig } from '../helpers';

const mapStateToAppProps = ({
  auth: { loggedIn },
  language: { intl },
}: STATES.AppState) => ({
  intl,
  loggedIn,
});

const GlobalProvider: FC = ({ children }) => {
  const { intl, loggedIn } = useSelector(mapStateToAppProps);

  const getUserConfirmation = (
    message: string,
    callback: (ok: boolean) => void
  ) => {
    const confirmModalTrigger = window[message];

    if (confirmModalTrigger) {
      confirmModalTrigger(callback);
      return;
    }

    callback(true);
  };

  const getBaseUrl = () => {
    const queryParam =
      window.location.pathname.replace(
        process.env.REACT_APP_PUBLIC_URL || '',
        ''
      ) || '/';
    const queryParamArray = queryParam.split('/');
    if (!loggedIn) {
      const qss = queryString.parse(window.location.search.replaceAll('+', '%2B'));
      if (
        queryParamArray.length > 1 &&
        queryParamArray[1] !== '' &&
        queryParamArray[1] !== 'login'
      ) {
        localStorage.setItem('Tenant', queryParamArray[1]);
      } else if (qss.tenant) {
        localStorage.setItem('Tenant', qss.tenant as string);
      }
    }
    return `${process.env.REACT_APP_PUBLIC_URL}/${localStorage
      .getItem('Tenant')
      ?.toString() ||
      getConfig()?.defaultTenant ||
      'coherent'}`;
  };

  return (
    <RawIntlProvider value={intl}>
      <BrowserRouter
        basename={getBaseUrl()}
        getUserConfirmation={getUserConfirmation}
      >
        <Suspense fallback={<SuspenseLoading />}>{children}</Suspense>
      </BrowserRouter>
    </RawIntlProvider>
  );
};

export default GlobalProvider;
