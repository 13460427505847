import moment from 'moment';
import { Locale } from 'antd/lib/locale-provider';
import configProviderEn from 'antd/es/locale/en_US';
import configProviderJa from 'antd/es/locale/ja_JP';

import { LanguageConstants } from '../constants';
import { LanguageThunkAction } from './types';

import 'moment/locale/ja';

const loadMessages = (
  language: string
): LanguageThunkAction => async dispatch => {
  let currentLanguage = language.toLowerCase();
  let configProviderLocale: Locale = configProviderEn;

  if (!LanguageConstants.SUPPORTED_LANGUAGES.includes(currentLanguage)) {
    currentLanguage = currentLanguage.split('-')[0];
    if (!LanguageConstants.SUPPORTED_LANGUAGES.includes(currentLanguage)) {
      currentLanguage = 'en';
    }
  }

  moment.locale(currentLanguage);

  if (currentLanguage === 'ja') {
    configProviderLocale = configProviderJa;
  }

  const { messages } = await import(`../locale/${currentLanguage}`);

  dispatch({
    type: LanguageConstants.SET_LANGUAGE_MESSAGES,
    payload: {
      currentLanguage,
      messages,
      configProviderLocale,
    },
  });
};

const appendMessages = (messages: {
  [id: string]: string;
}): LanguageThunkAction => async dispatch => {
  dispatch({
    type: LanguageConstants.APPEND_LANGUAGE_MESSAGES,
    payload: {
      messages,
    },
  });
};

export const LanguageActions = {
  loadMessages,
  appendMessages,
};
