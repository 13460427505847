import { useRef, useEffect } from 'react';
import { PAGETITLE_SPARK_PRODUCT } from '../constants/pageTitle.constants';

function useDocumentTitle(title, prevailOnUnmount = false) {
  const defaultTitle = useRef(document.title);

  useEffect(() => {
    document.title = `${title} | ${PAGETITLE_SPARK_PRODUCT}`;
  }, [title]);

  useEffect(
    () => () => {
      if (!prevailOnUnmount) {
        document.title = defaultTitle.current;
      }
    },

    []
  );
}

export { useDocumentTitle };
