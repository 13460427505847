import { ReactNode } from 'react';
import { AlertActions } from './alert.actions';
import { BookmarkConstants } from '../constants';
import { AppThunkAction, BookmarkAction, BookmarkThunkAction } from './types';
import { BookmarkService } from '../services';
import { ApiError } from '../helpers';

const getBookmarks = (
  requestParams: DTO.GetBookmarksActionPropType
): AppThunkAction<boolean> => async dispatch => {
  try {
    dispatch({
      type: BookmarkConstants.GET_BOOKMARKS_REQUEST,
      payload: requestParams,
    });

    const { payload, status } = await BookmarkService.getBookmarks(
      requestParams
    );

    if (status !== 200 || payload.status === 'Error') {
      throw new ApiError(payload);
    }
    dispatch({
      type: BookmarkConstants.GET_BOOKMARKS_SUCCESS,
      payload: {
        bookmarks: payload.data.data,
      },
    });
    return true;
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: BookmarkConstants.GET_BOOKMARK_FAILURE,
      payload: {
        error: msg,
      },
    });
    return false;
  }
};

const getBookmark = (bookmarkId: string): AppThunkAction => async dispatch => {
  try {
    dispatch({
      type: BookmarkConstants.GET_BOOKMARK_REQUEST,
    });

    const { payload, status } = await BookmarkService.getBookmark(bookmarkId);

    if (status !== 200 || payload.status === 'Error') {
      throw new ApiError(payload);
    }

    dispatch({
      type: BookmarkConstants.GET_BOOKMARK_SUCCESS,
      payload: {
        bookmark: payload.data,
      },
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: BookmarkConstants.GET_BOOKMARK_FAILURE,
      payload: {
        error: msg,
      },
    });
  }
};

const createBookmark = (
  createBookmarkParams: DTO.CreateBookmarkActionPropType
): AppThunkAction<string | undefined> => async dispatch => {
  try {
    dispatch({
      type: BookmarkConstants.CREATE_BOOKMARK_REQUEST,
      payload: createBookmarkParams,
    });

    const { payload, status } = await BookmarkService.createBookmark(
      createBookmarkParams
    );

    if (status !== 200 || payload.status === 'Error') {
      throw new ApiError(payload);
    }
    dispatch({
      type: BookmarkConstants.CREATE_BOOKMARK_SUCCESS,
      payload: {
        id: payload.data,
      },
    });

    return payload.data;
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: BookmarkConstants.GET_BOOKMARK_FAILURE,
      payload: {
        error: msg,
      },
    });

    return undefined;
  }
};

const deleteBookmark = (
  bookmarkId: string,
  content?: ReactNode
): AppThunkAction<boolean> => async (dispatch, getState) => {
  try {
    const {
      language: { intl },
    } = getState();
    dispatch({
      type: BookmarkConstants.DELETE_BOOKMARK_REQUEST,
      payload: {
        id: bookmarkId,
      },
    });
    const { payload, status } = await BookmarkService.deleteBookmark(
      bookmarkId
    );

    if (status !== 200 || payload.status === 'Error') {
      throw new ApiError(payload);
    }

    content && dispatch(AlertActions.info(content));
    !content &&
      dispatch({
        type: BookmarkConstants.DELETE_BOOKMARK_SUCCESS,
      });
    dispatch(
      AlertActions.success(
        intl.formatMessage({
          id: 'Bookmark.notification.success.delete',
        })
      )
    );
    return true;
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: BookmarkConstants.GET_BOOKMARK_FAILURE,
      payload: {
        error: msg,
      },
    });

    return false;
  }
};

const renameBookmark = (
  renameBookmarkParams: DTO.RenameBookmarkRequestProp,
  callback?: (res: boolean) => void
): AppThunkAction => async (dispatch, getState) => {
  try {
    const {
      language: { intl },
    } = getState();

    dispatch({
      type: BookmarkConstants.RENAME_BOOKMARK_REQUEST,
      payload: {
        id: renameBookmarkParams.id,
        name: renameBookmarkParams.name,
      },
    });

    const { payload, status } = await BookmarkService.renameBookmark(
      renameBookmarkParams
    );

    if (status !== 200 || payload.status === 'Error') {
      callback && typeof callback === 'function' && callback(false);
      if (
        payload.errorCode ===
        BookmarkConstants.BOOKMARK_WITH_SAME_NAME_ALREADY_EXISTS
      ) {
        dispatch(
          AlertActions.error(
            intl.formatMessage({
              id: 'Bookmark.alreadyExists',
            })
          )
        );
        return;
      }

      if (payload.errorCode === BookmarkConstants.INVALID_BOOKMARK_NAME) {
        dispatch(
          AlertActions.error(
            intl.formatMessage({
              id: 'Bookmark.bookmark.warning',
            })
          )
        );
        return;
      }

      throw new ApiError(payload);
    }
    dispatch({
      type: BookmarkConstants.RENAME_BOOKMARK_SUCCESS,
    });
    dispatch(
      AlertActions.success(
        intl.formatMessage({
          id: 'Bookmark.notification.success.rename',
        })
      )
    );
    callback && typeof callback === 'function' && callback(true);
  } catch (error) {
    const msg = dispatch(AlertActions.error(error));
    callback && typeof callback === 'function' && callback(false);
    dispatch({
      type: BookmarkConstants.RENAME_BOOKMARK_FAILURE,
      payload: {
        error: msg,
      },
    });
  }
};

const getBookmarkByUrl = (
  requestParams: DTO.GetBookmarkByUrlActionPropTypes
): AppThunkAction<DTO.Bookmark | null> => async () => {
  try {
    const { payload } = await BookmarkService.getBookmarksByUrl(requestParams);
    if (!payload.data) {
      return null;
    }
    return payload.data.data[0];
  } catch (error) {
    return error.message;
  }
};

const resetBookmarkByUrl = (): BookmarkAction => ({
  type: BookmarkConstants.RESET_BOOKMARK_BY_URL,
});

const clearBookmarks = (): BookmarkThunkAction => dispatch => {
  dispatch({
    type: BookmarkConstants.CLEAR_BOOKMARKS,
  });
};

const popBookmarkIds = (): BookmarkThunkAction => dispatch => {
  dispatch({
    type: BookmarkConstants.CLEAR_BOOKMARK_IDS,
  });
};

export const BookmarkActions = {
  getBookmarks,
  getBookmark,
  createBookmark,
  deleteBookmark,
  renameBookmark,
  getBookmarkByUrl,
  resetBookmarkByUrl,
  clearBookmarks,
  popBookmarkIds,
};
