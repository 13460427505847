import _ from 'lodash';
import moment from 'moment';
import { LanguageConstants, SystemConstants } from '../constants';
import { ApiError } from '../helpers';
import { SystemService } from '../services/system.services';
import { AlertActions } from './alert.actions';
import { AppThunkAction, SystemThunkAction } from './types';

const getReleaseDetails = (): SystemThunkAction => async dispatch => {
  try {
    dispatch({
      type: SystemConstants.GET_RELEASE_DETAILS_REQUEST,
    });

    const { payload, status } = await SystemService.getReleaseDetails();

    if (status !== 200 || payload.status !== 'Success') {
      throw new ApiError(payload);
    }

    dispatch({
      type: SystemConstants.GET_RELEASE_DETAILS_SUCCESS,
      payload: { releaseDetails: payload.data },
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: SystemConstants.GET_RELEASE_DETAILS_FAILURE,
      payload: { error: msg },
    });
  }
};

const getLookupData = (isCached = true): SystemThunkAction<boolean> => async (
  dispatch,
  getState
) => {
  try {
    const {
      system: { isLoadingLookupData },
    } = getState();

    if (isLoadingLookupData) {
      return true;
    }

    dispatch({
      type: SystemConstants.GET_LOOKUP_DATA_REQUEST,
    });
    const { payload, status } = await SystemService.getLookupData(isCached);

    if (status !== 200 || payload.status !== 'Success') {
      throw new ApiError(payload);
    }

    dispatch({
      type: SystemConstants.GET_LOOKUP_DATA_SUCCESS,
      payload: { lookupData: payload.data },
    });
    return true;
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: SystemConstants.GET_LOOKUP_DATA_FAILURE,
      payload: { error: msg },
    });
    return false;
  }
};

const getProductDocumentMetadata = (): SystemThunkAction => async (
  dispatch,
  getState
) => {
  try {
    const {
      system: { isLoadingLookupData },
    } = getState();

    if (isLoadingLookupData) {
      return;
    }

    dispatch({
      type: SystemConstants.GET_PRODUCT_DOCUMENT_METADATA_REQUEST,
    });

    const {
      payload,
      status,
    } = await SystemService.getProductDocumentMetadata();

    if (status !== 200 || payload.status !== 'Success') {
      throw new ApiError(payload);
    }

    dispatch({
      type: SystemConstants.GET_PRODUCT_DOCUMENT_METADATA_SUCCESS,
      payload: { metadata: payload.data },
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: SystemConstants.GET_PRODUCT_DOCUMENT_METADATA_FAILURE,
      payload: { error: msg },
    });
  }
};

const getDiagnoseDetails = (): SystemThunkAction => async dispatch => {
  try {
    dispatch({
      type: SystemConstants.GET_DIAGNOSE_DETAILS_REQUEST,
    });

    const { payload, status } = await SystemService.getDiagnoseDetails();
    if (status !== 200 || payload.status === 'Error') {
      throw new ApiError(payload);
    }

    const { data } = payload;

    dispatch({
      type: SystemConstants.GET_DIAGNOSE_DETAILS_SUCCESS,
      payload: { diagnoseDetails: data },
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: SystemConstants.GET_DIAGNOSE_DETAILS_FAILURE,
      payload: { error: msg },
    });
  }
};

const clearCacheMemory = (
  pattern: string
): SystemThunkAction => async dispatch => {
  try {
    for (let ctr = 0; ctr < 5; ctr += 1) {
      dispatch({
        type: SystemConstants.CLEAR_CACHE_MEMORY_REQUEST,
      });

      // eslint-disable-next-line no-await-in-loop
      const { payload, status } = await SystemService.clearCacheMemory(pattern);

      if (status !== 200 || payload.status === 'Error') {
        throw new ApiError(payload);
      }

      const { data } = payload;

      dispatch({
        type: SystemConstants.CLEAR_CACHE_MEMORY_SUCCESS,
        payload: { diagnoseDetails: data },
      });
    }
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: SystemConstants.CLEAR_CACHE_MEMORY_FAILURE,
      payload: { error: msg },
    });
  }
};

const clearApplicationMemory = (): SystemThunkAction => async dispatch => {
  try {
    for (let ctr = 0; ctr < 5; ctr += 1) {
      dispatch({
        type: SystemConstants.CLEAR_APPLICATION_MEMORY_REQUEEST,
      });
      // eslint-disable-next-line no-await-in-loop
      const { payload, status } = await SystemService.clearApplicationMemory();

      if (status !== 200 || payload.status === 'Error') {
        throw new ApiError(payload);
      }

      const { data } = payload;

      dispatch({
        type: SystemConstants.CLEAR_APPLICATION_MEMORY_SUCCESS,
        payload: { diagnoseDetails: data },
      });
    }
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: SystemConstants.CLEAR_APPLICATION_MEMORY_FAILURE,
      payload: { error: msg },
    });
  }
};

const getAppConfig = (
  currentLanguage: string
): AppThunkAction => async dispatch => {
  dispatch({ type: SystemConstants.GET_APPCONFIG_REQUEST });

  try {
    const getAppConfigs = async (count = 0) => {
      const callCount = count;
      if (callCount < 3) {
        try {
          const { payload } = await SystemService.getAppConfig();
          if (payload.status !== 'Success') {
            const { payload } = await getAppConfigs(callCount + 1);
            return payload;
          }
          return payload;
        } catch (_e) {
          return getAppConfigs(callCount + 1);
        }
      }
      throw new ApiError({
        errorCode: 'CONFIGURATION_NOT_FOUND',
      });
    };

    const { data } = await getAppConfigs();
    dispatch({
      type: SystemConstants.GET_APPCONFIG_SUCCESS,
      payload: {
        config: data,
      },
    });

    if (data['LanguageMessages'] && currentLanguage) {
      dispatch({
        type: LanguageConstants.APPEND_LANGUAGE_MESSAGES,
        payload: {
          messages: data['LanguageMessages'][currentLanguage],
        },
      });
    }

    const showMaintenanceBanner = localStorage.getItem('showMaintenanceBanner');

    if (showMaintenanceBanner !== 'false') {
      if (
        data['ShowMaintenanceBanner']?.toString()?.toLowerCase() === 'true' &&
        moment.utc(data['MaintenanceEndDateTime']).isAfter() &&
        (moment.utc(data['MaintenanceEndDateTime']).isBefore(
          moment()
            .utc()
            .add(7, 'd')
        ) ||
          moment.utc(data['MaintenanceStartDateTime']).isBefore(
            moment()
              .utc()
              .add(7, 'd')
          ))
      ) {
        localStorage.setItem('showMaintenanceBanner', 'true');
      } else {
        localStorage.removeItem('showMaintenanceBanner');
      }
      dispatch({
        type: SystemConstants.UPDATE_BANNER_VISIBLITY,
      });
    }
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: SystemConstants.GET_APPCONFIG_FAILURE,
      payload: { error: msg },
    });
  }
};

const getCustomBrandingImage = (
  imageAPIEndpointURL: string
): SystemThunkAction<string> => async dispatch => {
  try {
    dispatch({
      type: SystemConstants.GET_CUSTOMBRANDINGIMAGE_REQUEST,
      payload: { imageAPIEndpointURL },
    });

    const { status, payload } = await SystemService.getCustomBrandingImage(
      imageAPIEndpointURL
    );

    if (status !== 200) {
      throw new ApiError(payload);
    }

    const imageUrl = URL.createObjectURL(payload.blob);

    dispatch({
      type: SystemConstants.GET_CUSTOMBRANDINGIMAGE_SUCCESS,
      payload: {
        imageUrl,
        imageData: payload.blob,
      },
    });

    return imageUrl;
  } catch (error) {
    dispatch({
      type: SystemConstants.GET_CUSTOMBRANDINGIMAGE_FAILURE,
      payload: {
        imageAPIEndpointURL,
        error: error.toString(),
      },
    });
    return '';
  }
};
export const SystemActions = {
  getReleaseDetails,
  getDiagnoseDetails,
  getLookupData,
  clearCacheMemory,
  clearApplicationMemory,
  getProductDocumentMetadata,
  getAppConfig,
  getCustomBrandingImage,
};
