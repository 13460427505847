import { getConfig } from '../helpers/config';
import httpClient from '../helpers/http-client';
import makeRequest from '../helpers/make-request';

const getByUserId = () =>
  httpClient<{ data: DTO.BackgroundJob[] }>({
    method: 'GET',
    path: '/api/backgroundjob/GetUserJobs',
  });

const cancelTaskQueue = (id: string) =>
  httpClient({
    method: 'POST',
    path: `/api/backgroundjob/CancelTaskQueue/${id}`,
    body: {},
  });

const getRunStatus = (taskQueueId: string) => {
  const url = `${
    getConfig().excelEngineDomain
  }/api/BackgroundJob/GetRunStatus/${taskQueueId}`;
  return makeRequest<{ data: DTO.TestbedQueueProgress }>('GET', url);
};

const deleteBackgroundJob = (
  jobId: string,
  queueType: 'TaskQueue' | 'TestbedQueueBatch'
) => {
  const url = `${
    getConfig().excelEngineDomain
  }/api/backgroundjob/DeleteBackgroundJob/taskId/${jobId}/queueType/${queueType}`;
  return makeRequest('POST', url, {});
};

export const BackgroundJobsService = {
  getByUserId,
  cancelTaskQueue,
  getRunStatus,
  deleteBackgroundJob,
};
