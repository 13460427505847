import { message } from 'antd';
import React, { lazy, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
} from 'react-router-dom';
import { AnonymousRoute, PrivateRoute } from '../components/Routes';
import { fetchJsonConfig, getConfig } from '../helpers/config';
import { decodeToken } from '../helpers/user-auth';
import { apmInit } from '../logging/apm';
import { sentryInit } from '../logging/sentry';
import installPendo from '../logging/installPendo';
import { RouteMappings } from '../constants/routes.constants';
import { InsightsActions, SystemActions } from '../actions';
import { useLanguage } from '../hooks';

const MainThemeProvider = lazy(() =>
  import(
    /* webpackChunkName: 'MainThemeProvider' */ '../providers/MainThemeProvider'
  )
);

const Error = lazy(() => import(/* webpackChunkName: 'Error' */ './Error'));
const Auth = lazy(() => import(/* webpackChunkName: 'Auth' */ './Auth'));
const Main = lazy(() => import(/* webpackChunkName: 'Main' */ './Main'));
const Admin = lazy(() => import(/* webpackChunkName: 'Admin' */ './Admin'));

const mapStateToAppProps = ({
  auth: { loggedIn, isLoggingOut, userAuth },
  language: { intl },
}: STATES.AppState) => ({
  loggedIn,
  intl,
  isLoggingOut,
  userAuth,
});

const App = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const [isConfigLoading, setIsConfigLoading] = useState<boolean>(true);
  const [lastVisityPage, setLastVisitedPage] = useState<string | undefined>(
    undefined
  );
  const [pendoConfig, setPendoConfig] = useState<
    DTO.GlobalConfiguration['pendo']
  >();
  const { language } = useLanguage();
  const { loggedIn, isLoggingOut, userAuth } = useSelector(mapStateToAppProps);
  const match = useRouteMatch<{ [name: string]: string }>({
    path: Object.keys(RouteMappings),
    exact: true,
  });

  useEffect(() => {
    return history.listen(_ => {
      message && message.destroy();
    });
  }, [history]);

  useEffect(() => {
    if (match && loggedIn && !isConfigLoading) {
      const routeName = RouteMappings[match.path].name;
      if (routeName !== lastVisityPage) {
        dispatch(InsightsActions.incrementPageCounter(routeName));
        setLastVisitedPage(routeName);
      }
    }
  }, [location, dispatch, isConfigLoading]);

  useEffect(() => {
    if (loggedIn && userAuth) {
      const loginEvent = new CustomEvent('user_session_established', {
        detail: {
          email: userAuth?.username,
        },
      });
      window.dispatchEvent(loginEvent);
    }
  }, [loggedIn, userAuth]);

  useEffect(() => {
    const loadConfiguration = async () => {
      setIsConfigLoading(true);
      await fetchJsonConfig();
      apmInit();
      setIsConfigLoading(false);
      setPendoConfig(getConfig().pendo);
    };

    loadConfiguration();
  }, [setIsConfigLoading]);

  const [isPendoInstalled, setIsPendoInstalled] = useState<boolean>(false);

  useEffect(() => {
    let interval: number;
    if (!!pendoConfig?.enabled && !!pendoConfig?.apiKey) {
      installPendo(pendoConfig.apiKey);
      interval = window.setInterval(() => {
        if (
          window.pendo?.clearSession &&
          window.pendo?.getSerializedMetadata &&
          window.pendo?.identify &&
          window.pendo?.initialize
        ) {
          setIsPendoInstalled(true);
          clearInterval(interval);
        }
      }, 1000);
    }

    return () => {
      window.clearInterval(interval);
    };
  }, [pendoConfig?.enabled, pendoConfig?.apiKey]);

  useEffect(() => {
    if (isPendoInstalled) {
      const updatePendoConfig = async () => {
        if (!userAuth?.idToken) {
          window.pendo.clearSession();
          return;
        }

        const decodedJwt = await decodeToken(userAuth.idToken);

        if (!decodedJwt) {
          window.pendo.clearSession();
          return;
        }

        const hostnameRegex = /(?<app>[^.]+)\.(?<environment>[^.]+)\.((?<region>[^.]+)\.){0,1}coherent\.global/;
        const matchObj = hostnameRegex.exec(window.location.hostname);

        const config = {
          account: {
            id: decodedJwt.realm.toLowerCase(),
          },
          visitor: {
            id: decodedJwt.preferred_username,
            email: decodedJwt.preferred_username,
            environment: [
              matchObj?.groups?.region,
              matchObj?.groups?.environment,
            ]
              .filter(Boolean)
              .join('-'),
            tenant: decodedJwt.realm.toLowerCase(),
            given_name: decodedJwt.given_name,
            family_name: decodedJwt.family_name,
            groups: decodedJwt.groups.join(','),
            tenant_admin: decodedJwt.groups.includes('tenant-admin'),
            language,
            user_created_timestamp: new Date(
              decodedJwt.user_created_timestamp
            ).toISOString(),
          },
        };

        const pendoMeta = window.pendo.getSerializedMetadata();
        if (!pendoMeta.account?.id && !pendoMeta.visitor?.id) {
          window.pendo.initialize(config);
        } else {
          window.pendo.identify(config);
        }
      };
      updatePendoConfig();
    }
  }, [isPendoInstalled, language, userAuth?.idToken]);

  useEffect(() => {
    if (!isConfigLoading && userAuth) {
      sentryInit(userAuth);
    }
  }, [isConfigLoading, userAuth]);

  useEffect(() => {
    if (loggedIn && !isConfigLoading) {
      dispatch(SystemActions.getLookupData(false));
    }
  }, [dispatch, loggedIn, isConfigLoading]);

  return (
    <>
      {isConfigLoading ? (
        <></>
      ) : (
        <Switch>
          <PrivateRoute
            loggedIn={loggedIn}
            isLoggingOut={isLoggingOut}
            path="/admin"
            render={() => (
              <MainThemeProvider>
                <Admin />
              </MainThemeProvider>
            )}
          />
          <PrivateRoute
            loggedIn={loggedIn}
            isLoggingOut={isLoggingOut}
            path="/error"
            render={() => (
              <MainThemeProvider>
                <Error />
              </MainThemeProvider>
            )}
          />
          <AnonymousRoute
            loggedIn={loggedIn}
            path="/login"
            render={() => (
              <MainThemeProvider>
                <Auth />
              </MainThemeProvider>
            )}
          />
          <PrivateRoute
            loggedIn={loggedIn}
            isLoggingOut={isLoggingOut}
            path="/"
            render={() => (
              <MainThemeProvider>
                <Main />
              </MainThemeProvider>
            )}
          />
        </Switch>
      )}
    </>
  );
};

export default App;
