import { CopyOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React, { memo, NamedExoticComponent } from 'react';
import { useIntl } from 'react-intl';
import SyntaxHighlighter, {
  SyntaxHighlighterProps,
} from 'react-syntax-highlighter';
import a11yDark from 'react-syntax-highlighter/dist/cjs/styles/hljs/a11y-dark';
import styled from 'styled-components';
import { copyToClipboard } from '../../../helpers';
import { UserService } from '../../../services';
import { ScrollCss } from '../ScrollContainer';

export interface CodeHighlighterProp extends SyntaxHighlighterProps {
  codeString: string;
  allowCopy?: boolean;
  width?: number;
  height: number;
  enabledFull?: boolean;
  productName: string;
  serviceName: string;
  userAction: string;
  auditType: string;
}

const Highlighter = styled.div<{
  heightT: number;
  enabledFull: boolean;
}>`
  .SyntaxHighlighter {
    position: relative;
    border-radius: 4px;

    pre {
      border-radius: 4px;
      ${ScrollCss}
      ::-webkit-scrollbar-corner {
        background: ${({ theme }) => theme.color.dark};
        border-radius: 4px;
      }
      ::-webkit-scrollbar {
        background: ${({ theme }) => theme.color.dark};
        border-radius: 4px;
      }
      width: calc(97vw - 100px);
      margin: 0 30px 16px 30px;
      height: ${({ heightT }) => `${heightT}px`};
      overflow-y: scroll;
      overflow-x: scroll;
      background: ${({ theme }) => theme.color.dark} !important;
    }
    .sticky-copy-btn {
      position: absolute;
      bottom: 15px;
      right: 45px;
      .anticon {
        color: ${({ theme }) => theme.color.white};
      }
    }
  }
`;

const CodeHighlighter: NamedExoticComponent<CodeHighlighterProp> = memo(
  ({
    codeString,
    allowCopy,
    theme,
    height,
    enabledFull,
    productName,
    serviceName,
    userAction,
    auditType,
    width,
    ...rest
  }) => {
    const intl = useIntl();
    let languageCode = '';
    if (userAction === 'swagger') {
      userAction = 'CopiedSwagger';
    } else if (userAction === 'swaggerForm') {
      userAction = 'CopiedSwaggerFormData';
    } else {
      if (userAction && typeof userAction === 'string') {
        languageCode = userAction.replace('###', ' - ');
      }
      userAction = 'CopiedOtherLanguageCode';
    }
    const onCopyContent = () => {
      copyToClipboard(codeString, intl.formatMessage({ id: 'Global.copied' }));
      UserService.saveUserAction({
        productName,
        serviceName,
        userAction,
        auditType,
        languageCode,
      });
    };
    return (
      <Highlighter heightT={height} enabledFull={enabledFull || false}>
        <div className="SyntaxHighlighter">
          <SyntaxHighlighter style={a11yDark} {...rest}>
            {codeString}
          </SyntaxHighlighter>
          {allowCopy && (
            <Button
              type="link"
              size="large"
              data-testid="at-integration-copy-code-snippet-icon"
              className="p-0 sticky-copy-btn icon-button"
              icon={<CopyOutlined />}
              onClick={onCopyContent}
            />
          )}
        </div>
      </Highlighter>
    );
  }
);

export { CodeHighlighter };
