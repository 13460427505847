import { SetPermissionType } from '../components/SetPermissions/SetPermissionsModal';
import { EntityPermissionConstant } from '../constants';
import { ApiError } from '../helpers';
import { EntityPermissionService } from '../services';
import { AlertActions } from './alert.actions';
import { EntityPermissionThunkAction } from './types';

const setEntityPermission = (
  entityPermissionRequest: DTO.EntityPermissionRequest,
  type: SetPermissionType
): EntityPermissionThunkAction => async dispatch => {
  dispatch({
    type: EntityPermissionConstant.SET_ENTITY_PERMISSION_REQUEST,
    payload: {
      updatedPrincipalName:
        type === 'Update' ? entityPermissionRequest.members : '',
    },
  });

  try {
    const {
      status,
      payload,
    } = await EntityPermissionService.setEntityPermissions(
      entityPermissionRequest
    );

    if (status !== 200 || payload.status !== 'Success') {
      throw new ApiError(payload);
    }

    if (type === 'Add') {
      const {
        status,
        payload,
      } = await EntityPermissionService.getEntityPermissions(
        entityPermissionRequest.entityId
      );

      if (status !== 200 || payload.status !== 'Success') {
        throw new ApiError(payload);
      }

      const newPrincipalDetail = payload.data.find(
        item => item.principal === entityPermissionRequest.members
      );

      if (newPrincipalDetail) {
        dispatch({
          type: EntityPermissionConstant.SET_ENTITY_PERMISSION_SUCCESS,
          payload: {
            newPrincipalDetail,
          },
        });

        setTimeout(() => {
          dispatch({
            type: EntityPermissionConstant.REMOVE_HIGHLIGHTS,
            payload: {
              principalName: newPrincipalDetail.principal,
            },
          });
        }, 5000);
      }
    } else if (type === 'Delete') {
      dispatch({
        type: EntityPermissionConstant.SET_ENTITY_PERMISSION_SUCCESS,
        payload: { deletedPrincipalName: entityPermissionRequest.members },
      });
    } else {
      dispatch({
        type: EntityPermissionConstant.SET_ENTITY_PERMISSION_SUCCESS,
        payload: {
          updatedPrincipalDetail: entityPermissionRequest,
        },
      });
    }
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: EntityPermissionConstant.SET_ENTITY_PERMISSION_FAILURE,
      payload: {
        error: msg,
        updatedPrincipalName:
          type === 'Update' ? entityPermissionRequest.members : '',
      },
    });
  }
};

const getEntityPermission = (
  entityId: string
): EntityPermissionThunkAction => async dispatch => {
  dispatch({
    type: EntityPermissionConstant.GET_ENTITY_PERMISSION_REQUEST,
  });

  try {
    const {
      status,
      payload,
    } = await EntityPermissionService.getEntityPermissions(entityId);

    if (status !== 200 || payload.status !== 'Success') {
      throw new ApiError(payload);
    }

    dispatch({
      type: EntityPermissionConstant.GET_ENTITY_PERMISSION_SUCCESS,
      payload: {
        entityPermissions: payload.data,
      },
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: EntityPermissionConstant.GET_ENTITY_PERMISSION_FAILURE,
      payload: {
        error: msg,
      },
    });
  }
};

const resetEntityPermission = (): EntityPermissionThunkAction => dispatch => {
  dispatch({
    type: EntityPermissionConstant.RESET_ENTITY_PERMISSION,
  });
};

const getKeycloakUsers = (): EntityPermissionThunkAction => async dispatch => {
  dispatch({
    type: EntityPermissionConstant.GET_KEYCLOAK_USERS_REQUEST,
  });

  try {
    const {
      status,
      payload,
    } = await EntityPermissionService.getKeycloakUsers();

    if (status !== 200 || payload.status !== 'Success') {
      throw new ApiError(payload);
    }

    dispatch({
      type: EntityPermissionConstant.GET_KEYCLOAK_USERS_SUCCESS,
      payload: {
        list: payload.response_data,
      },
    });
  } catch (error) {
    await dispatch(AlertActions.error(error));
    dispatch({
      type: EntityPermissionConstant.GET_KEYCLOAK_USERS_FAILURE,
    });
  }
};

const getKeycloakUserGroups = (): EntityPermissionThunkAction => async dispatch => {
  dispatch({
    type: EntityPermissionConstant.GET_KEYCLOAK_USER_GROUPS_REQUEST,
  });

  try {
    const {
      status,
      payload,
    } = await EntityPermissionService.getKeycloakUserGroups();

    if (status !== 200 || payload.status !== 'Success') {
      throw new ApiError(payload);
    }

    dispatch({
      type: EntityPermissionConstant.GET_KEYCLOAK_USER_GROUPS_SUCCESS,
      payload: {
        list: payload.response_data,
      },
    });
  } catch (error) {
    await dispatch(AlertActions.error(error));
    dispatch({
      type: EntityPermissionConstant.GET_KEYCLOAK_USER_GROUPS_FAILURE,
    });
  }
};

export const EntityPermissionActions = {
  setEntityPermission,
  getEntityPermission,
  resetEntityPermission,
  getKeycloakUsers,
  getKeycloakUserGroups,
};
