import { DownOutlined, FileOutlined } from '@ant-design/icons';
import {
  Alert,
  Breadcrumb,
  Button,
  Card,
  Checkbox,
  Col,
  Collapse,
  Descriptions,
  Divider,
  Empty,
  Form,
  Input,
  InputNumber,
  List,
  Menu,
  Progress,
  Radio,
  Row,
  Switch,
  Table,
  Tag,
  Typography,
  Upload,
} from 'antd';
import { TableProps } from 'antd/es/table';
import React from 'react';
import styled, { createGlobalStyle, css } from 'styled-components';
import { colorHexToRgba } from '../helpers';
import {
  CustomTabs as ApiTesterCustomTabs,
  CommonAlert,
  CustomDatePicker,
  CustomModal,
  CustomScrollCss,
  CustomSelect,
  FrappeGantt,
  IconSvg,
  ProgressBar,
  ScrollContainer,
  ScrollCss,
  SearchInput,
} from './Common';
import { CustomCollapse } from './Common/CustomCollapse';
import FileUpload from './Upload/FileUpload';

const { TextArea } = Input;
const { Text } = Typography;
const { Dragger } = Upload;

// ApiTester Styled Start
export const StyledTabWrapper = styled.div`
  width: -webkit-fill-available;
  width: 100%;
  background-color: ${({ theme }) => theme.color.pearl40};
`;

export const StyledNote = styled.div`
  && {
    margin: 0 auto;
    margin-bottom: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${({ theme }) => theme.color.dark};
    font-size: 16px;
    line-height: 20px;

    text-align: center;
    min-height: 40px;

    p {
      align-items: center;
      margin-bottom: 0px;
    }
  }
`;

export const StyledWrapper = styled.div<{ bodyHeight }>`
      
      .scrollshadow{
        width: 100%;
        height: 10px;
        z-index: 999;
        &.show {
          background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.05) 100%);
          mix-blend-mode: multiply;  
        }
      }
    
      &.bordered {
        border-top: 1px solid ${({ theme }) => theme.color.dark20} !important;
        border-bottom: 1px solid ${({ theme }) =>
          theme.color.dark20} !important;
          .padded-wrap {
      
            height: ${({ bodyHeight }) => bodyHeight || '372px'};
            min-height: ${({ bodyHeight }) => bodyHeight || '372px'};
            max-height: ${({ bodyHeight }) => bodyHeight || '372px'};
            overflow: auto;
            padding-right: 8px
            padding: 16px 18px 6px 18px;
            ${CustomScrollCss}
      
            .api-tester-expandicon {
              position: absolute;
              right: 10px;
              z-index: 99;
              margin-top: 5px;
              .anticon {
                color: ${({ theme }) => theme.color.white};
              }
            }
          }    
      
          .dark-scrollbar {
            background-color: ${({ theme }) => theme.color.dark};
            ::-webkit-scrollbar {
              background-color: ${({ theme }) => theme.color.dark};
        }    
      }
    `;

export const StyledJsonToggleContainer = styled.div`
  display: flex;
  height: 20px;
  justify-content: center;
  align-items: center;

  .switch-container {
    .ant-switch {
      background-color: ${({ theme }) => theme.color.gray2};
      border: none;
    }
    .ant-switch::after {
      background-color: ${({ theme }) => theme.color.white};
    }
    .ant-switch-checked {
      background-color: ${({ theme }) => theme.color.purple};
    }
  }

  .switch-label {
    font-size: 14px;
    margin-left: 10px;
    font-weight: 400;
  }

  .bold {
    font-weight: bold;
  }

  .select-chain-label {
    background-color: ${({ theme }) => colorHexToRgba(theme.color.purple, 0.3)};

    span {
      font-weight: bold;
      font-size: 16px;
    }
  }

  > span {
    font-weight: bold;
    margin-left: 5px;
  }

  > button,
  span {
    &.hide {
      display: none;
    }
  }
`;

export const StyledCustomTabs = styled(ApiTesterCustomTabs)`
      && {
        margin-top: 20px;
        height: 100%;
    
        .ant-tabs-nav-wrap {
          max-width: 100%;
          padding-right: 0;
          justify-content: center;
          .ant-tabs-nav-list {
            .ant-tabs-tab {
              padding: 0px 20px !important;
              border: 1px solid ${({ theme }) =>
                theme.color.purpleDarkened2} !important;
              min-width: 138px !important;
              height: 36px;
            }
            .ant-tabs-tab:nth-child(4) {
              border-top-right-radius: 4px !important ;
              border-bottom-right-radius: 4px !important;
            }
          }
        }
    
        .tab-pane-container {
          padding: 0 !important
          margin-top: 0px;
          display: flex;
          flex-direction: column;
          align-items: center;
          width: -webkit-fill-available;
          border-bottom: 0 !important;
    
          .apiTesterInputTableContainer {
            width: 100%;
          }
        }
        .scroll-container {
          overflow: hidden;
        }
        .react-json-view {
          border-bottom: none !important;
          max-width: 100%;
          overflow: auto;
          border-radius: 6px 6px 0px 0px;
        }
      }
    `;

export const StyledRawTextArea = styled(Input.TextArea)`
  && {
    max-height: auto;
    min-height: 340px;
    border-radius: 6px 6px 6px 6px;
    border: none;
    background: transparent;
    overflow: hidden;
    :hover,
    :focus {
      border: none !important;
      border-bottom: none !important;
    }
    ${CustomScrollCss}
  }
`;

export const StyledCustomCollapse = styled(CustomCollapse)`
  .ant-collapse-content > .ant-collapse-content-box {
    padding: 0;
  }

  .anticon.Download {
    color: ${({ theme }) => theme.color.purple};
  }
`;

export const StyledHeader = styled.th`
  padding: 20px;
  width: 50%;
  font-size: 16px;
  line-height: 20px;
`;

export const StyledTable = styled.table`
  width: 100%;
  text-align: left;
  margin-bottom: 40px;
  font-size: 14px;

  th:first-child {
    padding: 10px 5px;
    width: 40%;
  }

  th:nth-child(2) {
    padding: 10px 5px;
    width: 60%;
  }

  tr {
    color: ${({ theme }) => theme.color.dark};
    width: 100%;
  }

  tr:hover {
    background-color: none;
  }

  td {
    width: 30%;
    padding: 10px 5px;
    word-break: break-all;
  }

  td.positionRelative {
    display: flex;
    width: 100%;
  }

  td.inputLabel {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
  }

  div.ant-radio-group {
    width: 100%;
  }
`;

export const StyledContainer = styled.div`
  height: calc(100vh - 240px);
  overflow-y: auto;
  ${ScrollCss}
`;

export const StyledCollapse = styled(Collapse)<{
  numberOfTableCols: number;
  shouldRemoveScroll: boolean;
  hasScroll: boolean;
}>`
  && {
    .ant-collapse-item {
      margin-bottom: 5px;
      border: none;
      .ant-collapse-header {
        border: 1px solid ${({ theme }) => theme.color.gray2};
        border-radius: 4px;

        color: ${({ theme }) => theme.color.dark};
        font-size: 16px;
        line-height: 20px;
        font-weight: 700 !important;

        :hover,
        :active {
          background: ${({ theme }) => theme.color.purple20} !important;
        }
      }
      .ant-collapse-content {
        margin-bottom: 15px !important;
        border-bottom: none;
      }
    }
    .ant-collapse-item:last-child,
    .ant-collapse-item:last-child > .ant-collapse-header {
      border-radius: 4px !important;
    }
    .expandIcon {
      svg {
        color: ${({ theme }) => theme.color.purple};
      }
    }

    .ant-collapse-item-active {
      background: ${({ theme }) => theme.color.purple20} !important;
      .ant-collapse-header {
        background: ${({ theme }) => theme.color.purple20} !important;
        border: 1px solid ${({ theme }) => theme.color.purple} !important;
        border-radius: 6px 6px 0 0 !important;
      }
    }

    .ant-table {
      font-size: 14px;
      tr {
        height: 46px;
      }
      .ant-table-header {
        background: ${({ theme }) => theme.color.gray6} !important;
        border: none !important;
        margin-bottom: 0 !important;
        overflow: hidden !important;
        padding-bottom: 0 !important;
      }
      .ant-table-body {
        overflow: ${({ shouldRemoveScroll }) =>
          shouldRemoveScroll ? 'auto' : 'scroll'} !important;
        border-bottom: 1px solid ${({ theme }) => theme.color.gray6};
        border-right: ${({ theme, shouldRemoveScroll }) =>
          shouldRemoveScroll ? '0px' : `1px solid ${theme.color.gray6}`};
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
        box-shadow: ${({ hasScroll, numberOfTableCols }) =>
            hasScroll
              ? '-15px -15px 11px 5px'
              : numberOfTableCols > 6
              ? '-10px 0px 5px -3px'
              : '0px'}
          ${({ theme }) => theme.color.gray5} inset;

        ::-webkit-scrollbar-thumb {
          background: ${({ theme }) => theme.color.dark20};
          border-radius: 20px;
          border-right: 4px solid transparent;
          background-clip: content-box;
        }
        ::-webkit-scrollbar:horizontal {
          border-left: 1px solid ${({ theme }) => theme.color.gray6};
        }
      }
      .ant-table-thead > tr > th {
        border-bottom-color: ${({ theme }) => theme.color.gray6} !important;
      }

      .ant-table-thead {
        font-size: 14px;
        line-height: 16px;
        font-weight: bold;
      }
      .ant-table-thead > tr > th:first-child {
        border-left-width: 0px;
      }
      .ant-table-column-title {
        color: ${({ theme }) => theme.color.dark80};
      }

      .ant-table-tbody > tr:last-child > td {
        border-bottom: none;
      }
      .ant-table-tbody {
        font-size: 14px;
        line-height: 16px;

        tr:last-child > td {
          border-bottom: none;
        }
      }

      .ant-table-tbody > tr:first-child > td {
        border-top: 0px !important;
      }

      .ant-table-tbody > tr > td {
        border-bottom: 1px solid ${({ theme }) => theme.color.gray5} !important;
      }
    }
  }
`;

export const StyledPopover = createGlobalStyle<{ theme: STATES.ThemeState }>`
      .main-layout-popup {
        .ant-popover-inner-content {
          text-align: left !important;
          font-size: 12px !important;
          line-height: 16px !important;
          padding: 6px 0px !important;
          .popover-content {
            display: flex !important;
          }
    
          .anticon {
            margin-top: 2px;
            margin-right: 5px !important;
          }
        }
      }
    `;

export const ApiOutputCustomTabs = styled(ApiTesterCustomTabs)`
  && {
    margin-top: 20px;
    height: 100%;

    .ant-tabs-nav-wrap {
      max-width: 100%;
      padding-right: 0;
      justify-content: center;
      .ant-tabs-nav-list {
        .ant-tabs-tab {
          padding: 0px 20px !important;
          border-radius: 0px;
          min-width: 180px !important;
          height: 36px;
          border: 1px solid ${({ theme }) => theme.color.purpleDarkened2} !important;
        }

        .ant-tabs-tab:nth-child(3) {
          border-top-right-radius: 4px !important;
          border-bottom-right-radius: 4px !important;
        }
      }
    }
    .tab-pane-container {
      padding: 0 !important;
    }

    .tab-pane-common-view {
      display: flex;
      justify-content: center;
    }
    .scroll-container {
      overflow: hidden;
    }

    &.preview-tabs {
      margin: 0 auto;
      margin-top: 16px;
      .ant-tabs-tab:nth-child(2) {
        border-radius: 0px 4px 4px 0px !important;
      }
      .ant-tabs-tab-active {
        border: 1px solid #6b32b1 !important;
      }
      .Snippet-footer-btn {
        text-align: center;
      }
    }
  }
`;

export const StyledIconContainer = styled.div`
  && {
    width: 100%;
    height: 20px;
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    border-bottom: 0px;

    .anticon {
      color: ${({ theme }) => theme.color.purple};
      margin-right: 10px;
      cursor: pointer;
      svg {
        width: 13px;
        height: 13px;
      }
    }

    .tab-pane-common-view {
      display: flex;
      justify-content: center;
    }
    .scroll-container {
      overflow: hidden;
    }
  }
`;

export const StyledIcon = styled(IconSvg)`
  color: ${({ theme }) => theme.color.purple};
`;

export const DisabledStyledIcon = styled(IconSvg)`
  color: ${({ theme }) => theme.color.dark20} !important;
`;

export const StyledCustomSelect = styled(CustomSelect)`
  width: 100%;
  max-width: 500px;
  && {
    .CaretDownFilled {
      color: ${({ theme }) => theme.color.purple} !important;
    }
  }
`;

export const StyledApiVersionSelectorContainer = styled.div`
  .ant-form-item {
    margin-top: 16px;
    margin-bottom: 16px;

    .ant-form-item-label {
      min-height: 36px;
      > label {
        font-weight: 400;
        ::after {
          margin: 0 6px 0 2px;
        }
      }
    }
  }
`;

export const StyledQuestionCircleOutlined = styled.span`
  color: ${({ theme }) => theme.color.gray2};
  margin: 0 5px;
  cursor: pointer;
`;

export const StyledTextArea = styled(TextArea)<{ error: boolean }>`
  && {
    border: none;
    font-family: SFMono-Regular, Consolas, Liberation Mono, Menlo, Courier,
      monospace;
    font-size: 1em;
    min-height: ${({ error }) => (error ? '226px' : '372px')};
    color: ${({ theme }) => theme.color.white};
    resize: none;
    box-shadow: none !important;
    background-color: ${({ theme }) => theme.color.dark};

    :hover,
    :focus {
      border: none !important;
    }

    ::-webkit-scrollbar {
      background-color: ${({ theme }) => theme.color.dark};
      height: 7px;
      width: 7px;
    }
    ::-webkit-scrollbar-thumb {
      background: ${({ theme }) => theme.vars.dark};
      border-radius: 20px;
      background-clip: content-box;
    }
  }
`;

export const ErrorMsgContainer = styled.div`
  && {
    padding: 0px 16px;
    width: 100%;
    margin-top: 16px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    .ant-alert {
      padding: 12px 16px 12px 16px !important;
    }
  }
`;

export const StyledIconErrorMsgContainer = styled.div`
  && {
    svg {
      width: 30px;
      height: 28px;
      color: ${({ theme }) => theme.color.red};
    }
  }
`;

export const StyledMessage = styled.div`
  && {
    font-size: 14px;
    color: ${({ theme }) => theme.color.dark};
    margin-left: 10px;
  }
`;

export const StyledLabel = styled.label`
  font-size: 14px;
`;

export const StyledSwitch = styled(Switch)`
  margin-left: 12px !important;

  &&.ant-switch {
    background-color: ${({ theme }) => theme.color.gray2};
    border: none;
  }
  &&.ant-switch-checked {
    background-color: ${({ theme }) => theme.color.purple};
  }
`;

export const StyledApiDetailsFormContainer = styled.div``;

export const StyledFlexDiv = styled.div`
  display: flex;
  align-items: center;
  &.flx-end {
    justify-content: flex-end;
  }
`;

export const StyledInput = styled(Input)<{
  longInput?: boolean;
  marginRight?: string;
  disabled?: boolean;
  error?: boolean;
}>`
  && {
    .ant-input-affix-wrapper {
      .ant-input {
        color: ${({ theme }) => theme.color.dark};
      }
      &.ant-input-affix-wrapper-disabled {
        background: ${({ theme }) => theme.color.white};
      }
    }
  }
`;

export const StyledMultiSelect = styled(CustomSelect)`
  &&.ant-select {
    .ant-select-item-option-selected span {
      vertical-align: middle !important;
    }

    .ant-select-selection-item-content .ant-checkbox-wrapper {
      display: none;
    }
  }
`;

export const BrowserStyle = createGlobalStyle<{ theme: STATES.ThemeState }>`
      ::-webkit-scrollbar {      
          overflow: hidden;
          } 
    `;

export const StyledCodeContainer = styled.div`
  && {
    max-height: calc(100vh - 280px);
    min-height: 250px;
    background-color: ${({ theme }) => theme.color.dark};
    overflow: auto;
    ::-webkit-scrollbar-corner {
      background-color: ${({ theme }) => theme.color.dark};
    }
    ::-webkit-scrollbar {
      background-color: ${({ theme }) => theme.color.dark};
      width: 8px;
      height: 8px;
    }
    ::-webkit-scrollbar-thumb {
      background: ${({ theme }) => theme.color.dark20};
      border-radius: 20px;
      background-clip: content-box;
    }
    .expanded-icon,
    .collapsed-icon,
    .copy-icon {
      svg {
        fill: ${({ theme }) => theme.color.purple};
      }
    }
  }
`;

export const StyledRangeValueCSVCustomModal = styled(CustomModal)`
  .ant-modal-header {
    padding: 32px 0 0 0;
  }
  textarea {
    max-height: calc(100vh - 260px) !important;
    overflow: auto;
    ${CustomScrollCss}
  }
`;
export const StyledIconSvg = styled(IconSvg)`
  && {
    color: ${({ theme }) => theme.color.purple};
  }
`;

export const StyledApiTesterOutputContainer = styled.div`
  && {
    .ant-collapse,
    .ant-table {
      font-size: 14px;
    }
  }
  &.apiTesterOutputFormattedTable {
    .ant-collapse-item-disabled {
      background: #f7f7f7;
      .ant-collapse-header {
        border: 1px solid #d2d1d8 !important;
        &:hover {
          background: transparent !important;
        }
      }
      .ant-collapse-header,
      .ant-collapse-expand-icon svg,
      .ant-collapse-extra span,
      .ant-collapse-header-text {
        color: ${({ theme }) => theme.color.dark20} !important;
        pointer-events: none;
      }
    }
  }
`;

export const StyledCollapseTable = styled(Collapse)`
  && {
    .ant-collapse-item {
      margin-bottom: 5px;
      border: none;
      .ant-collapse-header {
        border: 1px solid ${({ theme }) => theme.color.gray2};
        color: ${({ theme }) => theme.color.dark};
        border-radius: 4px;
        font-size: 16px;
        line-height: 20px;
        font-weight: bold !important;
        word-break: break-all;
        :hover {
          background: ${({ theme }) => theme.color.purple20};
        }
      }
      .ant-collapse-content {
        margin-bottom: 5px;
        border-bottom: none;
      }
      .ant-table {
        border: 1px solid ${({ theme }) => theme.color.purple};
        margin-top: -3px;
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
        .ant-table-tbody > tr {
          height: 46px;
          > td {
            border-left: 0;
            border-radius: 0;
          }
        }
      }
    }
    .ant-collapse-item:last-child,
    .ant-collapse-item:last-child > .ant-collapse-header {
      border-radius: 4px !important;
    }
    .expandIcon {
      svg {
        color: ${({ theme }) => theme.color.purple};
      }
    }

    .ant-collapse-item-active {
      background: ${({ theme }) => theme.color.purple20} !important;
      .ant-collapse-header {
        background: ${({ theme }) => theme.color.purple20} !important;
        border: 1px solid ${({ theme }) => theme.color.purple};
      }
    }
  }
`;

export const TabBarExtractContentContainer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  width: 100%;
`;

export const StyledApiTesterExpandModal = styled(CustomModal)`
  .scroll-container {
    overflow: hidden !important;
    padding: 0px;
  }

  .ant-modal-content {
    background: ${({ theme }) => theme.color.pearl40} !important;
    overflow: auto;
    padding: 0px !important;

    .ant-modal-body {
      .content-container {
        padding: 0;
      }
    }

    ::-webkit-scrollbar {
      background-color: transparent;
    }
    ::-webkit-scrollbar-thumb {
      background: ${({ theme }) => theme.color.dark20};
      border-radius: 20px;
      border: 4px solid transparent;
      background-clip: content-box;
    }
  }
  .modaltable .ant-table {
    border: 1px solid ${({ theme }) => theme.color.purple};
    margin-top: -3px;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    .ant-table-tbody > tr {
      height: 46px;
      > td {
        border-left: 0;
        border-radius: 0;
      }
    }
  }
`;

export const APITesterRadioButtonControl = styled(Radio.Group)`
  .ant-radio-wrapper {
    font-size: 16px;
    min-width: 80px;
    font-weight: normal;
  }
`;

export const APITesterTextAreaControl = styled(Input.TextArea)`
  padding-top: 11px !important;
  padding-left: 20px !important;
  resize: none;
`;

export const MutlipleCellsInputButton = styled(Button)`
  && {
    width: 100%;
    text-align: left;
    font-weight: normal;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: none;
    border: 1px solid ${({ theme }) => theme.color.gray2};
    background-color: ${({ theme }) => theme.color.white};
    height: 40px;
    font-size: 16px;
    border-radius: 4px !important;
    color: ${({ theme }) => theme.color.dark};
    :hover,
    :active,
    :focus {
      border-color: ${({ theme }) => theme.color.purple};
      color: ${({ theme }) => theme.color.purple};
    }
    .anticon {
      color: ${({ theme }) => theme.color.purple};
    }
  }
`;

export const StyledInvalidJSONText = styled(Text)`
  color: ${({ theme }) => theme.color.red};
`;

export const ApiTesterExpandModalBody = styled(ScrollContainer)`
  .ant-table-thead > tr > th {
    border-top-width: 0 !important;
    border-bottom-color: ${({ theme }) =>
      colorHexToRgba(theme.color.dark, 0.3)} !important;
  }

  .ant-table-thead > tr > th:first-child {
    border-left-width: 0 !important;
  }

  .ant-table-tbody:before,
  .ant-table-tbody:after {
    content: none !important;
  }

  .ant-table-tbody > tr > td {
    border-bottom-color: ${({ theme }) =>
      colorHexToRgba(theme.color.dark, 0.3)} !important;
    border-left: none !important;
  }

  .ant-table-tbody > tr:first-child > td {
    border-top: 1px solid
      ${({ theme }) => colorHexToRgba(theme.color.dark, 0.3)}!important;
  }

  .ant-table-tbody > tr:last-child > td {
    border-bottom-width: 0;
  }
`;
// ApiTester Styled End

// BackgroundJobs Styled Start
export const StyledJobItemContainer = styled.div`
  font-size: 12px;
  font-size: 16px;
  line-height: 20px;
  padding: 16px 0px;
  border-bottom: 1px solid ${({ theme }) => theme.color.gray5};
  :hover,
  :active {
    background-color: ${({ theme }) => theme.color.pearl40};
  }
  div.heading {
    margin: 0px 32px 4px 24px;
    div.ant-typography,
    .ant-typography p {
      margin-bottom: 0;
      color: ${({ theme }) => theme.color.dark} !important;
      overflow-wrap: break-word;
      word-break: break-all;
      span {
        word-break: unset !important;
      }
    }
  }
  div.description {
    .icon-success {
      color: ${({ theme }) => theme.color.success};
      margin-right: 8px;
      margin-top: 2px;
    }
    .icon-pending {
      color: ${({ theme }) => theme.color.purple};
      margin-right: 8px;
      margin-top: 2px;
    }
    .icon-failed {
      color: ${({ theme }) => theme.color.failed};
      margin-right: 8px;
      margin-top: 2px;
    }
    .icon-cancelled {
      color: ${({ theme }) => theme.color.dark60};
      margin-right: 8px;
      margin-top: 2px;
    }
    display: flex;
    flex-direction: row;
    font-size: 14px;
    line-height: 16px;
    margin: 4px 32px 0px 24px;
    color: ${({ theme }) => theme.color.dark80};
    span {
      line-height: 18px;
      word-break: unset;
      overflow-wrap: break-word;
    }
  }

  .title-link {
    &,
    &:hover,
    &:active,
    &:focus {
      text-decoration: underline;
      color: ${({ theme }) =>
        colorHexToRgba(theme.color.gray1, 0.6)} !important;

      .anticon {
        font-size: 14px;
        margin-left: 10px;
      }
    }
  }

  :last-child {
    border-bottom: none;
  }

  .progress-container {
    margin: 0px 32px 0px 24px;
    display: flex;
    align-items: center;

    .icon-container {
      width: 14px;
      height: 20px;
      margin-left: 28px;
      line-height: 21px;
      text-align: right;
      cursor: hand;

      .anticon {
        font-size: 16px;
        color: ${({ theme }) => theme.color.dark60};
        :hover {
          color: ${({ theme }) => theme.color.dark80};
        }
      }
    }

    .ant-progress {
      width: auto;
      flex: 1;

      .ant-progress-text {
        visibility: collapse;
      }
    }
    .ant-progress-inner {
      background-color: ${({ theme }) => theme.color.gray10} !important;
    }
    .ant-progress-bg {
      height: 6px !important;
      border-radius: 22px !important;
    }

    .ant-progress-status-normal .ant-progress-bg {
      background-color: ${({ theme }) => theme.color.purple60} !important;
    }

    .ant-progress-status-success .ant-progress-bg {
      background-color: ${({ theme }) => theme.color.success} !important;
    }

    .ant-progress-status-exception .ant-progress-bg {
      background-color: ${({ theme }) => theme.color.failed} !important;
    }

    .ant-progress-status-active .ant-progress-bg {
      background-color: ${({ theme }) => theme.color.dark60} !important;

      :before {
        animation: unset;
      }
    }
  }
`;
// BackgroundJobs Styled End

// Bookmark Styled Start
export const StyledBookmarkContainer = styled.div`
  margin-right: 26px;
  display: flex;
  align-items: center;
  height: var(--tab-height);

  && .boorkmark-link {
    .anticon.anticon-check-circle {
      color: ${({ theme }) => theme.color.success};
    }

    :active,
    :hover,
    &.ant-dropdown-open {
      background-color: ${({ theme }) => theme.color.purple20} !important;
      border-radius: 50px;
      color: ${({ theme }) => theme.color.purpleDarkened3};
      .anticon.anticon-check-circle {
        color: ${({ theme }) => theme.color.purple} !important;
      }
    }
  }
`;

export const StyledBookmarkMenu = styled(Menu)`
  && .bookmark-menu {
    color: ${({ theme }) => theme.color.dark} !important;
    :hover {
      color: ${({ theme }) => theme.color.white} !important;
    }
  }
`;

export const GlobalWrapper = createGlobalStyle<{
  theme: STATES.ThemeState;
  styledNotification: boolean;
}>``;

export const StyledBookmarkMessage = styled.div<{
  theme: STATES.ThemeState;
  styledNotification: boolean;
}>`
  .styled_notification {
    width: 400px;
  }
  .bookmark-alert-text-wrap {
    .button-wrap {
      display: flex;
      justify-content: flex-end;
      .ant-btn {
        font-weight: bold;
        min-width: fit-content;
        color: ${({ theme }) => theme.color.dark80};
        :focus,
        :hover,
        :active {
          color: ${({ theme }) => theme.color.dark80};
        }
        span {
          padding: 0;
        }
      }
    }
    span.text {
      text-align: left;
      padding: 8px 0 0 0 !important;
      margin: 0;
    }
  }
`;

export const StyledDeleteBookmark = styled.div`
  width: 100%;
  text-align: center;
  .title {
    font-size: 24px;
    line-height: 27px;
  }
  .value {
    margin-left: 8px;
    font-weight: bold;
  }
`;

// Bookmark Styled End

// CategoryManager Styled start

export const StyledAddNewCategory = styled.div`
  .title {
    font-size: 22px;
    font-weight: 600;
    color: ${({ theme }) => theme.color.dark};
    margin: 0px 0px 20px 0px;
  }
`;

export const StyledAddCategoryForm = styled(Form)`
  .ant-form-item-label {
    padding: 0 !important;
    display: flex !important;
    .ant-form-item-required::before {
      display: none;
    }
  }
`;

export const StyledListItem = styled(props => <List.Item {...props} />)`
  margin-bottom: 5px;
  border-radius: 4px;
  padding: 10px 20px !important;
  border: 1px solid #e8e8e8 !important;
  .ant-list-item-meta-content {
    margin-top: 6px;
    .ant-list-item-meta-title {
      color: ${({ theme }) => colorHexToRgba(theme.color.dark, 0.75)};
      word-break: break-all;
    }
  }
  .ant-list-item-action-split {
    display: none !important;
  }
  .ant-list-item-action li:last-child {
    padding-right: 0 !important;
  }
`;

export const StyledCategory = styled.button`
  height: 52px;
  background: ${({ theme }) => theme.color.white};
  padding: 10px !important;
  margin: 5px !important;
  border: 1px solid #888 !important;
  vertical-align: middle;
  text-align: center;

  :hover {
    background: ${({ theme }) => theme.color.purpleDarkened2};
    svg {
      color: ${({ theme }) => theme.color.white} !important;
    }
  }
`;

export const StyledCustomDropdown = styled.div`
  height: 250px;
  display: flex;
  flex-wrap: wrap;
  overflow: auto;

  ${CustomScrollCss}
`;

// CategoryManager Styled End

// Upload Styled Start
export const StyledDragger = styled(Dragger)<{
  dragEnter?: boolean;
  width: number;
}>`
  &.ant-upload-drag {
    display: flex;
    flex-direction: column;
    justify-content: center;

    background-color: ${({ theme }) => theme.color.white} !important;

    border-radius: 10px;
    border-color: ${({ theme, dragEnter }) =>
      dragEnter ? theme.color.purple : theme.color.lightPurple};
    border-width: ${({ dragEnter }) => (dragEnter ? '2px' : '1px')} !important;
    border-style: ${({ dragEnter }) =>
      dragEnter ? 'solid' : 'dashed'} !important;
    width: ${({ width }) => `${width}px`};
    height: 200px;
    transition: all 0.3s ease-in-out;
    &:hover {
      background-color: ${({ theme }) =>
        colorHexToRgba(theme.color.purple, 0.2)} !important;
      border: 2px solid ${({ theme }) => theme.color.purple} !important;
    }

    &:focus {
      background: black !important;
    }
  }
`;

export const StyledCard = styled(Card)<{
  error?: string | null;
  executeProgress?: number | null;
  uploadProgress?: number | null;
  showInvalidFileNameError: boolean;
  showUploadFileSizeError: boolean;
}>`
  && {
    flex: 1;
    background-color: transparent;
    border: none !important;
    height: fit-content !important;
    border: none !important;
    border-radius: 5px;
    margin-bottom: 16px;

    h1 {
      text-align: center;
      margin-bottom: 0;
    }

    h4 {
      text-align: center;
      margin-bottom: 15px;
    }
    .ant-card-body {
      display: flex;
      flex-direction: column;
      .ant-upload.ant-upload-drag {
        width: 400px;
        height: 200px;
        margin-left: auto;
        margin-right: auto;
        border-radius: 10px;
        word-break: break-all;

        .ant-upload.ant-upload-btn {
          padding: 10px;
          .ant-upload-drag-container {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            pointer-events: none;
            width: 100%;
            height: 100%;
            .uploadLoadingContainer {
              text-align: center;
              .ant-progress {
                padding-left: 0 !important;
              }
              .ant-progress-inner {
                width: 296px !important;
              }
              .progress-bar:first-child {
                margin-top: ${({ error }) => (error ? '1rem' : '0')};
              }
              .progress-bar:first-child {
                margin-top: ${({ error, executeProgress, uploadProgress }) =>
                  error && !!executeProgress && !!uploadProgress
                    ? '.5rem'
                    : '0'};
              }

              .progress-bar:nth-child(2) {
                margin-top: ${({ error }) => (error ? '.5rem' : '1rem')};
              }
            }
            .upload-error {
              .ant-typography {
                color: ${({ theme }) => theme.color.red} !important;
                display: ${({
                  showInvalidFileNameError,
                  showUploadFileSizeError,
                  executeProgress,
                  uploadProgress,
                }) =>
                  showInvalidFileNameError ||
                  showUploadFileSizeError ||
                  (!!executeProgress && !!uploadProgress)
                    ? 'inline-block'
                    : 'none'};
              }
            }

            .progress-bar {
              flex-direction: column;
            }
          }
        }
      }
      .upload-cancel.ant-btn-link {
        text-decoration: underline;
        color: ${({ theme }) => theme.color.black} !important;
        transform: translateY(-38px);
      }
    }
  }
`;

export const FileUploadDefaultContentContainer = styled.div`
  .ant-typography {
    color: ${({ theme }) => theme.color.dark} !important;

    &.desc {
      font-weight: normal;
      font-size: 12px;
      margin-bottom: 8px;
    }
  }

  .anticon-upload > svg {
    color: ${({ theme }) => theme.color.gray1} !important;
  }
  .ant-btn.has-error-top-space {
    margin-top: 30px !important;
  }
`;
export const FileUploadErrorContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 90%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  .anticon-warning {
    margin-bottom: 0;
    flex: 0 !important;
    svg {
      width: 28px;
      height: 24.5px;
      fill: ${({ theme }) => theme.color.red};
    }
  }
  .upload-error .ant-typography {
    font-size: 14px;
    margin-top: 0;
  }
`;

export const FileUploadSuccessContainer = styled.div`
  text-align: center;
  margin-bottom: 10px;

  .anticon {
    color: ${({ theme }) => theme.color.purple};
    margin-bottom: 0;
    font-size: 90px;
  }

  .anticon {
    margin-bottom: 1rem;
  }

  span.ant-typography {
    color: ${({ theme }) => theme.color.gray1};
  }
`;
// Upload Styled End

// TestCaseGeneration Styled Start
export const CheckboxGroupContainer = styled(Checkbox.Group)`
  width: 100%;
`;

export const StyledCheckbox = styled(Checkbox)`
  &.ant-checkbox-wrapper {
    padding: 6px 12px;
    min-width: 120px;
    min-height: 36px;
  }
`;

export const PrefixLabel = styled.div<{ showError?: boolean }>`
  padding: 0 10px 0 10px;
  background-color: ${({ theme }) => theme.color.gray6};
  border-radius: 4px 0px 0px 4px;
  border: 1px solid
    ${({ theme, showError }) =>
      showError ? theme.color.failed : theme.color.gray4};
  border-right-width: 0;
  height: 30px;
  min-width: 81px;
  display: flex;
  align-items: center;
`;

export const InputContainer = styled.div`
  flex: 1;
  height: 30px;

  .ant-input {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
`;

export const StyledInputGroup = styled(Input.Group)`
  margin-top: 5px !important;
`;

export const Wrapper = styled(Col)`
  padding: 8px 0px 24px 0px;
  border-bottom: 1px solid ${({ theme }) => theme.color.dark20};
  &&:last-of-type {
    border-bottom: none;
  }

  .ant-form label {
    font-size: 16px !important;
  }

  .testgenerationerrormsg {
    color: #d31e47;
    background-image: url(assets/img/warning.svg);
    background-repeat: no-repeat;
    padding-left: 20px;
    background-position-y: 3px;
    font-size: 14px;
    position: absolute;
    bottom: -22px;
    margin-left: 5px;
  }
`;

export const TestCaseLabel = styled.label<{
  topSpace?: boolean;
}>`
  word-break: break-all;
  color: ${({ theme }) => theme.color.dark};
  font-size: 16px !important;
  margin-bottom: 0px;
  display: block;
  font-weight: bold;
  margin-bottom: 12px;

  ${({ topSpace }) =>
    topSpace &&
    `
          margin-top: 20px;
          display: block;
          `}
`;

export const StyledFormItem = styled(Form.Item)<{ hideMessage?: boolean }>`
  .ant-form-item-control-input {
    min-height: 30px !important;
  }
  .ant-form-item-explain-error {
    position: absolute;
    width: 500px;
    margin-left: -80px;
    display: ${({ hideMessage }) => (hideMessage ? 'none' : 'block')};
  }
`;

export const TestCaseTextField = styled(Input)<{
  type?: 'number' | 'input' | null;
}>`
  && {
    height: 30px;
  }
`;

export const TestCaseTextAreaField = styled(Input.TextArea)`
  && {
    min-height: 100px;
    margin-bottom: 20px;
  }
`;
// TestCaseGeneration Styled End

// TemplateManager Styled Start
export const StyledTemplateNotFound = styled.div`
  color: ${({ theme }) => colorHexToRgba(theme.color.dark, 0.6)};
  margin-bottom: 16px;
  .secondary {
    color: ${({ theme }) => theme.color.dark80};
  }
`;
export const TemplateWrapper = styled.div`
  .ant-row:first-child {
    padding-top: 0 !important;
  }
`;
export const StyledTemplateListRow = styled(Row)`
  h4.ant-typography {
    margin: 0 !important;
  }

  margin: 15px 0px !important;

  .ant-col-4 {
    flex: none !important;
    max-width: 20% !important;
    width: 20% !important;
  }
`;
export const TemplateCardStarIcon = styled(props => <Text {...props} />)`
  bottom: 5px;
  right: 10px;
  font-size: 20px;
  position: absolute;

  i {
    color: ${({ theme }) => theme.color.gray2} !important;
  }
`;
export const TemplateCardStyledTag = styled(props => <Tag {...props} />)`
  padding: 4px 8px !important;
  background: ${({ theme }) => theme.color.gray10} !important;
  border: 1px solid ${({ theme }) => theme.color.dark20} !important;
  border-radius: 2px !important;
  font-size: 13px !important;
  color: ${({ theme }) => theme.color.dark80} !important;
  margin-bottom: 8px !important;
  line-height: 15px !important;
`;
export const StyledTemplateCard = styled(props => <Card {...props} />)`
  text-align: center;
  width: auto;
  height: 274px;
  .ant-card-body {
    padding: 10px !important;
  }

  border-radius: 8px !important;
  border: 1px solid ${({ theme }) => theme.color.gray2} !important;
  .header-container {
    padding-bottom: 16px;
  }
  .ant-card-head {
    padding: 0;
    border-bottom: none;
  }
  .card-head-title {
    padding-top: 0;
    display: inline-block;
    color: ${({ theme }) => theme.color.dark};
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    word-break: break-all;
  }
  .anticon-file-excel {
    color: ${({ theme }) => theme.color.dark};
    font-size: 24px;
  }
  .card-content {
    font-size: 13px;
    font-weight: 400;
    color: ${({ theme }) => theme.color.dark80} !important;
    margin-bottom: 13px;
  }
`;
// TemplateManager Styled End

// SetPermission Styled Start
export const StyledSelect = styled(props => <CustomSelect {...props} />)``;

export const EditPermissionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px;

  .ant-typography {
    font-size: 28px;
    font-weight: normal;
  }

  .ant-form-explain {
    position: absolute;
  }

  .ant-btn {
    min-width: 150px;
    margin-top: 20px;
  }

  .ant-form {
    margin-top: 30px;
    width: 100%;
    text-align: center;
  }
`;

export const SetPermissionFieldLabel = styled.div`
  && {
    font-size: 14px;
    width: 450px;
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    text-align: left;
  }
`;

export const SetPermissionHeading = styled.div`
  && {
    margin-top: 12px;
    font-size: 24px;
  }
`;

export const SetPermissionButtonContainer = styled.div`
  padding: 24px 0px 0px 0px;
`;

export const SetPermissionSpan = styled.span`
  color: ${({ theme }) => theme.color.gray2};
`;

export const StyledSetPermissionsTooltip = styled.div`
  display: flex;
  max-width: 300px;
  .content {
    padding: 0 4px;
  }
`;

export const SetPermissionButton = styled(Button)`
  && {
    &.ant-btn.ant-btn {
      padding-left: 8px;
      padding-right: 8px;
    }
    border: none;
    height: 36px;
    padding: 0;
    margin: 0;
    border-radius: 0;
    background: transparent;
    color: ${({ theme }) => theme.color.purple};
    outline: none;
    min-width: fit-content !important;

    &:hover,
    &:active,
    &:focus {
      background-color: transparent;
      color: ${({ theme }) => theme.color.purple};
    }

    .anticon {
      font-size: 19px;
    }
    &.ant-btn[disabled] {
      background-color: transparent;
    }
  }
`;

export const SetPermissionListItem = styled.div`
  height: 315px;
  overflow-y: auto;
  background: ${({ theme }) => theme.color.white};
  border: 1px solid ${({ theme }) => theme.color.gray2};
  border-radius: 4px;
  .ant-list-bordered {
    height: 100%;
  }
`;

export const SetPermissionFieldLabelIcon = styled.div`
  margin-left: 5px;
  .anticon {
    font-size: 16px;
    color: ${({ theme }) => theme.color.purple};
  }
`;

export const SetPermissionForm = styled(Form)`
  .ant-row.ant-form-item {
    .has-error {
      .ant-form-explain {
        margin-top: 5px;
      }
    }
  }
  .ant-row {
    .ant-form-item-control-input {
      .ant-form-item-control-input-content {
        display: flex !important;
        align-items: center !important;
      }
    }
  }
  .addmember-tag {
    width: 100%;
  }
  .member-tag {
    display: flex;
    align-items: center;
  }
`;

export const SetPermissionRow = styled(Row)`
  padding-bottom: 10px;
  width: 100%;
`;

export const StyledStatusColumn = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledActiveIcon = styled.div`
  height: 8px;
  width: 8px;
  border-radius: 50%;
  margin-right: 4px;
  background-color: ${({ theme }) => theme.color.success};
`;

// Routes Styled Start
export const BreadcrumbContainer = styled.div`
  && {
    display: flex;
    padding-top: 5px;
    padding-bottom: 5px;
    align-items: center;
  }
`;
export const StyledBreadcrumb = styled(Breadcrumb).attrs({
  'data-testid': 'breadcrumbs-container',
})`
  && {
    color: ${({ theme }) => theme.color.dark80};

    a {
      color: ${({ theme }) => theme.color.dark80};
      &:hover {
        color: ${({ theme }) => theme.color.purple};
        text-decoration: underline;
      }
    }

    > span:last-child {
      color: ${({ theme }) => theme.color.dark};
      a,
      a:hover {
        color: ${({ theme }) => theme.color.dark};
        text-decoration: none;
        cursor: default;
      }
    }

    .ant-breadcrumb-separator {
      color: ${({ theme }) => theme.color.dark80};
    }

    .cursor-pointer {
      cursor: pointer;
    }
  }
`;

// Routes Styled End

// ProductDocument Styled Start
export const DeleteModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const DeleteModalRadioWrap = styled.div<{
  checked?: boolean;
  hasError?: boolean;
}>`
  padding: 8px;
  width: 320px;
  margin-bottom: 10px;
  border: 1px solid
    ${({ theme, checked, hasError }) => {
      if (checked) {
        return hasError ? theme.color.failed : theme.color.purple;
      }
      return 'transparent';
    }};
  background: ${({ theme, checked, hasError }) => {
    if (checked) {
      return hasError ? theme.color.attention20 : theme.color.purple20;
    }
    return 'transparent';
  }};
  border-radius: 4px;

  .dropdown-select-wrap {
    margin-top: 8px;

    .CaretDownFilled {
      color: ${({ theme, hasError }) =>
        hasError ? theme.color.failed : theme.color.purple};
    }
  }

  .error-msg {
    color: ${({ theme }) => theme.color.failed};
    margin: 0;
  }

  .ant-select-selection {
    border-color: ${({ theme, hasError }) =>
      hasError ? theme.color.failed : theme.color.purple} !important;
  }
`;

export const DeleteModalCustomSelect = styled(CustomSelect)`
  width: 304px;
`;
// ProductDocument Styled End

// ProductCatalogue Styled Start
export const ProductRowSection = styled(Row)<{
  viewtype?: string;
  showmorebutton?: boolean;
}>`
  padding: ${({ viewtype }) =>
    `${viewtype === 'grid' ? '10px 8px 10px 10px' : '0px 8px 10px 10px'}`};
  height: ${({ viewtype, showmorebutton }) =>
    `${
      viewtype === 'grid' && showmorebutton
        ? 'calc(100vh - 190px)'
        : 'calc(100vh - 143px)'
    }`};
  margin-left: 8px !important;
  margin-right: 0px !important;
  overflow-y: auto;
  align-content: flex-start;

  ${ScrollCss}
  .square-card,
  .add-button-card {
    margin-bottom: 20px;
  }

  .add_product_btn {
    width: 100%;
  }
`;

export const ProductColWrapper = styled(Col)`
  @media (min-width: 576px) {
    width: 50%;
  }

  @media (min-width: 768px) {
    width: 33.33%;
  }

  @media (min-width: 992px) {
    width: 25%;
  }
`;

export const StyledProductNoFolderWrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  vertical-align: middle;
  align-items: center;
  justify-content: center;
  height: 250px;

  > p {
    margin-bottom: 32px;
  }

  svg {
    color: ${({ theme }) => theme.color.purple};
  }

  .icon-empty {
    font-size: 70px;

    svg {
      color: ${({ theme }) => theme.color.white};
    }
  }
`;

export const StyledCardRow = styled(Row)`
  margin: 0 !important;
  column-gap: 8px;
`;

export const StyledName = styled.div`
  cursor: grabbing;
`;

export const RowContainer = styled.div`
  align-items: flex-start;
  justify-content: space-between;
  height: 143px;
  border: 1px solid ${({ theme }) => theme.color.dark20};
  border-radius: 3px;
  background: ${({ theme }) => theme.color.gray10};
`;

export const DocsNamesContainer = styled.div`
  overflow: hidden;
  text-align: center;
  background: transparent;
`;

export const DocsStyledName = styled.div`
  margin: 0;
  font-size: 12px;
  overflow: hidden;
  -webkit-box-orient: vertical;
  display: block;
  display: -webkit-box !important;
  overflow: hidden !important;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  word-wrap: break-word;
`;

export const DocsMenuContainer = styled.div`
  float: right;
  position: relative;
  z-index: 4;
`;

export const StyledWrap = styled.div`
  cursor: grab;
  margin-bottom: 16px;
`;

export const UploadSection = styled.div`
  position: relative;

  .doc-file-upload {
    .ant-upload.ant-upload-drag .ant-upload-btn {
      display: block !important;
    }
  }

  .ant-upload-drag {
    width: 100% !important;
  }

  .header {
    padding: 32px 0 0;
    .title {
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 27px;
      color: ${props => props.theme.color.dark};
    }
  }

  .filter-icon {
    color: ${({ theme }) => theme.color.purple} !important;
  }

  .add-message {
  }
  .ant-row-flex.ant-row-flex-center {
    .ant-col.ant-col-20 {
      width: 90% !important;
      height: 240px !important;
      .contentContainer {
        .ant-card-body {
          padding-left: 0;
          padding-right: 0;
          height: 240px !important;

          .ant-upload.ant-upload-drag {
            width: -webkit-fill-available;
          }
        }
      }
    }
  }
  .selectDocument {
    width: 100%;
    display: flex;
    justify-content: start;
    align-items: center;
    flex-direction: column;
    font-style: normal;
    font-size: 16px;
    line-height: 20px;

    color: ${({ theme }) => theme.color.dark};
    margin-bottom: 16px;
    .error {
      margin-bottom: 8px;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 20px;

      display: flex;
      align-items: center;
      text-align: center;

      color: ${({ theme }) => theme.color.failed};
      p {
        padding: 0px;
        margin: 0px;
        margin-left: 8px;
      }

      svg {
        color: ${({ theme }) => theme.color.failed};
      }
    }
    p {
      font-weight: bold;
      margin: 0;
      padding: 0;
    }
    p {
      margin-bottom: 8px;
    }
  }
  .footer {
    text-align: center;
    box-shadow: 0px 0px 0px rgb(0 0 0 / 100%) !important;
    padding: 16px 0 0;
    .ant-btn {
      margin: 0 5px;
    }
  }
`;

export const CheckBox = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
`;

export const MainLabel = styled.div<{ disabled?: boolean }>`
  margin-bottom: 8px;
  color: ${({ theme, disabled }) => (disabled ? theme.color.pearl40 : '')};
`;

export const Label = styled.span`
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 23px;
  letter-spacing: 0px;
  text-align: left;
  color: ${({ theme }) => theme.color.dark};
`;

export const SyncSection = styled.div`
  position: relative;

  .header {
    .ant-btn.close.ant-btn-link {
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  .contentContainer {
    padding: 20px 0 40px;

    .fileUploadContainer {
      margin: 0 auto 0px;

      .ant-card {
        border: none;
        background: transparent;

        .ant-card-head {
          padding: 0;

          .ant-card-head-wrapper {
            .ant-card-head-title {
              font-size: 14px;
              padding-bottom: 0px;
            }
          }
        }
        .ant-card-body {
          padding: 0;
          border: 1px dashed ${({ theme }) => theme.color.dark60};
          background-color: ${({ theme }) => theme.color.white};
          padding: 20px;
          border-radius: 6px;

          .ant-upload.ant-upload.ant-upload-drag {
            border: none;
            background: none;
            min-height: 300px;
            padding-bottom: 60px;
            z-index: 1;
          }

          .uploadLoadingContainer {
            position: absolute;
            bottom: 20px;
            left: 20px;
            right: 20px;
          }
        }
      }
    }
  }
  .footer {
    text-align: center;

    .ant-btn {
      margin: 0 5px;
      height: 36px;
      &.ant-btn-primary {
        color: ${({ theme }) => theme.color.white};
        background-color: ${({ theme }) => theme.color.purpleDarkened2};
        border-color: ${({ theme }) => theme.color.purpleDarkened2};
        border: none;
        :focus,
        :hover {
          background-color: ${({ theme }) => theme.color.purpleDarkened1};
          border: none;
          color: ${({ theme }) => theme.color.white};
        }
        :active {
          background-color: ${({ theme }) => theme.color.purpleDarkened3};
        }
      }
      &.ant-btn-default {
        border-color: ${({ theme }) => theme.color.purple};
        color: ${({ theme }) => theme.color.purpleDarkened3};
        font-weight: bold;
        :focus,
        :hover {
          background-color: ${({ theme }) => theme.color.purple20};
        }
        :active {
          background-color: ${({ theme }) => theme.color.purpleDarkened3};
          color: ${({ theme }) => theme.color.white};
        }
      }
    }
  }
`;

export const SyncTitle = styled.div`
  font-size: 30px;
  color: rgb(32, 26, 61);
  text-align: center;
  width: 100%;
`;

export const StyledFooterSection = styled(Form.Item)`
  .ant-form-item-control-input-content {
    display: flex;
    justify-content: center;
  }
  left: 22%;
  padding-bottom: 0px !important;
  padding-top: 24px !important;
`;

export const Container = styled.div`
  .product-timeline-label {
    font-size: 16px;
    padding-left: 5px;
  }

  > div {
    flex: 1;
  }
`;

export const Description = styled.div`
  margin-top: 15px;
  margin-left: 0;
  font-size: 16px;

  .description {
    height: 240px;
  }

  @media screen and (min-width: 1100px) {
    flex: 0 0 49%;
    margin-top: 0;
  }
`;

export const ProductTimeline = styled.div`
  display: flex;
  flex: 1;
  align-items: center;

  button.ant-btn {
    margin-right: 5px;
    background: transparent;
    border: none;
    font-size: 25px;
    color: ${({ theme }) => theme.color.gray3} !important;

    &:hover,
    &:focus,
    &:active {
      background: ${({ theme }) => theme.color.purple} !important;
      color: ${({ theme }) => theme.color.dark} !important;
    }
  }
`;

export const StyledDivider = styled(Divider)`
  display: none !important;
  border: 1px solid ${({ theme }) => theme.color.gray2};
  height: 60px !important;
`;

export const StyledLabelValueWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 14px;
  font-size: 16px;
  line-height: 20px;

  div:first-child {
    margin-bottom: 4px;
    font-weight: bold;
  }

  div {
    word-break: break-all;
  }
`;

export const StyledProductCategoryWrap = styled.div`
  display: flex;

  .icon-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 8px 0 0;
    height: 24px;
    width: 24px;
    border: 1px solid ${({ theme }) => theme.color.dark20};
    box-sizing: border-box;
    border-radius: 3px;
  }
`;

export const StyledModalContainer = styled.div`
  .ant-modal-content {
    background-color: ${({ theme }) => theme.color.gray6};
  }
  .footer {
    padding-top: 0px !important;
  }
  .header {
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
    .ant-row {
      width: 100%;
    }
    .ant-btn.close.ant-btn-link {
      height: 56px;
      width: 56px;
      line-height: 56px;
      padding: 0;
      margin: 0 !important;
      position: absolute;
      align-self: flex-end;
      color: ${({ theme }) => theme.color.dark} !important;
      right: -24px;
      top: -24px;
      .anticon {
        font-size: 24px;
      }
    }
    .title {
      text-align: center;
      width: 100%;
      display: block;
      font-size: 20px;
      color: ${({ theme }) => theme.color.dark};
      .anticon {
        color: ${({ theme }) => theme.color.purple};
        font-size: 50px;
        margin-right: 20px;
      }
    }
  }
  .ant-row.ant-form-item {
    .ant-select,
    .ant-cascader-picker {
      width: 100%;
    }
    .browse-file {
      width: 100%;
      overflow: hidden;
      .ant-btn {
        width: 100%;
        text-align: left;
        border-color: ${({ theme }) => theme.color.purple};
        font-size: 16px;
        font-weight: normal;
        padding: 0 10px;
      }
      .anticon {
        color: ${({ theme }) => theme.color.purple};
      }
    }
  }
  .contentContainer {
    .fileUploadContainer {
      margin-top: 60px;
      margin-bottom: 30px;
      padding-top: 20px;
      background: ${({ theme }) => theme.color.white};
    }
    .effective-form {
      margin-top: 50px;
      margin-bottom: 50px;
      .effectiveDateFormContainer {
        justify-content: space-between;
      }
    }
    .warning-container {
      margin-bottom: 90px;
    }
    .summary-container {
      margin-top: 20px;
      margin-bottom: 40px;
    }
  }
  .upload-section {
    .ant-steps.ant-steps-horizontal.ant-steps.ant-steps-horizontal {
      margin-top: 30px;
    }
  }
`;

export const StyledDateCol = styled.div`
  display: none;
  & {
    flex: 0 0 50%;
    flex-wrap: wrap;
  }
`;

export const DateContainer = styled.div<{ isStartDate: boolean }>`
  margin-right: 20px;
  display: flex;
  align-items: center;
  justify-content: ${({ isStartDate }) =>
    isStartDate ? 'none' : 'space-between'};

  :last-child {
    margin-right: 0;
  }

  .data-container {
    display: flex;
    flex-direction: column;

    span {
      min-height: 40px;
      display: inline-flex;
      align-items: center;
      font-size: 16px;
    }

    label {
      margin-right: 5px;
      font-size: 16px;
      font-weight: 500;
      font-weight: bold;
      font-size: 16px;
      line-height: 20px;
    }

    @media screen and (min-width: 1100px) and (max-width: 1125px) {
      span {
        font-size: 15px;
      }

      label {
        font-size: 15px;
      }
    }

    @media screen and (min-width: 1240px) and (max-width: 1495px) {
      span {
        font-size: 15px;
      }

      label {
        font-size: 15px;
      }
    }
  }
`;

export const Redspan = styled.span`
  && {
    color: ${({ theme }) => theme.color.failed};
    margin-right: 5px;
  }
`;

export const CustomRow = styled(Row)`
  color: ${({ theme }) => theme.color.dark};

  .rightAlign {
    text-align: right;
    padding-right: 20px;
  }
  .leftAlign {
    text-align: left;
  }

  .centerAlign {
    text-align: center;
  }

  .ant-calendar-picker {
    width: 100% !important;
  }
`;

export const DescriptionText = styled.div`
  color: ${({ theme }) => theme.color.gray1};
  text-align: center;
  margin-bottom: 25px;
  margin-top: 20px;
  text-align: left;
`;

export const EffectiveForm = styled(Form)`
  && {
    margin-top: 40px;
    .effective_date_form .ant-form-item-children {
      display: block !important;
    }

    .effective_date_form label {
      font-size: 14px;
      font-weight: 400;
    }

    .centerAlign {
      display: flex;
      justify-content: center;
    }
    .divHide {
      display: none;
    }
    .divShow {
      display: block;
    }
    .selectStyle {
      margin-bottom: 30px;
    }
    .footer {
      margin: 40px 0;

      .ant-btn.ant-btn-round {
        margin: 0 5px;
      }
    }
    .summaryText {
      color: ${({ theme }) => theme.color.gray1};
      font-size: 16px;
      font-weight: 400;
    }
  }
`;

export const Title = styled.div`
  text-align: center;
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 18px;
  color: ${({ theme }) => theme.color.dark};
`;

export const SelectOptionTitle = styled.div`
  text-align: center;
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 20px;
  color: ${({ theme }) => theme.color.dark};
`;

export const EffectiveDate = styled.div`
  margin: 0 auto 10px !important;
  color: ${({ theme }) => theme.color.dark};
  background: ${({ theme }) => colorHexToRgba(theme.color.gray10, 0.4)};
  border: 1px solid ${({ theme }) => theme.color.gray4};
  padding: 10px 20px;
  border-radius: 6px;

  .inputTitle {
    line-height: 40px;
    text-align: right;
  }

  .ant-row-flex {
    margin-top: 5px;
    margin-bottom: 10px;
  }

  .ant-form-item-label > label {
    font-size: 14px;
    font-weight: 700;
  }

  .ant-calendar-picker {
    width: 100%;
  }
  .effectiveDateTitle {
    color: ${({ theme }) => theme.color.gray1};
    font-weight: 700;
    font-size: 16px;
  }
  .compulsoryFieldLabel {
    font-size: 12px;
    color: ${({ theme }) => theme.color.dark};
    font-style: italic;
  }

  .effectiveDateFormContainer {
    display: flex !important;
    flex-direction: row !important;

    .ant-col {
      flex: 1 !important;

      .ant-row {
        display: initial !important;
      }
    }
  }
`;

export const ChangeSummaryContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`;

export const ChangeSummaryWrapper = styled.div`
  flex: 1;
  b {
    word-break: break-all;
  }
`;

export const StyledChangedDate = styled.span`
  flex: 1;
  margin-left: 10px;
  text-align: right;
`;

export const LaunchTimelineContainer = styled(Form.Item)`
  width: 101%;
  margin-bottom: 12px !important;
  padding-bottom: 12px !important;
  display: none !important;
`;

export const CreatedByContainer = styled(Form.Item)`
  margin-bottom: 12px !important;
  padding-bottom: 12px !important;

  .ant-form-item-control-input {
    min-height: 22px;
  }
`;

export const CategoryContainer = styled(Form.Item)`
  margin-bottom: 12px !important;
  padding-bottom: 12px !important;
`;

export const LaunchTimelineRow = styled(Row)`
  width: 100% !important;
  padding-bottom: 0px !important;

  span {
    font-size: 16px !important;
    color: ${({ theme }) => theme.color.dark}!important;
    font-weight: bold;
  }

  .ant-calendar-picker {
    margin-right: 0px !important;
  }

  .product-date-label {
    font-size: 14px;
    margin-bottom: -8px;
    display: block;
    color: ${({ theme }) => theme.color.dark};
  }
`;

export const DescriptionContainer = styled.div`
  .ant-form-item {
    margin-bottom: 0px !important;

    .ant-form-item-control-input {
      textarea {
        border-radius: 4px !important;
      }
    }
  }
`;

export const StyledTextarea = styled(TextArea)`
  && {
    border: 1px solid ${({ theme }) => theme.color.gray2};
    box-sizing: border-box;
    height: 109px;
    border-radius: 2px;
  }
  ::-webkit-scrollbar {
    border: 1px solid ${({ theme }) => theme.color.dark20};
  }
`;

export const StyledFormBody = styled.div`
  .category-drop-down {
    width: 100% !important;
  }

  label {
    font-weight: bolder;
  }
  .ant-form-item {
    margin-bottom: 0 !important;
  }
  .cover-image-row {
    margin-top: 20px;
    & .ant-form-extra {
      font-style: italic;
      color: ${({ theme }) => theme.color.dark};
      & span {
        color: ${({ theme }) => theme.color.failed};
      }
    }
  }

  .ant-form-item-control {
    width: 100%;
  }
  .ant-form-extra {
    font-size: 12px;
    line-height: 14px;
    margin-top: 3px;
  }
`;

export const StyledForm = styled(Form)`
  .ant-row.ant-form-item {
    .ant-form-item-label {
      line-height: 1.5 !important;
      min-height: 0px !important;
    }
    .ant-form-item-label > label {
      font-weight: bold !important;
      font-size: 16px !important;
    }

    .ant-cascader-picker {
      width: 100%;
      overflow: hidden;
    }

    & ::placeholder {
      font-size: 14px !important;
      color: ${({ theme }) => colorHexToRgba(theme.color.dark, 0.6)};
    }

    .ant-calendar-picker-input {
      border-width: 1px !important;
    }

    .ant-calendar-picker {
      width: 99%;
    }

    textarea.ant-input {
      padding-top: 10px;
      margin-bottom: 0px;
    }

    .browse-file {
      width: 100%;
      overflow: hidden;

      .ant-upload {
        width: 100%;
      }

      .ant-btn {
        width: 100%;
        height: 40px;
        text-align: left;
        border-radius: 4px !important;
        border-color: ${({ theme }) => theme.color.purple};
        font-size: 14px;
        font-weight: normal;
        padding: 0 10px;
        margin-left: 0 !important;
        margin-right: 0 !important;
        color: ${({ theme }) => colorHexToRgba(theme.color.dark, 0.6)};

        &:hover {
          color: ${({ theme }) => theme.color.purple};
        }
      }

      .anticon {
        color: ${({ theme }) => theme.color.purple};
      }
    }
  }
`;

export const SelectedFileContainer = styled.div`
  padding: 10px;
  text-align: center;
  color: ${({ theme }) => theme.color.dark};

  .anticon {
    font-size: 40px;
    color: ${({ theme }) => theme.color.purple};
  }

  .name {
    font-weight: bold;
    margin-top: 10px;
  }

  .size {
    font-style: italic;
  }
`;

export const StyledCoverImageContainer = styled.div`
  img {
    margin-bottom: 16px;
  }
  .ant-upload.ant-upload-drag {
    width: 100% !important;
    min-width: 200px;
  }
`;

export const StyledOptionalSpan = styled.span`
  color: ${({ theme }) => theme.color.dark80};
  font-weight: normal;
`;

export const StyledInputElement = styled(Col)`
  .ant-picker-footer {
    .ant-picker-now-btn {
      color: ${({ theme }) => theme.color.white};
    }
  }
  .ant-picker-content tbody tr td .ant-picker-cell-inner:hover {
    background-color: ${({ theme }) => theme.color.gray10} !important;
  }
`;

export const StyledRowSection = styled.div`
  display: flex;
  padding-right: 5px;

  .product-summary {
    width: 100%;
  }

  .product-detail-summary {
    .product-summary {
      margin-bottom: 0;
    }
  }
`;

export const Footer = styled.div`
  padding-top: 60px;
  height: 112px;
  justify-content: center;
  text-align: center;

  position: relative;
  bottom: 94px;
  background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 1)
  );

  .ant-btn {
    width: 200px;
    font-weight: bold;
  }
`;

export const SectionTitle = styled.div`
  font-weight: bold;
  text-align: center;
  font-size: 18px;
  line-height: 26px;
  color: ${({ theme }) => theme.color.gray1};
  margin-bottom: 5px;
`;

export const SectionContent = styled.div`
  text-align: center;
  line-height: 24px;
  font-size: 16px;
  color: ${({ theme }) => theme.color.gray1};
  padding: 0 10%;
`;

export const SectionTextArea = styled.div`
  text-align: center;
  line-height: 22px;
  padding: 20px 15%;
`;

export const FullScreenContent = styled.div`
  width: 100%;
`;

export const PageTitle = styled(Row)`
  padding-left: 22px;

  .anticon-plus-square {
    margin-right: 0 !important;
  }
`;

export const StyledRedspan = styled.span`
  color: red;
`;

export const StyledTooltipContainer = styled.div`
  display: flex;
  max-width: 300px;

  .content {
    padding: 0 5px;
  }
`;

export const DescriptionTitle = styled.div`
  color: ${({ theme }) => theme.color.dark};
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 10px;
`;

export const StyledDescriptionText = styled.div`
  color: ${({ theme }) => theme.color.dark};
  font-size: 16px;
  margin-bottom: 25px;
`;

export const ModalContent = styled.div`
  margin-top: 30px;
  margin-bottom: 30px;
  .inputTitle {
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 10px;
    color: ${({ theme }) => theme.color.gray1};
  }
  .warningText {
    font-size: 14px;
    font-style: italic;
  }
  textarea.ant-input {
    padding-top: 10px;
  }
`;

export const StyledCustomRow = styled(Row)`
  width: 100%;
  color: ${({ theme }) => theme.color.dark};

  .rightAlign {
    text-align: right;
    padding-right: 20px;
  }
  .leftAlign {
    text-align: left;
  }

  .ant-calendar-picker {
    width: 100% !important;
  }
`;

export const StyledButtonSection = styled(Button)`
  && {
    padding: 0px;
    height: 21px !important;
    width: auto !important;

    .anticon {
      color: ${({ theme }) => theme.color.purple};
      vertical-align: -0.3rem !important;

      svg {
        width: 21px;
        height: 21px;
      }
    }
  }
`;

export const StyledUploadSection = styled.div`
  position: relative;

  .header {
    .ant-btn.close.ant-btn-link {
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  .contentContainer {
    padding: 20px 0 40px;

    .fileUploadContainer {
      margin: 0 auto 0px;

      .ant-card {
        border: none;
        background: transparent;

        .ant-card-head {
          padding: 0;

          .ant-card-head-wrapper {
            .ant-card-head-title {
              font-size: 14px;
              padding-bottom: 0px;
            }
          }
        }
        .ant-card-body {
          padding: 0;
          border: 1px dashed ${({ theme }) => theme.color.dark60};
          background-color: ${({ theme }) => theme.color.white};
          padding: 20px;
          border-radius: 6px;

          .ant-upload.ant-upload.ant-upload-drag {
            border: none;
            background: none;
            min-height: 300px;
            padding-bottom: 60px;
            z-index: 1;
          }

          .uploadLoadingContainer {
            position: absolute;
            bottom: 20px;
            left: 20px;
            right: 20px;
          }
        }
      }
    }
  }
  .footer {
    text-align: center;
  }
`;

export const EffectiveWarning = styled.div`
  text-align: center;
`;

export const UploadEngineFormSection = styled.div`
  .header {
    padding-top: 10px;
    .ant-row > .ant-col {
      text-align: center !important;
      font-size: 20px !important;
    }
    .ant-btn.close.ant-btn-link {
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  .ant-steps.ant-steps-horizontal.ant-steps.ant-steps-horizontal {
    margin-top: 30px !important;

    .ant-steps-item {
      .ant-steps-item-container {
        .ant-steps-item-tail {
          padding: 0 10px !important;
          margin-top: 2px !important;
          ::after {
            background: ${({ theme }) =>
              theme.color.purpleDarkened1} !important;
            height: 2px !important;
          }
        }

        .ant-steps-item-icon {
          background: ${({ theme }) => theme.color.white} !important;
          border: 2px solid ${({ theme }) => theme.color.purpleDarkened1} !important;
          height: 27px !important;
          width: 27px !important;
          .ant-steps-icon {
            vertical-align: top;
            line-height: 22px;
            font-weight: 500;
            font-size: 12px;
            color: ${({ theme }) => theme.color.dark80};
          }
        }
        .ant-steps-item-content {
          margin: 0 !important;
          .ant-steps-item-title {
            font-size: 12px !important;
            color: ${({ theme }) => theme.color.dark80} !important;
          }
        }
      }
    }

    .ant-steps-item.ant-steps-item-active {
      .ant-steps-item-icon {
        background: ${({ theme }) => theme.color.purpleDarkened1} !important;
        .ant-steps-icon {
          color: ${({ theme }) => theme.color.white} !important;
        }
      }
      .ant-steps-item-content {
        .ant-steps-item-title {
          font-weight: bold !important;
        }
      }
    }

    .ant-steps-item.ant-steps-item-finish {
      .ant-steps-item-container {
        .ant-steps-item-icon {
          background: ${({ theme }) => theme.color.purpleDarkened1} !important;
          .ant-steps-icon {
            color: ${({ theme }) => theme.color.white} !important;
          }
        }
        .ant-steps-item-content {
          .ant-steps-item-title {
            color: ${({ theme }) => theme.color.dark80} !important;
            font-weight: bold !important;
          }
        }
      }
    }

    .ant-steps-item.ant-steps-item-disabled {
      .ant-steps-item-container {
        .ant-steps-item-icon {
          background: ${({ theme }) => theme.color.dark20} !important;
          border-color: ${({ theme }) => theme.color.dark20} !important;
          .ant-steps-icon {
            color: ${({ theme }) => theme.color.dark} !important;
          }
        }
        .ant-steps-item-content {
          .ant-steps-item-title {
            color: ${({ theme }) => theme.color.dark80} !important;
            font-weight: normal !important;
          }
        }
      }
    }
  }

  .fileUploadContainer {
    margin: 0 auto 0px;
    margin-top: 80px;
    margin-bottom: 40px;
    height: 310px;
    padding-bottom: 20px;
    padding-top: 20px;

    .upload-cancel {
      display: none;
    }
  }
  .footer {
    text-align: center;
    box-shadow: none !important;
  }
`;

export const StyledContainerSection = styled.div`
  width: 100%;
  display: flex;
  color: ${({ theme }) => theme.color.dark};
  height: 300px;
  padding-top: 120px;

  .rightAlign {
    text-align: right;
    padding-right: 20px;
  }
  .leftAlign {
    text-align: left;
  }

  .ant-calendar-picker {
    width: 100% !important;
  }

  .anticon {
    color: ${({ theme }) => theme.color.failed};
    font-size: 50px;
    text-align: center;
    margin-bottom: 10px;
  }
`;

export const WarningRow = styled(Row)`
  width: 100%;
  color: ${({ theme }) => theme.color.dark};
  margin-bottom: 16px;
`;

export const StyledModalContent = styled.div`
  width: 90%;
  margin: 0 auto;
  margin-bottom: 50px;
`;

export const DescriptionTextSection = styled.div`
  color: ${({ theme }) => theme.color.gray1};
  text-align: left;
  margin-bottom: 25px;
  margin-left: 20px;
  margin-top: 10px;
  font-size: 16px;
`;
// ProductCatalogue Styled End

// Language Styled Start

export const StyledMenuTogglerTransparentCss = css`
  color: ${({ theme }) => theme.color.white} !important;
  background-color: transparent;

  .anticon {
    color: ${({ theme }) => theme.color.white};
  }

  &:hover,
  &:active,
  &:focus {
    color: ${({ theme }) => theme.color.white} !important;
    background-color: transparent;

    .anticon {
      color: ${({ theme }) => theme.color.white};
    }
  }
`;

export const StyledMenuToggler = styled(Button)<{
  text?: boolean;
  transparent?: boolean;
}>`
  && {
    border: none;
    margin-left: 0px;
    font-size: 24px;
    font-weight: 500;
    color: ${({ theme }) => theme.color.gray3} !important;

    &.ant-btn.ant-btn {
      padding: 0px;
    }
    .anticon {
      margin-left: 0px;
      font-size: 20px;
      color: ${({ theme }) => theme.color.purple};
    }

    display: flex;
    align-items: center;

    &:hover,
    &:active {
      color: ${({ theme }) => theme.color.dark} !important;
      background: ${({ theme }) => theme.color.purple20};
      .anticon {
        color: ${({ theme }) => theme.color.dark};
      }
    }
    ${({ transparent }) => transparent && StyledMenuTogglerTransparentCss}
  }
`;

export const LanguageSwitchContainer = styled.div`
  display: flex;
  align-items: center;

  a {
    font-size: 14px;
    line-height: 1;
    color: ${({ theme }) => colorHexToRgba(theme.color.purple, 0.6)};
    padding: 0 15px;
    border-right: 1px solid
      ${({ theme }) => colorHexToRgba(theme.color.purple, 0.6)};

    &:last-child {
      border-right: none;
    }

    &.active {
      font-weight: bold;
      color: ${({ theme }) => theme.color.purple};
    }
  }
`;
// Language Styled End

// Insights Styled Start
export const TopMetricsRow = styled(Row)`
  padding: 21px 24px 18px 24px;
`;

export const MetricsListItem = styled(List.Item)`
  cursor: pointer;

  &.active {
    background-color: ${({ theme }) => theme.color.purple20};
  }

  .metricsname {
    font-size: 16px;
    color: ${({ theme }) => theme.color.dark};
    @media (max-width: 767px) {
      font-size: 14px;
    }
  }
  .metricsvalue {
    font-size: 24px;
    color: ${({ theme }) => theme.color.dark};
    @media (max-width: 767px) {
      font-size: 16px;
    }
  }
`;

export const InsightSectionContainer = styled.div`
  .title-matrics {
    padding-top: 4px;
    padding-bottom: 8px;
    font-size: 16px;
    font-weight: 700;
    color: ${({ theme }) => theme.color.dark};
  }
`;

export const InsightSectionRow = styled(Row)`
  border: 1px solid ${({ theme }) => theme.color.gray2};
  border-radius: 4px;
  margin: 0 !important;
  height: 471px !important;
  .insightmetric {
    .ant-spin-container {
      max-height: 470px !important;
      overflow-y: auto;
      ${ScrollCss}
      ::-webkit-scrollbar {
        border: 1px solid ${({ theme }) => theme.color.dark20};
      }
    }
  }
  .ant-list-items {
    height: 470px;
  }
  .ant-list-item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 8px 16px;
    height: 67px;
    &:hover {
      background-color: ${({ theme }) => theme.color.purple20};
    }
    @media (max-width: 767px) {
      padding: 8px 10px;
    }
  }
`;

export const InsightChartContainer = styled.div`
  .metrics-chart {
    padding: 16px;
  }
`;

export const StyledAlert = styled(Alert)`
  border: none !important;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
`;

export const StyledTitle = styled.div`
  margin-top: 8px;
  margin-bottom: 24px;
  font-weight: normal;
  text-align: center;
  width: 100%;

  .metrics-name {
    font-size: 20px;
    line-height: 23px;
  }
  .metrics-value {
    font-size: 14px;
    line-height: 24px;
  }

  .metrics-drilldown {
    height: 48px;
    margin-top: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 8px;
    background: ${({ theme }) => theme.color.pearl40};
    box-shadow: inset 0px -1px 0px ${({ theme }) => theme.color.gray10},
      inset 0px 1px 0px ${({ theme }) => theme.color.gray10};

    .metrics-drilldown-select {
      width: 30%;
      margin-top: 8px;
      margin-bottom: 6px;
      margin-left: 7px;
    }
  }
`;

export const StyledButton = styled(Button)`
  position: absolute !important;
  top: 16px;
  right: 0px;
  display: block !important;
  height: 18px !important;
  line-height: 0 !important;
`;

export const StyledEmpty = styled(Empty)`
  margin-top: 120px !important;
`;
// Insights Styled End

// FileManager
export const PathRow = styled(Row)`
  .inputSharePath {
    border-radius: 5px;
    background: ${({ theme }) => theme.color.purple};
    padding: 5px 15px;
    line-height: 25px;
    text-align: center;
    color: ${({ theme }) => theme.color.gray};
    margin: 20px 0;
    box-shadow: inset 0 2px 5px
      ${({ theme }) => colorHexToRgba(theme.color.black, 0.2)};
  }
`;
export const FileManagerMenuToggler = styled(Button)`
  && {
    border: none;
    padding: 0;
    margin: 0;
    border-radius: 0;
    background: transparent;
    color: ${({ theme }) => theme.color.white};
    font-size: 1rem;
    font-weight: bold;
    outline: none;

    &:hover,
    &:active,
    &:focus {
      background-color: transparent;
      color: ${({ theme }) => theme.color.white};
    }

    .anticon {
      font-size: 30px;
    }
  }
`;

export const FileManagerContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ theme }) => theme.color.dark};
  height: 70px;
  color: ${({ theme }) => theme.color.white};
  padding: 0 10px;

  .ant-typography {
    color: ${({ theme }) => theme.color.white} !important;
    margin-bottom: 0;
  }
`;

export const FileManagerFilesIconSvg = styled(IconSvg)`
  font-size: 16px;
  color: ${({ theme }) => theme.color.gray2}!important;
`;

export const StyledFileOutlined = styled(FileOutlined)`
  font-size: 16px;
  color: ${({ theme }) => theme.color.gray2} !important;
`;

// EngineManager Styled Start

export const StyledEngineDownloadModal = styled(CustomModal)`
  .ant-modal-footer {
    padding: 0px 32px 24px 32px;
  }
  .ant-modal-body {
    padding: 0 32px 24px;

    .ant-form-vertical .ant-form-item-label {
      padding: 0;
    }
    .ant-form-item-label {
      min-height: 30px;
    }
    .ant-form {
      .ant-row {
        &.ant-form-item {
          border-bottom: none !important;
          margin-bottom: 0px !important;
          padding-bottom: 12px !important;

          &:nth-last-child(2),
          &:last-child {
            margin-bottom: 0px !important;
            padding-bottom: 12px !important;
            border-bottom: none !important;
          }

          .CaretDownFilled {
            color: ${({ theme }) => theme.color.purple} !important;
          }
        }
        .ant-form-item-label > label {
          font-weight: 600;
        }
      }
    }
  }
`;

export const StyledSpan = styled.span`
  font-style: italic;
  .bold {
    font-weight: bold;
  }
`;

export const StyledEngineVersionDetailModal = styled(CustomModal)`
  padding: 0 !important;
  width: 80% !important;
  color: ${({ theme }) => theme.color.dark};

  .ant-table-row-hover,
  tr:hover {
    td {
      background: unset !important;
    }
  }
  .versionoverviewmodal .scroll-container .ant-table .ant-table-body {
    max-height: calc(100vh - 170px) !important;
  }

  .ant-modal-content {
    width: 100%;
  }
  .ant-tabs-bar {
    border-bottom: 0;
  }

  .ant-tabs-nav-scroll {
    display: flex;
    justify-content: center;
  }

  .ant-tabs-tab:first-child {
    border-radius: 3px 0px 0px 3px !important;
  }

  .ant-tabs-tab:last-child {
    border-radius: 0px 3px 3px 0px !important;
  }
`;

export const ActionButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 40px;

  button {
    height: 40px !important;
  }
`;

export const StyledTabName = styled.div`
  text-align: center;
`;

export const StyledEngineVersionButton = styled(Button)`
  width: 30px !important;
  min-width: 30px !important;
  border-radius: 4px !important;
  color: ${({ theme }) => theme.color.purple} !important;
  border-color: ${({ theme }) => theme.color.purple} !important;
  padding-left: 4px !important;
  padding-right: 4px !important;
  margin: 0 8px;

  &:disabled {
    opacity: 0.2 !important;
    background-color: ${({ theme }) => theme.color.white};
  }
`;

export const StyledEngineVersionTitle = styled.p`
  font-size: 18px;
  text-align: center;
`;

export const StyledEngineVersionWrapper = styled.div`
  .submenu-wrapper {
    li {
      background: black !important;
    }
  }
`;

export const StyledTableContent = styled.div`
  display: flex;
  justify-content: center;
  &.view-more-text {
    text-decoration: underline;
    font-size: 14px;
    cursor: pointer;
  }
`;

export const ChartWrapper = styled.div`
  && {
    border: 1px solid ${({ theme }) => theme.color.gray10};
    border-top: none;
    width: 100%;
    height: calc(100vh - 265px);
    overflow: scroll;
    background-image: linear-gradient(to right, white, white),
      linear-gradient(to right, white, white),
      linear-gradient(to right, rgb(0 0 0 / 10%), rgba(255, 255, 255, 0)),
      linear-gradient(to left, rgb(0 0 0 / 13%), rgba(255, 255, 255, 0));
    background-position: left center, right center, left center, right center;
    background-repeat: no-repeat;
    background-color: white;
    background-size: 20px 100%, 20px 100%, 10px 100%, 10px 100%;
    background-attachment: local, local, scroll, scroll;
    ${ScrollCss}

    .lower-text {
      font-size: 14px !important;
      border: 1px solid red;
      fill: ${({ theme }) => theme.color.dark80} !important;
    }

    ::-webkit-scrollbar:horizontal {
      border: 1px solid ${({ theme }) => theme.color.gray10};
      border-right: none;
      border-left: none;
      border-bottom: 0;
    }

    ::-webkit-scrollbar:vertical {
      border: 1px solid ${({ theme }) => theme.color.gray10};
      border-right: 0;
      border-bottom: 0;
    }

    .gantt-container {
      overflow: visible;

      svg {
        background: ${({ theme }) => theme.color.white} !important;
        margin-left: -1px;
      }
    }
  }
`;

export const ActiveLegend = styled.div`
  border-radius: 2px;
  background-color: ${({ theme }) => theme.color.purple20};
  margin: 0 7px 0 12px;
  height: 16px;
  width: 16px;
  border: 1px solid ${({ theme }) => theme.color.purple};
`;

export const InactiveLegend = styled.div`
  border-radius: 2px;
  background-color: ${({ theme }) => theme.color.gray10};
  margin: 0 7px 0 20px;
  height: 16px;
  width: 16px;
  border: 1px solid ${({ theme }) => theme.color.dark20};
`;

export const HelperMessage = styled.div`
  font-weight: normal;
  font-size: 14px;
  line-height: 25px;
  color: ${({ theme }) => theme.color.lightgray};
`;

export const LegendText = styled.div`
  font-weight: normal;
  font-size: 14px;
  line-height: 25px;
  color: ${({ theme }) => theme.color.dark};
`;

export const ButtonRowWrap = styled.div`
  display: flex;
  justify-content: space-between;

  .legend-wrap {
    display: flex;
    align-items: center;
  }
`;

export const TimelineContainer = styled.div`
  && {
    display: flex;
    flex-flow: column;
    align-items: center;

    .handle {
      display: none !important;
    }

    .grid-header-stroke,
    .grid-header-stroke-light {
      display: none;
    }

    .gantt .tick {
      stroke: ${({ theme }) => theme.color.gray10};
      stroke-width: 10;
    }
    .gantt .tick.thick {
      stroke-width: 10;
    }

    .bar-popup {
      border-radius: 4px;
      padding: 5px 10px;
      font-size: 14px;
      background: ${({ theme }) => theme.color.dark80};
      color: ${({ theme }) => theme.color.white};
      width: 300px !important;
    }
  }
`;

export const StyledFreppeGantt = styled(props => <FrappeGantt {...props} />)`
  && {
    background: ${({ theme }) => theme.color.white} !important;
    display: none;

    .inactive-version {
      display: none;
    }
  }
`;

export const StyledTimelineWrapper = styled.div`
  margin: 0 30px 0 30px;
  background: ${({ theme }) => theme.color.white};

  .bottom-row {
    margin-top: 6px;
  }

  .gantt-container {
    margin-top: -20px;
    > svg {
      background: ${({ theme }) =>
        colorHexToRgba(theme.color.gray10, 0.2)} !important;
    }

    .inactive-version {
      .bar {
        fill: ${({ theme }) => theme.color.gray10} !important;
        stroke: ${({ theme }) => theme.color.dark20};
        stroke-width: 1px;
        ry: 4;
        rx: 4;

        :hover,
        :visited,
        :active,
        :focus {
          fill: ${({ theme }) => theme.color.gray10} !important;
          stroke: ${({ theme }) => theme.color.dark20};
          stroke-width: 1px;
          ry: 4;
          rx: 4;
        }
      }
    }

    .active-version {
      .bar {
        fill: ${({ theme }) => theme.color.purple20} !important;
        stroke: ${({ theme }) => theme.color.purple};
        stroke-width: 1px;
        ry: 4;
        rx: 4;

        :hover,
        :visited,
        :active,
        :focus {
          fill: ${({ theme }) => theme.color.purple20} !important;
          stroke: ${({ theme }) => theme.color.purple};
          stroke-width: 1px;
          ry: 4;
          rx: 4;
        }
      }
    }

    .tick {
      stroke: ${({ theme }) => theme.color.gray10} !important;
      stroke-width: 1 !important;
    }

    .popup-wrapper {
      position: absolute;
      top: 0;
      left: 0;
      background: green;
      padding: 0;
      color: red;
      border-radius: 3px;

      .pointer {
        border-top-color: ${({ theme }) => theme.color.dark80};
      }
    }
  }
`;

export const StyledTimelineTitle = styled.div`
  text-align: center;
  font-size: 16px;
  color: ${({ theme }) => theme.color.dark80};
  margin: 5px 30px 15px 30px;
`;

export const StyledInputsTable = styled(
  (props: TableProps<DTO.XSingelCellNameRange>) => (
    <Table pagination={false} {...props} />
  )
)`
  && {
    background: ${({ theme }) => theme.color.white};
    font-weight: normal;
    background-color: ${({ theme }) => theme.color.white};
    border: 0.6px solid ${({ theme }) => colorHexToRgba(theme.color.dark, 0.4)};
    border-radius: 3px;
    .ant-table {
      height: fit-content;
      .ant-table-body {
        max-height: calc(100vh - 310px) !important;
      }
    }
    .ant-table .ant-table-tbody > tr > td {
      border: none;
      font-size: 14px;
      textarea {
        padding: 10px 12px;
      }
    }

    .ant-table-thead .ant-table-cell::before {
      display: none;
    }

    .ant-table-content {
      ${ScrollCss}
    }

    .ant-input {
      width: 230px;
    }

    .ant-table-cell-scrollbar {
      box-shadow: none;
    }

    .ant-table .ant-table-thead > tr > th {
      font-size: 14px;
      background-color: ${({ theme }) => theme.color.purple40};
      height: 45px;
    }
    .ant-table-tbody {
      tr:hover,
      tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected),
      tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) {
        td {
          background: ${({ theme }) => theme.color.purple20};
        }
      }
    }
  }
`;

export const StyledReferenceTable = styled(Table)`
  && {
    background: ${({ theme }) => theme.color.white};
    font-size: 14px;
    border: 0.6px solid ${({ theme }) => colorHexToRgba(theme.color.dark, 0.4)};
    border-radius: 3px;

    .ant-table .ant-table-tbody > tr > td {
      border: none;
      font-size: 14px;
    }

    .ant-table .ant-table-thead > tr > th {
      font-size: 14px;
      background-color: ${({ theme }) => theme.color.purple40};
      height: 45px;
    }

    .ant-table-thead .ant-table-cell::before {
      display: none;
    }

    tr.ant-table-row td:nth-child(odd) {
      font-weight: bold;
    }

    .ant-table .ant-table-tbody > tr {
      height: 50px;
    }

    tr.ant-table-row td:nth-child(even) {
      font-weight: normal;
    }

    .ant-table-bordered .ant-table-tbody > tr > td:not(:last-child) {
      border-right: none;
    }

    .ant-table-tbody {
      tr:hover,
      tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected),
      tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) {
        td {
          background: ${({ theme }) => theme.color.purple20};
        }
      }
    }
  }
`;

export const StyledPropertiesAnchor = styled.a`
  color: ${({ theme }) => theme.color.purpleDarkened1};
  :hover {
    text-decoration: underline;
    color: ${({ theme }) => theme.color.purple};
  }
  :active {
    text-decoration: underline;
    color: ${({ theme }) => theme.color.purpleDarkened2};
  }
`;

export const StyledVersionDetailsForm = styled(Form)`
  && {
    .ant-calendar-picker,
    .version_label_input {
      border: 1px solid ${({ theme }) => theme.color.gray2};
      border-radius: 4px;
      :focus,
      :hover {
        border: 1px solid ${({ theme }) => theme.color.purple} !important;
      }
      :focus {
        box-shadow: 0 0 0 2px ${({ theme }) => theme.color.purple40};
      }
    }
    .version_description_input {
      padding-top: 9px;
    }
  }
`;

export const StyledUploadDetailsAlert = styled(CommonAlert)`
  && {
    display: none;
  }
  &&.alert-info {
    border: 1px solid ${({ theme }) => theme.color.purple};
    border-radius: 3px;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .alert-content {
      margin-left: 34.4px;
    }
    .alert-icon {
      color: ${({ theme }) => theme.color.purple};
    }
    .alert-message {
      text-align: left;
    }
    .alert-description {
      font-size: 14px;
      text-align: left;
    }
  }
  &&.alert-hidden {
    display: none;
  }
`;
// EngineManager Styled End

// ConfigurationFiles Styled Start
export const LinkButton = styled(Button)`
  && {
    &.ant-btn {
      border-radius: 40px;
      color: ${({ theme }) => theme.color.purpleDarkened1};
      .anticon {
        padding-right: 5px;
        color: ${({ theme }) => theme.color.purple};
      }
      :hover {
        background-color: ${({ theme }) => theme.color.purple20};
        color: ${({ theme }) => theme.color.purpleDarkened3};
      }
      :active {
        color: ${({ theme }) => theme.color.white};
        background-color: ${({ theme }) => theme.color.purpleDarkened3};
        .anticon {
          color: ${({ theme }) => theme.color.purple60};
        }
      }
    }
  }
`;

export const FailureText = styled(Col)`
  color: ${({ theme }) => theme.color.failed};
`;

export const StyledProgressBar = styled(ProgressBar)`
  flex: 1;
  align-items: initial !important;
  flex-flow: column !important;
  .ant-progress-outer,
  .ant-progress-inner {
    border-radius: 20px !important;
  }

  .label {
    font-size: 14px !important;
  }

  .count {
    font-size: 14px !important;
  }

  .anticon {
    display: none;
  }
`;

export const LogContainer = styled.div`
  background: #ececeb;
  opacity: 1;
  border: 1px solid #dcdcdc;
  box-sizing: border-box;
  border-radius: 4px;
  margin-top: 5px;
  height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 15px;
  white-space: normal;
`;

export const StyledSuccessLogCol = styled.div`
  border: none;
  color: ${({ theme }) => theme.color.gray};
`;

export const StyledWarningLogCol = styled.div`
  border: none;
  color: ${({ theme }) => theme.color.red};
`;

export const StyledErrorLogCol = styled.div`
  border: none;
  color: ${({ theme }) => theme.color.failed};
`;

export const SuccessText = styled(Col)`
  color: ${({ theme }) => theme.color.purple};
`;

export const StyledFormWrapper = styled.div`
  width: 80%;
  margin: 0 auto;
  max-width: 592px;

  .pageTitle {
    font-size: 20px;
    line-height: 20px;
    font-weight: 400;
    margin-bottom: 16px;
    color: ${({ theme }) => theme.color.dark};
  }

  .small-font {
    font-size: 16px;
  }

  .bottom-border {
    padding-bottom: 16px;
    border-bottom: 1px solid ${({ theme }) => theme.color.dark20} !important;

    .ant-btn {
      margin-top: 0px;
    }
  }

  .ant-btn[disabled] {
    color: ${({ theme }) => theme.color.dark20} !important;
    background: ${({ theme }) => theme.color.pearl40} !important;
    border: 1px solid ${({ theme }) => theme.color.dark20} !important;
  }
  .ant-progress-inner {
    border-radius: 15px !important;
    .ant-progress-bg {
      border-radius: 15px !important;
    }
  }

  .ant-row {
    > .ant-col {
      width: 100% !important;
      .ant-form-item-control-wrapper: {
        margin-top: 0px;
      }
    }
  }

  .ant-btn-primary {
    border-width: 0.6px !important;
  }

  .ant-row {
    .ant-form-item {
      .ant-form-item-control-input-content {
        display: flex;
        justify-content: center;
      }
    }
  }
`;

export const StyledConfigurationFileContainer = styled.div`
  padding-top: 40px;
`;

// ConfigurationFiles  Styled End

// TestLogs  Styled Start
export const TransactionFilter = styled(props => (
  <Row align="middle" {...props} />
))`
  margin-bottom: 20px;

  > span {
    margin-right: 25px;
  }

  .ant-calendar-picker {
    .ant-calendar-picker-clear,
    .ant-calendar-picker-icon {
      position: absolute;
      top: 35%;
      right: 25px;
    }
  }
  .ant-picker-header {
    color: ${({ theme }) => theme.color.white};
    button {
      color: ${({ theme }) => theme.color.white};
    }
  }
  .ant-picker-content tbody tr td .ant-picker-cell-inner:hover {
    background-color: ${({ theme }) => theme.color.gray10} !important;
  }
`;

export const DateRangeFilterButton = styled(Button)`
  && {
    padding: 0;
    border: none;
    background-color: transparent;
    color: ${({ theme }) => theme.color.purple};

    .anticon {
      font-size: 40px;
    }

    &:hover,
    &:active,
    &:focus {
      background-color: transparent;
      color: ${({ theme }) => theme.color.purple};
    }
  }
`;

export const StyledDownOutlined = styled(DownOutlined)`
  padding: 10px 2px 10px 10px; !important;
`;

export const DatePickerComponent = styled(({ ...rest }) => (
  <CustomDatePicker {...rest} />
))`
  min-width: 205px !important;
  .ant-picker-input {
    border: 1px solid ${({ theme }) => theme.color.gray2} !important;
    &:hover,
    &:focus {
      border: 1px solid ${({ theme }) => theme.color.purple} !important;
    }
  }

  .anticon {
    color: ${({ theme }) => theme.color.purple};
    font-size: 14px !important;
    &.anticon-close-circle {
      display: inline-block !important;
    }
  }
  .anticon:hover {
    color: ${({ theme }) => theme.color.purple};
  }
`;

export const StyledIconButton = styled.div`
  cursor: pointer;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;

  .anticon {
    margin-right: 5px;
    color: ${({ theme }) => theme.color.purple};
  }
`;

export const StyledLogsDownloadLabel = styled.span`
  align-self: center;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 5px;
  color: ${({ theme }) => theme.color.dark};
`;

export const StyledSearchInput = styled(props => <SearchInput {...props} />)`
  width: 427px !important;
  margin-right: 0px !important;

  @media (max-width: 1150px) {
    width: 205px !important;
  }
`;
// TestLogs Styled End

// TestingCenter Styled Start

export const StyledInputNumber = styled(InputNumber)`
  width: 100% !important;
`;
export const StyledSubHeader = styled.div<{ showForm: boolean }>`
  text-align: center;
  font-weight: bold;
  margin-top: ${({ showForm }) => (showForm ? '16px' : '')};
`;

export const StyledTestingModalCard = styled.div`
  text-align: center;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.color.white};
  border: thin solid #d9d9d9;

  .card-header {
    height: 56px;
    border-radius: 3px 3px 0px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
  }

  .card-content {
    border-radius: 0px 0px 3px 3px;

    .bottom-border:first-child:after {
      content: '';
      border: 1px solid ${({ theme }) => theme.color.dark20};
      display: inline-block;
      transform: rotate(180deg);
      width: 100%;
      position: absolute;
      bottom: 0px;
      left: 0%;
    }
    .divider-height {
      height: calc(100% - 56px);
      border: 1px solid ${({ theme }) => theme.color.dark20};
    }

    .tile-btn-bottom {
      position: absolute;
      bottom: 0;
    }

    .tile-desc {
      line-height: 20px;
      font-size: 16px;
      margin-bottom: 16px;

      a {
        color: ${({ theme }) => theme.color.purpleDarkened1};
        :hover,
        :active,
        :focus {
          color: ${({ theme }) => theme.color.purpleDarkened1};
        }
      }
    }

    .tile-btn {
      .ant-btn {
        width: 200px;
      }
    }
  }
`;

export const StyledRunTestbedSummary = styled.div`
  .ant-radio-wrapper {
    width: 120px;
    padding: 8px;
    &.ant-radio-wrapper-checked {
      background-color: ${({ theme }) => theme.color.purple20};
      border-radius: 4px;
      border: 1px solid ${({ theme }) => theme.color.purple};
    }
  }
`;

export const StyledPanel = styled.div`
  margin-bottom: 16px;
  padding: 16px;
  background: ${({ theme }) => theme.color.pearl40};
  border: 1px solid ${({ theme }) => theme.color.dark20};
  box-sizing: border-box;
  border-radius: 4px;
  .ant-descriptions-item-label {
    font-weight: 700;
  }
  .ant-descriptions-item {
    padding-bottom: 1px;
  }
  &.success {
    border: 1px solid ${({ theme }) => theme.color.success};
    background: ${({ theme }) => theme.color.success15};
  }
`;

export const StyledFileUpload = styled(props => <FileUpload {...props} />)`
  && {
    .ant-upload {
      width: 100% !important;
    }
    .ant-upload-drag-container {
      .ant-btn {
        background-color: ${({ theme }) => theme.color.purpleDarkened2};
        color: ${({ theme }) => theme.color.white} !important;
        &.has-error {
          background-color: ${({ theme }) => theme.color.white};
          border-color: ${({ theme }) => theme.color.purple};
          color: ${({ theme }) => theme.color.purpleDarkened3} !important;
        }
      }
    }
    .name {
      word-break: break-all;
    }
  }
`;

export const StyledTestbeddetailsContainer = styled.div`
  width: 100%;
  padding-top: 0;
  background: ${({ theme }) => theme.color.white};
  .sectiontitle {
    font-size: 20px;
    font-weight: 400;
    color: ${({ theme }) => theme.color.dark80};
    text-align: center;
    width: 100%;
    padding: 16px 0;
  }
  .ant-table-wrapper {
    padding: 8px 36px;
    padding-top: 0;
  }
  .ant-affix {
    z-index: 2;
  }
`;

export const StyledTestbedDetailsPanel = styled.div`
  width: 100%;
  padding-top: 0;
  background: ${({ theme }) => theme.color.white};
  padding: 8px 36px;
`;

export const StyledDetailsPanel = styled.div`
  && {
    margin-bottom: 20px;
    border-radius: 5px;
    border: 1px solid ${({ theme }) => theme.color.gray5};
    background: ${({ theme }) => theme.color.pearl40};
    .name-ellipsis {
      display: flex;
      align-items: center;
      overflow: hidden;
      text-overflow: ellipsis;
      .name {
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
`;

export const StyledRightPanel = styled.div`
  display: flex;
  justify-content: end;
  .ant-icon-only {
    margin: 0px 4px !important;
  }
`;

export const StyledSelectDisplay = styled.div``;

export const StyledTagContainer = styled.div`
  float: right;
  display: flex;
  align-items: center;
`;

export const StyledLinkButton = styled(Button)`
  line-height: 1.499 !important;
`;

export const StyledFilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.color.pearl40} !important;
  padding: 8px 36px;
`;

export const StyledInputContainer = styled.div`
  overflow: hidden;
  background: ${({ theme }) => theme.color.white};
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.color.dark20};
  .scroll-visibility {
    width: 100%;
    height: 5px;
    z-index: 999;
    &.topshadow {
      background: linear-gradient(#a6a3b178 0%, rgb(0 0 0 / 0%) 100%);
    }
    &.bottomshadow {
      background: linear-gradient(#a6a3b100 0%, rgb(0 0 0 / 10%) 100%);
    }
  }
  .generate-testcases-form {
    max-height: calc(100vh - 494px);
    overflow-y: auto;
  }
  .ant-alert-error {
    background-color: #f9dde4 !important;
  }
`;

export const StyledInputSection = styled.div<{ showFooter: boolean }>`
  ${ScrollCss}
  height: ${({ showFooter }) => (showFooter ? '200px' : 'auto')} ;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 24px;
  ::-webkit-scrollbar{
    background: transparent;
  }
`;

export const StyledProgressSection = styled.div`
  padding-left: 18px;
  padding-right: 18px;
`;

export const StyledFilterPanel = styled.div`
  display: flex;
`;

export const StyledSelectedRecordCompare = styled.div`
  display: flex;
  padding: 16px 36px;
  align-items: center;
`;

export const StyledSelectedRecordCompareButton = styled(Button)`
  margin-right: 8px;
  &[ant-click-animating-without-extra-node]:after {
    animation: 0s !important;
  }

  &.ant-btn {
    border: none;
    box-shadow: none;
    background: ${({ theme }) => theme.color.purple};
    color: ${({ theme }) => theme.color.white};
    min-width: fit-content;

    .anticon {
      color: ${({ theme }) => theme.color.purple60};
    }

    :focus,
    :active,
    .active,
    :hover {
      background: ${({ theme }) => theme.color.purple};
      color: ${({ theme }) => theme.color.white};
      .anticon {
        color: ${({ theme }) => theme.color.white};
      }
    }
  }
`;
export const RefreshButton = styled(Button)`
  && {
    padding: 0;
    border: 0;
    height: fit-content;
    min-width: 25px;
    background-color: transparent;
    margin-left: 40px;
    font-weight: normal;

    .anticon {
      font-size: 14px;
      margin-right: 5px
      color: ${({ theme }) => theme.color.purple};
    }

    :hover,
    :active,
    :focus {
      background-color: transparent;
    }
  }
  
`;

export const StyledDescriptions = styled(Descriptions)<{ open?: boolean }>`
  background-color: ${({ theme, open }) =>
    open ? theme.color.success15 : theme.color.pearl40};
  margin-top: 7px;
  margin-bottom: 16px;
  padding: 16px;
  border: 1px solid
    ${({ theme, open }) => (open ? theme.color.success : theme.color.dark20)};
  box-sizing: border-box;
  border-radius: 4px;
  .ant-descriptions-item {
    padding-bottom: 0px;
  }
  .ant-descriptions-item-label {
    font-weight: bold;
  }
`;

export const StyledCompareDescriptions = styled(Descriptions)<{
  open?: boolean;
}>`
  background-color: ${({ theme, open }) =>
    open ? theme.color.success15 : theme.color.pearl40};
  margin-top: 16px;
  margin-bottom: 16px;
  padding: 16px;
  border: 1px solid #d2d1d8;
  box-sizing: border-box;
  border-radius: 4px;
  .ant-descriptions-item {
    padding-bottom: 0px;
  }
  .sub-title {
    font-weight: bold;
  }
`;

export const SuccessHighLight = css`
  background-color: ${({ theme }) => theme.color.success15};
`;

export const StyledRow = styled.div<{ highLight?: boolean }>`
  display: flex;
  height: 88px;
  align-items: center;
  padding-left: 20px;
  justify-content: space-between;
  ${({ highLight }) => highLight && SuccessHighLight};
`;

export const StyledText = styled.span`
  margin: 0;
  font-weight: bolder;
  font-size: 16px;
  line-height: 20px;
  color: ${({ theme }) => theme.color.textColor};
`;

export const StyleCol = styled.div`
  width: 24%;
  border-right: 1px solid ${({ theme }) => theme.color.dark20};
  :last-child {
    border-right: none;
  }
`;

export const Header = styled.div`
  height: 56px;
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.color.gray10};
  color: ${({ theme }) => theme.color.dark};
  font-size: 16px;
  font-weight: bold;
  padding-left: 20px;
  overflow: hidden;
  padding-right: 8px;
  justify-content: space-between;
  .anticon-star {
    svg {
      fill: ${({ theme }) => theme.color.gray2};
      margin-right: 10px;
    }
  }
`;

export const StyledTestingCenterForm = styled(Form)`
  margin-bottom: 16px !important;
  label {
    font-weight: 400 !important;
  }
  &.testrunmodal {
    font-size: 14px;
    .ant-form-item {
      margin-bottom: 16px !important;
    }
    .ant-form-item-label {
      min-height: 0;
      padding: 0px 0 4px;
      label {
        font-size: 14px;
      }
    }
    .ant-typography {
      color: ${({ theme }) => theme.color.dark80} !important;
    }
    textarea.ant-input {
      border-radius: 4px;
    }
  }

  .ant-typography.ant-typography-secondary {
    color: ${({ theme }) => theme.color.dark80} !important;
  }

  &.add_testcases_modal_form,
  &.upload_testbed_modal_form {
    font-size: 14px;
    .ant-form-item {
      margin-bottom: 19px !important;
    }
    .ant-form-item-label {
      min-height: 0;
      padding: 0 0 5px;
      label {
        font-size: 14px;
      }
    }
    textarea.ant-input {
      padding-top: 8px;
      padding-bottom: 8px;
      border-radius: 4px;
    }
  }
  .inputTitle {
    font-size: 14px;
    margin-bottom: 0px;
    color: ${({ theme }) => theme.color.dark};
    height: 32px;
    margin-top: 10px;
  }
  .user_alert {
    padding: 16px 0px 16px;
    text-align: center;
    font-size: 16px;
    font-weight: bold;
  }
  .ant-typography {
    color: ${({ theme }) => theme.color.dark80} !important;
  }
  .ant-form-item {
    margin-bottom: 16px !important;
  }
`;

export const TestingCenterCustomSelect = styled(CustomSelect)`
  &&.ant-select {
    width: 100%;
  }
`;

export const StyledTestbedProgressBar = styled(Progress)`
  width: 50%;
`;

export const StyledTestbedDetailsSearchInput = styled(SearchInput)`
  width: 295px !important;
  border: 1px solid ${({ theme }) => theme.color.gray2} !important;
  margin-left: 0px;
`;

export const StyledTestbedDetailLabel = styled.div`
  display: flex;
  line-height: 20px;
  font-size: 14px;
  line-height: 20px;
  word-break: break-all;
  .detailslabel {
    font-weight: bold;
    margin-right: 5px;
    margin-bottom: 5px;
  }
`;

export const StyledTestingCenterContainer = styled.div`
  border: 1px solid ${({ theme }) => theme.color.dark20};
  box-sizing: border-box;
  border-radius: 4px;
  background: ${({ theme }) => theme.color.white};
  padding: 32px;
  font-size: 14px;
  i {
    font-size: 14px;
  }
  .testrunbackground {
    color: ${({ theme }) => theme.color.purple};
  }
  .CaretDownFilled {
    color: ${({ theme }) => theme.color.purple} !important;
  }
  &.runtestbed {
    min-height: 560px;
  }
  &.uploadtestresults_modal {
    min-height: 500px;
  }
  &.add_testcases_modal,
  &.upload_testbed_modal {
    font-size: 14px;
    min-height: 500px;

    .user_alert {
      padding: 14px 0px 4px;
      text-align: center;
      font-size: 16px;
      font-weight: bold;
    }

    .testbeddetails {
      margin-top: 40px;
      margin-bottom: 8px;
      line-height: 16px;
    }
  }
  &.uploadtestresults_modal {
    .ant-form-item-label {
      padding: 0px;
      min-height: 24px;
      label {
        font-size: 14px;
        font-weight: 400 !important;
      }
    }

    .ant-form-item {
      margin-bottom: 16px !important;
    }
  }
  &.generate-testcases {
    label {
      font-weight: 400 !important;
    }
    font-size: 14px;
    .ant-form-item {
      margin-bottom: 16px !important;
    }
    .ant-form-item-label {
      min-height: 0;
      padding: 0px 0 4px;
      label {
        font-size: 14px;
      }
    }
    .ant-typography {
      color: ${({ theme }) => theme.color.dark80} !important;
    }
    textarea.ant-input {
      border-radius: 4px;
    }
  }
  .guide_text {
    display: flex;
    flex-direction: column;
    align-items: center;
    .title {
      font-size: 16px;
      font-weight: bold;
    }
    .desc {
      font-size: 14px;
    }
  }
  .download_file {
    display: flex;
    flex-direction: row;
    justify-content: center;
    .text {
      font-size: 14px;
      margin-right: 5px;
    }
    .download {
      font-size: 14px;
      color: ${({ theme }) => theme.color.purpleDarkened1};
      :hover {
        color: ${({ theme }) => theme.color.purple};
        text-decoration: underline;
      }
    }
  }
  .ant-card.upload-card {
    .ant-card-body {
      padding: 16px 0px 0px 0px !important;
    }
  }
  &.generate-testcases-form {
    max-height: calc(100vh - 370px);
    overflow-y: auto;
  }
`;
// TestingCenter TestingCenter

export const CompareEngineVersionModalScroll = styled.div`
  height: calc(65vh);
  overflow: auto;
  ${ScrollCss}
`;

export const StyledCodeSnippetSelect = styled(props => (
  <CustomSelect {...props} />
))`
  && {
    width: auto;
    min-width: 200px;
    .rc-virtual-list-holder {
      ${ScrollCss}
    }
  }
`;

export const StyledFilterBarPanel = styled.div`
  padding: 6px 30px;
  margin-bottom: 20px;
  background: ${({ theme }) => theme.color.pearl40};
`;
