import { v4 as uuidv4 } from 'uuid';

const download = (
  content: string,
  fileName: string,
  openInNewWindow = false
) => {
  const id = uuidv4();
  const temp = Object.assign(document.createElement('a'), {
    style: { display: 'none' },
    href: content,
    id,
  });
  if (fileName) {
    temp.download = fileName;
  }
  if (openInNewWindow === true) {
    temp.target = '_blank';
  }

  document.body.appendChild(temp);
  document.getElementById(id)?.click();
  document.getElementById(id)?.remove();
};

const downloadBlob = (
  blob: Blob,
  fileName: string,
  openInNewWindow = false
) => {
  download(window.URL.createObjectURL(blob), fileName, openInNewWindow);
};

const downloadString = (content: string, fileName: string) => {
  download(
    `data:text/plain;charset=utf-8,${encodeURIComponent(content)}`,
    fileName
  );
};

const downloadFile = (url: string, fileName: string) => {
  download(url, fileName);
};

const generateCSV = <T extends {}>(data: T[], fileName: string) => {
  const emptyHandler = (_, value) => (value == null ? '' : value);
  const headers = Object.keys(data[0]);
  const csv = data.map(entry =>
    headers.map(field => JSON.stringify(entry[field], emptyHandler)).join(',')
  );
  csv.unshift(headers.join(','));

  const blob = new Blob(['\ufeff', csv.join('\r\n')], { type: 'text/csv' });
  downloadBlob(blob, fileName);
};

export { downloadBlob, generateCSV, downloadFile, downloadString };
