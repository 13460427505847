/* eslint-disable @typescript-eslint/no-explicit-any */

import {
  ContentBlock,
  EditorState,
  ContentState,
  Modifier,
  RichUtils,
  SelectionState,
  genKey,
} from 'draft-js';

import { Map } from 'immutable';

import {
  MAX_LIST_DEPTH,
  MAX_INDENT_DEPTH,
} from '../constants/richTextEditor.constants';

const removeSizeDataFromBlock = (newEditorState, block) => {
  const data = block
    .getData()
    .delete('height')
    .delete('width');
  block = block.set('data', data);
  let contentState = newEditorState.getCurrentContent();
  let blockMap = contentState.getBlockMap();
  blockMap = blockMap.set(block.getKey(), block);
  contentState = contentState.set('blockMap', blockMap);
  newEditorState = EditorState.push(
    newEditorState,
    contentState,
    'change-block-data'
  );
  return newEditorState;
};

const handleTabInTable = (
  editorState: EditorState,
  onEditorStateChange: (value: EditorState) => void,
  direction = 'next',
  collapsed = false
) => {
  let newEditorState = editorState;
  let selection = editorState.getSelection();
  let contentState = editorState.getCurrentContent();
  let targetKey = selection.getAnchorKey();
  let targetBlock = contentState.getBlockForKey(targetKey);
  do {
    if (direction === 'next') {
      targetBlock = contentState.getBlockAfter(targetKey) as ContentBlock;
    } else {
      targetBlock = contentState.getBlockBefore(targetKey) as ContentBlock;
    }
    targetKey = targetBlock && targetBlock.getKey();
  } while (
    targetKey &&
    ['atomic', 'horizontal-rule'].includes(targetBlock.getType())
  );
  if (!targetBlock && direction === 'next') {
    selection = selection.merge({
      anchorOffset: contentState
        .getBlockForKey(selection.getAnchorKey())
        .getLength(),
      focusOffset: contentState
        .getBlockForKey(selection.getAnchorKey())
        .getLength(),
    });
    contentState = Modifier.splitBlock(contentState, selection);
    targetBlock = contentState.getLastBlock();
    selection = SelectionState.createEmpty(targetBlock.getKey());
    contentState = Modifier.setBlockType(contentState, selection, 'unstyled');
    targetBlock = contentState.getLastBlock();
    newEditorState = EditorState.push(editorState, contentState, 'split-block');
  } else if (!targetBlock) {
    targetBlock = contentState.getBlockForKey(selection.getAnchorKey());
  }
  const isTargetTable = targetBlock.getType() === 'table' && !collapsed;
  const endOffset = targetBlock.getLength();
  selection = SelectionState.createEmpty(targetBlock.getKey());
  selection = selection.merge({
    anchorOffset: isTargetTable || direction === 'next' ? 0 : endOffset,
    focusOffset: isTargetTable || direction === 'previous' ? endOffset : 0,
  });
  onEditorStateChange(EditorState.forceSelection(newEditorState, selection));
  return null;
};

const moveSelectionToStart = currentEditorState => {
  const content = currentEditorState.getCurrentContent();
  const firstBlock = content.getFirstBlock();
  const key = firstBlock.getKey();
  return EditorState.forceSelection(
    currentEditorState,
    SelectionState.createEmpty(key)
  );
};

const setSelectionIfNone = currentEditorState => {
  const selection = currentEditorState.getSelection();
  if (!selection.getHasFocus()) {
    return moveSelectionToStart(currentEditorState);
  }
  return currentEditorState;
};

const toggleInlineStyle = (inlineStyle, editorState, onEditorStateChange) => {
  let newEditorState = setSelectionIfNone(editorState);

  if (/^fontSize\.\d{1,2}$/.test(inlineStyle)) {
    inlineStyle += 'pt';
  }
  // if the new style is a compound style type (fontFamily, fontSize, color, or backgroundColor) and the current style includes the same type,
  // remove the current matching style type before turning on the new style.
  const existingMatch = newEditorState
    .getCurrentInlineStyle() // getCurrentInlineStyle() returns an Immutable OrderedSet
    .filter(
      style =>
        style.includes('.') && style.split('.')[0] === inlineStyle.split('.')[0]
    ) // compound styles are dot-delimited e.g. fontFamily.Arial
    .toList()
    .get(0);
  if (existingMatch) {
    newEditorState = RichUtils.toggleInlineStyle(newEditorState, existingMatch);
  }

  if (inlineStyle.endsWith('unset')) {
    onEditorStateChange(newEditorState);
  } else {
    onEditorStateChange(
      RichUtils.toggleInlineStyle(newEditorState, inlineStyle)
    );
  }
  return null;
};

const toggleListType = (listType, editorState, onEditorStateChange) => {
  if (RichUtils.getCurrentBlockType(editorState) === 'table') {
    return null;
  }
  const blockType =
    listType === 'BULLETLIST' ? 'unordered-list-item' : 'ordered-list-item';
  onEditorStateChange(RichUtils.toggleBlockType(editorState, blockType));
  return null;
};

const toggleBlockData = (newData, editorState, onEditorStateChange) => {
  const contentState = editorState.getCurrentContent();
  const selectionState = editorState.getSelection();
  const block = contentState.getBlockForKey(selectionState.getAnchorKey());
  let data = block.getData();
  Object.keys(newData).forEach(key => {
    if (data.get(key) === newData[key]) {
      data = data.remove(key);
    } else {
      data = data.merge(newData);
    }
  });
  const newContentState = Modifier.setBlockData(
    contentState,
    selectionState,
    data
  );
  onEditorStateChange(
    EditorState.push(editorState, newContentState, 'change-block-data')
  );
  return null;
};

const setIndent = (
  direction,
  newEditorState,
  onEditorStateChange,
  listMax = MAX_LIST_DEPTH,
  indentMax = MAX_INDENT_DEPTH,
  setDepth = 0
) => {
  const selectionState = newEditorState.getSelection();
  const contentState = newEditorState.getCurrentContent();
  const adjustment = direction === 'INDENT' ? 1 : -1;
  const startKey = selectionState.getStartKey();
  const endKey = selectionState.getEndKey();
  let blockMap = contentState.getBlockMap();
  const blocks = blockMap
    .toSeq()
    .skipUntil((_, k) => k === startKey)
    .takeUntil((_, k) => k === endKey)
    .concat([[endKey, blockMap.get(endKey)]])
    .map(block => {
      const depth = block?.getDepth() as number;
      const maxDepth = block?.getType().includes('list-item')
        ? listMax
        : indentMax;
      const newDepth =
        setDepth !== undefined
          ? setDepth
          : Math.max(0, Math.min(depth + adjustment, maxDepth));
      return block?.set('depth', newDepth);
    });

  blockMap = blockMap.merge(blocks);
  const newContentState = contentState.merge({
    blockMap,
    selectionBefore: selectionState,
    selectionAfter: selectionState,
  });
  newEditorState = EditorState.push(
    newEditorState,
    newContentState as ContentState,
    'adjust-depth'
  );
  onEditorStateChange(newEditorState);
  return null;
};

const handleKeypressWhenSelectionNotCollapsed = (
  newEditorState,
  onEditorStateChange,
  chars = ''
) => {
  let selection = newEditorState.getSelection();
  let content: any = newEditorState.getCurrentContent();
  const startKey = selection.getStartKey();
  const startBlock = content.getBlockForKey(startKey);
  const endKey = selection.getEndKey();
  const endBlock = content.getBlockForKey(endKey);
  const prevBlock = content.getBlockBefore(startKey);
  const nextBlock = content.getBlockAfter(endKey);
  const firstCell =
    startBlock.getType() === 'table' &&
    selection.getStartOffset() === 0 &&
    prevBlock?.getType() !== 'table';
  const firstTableKey = startBlock.getData().get('tableKey');
  const lastCell =
    endBlock.getType() === 'table' &&
    selection.getEndOffset() === endBlock.getLength() &&
    nextBlock?.getType() !== 'table';
  const lastTableKey = endBlock.getData().get('tableKey');
  const sameTable = firstTableKey === lastTableKey;

  if (
    startBlock === endBlock ||
    (startBlock.getType() !== 'table' && endBlock.getType() !== 'table')
  ) {
    return 'not-handled';
  }

  let blockMap: any = content.getBlockMap();
  const startOffset = selection.getStartOffset();
  const endOffset = selection.getEndOffset();
  let blocks: any = blockMap
    .toSeq()
    .skipUntil(v => v === startBlock)
    .takeUntil(v => v === nextBlock) // take up to but not including nextBlock
    .map(block => {
      let text;
      const type = block?.getType();
      if (block === startBlock) {
        text = block.getText().slice(0, startOffset) + chars;
        return block.set('text', type === 'table' ? text || ' ' : text);
      }
      if (block === endBlock) {
        text = block.getText().slice(endOffset, block.getLength());
        return block.set('text', type === 'table' ? text || ' ' : text);
      }
      return block?.set('text', type === 'table' ? ' ' : '');
    })
    .toOrderedMap();

  switch (true) {
    case startBlock.getType() !== 'table' && lastCell: // remove all selected blocks
    case firstCell && lastCell: // remove all selected blocks
      blockMap = blockMap.merge(blocks);
      blockMap = blockMap.filter(
        (block, key) =>
          !blocks.has(key) || (block.getType() !== 'table' && block.getText())
      );
      if (!blockMap.size) {
        const key = genKey();
        blockMap = blockMap.merge([
          [
            key,
            new ContentBlock({
              key,
              type: 'unstyled',
              text: '',
              data: Map(),
            }),
          ],
        ]);
      }
      break;
    case firstCell && endBlock.getType() !== 'table': {
      // remove all selected blocks, but preserve inline style/entities in partial block after selection
      content = Modifier.removeRange(content, selection, 'backward');
      blockMap = content.getBlockMap();
      const firstBlock = blockMap
        .first()
        .set('type', 'unstyled')
        .set('data', Map());
      blockMap = blockMap.merge([[firstBlock.getKey(), firstBlock]]);
      break;
    }
    case sameTable: {
      // clear cell contents in part of a table
      blocks = blocks.butLast();
      blockMap = blockMap.merge(blocks);
      let subSelection: any = SelectionState.createEmpty(endKey);
      subSelection = subSelection.merge({
        focusOffset: selection.getEndOffset(),
      });
      content = content.set('blockMap', blockMap);
      content = Modifier.removeRange(content, subSelection, 'backward');
      blockMap = content.getBlockMap();
      break;
    }
    case firstCell: {
      // remove selected blocks, but just clear contents of blocks matching lastTableKey
      const notLastTable = blocks.filter(
        block => block.getData().get('tableKey') !== lastTableKey
      );
      blockMap = blockMap.merge(blocks.butLast());
      blockMap = blockMap.filter((_, key) => !notLastTable.has(key));
      let subSelection = SelectionState.createEmpty(endKey);
      subSelection = subSelection.set(
        'focusOffset',
        selection.getEndOffset()
      ) as SelectionState;
      content = content.set('blockMap', blockMap);
      content = Modifier.removeRange(content, subSelection, 'backward');
      blockMap = content.getBlockMap();
      break;
    }
    case lastCell: {
      // clear contents of blocks matching firstTableKey & remove all other selected blocks
      const notFirstTable = blocks.filter(
        block => block.getData().get('tableKey') !== firstTableKey
      );
      blockMap = blockMap.merge(blocks);
      blockMap = blockMap.filter((_, key) => !notFirstTable.has(key));
      break;
    }
    case startBlock.getType() === 'table' &&
      endBlock.getType() === 'table' &&
      !sameTable: {
      // clear contents of firstTableKey & lastTableKey, & delete all blocks in between, but leave one empty block to separate the tables.
      const notTable = blocks.filter(block => !block.getData().get('tableKey'));
      const separatorBlock = notTable.first();
      blockMap = blockMap.merge(blocks.butLast());
      blockMap = blockMap.filter(
        (_, key) => !notTable.has(key) || key === separatorBlock.getKey()
      );
      blockMap = blockMap.merge([
        [separatorBlock.getKey(), separatorBlock.set('text', ' ')],
      ]);
      let subSelection: any = SelectionState.createEmpty(endKey);
      subSelection = subSelection.set('focusOffset', selection.getEndOffset());
      content = content.set('blockMap', blockMap);
      content = Modifier.removeRange(content, subSelection, 'backward');
      blockMap = content.getBlockMap();
      break;
    }
    case startBlock.getType() !== 'table' && endBlock.getType() === 'table': {
      //
      if (prevBlock?.getType() === 'table') {
        const separatorBlock = blocks.first().set('text', ' ');
        blocks = blocks.merge([[separatorBlock.getKey(), separatorBlock]]);
      }
      blockMap = blockMap.merge(blocks.butLast());
      blockMap = blockMap.filter(block => block.getLength());
      let subSelection: any = SelectionState.createEmpty(endKey);
      subSelection = subSelection.set('focusOffset', selection.getEndOffset());
      content = content.set('blockMap', blockMap);
      content = Modifier.removeRange(content, subSelection, 'backward');
      blockMap = content.getBlockMap();
      break;
    }
    default: {
      // clear contents of firstTableKey, delete other blocks
      const notTableStart = blocks
        .find(block => !block.getData().get('tableKey'))
        .getKey();
      const table = blocks.filter(
        block => block.getData().get('tableKey') === firstTableKey
      );
      blockMap = blockMap.merge(table);
      content = content.set('blockMap', blockMap);
      let subSelection = SelectionState.createEmpty(notTableStart);
      subSelection = subSelection.merge({
        focusKey: endKey,
        focusOffset: endOffset,
      });
      content = Modifier.removeRange(content, subSelection, 'backward');
      blockMap = content.getBlockMap();
    }
  }
  // create a new collapsed selection positioned where the former selection started
  const selectionKey = blockMap.has(startKey)
    ? startKey
    : blockMap.has(endKey)
    ? endKey
    : prevBlock?.getKey() || nextBlock?.getKey() || blockMap.first().getKey();
  selection = SelectionState.createEmpty(selectionKey);
  selection = selection.merge({
    anchorKey: selectionKey,
    anchorOffset: startOffset + chars.length,
    focusKey: selectionKey,
    focusOffset: startOffset + chars.length,
  });
  content = content.set('blockMap', blockMap) as ContentState;
  newEditorState = EditorState.push(newEditorState, content, 'remove-range');
  newEditorState = EditorState.forceSelection(newEditorState, selection);
  onEditorStateChange(newEditorState);
  return 'handled';
};

const handleDeleteKey = (editorState, onEditorStateChange) => {
  const selection = editorState.getSelection();
  const contentState = editorState.getCurrentContent();
  const startBlock = contentState.getBlockForKey(selection.getStartKey());
  const endBlock = contentState.getBlockForKey(selection.getEndKey());
  const nextBlock = contentState.getBlockAfter(endBlock.getKey());
  const endOffset = selection.getEndOffset();
  // prevent deleting entire table cell
  if (selection.isCollapsed()) {
    const length = startBlock.getLength();
    if (endOffset === length && nextBlock?.getType() === 'table') {
      return 'handled';
    }
    return 'not-handled';
  }
  return handleKeypressWhenSelectionNotCollapsed(
    editorState,
    onEditorStateChange
  );
};

const setAlignmentInTable = (alignment, content, blocks) => {
  // because cell style data is kept in the tableShape array stored with
  // the first block in the table, we have to update that information here
  let blockMap = content.getBlockMap();
  const tableKey = blocks
    .first()
    .getData()
    .get('tableKey');
  let firstTableBlock = blockMap.find(
    block => block.getData().get('tablePosition') === `${tableKey}-0-0`
  );
  const tableShape = firstTableBlock.getData().get('tableShape');
  blocks.forEach(block => {
    const rowcol = block
      .getData()
      .get('tablePosition')
      .split('-');
    const row = rowcol[1];
    const col = rowcol[2];

    if (tableShape[row] && tableShape[row][col] && tableShape[row][col].style) {
      tableShape[row][col].style = {
        ...tableShape[row][col]?.style,
        'text-align': alignment,
      };
    }
  });
  let data = firstTableBlock.getData();
  data = data.set('tableShape', tableShape);
  firstTableBlock = firstTableBlock.merge({ data });
  blockMap = blockMap.merge([[firstTableBlock.getKey(), firstTableBlock]]);
  content = content.merge({ blockMap });
  return content;
};

export {
  handleKeypressWhenSelectionNotCollapsed,
  removeSizeDataFromBlock,
  toggleInlineStyle,
  toggleListType,
  handleTabInTable,
  toggleBlockData,
  setIndent,
  handleDeleteKey,
  moveSelectionToStart,
  setAlignmentInTable,
};
