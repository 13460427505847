import { Action, applyMiddleware, combineReducers, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createTransform, persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunkMiddleware from 'redux-thunk';
import { EngineConstants } from '../constants';
import reducers from '../reducers';
import { initialAuthState } from '../reducers/auth.reducer';
import { getValidUserAuth } from './get-valid-user-auth';

const timeoutMiddleware = store => next => action => {
  if (action.type === EngineConstants.SET_HIGHLIGHT_DATA) {
    next(action);
    setTimeout(() => {
      store.dispatch({ type: EngineConstants.CLEAR_HIGHLIGHT_DATA });
    }, 5000);
  } else {
    next(action);
  }
};

const NODE_ENV = process.env.NODE_ENV;
const middleWares = [thunkMiddleware, timeoutMiddleware];
const rootReducer = combineReducers(reducers);

const authTransform = createTransform<STATES.AuthState, STATES.AuthState>(
  (inboundState, key) => {
    if (key === 'auth') {
      return {
        ...inboundState,
        isLoadingRules: false,
        isLoggingOut: false,
      };
    }

    return { ...inboundState };
  },
  (outboundState, key) => {
    if (key === 'auth') {
      return outboundState.userAuth &&
        getValidUserAuth(outboundState.userAuth).userAuth // Set to logged out state if user token expired
        ? { ...outboundState, changingPassword: false }
        : { ...initialAuthState };
    }

    return { ...outboundState };
  },
  {
    whitelist: ['auth'],
  }
);

const systemTransform = createTransform<STATES.SystemState, STATES.SystemState>(
  (inboundState, key) => {
    if (key === 'system') {
      return {
        ...inboundState,
        isLoading: false,
        isLoadingReleaseDetails: false,
        isLoadingLookupData: false,
      };
    }

    return { ...inboundState };
  },
  outboundState => {
    return { ...outboundState };
  }
);

export const rootPersistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth', 'user', 'language', 'system'],
  transforms: [authTransform, systemTransform],
};

export const store = createStore(
  persistReducer<ReturnType<typeof rootReducer>, Action>(
    rootPersistConfig,
    rootReducer
  ),
  NODE_ENV !== 'production'
    ? composeWithDevTools(applyMiddleware(...middleWares))
    : applyMiddleware(...middleWares)
);

export const persistor = persistStore(store);
