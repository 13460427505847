export const PAGETITLE_FOLDER_CATALOGUE = 'Home';
export const PAGETITLE_OPTIONS_FILE_MANAGER = 'Files';
export const PAGETITLE_OPTIONS_SERVICES = 'Service console';
export const PAGETITLE_OPTIONS_USERS = 'Users';
export const PAGETITLE_OPTIONS_USERS_GROUP = 'User groups';
export const PAGETITLE_OPTIONS_USAGE_DETAILS = 'Usage details';
export const PAGETITLE_OPTIONS_INSIGHTS = 'Insights';
export const PAGETITLE_CATEGORIES = 'Categories';
export const PAGETITLE_OPTIONS_TEMPLATES = 'Templates';
export const PAGETITLE_OPTIONS_API_KEYS = 'API keys';
export const PAGETITLE_OPTIONS_CONFIGURATION_FILES = 'Configuration files';
export const PAGETITLE_OPTIONS_INGENIUM = 'Ingenium configuration';
export const PAGETITLE_OPTIONS_SDK_GENERATOR = 'SDK generator';
export const PAGETITLE_SPARK_PRODUCT = 'Spark';
export const PAGETITLE_OPTION_TENANT_CONFIGURATION = 'Tenant configuration';
