import { useSelector, useDispatch } from 'react-redux';
import { useCallback } from 'react';
import { LanguageActions } from '../actions';

const mapStateToProps = ({
  language: { currentLanguage, intl },
  system: { appConfig },
}: STATES.AppState) => ({
  currentLanguage,
  intl,
  appConfig,
});

const useLanguage = () => {
  const dispatch = useDispatch();

  const { currentLanguage, intl, appConfig } = useSelector(mapStateToProps);

  const setLanguage = useCallback(
    async lang => {
      if (lang === currentLanguage) {
        return;
      }

      await dispatch(LanguageActions.loadMessages(lang));
      if (appConfig['LanguageMessages'][lang]) {
        dispatch(
          LanguageActions.appendMessages(appConfig['LanguageMessages'][lang])
        );
      }
    },
    [dispatch, currentLanguage, appConfig]
  );

  return { setLanguage, language: currentLanguage, intl };
};

export { useLanguage };
