import { Layout, Menu } from 'antd';
import React, { useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { Logo, ScrollCss } from '../Common';

const { Item, ItemGroup } = Menu;
const { Sider } = Layout;

const SideBarMenuWrapper = styled(Sider)`
  && {
    ${ScrollCss}
    ::-webkit-scrollbar {
      background-color: transparent;
    }
    z-index: 6;
    overflow: auto;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;

    background: ${({ theme }) => theme.color.pearl60};
    .ant-menu {
      background: ${({ theme }) => theme.color.pearl60};
      border-top-width: 0;
      margin-top: 5px;
      font-size: 16px;
      .ant-menu-item {
        margin: 0;
        transition: unset;
        a {
          transition: unset;
          color: ${({ theme }) => theme.color.dark};
          font-size: 16px;
        }
      }
    }

    .ant-menu-item:hover {
      background-color: ${({ theme }) => theme.color.gray10};
      color: ${({ theme }) => theme.color.dark};
      a {
        color: ${({ theme }) => theme.color.dark};
      }
    }

    .ant-menu-item:active,
    .menu-item-selected:not(ant-menu-item:hover) {
      background-color: ${({ theme }) => theme.color.purpleDarkened2};
      color: ${({ theme }) => theme.color.white};
      a {
        color: ${({ theme }) => theme.color.white};
      }
    }

    .ant-menu-item-group {
      margin-bottom: 50px;
      .ant-menu-item-group-title {
        padding: 0 8px 0 16px !important;
        color: ${({ theme }) => theme.color.dark};
        font-size: 16px;
        font-weight: bold;
      }

      .ant-menu-item-group-list {
        .ant-menu-item {
          padding: 0 8px 0 24px !important;
        }
      }
    }
  }
`;

const LogoContainer = styled.div`
  background-color: ${({ theme }) => theme.vars.gray6};
  padding: 18px 28px;
  height: 100px;
  line-height: 3.6;
`;

export interface SideMenuItem {
  to: string;
  title: string;
  permission?: boolean;
  disabled?: boolean;
}

export interface SideMenuGroup {
  key: string;
  groupName: string;
  isNormalUser?: boolean;
  groups: SideMenuItem[];
}

interface LayoutSideMenuProps {
  menuItems: SideMenuGroup[];
  customImageURL?: string;
  isLogoImageLoading?: boolean;
}

const LayoutSideMenu: React.FC<LayoutSideMenuProps> = ({
  menuItems,
  customImageURL,
  isLogoImageLoading,
}) => {
  const location = useLocation();
  const intl = useIntl();
  const sidebarMenuWrapperRef = useRef<HTMLDivElement>(null);

  const renderItems = (menuGroup: SideMenuGroup[]) => {
    return menuGroup.map(
      ({ groups, key, groupName, isNormalUser }) =>
        !isNormalUser && (
          <ItemGroup key={key} title={intl.formatMessage({ id: groupName })}>
            {groups.map(
              ({ title, to, permission, disabled }) =>
                permission && (
                  <Item
                    className={
                      location.pathname.startsWith(to)
                        ? 'menu-item-selected'
                        : ''
                    }
                    disabled={disabled}
                    key={title}
                  >
                    <Link
                      to={{
                        pathname: to,
                      }}
                    >
                      <span>
                        <span>
                          <FormattedMessage id={title} />
                        </span>
                      </span>
                    </Link>
                  </Item>
                )
            )}
          </ItemGroup>
        )
    );
  };
  return (
    <>
      <SideBarMenuWrapper width={215}>
        <LogoContainer>
          <Link to="/">
            <Logo
              type="CoherentSparkV1WhiteBgColoredLogo"
              height="40px"
              src={customImageURL}
              isLoading={isLogoImageLoading ?? false}
            />
          </Link>
        </LogoContainer>
        <Menu
          mode="vertical"
          selectable
          selectedKeys={[location.pathname]}
          getPopupContainer={() =>
            sidebarMenuWrapperRef.current || document.body
          }
        >
          {renderItems(menuItems)}
        </Menu>
      </SideBarMenuWrapper>
    </>
  );
};

export default LayoutSideMenu;
