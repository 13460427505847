export const GET_DIAGNOSE_DETAILS_REQUEST = 'GET_DIAGNOSE_DETAILS_REQUEST';
export const GET_DIAGNOSE_DETAILS_SUCCESS = 'GET_DIAGNOSE_DETAILS_SUCCESS';
export const GET_DIAGNOSE_DETAILS_FAILURE = 'GET_DIAGNOSE_DETAILS_FAILURE';

export const GET_RELEASE_DETAILS_REQUEST = 'GET_RELEASE_DETAILS_REQUEST';
export const GET_RELEASE_DETAILS_SUCCESS = 'GET_RELEASE_DETAILS_SUCCESS';
export const GET_RELEASE_DETAILS_FAILURE = 'GET_RELEASE_DETAILS_FAILURE';

export const GET_LOOKUP_DATA_REQUEST = 'GET_LOOKUP_DATA_REQUEST';
export const GET_LOOKUP_DATA_SUCCESS = 'GET_LOOKUP_DATA_SUCCESS';
export const GET_LOOKUP_DATA_FAILURE = 'GET_LOOKUP_DATA_FAILURE';

export const GET_PRODUCT_DOCUMENT_METADATA_REQUEST =
  'GET_PRODUCT_DOCUMENT_METADATA_REQUEST';
export const GET_PRODUCT_DOCUMENT_METADATA_SUCCESS =
  'GET_PRODUCT_DOCUMENT_METADATA_SUCCESS';
export const GET_PRODUCT_DOCUMENT_METADATA_FAILURE =
  'GET_PRODUCT_DOCUMENT_METADATA_FAILURE';

export const CLEAR_CACHE_MEMORY_REQUEST = 'CLEAR_CACHE_MEMORY_REQUEST';
export const CLEAR_CACHE_MEMORY_SUCCESS = 'CLEAR_CACHE_MEMORY_SUCCESS';
export const CLEAR_CACHE_MEMORY_FAILURE = 'CLEAR_CACHE_MEMORY_FAILURE';

export const CLEAR_APPLICATION_MEMORY_REQUEEST =
  'CLEAR_APPLICATION_MEMORY_REQUEEST';
export const CLEAR_APPLICATION_MEMORY_SUCCESS =
  'CLEAR_APPLICATION_MEMORY_SUCCESS';
export const CLEAR_APPLICATION_MEMORY_FAILURE =
  'CLEAR_APPLICATION_MEMORY_FAILURE';

export const GET_APPCONFIG_REQUEST = 'GET_APPCONFIG_REQUEST';
export const GET_APPCONFIG_SUCCESS = 'GET_APPCONFIG_SUCCESS';
export const GET_APPCONFIG_FAILURE = 'GET_APPCONFIG_FAILURE';

export const UPDATE_BANNER_VISIBLITY = 'UPDATE_BANNER_VISIBLITY';

export const SHORT_DATE_FORMAT = 'YYYY-MM-DD';
export const SHORT_DATE_TIME_FORMAT = 'YYYY-MM-DD, h:mm A';
export const SHORT_DATE_TIME_FORMAT_SEMANTIC = 'YYYY-MM-DD, hh:mm A';
export const SHORT_DATE_TIME_FORMAT_WITHOUT_MERIDIEM = 'YYYY-MM-DD, h:mm';
export const FULL_DATE_TIME_FORMAT = 'YYYY-MM-DD, HH:mm:ss';
export const BASE_DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';
export const UTC_DATE_TIME_FORMAT = 'YYYY-MM-DDTHH:mm:ss.SSS[Z]';

export const GET_CUSTOMBRANDINGIMAGE_REQUEST = 'GET_CUSTOMBRANDINGIMAGE_REQUEST';
export const GET_CUSTOMBRANDINGIMAGE_SUCCESS = 'GET_CUSTOMBRANDINGIMAGE_SUCCESS';
export const GET_CUSTOMBRANDINGIMAGE_FAILURE = 'GET_CUSTOMBRANDINGIMAGE_FAILURE';
