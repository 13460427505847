import moment from 'moment';
import { getConfig } from '../helpers/config';
import makeRequest from '../helpers/make-request';
import uploadRequest from '../helpers/upload-request';

const generateTemplate = (
  testbedTemplateRequest: DTO.TestbedTemplateRequest
) => {
  const url = `${
    getConfig().excelEngineDomain
  }/api/v1/testbedtemplate/generate/${moment(new Date()).utcOffset()}`;

  return makeRequest<DTO.GenerateTestCasesResponse>(
    'POST',
    url,
    testbedTemplateRequest
  );
};

const getTestbeds = (testbedPagedRequest: DTO.TestbedPagedRequest) => {
  const url = `${
    getConfig().excelEngineDomain
  }/api/v1/testbed/ListWithFavourite`;

  const search: { field: string; value: string }[] = [];

  if (
    testbedPagedRequest.startDate &&
    testbedPagedRequest.startDate.length > 0
  ) {
    search.push({ field: 'startDate', value: testbedPagedRequest.startDate });
  }

  if (testbedPagedRequest.endDate && testbedPagedRequest.endDate.length > 0) {
    search.push({ field: 'endDate', value: testbedPagedRequest.endDate });
  }

  if (testbedPagedRequest.name && testbedPagedRequest.name.length > 0) {
    search.push({ field: 'name', value: testbedPagedRequest.name });
  }

  if (testbedPagedRequest.isFavourite) {
    search.push({ field: 'favourite', value: 'true' });
  }

  if (
    testbedPagedRequest.productName &&
    testbedPagedRequest.productName.length > 0
  ) {
    search.push({
      field: 'productName',
      value: testbedPagedRequest.productName,
    });
  }

  if (
    testbedPagedRequest.serviceName &&
    testbedPagedRequest.serviceName.length > 0
  ) {
    search.push({
      field: 'serviceName',
      value: testbedPagedRequest.serviceName,
    });
  }

  const sort = `${testbedPagedRequest.sort === 'desc' ? '-' : ''}${
    testbedPagedRequest.sortBy
  }`;
  return makeRequest<DTO.TestbedPagedResponse>('POST', url, {
    page: testbedPagedRequest.page,
    pageSize: testbedPagedRequest.pageSize,
    sort,
    search,
  });
};

const getTestbedQueue = (testbedQueueId: string) => {
  const url = `${
    getConfig().excelEngineDomain
  }/api/v1/testbedqueue/get/${testbedQueueId}`;
  return makeRequest<{
    data: DTO.TestbedQueueProgress;
  }>('GET', url);
};

const saveTestbedQueue = (testbedQueueRequest: DTO.TestbedQueueRequest) => {
  const url = `${getConfig().excelEngineDomain}/api/v1/testbedqueue/save`;

  return makeRequest<{
    data: string;
  }>('POST', url, testbedQueueRequest);
};

const getTestbedResult = (testbedQueueId: string) => {
  const url = `${
    getConfig().excelEngineDomain
  }/api/v1/testbedqueue/getresult/${testbedQueueId}`;
  return makeRequest<{
    data: DTO.TestbedResultResponse;
  }>('GET', url);
};

const downloadTestResult = (testRunId: string, fileType: string) => {
  const url = `${
    getConfig().excelEngineDomain
  }/api/v1/testbedqueue/SaveTestbedResultQueue/${testRunId}/${fileType}/${moment(
    new Date()
  ).utcOffset()}`;
  return makeRequest<{
    data: string;
  }>('POST', url);
};

const removeTestbed = (testbedId: string) => {
  const url = `${
    getConfig().excelEngineDomain
  }/api/v1/testbed/delete/${testbedId}`;
  return makeRequest('DELETE', url);
};

const getTestbedById = (folder: string, serviceName: string, id: string) => {
  const url = `${
    getConfig().excelEngineDomain
  }/api/v1/testbed/GetTestbedById/${folder}/${serviceName}/${id}`;
  return makeRequest<{
    data: DTO.Testbed;
  }>('GET', url);
};

const getTestRunsByTesbedId = (
  folder: string,
  service: string,
  testbedId: string,
  request: DTO.PagedRequest
) => {
  const url = `${
    getConfig().excelEngineDomain
  }/api/v1/testrun/getTestRunsByTesbedId/${folder}/${service}/${testbedId}`;
  return makeRequest<{ data: DTO.PagedResponse<DTO.TestbedVersion> }>(
    'POST',
    url,
    request
  );
};

const deleteTestRun = (testRunId: string) => {
  const url = `${
    getConfig().excelEngineDomain
  }/api/v1/testrun/delete/${testRunId}`;

  return makeRequest('DELETE', url);
};

const uploadTestbed = (
  file: File | undefined,
  testbedRequest: DTO.TestBedRequest,
  onUploadProgress: (precent: number) => void,
  xhrRef: (xhr: XMLHttpRequest) => void
) => {
  const url = `${getConfig().excelEngineDomain}/api/v1/testbed/AddTestbed`;
  const formData = new FormData();

  file && formData.append('testbedfile', file);
  testbedRequest.name && formData.append('name', testbedRequest.name);
  testbedRequest.description &&
    formData.append('description', testbedRequest.description);
  testbedRequest.testbedId &&
    formData.append('testbedId', testbedRequest.testbedId);
  formData.append('IsDraftStatus', 'true');
  testbedRequest.productName &&
    formData.append('productName', testbedRequest.productName);
  testbedRequest.serviceName &&
    formData.append('serviceName', testbedRequest.serviceName);

  return uploadRequest<{ data: DTO.UploadTestbedResult }>(
    'POST',
    url,
    formData,
    onUploadProgress,
    xhrRef
  );
};

const generateCompareResult = (
  sourceTestRunId: string,
  targetTestRunId: string
) => {
  const url = `${
    getConfig().excelEngineDomain
  }/api/v1/testrun/DownloadCompareResult/${sourceTestRunId}/${targetTestRunId}/${moment(
    new Date()
  ).utcOffset()}`;
  return makeRequest<DTO.GenerateTestCasesResponse>('GET', url);
};

const updateTestbed = (
  testbedVersionId: string,
  request: DTO.UpdateTestbedItemRequest[]
) => {
  const url = `${
    getConfig().excelEngineDomain
  }/api/v1/testbed/updatetestbed/${testbedVersionId}`;

  return makeRequest<{
    data: string;
  }>('POST', url, request);
};

const queueSystematicGenerateTestCases = (
  request_data: DTO.RequestV3<
    DTO.SystematicGenerateTestCasesRequestData,
    DTO.SystematicGenerateTestCasesRequestMeta
  >
) => {
  const config = getConfig();
  const tenant = localStorage.getItem('Tenant');
  const url = `${getConfig().excelEngineDomain}/${tenant ||
    config.defaultTenant}/api/v3/testbed/generate/systematic`;
  return makeRequest<DTO.ResponseV3<{ job_id: string }>>(
    'POST',
    url,
    request_data
  );
};

const queueGenerateTestCases = (
  productName: string,
  serviceName: string,
  versionId: string,
  data: DTO.GenerateTestCasesRequest
) => {
  const url = `${
    getConfig().excelEngineDomain
  }/api/v1/testbed/generate/${productName}/${serviceName}/${versionId}/queue`;

  return makeRequest<{
    data: string;
  }>('POST', url, data);
};

const getGenerateTestcasesStatus = (taskQueueId: string) => {
  const url = `${
    getConfig().excelEngineDomain
  }/api/v1/testbed/generate/getstatus/${taskQueueId}`;
  return makeRequest<{ data: DTO.TestbedQueueProgress }>('GET', url);
};

const downloadTestBedTestCases = (
  testbedId: string,
  type: string,
  source?: string,
  SubServiceName?: string
) => {
  const url = `${
    getConfig().excelEngineDomain
  }/api/v1/testbed/DownloadTestBedJob/${testbedId}/${type}/${source}/${moment(
    new Date()
  ).utcOffset()}`;
  return makeRequest<{ data: string }>('POST', url, { SubServiceName });
};

const cancelTestbedQueue = (testbedQueueId: string) => {
  const url = `${
    getConfig().excelEngineDomain
  }/api/v1/testbedqueue/cancel/${testbedQueueId}`;
  return makeRequest('GET', url);
};

const publishTestbed = (request: DTO.PublishTestbedRequest) => {
  const url = `${getConfig().excelEngineDomain}/api/v1/testbed/PublishTestBed`;
  return makeRequest('POST', url, request);
};

const cancelDraftTestbed = (versionId: string) => {
  const url = `${
    getConfig().excelEngineDomain
  }/api/v1/testbed/CancelTestBedDraft/testbedversion/${versionId}`;
  return makeRequest('POST', url);
};

const isTestbedExists = (folder: string, service: string, name: string) => {
  const url = `${
    getConfig().excelEngineDomain
  }/api/v1/testbed/IsTestBedNameExists/${folder}/${service}/${name}`;
  return makeRequest('POST', url);
};

const isTestRunNameExists = (
  testbedId: string,
  testRunName: string,
  testRunId?: string
) => {
  const testRunIdUrl = testRunId ? `/${testRunId}` : '';
  const url = `${
    getConfig().excelEngineDomain
  }/api/v1/testrun/IsTestRunNameExists/${testbedId}/${testRunName}${testRunIdUrl}`;
  return makeRequest('GET', url);
};

const favouriteTestbed = (id: string, isFavourite: boolean) => {
  const url = `${
    getConfig().excelEngineDomain
  }/api/v1/testbeduserpreference/save`;
  return makeRequest('POST', url, { testbedId: id, isFavourite });
};

const uploadTestbedTestcases = (
  file: File | undefined,
  testbedRequest: DTO.UploadTestbedTestcases,
  onUploadProgress: (precent: number) => void,
  xhrRef: (xhr: XMLHttpRequest) => void
) => {
  const url = `${
    getConfig().excelEngineDomain
  }/api/v1/testbed/AddTestbedTestCases`;
  const formData = new FormData();
  file && formData.append('testbedfile', file);
  testbedRequest.TestbedId &&
    formData.append(
      'testbedRequest',
      JSON.stringify({
        TestbedId: testbedRequest.TestbedId,
        productName: testbedRequest.productName,
        serviceName: testbedRequest.serviceName,
      })
    );

  return uploadRequest<{ data: DTO.UpdateTestbedFileuploadResult }>(
    'POST',
    url,
    formData,
    onUploadProgress,
    xhrRef
  );
};

const publishTestbedTestcases = (
  request: DTO.PublishTestbedTestcasesRequest
) => {
  const url = `${
    getConfig().excelEngineDomain
  }/api/v1/testbed/PublishTestBedTestCases`;

  return makeRequest<{
    data: string;
  }>('POST', url, request);
};

const uploadTestrunResults = (
  file: File | undefined,
  testRunRequest: DTO.AddTestRunUploadRequest,
  onUploadProgress: (precent: number) => void,
  xhrRef: (xhr: XMLHttpRequest) => void
) => {
  const url = `${
    getConfig().excelEngineDomain
  }/api/v1/testrun/AddTestRun/${moment(new Date()).utcOffset()}`;

  const formData = new FormData();
  file && formData.append('file', file);

  formData.append('testRunRequest', JSON.stringify(testRunRequest));

  return uploadRequest<{ data: DTO.AddTestRunUploadResponse }>(
    'POST',
    url,
    formData,
    onUploadProgress,
    xhrRef
  );
};

const updateTestrunResults = (request: DTO.UpdateTestrunResultsRequest) => {
  const url = `${getConfig().excelEngineDomain}/api/v1/testrun/update`;

  return makeRequest<{
    data: string;
  }>('POST', url, request);
};

const getCompareResult = (sourceTestRunId: string, targetTestRunId: string) => {
  const url = `${
    getConfig().excelEngineDomain
  }/api/v1/testrun/GetCompareResult/${sourceTestRunId}/${targetTestRunId}/true`;
  return makeRequest<{
    data: DTO.TestCompareResultResponse;
  }>('GET', url);
};

const queueCompareRun = (sourceTestRunId: string, targetTestRunId: string) => {
  const url = `${
    getConfig().excelEngineDomain
  }/api/v1/testrun/QueueCompareRun/${sourceTestRunId}/${targetTestRunId}`;

  return makeRequest<{
    data: {
      isExists: boolean;
      taskQueueId: string;
    };
  }>('POST', url);
};

const getCompareStatus = (taskQueueId: string) => {
  const url = `${
    getConfig().excelEngineDomain
  }/api/v1/testrun/GetCompareStatus/${taskQueueId}`;
  return makeRequest<{ data: DTO.TestbedQueueProgress }>('GET', url);
};

const getTestrunById = (testrunId: string) => {
  const config = getConfig();
  const tenant = localStorage.getItem('Tenant');
  const url = `${getConfig().excelEngineDomain}/${tenant ||
    config.defaultTenant}/api/v3/testrun/GetTestRunByTestbedQueueId/${testrunId}`;
  return makeRequest<DTO.ResponseV3<DTO.TestRun>>('GET', url);
};

const getTestCaseDetails = (testRunId: string, testCaseId: string) => {
  const config = getConfig();
  const tenant = localStorage.getItem('Tenant');
  const url = `${getConfig().excelEngineDomain}/${tenant ||
    config.defaultTenant}/api/v3/testrun/${testRunId}/testrunitem/${testCaseId}`;
  return makeRequest<DTO.ResponseV3<DTO.TestRun>>('GET', url);
};

export const TestbedService = {
  generateTemplate,
  getTestbeds,
  getTestbedQueue,
  saveTestbedQueue,
  getTestbedResult,
  removeTestbed,
  uploadTestbed,
  generateCompareResult,
  updateTestbed,
  queueGenerateTestCases,
  queueSystematicGenerateTestCases,
  getGenerateTestcasesStatus,
  downloadTestBedTestCases,
  cancelTestbedQueue,
  downloadTestResult,
  publishTestbed,
  cancelDraftTestbed,
  isTestbedExists,
  favouriteTestbed,
  getTestbedById,
  getTestRunsByTesbedId,
  deleteTestRun,
  uploadTestbedTestcases,
  publishTestbedTestcases,
  isTestRunNameExists,
  uploadTestrunResults,
  updateTestrunResults,
  getCompareResult,
  queueCompareRun,
  getCompareStatus,
  getTestrunById,
  getTestCaseDetails,
};
