import { Location } from 'history';
import React, { useEffect, useState } from 'react';
import { Prompt, useLocation } from 'react-router-dom';
import { CustomModal } from '../Common';

interface Props {
  when?: boolean | undefined;
  isClosable?: boolean | undefined;
  navigate: (path: string) => void;
  shouldBlockNavigation?: (location: Location) => boolean;
  children?: (props: {
    [key: string]: () => void;
  }) => React.ReactNode | React.ReactNode[];
}
const RouteLeavingGuard = ({
  when,
  isClosable,
  navigate,
  shouldBlockNavigation,
  children,
}: Props) => {
  const pathname = useLocation().pathname;
  const [modalVisible, setModalVisible] = useState(false);
  const [lastLocation, setLastLocation] = useState<Location | null>(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);
  const closeModal = () => {
    setModalVisible(false);
  };
  useEffect(() => {
    setLastLocation(null);
    setConfirmedNavigation(false);
  }, [pathname]);
  const handleBlockedNavigation = (nextLocation: Location): boolean => {
    if (
      !confirmedNavigation &&
      shouldBlockNavigation &&
      shouldBlockNavigation(nextLocation)
    ) {
      setModalVisible(true);
      setLastLocation(nextLocation);
      return false;
    }
    return true;
  };
  const handleConfirmNavigationClick = () => {
    setModalVisible(false);
    setConfirmedNavigation(true);
  };

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      navigate(lastLocation.pathname);
    }
  }, [confirmedNavigation, lastLocation]);

  useEffect(() => {
    if (when) {
      window.onbeforeunload = (): boolean => {
        return false;
      };
    }
    return (): void => {
      if (when) window.onbeforeunload = null;
    };
  }, [when]);
  return (
    <>
      <Prompt when={when} message={handleBlockedNavigation} />
      <CustomModal
        wrapClassName="RouteLeavingGuard"
        centered
        visible={modalVisible}
        closable={isClosable ?? false}
        width={660}
        onOk={handleConfirmNavigationClick}
        onCancel={closeModal}
        maskClosable
        content={
          children &&
          children({
            handleConfirmNavigationClick,
            closeModal,
          })
        }
        keyboard
      />
    </>
  );
};
export { RouteLeavingGuard };
