import { TemplateManagerConstants } from '../constants';
import { ApiError, downloadBlob } from '../helpers';
import { FileManagerService, TemplateManagerService } from '../services';
import { AlertActions } from './alert.actions';
import { AppAction, TemplateManagerThunkAction } from './types';

const getAll = (
  data: DTO.GetTemplateRequest
): TemplateManagerThunkAction => async dispatch => {
  try {
    dispatch({
      type: TemplateManagerConstants.GET_TEMPLATES_REQUEST,
      payload: { ...data },
    });

    const { payload, status } = await TemplateManagerService.getTemplates(data);

    if (status !== 200 || payload.status !== 'Success') {
      throw new ApiError(payload);
    }

    dispatch({
      type: TemplateManagerConstants.GET_TEMPLATES_SUCCESS,
      payload: {
        ...data,
        templates: payload?.data,
        favouriteTemplates: payload?.data,
        total: payload?.count,
      },
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: TemplateManagerConstants.GET_TEMPLATES_FAILURE,
      payload: { error: msg },
    });
  }
};

const deleteTemplate = (
  template: DTO.Template,
  isStarred: boolean
): TemplateManagerThunkAction => async dispatch => {
  try {
    const { id } = template;
    dispatch({
      type: TemplateManagerConstants.DELETE_TEMPLATE_REQUEST,
      payload: {
        id,
      },
    });

    const { status, payload } = await TemplateManagerService.deleteTemplate(id);

    if (status !== 200 || payload.status !== 'Success') {
      throw new ApiError(payload);
    }

    dispatch({
      type: TemplateManagerConstants.DELETE_TEMPLATE_SUCCESS,
      payload: {
        id,
        isStarred,
      },
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: TemplateManagerConstants.DELETE_TEMPLATE_FAILURE,
      payload: { error: msg },
    });
  }
};

const updateTemplateFavourite = (
  templateName: string,
  favorite: boolean
): TemplateManagerThunkAction => async dispatch => {
  try {
    dispatch({
      type: TemplateManagerConstants.UPDATE_TEMPLATE_FAVORITE_REQUEST,
    });

    const {
      status,
      payload,
    } = await TemplateManagerService.markTemplateFavorite(
      templateName,
      favorite
    );

    if (status !== 200 || payload.status !== 'Success') {
      throw new ApiError(payload);
    }

    dispatch({
      type: TemplateManagerConstants.UPDATE_TEMPLATE_FAVORITE_SUCCESS,
      payload: {
        templateName,
        favorite,
      },
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: TemplateManagerConstants.UPDATE_TEMPLATE_FAVORITE_FAILURE,
      payload: {
        error: msg,
      },
    });
  }
};

const downloadTemplate = (
  templateId: string
): TemplateManagerThunkAction => async () => {
  const downloadUrl = TemplateManagerService.getTemplateDownloadUrl(templateId);
  const blob = await FileManagerService.downloadBlob(downloadUrl);
  if (blob.payload.blob) {
    downloadBlob(blob.payload.blob, blob.payload.blobName);
  }
};

const saveTemplate = (
  template: DTO.CreateTemplateRequest
): TemplateManagerThunkAction<string | null> => async dispatch => {
  try {
    dispatch({ type: TemplateManagerConstants.CREATE_TEMPLATE_REQUEST });

    const { status, payload } = await TemplateManagerService.createTemplate(
      template
    );

    if (status !== 200 || payload.status === 'Error') {
      throw new ApiError(payload);
    }

    dispatch({
      type: TemplateManagerConstants.CREATE_TEMPLATE_SUCCESS,
      payload: { selectedTemplateName: template.templateName },
    });

    dispatch(
      AlertActions.success('TemplateManager.submit.add.success', {
        template: template.templateName,
      })
    );

    return template.templateName;
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: TemplateManagerConstants.CREATE_TEMPLATE_FAILURE,
      payload: {
        error: msg,
      },
    });

    return null;
  }
};

const selectTemplate = (template: DTO.Template) => ({
  type: TemplateManagerConstants.SELECT_TEMPLATE,
  payload: { template },
});

const updateTemplate = (
  templateId: string,
  template: DTO.UpdateTemplateRequest
): TemplateManagerThunkAction<string | null> => async dispatch => {
  try {
    dispatch({ type: TemplateManagerConstants.UPDATE_TEMPLATE_REQUEST });

    const { status, payload } = await TemplateManagerService.updateTemplate(
      templateId,
      template
    );

    if (status !== 200 || payload.status === 'Error') {
      throw new ApiError(payload);
    }

    dispatch({
      type: TemplateManagerConstants.UPDATE_TEMPLATE_SUCCESS,
      payload: {
        selectedTemplateName: template.templateName || templateId,
        selectedTemplate: null,
      },
    });

    dispatch(
      AlertActions.success('TemplateManager.submit.edit.success', {
        template: template.templateName,
      })
    );

    return template.templateName;
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: TemplateManagerConstants.UPDATE_TEMPLATE_FAILURE,
      payload: {
        error: msg,
      },
    });

    return null;
  }
};

const resetTemplate = (): AppAction => ({
  type: TemplateManagerConstants.SELECTED_TEMPLATE_RESET,
});

export const TemplateManagerActions = {
  getAll,
  deleteTemplate,
  updateTemplateFavourite,
  downloadTemplate,
  saveTemplate,
  updateTemplate,
  resetTemplate,
  selectTemplate,
};
