import { getConfig } from '../helpers/config';
import { getbaseUrl } from '../helpers/generic';
import makeRequest from '../helpers/make-request';
import uploadRequest from '../helpers/upload-request';

const checkServiceExists = async (
  folderName: string,
  serviceName: string,
  fileNameTruncated?: string | null,
  newAbortController?: AbortController
) => {
  let url = `${getbaseUrl() || ''}folders/${encodeURIComponent(
    folderName
  )}/services/${encodeURIComponent(serviceName)}/exists`;
  if (fileNameTruncated) {
    url += `?truncatedText=${fileNameTruncated}`;
  }
  return makeRequest<DTO.ResponseV3<DTO.FileServicesCheckServiceExists>>(
    'GET',
    url,
    undefined,
    undefined,
    undefined,
    { signal: newAbortController?.signal }
  );
};

const uploadService = async (
  folderName: string,
  serviceName: string,
  file: File,
  data: any,
  onUploadProgress?: (uploadPercent: number) => void,
  xhrRef?: (xhr: XMLHttpRequest) => void
) => {
  const url = `${getbaseUrl() || ''}folders/${encodeURIComponent(
    folderName
  )}/services/${encodeURIComponent(serviceName)}/upload`;
  const formData = new FormData();
  formData.append('serviceFile', file);
  formData.append(
    'engineUploadRequestEntity',
    JSON.stringify({
      request_data: data,
    })
  );
  return uploadRequest<DTO.ResponseV3<DTO.FileServicesUploadService>>(
    'POST',
    url,
    formData,
    onUploadProgress,
    xhrRef
  );
};

const getUploadLogs = (
  folder: string,
  service: string,
  isRecompile = false
) => {
  let url = `${getbaseUrl() || ''}folders/${encodeURIComponent(
    folder
  )}/services/${encodeURIComponent(service)}/getuploadlogs`;
  if (isRecompile) {
    url += '/false';
  }
  return makeRequest<DTO.ResponseV3<DTO.UploadLogs>>('GET', url);
};

const getCompilationProgess = (
  folderName: string,
  serviceName: string,
  nodegenCompilationJobid: string
) => {
  const url = `${getbaseUrl() || ''}folders/${encodeURIComponent(
    folderName
  )}/services/${encodeURIComponent(
    serviceName
  )}/getcompilationprogess/${nodegenCompilationJobid}`;
  return makeRequest<DTO.ResponseV3<DTO.NodegenCompilationDetails>>('GET', url);
};

const publishService = (
  folderName: string,
  serviceName: string,
  request: any
) => {
  const url = `${getbaseUrl() || ''}folders/${encodeURIComponent(
    folderName
  )}/services/${encodeURIComponent(serviceName)}/publish`;
  return makeRequest<DTO.ResponseV3<DTO.PublishServiceDetails>>('POST', url, {
    request_data: request,
  });
};

const updateService = (
  folderName: string,
  serviceName: string,
  request: DTO.UpdateServiceRequest
) => {
  const url = `${getbaseUrl() || ''}folders/${encodeURIComponent(
    folderName
  )}/services/${encodeURIComponent(serviceName)}/update`;
  return makeRequest<DTO.ResponseV3<DTO.UpdateServiceResponse>>('PUT', url, {
    request_data: request,
  });
};

const cancelPublish = (product: string, service: string, queueId: string) => {
  const config = getConfig();
  const tenant = localStorage.getItem('Tenant');
  const url = `${getConfig().excelEngineDomain}/${tenant ||
    config.defaultTenant}/api/v3/folders/${product}/services/${service}/cancel/${queueId}`;

  return makeRequest('DELETE', url);
};

const serviceFileService = {
  uploadService,
  checkServiceExists,
  getUploadLogs,
  getCompilationProgess,
  publishService,
  updateService,
  cancelPublish,
};
export { serviceFileService };
