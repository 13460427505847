import React, { memo, ReactNode, NamedExoticComponent, useRef } from 'react';
import { Collapse } from 'antd';
import { CollapseProps, CollapsePanelProps } from 'antd/lib/collapse';
import styled from 'styled-components';
import { colorHexToRgba } from '../../../helpers/styled-color-opacity';

const { Panel } = Collapse;

interface Panels extends CollapsePanelProps {
  view: ReactNode | null;
}

export interface CustomCollapseProps extends CollapseProps {
  className?: string;
  panels: Panels[];
  onChange?: (key: string | string[]) => void;
  activeKey?: string | string[];
}

const StyledCollapse = styled(Collapse)`
  &&.ant-collapse {
    border-radius: 8px;
    background-color: ${({ theme }) => theme.color.gray10};

    .ant-collapse-item:last-child,
    .ant-collapse-item:last-child > .ant-collapse-header {
      border-radius: 0 0 8px 8px;
    }

    .ant-collapse-item:last-child > .ant-collapse-content {
      border-radius: 0 0 8px 8px;
    }

    .ant-collapse-content {
      background-color: ${({ theme }) => theme.color.pearl40};
    }

    .ant-collapse-header {
      font-size: 16px;
      font-weight: bold;
      color: ${({ theme }) => colorHexToRgba(theme.color.black, 0.8)};
      .anticon.anticon-right.ant-collapse-arrow {
        font-size: 14px;
        margin-right: 8px;
        font-weight: bold;
        color: ${({ theme }) => theme.color.purple};
      }
    }

    .ant-collapse-item-disabled > .ant-collapse-header,
    .ant-collapse-item-disabled > .ant-collapse-header > .arrow {
      color: ${({ theme }) => colorHexToRgba(theme.color.black, 0.8)};
      cursor: auto;
    }
  }
`;

const CustomCollapse: NamedExoticComponent<CustomCollapseProps> = memo(
  ({ className, panels, onChange, activeKey, ...rest }) => {
    const containerRef = useRef<HTMLDivElement>(null);

    const OnActivePanelChange = (activeKeyArray: string | string[]) => {
      onChange && onChange(activeKeyArray as string[]);
    };

    return (
      <div ref={containerRef}>
        <StyledCollapse
          {...rest}
          className={`${className} ${activeKey || ''} customePanels`}
          onChange={OnActivePanelChange}
        >
          {Object.values(panels).map(({ view, ...restPanelProps }) => {
            return <Panel {...restPanelProps}>{view}</Panel>;
          })}
        </StyledCollapse>
      </div>
    );
  }
);

export { CustomCollapse };
