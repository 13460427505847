import { Checkbox } from 'antd';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import React, { memo, NamedExoticComponent } from 'react';
import styled from 'styled-components';

const StyledCheckbox = styled(props => <Checkbox.Group {...props} />)`
  .ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled) {
    padding: 10px 20px !important;
  }
`;

export interface CustomCheckboxItem {
  label: string;
  value: string;
}

export interface CustomCheckboxProps {
  className?: string;
  items: CustomCheckboxItem[];
  values?: string[];
  onChange?: (key: CheckboxValueType[]) => void;
  disabled?: boolean;
}

const CustomCheckbox: NamedExoticComponent<CustomCheckboxProps> = memo(
  ({ className, items, onChange, values, disabled = false }) => {
    return (
      <StyledCheckbox
        className={className}
        options={items}
        value={values}
        onChange={onChange}
        disabled={disabled}
      />
    );
  }
);

export { CustomCheckbox };
