import React, {
  NamedExoticComponent,
  memo,
  useCallback,
  ChangeEventHandler,
  RefObject,
} from 'react';
import { Input } from 'antd';
import { InputProps } from 'antd/lib/input';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { CloseCircleFilled, SearchOutlined } from '@ant-design/icons';

interface SearchInputProps extends Omit<InputProps, 'onChange'> {
  searchInputRef?: RefObject<Input>;
  onChange?: (value: string) => void;
  prefix?: boolean;
  customPrefix?: React.ReactNode;
  hideClearAll?: boolean;
  alwaysShowPrefix?: boolean;
}

const StyledInput = styled(props => <Input {...props} />)`
  && {
    width: 300px;
    margin-right: 10px;
    border-radius: 4px;

    .ant-input {
      border: none !important;
      height: revert;
      padding: 0 !important;
      font-size: 14px;
    }

    &.ant-input-affix-wrapper .ant-input:not(:last-child) {
      padding-right: 40px;
      padding-left: 38px;
    }

    .anticon {
      font-size: 16px;
      color: ${({ theme }) => theme.color.purple};

      &.close-circle {
        font-size: 14px;
        svg {
          color: ${({ theme }) => theme.color.gray2};
        }
      }
    }
  }
  .ant-input-affix-wrapper-disabled {
    border: 1px solid red !important;
  }
`;

const SearchInput: NamedExoticComponent<SearchInputProps> = memo(
  ({
    onChange,
    value,
    placeholder,
    searchInputRef,
    customPrefix,
    hideClearAll,
    alwaysShowPrefix = false,
    prefix,
    disabled,
    ...rest
  }) => {
    const intl = useIntl();
    const onClear = useCallback(() => {
      onChange && onChange('');
      searchInputRef &&
        searchInputRef.current &&
        searchInputRef.current.focus();
    }, [onChange, searchInputRef]);

    const onInputChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
      e => {
        onChange && onChange(e.target.value);
      },
      [onChange]
    );

    const suffixRender = suffixValue => {
      if (suffixValue) {
        if (!hideClearAll) {
          return (
            <CloseCircleFilled className="close-circle" onClick={onClear} />
          );
        }
      }
      if (!prefix) {
        return <SearchOutlined />;
      }
      return <></>;
    };

    return (
      <StyledInput
        {...rest}
        disabled={disabled}
        ref={searchInputRef}
        placeholder={placeholder ? intl.formatMessage({ id: placeholder }) : ''}
        onChange={onInputChange}
        value={value}
        allowClear={false}
        prefix={
          prefix &&
          (!value || alwaysShowPrefix) &&
          (customPrefix || <SearchOutlined />)
        }
        suffix={suffixRender(value)}
      />
    );
  }
);

export { SearchInput };
