export const MAX_INDENT_DEPTH = 20;
export const MAX_LIST_DEPTH = 4;
export const COLORS = [
  '#000000',
  '#FFFFFF',
  '#888888',
  '#AAAAAA',
  '#EEEEEE',
  '#880000',
  '#CC0000',
  '#FF0000',
  '#FFCCCC',
  '#FF8800',
  '#FFCC00',
  '#FFFF00',
  '#CCFF00',
  '#88FF00',
  '#008800',
  '#00CC00',
  '#00CC88',
  '#00CCCC',
  '#CCEEFF',
  '#00CCFF',
  '#0088FF',
  '#0000FF',
  '#8800FF',
  '#CC00CC',
  '#CC0088',
];
export const FONTS = [
  'Calibri',
  'Comfortaa',
  'EB Garamond',
  'Merriweather',
  'Oswald',
  'Poiret One',
  'Roboto Mono',
].sort();
export const FONT_SIZES = [10, 12, 14, 20, 28];

export const defaultPreTagStyling = [
  ['padding', '9.5px'],
  ['margin', '0 0 10px'],
  ['border', '1px solid rgb(204, 204, 204)'],
  ['background', 'rgb(245, 245, 245)'],
];

export const availableAlignments = [
  { type: 'left', display: { icon: 'AlignLeft', chevron: false } },
  { type: 'center', display: { icon: 'AlignCenter', chevron: false } },
  { type: 'right', display: { icon: 'AlignRight', chevron: false } },
];

export const availableBlockTypes = [
  { type: 'unstyled', display: { name: 'normal' }, tag: 'div' },
  { type: 'paragraph', display: { name: 'paragraph' }, tag: 'p' },
  {
    type: 'unordered-list-item',
    display: {
      name: ' bullet list',
      style: { fontSize: '14px' },
      icon: 'UnOrderedList',
    },
  },
  {
    type: 'ordered-list-item',
    display: {
      name: ' number list',
      style: { fontSize: '14px' },
      icon: 'OrderedList',
    },
  },
  {
    type: 'horizontal-rule',
    display: { name: 'h-rule', style: { fontSize: '14px' } },
    tag: 'hr',
  },
  {
    type: 'page-break',
    display: { name: 'page-break', style: { fontSize: '14px' } },
    tag: 'div',
  },
  {
    type: 'header-one',
    display: { name: 'H1', style: { fontSize: '26px', fontWeight: '700' } },
    tag: 'h1',
  },
  {
    type: 'header-two',
    display: { name: 'H2', style: { fontSize: '24px', fontWeight: '700' } },
    tag: 'h2',
  },
  {
    type: 'header-three',
    display: { name: 'H3', style: { fontSize: '18.72px', fontWeight: '700' } },
    tag: 'h3',
  },
  {
    type: 'header-four',
    display: { name: 'H4', style: { fontSize: '16px', fontWeight: '700' } },
    tag: 'h4',
  },
  {
    type: 'header-five',
    display: { name: 'H5', style: { fontSize: '13.3', fontWeight: '700' } },
    tag: 'h5',
  },
  {
    type: 'header-six',
    display: { name: 'H6', style: { fontSize: '12px', fontWeight: '700' } },
    tag: 'h6',
  },
  {
    type: 'blockquote',
    display: {
      name: 'blockquote',
      style: {
        fontFamily: "'Hoefler Text', Georgia, serif",
        fontStyle: 'italic',
      },
    },
    tag: 'blockquote',
  },
  {
    type: 'code-block',
    display: { name: 'monospace', style: { fontFamily: 'monospace' } },
    tag: 'pre',
  },
];

export const availableColors = (() => {
  const colors: {
    type: string;
    display: {
      name: string;
    };
  }[] = [];
  ['color', 'backgroundColor'].forEach(style => {
    COLORS.forEach(color => {
      colors.push({
        type: `${style}.${color}`,
        display: { name: color },
      });
    });
  });
  return colors;
})();

export const availableFonts = (() => {
  return FONTS.map(font => {
    return {
      type: `fontFamily.${font}`,
      display: { name: font, style: { fontFamily: font } },
    };
  });
})();

export const availableFontSizes = (() => {
  const Title = ['SMALL', 'Paragraph 1', 'Paragraph 2', 'Headline', 'Large'];
  return FONT_SIZES.map((size, index) => {
    return {
      type: `fontSize.${size}`,
      display: { name: size, title: Title[index], style: { fontSize: size } },
    };
  });
})();

const dropdownControls = {
  alignment: {
    activeOption: (alignment = 'left') =>
      availableAlignments.find(a => a.type === alignment),
    controlWidth: 40,
    dropdownWidth: 40,
    list: availableAlignments,
    method: 'listDropdown',
  },
  blockType: {
    activeOption: blockType =>
      availableBlockTypes.find(b => b.type === blockType),
    controlWidth: 90,
    dropdownWidth: 135,
    list: availableBlockTypes,
    method: 'listDropdown',
  },
  color: {
    activeOption: () => ({ display: { icon: 'FontColor', chevron: false } }),
    controlWidth: 40,
    dropdownWidth: 280,
    list: availableColors,
    method: 'listDropdown',
    className: 'FontColor',
    tooltip: 'ApiTester.documentation.editor.tooltip.textColor',
  },
  fontFamily: {
    activeOption: font =>
      availableFonts.find(f => f.display.name === font) || {
        display: {
          name: 'ApiTester.documentation.editor.default.fontFamily',
          style: { fontStyle: '' },
          chevron: true,
        },
      },
    controlWidth: 130,
    dropdownWidth: 155,
    list: font => [
      ...availableFonts.map(f => {
        return {
          ...f,
          display: { ...f.display, active: f.display.name === font },
        };
      }),
    ],
    method: 'listDropdown',
    className: 'FontFamily',
    tooltip: 'ApiTester.documentation.editor.tooltip.font',
  },
  fontSize: {
    activeOption: fontSize =>
      availableFontSizes.find(size => size.display.name === fontSize) || {
        display: { name: '', icon: 'FontSize' },
      },
    controlWidth: 40,
    dropdownWidth: 65,
    list: size => [
      ...availableFontSizes.map(s => {
        return {
          ...s,
          display: {
            ...s.display,
            active: s.display.name === Number(size.split('pt')[0]),
          },
        };
      }),
    ],
    method: 'listDropdown',
    className: 'FontSize',
    tooltip: 'ApiTester.documentation.editor.tooltip.fontSize',
  },
  insertImage: {
    activeOption: () => ({ display: { icon: 'image-sld' } }),
    controlWidth: 40,
    dropdownWidth: 400,
    icon: 'Error',
    method: 'formDropdown',
  },
  insertLink: {
    activeOption: () => ({ display: { icon: 'LinkUrl' } }),
    controlWidth: 40,
    dropdownWidth: 400,
    icon: 'LinkUrl',
    method: 'formDropdown',
    className: 'InsertLink',
    tooltip: 'ApiTester.documentation.editor.tooltip.addLink',
  },
  insertTable: {
    activeOption: () => ({ display: { icon: 'Error' } }),
    controlWidth: 40,
    dropdownWidth: 160,
    icon: 'Error',
    method: 'tableDropdown',
  },
};

export const availableControls = {
  ...dropdownControls,
  bold: {
    callback: 'inlineToggle',
    icon: 'Bold',
    method: 'controlButton',
    tooltip: 'ApiTester.documentation.editor.tooltip.bold',
  },
  bulletList: {
    callback: 'listToggle',
    icon: 'UnOrderedList',
    method: 'controlButton',
    tooltip: 'ApiTester.documentation.editor.tooltip.bulletList',
  },
  floatRight: {
    callback: 'blockDataToggle',
    icon: 'Error',
    method: 'controlButton',
    tooltip: 'Citadel.organisms.richEditor.tooltips.floatRight',
  },
  indent: {
    callback: 'indentChange',
    icon: 'Error',
    method: 'controlButton',
    tooltip: 'Citadel.organisms.richEditor.tooltips.indent',
  },
  italic: {
    callback: 'inlineToggle',
    icon: 'Italic',
    method: 'controlButton',
    tooltip: 'ApiTester.documentation.editor.tooltip.italic',
  },
  numberList: {
    callback: 'listToggle',
    icon: 'OrderedList',
    method: 'controlButton',
    tooltip: 'ApiTester.documentation.editor.tooltip.numberedList',
  },
  outdent: {
    callback: 'indentChange',
    icon: 'Error',
    method: 'controlButton',
    tooltip: 'Citadel.organisms.richEditor.tooltips.outdent',
  },
  spacer: {
    method: 'spacer',
  },
  strikethrough: {
    callback: 'inlineToggle',
    icon: 'Error',
    method: 'controlButton',
  },
  underline: {
    callback: 'inlineToggle',
    icon: 'Underline',
    method: 'controlButton',
    tooltip: 'ApiTester.documentation.editor.tooltip.underline',
  },
  'alignment-left': {
    callback: 'alignmentSelect',
    icon: 'AlignLeft',
    method: 'controlButton',
    tooltip: 'ApiTester.documentation.editor.tooltip.left',
  },
  'alignment-center': {
    callback: 'alignmentSelect',
    icon: 'AlignCenter',
    method: 'controlButton',
    tooltip: 'ApiTester.documentation.editor.tooltip.center',
  },
  'alignment-right': {
    callback: 'alignmentSelect',
    icon: 'AlignRight',
    method: 'controlButton',
    tooltip: 'ApiTester.documentation.editor.tooltip.right',
  },
  removeLink: {
    callback: 'breakLink',
    icon: 'UnLinkUrl',
    method: 'controlButton',
    className: 'RemoveLink',
    tooltip: 'ApiTester.documentation.editor.tooltip.breakLink',
  },
  code: {
    callback: 'blockTypeSelect',
    icon: 'Code',
    method: 'controlButton',
    className: 'Code-Block',
    tooltip: 'ApiTester.documentation.editor.tooltip.insertSnippet',
  },
  viewHtml: {
    callback: 'editModeToggle',
    icon: 'Code',
    method: 'controlButton',
  },
};

const commonControls = [
  'fontFamily',
  'fontSize',
  'bold',
  'italic',
  'underline',
  'color',
  'alignment-left',
  'alignment-center',
  'alignment-right',
  'numberList',
  'bulletList',
  'insertLink',
  'removeLink',
  'code',
];

export const controlSets = {
  basic: ['bold', 'italic', 'underline', 'bulletList', 'numberList'],
  default: [...commonControls],
  withImages: [...commonControls, 'insertImage', 'viewHtml'],
};

export const Keys = {
  B: 66,
  Backspace: 8,
  Delete: 127,
  E: 69,
  Enter: 13,
  I: 73,
  J: 74,
  L: 76,
  R: 82,
  T: 84,
  Tab: 9,
  U: 85,
  '[': 219,
  ']': 221,
};
