import { getConfig } from '../helpers';
import makeRequest from '../helpers/make-request';

const saveDeploymentRequest = (
  data: DTO.NodegenApiRequestEntity<DTO.DeploymentDataRequest>
) => {
  const tenant = localStorage.getItem('Tenant');
  const obj = {
    request_data: data,
  };
  const url = `${getConfig().excelEngineDomain}/${tenant ||
    getConfig().defaultTenant}/api/v3/deployment/request/save`;
  return makeRequest<DTO.NodegenApiResponse<boolean, DTO.ResponseMeta>>(
    'POST',
    url,
    obj
  );
};

export const DeploymentService = {
  saveDeploymentRequest,
};
