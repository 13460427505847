export const GET_COUNTER_DEFINITIONS_REQUEST =
  'GET_COUNTER_DEFINITIONS_REQUEST';
export const GET_COUNTER_DEFINITIONS_SUCCESS =
  'GET_COUNTER_DEFINITIONS_SUCCESS';
export const GET_COUNTER_DEFINITIONS_FAILURE =
  'GET_COUNTER_DEFINITIONS_FAILURE';

export const GET_AGGREGATED_DATA_REQUEST = 'GET_AGGREGATED_DATA_REQUEST';
export const GET_AGGREGATED_DATA_SUCCESS = 'GET_AGGREGATED_DATA_SUCCESS';
export const GET_AGGREGATED_DATA_FAILURE = 'GET_AGGREGATED_DATA_FAILURE';

export const GET_TOTAL_BY_COUNTER_REQUEST = 'GET_TOTAL_BY_COUNTER_REQUEST';
export const GET_TOTAL_BY_COUNTER_SUCCESS = 'GET_TOTAL_BY_COUNTER_SUCCESS';
export const GET_TOTAL_BY_COUNTER_FAILURE = 'GET_TOTAL_BY_COUNTER_FAILURE';

export const GET_NEXT_MEASURES_REQUEST = 'GET_NEXT_MEASURES_REQUEST';
export const GET_NEXT_MEASURES_SUCCESS = 'GET_NEXT_MEASURES_SUCCESS';
export const GET_NEXT_MEASURES_FAILURE = 'GET_NEXT_MEASURES_FAILURE';

export const GET_TENANTS_REQUEST = 'GET_TENANTS_REQUEST';
export const GET_TENANTS_SUCCESS = 'GET_TENANTS_SUCCESS';
export const GET_TENANTS_FAILURE = 'GET_TENANTS_FAILURE';

export const GET_TOP_METRICS_REQUEST = 'GET_TOP_METRICS_REQUEST';
export const GET_TOP_METRICS_SUCCESS = 'GET_TOP_METRICS_SUCCESS';
export const GET_TOP_METRICS_FAILURE = 'GET_TOP_METRICS_FAILURE';

export const INCREMENT_COUNTER_REQUEST = 'INCREMENT_COUNTER_REQUEST';
export const INCREMENT_COUNTER_SUCCESS = 'INCREMENT_COUNTER_SUCCESS';
export const INCREMENT_COUNTER_FAILURE = 'INCREMENT_COUNTER_FAILURE';

export const GET_INSIGHT_FOLDER_ENGINE_REQUEST =
  'GET_INSIGHT_FOLDER_ENGINE_REQUEST';
export const GET_INSIGHT_FOLDER_ENGINE_SUCCESS =
  'GET_INSIGHT_FOLDER_ENGINE_SUCCESS';
export const GET_INSIGHT_FOLDER_ENGINE_FAILURE =
  'GET_INSIGHT_FOLDER_ENGINE_FAILURE';

export const SPARK_APICALL_COUNTER_DEF_NAME = 'Spark.ApiCalls';
export const COUNTERS_WITH_NEXT_MEASURE = [
  'Spark.ApiCalls'
];
export const FOLDER_SELECT_HIDDEN_COUNTERDEFINITIONS = [
  'Spark.FoldersAdded',
  'Spark.OtherTenantFoldersAdded',
];
export const SERVICE_SELECT_HIDDEN_COUNTERDEFINITIONS = [
  'Spark.FoldersAdded',
  'Spark.OtherTenantFoldersAdded',
  'Spark.FoldersUpdated',
  'Spark.OtherTenantFoldersUpdated',
  'Spark.ServicesAdded',
  'Spark.OtherTenantServicesAdded',
];
export const DECIMAL_VALUE_COUNTERDEFINITIONS = [
  'Spark.TestBedRunsCalcTime',
  'Spark.OtherTenantTestBedRunsCalcTime',
  'Spark.ApiCallsCalcTime',
  'Spark.OtherTenantApiCallsCalcTime',
  'Spark.ApiCallsTotalTime',
  'Spark.OtherTenantApiCallsTotalTime',
  'Spark.BatchCalcTime',
  'Spark.OtherTenantBatchCalcTime',
];
