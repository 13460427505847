import { IntlShape } from 'react-intl';

const getLeftTime = bytes => {
  const mbByte = bytes / (1024 * 1024);
  const perMB = (mbByte / 5) * 5.6;
  const timeInSec = 9.5 + perMB;
  const finalTime = timeInSec * 1000;
  return finalTime;
};

const getUploadLogs = (state, updateState) => {
  const newArr = {
    warnings: (state.warnings || []).concat(updateState.warnings || []),
    errors: (state.errors || []).concat(updateState.errors || []),
    infos: (state.infos || []).concat(updateState.infos || []),
    tests: (state.tests || []).concat(updateState.tests || []),
    tips: (state.tests || []).concat(updateState.tips || []),
    nodeGenCompilerResult:
      updateState.nodegenCompilationResult ?? state.nodegenCompilationResult,
  };
  return newArr as DTO.UploadLogs;
};

const getUploadWarnings = (
  warnings: { key: string; value: string[] }[],
  intl: IntlShape
) => {
  const tmpWarning: DTO.UploadLogItem[] = [];
  warnings
    ? warnings.map(({ key, value }) => {
        if (key === 'INVALID_BYCHANGING_CELL_FORMULA') {
          value.map(message =>
            tmpWarning.push({
              key,
              values: message.split(',')[0],
              otherString: message.split(',')[1],
            })
          );
        } else if (
          key === 'INVALID_DATA_VALIDATION' ||
          key === 'INPUT_NAMERANGE_NAME_COLLISION' ||
          key === 'OUTPUT_NAMERANGE_NAME_COLLISION'
        ) {
          value.map(message =>
            tmpWarning.push({
              key,
              values: message.split('@!@')[0],
              otherString: message.split('@!@')[1],
            })
          );
        } else if (key === 'ENGINE_FORMULA_UNSUPPORTED') {
          tmpWarning.push({ key, values: [value.toString()] });
        } else if (key === 'API_REFERENCE_FOUND') {
          const apiValues =
            value &&
            value.map(message =>
              intl.formatMessage(
                {
                  id: 'API_REFERENCE_FOUND_DATA',
                },
                {
                  values: message,
                }
              )
            );
          tmpWarning.push({
            key: 'API_REFERENCE_FOUND',
            values: [apiValues.toString()],
          });
        } else if (key === 'ENGINE_RANGE_OVERLAPPING') {
          const rangeValues =
            value &&
            value.map(message =>
              intl.formatMessage(
                {
                  id: 'ENGINE_RANGE_OVERLAPPING_DATA',
                },
                {
                  values: message,
                }
              )
            );
          tmpWarning.push({
            key: 'ENGINE_RANGE_OVERLAPPING',
            values: [rangeValues.toString()],
          });
        } else {
          tmpWarning.push({ key, values: value });
        }
      })
    : [];

  return tmpWarning;
};

const checkCountDiff = (lastCount, newCount, diffObj, keyName) => {
  const countObj: {
    type: 'add' | 'remove' | 'neutral';
    count: number;
  } = { type: 'neutral', count: 0 };
  if (lastCount < newCount) {
    countObj['type'] = 'add';
    countObj['count'] = newCount - lastCount;
  } else if (lastCount > newCount) {
    countObj['type'] = 'remove';
    countObj['count'] = lastCount - newCount;
  } else {
    countObj['type'] = 'neutral';
    countObj['count'] = 0;
  }
  if (newCount !== lastCount) {
    diffObj['isDifference'] = true;
  }
  diffObj[keyName] = countObj;
  return diffObj;
};

const checkDifference = (
  lastVersionObj: DTO.FileServicesUploadService,
  newVersionObj: DTO.FileServicesUploadService
) => {
  const diff: DTO.ShowVersionDiff = {};
  checkCountDiff(
    lastVersionObj.no_of_sheets,
    newVersionObj.no_of_sheets,
    diff,
    'noOfSheets'
  );
  checkCountDiff(
    lastVersionObj.no_of_inputs,
    newVersionObj.no_of_inputs,
    diff,
    'noOfInputs'
  );
  checkCountDiff(
    lastVersionObj.no_of_outputs,
    newVersionObj.no_of_outputs,
    diff,
    'noOfOutputs'
  );
  checkCountDiff(
    lastVersionObj.no_of_formulas,
    newVersionObj.no_of_formulas,
    diff,
    'noOfFormulas'
  );
  checkCountDiff(
    lastVersionObj.no_of_cellswithdata,
    newVersionObj.no_of_cellswithdata,
    diff,
    'noOfCellswithdata'
  );

  return diff;
};

const getClassName = (uploadLogs, progressPercent) => {
  let className = '';
  if (progressPercent === 100) {
    if (uploadLogs?.warnings.length && uploadLogs?.errors.length) {
      className = 'warnings-margin-multi';
    } else if (uploadLogs?.warnings.length) {
      className = 'warnings-margin-bottom';
    } else if (uploadLogs?.errors.length) {
      className = 'warnings-margin-error';
    }
  }
  return className;
};

export {
  getLeftTime,
  getUploadLogs,
  getUploadWarnings,
  checkDifference,
  getClassName,
};
