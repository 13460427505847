import { Modal } from 'antd';
import React, { FC } from 'react';
import styled, { createGlobalStyle, css } from 'styled-components';
import { LoadingIndicator } from '../LoadingIndicator';
import { ScrollCss } from '../ScrollContainer';

const StyledContent = styled.div<{
  scrollable?: boolean;
  scrollableHeight?: number;
}>`
  ${({ scrollable }) => (scrollable ? ScrollCss : null)}
  height: ${({ scrollable, scrollableHeight }) =>
    scrollable ? `${scrollableHeight ?? 500}px` : 'auto'};
`;

const StyledModal = styled(props => <Modal {...props} />)<{
  width?: number | string;
}>`
  width: ${({ width }) => width || 'auto'};
  border-radius: 8px !important;
  padding-bottom: 0px !important;
  padding: 16px 24px 24px 24px;
  .ant-modal-content {
    background-color: ${props => props.theme.color.pearl40};
    border-radius: 8px !important;

    .ant-modal-close-x {
      font-size: 24px;
      color: ${({ theme }) => theme.color.black};
      height: 56px;
      width: 56px;
      line-height: 56px;
    }

    .ant-modal-body {
      .ant-form {
        .ant-row {
          &.ant-form-item {
            margin-bottom: 12px;
            padding-bottom: 12px;

            .ant-btn-link > .anticon {
              color: ${props => props.theme.color.purple};
            }

            .ant-col {
              .ant-form-item-control {
                .ant-form-item-children {
                  display: flex;
                  align-items: center;
                }
              }
            }

            &:nth-last-child(2),
            &:last-child {
              margin-bottom: 0;
              padding-bottom: 0;
              border-bottom: none;
            }

            &.header {
              .ant-typography {
                margin-bottom: 0;
                padding-top: 20px;
                display: flex;
                align-items: center;

                .anticon {
                  font-size: 35px;
                  margin-right: 10px;
                }
              }

              .ant-col {
                .ant-form-item-control {
                  .ant-form-item-children {
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-start;
                  }
                }
              }

              .close {
                font-size: 20px;
                padding: 0;
                margin: 0;
                height: auto;
                line-height: 1;
              }
            }

            &.footer {
              display: flex;
              justify-content: center;
              align-items: center;
              padding-top: 10px;
            }
          }
        }
      }
    }
  }

  .ant-modal-header {
    padding: 32px 0 0 0;
    background: transparent;
    text-align: center;
    border-bottom: none;

    .ant-modal-title {
      font-size: 20px;
      color: ${({ theme }) => theme.color.dark};
      color: ${({ theme }) => theme.color.dark};
      font-size: 24px !important;
      font-weight: normal;
      line-height: 1.2;

      .modal-title-icon {
        font-size: 50px;
        color: ${({ theme }) => theme.color.purple};
      }
    }
  }

  .ant-modal-footer {
    padding: 0px 32px 24px 32px;
    text-align: center;
    border-top: none;
  }
`;

const scrollBarPaddingCss = css`
  body {
    padding-right: 17px;
  }
`;
const scrollBarHiddenCss = css`
  body {
    overflow: hidden;
  }
`;
const StyledModalBackground = createGlobalStyle<{
  theme: STATES.ThemeState;
  isScroll: boolean;
  visible: boolean;
}>`
${({ visible, isScroll }) => visible && isScroll && scrollBarPaddingCss}
${({ visible }) => visible && scrollBarHiddenCss}
`;

type CustomModalPropsSize = 'small' | 'default' | 'large' | 'xlarge';
export interface CustomModalProps {
  className?: string;
  centered?: boolean;
  closable?: boolean;
  keyboard?: boolean;
  footer?: JSX.Element;
  content: JSX.Element | string | React.ReactNode;
  destroyOnClose?: boolean;
  getContainer?: HTMLElement;
  wrapClassName?: string;
  title?: React.ReactNode;
  key?: string;
  scrollable?: boolean;
  scrollableHeight?: number;
  visible: boolean;
  isContentLoading?: boolean;
  width?: string | number;
  zIndex?: number;
  loading?: boolean;
  showLoading?: boolean;
  size?: CustomModalPropsSize;
  top?: number | string;
  maskClosable?: boolean;
  onCancel?: () => void;
  onOk?: () => void;
  afterClose?: () => void;
  closeIcon?: React.ReactNode;
}

const CustomModal: FC<CustomModalProps> = ({
  className,
  title,
  visible,
  footer = false,
  isContentLoading,
  content,
  destroyOnClose,
  width,
  centered,
  closable,
  keyboard = true,
  getContainer,
  wrapClassName,
  zIndex,
  key,
  scrollable,
  scrollableHeight,
  showLoading,
  onOk,
  afterClose,
  onCancel,
  top,
  size,
  maskClosable = false,
  closeIcon,
}) => {
  const getContent = () => {
    return (
      <StyledContent
        scrollable={scrollable}
        scrollableHeight={scrollableHeight}
        className="content-container"
      >
        {content}
      </StyledContent>
    );
  };

  const isScroll = document.body.scrollHeight > document.body.clientHeight;

  const getWidth = () => {
    if (width) {
      return width;
    }
    switch (size) {
      case 'small':
        return '40%';
      case 'large':
        return '70%';
      case 'xlarge':
        return '90%';
      case 'default':
      default:
        return '50%';
    }
  };

  return (
    <>
      <StyledModalBackground visible={visible} isScroll={isScroll} />
      <StyledModal
        className={className}
        centered={centered}
        closable={closable}
        keyboard={keyboard}
        footer={footer}
        destroyOnClose={destroyOnClose}
        getContainer={getContainer}
        wrapClassName={wrapClassName}
        title={title}
        key={key}
        visible={visible}
        width={getWidth()}
        zIndex={zIndex}
        onCancel={onCancel}
        onOk={onOk}
        afterClose={afterClose}
        maskClosable={maskClosable}
        style={{ top }}
        closeIcon={closeIcon}
      >
        {showLoading ? (
          <LoadingIndicator spinning={isContentLoading}>
            {getContent()}
          </LoadingIndicator>
        ) : (
          getContent()
        )}
      </StyledModal>
    </>
  );
};

export { CustomModal };
