import { useCallback, DependencyList } from 'react';
import { debounce } from 'lodash-es';

// Disabled because of useCallback signature
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function useDebounce<T extends (...args: any[]) => any>(
  callback: T,
  deps: DependencyList,
  debounceTime = 500
) {
  return useCallback(debounce(callback, debounceTime), deps);
}

export { useDebounce };
