import { LanguageConstants } from '../constants';
import { LanguageAction } from '../actions/types';
import { createNewIntl } from '../helpers';

export const initialState: STATES.LanguageState = {
  currentLanguage: navigator.language,
  intl: createNewIntl(navigator.language, {}),
};

export const language = (
  state = initialState,
  action: LanguageAction
): STATES.LanguageState => {
  if (action.type === LanguageConstants.SET_LANGUAGE_MESSAGES) {
    const { currentLanguage, messages, configProviderLocale } = action.payload;
    return {
      ...state,
      currentLanguage,
      intl: createNewIntl(currentLanguage, messages),
      configProviderLocale,
    };
  }
  if (action.type === LanguageConstants.APPEND_LANGUAGE_MESSAGES) {
    const { messages } = action.payload;
    return {
      ...state,
      intl: createNewIntl(state.currentLanguage, {
        ...(state?.intl?.messages || {}),
        ...messages,
      }),
    };
  }
  return state;
};
