import React, { NamedExoticComponent, memo, useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { LoadingIndicator } from '../LoadingIndicator';

const StyledImg = styled.img<{ width?: string; height?: string }>`
  width: ${({ width }) => width || 'auto'};
  height: ${({ height }) => height || 'auto'};
  cursor: pointer;
`;

type LogoPropsType =
  | 'CoherentBrandMarkDarkBgColoredLogo'
  | 'CoherentBrandMarkDarkBgWhiteLogo'
  | 'CoherentBrandMarkWhiteBgColoredLogo'
  | 'CoherentDarkBgColoredLogo'
  | 'CoherentDarkBgWhiteLogo'
  | 'CoherentWhiteBgColoredLogo'
  | 'CoherentV1DarkBgColoredLogo'
  | 'CoherentV1DarkBgWhiteLogo'
  | 'CoherentV1WhiteBgColoredLogo'
  | 'CoherentSparkDarkBgColoredLogo'
  | 'CoherentSparkDarkBgWhiteLogo'
  | 'CoherentSparkWhiteBgColoredLogo'
  | 'CoherentSparkV1DarkBgColoredLogo'
  | 'CoherentSparkV1DarkBgWhiteLogo'
  | 'CoherentSparkV1WhiteBgColoredLogo';

interface LogoProps {
  type?: LogoPropsType;
  width?: string;
  height?: string;
  alt?: string;
  src?: string;
  isLoading?: boolean;
}

const Logo: NamedExoticComponent<LogoProps> = memo(
  ({ isLoading, src, type, width, height, alt }) => {
    const theme = useContext(ThemeContext);

    const logoSvgPath =
      src && src !== ''
        ? src
        : type
        ? theme[`${type}`].substring(1)
        : theme['CoherentSparkV1WhiteBgColoredLogo'];

    return isLoading ? (
      <>
        <LoadingIndicator spinning={isLoading}></LoadingIndicator>
      </>
    ) : (
      <StyledImg
        src={logoSvgPath}
        width={width}
        data-testid="logo"
        height={height}
        alt={alt}
      />
    );
  }
);

export { Logo };
