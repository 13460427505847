import queryString from 'query-string';
import { getConfig } from '../helpers/config';
import makeRequest from '../helpers/make-request';
import uploadRequest from '../helpers/upload-request';
import { getDocFileExtensions } from '../helpers';

const getProducts = (request: DTO.GetProductsRequest) => {
  const {
    page = 1,
    pageSize = 16,
    sortBy = 'updated',
    sort = 'desc',
    searchText = '',
    filterStatus = '',
    filterCategory = '',
    filterFavorite = false,
    fakeSortingPage = null,
    fakeSortingPageSize = null,
    filterWithActiveService = false,
    shouldFetchActiveServicesCount = false,
  } = request;

  const url = `${getConfig().excelEngineDomain}/api/v1/product/list?`;

  const search: { field: string; value: string }[] = [];

  if (searchText) {
    search.push({
      field: 'productName_co',
      value: searchText.replace(/"/g, ''),
    });
  }

  if (filterStatus) {
    search.push({ field: 'status1_co', value: filterStatus });
  }

  if (typeof filterCategory === 'object') {
    if (filterCategory.length) {
      filterCategory.forEach(category =>
        search.push({ field: 'name1_co', value: category })
      );
    }
  } else {
    search.push({ field: 'name1_co', value: filterCategory });
  }

  if (filterFavorite) {
    search.push({ field: 'isstarred', value: 'true' });
  }

  if (filterWithActiveService) {
    search.push({ field: 'ACTIVESERVICE', value: 'true' });
  }

  return makeRequest<DTO.GetProductsResponse>('POST', url, {
    page: fakeSortingPage || page,
    pageSize: fakeSortingPageSize || pageSize,
    sort: sortBy ? `${sort === 'desc' ? '-' : ''}${sortBy}` : '',
    search,
    shouldFetchActiveServicesCount: shouldFetchActiveServicesCount ?? false,
  });
};

const getSharedProducts = (request: DTO.GetProductsRequest) => {
  const {
    page = 1,
    pageSize = 11,
    sortBy = 'updated',
    sort = 'desc',
    searchText = '',
    filterStatus = '',
    filterCategory = '',
    fakeSortingPage = null,
    fakeSortingPageSize = null,
    shouldFetchActiveServicesCount = false,
  } = request;

  const url = `${getConfig().excelEngineDomain}/api/v1/product/list?`;

  const search: { field: string; value: string | string[] }[] = [];

  if (searchText) {
    search.push({ field: 'productName_co', value: searchText });
  }

  if (filterStatus) {
    search.push({ field: 'status1_co', value: filterStatus });
  }

  if (typeof filterCategory === 'object') {
    if (filterCategory.length) {
      filterCategory.forEach(category =>
        search.push({ field: 'name1_co', value: category })
      );
    }
  } else {
    search.push({ field: 'name1_co', value: filterCategory });
  }

  return makeRequest<DTO.GetProductsResponse>('POST', url, {
    page: fakeSortingPage || page,
    pageSize: fakeSortingPageSize || pageSize,
    sort: sortBy ? `${sort === 'desc' ? '-' : ''}${sortBy}` : '',
    search,
    shouldFetchActiveServicesCount: shouldFetchActiveServicesCount ?? false,
  });
};

const createProducts = (product: DTO.ProductDetail) => {
  const url = `${getConfig().excelEngineDomain}/api/v1/product/create`;

  const formData = new FormData();

  formData.append('Name', product.name);
  formData.append('Category', product.category);
  formData.append('Description', product.description);
  formData.append('LaunchDate', product.launchDate);
  formData.append('StartDate', product.startDate);
  formData.append('Status', 'Design');

  if (product.sharedWithUserGroups) {
    formData.append('SharedWithUserGroups', product.sharedWithUserGroups);
  }

  if (product.coverImage) {
    formData.append('CoverImage', product.coverImage);
  }

  return makeRequest('POST', url, formData);
};

const cloneProduct = (product: DTO.CloneProductRequest) => {
  const url = `${getConfig().excelEngineDomain}/api/v1/product/${
    product.originalName
  }/clone`;
  const formData = new FormData();

  formData.append('name', product.name);
  formData.append('category', product.category);
  formData.append('description', product.description);
  formData.append('launchDate', product.launchDate);
  formData.append('startDate', product.startDate);

  if (product.sharedWithUserGroups) {
    formData.append('sharedWithUserGroups', product.sharedWithUserGroups);
  }

  if (product.coverImage) {
    formData.append('coverImage', product.coverImage);
  }

  return makeRequest<DTO.CloneProductResponse>('POST', url, formData);
};

const getCloneProductName = (productName: string) => {
  const url = `${
    getConfig().excelEngineDomain
  }/api/v1/product/GetCloneProductName/${productName}`;

  return makeRequest<DTO.GetCloneProductName>('POST', url);
};

const updateProduct = (
  productId: string,
  product: Partial<DTO.ProductDetail>,
  shouldTrackUserAction = true
) => {
  const url = `${
    getConfig().excelEngineDomain
  }/api/v1/product/update/${productId}`;

  return makeRequest('POST', url, { ...product, shouldTrackUserAction });
};

const uploadCoverImage = (
  productId: string,
  file: File | Blob,
  onUploadProgress: (percent: number) => void
) => {
  const url = `${
    getConfig().excelEngineDomain
  }/api/v1/product/UploadCoverImage`;
  const formData = new FormData();

  formData.append('id', productId);
  formData.append('coverImage', file);

  return uploadRequest('POST', url, formData, onUploadProgress);
};

const getCoverImage = (productName: string, fileName: string) => {
  const url = `${
    getConfig().excelEngineDomain
  }/api/v1/product/GetImage/${fileName}/${productName}`;

  return makeRequest<{ blob: Blob }>(
    'GET',
    url,
    undefined,
    'application/octet-stream'
  );
};

const getProductDetails = (
  productName: string,
  data: DTO.GetProductDetailsRequest = {}
) => {
  let url = `${
    getConfig().excelEngineDomain
  }/api/v1/product/GetProduct/${productName}?`;

  const { noOfCalculationEngines = 5 } = data;

  url += queryString.stringify({
    noOfCalculationEngines,
  });

  return makeRequest<DTO.GetProductDetailsResponse>('GET', url);
};

const setFavoriteProduct = (productId: string, isFavorite: boolean) =>
  updateProduct(productId, { isStarred: isFavorite }, false);

const getProductDocs = (
  page: number,
  pageSize: number,
  productName: string,
  documentType: string,
  searchTerm: string,
  filterCategory?: string | string[]
) => {
  const url = `${
    getConfig().excelEngineDomain
  }/api/v1/product/${productName}/documents/List/${
    documentType === 'noFolder' ? '' : documentType
  }`;
  const search: { field: string; value: string; operator?: string }[] = [];

  if (searchTerm) {
    search.push({ field: 'name1', value: searchTerm, operator: 'like' });
  }

  if (typeof filterCategory === 'object') {
    if (filterCategory.length) {
      filterCategory.forEach(docCategory => {
        const extensions = getDocFileExtensions(docCategory);
        extensions.forEach(extension => {
          search.push({ field: 'name1', value: extension, operator: 'like' });
        });
      });
    }
  } else {
    const extensions = getDocFileExtensions(filterCategory || '');
    extensions.forEach(extension => {
      search.push({ field: 'name1', value: extension, operator: 'like' });
    });
  }

  return makeRequest<DTO.GetProductDocsResponse>('POST', url, {
    page,
    pageSize,
    sort: '-created',
    search,
  });
};

const moveProductDocs = (prop: DTO.MoveProductDocsRequest) => {
  const {
    targetDocumentType,
    productName,
    FileName,
    sourceDocumentType,
  } = prop;

  const url = `${
    getConfig().excelEngineDomain
  }/api/v1/product/${productName}/documents/MoveDocument`;

  return makeRequest('POST', url, {
    FileName: FileName || '',
    sourceDocumentType:
      sourceDocumentType === 'noFolder' ? '' : sourceDocumentType,
    targetDocumentType:
      targetDocumentType === 'noFolder' ? '' : targetDocumentType,
  });
};

const deleteDocs = (
  productName: string,
  fileName: string,
  documentType: string
) => {
  const url = `${
    getConfig().excelEngineDomain
  }/api/v1/product/${productName}/documents/Delete/${fileName}/${
    documentType === 'noFolder' ? '' : documentType
  }`;

  return makeRequest('DELETE', url);
};

const uploadProductDoc = (
  documentType: string,
  productName: string,
  productDocument: File,
  metadata: object,
  onUploadProgress: (precent: number) => void,
  xhrRef: (xhr: XMLHttpRequest) => void
) => {
  const url = `${
    getConfig().excelEngineDomain
  }/api/v1/product/${productName}/documents/create`;
  const formData = new FormData();

  formData.append('ProductDocument', productDocument);
  formData.append('DocumentType', documentType);
  formData.append('Metadata', JSON.stringify(metadata));

  return uploadRequest<DTO.UploadProductDocResponse>(
    'POST',
    url,
    formData,
    onUploadProgress,
    xhrRef
  );
};

const getDocumentDownloadUrl = (
  request: DTO.DownloadDocumentRequest,
  documentType: string
) => {
  const { productName, fileName } = request;

  return `${
    getConfig().excelEngineDomain
  }/api/v1/Product/${productName}/documents/download/${fileName}/${
    documentType === 'noFolder' ? '' : documentType
  }`;
};

const getRecentChanges = (
  productName: string,
  data: DTO.GetRecentChangeRequest
) => {
  const url = `${
    getConfig().excelEngineDomain
  }/api/v1/useraction/list/${productName}`;

  if (!data.NextPageToken) {
    delete data.NextPageToken;
  }

  return makeRequest<DTO.GetRecentChangeResponse>('POST', url, data);
};

const getHtmlFileUrl = (
  productName: string,
  serviceName: string,
  token: string,
  type: string
) => {
  return `${
    getConfig().excelEngineDomain
  }/api/v1/product/${productName}/documents/GetContent/${serviceName}/${type}?token=${token}`;
};

const uploadProductZip = (
  file: File,
  onUploadProgress: (precent: number) => void,
  xhrRef: (xhr: XMLHttpRequest) => void
) => {
  const config = getConfig();
  const tenant = localStorage.getItem('Tenant');
  const url = `${config.excelEngineDomain}/${tenant ||
    config.defaultTenant}/api/v3/folders/validatezip`;

  const formData = new FormData();
  formData.append('file', file);

  return uploadRequest<DTO.ResponseV3<DTO.ValidateProductZipResponse>>(
    'POST',
    url,
    formData,
    onUploadProgress,
    xhrRef
  );
};

const createProductByZipFile = (data: DTO.CreateProductByZipRequest) => {
  const config = getConfig();
  const tenant = localStorage.getItem('Tenant');
  const url = `${config.excelEngineDomain}/${tenant ||
    config.defaultTenant}/api/v3/folders/${data.Name}/upload`;

  const formData = new FormData();
  if (data.ZipFile) {
    formData.append('file', data.ZipFile);
  }
  if (data.CoverImage) {
    formData.append('coverImage', data.CoverImage);
  }

  const obj = {
    request_data: {
      category: data.Category,
      status: 'Design',
      description: data.Description,
    },
  };

  formData.append('uploadFolderRequest', JSON.stringify(obj));

  return makeRequest<DTO.ResponseV3<DTO.DownloadUploadFolderAPIResponse>>('POST', url, formData);
};

const createSection = (
  productName: string,
  sectionName: string,
  position: number,
  fileTypes: string[]
) => {
  const url = `${
    getConfig().excelEngineDomain
  }/api/v1/product/${productName}/sections/create/${sectionName}/${position}`;

  return makeRequest<unknown>('POST', url, fileTypes);
};

const updateSection = (
  productName: string,
  oldSectionName: string,
  newSectionName: string,
  position: number,
  fileTypes: string[]
) => {
  const url = `${
    getConfig().excelEngineDomain
  }/api/v1/product/${productName}/sections/update/${oldSectionName}/${newSectionName}/${position}`;

  return makeRequest<unknown>('PUT', url, fileTypes);
};

const deleteSection = (productName: string, sectionName: string) => {
  const url = `${
    getConfig().excelEngineDomain
  }/api/v1/product/${productName}/sections/delete/${sectionName}`;

  return makeRequest<unknown>('DELETE', url);
};

const getActiveServiceCount = () => {
  const url = `${
    getConfig().excelEngineDomain
  }/api/v1/product/GetActiveServiceNumbers`;
  return makeRequest<{ data: DTO.ActiveServiceCount }>('POST', url);
};

const getActiveServices = (request: DTO.PagedRequest) => {
  const searchItem = request.search?.find(item => item.field === 'name1_co');
  if (searchItem && typeof searchItem.value === 'string') {
    searchItem.value = searchItem.value.replace(/"/g, '');
  }
  const url = `${
    getConfig().excelEngineDomain
  }/api/v1/product/getActiveServices`;
  return makeRequest<DTO.PagedResponse<DTO.ActiveServiceList>>(
    'POST',
    url,
    request
  );
};

const deleteFolder = folderId => {
  const url = `${
    getConfig().excelEngineDomain
  }/api/v1/product/delete/${folderId}`;
  return makeRequest<unknown>('DELETE', url, {});
};

const isProductExists = (productName: string) => {
  const url = `${
    getConfig().excelEngineDomain
  }/api/v1/product/IsProductExists/${productName}`;
  return makeRequest<{ data: boolean }>('POST', url);
};

export const ProductService = {
  getProducts,
  moveProductDocs,
  getSharedProducts,
  createProducts,
  updateProduct,
  uploadCoverImage,
  getCoverImage,
  setFavoriteProduct,
  getProductDetails,
  getProductDocs,
  uploadProductDoc,
  cloneProduct,
  getDocumentDownloadUrl,
  deleteDocs,
  getRecentChanges,
  getHtmlFileUrl,
  uploadProductZip,
  createProductByZipFile,
  createSection,
  updateSection,
  deleteSection,
  getCloneProductName,
  getActiveServiceCount,
  getActiveServices,
  deleteFolder,
  isProductExists,
};
