import queryString from 'query-string';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { SystemActions, ProductsActions } from '../../actions';
import { getPathNameWithoutBaseUrlString } from '../../helpers/pathName';
import { useAppDispatch } from '../../hooks';

const mapStateToProps = ({
  system: { isFetchingConfig, appConfig },
  products: { isLoading },
  language: { currentLanguage },
}: STATES.AppState) => ({
  isFetchingConfig,
  isLoading,
  appConfig,
  currentLanguage,
});

interface PrivateRouteProps extends RouteProps {
  loggedIn: boolean;
  isLoggingOut?: boolean;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({
  component: Component,
  render,
  loggedIn,
  isLoggingOut,
  ...rest
}) => {
  const {
    isFetchingConfig,
    appConfig,
    isLoading,
    currentLanguage,
  } = useSelector(mapStateToProps);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (loggedIn) {
      dispatch(SystemActions.getAppConfig(currentLanguage));
    }
  }, [loggedIn]);

  useEffect(() => {
    if (
      appConfig?.EnableActiveService?.toString()?.toLowerCase() === 'true' &&
      loggedIn &&
      !isLoading
    ) {
      dispatch(ProductsActions.getActiveServiceCount());
    }
  }, [loggedIn, isFetchingConfig, appConfig, dispatch]);

  return (
    <Route
      {...rest}
      render={props => {
        if (loggedIn) {
          if (render) {
            return render(props);
          }

          if (Component) {
            return <Component {...props} />;
          }

          return <Redirect to="/" />;
        }

        let redirectUrl;
        if (window.location && window.location.search) {
          const qss = queryString.parse(
            window.location.search.replaceAll('+', '%2B')
          );
          redirectUrl = qss.return_url;
        }

        if (!redirectUrl) {
          redirectUrl = getPathNameWithoutBaseUrlString(
            localStorage.getItem('Tenant')
          );

          redirectUrl = redirectUrl === '/' ? '' : redirectUrl;
        }
        let returnUrl = '';
        if (redirectUrl) {
          returnUrl = `?return_url=${redirectUrl.replace(/ /g, '@!@')}&`;
        }
        return <Redirect to={`/login${returnUrl}`} />;
      }}
    />
  );
};

export { PrivateRoute };
