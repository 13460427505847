import React, { FC } from 'react';
import styled from 'styled-components';
import { Alert } from 'antd';
import {
  InfoCircleFilled,
  CheckCircleFilled,
  ExclamationCircleFilled,
  WarningFilled,
} from '@ant-design/icons';

const StyledAlert = styled(props => <Alert {...props} />)`
  &.ant-alert {
    padding: 12px 16px 12px 16px;
    text-align: left;
    align-items: flex-start;
    border-radius: 4px;

    .ant-alert-icon {
      font-size: 14px;
      svg {
        margin-top: 5px;
      }
    }

    .anticon-close {
      svg {
        margin-top: 5px;
      }
    }

    &.ant-alert-closable {
      padding-right: 16px;
    }

    &.ant-alert-no-icon {
      padding: 12px 16px !important;
    }

    &.ant-alert-with-description .ant-alert-icon {
      font-size: 21px;
      svg {
        margin-top: 3px;
      }
    }

    &.ant-alert-with-description {
      padding: 16px;
    }

    &.ant-alert-with-description .anticon-close {
      svg {
        margin-top: 7px;
      }
    }

    .ant-alert-close-icon {
      font-size: 12px;
    }

    &.ant-alert-with-description .ant-alert-close-icon {
      top: 10px;
    }

    .ant-alert-message {
      color: ${({ theme }) => theme.color.dark};
    }

    .ant-alert-description {
      color: ${({ theme }) => theme.color.dark};
    }
  }

  &.ant-alert-info {
    background-color: ${({ theme }) => theme.color.azureBlue20};
    border-color: ${({ theme }) => theme.color.azureBlue60};

    .anticon {
      color: ${({ theme }) => theme.color.blue6};
    }

    .ant-alert-close-icon {
      .ant-alert-close-text {
        color: ${({ theme }) => theme.color.dark60};
      }

      .anticon {
        color: ${({ theme }) => theme.color.dark60};
      }
    }
  }

  &.ant-alert-warning {
    background-color: ${({ theme }) => theme.color.warning20};
    border-color: ${({ theme }) => theme.color.warning};

    .anticon {
      color: ${({ theme }) => theme.color.yellow};
    }

    .ant-alert-close-icon {
      .ant-alert-close-text {
        color: ${({ theme }) => theme.color.dark60};
      }

      .anticon {
        color: ${({ theme }) => theme.color.dark60};
      }
    }
  }

  &.ant-alert-success {
    background-color: ${({ theme }) => theme.color.success15};
    border-color: ${({ theme }) => theme.color.success};

    .anticon {
      color: ${({ theme }) => theme.color.success};
    }

    .ant-alert-close-icon {
      .ant-alert-close-text {
        color: ${({ theme }) => theme.color.dark60};
      }

      .anticon {
        color: ${({ theme }) => theme.color.dark60};
      }
    }
  }

  &.ant-alert-error {
    background-color: ${({ theme }) => theme.color.attention20};
    border-color: ${({ theme }) => theme.color.dangerColor};

    .anticon {
      color: ${({ theme }) => theme.color.dangerColor};
    }

    .ant-alert-close-icon {
      .ant-alert-close-text {
        color: ${({ theme }) => theme.color.dark60};
      }

      .anticon {
        color: ${({ theme }) => theme.color.dark60};
      }
    }
  }
`;

export type AlertPropsType = 'success' | 'info' | 'warning' | 'error';
type AlertPropsText = string | JSX.Element | React.ReactNode;
interface AlertProps {
  type?: AlertPropsType;
  message: AlertPropsText;
  description?: AlertPropsText;
  showIcon?: boolean;
  icon?: JSX.Element;
  closable?: boolean;
  className?: string;
  closeText?: AlertPropsText;
  onClose?: () => void;
  isBanner?: boolean;
  action?: JSX.Element | null;
}

const CustomAlert: FC<AlertProps> = ({
  type = 'info',
  message,
  description,
  showIcon = false,
  icon,
  closable = false,
  closeText = '',
  onClose,
  isBanner = false,
  className,
  action,
}) => {
  const getDefaultIconBasedOnType = () => {
    if (type === 'info') {
      return <InfoCircleFilled />;
    }
    if (type === 'success') {
      return <CheckCircleFilled />;
    }
    if (type === 'warning') {
      return <ExclamationCircleFilled />;
    }
    if (type === 'error') {
      return <WarningFilled />;
    }
    return undefined;
  };
  const iconRender = () => {
    if (showIcon) {
      if (icon) {
        return icon;
      }
      return getDefaultIconBasedOnType();
    }
    return undefined;
  };

  return (
    <StyledAlert
      type={type || 'info'}
      message={message}
      description={description}
      showIcon={showIcon}
      icon={iconRender()}
      closable={closable}
      closeText={closeText}
      onClose={onClose}
      banner={isBanner}
      className={className}
      action={action}
    />
  );
};

export { CustomAlert };
