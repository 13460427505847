import {
  BulbOutlined,
  SettingOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import {
  Button,
  Col,
  Drawer,
  Input,
  Layout,
  Menu,
  Row,
  Tooltip,
  Typography,
} from 'antd';
import { startsWith } from 'lodash-es';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { InsightsActions, SystemActions, UserActions } from '../../actions';
import AboutModal from '../../containers/AboutModal';
import BackgroundJobsPopover from '../../containers/BackgroundJobsPopover';
import BearerTokensModal from '../../containers/BearerTokenModal';
import {
  colorHexToRgba,
  copyToClipboard,
  getConfig,
  getInitials,
  getbaseUrlWithTenant,
} from '../../helpers';
import { useModal } from '../../hooks';
import { CustomAlert, CustomModal, IconSvg, Logo } from '../Common';
import { StyledQuestionCircleOutlined } from '../index.styles';
import { LanguageDropdown } from '../Language';
import { getBaseUrl } from '../../helpers/config';
import { InsightsService } from '../../services';

const { Header } = Layout;

interface RouteParams {
  productId: string;
}

const mapStateToProps = ({
  user: { userInfo },
  auth: { isAdmin, isSuperuser, tenant, issuerConfig, tenantRelation, groups },
  system: { appConfig, updateBannerVisiblity, cachedlogoURL },
}: STATES.AppState) => ({
  displayName: (userInfo && userInfo.displayName) || '...',
  userEmailID: userInfo && userInfo.userEmailID,
  isAdmin,
  isSuperuser,
  tenant,
  issuerConfig,
  tenantRelation,
  appConfig,
  groups,
  updateBannerVisiblity,
  cachedlogoURL,
});

type MainHeaderProps = { sideMenuVisible?: boolean; headerTitle?: string };

const StyledHeader = styled(Header)<{
  showbanner?: boolean;
}>`
  position: sticky;
  top: 0;
  z-index: 5;
  height: ${({ showbanner }) => (showbanner ? '172px' : '86px')} !important;
  padding: 0 0px !important;

  .header-wrapper {
    padding: 0px 40px !important;
    span {
      line-height: 22px;
      word-break: break-all;
    }
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
  height: 86px !important;
  background-color: ${({ theme }) => theme.color.white};
  padding-top: 30px;
  display: grid;
  grid-template-rows: 1fr;

  .header-title {
    font-size: 20px;
    line-height: 23px;
    margin: 0;
    text-align: center;
  }

  @media (max-width: 768px) {
    grid-template-columns: 30% 23% 47%;
  }

  @media (min-width: 769px) and (max-width: 1024px) {
    grid-template-columns: 21% 26% 34%;
  }
`;

const StyledMenuContainer = styled.div`
  display: flex;
  align-items: center;
  grid-column: 3/4;
  grid-row: 1/2;
  justify-content: flex-end;
`;

const StyledDrawer = styled(Drawer)`
  .ant-drawer-mask {
    background-color: ${({ theme }) => colorHexToRgba(theme.color.dark, 0.4)};
  }
  .ant-drawer-content-wrapper {
    width: 360px !important;
    height: auto !important;
    bottom: unset !important;
    .ant-drawer-header {
      background: ${({ theme }) => theme.color.gray6};
      padding: 26px 23px 22px 10px;
    }
    .ant-drawer-content {
      border-radius: 4px;
    }
    .ant-drawer-body {
      padding: 0;
      .ant-menu-item-divider {
        margin: 0;
        border: none;
      }
      .ant-menu-item {
        margin: 0;
        padding: 0;
        height: 36px;
        font-size: 16px;

        a {
          line-height: 2.5;
          padding: 9px 9px 9px 9px;
          display: flex;
          align-items: center;
          height: 36px;
          color: ${({ theme }) => theme.color.dark};

          .anticon {
            transition: unset;
            align-self: center;
            margin-right: 10px;
            margin-bottom: 5px;
            font-size: 20px;
            opacity: 1;
            position: relative;
            top: 4px;
          }
        }
      }

      .ant-menu li {
        color: ${({ theme }) => theme.color.dark};
        background: ${({ theme }) => theme.color.white};
        font-weight: 400 !important;
      }

      .ant-menu li:hover {
        background-color: ${({ theme }) => theme.color.gray10};
      }

      .ant-menu li:active {
        background-color: ${({ theme }) => theme.color.purpleDarkened2};
        color: ${({ theme }) => theme.color.white};
        a {
          color: ${({ theme }) => theme.color.white};
        }
      }
    }
  }
`;

const StyledProfileIcon = styled.div`
  display: inline-block;
  align-items: center;
  padding: 0px 10px;
  padding-right: 0;

  .initials {
    width: 36px;
    cursor: pointer;
    height: 36px;
    background-color: ${({ theme }) => theme.color.purpleDarkened2};
    border-radius: 18px;
    margin-right: 0px;
    color: ${({ theme }) => theme.color.white};
    display: inline-block;
    text-align: center;
    font-size: 15px;
    text-transform: uppercase;
    line-height: 36px;
  }

  .name {
    font-weight: bold;
    font-size: 18px;
    text-transform: capitalize;
    height: 36px;

    b {
      line-height: 2opx;
    }
  }
`;

const StyledProfileInfo = styled(Row)`
  .initials {
    width: 60px;
    cursor: default;
    height: 60px;
    background-color: ${({ theme }) => theme.color.purpleDarkened2};
    border-radius: 30px;
    color: ${({ theme }) => theme.color.white};
    text-align: center;
    font-size: 20px;
    text-transform: uppercase;
    line-height: 60px;
    font-weight: bold;
  }

  .name {
    font-weight: bold;
    font-size: 20px;
    text-transform: capitalize;
  }

  .emailAddress {
    font-weight: 400;
  }

  .tenant,
  .timezone {
    font-weight: 300;
  }
`;

const StyledLanguageDropdown = styled(LanguageDropdown)`
  button {
    padding-top: 0px !important;
    height: 36px;
    width: 36px;
    padding: 0;
    justify-content: center;
    align-items: center;
    border-radius: 4px !important;
    border: 1px solid transparent !important;

    .anticon {
      color: ${({ theme }) => theme.color.purple} !important;
    }

    :hover {
      background: ${({ theme }) => theme.color.purple20} !important;
      border: 1px solid ${({ theme }) => theme.color.purple} !important;
      .anticon {
        color: ${({ theme }) => theme.color.purpleDarkened1} !important;
      }
    }

    &.ant-dropdown-open,
    :active {
      background: ${({ theme }) => theme.color.purple} !important;
      border: 1px solid ${({ theme }) => theme.color.purple} !important;
      .anticon {
        color: ${({ theme }) => theme.color.white} !important;
      }
    }
  }
`;

const StyledCustomModal = styled(CustomModal)`
  display: flex;
  justify-content: center;

  .title {
    display: flex;
    justify-content: center;
    font-size: 20px;
    line-height: 23px;
    margin-bottom: 10px;
    color: ${({ theme }) => theme.color.dark};
  }

  input {
    width: 330px;
    height: 36px;
    margin-right: 8px;
    border: 1px solid ${({ theme }) => theme.color.purple};
    color: ${({ theme }) => theme.color.dark};
    font-size: 12px;
  }

  .copy-text {
    display: flex;
    justify-content: center;
    color: ${({ theme }) => theme.color.dark};
  }

  .ant-modal-footer {
    display: none;
  }

  .action-wrap {
    display: flex;
    margin: 20px 0;

    button {
      color: ${({ theme }) => theme.color.white} !important;
      border-radius: 20px;
      background-color: ${({ theme }) =>
        theme.color.purpleDarkened2} !important;
      color: ${({ theme }) => theme.color.white};
      font-size: 14px !important;
      font-weight: bold;
      width: 106px !important;
      height: 36px !important;
      min-width: unset !important;
    }
  }

  .copy-success {
    display: flex;
    justify-content: center;

    i {
      margin-top: 4px;
      margin-right: 5px;
      color: ${({ theme }) => theme.color.success};
    }

    .copy-text {
      margin-left: 5px;
    }
  }

  .anticon-close {
    color: ${({ theme }) => theme.color.dark} !important;
  }
`;

const MainHeader: React.FC<MainHeaderProps> = ({ children, headerTitle }) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const isShared = useLocation().pathname.startsWith('/shared');
  const { productId } = useParams<RouteParams>();
  const [aboutModdalVisible, setAboutModalVisible] = useState(false);
  const [isLogoImageLoading, setIsLogoImageLoading] = useState<boolean>(false);
  const [bearerTokensModalVisible, setBearerTokensModalVisible] = useState(
    false
  );
  const [showShareModal, setShowShareModal] = useState<boolean>(false);
  const [lastClickedMenuName, setLastClickedMenuName] = useState<
    string | undefined
  >();
  const [shareLink, setShareLink] = useState<string>(
    `${window.location.origin}${
      isShared ? '/shared' : ''
    }/products/${productId}`
  );
  const { showConfirm } = useModal();
  const history = useHistory();
  const location = useLocation();
  const config = getConfig();
  const {
    displayName,
    userEmailID,
    issuerConfig,
    tenantRelation,
    appConfig,
    updateBannerVisiblity,
    groups,
    cachedlogoURL,
  } = useSelector(mapStateToProps);
  const [showDrawer, setShowDrawer] = useState(false);
  const [showBanner, setShowBanner] = useState(false);
  const [customBrandingImageURL, setcustomBrandingImageURL] = useState('');
  const tenant = localStorage.getItem('Tenant') || config.defaultTenant;
  const ShowTenantConfigPage =
    appConfig?.ShowTenantConfigPage?.toString()?.toUpperCase() !== 'FALSE';

  const optionRoute =
    issuerConfig?.tenant === config.defaultAdminTenant
      ? '/admin/explorer/home'
      : groups.includes('tenant-admin') && ShowTenantConfigPage
      ? '/admin/tenant-configuration/general'
      : appConfig?.ShowFiles?.toString().toLowerCase() === 'true'
      ? '/admin/explorer/home'
      : '/admin/services/services-list';
  const onLogOut: React.MouseEventHandler<HTMLAnchorElement> = e => {
    e.preventDefault();

    showConfirm({
      content: 'MainHeader.logout.confirm',
      okText: 'Global.yes',
      cancelText: 'Global.no',
      onOk: () => {
        dispatch(UserActions.logout(issuerConfig));
        localStorage.removeItem('showMaintenanceBanner');
        setShowBanner(false);
      },
    });
  };

  const showAboutModal: React.MouseEventHandler<HTMLAnchorElement> = e => {
    e.preventDefault();
    setAboutModalVisible(true);
  };

  const hideAboutModal = useCallback(() => {
    setAboutModalVisible(false);
  }, []);

  const showBearerTokensModal: React.MouseEventHandler<HTMLAnchorElement> = e => {
    e.preventDefault();
    setLastClickedMenuName('BearerToken');
    setBearerTokensModalVisible(true);
  };

  const optionMenuClicked: React.MouseEventHandler<HTMLAnchorElement> = e => {
    e.preventDefault();
    dispatch(InsightsActions.incrementMenuCounter('Options'));
    history.push(optionRoute);
  };

  const hideBearerTokensModal = useCallback(() => {
    setBearerTokensModalVisible(false);
  }, []);

  const onClose = () => {
    setShowDrawer(false);
  };

  const onOpen = () => setShowDrawer(true);

  useEffect(() => {
    if (lastClickedMenuName) {
      setLastClickedMenuName(undefined);
      dispatch(InsightsActions.incrementMenuCounter(lastClickedMenuName));
    }
  }, [lastClickedMenuName, dispatch]);

  useEffect(() => {
    if (
      appConfig?.EnableCustomBranding?.toString().toLowerCase() === 'true' &&
      appConfig?.CustomBrandingImageGetURL
    ) {
      if (!cachedlogoURL || !cachedlogoURL.filePath) {
        setIsLogoImageLoading(true);
        dispatch(
          SystemActions.getCustomBrandingImage(
            getbaseUrlWithTenant() + appConfig?.CustomBrandingImageGetURL
          )
        );
      }

      if (cachedlogoURL && cachedlogoURL?.filePath) {
        setcustomBrandingImageURL(cachedlogoURL.filePath);
        setIsLogoImageLoading(false);
      }
    }
  }, [cachedlogoURL, appConfig?.CustomBrandingImageGetURL]);

  const onLinkCopy = () =>
    copyToClipboard(shareLink, intl.formatMessage({ id: 'Global.copied' }));

  // Customer Success SSO Redirect (Campus, Community)
  const getCsSsoUrl = (clientId: string) => {
    if (!config.csSsoBaseUrl) {
      throw new Error('csSsoBaseUrl is not configured');
    }

    const tenantUrl = new URL(getBaseUrl());
    tenantUrl.pathname = tenant as string;

    const redirectUrl = new URL(config.csSsoBaseUrl);
    redirectUrl.searchParams.append('client_id', clientId);

    if (tenantRelation === 'internal') {
      redirectUrl.searchParams.append('idp', 'azure_ad');
    } else {
      redirectUrl.searchParams.append('tenant_url', tenantUrl.href);
    }

    return redirectUrl.href;
  };

  const incrementFeatureCounter = () => {
    InsightsService.incrementFeatureCounter({
      folder_name: '',
      service_name: '',
      counter_event: 'CoherentCampus',
      total_counter_event_name: '',
      count: 1,
      batch_id: '',
    });
  };

  const userMenu = (
    <Menu onClick={onClose}>
      {startsWith(location.pathname, '/admin') && (
        <Menu.Item>
          <Link to="/">
            <IconSvg type="LeftArrow" />
            <FormattedMessage id="MainHeader.menu.catalogue" />
          </Link>
        </Menu.Item>
      )}
      <Menu.Divider />
      <Menu.Item>
        <Link
          to={optionRoute}
          onClick={optionMenuClicked}
          data-testid="options-menuitem"
        >
          <SettingOutlined />
          <FormattedMessage id="MainHeader.menu.options" />
        </Link>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item>
        <Link
          to="/"
          onClick={showBearerTokensModal}
          data-testid="bearerTokens-menuitem"
        >
          <IconSvg type="Key" />
          <FormattedMessage id="MainHeader.menu.bearerTokens" />
        </Link>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item>
        <a
          target="_blank"
          href={`${appConfig?.GitBookUrl}whats-new`}
          rel="noopener noreferrer"
          onClick={() => setLastClickedMenuName('WhatsNew')}
          data-testid="news-menuitem"
        >
          <BulbOutlined />
          <FormattedMessage id="MainHeader.menu.news" />
        </a>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item>
        <Link to="/" onClick={showAboutModal} data-testid="aboutspark-menuitem">
          <IconSvg type="InfoCircle" />
          <FormattedMessage id="MainHeader.menu.about" />
        </Link>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item>
        <a
          data-testid="userguide-menuitem"
          target="_blank"
          href={appConfig.GitBookUrl}
          rel="noopener noreferrer"
          onClick={() => setLastClickedMenuName('UserGuide')}
        >
          <IconSvg type="UserGuide" />
          <FormattedMessage id="MainHeader.menu.userGuide" />
        </a>
      </Menu.Item>
      <Menu.Divider />
      {config?.csSsoBaseUrl &&
        config?.csSsoCampusClientId &&
        tenantRelation !== 'other' && (
          <>
            <Menu.Item>
              <a
                target="_blank"
                href={getCsSsoUrl(config.csSsoCampusClientId)}
                onClick={incrementFeatureCounter}
                rel="noopener noreferrer"
              >
                <IconSvg type="Campus" />
                <FormattedMessage id="MainHeader.menu.coherentCampus" />
              </a>
            </Menu.Item>
            <Menu.Divider />
          </>
        )}
      <Menu.Item>
        <a
          target="_blank"
          href={`${config?.reportSupportUrl}`}
          rel="noopener noreferrer"
          onClick={() => setLastClickedMenuName('Support')}
          data-testid="support-menuitem"
        >
          <IconSvg type="SupportIcon" />
          <FormattedMessage id="MainHeader.menu.support" />
        </a>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item>
        <Link to="/" onClick={onLogOut} data-testid="logout-menuitem">
          <IconSvg type="Logout" />
          <FormattedMessage id="MainHeader.menu.logout" />
        </Link>
      </Menu.Item>
    </Menu>
  );

  useEffect(() => {
    setShowBanner(localStorage.getItem('showMaintenanceBanner') === 'true');
  }, [updateBannerVisiblity]);

  const getMaintenanceValues = () => {
    return {
      startDate: moment(appConfig?.MaintenanceStartDateTime || '').format(
        'ddd, MMM DD, YYYY, HH:mm:ss'
      ),
      endDate: moment(appConfig?.MaintenanceEndDateTime || '').format(
        'ddd, MMM DD, YYYY, HH:mm:ss'
      ),
      timezone: `GMT ${moment().format('Z')} - ${new Date()
        .toLocaleDateString(undefined, {
          day: '2-digit',
          timeZoneName: 'long',
        })
        .substring(4)}`,
    };
  };

  const getMaintenanceTitle = (name: string) => {
    return moment
      .utc(appConfig['MaintenanceStartDateTime'])
      .isBefore(moment().utc())
      ? `MaintenanceBanner.OnGoingMaintenance.${name}`
      : `MaintenanceBanner.UnderMaintenance.${name}`;
  };

  return (
    <>
      <StyledHeader className="main-header" showbanner={showBanner}>
        {showBanner && (
          <CustomAlert
            message={<FormattedMessage id={getMaintenanceTitle('Title')} />}
            description={
              <h4 className="mb-0">
                <FormattedMessage
                  id={getMaintenanceTitle('Description')}
                  values={getMaintenanceValues()}
                />
              </h4>
            }
            type="warning"
            showIcon
            closable
            onClose={() => {
              localStorage.setItem('showMaintenanceBanner', 'false');
              setShowBanner(false);
            }}
          />
        )}
        <Wrapper className="header-wrapper">
          {children || (
            <Link to="/" data-testid="brand-logo">
              <Logo
                type="CoherentSparkV1WhiteBgColoredLogo"
                height="50px"
                alt={intl.formatMessage({ id: 'MainHeader.alttext.logo' })}
                src={customBrandingImageURL ?? ''}
                isLoading={isLogoImageLoading}
              />
            </Link>
          )}
          <div className="header-title-wrap">
            {headerTitle && <p className="header-title">{headerTitle}</p>}
          </div>
          <StyledMenuContainer className="header-menu">
            <BackgroundJobsPopover />
            <StyledLanguageDropdown />
            <StyledProfileIcon onClick={onOpen} data-testid="user-menu-toggler">
              <b className="initials">{getInitials(displayName)}</b>
            </StyledProfileIcon>
          </StyledMenuContainer>
          <StyledDrawer
            title={
              <StyledProfileInfo
                style={{ overflowWrap: 'anywhere' }}
                gutter={[0, 0]}
                wrap={false}
                className="mt-14"
              >
                <Col flex="60px" className="initials mr-8">
                  {getInitials(displayName)}
                </Col>
                <Col flex="1 1">
                  <Row>
                    <Typography.Text
                      ellipsis={{
                        tooltip: {
                          title: displayName,
                          placement: 'bottomRight',
                        },
                      }}
                      className="name w-2"
                      style={{ flex: '1 1' }}
                    >
                      {displayName}
                    </Typography.Text>
                  </Row>
                  <Row>
                    <Typography.Text
                      ellipsis={{
                        tooltip: {
                          title: userEmailID,
                          placement: 'bottomRight',
                        },
                      }}
                      className="emailAddress w-2"
                      style={{ flex: '1 1' }}
                    >
                      {userEmailID}
                    </Typography.Text>
                  </Row>

                  <Row>
                    <span className="tenant">
                      {`${tenant} (${
                        appConfig?.IsPrivateSharingTenant ? 'Private' : 'Shared'
                      })`}
                    </span>
                    <Tooltip
                      placement="bottomRight"
                      arrowPointAtCenter
                      title={
                        <FormattedMessage
                          id={
                            appConfig?.IsPrivateSharingTenant
                              ? 'MainHeader.private.tenant.tooltip'
                              : 'MainHeader.shared.tenant.tooltip'
                          }
                        />
                      }
                    >
                      <StyledQuestionCircleOutlined>
                        <QuestionCircleOutlined />
                      </StyledQuestionCircleOutlined>
                    </Tooltip>
                    <Tooltip
                      placement="bottomRight"
                      arrowPointAtCenter
                      overlayClassName="tooltip-popover"
                      title={
                        <FormattedMessage
                          id="MainHeader.usergroup.tooltip"
                          values={{
                            usergroups: groups
                              .toString()
                              .split(',')
                              .map((group, index, array) => {
                                return index === array.length - 1
                                  ? group
                                  : `${group}, `;
                              })
                              .join(''),
                          }}
                        />
                      }
                    >
                      <StyledQuestionCircleOutlined>
                        {<IconSvg type="UserGroups" />}
                      </StyledQuestionCircleOutlined>
                    </Tooltip>
                  </Row>
                  <Row className="timezone">{`UTC${moment().format('Z')}`}</Row>
                </Col>
              </StyledProfileInfo>
            }
            placement="right"
            closable={false}
            onClose={onClose}
            open={showDrawer}
          >
            {userMenu}
          </StyledDrawer>
        </Wrapper>
      </StyledHeader>
      <AboutModal visible={aboutModdalVisible} onClose={hideAboutModal} />
      <BearerTokensModal
        visible={bearerTokensModalVisible}
        onClose={hideBearerTokensModal}
      />
      <StyledCustomModal
        visible={showShareModal}
        onCancel={() => setShowShareModal(false)}
        title={<FormattedMessage id="MainHeader.share.title" />}
        content={
          <>
            <div className="action-wrap">
              <Input
                value={shareLink}
                className="input-val"
                onChange={e => setShareLink(e.target.value)}
              />
              <Button onClick={onLinkCopy}>
                <FormattedMessage id="Global.copy" />
              </Button>
            </div>

            <p className="copy-text">
              <FormattedMessage id="MainHeader.share.text" />
            </p>
          </>
        }
      />
    </>
  );
};

export default MainHeader;
