import _ from 'lodash';
import { ServicesColumnName } from '../components/Services/EditSavedFilterModal';

export const buildPageRequestSearchFromApplyFilter = (
  appliedFilter: DTO.OptionsServiceFilter | undefined
) => {
  const appliedFilterSearchData: DTO.SearchV3[] = [];
  if (appliedFilter?.service_name) {
    appliedFilterSearchData.push({
      fieldName: 'name1_co',
      fieldValue: appliedFilter?.service_name,
    });
  }
  if (appliedFilter?.folders && appliedFilter?.folders.length > 0) {
    appliedFilterSearchData.push({
      fieldName: 'productname_co',
      fieldValue: appliedFilter?.folders,
    });
  }
  if (appliedFilter?.tags && appliedFilter?.tags.length > 0) {
    appliedFilterSearchData.push({
      fieldName: 'tags',
      fieldValue: appliedFilter?.tags,
    });
  }
  if (appliedFilter?.status && appliedFilter?.status.length > 0) {
    appliedFilterSearchData.push({
      fieldName: 'status',
      fieldValue: appliedFilter?.status,
    });
  }
  if (appliedFilter?.latest_version_added_from) {
    appliedFilterSearchData.push({
      fieldName: 'startdate',
      fieldValue: appliedFilter?.latest_version_added_from,
    });
  }
  if (appliedFilter?.latest_version_added_until) {
    appliedFilterSearchData.push({
      fieldName: 'enddate',
      fieldValue: appliedFilter?.latest_version_added_until,
    });
  }
  return appliedFilterSearchData;
};

export const buildSaveFilterRequest = (
  appliedFilter: DTO.OptionsServiceFilter | undefined,
  pagedRequest: DTO.PagedRequestV3
) => {
  const saveFilterRequest: DTO.OptionsServiceFilter = {} as DTO.OptionsServiceFilter;

  if (appliedFilter?.id) {
    saveFilterRequest.id = appliedFilter?.id;
  }

  if (appliedFilter?.filter_name) {
    saveFilterRequest.filter_name = appliedFilter?.filter_name;
  }

  const serviceFilter = pagedRequest.search?.find(
    item => item.fieldName === 'name1_co'
  );
  if (serviceFilter) {
    saveFilterRequest.service_name = serviceFilter.fieldValue;
  }

  const productsFilter = pagedRequest.search?.find(
    item => item.fieldName === 'productname_co'
  );
  if (productsFilter) {
    saveFilterRequest.folders = productsFilter.fieldValue;
  }

  const statusFilter = pagedRequest.search?.find(
    item => item.fieldName === 'status'
  );
  if (statusFilter) {
    saveFilterRequest.status = statusFilter.fieldValue;
  }

  const tagsFilter = pagedRequest.search?.find(
    item => item.fieldName === 'tags'
  );
  if (tagsFilter) {
    saveFilterRequest.tags = tagsFilter.fieldValue;
  }

  const startDateFilter = pagedRequest.search?.find(
    item => item.fieldName === 'startdate'
  );
  if (startDateFilter) {
    saveFilterRequest.latest_version_added_from = startDateFilter.fieldValue;
  }

  const endDateFilter = pagedRequest.search?.find(
    item => item.fieldName === 'enddate'
  );
  if (endDateFilter) {
    saveFilterRequest.latest_version_added_until = endDateFilter.fieldValue;
  }

  return saveFilterRequest;
};

export const isAppliedFilterAndPageRequestSearchSame = (
  appliedFilter: DTO.OptionsServiceFilter | undefined,
  pagedRequest: DTO.PagedRequestV3
) => {
  const appliedFilterSearchData = buildPageRequestSearchFromApplyFilter(
    appliedFilter
  );
  const appliedFilterSearchDataObj = {};
  appliedFilterSearchData.forEach(elem => {
    appliedFilterSearchDataObj[`${elem.fieldName}`] = Array.isArray(
      elem.fieldValue
    )
      ? elem.fieldValue.sort()
      : elem.fieldValue;
  });
  const pageRequestSearchData = pagedRequest.search;
  const pageRequestSearchDataObj = {};
  pageRequestSearchData?.forEach(elem => {
    pageRequestSearchDataObj[`${elem.fieldName}`] = Array.isArray(
      elem.fieldValue
    )
      ? elem.fieldValue.sort()
      : elem.fieldValue;
  });

  if (
    _.isEqual(
      JSON.parse(JSON.stringify(appliedFilterSearchDataObj)),
      JSON.parse(JSON.stringify(pageRequestSearchDataObj))
    )
  ) {
    return true;
  }
  return false;
};

export const differentiateAvailableFolderOrTags = (
  type: ServicesColumnName,
  pagedRequest: DTO.PagedRequestV3,
  productsList: DTO.Product[],
  tagsList: { [key: string]: string | null } | DTO.KeyValue[],
  enableTags: boolean,
  enableActiveService: boolean
): {
  availableData: string[];
  notAvailableData: string[];
  featureNotEnableData: string[];
} => {
  const searchData =
    pagedRequest.search?.filter(instance => instance.fieldName === type) || [];
  if (searchData.length === 0) {
    return {
      availableData: [],
      notAvailableData: [],
      featureNotEnableData: [],
    };
  }
  const data: {
    availableData: string[];
    notAvailableData: string[];
    featureNotEnableData: string[];
  } = {
    availableData: [],
    notAvailableData: [],
    featureNotEnableData: [],
  };
  if (type === 'productname_co') {
    searchData[0].fieldValue.forEach(item => {
      const index = productsList.findIndex(product => product.name === item);
      if (index !== -1) {
        data.availableData.push(item);
      } else {
        data.notAvailableData.push(item);
      }
    });
    return data;
  }

  if (type === 'status') {
    searchData[0].fieldValue.forEach(item => {
      if (!enableActiveService) {
        data.featureNotEnableData.push(item);
      } else {
        data.availableData.push(item);
      }
    });
    return data;
  }

  searchData[0].fieldValue.forEach(item => {
    if (!enableTags) {
      data.featureNotEnableData.push(item);
    } else {
      let index = -1;
      if (tagsList != null) {
        index = tagsList.findIndex(tags => tags.Key === item);
      }
      if (index !== -1) {
        data.availableData.push(item);
      } else {
        data.notAvailableData.push(item);
      }
    }
  });
  return data;
};

export const getFinalRequestFilter = (
  pagedRequest: DTO.PagedRequestV3,
  enableTags: boolean,
  enableActiveService: boolean
): DTO.SearchV3[] => {
  const SearchV3: DTO.SearchV3[] = [];

  const productFilter = pagedRequest.search?.filter(
    item => item.fieldName === 'productname_co'
  );
  if (productFilter && productFilter.length > 0) {
    productFilter[0].fieldValue?.forEach(item =>
      SearchV3.push({ fieldName: 'productname_co', fieldValue: item })
    );
  }

  if (enableTags) {
    const tagsFilter = pagedRequest.search?.filter(
      item => item.fieldName === 'tags'
    );
    if (tagsFilter && tagsFilter.length > 0) {
      tagsFilter?.forEach(item =>
        SearchV3.push({
          fieldName: 'tags',
          fieldValue: item.fieldValue.join(),
        })
      );
    }
  }

  if (enableActiveService) {
    const statusFilter = pagedRequest.search?.filter(
      item => item.fieldName === 'status'
    );
    if (statusFilter && statusFilter.length > 0) {
      statusFilter[0].fieldValue?.forEach(item =>
        SearchV3.push({
          fieldName: 'status',
          fieldValue: item,
        })
      );
    }
  }

  const serviceOrDateFilter = pagedRequest.search?.filter(item =>
    ['name1_co', 'startdate', 'enddate'].includes(item.fieldName)
  );
  serviceOrDateFilter?.forEach(item =>
    SearchV3.push({
      fieldName: item.fieldName,
      fieldValue: item.fieldValue,
    })
  );

  return SearchV3;
};
