import React, { FC, useEffect, useState } from 'react';
import { SelectValue } from 'antd/lib/select';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { CustomSelect } from '../CustomSelect';
import { EngineConstants } from '../../../constants';
import { IconSvg } from '../Icons/IconSvg';

const StyledWrapper = styled.div`
  width: 100%;
  .ant-select {
    width: 100% !important;
  }
`;

interface EngineTypeDropdownProps {
  shouldShowNodeGen?: boolean;
  shouldShowNodeGenBeta?: boolean;
  onChange?: (value: SelectValue) => void;
  value?: SelectValue;
  suffixIcon?: JSX.Element;
  className?: string;
  size?: 'small' | 'middle' | 'large';
  engineType: string;
  selectedValue?: string;
}

export const EngineTypeDropdown: FC<EngineTypeDropdownProps> = ({
  shouldShowNodeGen = true,
  shouldShowNodeGenBeta = true,
  engineType,
  selectedValue,
  onChange,
  ...rest
}) => {
  const intl = useIntl();
  const [options, setOptions] = useState<string[]>();
  const [value, setValue] = useState<string>();

  useEffect(() => {
    if (selectedValue && options) {
      setValue(options.includes(selectedValue) ? selectedValue : options[0]);
    }
  }, [selectedValue, options]);

  useEffect(() => {
    const tmpEngineType =
      engineType && engineType.length ? engineType.split(',') : [];
    let tmpOptions: string[] = [];

    tmpEngineType.forEach(e => {
      tmpOptions.push(e);
    });

    if (!shouldShowNodeGen) {
      tmpOptions = tmpOptions.filter(
        c =>
          c.toLowerCase() !== EngineConstants.NEURON.toLowerCase() &&
          c.toLowerCase() !== EngineConstants.APICONNECTOR.toLowerCase() &&
          c.toLowerCase() !== EngineConstants.XCONNECTOR.toLowerCase()
      );
    }

    if (!shouldShowNodeGenBeta) {
      tmpOptions = tmpOptions.filter(
        c => c.toLowerCase() !== EngineConstants.NEURON_BETA.toLowerCase()
      );
    }

    setOptions(tmpOptions);
  }, [engineType, shouldShowNodeGen, shouldShowNodeGenBeta]);

  const onOptionChange = e => {
    setValue(e);
    onChange && onChange(e);
  };

  return (
    <StyledWrapper>
      <CustomSelect
        onChange={onOptionChange}
        value={value}
        suffixIcon={<IconSvg type="CaretDownFilled" />}
        {...rest}
        placeholder="ApiDetailsForm.requestedServiceCategory.placeholder"
        options={
          options
            ? options.map((option, index) => {
                return {
                  key: index.toString(),
                  value: option,
                  title: intl.formatMessage({
                    id: `ApiDetailsForm.select.${option}`,
                  }),
                  data_testid: `engine-type-${option}`,
                };
              })
            : []
        }
      />
    </StyledWrapper>
  );
};
