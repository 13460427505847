import React, { memo, ReactNode, NamedExoticComponent, useRef } from 'react';
import styled, { css } from 'styled-components';
import { useIntl } from 'react-intl';
import { Tabs } from 'antd';
import { TabsType, TabsProps as AntdTabsProps } from 'antd/lib/tabs';
import { ScrollContainer, ScrollCss } from '../ScrollContainer';
import { LoadingIndicator } from '../LoadingIndicator';

const GhostTabs = css<{
  scrollable: boolean;
  tabWidth: number;
}>`
  .ant-tabs-nav::before {
    border: none;
  }
  margin-top: 20px;
  .ant-tabs-bar {
    border: 20px !important;
  }
  .ant-tabs-card-content {
    background: ${({ theme }) => theme.color.pearl60};
    height: ${({ scrollable }) => (scrollable ? '728px' : 'auto')};
    padding: 40px;
    border: 1px solid ${({ theme }) => theme.color.purple};
    border-top-width: 0;
  }

  .tab-pane-container {
    flex: 1;
  }

  .ant-tabs-card-content {
    display: flex;
    flex-direction: column;

    .ant-tabs-tabpane-active {
      display: flex;
      flex-direction: column;
      flex: 1 1 !important;
      overflow: ${({ scrollable }) => (scrollable ? 'auto' : 'visible')};
    }
  }

  --custom-tabs-fixed-tab-width: ${({ tabWidth }) => tabWidth}px;
  --custom-tabs-arrow-btn-size: 44px;
  --custom-tabs-arrow-btn-border-radius: 3px;

  .ant-tabs-nav-wrap {
    justify-content: flex-start;
    padding-left: 0;
    max-width: calc(
      var(--custom-tabs-fixed-tab-width) * 5 + var(--custom-tabs-arrow-btn-size)
    );
    padding-right: var(--custom-tabs-arrow-btn-size);

    .ant-tabs-tab-arrow-show {
      width: var(--custom-tabs-arrow-btn-size);
      height: var(--custom-tabs-arrow-btn-size);
      background: ${({ theme }) => theme.color.purple};
      border-radius: var(--custom-tabs-arrow-btn-border-radius)
        var(--custom-tabs-arrow-btn-border-radius) 0 0;
      color: ${({ theme }) => theme.color.white};

      .anticon {
        font-size: 25px !important;
      }

      .ant-tabs-tab-prev-icon,
      .ant-tabs-tab-next-icon {
        height: calc(var(--custom-tabs-arrow-btn-size) / 2);
      }
    }

    .ant-tabs-tab-btn-disabled {
      display: none;
    }

    .ant-tabs-tab-next,
    .ant-tabs-tab-prev {
      right: 0;
      top: 0;
      left: auto;
    }
  }

  .ant-tabs-nav-wrap {
    max-width: calc(
      var(--custom-tabs-fixed-tab-width) * 5 + var(--custom-tabs-arrow-btn-size)
    );

    .ant-tabs-tab {
      display: flex;
      justify-content: center;
      font-size: 16px;
      border: 0px !important;
      background-color: transparent !important;
      width: var(--custom-tabs-fixed-tab-width);
      padding-left: 5px !important;
      padding-right: 5px !important;
      text-align: center;
      border-radius: 0px !important;
      --tab-height: 44px;
      &.ant-tabs-tab-active {
        font-weight: 400;
        color: ${({ theme }) => theme.color.dark} !important;
      }
      .ant-tabs-tab-btn {
        a {
          padding: 12px 40px !important;
          color: ${({ theme }) => theme.color.dark80};
        }
      }
    }
  }
  .ant-tabs-nav-list {
    .ant-tabs-tab-active {
      .ant-tabs-tab-btn {
        span {
          a {
            color: ${({ theme }) => theme.color.dark};
          }
        }
      }
    }
  }
`;

const PrimaryTabs = css`
  font-family: ${({ theme }) => theme.fontFaces[1]};
  && {
    .ant-tabs-nav::before {
      border: none;
    }
    .ant-tabs-bar {
      border: 0 !important;
    }
    .ant-tabs-content {
      position: relative;
      background: transparent;
      height: auto !important;
      overflow-y: auto !important;
      overflow-x: hidden !important;
      ${ScrollCss}
    }
    .tab-pane-container {
      padding: 12px 24px 12px 24px;
      min-height: 350px;
      ${ScrollCss}
    }
    .ant-tabs-tab {
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: normal;
      min-width: 120px;
      border: 1px solid ${({ theme }) => theme.color.purpleDarkened2} !important;
      background: ${({ theme }) => theme.color.white}!important;
      padding: 0px !important;
      --tab-height: 44px;
      margin: 0px !important;
      font-size: 16px;
      span {
        height: 40px !important;
        div {
          padding: 6px 24px !important;
        }
      }
      &.ant-tabs-tab-active {
        font-weight: 400;
        background: ${({ theme }) => theme.color.purpleDarkened2} !important;
      }

      .ant-tabs-tab-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        span {
          display: contents !important;
        }
      }
      :hover {
        color: ${({ theme }) => theme.color.purple};
      }
      .ant-tabs-tab-btn {
        :active {
          color: ${({ theme }) => theme.color.purple};
        }
      }
    }
    .ant-tabs-tab:last-child {
      border-radius: 0px 4px 4px 0px !important;
    }
    .ant-tabs-tab:first-child {
      border-radius: 4px 0px 0px 4px !important;
    }

    .ant-tabs-card-content {
      border-color: transparent !important;
    }
  }
  .ant-tabs-nav-wrap {
    display: flex;
    justify-content: center;
    border: 0 !important;
  }
  .ant-tabs-nav-list {
    .ant-tabs-tab-active {
      background-color: ${({ theme }) =>
        theme.color.purpleDarkened2} !important;
      .ant-tabs-tab-btn {
        color: ${({ theme }) => theme.color.white} !important;
      }
    }
    .ant-tabs-ink-bar {
      visibility: hidden !important;
    }
  }
`;

const TabTitleContainer = styled.div`
  > span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    a {
      color: ${({ theme }) => theme.color.dark80};
    }
  }
`;

const StyledCustomTabs = styled(props => <Tabs {...props} />)<{
  scrollable: boolean;
  tabWidth: number;
  tabType: 'primary' | 'ghost';
}>`
  ${({ tabType }) => (tabType === 'primary' ? PrimaryTabs : GhostTabs)}
`;

interface Tab {
  view: ReactNode | null;
  name: ReactNode;
  key: string;
  datatestid?: string;
}

export interface CustomTabsProps {
  className?: string;
  tabs: { [key: string]: Tab };
  tabBarExtraContent?: ReactNode;
  topCommonView?: ReactNode;
  onChange?: (key: string) => void;
  activeKey?: string;
  commonView?: ReactNode;
  scrollable?: boolean;
  defaultActiveKey?: string;
  tabWidth?: number;
  type?: TabsType;
  tabType?: 'primary' | 'ghost';
  destroyInactiveTabPane?: boolean;
  isLoading?: boolean;
}

const CustomTabs: NamedExoticComponent<CustomTabsProps> = memo(
  ({
    tabs,
    tabBarExtraContent,
    onChange,
    commonView,
    className,
    activeKey,
    defaultActiveKey,
    scrollable = true,
    tabWidth = 130,
    topCommonView,
    type = 'card',
    tabType = 'primary',
    destroyInactiveTabPane = false,
    isLoading = false,
  }) => {
    const intl = useIntl();
    const tabsRef = useRef<AntdTabsProps | null>(null);
    const containerRef = useRef<HTMLDivElement>(null);
    const tabProps: AntdTabsProps = {
      className,
      tabBarExtraContent,
      type,
    };

    if (activeKey) {
      tabProps.activeKey = activeKey;
    } else if (defaultActiveKey) {
      tabProps.defaultActiveKey = defaultActiveKey;
    }

    const tabsOnChange = (nextActiveKey: string) => {
      onChange && onChange(nextActiveKey);

      const idx = Object.keys(tabs).indexOf(nextActiveKey);

      let buttonSelector = '';

      if (idx > 2) {
        buttonSelector = '.ant-tabs-tab-next:not(.ant-tabs-tab-btn-disabled)';
      } else {
        buttonSelector = '.ant-tabs-tab-prev:not(.ant-tabs-tab-btn-disabled)';
      }

      const button =
        containerRef.current &&
        (containerRef.current.querySelector(buttonSelector) as HTMLElement);

      button && button.click();
    };

    return (
      <div ref={containerRef}>
        <StyledCustomTabs
          {...tabProps}
          destroyInactiveTabPane={destroyInactiveTabPane}
          scrollable={scrollable}
          className={`${className} ${activeKey} customeTabs-${tabType}`}
          onChange={tabsOnChange}
          tabWidth={tabWidth}
          ref={tabsRef}
          tabType={tabType}
        >
          {Object.values(tabs).map(({ name, key, view, datatestid }) => {
            const tabTitle =
              typeof name === 'string'
                ? intl.formatMessage({ id: name })
                : name;

            return (
              <Tabs.TabPane
                tab={
                  <TabTitleContainer
                    title={typeof tabTitle === 'string' ? tabTitle : undefined}
                    data-testid={datatestid}
                  >
                    <span>{tabTitle}</span>
                  </TabTitleContainer>
                }
                key={key}
                className={key}
              >
                {scrollable ? (
                  <LoadingIndicator spinning={isLoading}>
                    {topCommonView}
                    <ScrollContainer className="tab-pane-container">
                      {view}
                    </ScrollContainer>
                  </LoadingIndicator>
                ) : (
                  <LoadingIndicator spinning={isLoading}>
                    {view}
                  </LoadingIndicator>
                )}

                {commonView && (
                  <div className="tab-pane-common-view">{commonView}</div>
                )}
              </Tabs.TabPane>
            );
          })}
        </StyledCustomTabs>
      </div>
    );
  }
);

export { CustomTabs };
