import { DashboardAction } from '../actions/types';
import { DashboardConstants } from '../constants';

export const initialState: STATES.DashboardState = {
  serviceList: {
    isLoading: false,
    error: '',
    list: [],
    requestList: [],
  },
  folderDetailServiceList: {
    isLoading: false,
    error: '',
    list: [],
    requestList: [],
  },
  folderDetailServiceCountChart: {
    isLoading: false,
    list: [],
  },
  serviceCountChart: {
    isLoading: false,
    list: [],
  },
  serviceUpdateChart: {
    isLoading: false,
    list: [],
    error: '',
  },
  usageChart: {
    isLoading: false,
    list: [],
    tagList: [],
    error: '',
  },
  folderListChart: {
    isLoading: false,
    list: [],
    error: '',
  },
  serviceListChart: {
    isLoading: false,
    list: [],
    error: '',
  },
  folderDetailUsageChart: {
    isLoading: false,
    list: [],
    tagList: [],
    error: '',
  },
  folderDetailServiceListChart: {
    isLoading: false,
    list: [],
    error: '',
  },
};

export const dashboard = (
  state = initialState,
  action: DashboardAction
): STATES.DashboardState => {
  switch (action.type) {
    case DashboardConstants.GET_DASHBOARD_SERVICES_LIST_REQUEST: {
      return {
        ...state,
        serviceList: {
          ...state.serviceList,
          isLoading: true,
          error: '',
        },
      };
    }

    case DashboardConstants.GET_DASHBOARD_SERVICES_LIST_SUCCESS: {
      const { list, requestList } = action.payload;
      return {
        ...state,
        serviceList: {
          ...state.serviceList,
          isLoading: false,
          list,
          requestList,
        },
      };
    }

    case DashboardConstants.GET_DASHBOARD_SERVICES_LIST_FAILURE: {
      const { error } = action.payload;
      return {
        ...state,
        serviceList: {
          ...state.serviceList,
          list: [],
          requestList: [],
          isLoading: false,
          error,
        },
      };
    }

    case DashboardConstants.GET_FOLDER_DETAIL_SERVICES_LIST_REQUEST: {
      return {
        ...state,
        folderDetailServiceList: {
          ...state.folderDetailServiceList,
          isLoading: true,
          error: '',
        },
      };
    }

    case DashboardConstants.GET_FOLDER_DETAIL_SERVICES_LIST_SUCCESS: {
      const { list, requestList } = action.payload;
      return {
        ...state,
        folderDetailServiceList: {
          ...state.folderDetailServiceList,
          isLoading: false,
          list,
          requestList,
        },
      };
    }

    case DashboardConstants.GET_FOLDER_DETAIL_SERVICES_LIST_FAILURE: {
      const { error } = action.payload;
      return {
        ...state,
        folderDetailServiceList: {
          ...state.folderDetailServiceList,
          list: [],
          requestList: [],
          isLoading: false,
          error,
        },
      };
    }

    case DashboardConstants.GET_SERVICE_COUNT_DATA_REQUEST: {
      return {
        ...state,
        serviceCountChart: {
          ...state.serviceCountChart,
          isLoading: true,
        },
      };
    }

    case DashboardConstants.GET_SERVICE_COUNT_DATA_SUCCESS: {
      const { list } = action.payload;
      return {
        ...state,
        serviceCountChart: {
          ...state.serviceCountChart,
          isLoading: false,
          list,
        },
      };
    }

    case DashboardConstants.GET_FOLDER_DETAIL_SERVICE_COUNT_DATA_REQUEST: {
      return {
        ...state,
        folderDetailServiceCountChart: {
          ...state.folderDetailServiceCountChart,
          isLoading: true,
        },
      };
    }

    case DashboardConstants.GET_FOLDER_DETAIL_SERVICE_COUNT_DATA_SUCCESS: {
      const { list } = action.payload;
      return {
        ...state,
        folderDetailServiceCountChart: {
          ...state.folderDetailServiceCountChart,
          isLoading: false,
          list,
        },
      };
    }

    case DashboardConstants.GET_SERVICE_UPDATE_DATA_REQUEST: {
      return {
        ...state,
        serviceUpdateChart: {
          ...state.serviceUpdateChart,
          isLoading: true,
          error: '',
        },
      };
    }

    case DashboardConstants.GET_SERVICE_UPDATE_DATA_SUCCESS: {
      const { list } = action.payload;
      return {
        ...state,
        serviceUpdateChart: {
          ...state.serviceUpdateChart,
          isLoading: false,
          list,
        },
      };
    }

    case DashboardConstants.GET_SERVICE_UPDATE_DATA_FAILURE: {
      const { error } = action.payload;
      return {
        ...state,
        serviceUpdateChart: {
          ...state.serviceUpdateChart,
          list: [],
          isLoading: false,
          error,
        },
      };
    }

    case DashboardConstants.GET_OTHER_CHART_DATA_REQUEST: {
      return {
        ...state,
        usageChart: {
          ...state.usageChart,
          isLoading: true,
          error: '',
        },
        folderListChart: {
          ...state.folderListChart,
          isLoading: true,
          error: '',
        },
        serviceListChart: {
          ...state.serviceListChart,
          isLoading: true,
          error: '',
        },
      };
    }

    case DashboardConstants.GET_OTHER_CHART_DATA_FAILURE: {
      const { error } = action.payload;

      return {
        ...state,
        folderListChart: {
          ...state.folderListChart,
          isLoading: false,
          list: [],
          error,
        },
        usageChart: {
          ...state.usageChart,
          isLoading: false,
          list: [],
          tagList: [],
          error,
        },
        serviceListChart: {
          ...state.serviceListChart,
          isLoading: false,
          list: [],
          error,
        },
      };
    }

    case DashboardConstants.GET_FOLDER_DETAIL_OTHER_CHART_DATA_REQUEST: {
      return {
        ...state,
        folderDetailUsageChart: {
          ...state.folderDetailUsageChart,
          isLoading: true,
          error: '',
        },
        folderDetailServiceListChart: {
          ...state.folderDetailServiceListChart,
          isLoading: true,
          error: '',
        },
      };
    }

    case DashboardConstants.GET_FOLDER_DETAIL_OTHER_CHART_DATA_FAILURE: {
      const { error } = action.payload;

      return {
        ...state,
        folderDetailUsageChart: {
          ...state.folderDetailUsageChart,
          isLoading: false,
          list: [],
          tagList: [],
          error,
        },
        folderDetailServiceListChart: {
          ...state.folderDetailServiceListChart,
          isLoading: false,
          list: [],
          error,
        },
      };
    }

    case DashboardConstants.GET_FOLDER_DETAIL_USAGE_DATA_SUCCESS: {
      const { list, tagList } = action.payload;
      return {
        ...state,
        folderDetailUsageChart: {
          ...state.folderDetailUsageChart,
          isLoading: false,
          list,
          tagList,
        },
      };
    }

    case DashboardConstants.GET_FOLDER_DETAIL_USAGE_DATA_FAILURE: {
      const { error } = action.payload;
      return {
        ...state,
        folderDetailUsageChart: {
          ...state.folderDetailUsageChart,
          list: [],
          tagList: [],
          isLoading: false,
          error,
        },
      };
    }

    case DashboardConstants.GET_FOLDER_DETAIL_SERVICE_LIST_DATA_SUCCESS: {
      const { list } = action.payload;
      return {
        ...state,
        folderDetailServiceListChart: {
          ...state.folderDetailServiceListChart,
          isLoading: false,
          list,
        },
      };
    }

    case DashboardConstants.GET_FOLDER_DETAIL_SERVICE_LIST_DATA_FAILURE: {
      const { error } = action.payload;
      return {
        ...state,
        folderDetailServiceListChart: {
          ...state.folderDetailServiceListChart,
          list: [],
          isLoading: false,
          error,
        },
      };
    }

    case DashboardConstants.GET_USAGE_DATA_SUCCESS: {
      const { list, tagList } = action.payload;
      return {
        ...state,
        usageChart: {
          ...state.usageChart,
          isLoading: false,
          list,
          tagList,
        },
      };
    }

    case DashboardConstants.GET_USAGE_DATA_FAILURE: {
      const { error } = action.payload;
      return {
        ...state,
        usageChart: {
          ...state.usageChart,
          list: [],
          tagList: [],
          isLoading: false,
          error,
        },
      };
    }

    case DashboardConstants.GET_FOLDER_LIST_DATA_SUCCESS: {
      const { list } = action.payload;
      return {
        ...state,
        folderListChart: {
          ...state.folderListChart,
          isLoading: false,
          list,
        },
      };
    }

    case DashboardConstants.GET_FOLDER_LIST_DATA_FAILURE: {
      const { error } = action.payload;
      return {
        ...state,
        folderListChart: {
          ...state.folderListChart,
          list: [],
          isLoading: false,
          error,
        },
      };
    }

    case DashboardConstants.GET_SERVICE_LIST_DATA_SUCCESS: {
      const { list } = action.payload;
      return {
        ...state,
        serviceListChart: {
          ...state.serviceListChart,
          isLoading: false,
          list,
        },
      };
    }

    case DashboardConstants.GET_SERVICE_LIST_DATA_FAILURE: {
      const { error } = action.payload;
      return {
        ...state,
        serviceListChart: {
          ...state.serviceListChart,
          list: [],
          isLoading: false,
          error,
        },
      };
    }

    default:
      return state;
  }
};
