import React, { FC } from 'react';
import { Menu, Dropdown } from 'antd';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { LanguageConstants } from '../../constants';
import { useLanguage } from '../../hooks';
import { IconSvg } from '../Common';
import { StyledMenuToggler } from '../index.styles';

interface LanguageDropdownProps {
  transparent?: boolean;
  className?: string;
}

const LanguageDropdown: FC<LanguageDropdownProps> = ({
  transparent,
  className,
}) => {
  const { language, setLanguage } = useLanguage();

  const onChange = (e, lang) => {
    e.preventDefault();
    setLanguage(lang);
  };

  const menu = (
    <Menu selectedKeys={[language]}>
      {LanguageConstants.SUPPORTED_LANGUAGES.map(lang => (
        <Menu.Item key={lang} data-testid="menu-item">
          <Link
            to="/"
            key={lang}
            onClick={e => {
              onChange(e, lang);
            }}
            data-testid={`menuitem-${lang}`}
          >
            <FormattedMessage
              id={`LanguageDropdown.${lang}`}
              defaultMessage={lang}
            />
          </Link>
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <div className={className}>
      <Dropdown
        overlay={menu}
        placement="bottomRight"
        trigger={['click']}
        overlayClassName="languageMenu"
      >
        <StyledMenuToggler
          className="language-toggler"
          transparent={transparent}
          data-testid="language-menu"
        >
          <IconSvg type="Language" />
        </StyledMenuToggler>
      </Dropdown>
    </div>
  );
};

export { LanguageDropdown };
