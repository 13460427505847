export const SUCCESS = 'ALERT_SUCCESS';
export const INFO = 'ALERT_INFO';
export const ERROR = 'ALERT_ERROR';
export const CLEAR = 'ALERT_CLEAR';
export const ALERT_GLOBAL_OPEN = 'ALERT_GLOBAL_OPEN';
export const ALERT_GLOBAL_CLOSE = 'ALERT_GLOBAL_CLOSE';

export const RADIS_GENERAL_ISSUE = 'RADIS_GENERAL_ISSUE';
export const RADISCACHE_TIMEOUT_ISSUE = 'RADISCACHE_TIMEOUT_ISSUE';
export const RADISCACHE_COMMAND_ISSUE = 'RADISCACHE_COMMAND_ISSUE';
