export const FOLDERS_DONT_ALLOW_ADD_SUB = ['', '/', /^\/ProductFactory\/.*/g];
export const DOCUMENT_FOLDER_REGEX = /^(^\/products\/[^/]+\/productfactory\/documents$|^\/products\/[^/]+\/productfactory\/documents\/[^/]+$)$/;
export const SERVICE_FOLDER_REGEX = /^\/products\/[^/]+\/productfactory\/engines$/;
export const PRODUCT_FOLDER_REGEX = /^\/products$/;
export const SPARK_CONFIG_REGEX = /^\/apps\/sparkconfig$/;
export const PRODUCT_FACTORY_FOLDER_PATH = '/products/';

export const GET_FOLDER_REQUEST = 'GET_FOLDER_REQUEST';
export const GET_FOLDER_SUCCESS = 'GET_FOLDER_SUCCESS';
export const GET_FOLDER_FAILURE = 'GET_FOLDER_FAILURE';

export const UPDATE_FILE_REQUEST = 'UPDATE_FILE_REQUEST';
export const UPDATE_FILE_SUCCESS = 'UPDATE_FILE_SUCCESS';
export const UPDATE_FILE_FAILURE = 'UPDATE_FILE_FAILURE';

export const DELETE_FOLDER_REQUEST = 'DELETE_FOLDER_REQUEST';
export const DELETE_FOLDER_SUCCESS = 'DELETE_FOLDER_SUCCESS';
export const DELETE_FOLDER_FAILURE = 'DELETE_FOLDER_FAILURE';

export const ADD_FOLDER = 'ADD_FOLDER';
export const SAVE_NEW_FOLDER_REQUEST = 'SAVE_NEW_FOLDER_REQUEST';
export const SAVE_NEW_FOLDER_SUCCESS = 'SAVE_NEW_FOLDER_SUCCESS';
export const SAVE_NEW_FOLDER_FAILURE = 'SAVE_NEW_FOLDER_FAILURE';

export const UPLOAD_FILE_OPEN = 'UPLOAD_FILE_OPEN';
export const UPLOAD_FILE_CLOSE = 'UPLOAD_FILE_CLOSE';
export const UPLOAD_FILE_START = 'UPLOAD_FILE_START';
export const UPLOAD_FILE_PROGRESS = 'UPLOAD_FILE_PROGRESS';
export const UPLOAD_FILE_XHR_REF = 'UPLOAD_FILE_XHR_REF';
export const UPLOAD_FILE_SUCCESS = 'UPLOAD_FILE_SUCCESS';
export const UPLOAD_FILE_FAILURE = 'UPLOAD_FILE_FAILURE';
export const UPLOAD_FILE_RESET = 'UPLOAD_FILE_RESET';

export const DELETE_FILE_REQUEST = 'DELETE_FILE_REQUEST';
export const DELETE_FILE_SUCCESS = 'DELETE_FILE_SUCCESS';
export const DELETE_FILE_FAILURE = 'DELETE_FILE_FAILURE';

export const GET_FOLDER_DETAILS_REQUEST = 'GET_FOLDER_DETAILS_REQUEST';
export const GET_FOLDER_DETAILS_SUCCESS = 'GET_FOLDER_DETAILS_SUCCESS';
export const GET_FOLDER_DETAILS_FAILURE = 'GET_FOLDER_DETAILS_FAILURE';

export const SET_WAITING_SELECTED_FOLDER_DETAILS =
  'SET_WAITING_SELECTED_FOLDER_DETAILS';

export const GET_FILE_DETAILS_REQUEST = 'GET_FILE_DETAILS_REQUEST';
export const GET_FILE_DETAILS_SUCCESS = 'GET_FILE_DETAILS_SUCCESS';
export const GET_FILE_DETAILS_FAILURE = 'GET_FILE_DETAILS_FAILURE';

export const UPDATE_FOLDER_REQUEST = 'UPDATE_FOLDER_REQUEST';
export const UPDATE_FOLDER_SUCCESS = 'UPDATE_FOLDER_SUCCESS';
export const UPDATE_FOLDER_FAILURE = 'UPDATE_FOLDER_FAILURE';

export const CANCEL_NEW_FOLDER = 'CANCEL_NEW_FOLDER';
