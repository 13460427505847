import { UserConstants } from '../constants';
import { UserAction } from '../actions/types';

export const initialState: STATES.UserState = {
  userInfo: null,
};

export const userState = (
  state = initialState,
  action: UserAction
): STATES.UserState => {
  switch (action.type) {
    case UserConstants.LOGIN_SUCCESS: {
      const { userInfo } = action.payload;

      return {
        ...state,
        userInfo,
      };
    }
    case UserConstants.LOGOUT: {
      const logoutEvent = new CustomEvent('logout');
      window.dispatchEvent(logoutEvent);
      return { ...initialState };
    }
    default:
      return state;
  }
};
