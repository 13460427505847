import React, { FC, RefObject } from 'react';
import { Input } from 'antd';
import { PasswordProps } from 'antd/lib/input';
import styled from 'styled-components';
import { colorHexToRgba } from '../../../helpers/styled-color-opacity';

const StyledPasswordInput = styled(props => <Input.Password {...props} />)<{
  hasError?: boolean;
}>`
  && {
    .anticon.ant-input-password-icon {
      font-size: 30px;
    }

    ${({ hasError, theme }) =>
      hasError &&
      `
      .ant-input {
        border-color: ${theme.color.red};
        background-color: ${colorHexToRgba(
          theme.color.red,
          0.2
        )} !important;
  
        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus {
          -webkit-text-fill-color: ${theme.color.dark};
          -webkit-box-shadow: 0 0 0 100px ${colorHexToRgba(
            theme.color.red,
            0.2
          )}  inset;
          transition: background-color 5000s ease-in-out 0s;
        }
      }
    `}
  }
`;

interface InputPasswordProps extends PasswordProps {
  forwardRef?: React.Ref<PasswordProps> | RefObject<PasswordProps>;
  hasError?: boolean;
}

const InputPassword: FC<InputPasswordProps> = ({ forwardRef, ...props }) => {
  return <StyledPasswordInput ref={forwardRef} {...props} visibilityToggle />;
};

const InputPasswordForwardRef = React.forwardRef(
  (props: InputPasswordProps, ref: React.Ref<PasswordProps>) => (
    <InputPassword forwardRef={ref} {...props} />
  )
);

export { InputPasswordForwardRef as InputPassword };
