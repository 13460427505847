import React, { FC, ReactNode, useCallback, useContext, useState } from 'react';
import { Button, ButtonProps, Form, Input, Modal, Row } from 'antd';
import { ModalFuncProps } from 'antd/es/modal';
import {
  FormattedHTMLMessage,
  FormattedMessage,
  IntlShape,
  RawIntlProvider,
  useIntl,
} from 'react-intl';
import styled, { ThemeContext, ThemeProvider } from 'styled-components';
import { FormInstance } from 'antd/lib/form';
import { IconSvg, LoadingIndicator } from '../components/Common';

const { confirm, info } = Modal;

export type ModalFunc = (
  props: ModalFuncProps
) => {
  destroy: () => void;
  update: (newConfig: ModalFuncProps) => void;
};

type IconColorType = 'dangerColor' | 'primaryColor' | 'secondaryColor';
type StringOrElementType = JSX.Element | string | React.ReactNode;
interface ModalConfig extends ModalFuncProps {
  iconType?: string;
  titleValues?: Record<string, PrimitiveType>;
  contentValues?: Record<string, PrimitiveType>;
  contentFontSize?: 'normal' | 'smaller';
  onOkAsync?: () => Promise<void>;
  showIcon?: boolean;
  iconColor?: IconColorType;
  className?: string;
  maskClosable?: boolean;
  keyboard?: boolean;
}

export interface DeleteModalConfig {
  title: StringOrElementType;
  deleteItemNameTitle?: string;
  deleteItemNameValue?: string;
  extraInfo?: StringOrElementType;
  description: StringOrElementType;
  onOkAsync?: () => Promise<void>;
  onOk?: () => void;
  onCancel?: () => void;
  afterClose?: () => void;
  showWarning?: boolean;
  okText?: string;
  cancelText?: string;
  okButtonProps?: ButtonProps;
  cancelButtonProps?: ButtonProps;
  showDeleteInputField?: boolean;
  maskClosable?: boolean;
  keyboard?: boolean;
  titleIcon?: JSX.Element | React.ReactNode;
}

const ConfirmTitle = styled.div<{
  contentFontSize?: ModalConfig['contentFontSize'];
  iconColor?: IconColorType;
}>`
  font-size: 24px;
  font-weight: normal;
  text-align: center;
  color: ${({ theme }) => theme.color.dark};
  font-size: ${({ contentFontSize = 'normal' }) =>
    contentFontSize === 'normal' ? '24px' : '22px'};
  .anticon {
    margin-bottom: 20px;
    color: ${({ theme, iconColor }) =>
      iconColor ? `${theme.vars[iconColor]}` : theme.color.red};
    font-size: 50px;
    display: block;
  }
`;

const ConfirmContent = styled.div<{
  contentFontSize: ModalConfig['contentFontSize'];
  iconColor?: 'dangerColor' | 'primaryColor' | 'secondaryColor';
}>`
  color: ${({ theme }) => theme.color.gray1};
  font-size: ${({ contentFontSize = 'normal' }) =>
    contentFontSize === 'normal' ? '16px' : '18px'};
`;

const StyledDeleteTitle = styled.div`
  color: ${({ theme }) => theme.color.dark};
  line-height: 27px;
  text-align: center;
  font-size: 24px;
`;

const StyledDeleteFile = styled.div`
  margin-top: 16px;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: ${({ theme }) => theme.color.dark};
  .value {
    margin-left: 8px;
    font-weight: bold;
    word-break: break-all;
  }
`;

const StyledExtraInfo = styled.div`
  margin-top: 4px;
  font-size: 16px;
  text-align: left;
  color: ${({ theme }) => theme.color.dark};
`;

const StyledDescription = styled.div`
  margin-top: 16px;
  margin-bottom: 16px;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: ${({ theme }) => theme.color.dark};
`;

const StyledIconSvg = styled(IconSvg)`
  font-size: 42px;
  color: ${({ theme }) => theme.color.red} !important;
`;

const StyledForm = styled(Form)`
  .ant-form-item {
    justify-content: center;
    margin-bottom: 0px;
    .ant-form-item-label {
      align-items: baseline;
    }
  }
  &.delete-modal-form {
    .ant-form-item-row {
      justify-content: center;
    }
  }
`;
interface AppContextProps {
  intl: IntlShape;
  themeContext: {};
}

type resolveButtonTextType = string | ReactNode | null | undefined;

const AppContext: FC<AppContextProps> = ({ intl, themeContext, children }) => {
  return (
    <RawIntlProvider value={intl}>
      <ThemeProvider theme={themeContext}>{children}</ThemeProvider>
    </RawIntlProvider>
  );
};

interface ConfirmContentTitleProps extends AppContextProps {
  iconType?: string;
  content?: ReactNode;
  contentValues: Record<string, PrimitiveType> | undefined;
  type: 'title' | 'content';
  contentFontSize?: ModalConfig['contentFontSize'];
  loading?: boolean;
  showIcon?: boolean;
  iconColor?: 'dangerColor' | 'primaryColor' | 'secondaryColor';
}

const destroyModalCallback = (
  modal: ReturnType<ModalFunc>,
  before?: () => void
) => {
  before && before();

  modal.destroy();
};

interface DeleteModalContentProps extends AppContextProps {
  title: StringOrElementType;
  deleteItemNameTitle?: string;
  deleteItemNameValue?: string;
  extraInfo?: StringOrElementType;
  description: StringOrElementType;
  form?: FormInstance;
  showDeleteInputField?: boolean;
  showWarning?: boolean;
  okText?: string;
  cancelText?: string;
  onOkAsync?: () => Promise<void>;
  onOk?: () => void;
  onCancel?: () => void;
  okButtonProps?: ButtonProps;
  cancelButtonProps?: ButtonProps;
  handleHideConfirm: () => void;
}

const ConfirmContentTitle: FC<ConfirmContentTitleProps> = ({
  intl,
  themeContext,
  iconType,
  content,
  contentValues,
  type = 'title',
  contentFontSize,
  loading = false,
  showIcon = true,
  iconColor,
}) => {
  const Component = type === 'title' ? ConfirmTitle : ConfirmContent;
  return (
    <AppContext intl={intl} themeContext={themeContext}>
      <Component iconColor={iconColor} contentFontSize={contentFontSize}>
        {type === 'title' && showIcon === true && (
          <IconSvg type={iconType || 'WarmIcon'} />
        )}
        {content &&
          (typeof content === 'string' ? (
            <FormattedHTMLMessage id={content} values={contentValues} />
          ) : (
            content
          ))}
      </Component>
      {loading && <LoadingIndicator spinning>&nbsp;</LoadingIndicator>}
    </AppContext>
  );
};

const DeleteModalContent: FC<DeleteModalContentProps> = ({
  intl,
  themeContext,
  title,
  deleteItemNameTitle,
  deleteItemNameValue,
  extraInfo,
  description,
  form,
  showDeleteInputField,
  showWarning,
  okText,
  cancelText,
  onOkAsync,
  onOk,
  onCancel,
  okButtonProps,
  cancelButtonProps,
  handleHideConfirm,
}) => {
  const layout = {
    wrapperCol: {
      span: 13,
    },
  };
  const [loading, setloading] = useState(false);
  const onClickOk = async () => {
    const finalFn =
      typeof onOkAsync === 'function'
        ? async () => {
            if (showDeleteInputField) {
              form &&
                form
                  .validateFields()
                  .then(async () => {
                    setloading(true);
                    onOkAsync && (await onOkAsync());
                    form.resetFields();
                    handleHideConfirm();
                    setloading(false);
                  })
                  .catch(() => {
                    form.submit();
                  });
            } else {
              setloading(true);
              onOkAsync && (await onOkAsync());
              form && form.resetFields();
              handleHideConfirm();
              setloading(false);
            }
          }
        : () => {
            onOk && onOk();
            form && form.resetFields();
            handleHideConfirm();
          };

    finalFn();
  };

  const onClickCancel = async () => {
    onCancel && onCancel();
    handleHideConfirm();
  };
  return (
    <AppContext intl={intl} themeContext={themeContext}>
      <StyledDeleteTitle>
        {typeof title === 'string' ? (
          <FormattedHTMLMessage id={title} />
        ) : (
          title
        )}
      </StyledDeleteTitle>
      {deleteItemNameTitle && (
        <StyledDeleteFile>
          <span>{intl.formatMessage({ id: deleteItemNameTitle })}</span>
          <span className="value">{deleteItemNameValue}</span>
        </StyledDeleteFile>
      )}
      {extraInfo && <StyledExtraInfo>{extraInfo}</StyledExtraInfo>}
      {description && (
        <StyledDescription>
          {typeof description === 'string' ? (
            <FormattedHTMLMessage id={description} />
          ) : (
            description
          )}
        </StyledDescription>
      )}
      {showWarning && showDeleteInputField && (
        <StyledForm
          {...layout}
          form={form}
          onFinish={async () => {
            await onClickOk();
          }}
          name="delete-modal-form"
          className="delete-modal-form"
        >
          <Form.Item
            name="deleteInput"
            className="h-58"
            label={<FormattedMessage id="Global.message.input.label" />}
            validateFirst
            initialValue=""
            validateTrigger={['onChange', 'onSubmit']}
            rules={[
              {
                required: true,
                message: intl.formatMessage({
                  id: 'Global.message.input.deleteConfirm',
                }),
                validateTrigger: ['onChange', 'onSubmit'],
              },
              {
                validator: async (_, value: string) => {
                  const enteredText = value || '';
                  const deleteText = 'DELETE';
                  if (
                    enteredText !== deleteText.substring(0, enteredText.length)
                  ) {
                    throw new Error(
                      intl.formatMessage({
                        id: 'Global.message.input.deleteConfirm',
                      })
                    );
                  }
                },
                validateTrigger: ['onChange', 'onSubmit'],
              },
              {
                enum: ['DELETE'],
                type: 'enum',
                message: intl.formatMessage({
                  id: 'Global.message.input.deleteConfirm',
                }),
                validateTrigger: ['onSubmit'],
              },
            ]}
          >
            <Input allowClear size="middle" autoComplete="off" />
          </Form.Item>
        </StyledForm>
      )}
      {showWarning && (
        <Row justify="center" className="mt-24">
          <Button
            type="default"
            shape="round"
            size="large"
            className="mw-200 mx-4"
            {...cancelButtonProps}
            onClick={onClickCancel}
          >
            {resolveButtonText(cancelText, intl, 'Global.cancel')}
          </Button>
          <Button
            type="primary"
            danger
            shape="round"
            size="large"
            className="mw-200 mx-8"
            {...okButtonProps}
            onClick={async () => {
              if (typeof onOkAsync === 'function' && showDeleteInputField) {
                form && form.submit();
              } else {
                await onClickOk();
              }
            }}
          >
            {resolveButtonText(okText, intl, 'Global.delete')}
          </Button>
        </Row>
      )}
      {!showWarning && (
        <Row justify="center" className="mt-24">
          <Button
            type="primary"
            shape="round"
            size="large"
            className="mw-200 mx-8"
            {...okButtonProps}
            onClick={onClickOk}
          >
            {resolveButtonText(okText, intl, 'Global.ok')}
          </Button>
        </Row>
      )}
      {loading && <LoadingIndicator spinning>&nbsp;</LoadingIndicator>}
    </AppContext>
  );
};

const resolveButtonText = (
  text: resolveButtonTextType,
  intl: IntlShape,
  defaultText: string
) => {
  if (text == null || typeof text === 'string') {
    return intl.formatMessage({ id: text || defaultText });
  }

  return text;
};

const useModal = () => {
  const intl = useIntl();
  const themeContext = useContext(ThemeContext);
  const [form] = Form.useForm();

  const showConfirm = useCallback(
    ({
      iconType,
      title,
      titleValues,
      okText,
      cancelText,
      width,
      content,
      contentValues,
      centered,
      showIcon,
      iconColor,
      onOkAsync,
      onOk,
      onCancel,
      contentFontSize = 'normal',
      className,
      cancelButtonProps = {},
      okButtonProps = {},
      ...rest
    }: ModalConfig) => {
      const modal = confirm({
        ...rest,
        icon: null,
        centered: typeof centered === 'boolean' ? centered : true,
        width: width || 700,
        maskClosable: true,
        keyboard: true,
        className,
        title: (
          <ConfirmContentTitle
            intl={intl}
            themeContext={themeContext}
            iconType={iconType || 'WarningIcon'}
            contentValues={titleValues}
            content={title}
            type="title"
            iconColor={iconColor}
            showIcon={showIcon}
            contentFontSize={contentFontSize}
          />
        ),
        content: content && (
          <ConfirmContentTitle
            intl={intl}
            themeContext={themeContext}
            type="content"
            contentValues={contentValues}
            content={content}
            contentFontSize={contentFontSize}
          />
        ),
        okText: resolveButtonText(okText, intl, 'Global.confirm'),
        okButtonProps: {
          type: 'primary',
          shape: 'round',
          className: 'mw-200 mx-8',
          size: 'large',
          ...okButtonProps,
        },
        cancelText: resolveButtonText(cancelText, intl, 'Global.cancel'),
        cancelButtonProps: {
          type: 'default',
          shape: 'round',
          className: 'mw-200 mx-8',
          size: 'large',
          ...cancelButtonProps,
        },
        onCancel: () => destroyModalCallback(modal, onCancel),
        onOk:
          typeof onOkAsync === 'function'
            ? async () => {
                modal.update({
                  content: (
                    <ConfirmContentTitle
                      intl={intl}
                      themeContext={themeContext}
                      type="content"
                      contentValues={contentValues}
                      content={content}
                      contentFontSize={contentFontSize}
                      loading
                    />
                  ),
                });

                await onOkAsync();

                modal.destroy();
              }
            : () => destroyModalCallback(modal, onOk),
      });

      return modal;
    },
    [intl, themeContext]
  );

  const showInfo = useCallback(
    ({
      iconType,
      title,
      titleValues,
      okText,
      width,
      content,
      contentValues,
      centered,
      onOk,
      showIcon,
      iconColor,
      contentFontSize = 'normal',
      ...rest
    }: ModalConfig) => {
      const modal = info({
        ...rest,
        icon: null,
        centered: typeof centered === 'boolean' ? centered : true,
        width: width || 700,
        title: (
          <ConfirmContentTitle
            intl={intl}
            themeContext={themeContext}
            iconType={iconType || 'warning'}
            contentValues={titleValues}
            content={title}
            type="title"
            showIcon={showIcon}
            iconColor={iconColor}
          />
        ),
        content: content && (
          <ConfirmContentTitle
            intl={intl}
            themeContext={themeContext}
            type="content"
            contentValues={contentValues}
            content={content}
            contentFontSize={contentFontSize}
          />
        ),
        okText: resolveButtonText(okText, intl, 'Global.ok'),
        okButtonProps: {
          type: 'primary',
          shape: 'round',
          className: 'mw-200 mx-8',
        },
        onOk: () => destroyModalCallback(modal, onOk),
      });

      return modal;
    },
    [intl, themeContext]
  );

  const showDeleteConfirm = useCallback(
    ({
      showWarning = true,
      title,
      deleteItemNameTitle,
      deleteItemNameValue,
      extraInfo,
      description,
      onOkAsync,
      onOk,
      onCancel,
      okText,
      cancelText,
      showDeleteInputField,
      okButtonProps,
      cancelButtonProps,
      maskClosable = false,
      keyboard = false,
      titleIcon,
      afterClose,
    }: DeleteModalConfig) => {
      let modal: ReturnType<ModalFunc>;
      const handleHideConfirm = () => {
        modal.destroy();
      };
      const commonConfig: ModalFuncProps = {
        icon: null,
        width: 600,
        maskClosable,
        keyboard,
        centered: true,
        afterClose: () => {
          form.resetFields();
          afterClose && afterClose();
        },
        className: 'hide-confirm-btn',
        title: (
          <AppContext intl={intl} themeContext={themeContext}>
            {titleIcon || <StyledIconSvg type="WarningIcon" />}
          </AppContext>
        ),
        content: (
          <DeleteModalContent
            intl={intl}
            themeContext={themeContext}
            title={title}
            deleteItemNameTitle={deleteItemNameTitle}
            deleteItemNameValue={deleteItemNameValue}
            extraInfo={extraInfo}
            description={description}
            form={form}
            showWarning={showWarning}
            showDeleteInputField={showDeleteInputField}
            okText={okText}
            cancelText={cancelText}
            onOk={onOk}
            onOkAsync={onOkAsync}
            onCancel={onCancel}
            okButtonProps={okButtonProps}
            cancelButtonProps={cancelButtonProps}
            handleHideConfirm={handleHideConfirm}
          />
        ),
        autoFocusButton: null,
      };
      if (showWarning) {
        modal = confirm({
          ...commonConfig,
        });

        return modal;
      }
      modal = info({
        ...commonConfig,
      });
      return modal;
    },
    [intl, themeContext]
  );

  return { showConfirm, showInfo, showDeleteConfirm };
};

export { useModal };
