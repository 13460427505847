import { EngineAction, EngineChainAction, UserAction } from '../actions/types';
import { EngineConstants, UserConstants } from '../constants';
import { arrayRemoveFirst, arrayReplaceFirst } from '../helpers';

export const initialState: STATES.ProductEnginesState = {
  isLoading: false,
  isExecuting: false,
  engines: [],
  page: 1,
  totalPages: 0,
  total: 0,
  pageSize: 3,
  searchText: '',
  refreshTrigger: 0,
  selectedEngineName: null,
  selectedEngine: null,
  tempSelectedEngine: null,
  selectedRevision: null,
  isLoadingDetails: false,
  isLoadingExecuteInputs: false,
  isUpdating: false,
  updateSuccess: false,
  sortBy: 'updated',
  generatingTestCases: false,
  testbedVersionId: null,
  generatedTestData: null,
  propertiesChanges: null,
  selectedVersionId: null,
  chainedEngines: [],
  unChainedEngines: [],
  showWarningMessage: false,
  releaseNoteUpdated: false,
  isLoadingLanagugeContent: false,
  isLoadingLanaugeList: false,
  languageContent: '',
  languageList: [],
  recompileJobId: null,
  isRecompileLoading: false,
  recompileProgressPercent: 0,
  recompileProgressStatus: 'active',
  recompileStatus: null,
  recompileServiceId: null,
};

type ActionType = EngineAction | UserAction | EngineChainAction;
export const productEngines = (
  state = initialState,
  action: ActionType
): STATES.ProductEnginesState => {
  switch (action.type) {
    case EngineConstants.GET_PRODUCT_ENGINES_REQUEST: {
      const { page, searchText, sortBy, pageSize } = action.payload;
      return {
        ...state,
        isLoading: true,
        page,
        searchText,
        sortBy,
        pageSize,
      };
    }
    case EngineConstants.GET_PRODUCT_ENGINES_SUCCESS: {
      const {
        engines,
        page,
        searchText,
        total,
        sortBy,
        fakeSortingPage = null,
      } = action.payload;

      if (
        searchText !== state.searchText ||
        page !== state.page ||
        sortBy !== state.sortBy
      ) {
        return state;
      }

      let enginesList: DTO.Engine[] = [];
      if (fakeSortingPage) {
        enginesList = [...engines];
      } else {
        enginesList = page === 1 ? engines : [...state.engines, ...engines];
      }

      return {
        ...state,
        isLoading: false,
        engines: enginesList,
        totalPages:
          Math.floor(total / state.pageSize) + (total % state.pageSize ? 1 : 0),
        total,
      };
    }

    case EngineConstants.GET_PRODUCT_ENGINES_FAILURE:
    case EngineConstants.RESTORE_VERSION_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case EngineConstants.GET_PRODUCT_ENGINE_DETAILS_REQUEST: {
      const { serviceName, revision, versionId } = action.payload;

      return {
        ...state,
        selectedEngineName: serviceName,
        selectedVersionId: versionId,
        selectedRevision: revision,
        isLoadingDetails: true,
      };
    }
    case EngineConstants.GET_PRODUCT_ENGINE_DETAILS_SUCCESS: {
      const { engine, serviceName, versionId } = action.payload;

      if (
        state.selectedEngineName !== serviceName ||
        state.selectedVersionId !== versionId
      ) {
        return state;
      }

      if (versionId && versionId !== '') {
        engine.bookSummary.versionId = versionId;
      }

      const existingEngine = state.engines.find(
        e => e.serviceName === state.selectedEngineName
      );
      if (existingEngine && existingEngine.versions) {
        engine.versions = existingEngine.versions;
      }

      if (engine.xlInputs && engine.xlInputs.length) {
        engine.xlInputs.forEach((item, index) => {
          if (item['type'] === 'XJ' && item['xjName']) {
            try {
              item['value'] = JSON.parse(item['value']);
            } catch (e) {
              //
            }
          }
        });
      }
      return {
        ...state,
        generatingTestCases: false,
        testbedVersionId: null,
        selectedEngine: engine,
        isLoadingDetails: false,
      };
    }

    case EngineConstants.GET_PRODUCT_ENGINE_DETAILS_SUCCESS_NO_SELECT: {
      const { engine, versionId } = action.payload;

      if (versionId && versionId !== '') {
        engine.bookSummary.versionId = versionId;
      }

      return {
        ...state,
        tempSelectedEngine: engine,
        isLoadingDetails: false,
      };
    }
    case EngineConstants.GET_PRODUCT_ENGINE_DETAILS_FAILURE: {
      const { serviceName, versionId } = action.payload;

      if (
        state.selectedEngineName !== serviceName ||
        state.selectedVersionId !== versionId
      ) {
        return state;
      }

      return {
        ...state,
        isLoadingDetails: false,
      };
    }
    case EngineConstants.API_TEST_EXECUTE_INPUTS: {
      return {
        ...state,
        isLoadingExecuteInputs: true,
      };
    }
    case EngineConstants.API_TEST_EXECUTE_INPUTS_SUCCESS: {
      const { xlInputs } = action.payload;
      if (state.selectedEngine) {
        const tempSelectedEngine = {
          ...state.selectedEngine,
          xlInputs,
          oldXlInputs: state.selectedEngine?.oldXlInputs
            ? state.selectedEngine?.oldXlInputs
            : state.selectedEngine.xlInputs,
        };
        return {
          ...state,
          selectedEngine: tempSelectedEngine,
          isLoadingExecuteInputs: false,
        };
      }
      return {
        ...state,
        isLoadingExecuteInputs: false,
      };
    }
    case EngineConstants.API_TESTER_XLINPUT_RESET: {
      if (state.selectedEngine) {
        const tempSelectedEngine = {
          ...state.selectedEngine,
          xlInputs: state.selectedEngine?.oldXlInputs
            ? state.selectedEngine?.oldXlInputs
            : state.selectedEngine.xlInputs,
          oldXlInputs: undefined,
        };
        return {
          ...state,
          selectedEngine: tempSelectedEngine,
          isLoadingExecuteInputs: false,
        };
      }
      return {
        ...state,
        isLoadingExecuteInputs: false,
      };
    }
    case EngineConstants.API_TEST_EXECUTE_INPUTS_FAILURE: {
      return {
        ...state,
        isLoadingExecuteInputs: false,
      };
    }
    case EngineConstants.UPDATE_ENGINE_PROPS_REQUEST: {
      return {
        ...state,
        updateSuccess: false,
        isUpdating: true,
      };
    }
    case EngineConstants.UPDATE_ENGINE_PROPS_SUCCESS: {
      const { serviceName, jobId } = action.payload;
      if (serviceName !== state.selectedEngineName) {
        return state;
      }

      return {
        ...state,
        updateSuccess: true,
        isUpdating: false,
        refreshTrigger: state.refreshTrigger + 1,
        releaseNoteUpdated: true,
        recompileJobId: jobId,
      };
    }
    case EngineConstants.UPDATE_ENGINE_PROPS_FAILURE: {
      return {
        ...state,
        isUpdating: false,
      };
    }
    case EngineConstants.RECOMPILE_NODEGEN_REQUEST: {
      return {
        ...state,
        updateSuccess: false,
        isUpdating: true,
        isLoading: true,
        recompileProgressPercent: 2,
      };
    }
    case EngineConstants.RECOMPILE_NODEGEN_SUCCESS: {
      const { jobId } = action.payload;
      return {
        ...state,
        updateSuccess: true,
        isUpdating: false,
        isLoading: false,
        refreshTrigger: state.refreshTrigger + 1,
        releaseNoteUpdated: true,
        recompileJobId: jobId,
      };
    }
    case EngineConstants.RECOMPILE_NODEGEN_FAILURE: {
      return {
        ...state,
        isUpdating: false,
        isLoading: false,
        recompileProgressPercent: 100,
        recompileProgressStatus: 'exception',
        recompileStatus: 'Fail',
      };
    }
    case EngineConstants.UPDATE_ENGINE_FAVORITE_SUCCESS:
    case EngineConstants.UPDATE_ENGINE_FAVORITE_REQUEST: {
      const { serviceName, favorite } = action.payload;

      return {
        ...state,
        engines: arrayReplaceFirst(
          state.engines,
          engine => engine.serviceName === serviceName,
          engine => ({
            ...engine,
            isStarred: favorite,
          })
        ),
      };
    }

    case EngineConstants.DELETE_ENGINE_SUCCESS: {
      const { serviceName } = action.payload;

      return {
        ...state,
        engines: arrayRemoveFirst(
          state.engines,
          engine => engine.serviceName === serviceName
        ),
        refreshTrigger: state.refreshTrigger + 1,
      };
    }

    case EngineConstants.UPDATE_ENGINE_PROPS: {
      const { data } = action.payload;
      return {
        ...state,
        propertiesChanges: data,
      };
    }

    case EngineConstants.CANCEL_UPDATE_CONFIRM: {
      return {
        ...state,
        propertiesChanges: null,
        releaseNoteUpdated: false,
      };
    }

    case EngineConstants.PRODUCT_ENGINE_SELECTION_RESET:
    case UserConstants.LOGOUT: {
      return { ...initialState };
    }

    case EngineConstants.PRODUCT_ENGINES_RESET: {
      return {
        ...initialState,
        engines: state.engines,
      };
    }

    case EngineConstants.GET_ENGINE_VERSIONS_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case EngineConstants.GET_ENGINE_VERSIONS_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case EngineConstants.GET_ENGINE_VERSIONS_SUCCESS: {
      const { serviceName, versions } = action.payload;
      const tempSelectedEngine = state.selectedEngine;
      if (tempSelectedEngine && state.selectedEngineName === serviceName) {
        tempSelectedEngine.versions = versions;
      }

      return {
        ...state,
        engines: arrayReplaceFirst(
          state.engines,
          engine => engine.serviceName === serviceName,
          engine => ({ ...engine, versions })
        ),
        selectedEngine: tempSelectedEngine,
        selectedEngineVersions: versions,
        isLoading: false,
      };
    }

    case EngineConstants.SELECTED_ENGINE_VERSIONS_RESET: {
      return {
        ...state,
        selectedEngineVersions: undefined,
      };
    }

    case EngineConstants.GET_ENGINE_CHAIN_REQUEST: {
      return {
        ...state,
        isExecuting: true,
        chainedEngines: [],
        showWarningMessage: false,
      };
    }

    case EngineConstants.CREATE_ENGINE_CHAIN_REQUEST:
    case EngineConstants.DELETE_ENGINE_CHAIN_REQUEST:
    case EngineConstants.GET_ENGINE_UNCHAIN_CHAIN_REQUEST: {
      return {
        ...state,
        isExecuting: true,
        showWarningMessage: false,
      };
    }

    case EngineConstants.RESTORE_VERSION_REQUEST: {
      return {
        ...state,
        isLoading: true,
        showWarningMessage: false,
      };
    }

    case EngineConstants.RESTORE_VERSION_SUCCESS: {
      const { latestVersionId, selectedEngineName } = action.payload;
      return {
        ...state,
        latestVersionId,
        selectedEngineName,
        isLoading: false,
      };
    }

    case EngineConstants.DELETE_ENGINE_CHAIN_SUCCESS:
    case EngineConstants.GET_ENGINE_CHAIN_FAILURE:
    case EngineConstants.CREATE_ENGINE_CHAIN_FAILURE:
    case EngineConstants.GET_ENGINE_UNCHAIN_CHAIN_FAILURE:
    case EngineConstants.CREATE_ENGINE_CHAIN_SUCCESS:
    case EngineConstants.DELETE_ENGINE_CHAIN_FAILURE: {
      return {
        ...state,
        isExecuting: false,
      };
    }

    case EngineConstants.GET_ENGINE_CHAIN_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        isExecuting: false,
        chainedEngines: data,
      };
    }

    case EngineConstants.GET_ENGINE_UNCHAIN_CHAIN_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        isExecuting: false,
        unChainedEngines: data,
      };
    }

    case EngineConstants.ENGINE_CHAIN_MISMATCH_ERROR: {
      return {
        ...state,
        showWarningMessage: !state.showWarningMessage,
        isExecuting: false,
      };
    }

    case EngineConstants.DOWNLOAD_ENGINE_REQUEST: {
      return {
        ...state,
        isLoadingDetails: true,
        isLoading: true,
        isExecuting: true,
      };
    }

    case EngineConstants.DOWNLOAD_ENGINE_FAILURE:
    case EngineConstants.DOWNLOAD_ENGINE_SUCCESS: {
      return {
        ...state,
        isLoadingDetails: false,
        isLoading: false,
        isExecuting: false,
      };
    }

    case EngineConstants.ACIVATE_SERVICE_REQUEST: {
      return {
        ...state,
      };
    }

    case EngineConstants.ACIVATE_SERVICE_SUCCESS: {
      const {
        productName,
        serviceName,
        isActive,
        isActiveServiceFilterApplied,
        refreshServiceList,
      } = action.payload;
      const tempSelectedEngine = state.selectedEngine;
      const tempEngines = state.engines;

      if (tempSelectedEngine) {
        if (
          tempSelectedEngine.bookSummary.bookServiceName === serviceName &&
          tempSelectedEngine.bookSummary.product === productName
        ) {
          tempSelectedEngine.isActiveService = isActive;
        }
      }

      const tempEngineIndex = tempEngines.findIndex(
        engine => engine.serviceName === serviceName
      );

      if (isActiveServiceFilterApplied) {
        tempEngines.splice(tempEngineIndex, 1);
      } else if (tempEngineIndex !== -1) {
        tempEngines[tempEngineIndex].isActiveService = isActive;
      }

      return {
        ...state,
        engines: [...tempEngines],
        selectedEngine: tempSelectedEngine,
        refreshTrigger: refreshServiceList
          ? state.refreshTrigger + 1
          : state.refreshTrigger,
      };
    }

    case EngineConstants.ACIVATE_SERVICE_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case EngineConstants.GET_ALL_CODE_LANGUAGE_REQUEST: {
      return {
        ...state,
        isLoadingLanaugeList: true,
      };
    }

    case EngineConstants.GET_ALL_CODE_LANGUAGE_SUCCESS: {
      const { codeLangugeList } = action.payload;
      return {
        ...state,
        isLoadingLanaugeList: false,
        languageList: codeLangugeList,
      };
    }

    case EngineConstants.GET_ALL_CODE_LANGUAGE_FAILURE: {
      return {
        ...state,
        isLoadingLanaugeList: false,
      };
    }

    case EngineConstants.GET_CODE_CONTENT_REQUEST: {
      return {
        ...state,
        isLoadingLanagugeContent: true,
      };
    }

    case EngineConstants.GET_CODE_CONTENT_SUCCESS: {
      const { codeContent } = action.payload;
      return {
        ...state,
        isLoadingLanagugeContent: false,
        languageContent: codeContent,
      };
    }

    case EngineConstants.GET_CODE_CONTENT_FAILURE: {
      return {
        ...state,
        isLoadingLanagugeContent: false,
      };
    }
    case EngineConstants.GET_RECOMPILE_PROGESS_REQUEST: {
      return {
        ...state,
        isRecompileLoading: true,
      };
    }

    case EngineConstants.GET_RECOMPILE_PROGESS_SUCCESS: {
      const { status, progress } = action.payload;
      const newState = {
        ...state,
      };
      if (status === 'Fail') {
        newState['recompileProgressPercent'] = 100;
        newState['recompileProgressStatus'] = 'exception';
        newState['recompileStatus'] = 'Fail';
        newState['isRecompileLoading'] = false;
      } else if (status === 'Success') {
        newState['recompileProgressPercent'] = 100;
        newState['recompileProgressStatus'] = '';
        newState['recompileStatus'] = 'Success';
        newState['isRecompileLoading'] = false;
      } else if (state.recompileProgressPercent < 90) {
        newState['recompileProgressPercent'] =
          state.recompileProgressPercent + progress;
      }

      return newState;
    }

    case EngineConstants.GET_RECOMPILE_PROGESS_FAILURE: {
      return {
        ...state,
        isRecompileLoading: false,
      };
    }
    case EngineConstants.RESET_RECOMPILE_PROGESS: {
      return {
        ...state,
        recompileProgressPercent: 0,
        recompileProgressStatus: 'active',
        isRecompileLoading: false,
        isLoading: false,
        recompileJobId: null,
        recompileStatus: null,
      };
    }
    case EngineConstants.SET_HIGHLIGHT_DATA: {
      const { recompileServiceId } = action.payload;
      return {
        ...state,
        recompileServiceId,
      };
    }
    case EngineConstants.CLEAR_HIGHLIGHT_DATA: {
      return {
        ...state,
        recompileServiceId: null,
      };
    }
    default:
      return state;
  }
};
