import { DmlFileAction } from '../actions/types';
import { DmlFileConstants } from '../constants';

export const initialDmlFileState: STATES.DmlFileState = {
  dmlTaskQueueId: '',
  dmlTaskQueue: null,
};

export const dmlFile = (
  state = initialDmlFileState,
  action: DmlFileAction
): STATES.DmlFileState => {
  switch (action.type) {
    case DmlFileConstants.RUN_DML_UTILITY_REQUEST: {
      return {
        ...state,
        dmlTaskQueueId: '',
        dmlTaskQueue: null,
      };
    }
    case DmlFileConstants.RUN_DML_UTILITY_SUCCESS: {
      const { dmlTaskQueueId } = action.payload;

      return {
        ...state,
        dmlTaskQueueId,
      };
    }
    case DmlFileConstants.RUN_DML_UTILITY_FAILURE:
    case DmlFileConstants.GET_RUN_STATUS_REQUEST: {
      return {
        ...state,
      };
    }
    case DmlFileConstants.GET_RUN_STATUS_SUCCESS: {
      const { dmlTaskQueue } = action.payload;
      return {
        ...state,
        dmlTaskQueue,
      };
    }
    case DmlFileConstants.GET_RUN_STATUS_FAILURE: {
      const tempTaskQueue = state.dmlTaskQueue;
      if (tempTaskQueue) {
        return {
          ...state,
          dmlTaskQueue: {
            ...tempTaskQueue,
            progress: 100,
            status: 'Fail',
          },
        };
      }
      return {
        ...state,
      };
    }
    case DmlFileConstants.CLEAR_DML_JOB: {
      return {
        ...state,
        dmlTaskQueue: null,
      };
    }
    case DmlFileConstants.RESET_DML_STATE: {
      return {
        ...initialDmlFileState,
      };
    }
    default:
      return state;
  }
};
