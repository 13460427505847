import { VersionOverviewConstants } from '../constants';
import { VersionOverviewAction } from '../actions/types';

export const initialState: STATES.VersionOverviewState = {
  compareServiceVersion: {
    folderName: '',
    serviceName: '',
    selectedVersion: [],
    taskQueueId: '',
    compareServiceVersionsResult: null,
  },
};

export const versionOverview = (
  state = initialState,
  action: VersionOverviewAction
): STATES.VersionOverviewState => {
  switch (action.type) {
    case VersionOverviewConstants.COMPARE_SERVICE_VERSIONS_REQUEST: {
      const { folderName, serviceName, selectedVersion } = action.payload;

      return {
        ...state,
        compareServiceVersion: {
          ...initialState.compareServiceVersion,
          folderName,
          serviceName,
          selectedVersion,
        },
      };
    }
    case VersionOverviewConstants.COMPARE_SERVICE_VERSIONS_SUCCESS: {
      const { taskQueueId } = action.payload;
      return {
        ...state,
        compareServiceVersion: {
          ...state.compareServiceVersion,
          taskQueueId,
        },
      };
    }
    case VersionOverviewConstants.COMPARE_SERVICE_VERSIONS_RESET: {
      return {
        ...state,
        compareServiceVersion: {
          ...initialState.compareServiceVersion,
        },
      };
    }
    case VersionOverviewConstants.GET_COMPARE_SERVICE_VERSIONS_RESULT_SUCCESS: {
      const { compareServiceVersionsResult } = action.payload;

      return {
        ...state,
        compareServiceVersion: {
          ...state.compareServiceVersion,
          compareServiceVersionsResult,
        },
      };
    }
    default:
      return state;
  }
};
