import { DeploymentAction } from '../actions/types';
import { DeploymentConstants } from '../constants';

export const deploymentInitialState: STATES.DeploymentRequestState = {
  deploymentRequest: {
    folder: '',
    service: '',
    serviceVersion: '',
    deploymentNotes: '',
    versionId: '',
  },
  saveDeploymentRequest: {
    isDeploymentRequestSaved: false,
    error: '',
    isLoading: false,
  },
};

export const deployment = (
  state = deploymentInitialState,
  action: DeploymentAction
): STATES.DeploymentRequestState => {
  switch (action.type) {
    case DeploymentConstants.DEPLOYMENT_REQUEST_CONFIG_PARAMETERS_CHANGE: {
      return {
        ...state,
        deploymentRequest: {
          ...state.deploymentRequest,
          ...action.payload,
        },
      };
    }

    case DeploymentConstants.DEPLOYMENT_REQUEST_CLEAR_CONFIG_PARAMETERS: {
      return {
        ...deploymentInitialState,
      };
    }

    case DeploymentConstants.SAVE_DEPLOYMENT_REQUEST: {
      return {
        ...state,
        saveDeploymentRequest: {
          ...state.saveDeploymentRequest,
          isDeploymentRequestSaved: false,
          isLoading: true,
          error: '',
        },
      };
    }

    case DeploymentConstants.SAVE_DEPLOYMENT_REQUEST_SUCCESS: {
      const { isSuccess } = action.payload;
      return {
        ...state,
        saveDeploymentRequest: {
          ...state.saveDeploymentRequest,
          isDeploymentRequestSaved: isSuccess,
          isLoading: false,
        },
      };
    }

    case DeploymentConstants.SAVE_DEPLOYMENT_REQUEST_FAILURE: {
      const { error } = action.payload;
      return {
        ...state,
        saveDeploymentRequest: {
          ...state.saveDeploymentRequest,
          isLoading: false,
          error,
        },
      };
    }

    default: {
      return state;
    }
  }
};
