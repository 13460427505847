import {
  Button,
  Card,
  Col,
  Collapse,
  Form,
  Input,
  Layout,
  List,
  Row,
  Tabs,
  Tag,
  Typography,
} from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import styled, { createGlobalStyle } from 'styled-components';
import {
  CustomModal,
  CustomScrollCss,
  CustomTabs,
  ScrollCss,
} from '../components/Common';
import FileUpload from '../components/Upload/FileUpload';
import { colorHexToRgba } from '../helpers';

const { Text, Title } = Typography;
const { Content, Header } = Layout;
const { Panel } = Collapse;
// Testing center
export const StyledPage = styled.div`
  background-color: ${({ theme }) => theme.color.gray10} !important;
  .ant-breadcrumb {
    padding-left: 16px;
  }
  .scrollable-container {
    background-color: ${({ theme }) => theme.color.white};
  }
  .sectiontitle {
    font-size: 20px;
    font-weight: 400;
    color: ${({ theme }) => theme.color.dark80};
    text-align: center;
    width: 100%;
    padding: 16px 0;
  }
  .ant-affix > div:first-child {
    background: ${({ theme }) => theme.color.gray10};
  }
  .ant-affix .sticky-filter {
    margin-top: 0;
    box-shadow: 0px 6px 6px rgb(0 0 0 / 10%);
    background: ${({ theme }) => theme.color.white} !important;
  }
  .ant-table-cell-fix-left,
  .ant-table-cell-fix-right {
    z-index: 1 !important;
  }
`;

export const StyledSubPage = styled.div`
  background-color: ${({ theme }) => theme.color.white};
  .empty-container {
    margin-top: 7%;
  }
  .empty-container-active {
    margin-top: 2%;
  }
`;

export const StyledFilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.color.pearl40};
  padding: 8px 12px;
  margin-top: 16px;
`;

export const StyledContainer = styled.div`
  min-height: 300px;
  text-align: center;
  line-height: 27px;
  color: ${({ theme }) => theme.color.dark};
  background-color: transparent;
  display: grid !important;
  place-items: center !important;
  height: 100%;
  width: 100%;
  padding: 8px 36px;
  .ant-empty {
    margin-bottom: 0px;
  }

  .ant-empty-description {
    color: ${({ theme }) => theme.color.dark80};
  }

  .separator {
    margin: 32px auto;
    border: 1px solid ${({ theme }) => theme.color.dark20};
    width: 96px;
    height: 0px;
  }
`;

export const StyledDiv = styled.div`
  && {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    box-shadow: 0px 2px 6px
      ${({ theme }) => colorHexToRgba(theme.color.black, 0.1)};
    height: 48px;
    background-color: ${({ theme }) => theme.color.white};
    .ant-btn {
      box-shadow: none;
      color: ${({ theme }) => theme.color.purpleDarkened1};
    }
  }
`;

export const StyledFavoritToggleWrap = styled.div<{ isChecked: boolean }>`
  margin-left: 12px;
  display: inline;

  button {
    margin-left: 8px;
  }

  @media (max-width: 1150px) {
    margin-left: 0;
  }
`;

export const StyledName = styled(Text)`
  word-break: break-all;
  width: 250px;
  font-weight: 800;
`;

export const StyledFilterPanel = styled.div`
  padding-left: 28px;
`;

export const StyledVersion = styled.div`
  font-weight: 800;
`;

// Layout

export const StyledLayoutContainer = styled(Layout)``;

export const StyledLayoutContent = styled(Content)`
  background-color: ${({ theme }) => theme.color.white};
`;

export const StyledMainContent = styled(Content)`
  padding: 0 40px;
  background: ${({ theme }) => theme.color.gray11};
`;

export const StyledContainerForMain = styled(Layout)<{
  showbanner?: boolean;
}>`
  height: 100vh;
  position: relative;
  margin-left: 215px;

  .header-wrapper {
    border-bottom: none;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
    padding: 0px 35px;
    height: 86px !important;
  }

  .ant-layout-header {
    height: ${({ showbanner }) => (showbanner ? '172px' : '86px')} !important;
  }

  .ant-layout-content {
    padding: 0px 30px;
  }
  .ant-layout-content:has(div.new-container) {
    padding: 0px;
  }

  .main-header {
    padding: 0 !important;
  }
`;

export const StyledContainerForSub = styled(Layout)`
  position: relative;
  margin-left: 64px;

  .main-layout-popup {
    .ant-tooltip {
      .ant-tooltip-content {
        .ant-tooltip-inner {
          border-radius: 4px !important;
          .anticon {
            display: none !important;
          }
        }
      }
    }
  }
`;

export const StyledAboutContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  h3 {
    font-weight: normal !important;
    text-align: center;
    margin: 30px 0 15px 0;
  }

  h4 {
    margin-top: 0 !important;
    font-weight: bold;
    text-align: left;
    margin-bottom: 20px;
  }

  table {
    tr {
      :hover {
        td {
          background: transparent !important;
        }
      }

      &:last-child {
        td {
          border-bottom: none;
        }
      }
    }
  }
`;

export const StyledNewFolderRow = styled.div`
  width: 100%;
  text-align: center;
  margin-top: 20px;

  span {
    font-size: 16px;
    color: ${({ theme }) => theme.color.dark};
  }
`;

export const StyledNewFolderWarning = styled(Row)`
  background-color: ${({ theme }) => theme.color.warning20};
  width: 494px;
  margin-top: 24px;
  border: 1px solid ${({ theme }) => theme.color.warning};
  box-sizing: border-box;
  border-radius: 4px;
  padding: 12px 16px;

  .ant-col {
    text-align: left;
  }

  svg {
    color: ${({ theme }) => theme.color.warning};
  }
`;

export const StyledNewFolderTag = styled(Tag)<{ isSelected: boolean }>`
  && {
    padding: 6px 16px 6px 16px;
    border-radius: 18px;
    font-size: 14px;
    cursor: pointer;
    color: ${({ theme }) => theme.color.dark};
    background-color: ${({ theme, isSelected }) =>
      isSelected ? theme.color.purple20 : theme.color.white};
    border: 1px solid
      ${({ theme, isSelected }) =>
        isSelected ? theme.color.purple : theme.color.gray4};
  }
`;

export const StyledNewFolderFormItem = styled(Form.Item)`
  display: block !important;
`;

export const StyledAddProductCustomModal = styled(CustomModal)`
  .ant-modal.add-product-modal {
    & .ant-modal-body {
      padding: 4px 32px 0px 32px;
      border: thin solid ${({ theme }) => theme.color.gray4};
    }
  }

  .add-product-fileupload {
    .ant-upload.ant-upload-drag {
      width: auto !important;
      max-width: 400px;
    }
  }
`;

export const StyledAddProductTabs = styled(Tabs)`
  && {
    .ant-tabs-nav {
      margin: 0;
    }
    .ant-tabs-nav::before {
      border: none;
    }

    .ant-tabs-nav-wrap {
      display: flex;
      justify-content: center;
    }

    .ant-tabs-tab {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0px 32px 0px 0px !important;
      padding: 12px 16px !important;

      .ant-tabs-tab-btn {
        color: ${({ theme }) => theme.color.dark};
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .ant-tabs-content {
      .ant-tabs-tabpane:nth-child(2) {
        .form {
          height: 310px;
        }
      }
    }
    .ant-tabs-top-content.ant-tabs-content-animated,
    .ant-tabs-bottom-content.ant-tabs-content-animated {
      transition: none;
    }
  }
`;

export const StyledAddProductHeading = styled.div`
  && {
    font-size: 24px;
  }
`;

export const StyledAddProductFileUpload = styled(FileUpload)`
  && {
    margin-bottom: 0px !important;
  }
`;

export const StyledAdminlabel = styled.div`
  && {
    font-size: 20px;
    color: ${({ theme }) => theme.color.dark};
    font-weight: normal;
    padding: 0;
    margin: 0px auto;
    text-align: center;
    grid-column: 2 / 3;
    padding-right: 16px;
    align-items: center;

    .anticon + span {
      margin-left: 0 !important;
    }

    .anticon {
      margin-top: 3px;
      font-size: 24px;
      margin-right: 10px;
    }
  }
`;

export const StyledApiTesterWrapper = styled.div`
  padding: 0 20px 20px 20px;
  .output-download-alert {
    top: 0;
    .alert-message {
      font-weight: 400 !imporant;
    }
  }
`;

export const StyledInputOutputContainer = styled.div`
  margin-top: 16px;
`;

export const StyledAuthLogoContainer = styled.div`
  height: 58.5px;
  color: ${({ theme }) => theme.color.white};
  display: flex;
  flex-direction: column;
  padding: 5px;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
`;

export const StyledApiTestLCustomTabs = styled(CustomTabs)<{
  fullscreen: boolean;
}>`
  .ant-tabs-nav {
    margin-bottom: 0 !important;
  }

  .ant-tabs-nav-wrap {
    width: 100%;
    max-width: 80%;
    height: 44px;

    .ant-tabs-nav-list {
      max-width: 100%;
      .ant-tabs-tab {
        padding: 0px 0px !important;
        width: auto !important;
      }
    }
  }

  .scroll-container.tab-pane-container {
    overflow: hidden;
    flex: initial !important;
    max-height: 100% !important;
    width: 100% !important;
    padding-top: 5px;
    padding-bottom: 30px;
    .ant-layout {
      background: transparent;
    }
  }

  .ant-tabs-extra-content {
    margin-left: auto !important;
  }
`;

export const StyledApiTestLLink = styled(Link)`
  && {
    color: ${({ theme }) => theme.color.dark};
  }
`;

export const StyledAuthLLoginLayout = styled(Layout)`
  &.error {
    background: linear-gradient(180deg, #512b8b, #201a3d);
  }
  && {
    min-height: 100vh;
    padding-top: 20px;
    padding-bottom: 20px;
    background-size: cover;

    h3.ant-typography {
      margin-bottom: 0px;
    }

    .welcome {
      font-weight: bold;
      font-size: 27px;
      color: ${({ theme }) => theme.color.white};
      text-align: center;
      margin-bottom: 20px;
    }

    a.ant-dropdown-link {
      color: ${props => props.theme.color.white};
    }

    .ant-btn {
      width: 40px;
    }

    .ant-form label {
      font-weight: 500;
      font-size: 20px;
    }

    .ant-form-item.heading {
      font-size: 18px;
    }

    .ant-form-item:last-child {
      margin-bottom: 0;
      padding-bottom: 0;
    }

    .text-extra {
      color: ${({ theme }) => theme.color.gray1};
      font-size: 18px;
    }

    ul {
      padding-inline-start: 20px !important;
    }
  }
`;

export const StyledAuthLLanguageContainer = styled.div`
  float: right;
  margin-right: 30px;
  margin-top: 0px;

  .ant-dropdown {
    z-index: 1001 !important;
  }
`;

export const StyledAuthLMessageContainer = styled.div`
  && {
    font-size: 72px;
    color: ${({ theme }) => theme.color.white};
    font-weight: bold;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    justify-content: center;
    align-items: center;
    display: flex;

    .ant-card {
      display: none;
    }
  }
`;

export const StyledAuthLLogoContainer = styled.div`
  height: 58.5px;
  color: ${({ theme }) => theme.color.white};
  display: flex;
  flex-direction: column;
  padding: 5px;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
`;

export const StyledAuthLCard = styled(Card)`
  && {
    width: 600px;
    background-color: ${({ theme }) => theme.color.gray6};
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
    text-align: center;

    .ant-card-body {
      padding: 30px 30px;
    }

    &.ant-card-bordered {
      border: none;
      margin-top: 160px;
    }

    .anticon {
      margin-right: 5px;
      color: ${({ theme }) => theme.color.red};
      font-size: 42px !important;
      text-align: center;
      width: 100%;
    }

    .ant-btn {
      border-radius: 20px !important;
      width: 40% !important;
      text-align: center;
    }

    span {
      margin-top: 20px;
      font-size: 16px;
      justify-content: center;
      display: grid;
      color: ${({ theme }) => theme.color.gray1};
    }
    .oautherrortitle {
      font-size: 23px;
    }
  }
`;

export const StyledBGEmptyContainer = styled.div`
  padding: 24px;
  font-size: 16px;
  text-align: center;
  .ant-empty {
    margin: 16px 0px;
  }
`;

export const StyledBGItemContainer = styled.div<{ isPointer: boolean }>`
  cursor: ${({ isPointer }) => (isPointer ? 'pointer' : 'default')};
`;

export const PopoverStyle = createGlobalStyle<{ theme: STATES.ThemeState }>`
@keyframes background-job-spin {
  100% {
    transform: rotate(360deg);
  }
}

.ant-popover.background-jobs {
  padding-top: 0 !important;
  z-index: 999;
  box-shadow: 0px 2px 5px 1px rgb(0 0 0 / 20%);
  .ant-popover-content > .ant-popover-arrow {
    display: none;
  }

  .ant-popover-content {
    width: 440px;
    max-height: 500px;

    .ant-popover-inner {
      background: ${({ theme }) => theme.color.white};
      font-size: 12px;

      .ant-popover-inner-content {
        color: ${({ theme }) => theme.color.dark};
        padding: 0px;
      }
    }
  }
}
`;

export const StyledBGBackgroundLoader = styled.div`
  border: 2px solid;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border-color: ${({ theme }) => theme.color.purple}
    ${({ theme }) => theme.color.purple} transparent;
  animation: background-job-spin 1s linear infinite;
  margin-right: 8px;
  overflow: hidden;
`;

export const StyledBGFooter = styled.div`
  background-color: ${({ theme }) => theme.color.dark};
  line-height: 36px;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  display: none;

  .ant-btn-link > span {
    color: ${({ theme }) => theme.color.white} !important;
    text-decoration: underline;
  }
`;

export const StyledBGJobHeader = styled.div`
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  text-align: center;
  padding: 16px 12px;
  color: ${({ theme }) => theme.color.white};
  background-color: ${({ theme }) => theme.color.purpleDarkened2};
`;

export const StyledBGJobContainer = styled.div`
  && {
    display: flex;
    align-items: center;
    margin-right: 20px;
    margin-top: 4px;

    .dashed {
      float: left;
      display: inline-flex;
      margin-left: 10px;
      margin-top: 0px;

      span {
        font-size: 14px !important;
        color: ${({ theme }) => theme.color.dark60};
      }

      .job-progress-status-ic {
        &.anticon {
          font-size: 21px !important;
        }
      }
      .success {
        color: ${({ theme }) => theme.color.success} !important;
      }

      .error {
        color: ${({ theme }) => theme.color.dark80} !important;
      }
    }
  }
`;

export const StyledBGJobList = styled.div`
  max-height: calc(100vh - 250px);
  overflow-y: auto;
  ${ScrollCss}
`;

export const StyledBGGroupHeading = styled.div`
  background-color: ${({ theme }) => theme.color.gray10};
  padding: 5px 24px;
  font-weight: bold;
  height: 36px;
  font-size: 16px;
  line-height: 20px;
`;

export const StyledBGButton = styled(Button)`
  width: auto !important;
  border-color: ${({ theme }) => theme.color.gray2} !important;
  background-color: ${({ theme }) => theme.color.white} !important;
  border-radius: 2px !important;
  font-size: 16px !important;
  min-height: 32px !important;
  font-weight: 400 !important;
  color: ${({ theme }) => theme.color.gray9} !important;

  :hover {
    color: ${({ theme }) => theme.color.purple} !important;
    border-color: ${({ theme }) => theme.color.purple} !important;
    .anticon {
      color: ${({ theme }) => theme.color.purple} !important;
    }
  }

  :focus {
    color: ${({ theme }) => theme.color.purple60} !important;
    border-color: ${({ theme }) => theme.color.purple60} !important;
    .anticon {
      color: ${({ theme }) => theme.color.purple60} !important;
    }
  }

  :active {
    color: ${({ theme }) => theme.color.purpleDarkened1} !important;
    border-color: ${({ theme }) => theme.color.purpleDarkened1} !important;
    .anticon {
      color: ${({ theme }) => theme.color.purpleDarkened1} !important;
    }
  }

  .anticon {
    margin-left: 16px;
    margin-right: 8px;
    font-size: 14px;
    color: ${({ theme }) => theme.color.gray9} !important;
  }
  .ant-btn {
    .anticon {
      margin-right: 0px;
    }
  }
`;
export const StyledTokenModal = styled(CustomModal)`
  .title {
    margin-bottom: 7px;
    display: block;
  }
  .BearerTokensModalhint {
    display: flex;
    flex-direction: column;
    text-align: center;
  }
  .Bearertokensdetails {
    padding: 0 30px;
  }
  .ant-input,
  .ant-alert {
    margin-bottom: 16px;
  }
  .expireToken {
    margin-top: 24px;
  }
  .Bearertokenmodal {
    padding: 50px;
  }
`;

export const StyledCategoryManagerContainer = styled.div`
  && {
    .ant-layout-header {
      margin-bottom: 20px;
    }

    .ant-tabs-bar {
      border: none;
    }

    .ant-tabs-nav-container {
      position: absolute;
      z-index: 1;
      width: 29.16666667%;

      .ant-tabs-nav {
        width: 100%;
        .ant-tabs-tab {
          width: 50%;
          height: 43px;
        }
      }
    }

    .ant-tabs-tabpane {
      .ant-row-flex {
        border: none;
        .ant-col.ant-col-7 {
          margin-top: 43px;
          border: none;

          .tabNavLeftHeader {
            position: relative;
            z-index: 1;

            .ant-btn-link {
              margin-left: 0px;
              width: 50px;
              height: 50px;
              padding: 5px;

              .anticon-plus-square {
                font-size: 40px;
                margin-right: 0;
              }
            }
          }

          .ant-spin-nested-loading.flex-fill {
            border: 1px solid ${({ theme }) => theme.color.purple};
            border-top: none;
            border-left: none;
            position: relative;
            z-index: 1;

            .ant-spin-container {
              .icon-container {
                width: 62px;
              }
            }
          }
          :after {
            content: ' ';
            position: absolute;
            border-left: 1px solid ${({ theme }) => theme.color.purple};
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            z-index: 0;
          }
        }

        .ant-col.ant-col-24 {
          .categoryManagerTableContainer {
            background: none;
            .categoryManagerTable * {
              background: none;
            }
            .ant-table-fixed {
              background: ${({ theme }) => theme.color.gray6};
            }
          }
        }
      }
    }

    .folder-name {
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 0px;
    }

    .cardUpdateDate {
      font-size: 12px;
      line-height: 14px;
    }
  }
`;

export const StyledCategoryWrapper = styled(props => <div {...props} />)`
  && {
    background-color: ${({ theme }) => theme.color.white};
    padding: 10px;
  }
`;

export const StyledCategoryContainer = styled(Content)`
  background-color: ${({ theme }) => theme.color.white};

  padding: 20px !important;
  min-height: 600px !important;

  .text-center {
    text-align: center;
  }
`;
export const StyledClientContainer = styled(Content)`
  padding: 0px 0px !important;
  min-height: calc(100vh - 86px) !important;
  display: flex;
  flex-direction: column;
  margin: 0 -30px;
`;

export const StyledClientIFrame = styled.iframe`
  border: none;
  padding-top: 10px;
  flex: 1;
`;

export const StyledSuccessLogCol = styled.div`
  border: none;
  color: ${({ theme }) => theme.color.gray};
`;

export const StyledWarningLogCol = styled.div`
  border: none;
  color: ${({ theme }) => theme.color.failed};
`;

export const StyledErrorLogCol = styled.div`
  border: none;
  color: ${({ theme }) => theme.color.failed};
`;

export const StyledDownloadProductContent = styled.div`
  width: auto;

  .sub-title {
    text-align: center;
    display: flex;
    justify-content: space-around;
  }

  .download-options {
    text-align: center;
    display: flex;
    justify-content: center;
    margin: 20px 0px 20px 0px;
  }

  .warning {
    width: auto;
    text-align: center;
  }
`;

export const StyledDSFileModal = styled(CustomModal)`
  .title {
    margin: 0 auto;
    font-size: 24px !important;
  }

  .ant-form {
    .header {
      .ant-form-item-control-input-content {
        display: flex !important;
      }
    }
    .ant-form-item {
      flex-direction: column !important;
    }
    .footer {
      .ant-form-item-control-input-content {
        display: flex !important;
        justify-content: center !important;
      }
    }
  }
`;

export const StyledEngineManagerForm = styled(Form)`
  width: 100%;
  .ant-tabs-card-bar {
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  }
  .ant-tabs-content-holder {
    background-color: ${({ theme }) => theme.color.white};
    height: fit-content;
    min-height: calc(100vh - 170px);
    padding: 24px 40px !important;
  }
`;

export const StyledEngineManagerCustomTabs = styled(CustomTabs)<{
  fullscreen: boolean;
}>`
  .ant-tabs-nav {
    margin-bottom: 0 !important;
    justify-content: space-between;
  }
  .ant-tabs-nav-wrap {
    max-width: 80%;
    .ant-tabs-nav-list {
      .ant-tabs-tab {
        padding: 0px 0px !important;
        width: auto !important;
      }
    }
  }

  .scroll-container.tab-pane-container {
    ::-webkit-scrollbar {
      background-color: ${({ theme }) =>
        colorHexToRgba(theme.color.gray10, 0.6)};
    }
    .ant-table-wrapper {
      ${ScrollCss}
    }

    .ant-table-header {
      background: var(--table-header-bg-color);
    }
  }
`;

export const StyledEngineManagerLink = styled(Link)`
  && {
    color: ${({ theme }) => theme.color.lightPurple};
    &:active,
    &:focus {
      color: ${({ theme }) => theme.color.dark};
    }
  }
`;

export const StyledButtonsWrapper = styled.div.attrs({
  'data-testid': 'engine-save',
})<{
  center?: boolean;
}>`
  display: flex;
  justify-content: center;
  align-content: center;
  margin-top: 27px;
`;

export const StyledErrorLayout = styled(Layout)`
  && {
    background-color: ${({ theme }) => theme.color.purple};

    @media (max-width: 1440px) {
      .anticon {
        font-size: 700px;
      }
    }

    @media (min-width: 1025px) and (max-width: 1220px) {
      .anticon {
        font-size: 600px;
      }
    }

    @media (max-width: 1024px) {
      .anticon {
        font-size: 500px;
      }
    }

    @media (max-width: 700px) {
      .anticon {
        font-size: 400px;
      }
    }
  }

  .anticon {
    font-size: 800px;
    text-align: left;
    position: absolute;
    bottom: 0;
  }
`;

export const StyledErrorTextContainer = styled.div`
  color: ${({ theme }) => theme.color.dark};
  padding: 50px;
  position: absolute;
  right: 0;
  margin-top: 150px;
  margin-right: 600px;
  width: 700px;

  a {
    margin-left: 15px;
    color: ${({ theme }) => theme.color.black};
    text-decoration: underline;
    font-style: italic;
    font-weight: 700;
  }

  h1 {
    font-size: 130px;
  }

  h3 {
    font-size: 28px;
    font-weight: 400;
  }

  @media (min-width: 1441px) {
    margin-right: 200px;
  }

  @media (max-width: 1440px) {
    margin-top: 160px;
    margin-right: 10px;
    padding: 20px;
    width: 600px;
    h1 {
      font-size: 100px;
      margin-bottom: 20px;
    }

    h3 {
      font-size: 24px;
      font-weight: 400;
    }
  }

  @media (max-width: 1024px) {
    margin-top: 160px;
    margin-right: 80px;
    padding: 20px;
    width: 400px;
    h1 {
      font-size: 80px;
      margin-bottom: 10px;
    }

    h3 {
      font-size: 20px;
    }
  }

  @media (max-width: 700px) {
    margin-right: 70px;
    padding: 20px;
    width: 300px;
    margin-top: 20px;
    h1 {
      font-size: 70px;
      margin-bottom: 10px;
    }

    h3 {
      font-size: 18px;
    }
  }
`;

export const StyledFileManagerContainer = styled.div`
  && {
    .ant-layout-header {
      margin-bottom: 20px;
    }

    .ant-table-fixed-header .ant-table-body-inner {
      overflow: auto !important;
    }

    .ant-tabs-bar {
      border: none;
    }

    .ant-tabs-nav-container {
      position: absolute;
      z-index: 1;
      width: 29.16666667%;

      .ant-tabs-nav {
        width: 100%;
        .ant-tabs-tab {
          width: 50%;
          height: 43px;
        }
      }
    }

    .ant-tabs-tabpane {
      .ant-row-flex {
        border: none;
        .ant-col.ant-col-7 {
          margin-top: 43px;
          border: none;

          .tabNavLeftHeader {
            position: relative;
            z-index: 1;

            .ant-btn-link {
              margin-left: 0px;
              width: 50px;
              height: 50px;
              padding: 5px;

              .anticon-plus-square {
                font-size: 40px;
                margin-right: 0;
              }
            }
          }

          .ant-spin-nested-loading.flex-fill {
            border: 1px solid ${({ theme }) => theme.color.purple};
            border-top: none;
            border-left: none;
            position: relative;
            z-index: 1;

            .ant-spin-container {
              .icon-container {
                width: 62px;
              }
            }
          }
          :after {
            content: ' ';
            position: absolute;
            border-left: 1px solid ${({ theme }) => theme.color.purple};
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            z-index: 0;
          }
        }
      }
    }

    .folder-name {
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 0px;
    }

    .cardUpdateDate {
      font-size: 12px;
      line-height: 14px;
    }
  }
`;

export const StyledFileEditMetaContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  min-height: 350px;
`;

export const StyledFileEditTextArea = styled(Input.TextArea)`
  margin-top: 10px !important;
  flex: 1;
  box-shadow: inset 0px 1px 4px rgba(0, 0, 0, 0.15);
  background-color: ${({ theme }) => theme.color.lightwhitesmoke} !important;
`;

export const StyledFileMetaContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  padding-top: 10px;
  min-height: 100px;
  .label {
    maring-top: 5px;
    margin-bottom: 5px;
    font-weight: bolder;
  }
`;

export const StyledFileHelpText = styled.div`
  font-size: 14px;
  color: ${({ theme }) => theme.color.gray3};
`;

export const StyledDeleteFolderExtraInfo = styled.div`
  border: 1px solid #D2D1D8;
  background: #FFFFFF;
  border-radius: 4px
  padding: 8px 5px 8px 8px;  
  .scroll {
    overflow-y: auto;
    max-height: 275px;
    ${CustomScrollCss}
  }
  
  ul {
    margin-bottom: 0;
    li {
      font-weight: bold;
      font-size: 16px;
      line-height: 21px;
    }
  }
`;

export const StyledParentLink = styled(Link)`
  .anticon {
    font-size: 16px;
    color: ${({ theme }) => theme.color.purple};
  }
`;

export const StyledInsightsControlPanel = styled.div`
  .scroll-container.tab-pane-container {
    min-height: 600px;
    overflow: hidden;
  }
  .ant-tabs-bar {
    margin-top: 20px;
    margin-bottom: 15px;
  }
  .ant-tabs-nav-wrap {
    justify-content: center;
    display: flex;
  }
`;

export const StyledInsightsFilterPanel = styled.div`
  padding: 8px 24px 8px 24px;
  background-color: ${({ theme }) => theme.color.pearl40};
`;

export const StyledInsightsButton = styled(Button)<{ left: number }>`
  position: absolute !important;
  top: 1px;
  left: ${({ left }) => `${left}px`};
  display: block !important;
  height: 22px !important;
  line-height: 0 !important;
`;

export const StyledInsightsLabel = styled.span`
  align-self: center;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 5px;
  color: ${({ theme }) => theme.color.dark};
`;

export const StyledInsightsDateFilterContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  background-color: ${({ theme }) => theme.color.purple20};
  justify-content: center;
  padding: 8px 0;
`;

export const StyledInsightsDatePickerContainer = styled.div`
  margin-right: 16px;
  display: flex;
  span {
    margin-right: 8px;
  }
  .ant-picker-input {
    input {
      padding-right: 30px;
    }
    .anticon-calendar {
      font-size: 14px;
      svg {
        margin-right: -30px;
      }
    }
  }
  .ant-picker-content tbody tr td .ant-picker-cell-inner:hover {
    background-color: ${({ theme }) => theme.color.gray10} !important;
  }
`;

export const StyledInsightsCustomTabs = styled(CustomTabs)`
  && {
    height: 100%;
    .ant-tabs-nav {
      margin-top: 20px;
      margin-bottom: 10px;
    }

    .ant-tabs-nav-wrap {
      .ant-tabs-nav-list {
        border: 0 !important;
        .ant-tabs-tab {
          border-radius: 0px;
          padding: 0px 20px !important
          min-width: 138px !important;
          height: 44px;
        }
        .ant-tabs-tab:first-child {
          border-top-left-radius: 4px !important;
          border-bottom-left-radius: 4px !important;
        }
        .ant-tabs-tab:nth-child(2) {
          border-top-right-radius: 4px !important;
          border-bottom-right-radius: 4px !important;
        }
      }
    }
  }
`;

export const StyledInsightsErrorSection = styled.div`
  text-align: center;
`;

export const StyledProductDashWrapper = styled(Row)`
  background-color: ${({ theme }) => colorHexToRgba(theme.color.gray10, 0.6)};
  border-bottom: 1px solid ${({ theme }) => theme.color.gray5};
`;

export const StyledProductDashContainer = styled.div`
  background-color: ${({ theme }) => colorHexToRgba(theme.color.gray10, 0.9)};
`;

export const StyledProductDashControlPanel = styled.div`
  text-align: center;
  background-color: ${({ theme }) => theme.color.white};
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;

  .tab-pane-container {
    overflow: overlay !important;
  }
  .ant-tabs-bar {
    margin-top: 20px;
    margin-bottom: 10px;
  }
  .ant-tabs-tab {
    width: 152px !important;
  }

  && {
    .tab-pane-container {
      padding: 0px;
    }

    .description {
      margin-bottom: 20px;
    }
  }
`;

export const StyledProductDashContent = styled(Content)`
  margin: 0px 40px;
  border-radius: 6px;
`;

export const StyledProductDashRowContainer = styled(props => (
  <Row justify="space-between" align="middle" {...props} />
))`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  font-size: 20px;

  .Pencil {
    color: ${({ theme }) => theme.color.purple};
    border: 1px solid ${({ theme }) => theme.color.white};
    padding: 3px;
    border-radius: 4px;

    svg {
      height: 20px;
      width: 20px;
    }

    :hover {
      border: 1px solid ${({ theme }) => theme.color.dark20};
      color: ${({ theme }) => theme.color.purple};
      cursor: pointer;
    }
    :active {
      background: ${({ theme }) => theme.color.purple};
      border: 1px solid ${({ theme }) => theme.color.purple};
      color: ${({ theme }) => theme.color.white};
      cursor: pointer;
    }
  }
`;

export const StyledProductDashSectionLabel = styled(props => (
  <Title level={4} {...props} />
))`
  && {
    font-weight: bold;
    font-size: 20px;
    color: ${({ theme }) => theme.color.textColor};
    padding: 0;
    margin: 0;
  }
`;

export const StyledProductDashOverviewWrapper = styled(Col)`
  && {
    padding-right: 0;
    background: ${({ theme }) => theme.color.white};
    padding: 28px 24px 32px 24px;
    border-top-left-radius: 6px;
    font-size: 20px;
    width: 50%;
    max-height: 488px;
    height: auto;
    display: flex;
    flex: 0 0 100%;
    flex-direction: column;
    border-right: 1px solid ${({ theme }) => theme.color.gray5};

    @media screen and (min-width: 1100px) {
      flex: 0 0 auto;
    }

    @media screen and (min-width: 1503px) {
      width: 50%;
    }

    @media screen and (max-width: 1243px) {
      width: 50%;
    }

    @media screen and (max-width: 1099px) {
      width: 100% !important;
      margin-right: 0px !important;
      margin-bottom: 20px;
    }
  }
`;

export const StyledProductDashRecentChangesContainer = styled(Col)`
  && {
    display: flex;
    flex: 0 0 100%;
    max-height: 488px;
    height: auto;
    flex-direction: column;
    background: ${({ theme }) => theme.color.white};
    width: 50%;
    padding-top: 26px;
    border-top-right-radius: 6px;
    position: relative;
    z-index: 2 !important;
    @media screen and (min-width: 1100px) {
      flex: 0 0 auto;
    }
    @media screen and (max-width: 1099px) {
      width: 100% !important;
      margin-left: 0px !important;
    }
    .ant-tabs-bar {
      display: flex;
      justify-content: center;
      margin-bottom: 10px;
      text-align: center;
    }
  }
`;

export const StyledProductDashShowMore = styled.div<{ viewtype?: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  border-radius: 20px;
  border: 1px solid ${({ theme }) => theme.color.purple};
  height: 40px;
  cursor: pointer;
  margin: 24px;
  margin-top: ${({ viewtype }) => (viewtype === 'grid' ? '-0px' : '24px')};

  :hover {
    background: ${({ theme }) => theme.color.purple60};
    border: 1px solid ${({ theme }) => theme.color.purple60};
  }

  :active {
    color: ${({ theme }) => theme.color.white};
    background: ${({ theme }) => theme.color.purpleDarkened1};
    border: 1px solid ${({ theme }) => theme.color.purpleDarkened1};
  }
`;

export const GlobalStyleForAntMessage = createGlobalStyle<{
  theme: STATES.ThemeState;
}>`
  body{
    .ant-message{
      .ant-message-notice-content{
          .productDoc-upload-success{
          border: 1px solid ${({ theme }) => theme.color.success};
          color: ${({ theme }) => theme.color.dark80};
          .image-wrapper {
            background-color: ${({ theme }) => theme.color.success};
            .message-checked-icon{
              color: ${({ theme }) => theme.color.white} !important;
            }
          }
        }
      }
    }
  }
`;

export const StyledProductDashCustomTabs = styled(CustomTabs)`
  && {
    height: 100%;
    .ant-tabs-nav {
      margin-top: 20px;
      margin-bottom: 10px;
    }
    .ant-tabs-nav-wrap {
      max-width: 100%;
      display: flex;
      justify-content: center;
      .ant-tabs-nav-list {
        .ant-tabs-tab {
          min-width: 138px !important;
          height: 44px;
        }

        .ant-tabs-tab:last-child {
          border-top-right-radius: 3px;
          border-bottom-right-radius: 3px;
        }
      }
    }
    .ant-tabs-nav-wrap {
      .ant-tabs-nav-list {
        .ant-tabs-tab:last-of-type {
          color: red;
          border-top-right-radius: 3px;
          border-bottom-right-radius: 3px;
        }
      }
    }
    .tab-pane-container {
      min-height: 176px;
    }
  }
`;

export const StyledProductDocumentCollapse = styled(Collapse).attrs({
  'data-testid': 'calculate-engine',
})<{ viewtype?: 'grid' | 'list' }>`
  & {
    border: none !important;

    .ant-collapse-content > .ant-collapse-content-box {
      padding: ${({ viewtype }) => (viewtype === 'list' ? 0 : '16px')};
    }

    .icon-empty {
      margin-top: ${({ viewtype }) => (viewtype === 'grid' ? 0 : '25px')};
    }

    .ant-table-wrapper {
      padding-left: 0px !important;
    }

    .ant-collapse-item {
      .ant-collapse-header {
        border: none !important;
        background: ${({ theme }) => theme.color.gray10} !important;
        border-radius: 0px;

        .ant-collapse-arrow {
          color: ${({ theme }) => theme.color.purple}!important;
        }
      }
    }
  }
`;

export const StyledProductDocumentContainer = styled.div`
  background: ${({ theme }) => theme.color.white} !important;

  .active-file-row {
    background-color: ${({ theme }) => theme.color.success15} !important;
  }

  .ant-table-tbody
    > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
    > td {
    background: transparent;
  }
  .expand {
    font-size: 12px;
  }

  .plus {
    font-size: 12px;
    margin-right: 5px;
  }

  .expanded {
    margin-left: 5px;
  }

  .ant-collapse-item:last-child {
    border-top-left-radius: 6px !important;
    border-top-right-radius: 6px !important;
  }

  .ant-collapse-item {
    margin-bottom: 24px;
  }

  .panel-icons {
    display: flex;
    align-items: center;
  }

  .ant-collapse > .ant-collapse-item > .ant-collapse-header:hover {
    background: ${({ theme }) => theme.color.purple20};

    .panel-icons > .panel-arrow-icon {
      margin-left: 15px;
    }

    .panel-icons > .panel-drag-icon {
      display: contents;
    }

    .ant-collapse-extra > .ant-row > .panelIcon {
      display: initial;
      position: relative;
      bottom: 6px;
      margin-left: 10px;

      &:hover {
        background: ${({ theme }) => theme.color.white} !important;
        border: 1px solid ${({ theme }) => theme.color.gray10} !important;
        position: relative;
        bottom: 6px;
      }
    }
  }

  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    background: ${({ theme }) => theme.color.white} !important;
    border-radius: 6px;
    height: 48px;
    padding-left: 10px;
    display: flex;

    .panel-icons {
      margin: 0px;
      vertical-align: middle;

      .panel-arrow-icon {
        margin-left: 27px;
      }

      .panel-drag-icon {
        display: none;
      }
    }

    .panel-header-text {
      flex: 1;
      text-align: left;
      margin-left: 10px;
    }

    .ant-collapse-extra > .ant-row > .panelIcon {
      display: none;
    }
  }

  .ant-collapse > .ant-collapse-item-active > .ant-collapse-header {
    border-radius: 6px 6px 0 0;
  }

  .ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header {
    border-radius: 6px !important;
  }

  .ant-collapse > .ant-collapse-item-active:last-child > .ant-collapse-header {
    border-radius: 6px 6px 0 0 !important;
  }
`;

export const StyledProductDocumentLinkButton = styled(Button)`
  && {
    font-size: 14px;
    color: ${({ theme }) => theme.color.dark};
    padding: 0;
    line-height: 30px;
    margin-right: 25px;
    border: none !important;
  }
  &&:active {
    border: none;
  }
  &&:focus {
    border: none;
  }
  &&.primary-color {
    color: ${({ theme }) => theme.color.purpleDarkened1};
  }
`;

export const StyledProductDocumentExpandCollapseButton = styled(Button)<{
  allExpanded?: boolean;
  disableReduceAll?: boolean;
}>`
  && {
    font-size: 14px;
    color: ${({ theme, allExpanded }) =>
      allExpanded ? theme.color.dark20 : theme.color.dark};
    border: none !important;
  }
  &&:active {
    border: none;
  }
  &&:focus {
    border: none;
  }

  &.expand-all {
    color: ${({ theme, allExpanded }) =>
      allExpanded
        ? theme.color.dark20
        : theme.color.purpleDarkened1} !important;
    cursor: ${({ allExpanded }) => (allExpanded ? '' : 'pointer')};
    pointer-events: ${({ allExpanded }) => (allExpanded ? 'none' : '')};

    i {
      opacity: ${({ allExpanded }) => (allExpanded ? 0.6 : 1)};
    }
  }

  &.reduce-all {
    color: ${({ theme, disableReduceAll }) =>
      disableReduceAll
        ? theme.color.dark20
        : theme.color.purpleDarkened1} !important;
    cursor: ${({ disableReduceAll }) => (disableReduceAll ? '' : 'pointer')};
    pointer-events: ${({ disableReduceAll }) =>
        disableReduceAll ? 'none' : ''}
      i {
      opacity: ${({ disableReduceAll }) => (disableReduceAll ? 0.6 : 1)};
    }
  }
`;

export const StyledProductDocumentViewMoreButton = styled(Button)`
  && {
    font-size: 14px;
    color: ${({ theme }) => theme.color.dark} !important;
    margin-top: 15px;
    padding: 0;
    line-height: 30px;
    width: 100%;
    border-radius: 25px;
  }
  &&:hover {
    border-color: ${({ theme }) => theme.color.purple};
  }
  &&:focus {
    border-color: ${({ theme }) => theme.color.purple};
  }
`;

export const StyledProductDocumentPanel = styled(Panel)<{
  activePanel?: string | null;
  selectedFileSource?: string;
  panel: string | null;
  panelName: string;
  draggedOntoElement: DTO.ActivePanelProps;
  isValid: boolean;
}>`
  && {
    display: flex;
    flex-direction: column;

    border: 1px solid
      ${({
        theme,
        panel,
        activePanel,
        selectedFileSource,
        panelName,
        isValid,
        draggedOntoElement,
      }) => {
        if (activePanel === selectedFileSource) {
          return theme.color.gray2;
        }
        if (
          panel === panelName &&
          !isValid &&
          (draggedOntoElement.name?.length ?? 0) > 0
        ) {
          return 'red';
        }

        if (
          panel === panelName &&
          isValid &&
          (draggedOntoElement.name?.length ?? 0) > 0
        ) {
          return theme.color.purple;
        }

        return theme.color.gray2;
      }};
    border-radius: 6px;

    .ant-collapse-content {
      border-radius: 3px;
      flex: 1;

      background-color: ${({
        theme,
        panel,
        panelName,
        isValid,
        activePanel,
        draggedOntoElement,
        selectedFileSource,
      }) => {
        if (activePanel === selectedFileSource) {
          return theme.color.pearl40;
        }
        if (
          panel === panelName &&
          isValid &&
          (draggedOntoElement.name?.length ?? 0) > 0
        ) {
          return theme.color.purple20;
        }

        return theme.color.pearl40;
      }};
      border-bottom: 1px solid
        ${({ theme, panel, panelName, isValid, draggedOntoElement }) => {
          if (
            panel === panelName &&
            !isValid &&
            (draggedOntoElement.name?.length ?? 0) > 0
          ) {
            return 'red';
          }

          if (
            panel === panelName &&
            isValid &&
            (draggedOntoElement.name?.length ?? 0) > 0
          ) {
            return theme.color.purple;
          }

          return theme.color.gray2;
        }};
    }

    .drag-drop-zone {
      .ant-row-flex {
        margin-bottom: 0 !important;
      }
    }
  }
`;

export const StyledProductDocumentNoFolderWrap = styled.div<{
  activePanel?: string | null;
}>`
  border: 1px solid
    ${({ theme, activePanel }) =>
      activePanel === 'No Folder' ? theme.color.purple : 'transparent'};
`;

export const StyledProductDocDiv = styled.div`
  padding: 0;
  overflow: hidden;
  width: 100%;
`;

export const StyledProductDocFrame = styled.iframe`
  border: 0;
  overflow: auto;
  width: 100%;
  height: 700px;
`;
export const StyledProductDocContainer = styled.div`
  .active-file-card {
    background-color: ${({ theme }) => theme.color.success15} !important;
    border: 1px solid ${({ theme }) => theme.color.success} !important;
  }

  .footer-wrap {
    background: none;

    &.pagination {
      margin-top: 16px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }

  .ant-spin-container {
    max-height: unset !important;
  }
`;

export const StyledProductDocViewMoreContainer = styled.div`
  && {
    text-align: center;
    bottom: 15px;
    left: 0;
    right: 0;

    button {
      font-weight: bold;
      font-size: 16px;
      line-height: 20px;
    }
  }

  &&:after {
  }

  &.folder {
    margin-bottom: 24px;
  }
`;

export const StyledProductDocViewMoreButton = styled(Button)`
  && {
    font-size: 14px;
    color: ${({ theme }) => theme.color.dark} !important;
    margin-top: 15px;
    padding: 0;
    line-height: 30px;
    width: 100%;
    border-radius: 25px;
  }
  &&:hover {
    border-color: ${({ theme }) => theme.color.purple};
  }
  &&:focus {
    border-color: ${({ theme }) => theme.color.purple};
  }
`;

export const StyledProductEnginHeader = styled.h3`
  font-size: 16px;
  font-weight: bold;
  color: ${({ theme }) => theme.color.dark};
`;

export const StyledProductEnginWrapper = styled.div`
  .service-section {
    margin-bottom: 24px;

    h3 {
      display: flex;
    }
  }
`;

export const StyledProductEnginText = styled.p`
  font-size: 16px;
  color: ${({ theme }) => theme.color.dark60};
  display: flex;
`;

export const StyledProductEngineNoServiceWrap = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  svg {
    color: ${({ theme }) => theme.color.purple};
  }

  .icon-empty {
    font-size: 70px;

    svg {
      color: ${({ theme }) => theme.color.white};
    }
  }
`;

export const StyledProductHeader = styled(Header)`
  && {
    padding: 0 40px;
    display: flex;
    height: auto;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin: 15px 0;
    background-color: ${({ theme }) => colorHexToRgba(theme.color.gray10, 0.6)};
    width: 100%;

    @media screen and (min-width: 960px) {
      margin: 0;
    }
  }
`;

export const StyledProductRowContainer = styled.div`
  display: flex;
  align-items: center;
  height: 64px;
`;

export const StyledRecentChangeTabs = styled(CustomTabs)`
  font-family: ${({ theme }) => theme.fontFaces[1]};
  && {
    .tab-pane-container {
      padding: 12px 0 0 0;
      min-height: 354px;
      ${ScrollCss}
    }
    .ant-tabs-tab {
      padding: 0px 24px !important;
      .ant-tabs-tab-btn {
        height: 42px;
      }
    }
    .ant-tabs-tab:first-child {
      border-radius: 3px 0px 0px 3px !important;
    }
    .ant-tabs-tab:nth-child(2) {
      border-radius: 0px 3px 3px 0px !important;
    }
  }
`;

export const StyledServiceConsoleWrapper = styled.div<{
  bgTaskProgress: boolean;
}>`
  .header-title-wrap {
    width: ${({ bgTaskProgress }) => (bgTaskProgress ? '256px' : '148px')};
  }
`;

export const StyledServiceConsoleSpan = styled.div`
  && {
    margin: 0 auto;
    text-align: center;
    grid-column: 2/3;
    display: flex;
    padding-right: 16px;
    align-items: center;

    > div {
      font-weight: normal !important;
      font-size: 20px;
      line-height: 23px;
      word-break: break-all;
    }

    .anticon {
      background: transparent;
      color: ${({ theme }) => theme.color.success};
      height: 8px;
      width: 8px;
      margin-right: 8px;
      svg {
        height: 12px;
        width: 12px;
        margin-bottom: 4px;
      }
    }
  }
`;

export const StyledTemplateSelectedFileContainer = styled.div`
  padding: 10px;
  text-align: center;
  color: ${({ theme }) => theme.color.dark};

  .anticon {
    font-size: 40px;
    color: ${({ theme }) => theme.color.purple};
  }

  .name {
    font-weight: bold;
    margin-top: 10px;
  }

  .size {
    font-style: italic;
  }
`;

export const StyledTemplateListItem = styled(props => <List.Item {...props} />)`
  margin-bottom: 5px;
  border-radius: 4px;
  padding: 10px 20px !important;
  background: ${({ theme }) => theme.color.white} !important;
  border: 1px solid ${({ theme }) => theme.color.pearl401} !important;
  .ant-list-item-meta-content {
    margin-top: 6px;
  }
  .ant-list-item-action-split {
    display: none !important;
  }
  .ant-list-item-action li:last-child {
    padding-right: 0 !important;
  }
  .ant-list-item-meta-avatar {
    font-size: 32px;
  }
`;

export const StyledVersionCustomTabs = styled(CustomTabs)`
  .ant-tabs-nav {
    margin-bottom: 0 !important;
    margin-top: 0 !important;
    .ant-tabs-tab {
      padding: 0 !important;
    }
  }

  .tab-pane-container {
    min-height: calc(100vh - 173px) !important;
  }

  .history-wrapper {
    background: ${({ theme }) => theme.color.white};
    margin: 0px 30px 0px 30px;
  }

  .timeline-wrapper {
    padding-top: 18px;
    box-shadow: inset 0px 2px 6px rgba(0, 0, 0, 0.1);
  }

  .ant-tabs-nav {
    background: ${({ theme }) => theme.color.white};
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
    width: 100%;

    &-list {
      margin-left: 40px;
    }
  }

  .ant-tabs-extra-content {
    margin-left: auto !important;
  }
`;

export const StyledVersionLink = styled(Link)`
  && {
    color: ${({ theme }) => theme.color.dark};
  }
`;
export const StyledVeiwLogDiv = styled.div`
  && {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    box-shadow: 0px 2px 6px
      ${({ theme }) => colorHexToRgba(theme.color.black, 0.1)};
    height: 48px;
    background-color: ${({ theme }) => theme.color.white};
    .ant-btn {
      box-shadow: none;
      color: ${({ theme }) => theme.color.purpleDarkened1};
    }
  }
`;
