import { notification, message as popUpMessage } from 'antd';
import React, { ReactNode } from 'react';
import { ArgsProps as MessageConfigProps } from 'antd/lib/message';
import {
  CheckCircleFilled,
  InfoCircleFilled,
  WarningFilled,
} from '@ant-design/icons';
import { AlertConstants } from '../constants';
import { ApiError } from '../helpers';
import { AlertAction, AlertThunkAction } from './types';
import { IconSvg } from '../components/Common';

type ErrorContentType = string | Error | ApiError | unknown;

popUpMessage.config({
  top: 80,
  duration: 2,
  maxCount: 3,
});

const iconInfo = () => <IconSvg type="InfoCircle" />;
export const iconError = () => <WarningFilled />;

const success = (
  content: string | ReactNode,
  values?: { [key: string]: string }
): AlertThunkAction => (dispatch, getState) => {
  const {
    language: { intl },
  } = getState();

  const message = intl.formatMessage(
    {
      id: content,
      defaultMessage: content,
    },
    values
  );

  dispatch({
    type: AlertConstants.SUCCESS,
    payload: { message },
  });

  if (message.length > 0 && message.length <= 80) {
    popUpMessage.success(message);
  } else {
    notification.success({
      message,
      top: 90,
      duration: 5,
      placement: 'top',
      icon: <CheckCircleFilled />,
    });
  }

  // custom pendo track event
  window?.pendo?.track('SparkUI_success', {
    messages: message.toString() || '',
    url: window.location.href,
    type: 'success',
  });
  window?.pendo?.flushNow();
};

const info = (
  content: string | ReactNode,
  values?: { [key: string]: string }
): AlertThunkAction => (dispatch, getState) => {
  const {
    language: { intl },
  } = getState();

  const message = intl.formatHTMLMessage(
    {
      id: content,
      defaultMessage: content,
    },
    values
  );

  dispatch({
    type: AlertConstants.INFO,
    payload: { message },
  });

  if (message.length > 0 && message.length <= 80) {
    popUpMessage.info({ content: message, icon: iconInfo() });
  } else {
    notification.info({
      message,
      top: 90,
      duration: 5,
      placement: 'top',
      icon: <InfoCircleFilled />,
    });
  }

  // custom pendo track event for info
  window?.pendo?.track('SparkUI_info', {
    messages: message.toString() || '',
    url: window.location.href,
    type: 'info',
  });
  window?.pendo?.flushNow();
};

const customConfigAlert = (
  config: MessageConfigProps
): AlertThunkAction => () => {
  switch (config.type) {
    case 'info':
      config.icon = iconInfo();
      popUpMessage.info(config);
      break;
    case 'success':
      popUpMessage.success(config);
      break;
    case 'error':
      popUpMessage.error(config);
      break;
    case 'warning':
      popUpMessage.warning(config);
      break;
    default:
      break;
  }
};

const closeGlobalModal = (): AlertAction => {
  return { type: AlertConstants.ALERT_GLOBAL_CLOSE };
};

const openGlobalAlert = (contentKey: string): AlertAction => ({
  type: AlertConstants.ALERT_GLOBAL_OPEN,
  payload: { contentKey },
});

const getMessageOfApiErrorInstance = (
  dispatch,
  intl,
  defaultMessage: string,
  content: ApiError
): string[] => {
  let messages = [content.message || defaultMessage];

  if (content.codes && content.codes.length > 0) {
    let idx = content.codes.indexOf('RULE_VALIDATION_FAILED');
    if (idx !== -1) {
      content.codes.splice(idx, 1);
      dispatch(openGlobalAlert('Error.permissionError'));
    }

    idx = content.codes.indexOf('NETWORK_CANCELLED');
    if (idx !== -1) {
      content.codes.splice(idx, 1);
    }

    idx = content.codes.indexOf('INTERNAL_SERVER_ERROR');

    let msg = content.message;
    if (content.reason) {
      msg = `${content.message} Reason : ${content.reason}`;
    }
    if (idx !== -1) {
      messages = [msg];
      content.codes.splice(idx, 1);
    }

    const nonEmptyErrorCodes = content?.codes?.find(m => m.length > 0)?.length;
    if (nonEmptyErrorCodes && nonEmptyErrorCodes !== 0) {
      const radisErrorCodes = [
        AlertConstants.RADIS_GENERAL_ISSUE,
        AlertConstants.RADISCACHE_TIMEOUT_ISSUE,
        AlertConstants.RADISCACHE_COMMAND_ISSUE,
      ];
      if (radisErrorCodes.indexOf(content?.message) > -1) {
        content.message = '';
      }
      messages = content.codes.map(code =>
        intl.formatMessage({
          id: code,
          defaultMessage: content.message || defaultMessage,
        })
      );
    } else {
      // to support for v3 api.
      messages = messages?.map(message =>
        intl.formatMessage({
          id: message,
          defaultMessage: message || defaultMessage,
        })
      );
    }
  }

  return messages;
};

/**
 * @param content Error code or message
 */
const error = (
  content: ErrorContentType,
  duration?: number
): AlertThunkAction<string> => (dispatch, getState) => {
  const {
    language: { intl },
  } = getState();

  const defaultMessage = intl.formatMessage({
      id: 'DEFAULT_ERROR',
    });
  let messages = [defaultMessage];
  if (typeof content === 'string' && content) {
    messages = [
      intl.formatMessage({
        id: content,
        defaultMessage: content === '' ? defaultMessage : content,
      }),
    ];
  } else if (content instanceof ApiError) {
    messages = getMessageOfApiErrorInstance(
      dispatch,
      intl,
      defaultMessage,
      content
    );
  } else if (content instanceof Error) {
    messages = [
      intl.formatMessage({
        id: content.message,
        defaultMessage,
      }),
    ];
  }

  messages.forEach(message => {
    if (message.length > 0 && message.length <= 80) {
      popUpMessage.error({ content: message, duration, icon: iconError() });
    } else {
      notification.error({
        message,
        duration: 5,
        top: 90,
        placement: 'top',
        icon: iconError(),
      });
    }
    // custom pendo track event for error
    window?.pendo?.track('SparkUI_error', {
      messages: message.toString() || '',
      url: window.location.href,
      type: 'error',
    });
    window?.pendo?.flushNow();
  });

  return messages.join('-');
};

const clear = (): AlertAction => {
  return { type: AlertConstants.CLEAR };
};

export const AlertActions = {
  success,
  info,
  error,
  clear,
  closeGlobalModal,
  openGlobalAlert,
  customConfigAlert,
  iconInfo,
  iconError,
};
