/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useMemo } from 'react';
import { Table, Row, Dropdown, Menu } from 'antd';
import { TableProps, ColumnProps, ColumnType } from 'antd/lib/table';
import { SortOrder, ColumnTitle } from 'antd/lib/table/interface';
import styled from 'styled-components';
import { IconSvg } from '../Icons/IconSvg';

const CustomSortContainer = styled(({ supportOrder: boolean, ...rest }) => (
  <Row {...rest} />
))`
  ${({ supportOrder }) => supportOrder && 'padding-right: 35px;'}
  position: relative;
`;

const CustomDropDownSortContainer = styled(({ ...rest }) => <Row {...rest} />)`
  position: relative;
`;

const StyledSortContainer = styled(({ ...rest }) => <Row {...rest} />)`
  display: table;
  justify-content: start;
  &.left {
    justify-content: start;
  }

  &.center {
    justify-content: center;
  }

  &.right {
    justify-content: end;
  }
`;

const StyledIconSpan = styled.span`
  display: table-cell;
  vertical-align: middle;
`;

const CustomSortCaretIconContainer = styled.div`
  margin-left: 0.6rem;
`;

const CustomUpSortIcon = styled.div`
  height: 0.5rem;
  font-size: 6px;
  .anticon {
    color: ${({ theme }) => theme.color.gray2};
    &.fillColor {
      color: ${({ theme }) => theme.color.purple};
    }
  }
`;

const CustomDownSortIcon = styled.div`
  font-size: 6px;
  .anticon {
    color: ${({ theme }) => theme.color.gray2};
    &.fillColor {
      color: ${({ theme }) => theme.color.purple};
    }
  }
`;

const CustomSortIconContainer = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  margin-left: 15px;
  width: 20px;

  .drop-down-icon {
    margin-left: 22px !important;
    cursor: pointer;
    &.fillColor {
      color: ${({ theme }) => theme.color.purple};
    }
  }
`;

const StyledUpDownIconWrap = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 5px;
  align-items: center;
  justify-content: center;

  .colored {
    color: ${({ theme }) => theme.color.purple} !important;
  }

  .anticon {
    font-size: 5px;
    line-height: 1.5 !important;
  }
`;

export const StyledDefaultSortTitle = styled.div`
  display: flex;
  align-items: center;

  .anticon {
    color: ${({ theme }) => theme.color.gray2};
  }
`;

const StyledTable = styled(props => <Table {...props} />)`
  .ant-table {
    .ant-table-thead > tr > th {
      .ant-table-column-sorter {
        display: none !important;
      }
    }
  }
`;

export const getColumnTitle = (
  sortOrder: SortOrder | undefined | '',
  title: string
) => (
  <StyledDefaultSortTitle>
    {title}
    <StyledUpDownIconWrap>
      <IconSvg
        type="UpCaret"
        className={sortOrder === 'ascend' ? 'colored' : ''}
      />
      <IconSvg
        type="DownCaret"
        className={sortOrder === 'descend' ? 'colored' : ''}
      />
    </StyledUpDownIconWrap>
  </StyledDefaultSortTitle>
);

export const CustomSortTableOnHeaderCell = <
  T extends {},
  TSortBy extends string | undefined | null
>(
  sortBy: TSortBy | undefined,
  sortDir: SortOrder | undefined,
  setSort: (sortBy: TSortBy | undefined, sortDir: SortOrder | undefined) => void
): ColumnType<T>['onHeaderCell'] => {
  return (column: ColumnType<T>) => {
    return {
      onClick: () => {
        if (sortBy === column.key) {
          const sortOptions: (SortOrder | undefined)[] = [
            'ascend',
            'descend',
            undefined,
            'ascend',
          ];
          const nextSortDir = sortOptions[sortOptions.indexOf(sortDir) + 1];
          const nextSortBy = nextSortDir ? sortBy : undefined;

          setSort(nextSortBy, nextSortDir);

          return;
        }

        setSort(
          (typeof column.key === 'number'
            ? column.key.toString()
            : column.key) as TSortBy,
          'ascend'
        );
      },
    };
  };
};

export const sortIconJSX = (columnTitle, column) => {
  return (
    <StyledSortContainer
      align="middle"
      className={column.align ? column.align : ''}
    >
      <StyledIconSpan>{columnTitle}</StyledIconSpan>
      <StyledIconSpan>
        <CustomSortCaretIconContainer>
          <CustomUpSortIcon>
            {column?.sorter && (
              <IconSvg
                type="UpCaret"
                className={`sort-up-caret-icon ${
                  column.sortOrder === 'ascend' ? 'fillColor' : ''
                }`}
              />
            )}
          </CustomUpSortIcon>
          <CustomDownSortIcon>
            {column?.sorter && (
              <IconSvg
                type="DownCaret"
                className={`sort-down-caret-icon ${
                  column.sortOrder === 'descend' ? 'fillColor' : ''
                }`}
              />
            )}
          </CustomDownSortIcon>
        </CustomSortCaretIconContainer>
      </StyledIconSpan>
    </StyledSortContainer>
  );
};

export const dropDownJSX = (columnTitle, column, menu) => (
  <CustomDropDownSortContainer align="middle">
    <div>{columnTitle}</div>
    <CustomSortIconContainer>
      <Dropdown
        overlay={menu}
        placement="bottomRight"
        className="drop-down-icon"
      >
        <IconSvg
          type="DownCaret"
          className={`filter-icon ${column.sortOrder ? 'fillColor' : ''}`}
        />
      </Dropdown>
    </CustomSortIconContainer>
  </CustomDropDownSortContainer>
);

const CustomSortIconTable = <T extends {}>(props: TableProps<T>) => {
  const { columns } = props;

  const modifedColumns = useMemo(() => {
    if (!columns || columns.length === 0) {
      return [];
    }

    return columns.map(column => {
      if (!column.title) {
        return column;
      }

      const title: ColumnType<T>['title'] = (options: {
        filters: Record<string, string[]>;
        sortOrder?: SortOrder;
        sortColumn?: ColumnType<T>;
        sortColumns: {
          column: ColumnType<T>;
          order: SortOrder;
        }[];
      }) => {
        const sortColumns = options.sortColumns;
        const filteredCoulmns =
          sortColumns &&
          sortColumns.find(col => col.column.dataIndex === column['dataIndex']);

        let sortOrder;
        let sortColumn;
        if (filteredCoulmns) {
          sortOrder = filteredCoulmns.order;
          sortColumn = filteredCoulmns.column;
        }

        const columnTitle =
          typeof column.title === 'function'
            ? column.title(options)
            : column.title;

        return (
          <CustomSortContainer
            align="middle"
            supportOrder={
              !!sortColumn &&
              sortColumn.dataIndex === (column as ColumnType<{}>).dataIndex &&
              !!sortOrder
            }
          >
            <div>{columnTitle}</div>
            <CustomSortIconContainer>
              {sortColumn &&
                sortColumn.dataIndex === (column as ColumnType<{}>).dataIndex &&
                sortOrder &&
                (sortOrder === 'ascend' ? (
                  <IconSvg type="CaretRightOutlined" rotate={-90} />
                ) : (
                  <IconSvg type="CaretRightOutlined" rotate={90} />
                ))}
            </CustomSortIconContainer>
          </CustomSortContainer>
        );
      };

      return {
        ...column,
        className: column.align ? column.align : '',
        title,
      };
    });
  }, [columns]);

  return <Table {...props} columns={modifedColumns} />;
};

const CustomSortCaretIconTable = <T extends {}>(props: TableProps<T>) => {
  const { columns } = props;

  const modifedColumns = useMemo(() => {
    if (!columns || columns.length === 0) {
      return [];
    }

    return columns.map(column => {
      if (!column.title) {
        return column;
      }

      const title: ColumnTitle<T> = (options: {
        filters: Record<string, string[]>;
        sortOrder?: SortOrder;
        sortColumn?: ColumnType<T>;
      }) => {
        const columnTitle =
          typeof column.title === 'function'
            ? column.title(options)
            : column.title;

        return sortIconJSX(columnTitle, column);
      };
      return {
        ...column,
        className: column.align ? column.align : '',
        title,
      };
    });
  }, [columns]);

  return <Table {...props} columns={modifedColumns} />;
};

interface CustomTableProps<T extends {}> extends TableProps<T> {
  CustomColumn?: DTO.CustomSortDropColumnProps[];
  rowCickAction?: (record) => void;
  showCustomTitle?: boolean;
  showDefaultSortIcons?: boolean;
  showDropDown?: boolean;
}

const CustomSortDropDownTable = <T extends {}>(
  props: CustomTableProps<any>
) => {
  const {
    CustomColumn,
    dataSource,
    rowCickAction,
    columns,
    showDefaultSortIcons,
    showCustomTitle = true,
    showDropDown = true,
  } = props;
  const modifedColumns = useMemo(() => {
    if (!CustomColumn || CustomColumn.length === 0) {
      if (columns?.length) {
        return columns;
      }
      return [];
    }

    return CustomColumn.map((column, index) => {
      let title: ColumnProps<T>['title'] = column.title;
      if (
        showDefaultSortIcons &&
        column.title &&
        typeof column.title === 'string'
      ) {
        title = (
          <StyledDefaultSortTitle>
            {column.title}
            {column.sorter && <IconSvg type="CaretUpDownFilled" />}
          </StyledDefaultSortTitle>
        );
      }

      if (!showDefaultSortIcons && column.title && showCustomTitle) {
        const menu = (
          <Menu
            mode="vertical"
            selectedKeys={
              column.sortOrder !== undefined
                ? [`${column.key}${column.sortOrder}`]
                : []
            }
          >
            {column.menuItem &&
              column.menuItem.map((item, i) => (
                <Menu.Item
                  key={`${column.key}${i === 0 ? 'ascend' : 'descend'}`}
                  className="custom-dropdown"
                  onClick={() => {
                    item.onChangeSorting(
                      column.key as DTO.GetProductsRequestSortBy,
                      item.sortDir
                    );
                  }}
                >
                  {item.text}
                </Menu.Item>
              ))}
          </Menu>
        );

        title = () => {
          const columnTitle = column.title;
          return showDropDown
            ? dropDownJSX(columnTitle, column, menu)
            : sortIconJSX(columnTitle, column);
        };
      }

      return {
        ...column,
        className: column.align ? column.align : '',
        title,
        onCell: record => {
          if (index !== CustomColumn.length - 1) {
            return {
              onClick: () => rowCickAction && rowCickAction(record),
            };
          }
          return undefined;
        },
      };
    });
  }, [CustomColumn, dataSource]);

  return <StyledTable {...props} columns={modifedColumns as any} />;
};

export {
  CustomSortIconTable,
  CustomSortDropDownTable,
  CustomSortCaretIconTable,
};
