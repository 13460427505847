export const DYNAMIC_ISSUER_CONFIGURATION_KEY = '{tenant}';
export const ROLE_USER = 'user';
export const ROLE_SUPERVISOR = 'supervisor';
export const ROLE_SUPERVISOR_PF = 'supervisor:pf';
export const ROLE_API_INTEGRATION = 'user:api_integration';
export const USER_ROLES = [ROLE_SUPERVISOR, ROLE_USER];
export const GROUPS_FOR_ROLES: { [role: string]: string } = {
  [ROLE_USER]: `${ROLE_USER}:coherent.forms`,
  [ROLE_SUPERVISOR]: `${ROLE_SUPERVISOR}:coherent`,
};

export const USER_TOKEN_LOCAL_STORAGE_KEY = 'PF.USER.TOKEN';
export const USER_REFRESH_TOKEN_LOCAL_STORAGE_KEY = 'PF.USER.REFRESH.TOKEN';

export const LOGIN_REQUEST = 'USER_LOGIN_REQUEST';
export const LOGIN_CHANGE_PASSWORD_REQUIRED =
  'USER_LOGIN_CHANGE_PASSWORD_REQUIRED';
export const LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'USER_LOGIN_FAILURE';
export const LOGOUT = 'USER_LOGOUT';
export const LOGGED_OUT = 'LOGGED_OUT';

export const SET_ID_TOKEN = 'USER_SET_IDTOKEN';

export const PASSWORD_RESET_REQUEST = 'USER_PASSWORD_RESET_REQUEST';
export const PASSWORD_RESET_REQUEST_SUCCESS =
  'USER_PASSWORD_RESET_REQUEST_SUCCESS';
export const PASSWORD_RESET_REQUEST_FAILURE =
  'USER_PASSWORD_RESET_REQUEST_FAILURE';

export const PASSWORD_RESET_CONFIRM = 'USER_PASSWORD_RESET_CONFIRM';
export const PASSWORD_RESET_CONFIRM_SUCCESS =
  'USER_PASSWORD_RESET_CONFIRM_SUCCESS';
export const PASSWORD_RESET_CONFIRM_FAILURE =
  'USER_PASSWORD_RESET_CONFIRM_FAILURE';

export const PASSWORD_RESET_CLEAN = 'PASSWORD_RESET_CLEAN';
export const LOGIN_CLEAN = 'LOGIN_CLEAN';

export const CACHE_ACTION_PERMISSION = 'CACHE_ACTION_PERMISSION';
export const REFRESH_TOKEN_LIMIT_REACHED = 'REFRESH_TOKEN_LIMIT_REACHED';

export const ISSUER_CONFIGURATIONS = 'ISSUER_CONFIGURATIONS';
export const ISSUER_CONFIGURATIONS_REQUEST = 'ISSUER_CONFIGURATIONS_REQUEST';
export const ISSUER_CONFIGURATIONS_FAILURE = 'ISSUER_CONFIGURATIONS_FAILURE';
export const UNABLE_TO_CONNECT = 'UNABLE_TO_CONNECT';
export const UNAUTHORIZED_ACCESS = 'UNAUTHORIZED_ACCESS';
export const FORBIDDEN_ERROR = 'FORBIDDEN_ERROR';
export const UNABLE_TO_PARSE_RESPONSE = 'UNABLE_TO_PARSE_RESPONSE';
export const SUPERVISOR_ROAL_ADDED = 'SUPERVISOR_ROAL_ADDED';
