import { ThunkDispatch } from 'redux-thunk';
import { AppAction, AppThunkAction } from './types';
import { AlertActions } from './alert.actions';

const createAsyncThunk = <T = void>(
  creator: (
    dispatch: ThunkDispatch<STATES.AppState, null, AppAction>,
    getState: () => STATES.AppState
  ) => Promise<T>,
  onError: (
    error: Error | unknown,
    msg: string,
    dispatch: ThunkDispatch<STATES.AppState, null, AppAction>
  ) => T
): AppThunkAction<T> => async (dispatch, getState) => {
  try {
    const result = await creator(dispatch, getState);

    return result;
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    return onError(error, msg, dispatch);
  }
};

export { createAsyncThunk };
