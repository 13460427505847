import { Button } from 'antd';
import React, { FC } from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { IconSvg } from '../Icons/IconSvg';

export interface CustomButtonProps {
  onClick?: React.MouseEventHandler<HTMLElement>;
  className?: string;
  label?: string;
  type?: DTO.ButtonPropsType;
  shape?: 'circle' | 'round';
  disabled?: boolean;
  htmlType?: 'button' | 'submit' | 'reset';
  icon?: string;
  prefix?: React.ReactElement;
  to?: string;
  dataTestId?: string;
  block?: boolean;
}

const ButtonWrapper = styled(props => <Button {...props} />)`
  .ant-btn {
    margin: 0 5px;
    height: 36px !important;
    cursor: pointer;
    .prefix {
      margin-right: 8px;
    }

    &.ant-btn-link {
      cursor: pointer !important;
    }
    &.ant-btn-primary {
      line-height: 24px !important;
      color: ${({ theme }) => theme.color.white};
      background-color: ${({ theme }) => theme.color.purpleDarkened2};
      border-color: ${({ theme }) => theme.color.purpleDarkened2};
      border: none;
      min-width: 200px;
      :focus,
      :hover {
        background-color: ${({ theme }) => theme.color.purpleDarkened1};
        border: none;
        color: ${({ theme }) => theme.color.white};
      }
      :active {
        background-color: ${({ theme }) => theme.color.purpleDarkened3};
      }
    }
    &.ant-btn-default {
      line-height: 24px !important;
      border-color: ${({ theme }) => theme.color.purple};
      color: ${({ theme }) => theme.color.purpleDarkened3};
      font-weight: bold;
      :focus,
      :hover {
        background-color: ${({ theme }) => theme.color.purple20};
      }
      :active {
        background-color: ${({ theme }) => theme.color.purpleDarkened3};
        color: ${({ theme }) => theme.color.white};
      }
    }
  }
  &.ant-icon-only {
    height: 32px;
    width: 32px;
    color: ${({ theme }) => theme.color.purple};
    border-radius: 4px;
    padding: 0px !important;
    margin: 0px 8px;
    line-height: 24px !important;
    display: flex;
    align-items: center;
    justify-content: center;

    .anticon + span {
      margin-top: 2px;
      margin-left: 2px;
    }
    &.ant-btn-link:hover {
      border-color: initial;
    }
    :hover,
    :focus {
      border-radius: 4px;
      cursor: pointer;
      border: 1px solid ${({ theme }) => theme.color.dark20} !important;
      background: transparent;
    }
    &.ant-dropdown-open,
    :active {
      .anticon {
        color: #fff;
      }
      border: 1px solid transparent !important;
      background: ${({ theme }) => theme.color.purple};
    }
    &.dot-icon {
      width: 20px;
    }
    &.ant-btn-disabled {
      cursor: not-allowed;
      :hover {
        border: 1px solid transparent !important;
      }
      :active,
      :focus {
        background: transparent;
        border: none !important;
        .anticon {
          color: ${({ theme }) => theme.color.purple20};
        }
      }
      
    }
  }
`;

const CustomButton: FC<CustomButtonProps> = ({
  onClick,
  className,
  label,
  type,
  shape,
  disabled,
  htmlType,
  icon,
  prefix,
  to,
  dataTestId,
  block,
}) => {
  return (
    <ButtonWrapper
      block={block}
      className={className}
      onClick={onClick}
      shape={shape ?? 'round'}
      type={type ?? 'primary'}
      disabled={disabled}
      htmlType={htmlType}
      icon={icon ? <IconSvg type={icon} /> : <></>}
      href={to}
      data-testid={dataTestId}
    >
      {prefix && <span className="prefix">{prefix}</span>}
      {label && <FormattedMessage id={label} />}
    </ButtonWrapper>
  );
};

export { CustomButton };
