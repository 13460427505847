export const colorHexToRgb = (color: string) => {
  if (!color) {
    return '';
  }

  const components = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(
    color.toLowerCase()
  );

  if (!components) {
    return '';
  }

  const red = parseInt(components[1], 16);
  const green = parseInt(components[2], 16);
  const blue = parseInt(components[3], 16);

  return {
    red,
    green,
    blue,
  };
};

export const colorHexToRgba = (color: string, opacity: number) => {
  const rgb = colorHexToRgb(color);

  if (!rgb) {
    return '';
  }

  const { red, green, blue } = rgb;

  return `rgba(${red}, ${green}, ${blue}, ${opacity})`;
};
