import { ThemeAction } from '../actions/types';
import { LanguageConstants } from '../constants';
import MainDefaultTheme from '../styles/MainDefaultTheme';

export const theme = (
  state = MainDefaultTheme as STATES.ThemeState,
  action: ThemeAction
): STATES.ThemeState => {
  if (action.type === LanguageConstants.SET_LANGUAGE_MESSAGES) {
    const { currentLanguage } = action.payload;
    const font =
      currentLanguage === 'en' ? 'GT Walsheim' : 'Hiragino Kaku Gothic Pro';

    return {
      ...state,
      vars: { ...state.vars, fontFamily: font },
      color: { ...state.color },
    };
  }
  return state;
};
