import moment from 'moment';
import { IntlShape } from 'react-intl';
import { SystemConstants } from '../constants';

const getTagWiseServiceList = (tags: string[], list: DTO.OptionServices[]) => {
  const tagWiseList = {};

  for (let listIndex = 0; listIndex < list.length; listIndex++) {
    for (let tagsIndex = 0; tagsIndex < tags.length; tagsIndex++) {
      if (list[listIndex].tags?.includes(tags[tagsIndex])) {
        if (tagWiseList[tags[tagsIndex]]) {
          tagWiseList[tags[tagsIndex]].push(list[listIndex]);
        } else {
          tagWiseList[tags[tagsIndex]] = [list[listIndex]];
        }
        break;
      }
    }
  }
  return tagWiseList;
};

const groupDataByDate = (
  data: DTO.DashboardAggregatedData[] | { date: string; count: number }[], // 'YYYY-MM-DD HH:mm:ss'
  shouldGenerateAllDate = false,
  startDate?: string, // 'YYYY-MM-DD'
  endDate?: string // 'YYYY-MM-DD'
): {
  date: string;
  count: number;
}[] => {
  const groupedData = {};

  data.forEach(entry => {
    const date = moment(entry.date, SystemConstants.SHORT_DATE_FORMAT).format(
      SystemConstants.SHORT_DATE_FORMAT
    );

    if (groupedData[date]) {
      groupedData[date] += entry.count;
    } else {
      groupedData[date] = entry.count;
    }
  });

  if (shouldGenerateAllDate) {
    // Generate all dates between startDate and endDate
    const currentDate = moment(startDate, SystemConstants.SHORT_DATE_FORMAT);
    const formattedEndDate = moment(endDate, SystemConstants.SHORT_DATE_FORMAT);
    const allDates: string[] = [];

    while (currentDate.isSameOrBefore(formattedEndDate, 'day')) {
      allDates.push(currentDate.format(SystemConstants.SHORT_DATE_FORMAT));
      currentDate.add(1, 'days');
    }

    // Fill missing dates with count 0
    allDates.forEach(date => {
      if (!groupedData[date]) {
        groupedData[date] = 0;
      }
    });
  }

  // Sort the grouped data by date in ascending order
  const sortedDates = Object.keys(groupedData).sort((a, b) => {
    return (
      moment(a, SystemConstants.SHORT_DATE_FORMAT).valueOf() -
      moment(b, SystemConstants.SHORT_DATE_FORMAT).valueOf()
    );
  });

  return sortedDates.map(date => ({
    date,
    count: groupedData[date],
  }));
};

// Function to split dates accept param formate YYYY-MM-DD
const splitDates = (start: string, end: string) => {
  const startDt = moment(start, SystemConstants.SHORT_DATE_FORMAT);
  const endDt = moment(end, SystemConstants.SHORT_DATE_FORMAT);

  const diffDays = Math.ceil(endDt.diff(startDt, 'days') / 2);

  const firstHalfEnd = startDt
    .clone()
    .add(diffDays, 'days')
    .subtract(1, 'days')
    .format(SystemConstants.SHORT_DATE_FORMAT);
  const secondHalfStart = startDt
    .clone()
    .add(diffDays, 'days')
    .format(SystemConstants.SHORT_DATE_FORMAT);

  return [
    {
      startDate: startDt.format(SystemConstants.SHORT_DATE_FORMAT),
      endDate: firstHalfEnd,
    },
    {
      startDate: secondHalfStart,
      endDate: endDt.format(SystemConstants.SHORT_DATE_FORMAT),
    },
  ];
};

const splitDataByDates = (
  data: DTO.DashboardAggregatedData[],
  splitDateRange: {
    startDate: string; // YYYY-MM-DD
    endDate: string; // YYYY-MM-DD
  }[]
): DTO.DashboardAggregatedData[][] => {
  return splitDateRange.map(({ startDate, endDate }) => {
    const data1 = data.filter(item => {
      const itemDate = moment(item.date, SystemConstants.SHORT_DATE_FORMAT);
      const startDt = moment(startDate, SystemConstants.SHORT_DATE_FORMAT);
      const endDt = moment(endDate, SystemConstants.SHORT_DATE_FORMAT);

      return itemDate.isBetween(startDt, endDt, 'day', '[]');
    });

    return data1;
  });
};

const addTagToData = (
  splitData: DTO.DashboardAggregatedData[][],
  tagWiseServiceList: { [key: string]: DTO.OptionServices[] }
) => {
  if (Object.keys(tagWiseServiceList).length === 0) {
    return splitData;
  }
  const tempData = JSON.parse(JSON.stringify(splitData));
  tempData.forEach(data => {
    data.forEach(item1 => {
      Object.keys(tagWiseServiceList).forEach(folderType => {
        tagWiseServiceList[folderType].forEach(item2 => {
          if (
            item1.folder === item2.folderName &&
            item1.service === item2.fileName
          ) {
            item1.tag = folderType;
          }
        });
      });
    });
  });
  return tempData;
};

const getTagWiseCount = (
  splitData: DTO.DashboardAggregatedData[][],
  TagsSelected: string[],
  intl: IntlShape
) => {
  return splitData.map(data => {
    const tagWiseCount = TagsSelected.sort().map(tag => {
      const count = data.reduce((acc, item) => {
        return item.tag === tag ? acc + item.count : acc;
      }, 0);
      return { name: tag, count };
    });

    const totalCount = data.reduce((acc, item) => {
      return acc + item.count;
    }, 0);

    tagWiseCount.push({
      name: intl.formatMessage({
        id: 'Dashboard.chart.serviceCount.tag.total',
      }),
      count: totalCount,
    });

    return tagWiseCount;
  });
};

const calculatePercentageIncrease = (
  splitDataTagWiseCount: { name: string; count: number }[][]
) => {
  return splitDataTagWiseCount[0].map((item, index) => {
    const item1 = splitDataTagWiseCount[1][index];

    if (item.count !== 0) {
      const percentage = ((item1.count - item.count) / item.count) * 100;
      return {
        name: item1.name,
        count: item1.count,
        percentage,
      };
    }

    if (item.count === 0 && item1.count === 0) {
      return {
        name: item1.name,
        count: item1.count,
        percentage: 0,
      };
    }
    return {
      name: item1.name,
      count: item1.count,
      percentage: 'Infinity',
    };
  });
};

export {
  getTagWiseServiceList,
  groupDataByDate,
  splitDates,
  splitDataByDates,
  addTagToData,
  getTagWiseCount,
  calculatePercentageIncrease,
};
