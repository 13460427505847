/* eslint-disable @typescript-eslint/no-explicit-any */
import { BackgroundJobsActions } from './backgroundJobs.action';
import {
  BackgroundJobsConstants,
  TestbedConstants,
} from '../constants';
import { ApiError, isCustomErrorCode } from '../helpers';
import { TestbedService } from '../services';
import { AlertActions } from './alert.actions';
import { TestbedThunkAction } from './types';
import { FileManagerActions } from './fileManager.actions';

const generateTemplate = (
  testbedTemplateRequest: DTO.TestbedTemplateRequest
): TestbedThunkAction<boolean | null> => async (dispatch, getState) => {
  try {
    dispatch({
      type: TestbedConstants.GENERATED_TEST_TEMPLATE_REQUEST,
    });

    const { payload, status } = await TestbedService.generateTemplate(
      testbedTemplateRequest
    );

    if (status !== 200 || payload.status === 'Error') {
      checkTempDocFailureError(payload.errorCode ?? '', 'generateTemplate', getState);
      throw new ApiError(payload);
    }

    dispatch({
      type: TestbedConstants.GENERATED_TEST_TEMPLATE_SUCCESS,
      payload: {
        url: payload.data.url,
        fileName: payload.data.fileName,
      },
    });
    if (payload.data.url != null) {
      dispatch(
        FileManagerActions.downloadBlobAction(
          payload.data.url,
          payload.data.fileName
        )
      );
      dispatch(
        AlertActions.success(
          'TestingCenter.testtemplate.preview.generate.success'
        )
      );
    }

    return true;
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: TestbedConstants.GENERATED_TEST_TEMPLATE_FAILURE,
      payload: { error: msg },
    });
    return false;
  }
};

const getTestbeds = (
  testbedPagedRequest: DTO.TestbedPagedRequest
): TestbedThunkAction => async dispatch => {
  try {
    dispatch({
      type: TestbedConstants.GET_TEST_BEDS_REQUEST,
    });

    const { payload, status } = await TestbedService.getTestbeds(
      testbedPagedRequest
    );

    if (status !== 200 || payload.status === 'Error') {
      throw new ApiError(payload);
    }

    dispatch({
      type: TestbedConstants.GET_TEST_BEDS_SUCCESS,
      payload: {
        testbedList: payload.data,
        totalTestbeds: payload.count,
      },
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: TestbedConstants.GET_TEST_BEDS_FAILURE,
      payload: { error: msg },
    });
  }
};

const getTestbedQueue = (
  testbedQueueId: string
): TestbedThunkAction => async dispatch => {
  try {
    dispatch({
      type: TestbedConstants.GET_TEST_BED_QUEUE_REQUEST,
    });

    const { payload, status } = await TestbedService.getTestbedQueue(
      testbedQueueId
    );

    if (status !== 200 || payload.status === 'Error') {
      throw new ApiError(payload);
    }

    dispatch({
      type: TestbedConstants.GET_TEST_BED_QUEUE_SUCCESS,
      payload: {
        testbedQueueProgress: payload.data,
        testbedQueueId,
      },
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: TestbedConstants.GET_TEST_BED_QUEUE_FAILURE,
      payload: { error: msg },
    });
  }
};

const getTestbedResult = (
  testbedQueueId: string
): TestbedThunkAction => async dispatch => {
  try {
    dispatch({
      type: TestbedConstants.GET_TEST_BED_RESULT_REQUEST,
    });

    const { payload, status } = await TestbedService.getTestbedResult(
      testbedQueueId
    );

    if (status !== 200 || payload.status === 'Error') {
      throw new ApiError(payload);
    }

    dispatch({
      type: TestbedConstants.GET_TEST_BED_RESULT_SUCCESS,
      payload: {
        testbedResult: payload.data,
      },
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: TestbedConstants.GET_TEST_BED_RESULT_FAILURE,
      payload: { error: msg },
    });
  }
};

const saveTestbedQueue = (
  testbedQueueRequest: DTO.TestbedQueueRequest
): TestbedThunkAction<boolean> => async (dispatch, getState) => {
  try {
    dispatch({
      type: TestbedConstants.SAVE_TEST_BED_QUEUE_REQUEST,
    });

    const {
      language: { intl },
    } = getState();

    const { payload, status } = await TestbedService.saveTestbedQueue(
      testbedQueueRequest
    );

    if (payload?.errorCode === 'MAX_TESTRUN_LIMIT_PER_USER_EXCEEDED') {
      const errMsg = intl.formatMessage(
        { id: payload.errorCode },
        {
          values: payload.message,
        }
      );
      const errData = [{ key: payload.errorCode, values: payload.message }];
      throw new ApiError({
        message: errMsg,
        data: errData,
      });
    }

    if (status !== 200 || payload.status === 'Error') {
      throw new ApiError(payload);
    }

    dispatch({
      type: TestbedConstants.SAVE_TEST_BED_QUEUE_SUCCESS,
      payload: {
        testbedQueueId: payload.data,
      },
    });
    dispatch(BackgroundJobsActions.getAll());
    dispatch(getTestbedQueue(payload.data));
    return true;
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error, 5));

    dispatch({
      type: TestbedConstants.SAVE_TEST_BED_QUEUE_FAILURE,
      payload: { error: msg },
    });
    return false;
  }
};

const generateTestbedResult = (
  testRunId: string,
  fileType: string
) => async dispatch => {
  try {
    dispatch({
      type: TestbedConstants.GENERATED_TESTBED_RESULT_REQUEST,
    });

    const { payload, status } = await TestbedService.downloadTestResult(
      testRunId,
      fileType
    );

    if (status !== 200 || payload.status === 'Error') {
      throw new ApiError(payload);
    }

    dispatch({
      type: TestbedConstants.GENERATED_TESTBED_RESULT_SUCCESS,
      payload: {
        jobId: payload.data,
      },
    });

    dispatch({
      type: BackgroundJobsConstants.TRIGGER_REFRESH_GBJOBS,
    });
    await dispatch(AlertActions.info('TestingCenter.download.successMessage'));
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: TestbedConstants.GENERATED_TESTBED_RESULT_FAILURE,
      payload: { error: msg },
    });
  }
};

const removeTestbed = (
  testbedId: string,
  testbedName: string
): TestbedThunkAction => async (dispatch, getState) => {
  try {
    dispatch({
      type: TestbedConstants.REMOVE_TEST_BED_REQUEST,
    });

    const {
      language: { intl },
    } = getState();

    const { payload, status } = await TestbedService.removeTestbed(testbedId);

    if (status !== 200 || payload.status === 'Error') {
      throw new ApiError(payload);
    }

    dispatch({
      type: TestbedConstants.REMOVE_TEST_BED_SUCCESS,
    });

    dispatch(
      AlertActions.success(
        intl.formatMessage(
          { id: 'TestingCenter.testbeds.delete.success' },
          {
            name: testbedName,
          }
        )
      )
    );
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: TestbedConstants.REMOVE_TEST_BED_FAILURE,
      payload: { error: msg },
    });
  }
};

const getTestbedById = (
  folder: string,
  serviceName: string,
  id: string
): TestbedThunkAction<DTO.Testbed | undefined> => async dispatch => {
  try {
    dispatch({
      type: TestbedConstants.GET_TESTBEDID_REQUEST,
    });

    const { payload, status } = await TestbedService.getTestbedById(
      folder,
      serviceName,
      id
    );

    if (status !== 200 || payload.status === 'Error') {
      throw new ApiError(payload);
    }

    dispatch({
      type: TestbedConstants.GET_TESTBEDID_SUCCESS,
      payload: {
        testbed: payload.data,
      },
    });
    return payload.data;
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: TestbedConstants.GET_TESTBEDID_FAILURE,
      payload: { error: msg },
    });
    return undefined;
  }
};

const getTestRuns = (
  folder: string,
  service: string,
  testbedId: string,
  request: DTO.PagedRequest
): TestbedThunkAction => async dispatch => {
  dispatch({ type: TestbedConstants.GET_TESTRUNS_REQUEST });

  try {
    const { payload, status } = await TestbedService.getTestRunsByTesbedId(
      folder,
      service,
      testbedId,
      request
    );
    if (status !== 200 || payload.status === 'Error') {
      throw new ApiError(payload);
    }

    const { data } = payload;
    dispatch({
      type: TestbedConstants.GET_TESTRUNS_SUCCESS,
      payload: {
        data: data.result,
        count: data.count,
      },
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: TestbedConstants.GET_TESTRUNS_FAILURE,
      payload: { error: msg },
    });
  }
};

const deleteTestRun = (
  testRunId: string
): TestbedThunkAction<boolean> => async dispatch => {
  try {
    dispatch({
      type: TestbedConstants.DELETE_TESTRUN_REQUEST,
    });

    const { payload, status } = await TestbedService.deleteTestRun(testRunId);

    if (status !== 200 || payload.status === 'Error') {
      throw new ApiError(payload);
    }

    dispatch({
      type: TestbedConstants.DELETE_TESTRUN_SUCCESS,
    });
    return true;
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: TestbedConstants.DELETE_TESTRUN_FAILURE,
      payload: { error: msg },
    });
    return false;
  }
};

const resetUploadTestBed = () => ({
  type: TestbedConstants.TEST_BED_UPLOAD_RESET,
});

const uploadTestBed = (
  file: File | undefined,
  request: DTO.TestBedRequest
): TestbedThunkAction<boolean> => async dispatch => {
  try {
    let hasWarnings = false;
    dispatch({
      type: TestbedConstants.TEST_BED_UPLOAD_START,
    });
    const response = await TestbedService.uploadTestbed(
      file,
      request,
      uploadProgress => {
        if (uploadProgress === 100) {
          return;
        }

        // 5% for server processing time
        dispatch({
          type: TestbedConstants.TEST_BED_UPLOAD_PROGRESS,
          payload: {
            uploadProgress: Math.max(5, uploadProgress - 5),
          },
        });
      },
      xhrRef => {
        dispatch({
          type: TestbedConstants.TEST_BED_UPLOAD_XHR_REF,
          payload: { xhrRef },
        });
      }
    );
    const { status, payload } = response;
    if (status !== 200 || payload.status !== 'Success') {
      if (payload.errorCode) {
        dispatch({
          type: TestbedConstants.TEST_BED_UPLOAD_ERROR,
          payload: {
            error: payload.errorCode,
          },
        });
      }
      if (
        payload.errorCode === 'INVALID_TEST_BED_FILE' ||
        payload.errorCode === 'BAD_CSV_TEST_BED_FILE'
      ) {
        return false;
      }
      throw new ApiError(payload);
    }

    if (payload.data?.errors?.length) {
      dispatch({
        type: TestbedConstants.TEST_BED_UPLOAD_ERROR,
        payload: { error: payload.data?.errors[0] },
      });
      return false;
    }

    if (payload.data?.warnings?.length) {
      hasWarnings = true;
    }

    dispatch({
      type: TestbedConstants.TEST_BED_UPLOAD_SUCCESS,
      payload: {
        uploadTestbedResults: payload.data,
        hasWarnings,
      },
    });

    return true;
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: TestbedConstants.TEST_BED_UPLOAD_ERROR,
      payload: { error: msg },
    });

    return false;
  }
};

const publishTestbed = (
  request: DTO.PublishTestbedRequest
): TestbedThunkAction => async dispatch => {
  try {
    dispatch({
      type: TestbedConstants.PUBLISH_TESTBED_REQUEST,
    });

    const { payload, status } = await TestbedService.publishTestbed(request);

    if (status !== 200 || payload.status === 'Error') {
      throw new ApiError(payload);
    }
    await dispatch(
      AlertActions.success('TestingCenter.testbed.publish.success')
    );

    dispatch({
      type: TestbedConstants.PUBLISH_TESTBED_SUCCESS,
      payload: { testbedvId: request.testbedVersionId },
    });

    setTimeout(() => {
      dispatch({
        type: TestbedConstants.TEST_BED_LIST_HIGHLIGHT_RESET,
      });
    }, 5000);
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: TestbedConstants.PUBLISH_TESTBED_FAILURE,
      payload: { error: msg },
    });
  }
};
const generateTestCompare = (
  sourceTestRunId: string,
  targetTestRunId: string
): TestbedThunkAction<any> => async (dispatch, getState) => {
  try {
    dispatch({
      type: TestbedConstants.GENERATED_TEST_COMPARE_REQUEST,
    });

    const { payload, status } = await TestbedService.generateCompareResult(
      sourceTestRunId,
      targetTestRunId
    );

    if (status !== 200 || payload.status === 'Error') {
      checkTempDocFailureError(payload.errorCode ?? '', 'compareTest', getState);
      throw new ApiError(payload);
    }

    dispatch({
      type: TestbedConstants.GENERATED_TEST_COMPARE_SUCCESS,
      payload: {
        url: payload.data.url,
        fileName: payload.data.fileName,
      },
    });
    return {
      url: payload.data.url || null,
      fileName: payload.data.fileName || null,
    };
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: TestbedConstants.GENERATED_TEST_COMPARE_FAILURE,
      payload: { error: msg },
    });

    return {
      url: null,
      fileName: null,
    };
  }
};
const updateTestbed = (
  testbedVersionId: string,
  request: DTO.UpdateTestbedItemRequest[]
): TestbedThunkAction => async dispatch => {
  try {
    dispatch({
      type: TestbedConstants.UPDATE_TEST_BED_REQUEST,
    });
    const { payload, status } = await TestbedService.updateTestbed(
      testbedVersionId,
      request
    );

    if (status !== 200 || payload.status === 'Error') {
      throw new ApiError(payload);
    }
    dispatch(AlertActions.success('TestingCenter.testeditor.save.success'));
    dispatch({
      type: TestbedConstants.UPDATE_TEST_BED_SUCCESS,
      payload: {
        latestTestbedVersionId: payload.data,
      },
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: TestbedConstants.UPDATE_TEST_BED_FAILURE,
      payload: { error: msg },
    });
  }
};

const queueGenerateTestCases = (
  productName: string,
  serviceName: string,
  versionId: string,
  data: DTO.GenerateTestCasesRequest
): TestbedThunkAction => async dispatch => {
  try {
    dispatch({
      type: TestbedConstants.QUEUE_GENERATE_TESTCASES_REQUEST,
      payload: { productName, serviceName },
    });

    const { payload, status } = await TestbedService.queueGenerateTestCases(
      productName,
      serviceName,
      versionId,
      data
    );

    if (status !== 200 || payload.status === 'Error') {
      if (payload?.errorCode !== undefined) {
        await dispatch(AlertActions.error(payload?.errorCode));
        dispatch({
          type: TestbedConstants.QUEUE_GENERATE_TESTCASES_FAILURE,
          payload: { error: payload?.errorCode },
        });
        return;
      }
      throw new ApiError(payload);
    }
    dispatch(BackgroundJobsActions.getAll());

    dispatch({
      type: TestbedConstants.QUEUE_GENERATE_TESTCASES_SUCCESS,
      payload: {
        taskQueueId: payload.data,
      },
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: TestbedConstants.QUEUE_GENERATE_TESTCASES_FAILURE,
      payload: { error: msg },
    });
  }
};

const getGenerateTestcasesStatus = (
  taskQueueId: string
): TestbedThunkAction => async dispatch => {
  try {
    dispatch({
      type: TestbedConstants.GET_GENERATE_TESTCASE_STATUS_REQUEST,
    });

    const { payload, status } = await TestbedService.getGenerateTestcasesStatus(
      taskQueueId
    );

    if (status !== 200 || payload.status === 'Error') {
      throw new ApiError(payload);
    }

    dispatch({
      type: TestbedConstants.GET_GENERATE_TESTCASE_STATUS_SUCCESS,
      payload: {
        taskQueueProgress: payload.data,
        taskQueueId,
      },
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: TestbedConstants.GET_GENERATE_TESTCASE_STATUS_FAILURE,
      payload: { error: msg },
    });
  }
};

const resetTaskQueue = () => ({
  type: TestbedConstants.RESET_TASK_QUEUE,
});

const resetTaskQueueId = () => ({
  type: TestbedConstants.RESET_TASK_QUEUE_ID,
});

const resetFileUrls = () => ({
  type: TestbedConstants.RESET_FILE_URLS,
});

const resetTestbedRun = () => ({
  type: TestbedConstants.RESET_TEST_BED_RUN,
});

const resetTestBedTemplate = () => ({
  type: TestbedConstants.RESET_TESTBED_TEMPLATE,
});

const resetTestBedQueueReset = () => ({
  type: TestbedConstants.GET_TEST_BED_QUEUE_RESET,
});

const resetTestBedHighlight = () => ({
  type: TestbedConstants.TEST_BED_LIST_HIGHLIGHT_RESET,
});

const downloadTestBedTestCases = (
  testbedId: string,
  type: string,
  source?: string,
  SubServiceName?: string[]
): TestbedThunkAction => async dispatch => {
  try {
    dispatch({
      type: TestbedConstants.DOWNLOAD_TESTBED_TESTCASES_REQUEST,
    });

    const subServiceName = SubServiceName?.join(',');

    const { payload, status } = await TestbedService.downloadTestBedTestCases(
      testbedId,
      type,
      source,
      subServiceName
    );

    if (status !== 200 || payload.status === 'Error') {
      throw new ApiError(payload);
    }

    dispatch({
      type: TestbedConstants.DOWNLOAD_TESTBED_TESTCASES_SUCCESS,
      payload: {
        jobId: payload.data,
      },
    });
    dispatch(BackgroundJobsActions.getAll());
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: TestbedConstants.DOWNLOAD_TESTBED_TESTCASES_FAILURE,
      payload: { error: msg },
    });
  }
};

async function cancelTestbedQueue(dispatch, id: string) {
  dispatch({ type: TestbedConstants.CANCEL_TESTBED_QUEUE_REQUEST });

  try {
    const { payload } = await TestbedService.cancelTestbedQueue(id);
    if (payload.status !== 'Success') {
      throw new ApiError(payload);
    }

    dispatch({
      type: TestbedConstants.CANCEL_TESTBED_QUEUE_SUCCESS,
    });
  } catch (error) {
    const msg = error;

    dispatch({
      type: TestbedConstants.CANCEL_TESTBED_QUEUE_FAILURE,
      payload: { error: msg },
    });
  }
}

const cancelDraftTestbed = (
  versionId: string
): TestbedThunkAction => async dispatch => {
  dispatch({ type: TestbedConstants.CANCEL_DRAFT_TESTBED_REQUEST });

  try {
    const { payload } = await TestbedService.cancelDraftTestbed(versionId);
    if (payload.status !== 'Success') {
      throw new ApiError(payload);
    }

    dispatch({
      type: TestbedConstants.CANCEL_DRAFT_TESTBED_SUCCESS,
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: TestbedConstants.CANCEL_DRAFT_TESTBED_FAILURE,
      payload: { error: msg },
    });
  }
};

const isTestbedExists = (
  folder: string,
  service: string,
  name: string
): TestbedThunkAction<boolean> => async dispatch => {
  dispatch({ type: TestbedConstants.CHECK_TESTBED_NAME_REQUEST });

  try {
    const { payload } = await TestbedService.isTestbedExists(
      folder,
      service,
      name
    );
    if (payload.status !== 'Success') {
      throw new ApiError(payload);
    }

    dispatch({
      type: TestbedConstants.CHECK_TESTBED_NAME_SUCCESS,
    });
    return false;
  } catch (error) {
    dispatch({
      type: TestbedConstants.CHECK_TESTBED_NAME_FAILURE,
      payload: { error: error?.toString() },
    });
    return true;
  }
};

const isTestRunNameExists = (
  testbedId: string,
  testRunName: string,
  testRunId?: string
): TestbedThunkAction<boolean> => async dispatch => {
  dispatch({ type: TestbedConstants.CHECK_TESTRUN_NAME_REQUEST });

  try {
    const { payload } = await TestbedService.isTestRunNameExists(
      testbedId,
      testRunName,
      testRunId
    );
    if (payload.status !== 'Success') {
      throw new ApiError(payload);
    }

    dispatch({
      type: TestbedConstants.CHECK_TESTRUN_NAME_SUCCESS,
    });
    return false;
  } catch (error) {
    dispatch({
      type: TestbedConstants.CHECK_TESTRUN_NAME_FAILURE,
      payload: { error: error?.toString() },
    });
    return true;
  }
};
const favouriteTestbed = (
  id: string,
  isFavourite: boolean,
  name: string
): TestbedThunkAction => async dispatch => {
  dispatch({ type: TestbedConstants.FAVOURITE_TESTBED_REQUEST });

  try {
    const { payload } = await TestbedService.favouriteTestbed(id, isFavourite);
    if (payload.status !== 'Success') {
      throw new ApiError(payload);
    }

    dispatch({
      type: TestbedConstants.FAVOURITE_TESTBED_SUCCESS,
    });
    if (isFavourite) {
      dispatch(
        AlertActions.success('TestingCenter.testbeds.menu.favorite.success', {
          name,
        })
      );
    } else {
      dispatch(
        AlertActions.info('TestingCenter.testbeds.menu.unfavorite.success')
      );
    }
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: TestbedConstants.FAVOURITE_TESTBED_FAILURE,
      payload: { error: msg },
    });
  }
};

const uploadTestbedTestcases = (
  file: File | undefined,
  request: DTO.UploadTestbedTestcases
): TestbedThunkAction<boolean> => async dispatch => {
  try {
    dispatch({
      type: TestbedConstants.UPDATE_TESTBED_START,
    });
    const response = await TestbedService.uploadTestbedTestcases(
      file,
      request,
      uploadProgress => {
        if (uploadProgress === 100) {
          return;
        }

        // 5% for server processing time
        dispatch({
          type: TestbedConstants.UPDATE_TESTBED_PROGRESS,
          payload: {
            uploadProgress: Math.max(5, uploadProgress - 5),
          },
        });
      },
      xhrRef => {
        dispatch({
          type: TestbedConstants.UPDATE_TESTBED_XHR_REF,
          payload: { xhrRef },
        });
      }
    );
    const { status, payload } = response;
    if (status !== 200 || payload.status !== 'Success') {
      const handleErrorCode = [
        'INVALID_TEST_BED_FILE',
        'TEST_BED_DOES_NOT_MATCH',
        'INPUT_MISMATCH',
        'ALL_DUPLICATE_TEST_CASES',
      ];
      if (payload.errorCode && handleErrorCode.includes(payload.errorCode)) {
        dispatch({
          type: TestbedConstants.UPDATE_TESTBED_ERROR,
          payload: {
            error: `TestingCenter.testrundetails.addtestcases.upload.${payload.errorCode}`,
          },
        });
        return false;
      }
      throw new ApiError(payload);
    }

    dispatch({
      type: TestbedConstants.UPDATE_TESTBED_SUCCESS,
      payload: {
        uploadResult: payload.data,
      },
    });

    return true;
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));
    dispatch({
      type: TestbedConstants.UPDATE_TESTBED_ERROR,
      payload: {
        error: msg,
      },
    });
    return false;
  }
};

const resetUpdateTestbedTestcases = () => ({
  type: TestbedConstants.UPDATE_TESTBED_RESET,
});

const publishTestbedTestcases = (
  request: DTO.PublishTestbedTestcasesRequest,
  successDataRequest: { folder: string; serviceName: string; testbedId: string }
): TestbedThunkAction<boolean> => async (dispatch, getState) => {
  try {
    const {
      testbeds: {
        updateTestbed: {
          fileupload: { uploadResult },
        },
      },
    } = getState();

    dispatch({
      type: TestbedConstants.PUBLISH_TESTBED_TESTCASES_REQUEST,
    });

    const { payload, status } = await TestbedService.publishTestbedTestcases(
      request
    );

    if (status !== 200 || payload.status === 'Error') {
      throw new ApiError(payload);
    }

    // Start On successfull publish update testbedDetail
    const {
      payload: testbedDetailPayload,
      status: testbedDetailStatus,
    } = await TestbedService.getTestbedById(
      successDataRequest.folder,
      successDataRequest.serviceName,
      successDataRequest.testbedId
    );
    if (
      testbedDetailStatus !== 200 ||
      testbedDetailPayload.status === 'Error'
    ) {
      throw new ApiError(payload);
    }

    dispatch({
      type: TestbedConstants.GET_TESTBEDID_SUCCESS,
      payload: {
        testbed: testbedDetailPayload.data,
      },
    });
    // End On successfull publish update testbedDetail

    await dispatch(
      AlertActions.success(
        'TestingCenter.testrundetails.addtestcases.upload.success.message',
        {
          value: uploadResult?.newTestCaseCount?.toString() || '',
        }
      )
    );

    dispatch({
      type: TestbedConstants.PUBLISH_TESTBED_TESTCASES_SUCCESS,
    });

    setTimeout(() => {
      dispatch({
        type: TestbedConstants.PUBLISH_TESTBED_TESTCASES_RESET,
      });
    }, 5000);

    return true;
  } catch (error) {
    await dispatch(AlertActions.error(error));
    dispatch({
      type: TestbedConstants.PUBLISH_TESTBED_TESTCASES_FAILURE,
    });
    return false;
  }
};

const highlightTestbedRow = (
  testbedVersionId: string,
  testbedTestRunId: string
): TestbedThunkAction => dispatch => {
  dispatch({
    type: TestbedConstants.HIGHLIGHT_TESTBEDLIST_ROW_REQUEST,
    payload: {
      testbedVersionId,
      testbedTestRunId,
    },
  });

  setTimeout(() => {
    dispatch({
      type: TestbedConstants.HIGHLIGHT_TESTBEDLIST_ROW_RESET,
    });
  }, 5000);
};

const uploadTestrunResults = (
  file: File | undefined,
  request: DTO.AddTestRunUploadRequest
): TestbedThunkAction<boolean> => async dispatch => {
  try {
    let hasWarnings = false;
    dispatch({
      type: TestbedConstants.UPLOAD_TESTRUNRESULT_START,
    });
    const response = await TestbedService.uploadTestrunResults(
      file,
      request,
      uploadProgress => {
        if (uploadProgress === 100) {
          return;
        }
        // 5% for server processing time
        dispatch({
          type: TestbedConstants.UPLOAD_TESTRUNRESULT_PROGRESS,
          payload: {
            uploadProgress: Math.max(5, uploadProgress - 5),
          },
        });
      },
      xhrRef => {
        dispatch({
          type: TestbedConstants.UPLOAD_TESTRUNRESULT_XHR_REF,
          payload: { xhrRef },
        });
      }
    );
    const { status, payload } = response;
    if (status !== 200 || payload.status !== 'Success') {
      throw new ApiError(payload);
    }

    if (payload.data?.errors?.length) {
      dispatch({
        type: TestbedConstants.UPLOAD_TESTRUNRESULT_ERROR,
        payload: { error: payload.data?.errors[0] ?? '' },
      });
      return false;
    }

    if (payload.data?.warnings?.length) {
      hasWarnings = true;
    }

    dispatch({
      type: TestbedConstants.UPLOAD_TESTRUNRESULT_SUCCESS,
      payload: {
        uploadTestrunResult: payload.data,
        hasWarnings,
      },
    });

    return true;
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));
    dispatch({
      type: TestbedConstants.UPLOAD_TESTRUNRESULT_ERROR,
      payload: {
        error: msg,
      },
    });
    return false;
  }
};

const updateTestrunResults = (
  request: DTO.UpdateTestrunResultsRequest
): TestbedThunkAction<boolean> => async dispatch => {
  try {
    dispatch({
      type: TestbedConstants.UPDATE_TESTRUN_RESULT_REQUEST,
    });

    const { payload, status } = await TestbedService.updateTestrunResults(
      request
    );

    if (status !== 200 || payload.status === 'Error') {
      throw new ApiError(payload);
    }

    await dispatch(
      AlertActions.success('TestingCenter.newtestresult.upload.success.message')
    );

    dispatch({
      type: TestbedConstants.UPDATE_TESTRUN_RESULT_SUCCESS,
    });

    return true;
  } catch (error) {
    await dispatch(AlertActions.error(error));
    dispatch({
      type: TestbedConstants.UPDATE_TESTRUN_RESULT_FAILURE,
    });
    return false;
  }
};

const resetUploadTestrun = () => ({
  type: TestbedConstants.UPLOAD_TESTRUNRESULT_RESET,
});

const getCompareResult = (
  sourceTestRunId: string,
  targetTestRunId: string
): TestbedThunkAction => async dispatch => {
  try {
    dispatch({
      type: TestbedConstants.GET_TEST_COMPARE_RESULT_REQUEST,
    });

    const { payload, status } = await TestbedService.getCompareResult(
      sourceTestRunId,
      targetTestRunId
    );

    if (status !== 200 || payload.status === 'Error') {
      throw new ApiError(payload);
    }

    dispatch({
      type: TestbedConstants.GET_TEST_COMPARE_RESULT_SUCCESS,
      payload: {
        testCompareResult: payload.data,
      },
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: TestbedConstants.GET_TEST_COMPARE_RESULT_FAILURE,
      payload: { error: msg },
    });
  }
};

const queueCompareRun = (
  sourceTestRunId: string,
  targetTestRunId: string
): TestbedThunkAction<boolean> => async dispatch => {
  try {
    dispatch({
      type: TestbedConstants.QUEUE_COMPARE_RUN_REQUEST,
    });

    const { payload, status } = await TestbedService.queueCompareRun(
      sourceTestRunId,
      targetTestRunId
    );

    if (status !== 200 || payload.status === 'Error') {
      throw new ApiError(payload);
    }

    dispatch({
      type: TestbedConstants.QUEUE_COMPARE_RUN_SUCCESS,
      payload: {
        taskQueueId: payload.data.taskQueueId,
        isExists: payload.data.isExists,
      },
    });
    dispatch(BackgroundJobsActions.getAll());
    return payload.data.isExists;
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: TestbedConstants.QUEUE_COMPARE_RUN_FAILURE,
      payload: { error: msg },
    });
    return false;
  }
};

const getCompareStatus = (
  taskQueueId: string
): TestbedThunkAction => async dispatch => {
  try {
    dispatch({
      type: TestbedConstants.GET_TEST_COMPARE_STATUS_REQUEST,
    });

    const { payload, status } = await TestbedService.getCompareStatus(
      taskQueueId
    );

    if (status !== 200 || payload.status === 'Error') {
      throw new ApiError(payload);
    }

    dispatch({
      type: TestbedConstants.GET_TEST_COMPARE_STATUS_SUCCESS,
      payload: {
        taskQueueProgress: payload.data,
      },
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: TestbedConstants.GET_TEST_COMPARE_STATUS_FAILURE,
      payload: { error: msg },
    });
  }
};

const getTestrunById = (
  testrunId: string
): TestbedThunkAction => async dispatch => {
  try {
    dispatch({
      type: TestbedConstants.GET_TESTRUN_REQUEST,
    });

    const { payload, status } = await TestbedService.getTestrunById(testrunId);

    if (status !== 200 || payload.status === 'Error') {
      throw new ApiError(payload);
    }

    dispatch({
      type: TestbedConstants.GET_TESTRUN_SUCCESS,
      payload: {
        testrun: payload.response_data,
      },
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: TestbedConstants.GET_TESTRUN_FAILURE,
      payload: { error: msg },
    });
  }
};

const openExistingTestRun = (
  testbedQueueId: string
): TestbedThunkAction => async dispatch => {
  dispatch({
    type: TestbedConstants.OPEN_EXISTING_TESTRUN,
    payload: { testbedQueueId },
  });
};

const scheduleCompareTest = (
  selectedTestruns: object[]
): TestbedThunkAction => async dispatch => {
  dispatch({
    type: TestbedConstants.SCHEDULE_COMPARE_TEST,
    payload: { selectedTestruns },
  });
};

const checkTempDocFailureError = (
  errorCode: string,
  type: string,
  getState: () => STATES.AppState
): void => {
  if (!isCustomErrorCode(errorCode)) {
    return;
  }
  const {
    language: { intl },
  } = getState();

  const errMsg = intl.formatMessage(
    { id: `TestingCenter.${type}.download.Fail.Generic` },
    {
      errorCode,
    }
  );
  throw new ApiError({
    message: errMsg,
  });
};

export const TestbedActions = {
  generateTemplate,
  getTestbeds,
  getTestbedQueue,
  getTestbedResult,
  saveTestbedQueue,
  removeTestbed,
  uploadTestBed,
  resetUploadTestBed,
  generateTestCompare,
  updateTestbed,
  queueGenerateTestCases,
  getGenerateTestcasesStatus,
  resetTaskQueue,
  resetTestbedRun,
  downloadTestBedTestCases,
  cancelTestbedQueue,
  generateTestbedResult,
  resetTestBedTemplate,
  publishTestbed,
  cancelDraftTestbed,
  isTestbedExists,
  favouriteTestbed,
  getTestbedById,
  getTestRuns,
  deleteTestRun,
  uploadTestbedTestcases,
  resetUpdateTestbedTestcases,
  publishTestbedTestcases,
  resetTestBedQueueReset,
  isTestRunNameExists,
  resetTestBedHighlight,
  highlightTestbedRow,
  uploadTestrunResults,
  resetUploadTestrun,
  updateTestrunResults,
  getCompareResult,
  queueCompareRun,
  getCompareStatus,
  resetFileUrls,
  resetTaskQueueId,
  getTestrunById,
  openExistingTestRun,
  scheduleCompareTest,
};
