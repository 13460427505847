import { InsightsConstants } from '../constants';
import { ApiError } from '../helpers';
import { EngineService, InsightsService, ProductService } from '../services';
import { AlertActions } from './alert.actions';
import { InsightsThunkAction } from './types';

const getCounterDefinitions = (): InsightsThunkAction => async dispatch => {
  dispatch({ type: InsightsConstants.GET_COUNTER_DEFINITIONS_REQUEST });

  try {
    const { payload } = await InsightsService.getCounterDefinitions();
    if (payload.status !== 'Success') {
      throw new ApiError(payload);
    }

    const { data } = payload;
    dispatch({
      type: InsightsConstants.GET_COUNTER_DEFINITIONS_SUCCESS,
      payload: {
        list: data,
      },
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: InsightsConstants.GET_COUNTER_DEFINITIONS_FAILURE,
      payload: { error: msg },
    });
  }
};

const getAggregatedData = (
  request: DTO.InsightFilter
): InsightsThunkAction => async dispatch => {
  dispatch({ type: InsightsConstants.GET_AGGREGATED_DATA_REQUEST });

  try {
    const { payload } = await InsightsService.getAggregatedData(request);
    if (payload.status !== 'Success') {
      throw new ApiError(payload);
    }

    const { data } = payload;
    dispatch({
      type: InsightsConstants.GET_AGGREGATED_DATA_SUCCESS,
      payload: {
        list: data,
        startDate: request.startDate,
        endDate: request.endDate,
      },
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: InsightsConstants.GET_AGGREGATED_DATA_FAILURE,
      payload: { error: msg },
    });
  }
};

const getTotalByCounter = (
  request: DTO.InsightFilter
): InsightsThunkAction => async dispatch => {
  dispatch({ type: InsightsConstants.GET_TOTAL_BY_COUNTER_REQUEST });

  try {
    const { payload } = await InsightsService.getTotalByCounterName(request);
    if (payload.status !== 'Success') {
      throw new ApiError(payload);
    }

    const { data } = payload;
    dispatch({
      type: InsightsConstants.GET_TOTAL_BY_COUNTER_SUCCESS,
      payload: {
        list: data,
      },
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: InsightsConstants.GET_TOTAL_BY_COUNTER_FAILURE,
      payload: { error: msg },
    });
  }
};

const getNextMeasures = (
  request: DTO.InsightNextMeasure
): InsightsThunkAction => async dispatch => {
  dispatch({ type: InsightsConstants.GET_NEXT_MEASURES_REQUEST });

  try {
    const { payload } = await InsightsService.getNextMeasures(request);
    if (payload.status !== 'Success') {
      throw new ApiError(payload);
    }

    const { data } = payload;
    dispatch({
      type: InsightsConstants.GET_NEXT_MEASURES_SUCCESS,
      payload: {
        list: data,
      },
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: InsightsConstants.GET_NEXT_MEASURES_FAILURE,
      payload: { error: msg },
    });
  }
};

const getTopMetrics = (
  retrieveClusterCounters: boolean,
  tenant: string
): InsightsThunkAction => async dispatch => {
  dispatch({ type: InsightsConstants.GET_TOP_METRICS_REQUEST });

  try {
    const { payload } = await InsightsService.getTopMetrics(
      retrieveClusterCounters,
      tenant
    );

    if (payload.status !== 'Success') {
      throw new ApiError(payload);
    }

    const { data } = payload;
    dispatch({
      type: InsightsConstants.GET_TOP_METRICS_SUCCESS,
      payload: {
        topMetrics: data,
      },
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: InsightsConstants.GET_TOP_METRICS_FAILURE,
      payload: { error: msg },
    });
  }
};

const getTenants = (): InsightsThunkAction => async dispatch => {
  dispatch({ type: InsightsConstants.GET_TENANTS_REQUEST });

  try {
    const { payload } = await InsightsService.getTenants();
    if (payload.status !== 'Success') {
      throw new ApiError(payload);
    }

    const { response_data } = payload;
    dispatch({
      type: InsightsConstants.GET_TENANTS_SUCCESS,
      payload: {
        tenantList: response_data,
      },
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: InsightsConstants.GET_TENANTS_FAILURE,
      payload: { error: msg },
    });
  }
};

const incrementMenuCounter = (
  menuName: string
): InsightsThunkAction => async dispatch => {
  dispatch({ type: InsightsConstants.INCREMENT_COUNTER_REQUEST });

  try {
    const { payload } = await InsightsService.incrementMenuCounter(menuName);
    if (payload.status !== 'Success') {
      throw new ApiError(payload);
    }

    dispatch({
      type: InsightsConstants.INCREMENT_COUNTER_SUCCESS,
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: InsightsConstants.INCREMENT_COUNTER_FAILURE,
      payload: { error: msg },
    });
  }
};

const incrementPageCounter = (
  pageName: string
): InsightsThunkAction => async dispatch => {
  dispatch({ type: InsightsConstants.INCREMENT_COUNTER_REQUEST });

  try {
    const { payload } = await InsightsService.incrementPageCounter(pageName);
    if (payload.status !== 'Success') {
      throw new ApiError(payload);
    }

    dispatch({
      type: InsightsConstants.INCREMENT_COUNTER_SUCCESS,
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: InsightsConstants.INCREMENT_COUNTER_FAILURE,
      payload: { error: msg },
    });
  }
};

const getProducts = (): InsightsThunkAction => async dispatch => {
  try {
    const { payload: cp, status: cs } = await ProductService.getProducts({
      page: 1,
      pageSize: 3,
      shouldFetchActiveServicesCount: false,
    });
    if (cs !== 200 || cp.status === 'Error') {
      throw new ApiError(cp);
    }
    dispatch({
      type: InsightsConstants.GET_INSIGHT_FOLDER_ENGINE_REQUEST,
      payload: null,
    });
    const { payload, status } = await ProductService.getProducts({
      page: 1,
      pageSize: Math.max(cp.count, 3),
      shouldFetchActiveServicesCount: false,
    });

    if (status !== 200 || payload.status === 'Error') {
      throw new ApiError(cp);
    }

    payload.data.sort((a, b) => a.name.localeCompare(b.name));

    dispatch({
      type: InsightsConstants.GET_INSIGHT_FOLDER_ENGINE_SUCCESS,
      payload: {
        list: payload.data.map(i => {
          return {
            key: i.name,
            value: i.name,
            title: `${i.name}`,
          };
        }),
        type: 'product',
      },
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));
    dispatch({
      type: InsightsConstants.GET_INSIGHT_FOLDER_ENGINE_FAILURE,
      payload: { error: msg },
    });
  }
};

const getProductEngines = (
  productName: string
): InsightsThunkAction => async dispatch => {
  try {
    dispatch({
      type: InsightsConstants.GET_INSIGHT_FOLDER_ENGINE_REQUEST,
      payload: null,
    });
    const { payload, status } = await EngineService.getProductEngines(
      productName,
      {
        page: 1,
        pageSize: 1000,
      }
    );
    if (status !== 200 || payload.status === 'Error') {
      throw new ApiError(payload);
    }

    payload.data.sort((a, b) => a.serviceName.localeCompare(b.serviceName));

    dispatch({
      type: InsightsConstants.GET_INSIGHT_FOLDER_ENGINE_SUCCESS,
      payload: {
        list: payload.data.map(i => i.serviceName),
        type: 'engine',
      },
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));
    dispatch({
      type: InsightsConstants.GET_INSIGHT_FOLDER_ENGINE_FAILURE,
      payload: { error: msg },
    });
  }
};

const incrementFeatureCounter = (
  data: DTO.NodegenApiRequestEntity<DTO.IncrementFolderServiceCounter>
): InsightsThunkAction => async dispatch => {
  dispatch({ type: InsightsConstants.INCREMENT_COUNTER_REQUEST });

  try {
    const { payload } = await InsightsService.incrementFeatureCounter(data);
    if (payload.status !== 'Success') {
      throw new ApiError(payload);
    }

    dispatch({
      type: InsightsConstants.INCREMENT_COUNTER_SUCCESS,
    });
  } catch (error) {
    const msg = await dispatch(AlertActions.error(error));

    dispatch({
      type: InsightsConstants.INCREMENT_COUNTER_FAILURE,
      payload: { error: msg },
    });
  }
};

export const InsightsActions = {
  getCounterDefinitions,
  getAggregatedData,
  getTotalByCounter,
  getNextMeasures,
  getTopMetrics,
  incrementMenuCounter,
  incrementPageCounter,
  getProducts,
  getProductEngines,
  getTenants,
  incrementFeatureCounter,
};
