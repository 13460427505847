import { useLocation } from 'react-router-dom';

const useApiParams = () => {
  const pathname = useLocation().pathname;
  const isApiTester = pathname.endsWith('/apiTester/test');
  const isApiReview = pathname.endsWith('/apiTester/integrate');
  const isApiDocs = pathname.endsWith('/apiTester/docs');

  return { isApiTester, isApiReview, isApiDocs };
};

export { useApiParams };
