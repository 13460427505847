import { Progress, Tooltip, Typography } from 'antd';
import moment from 'moment';
import React, { FC, memo, NamedExoticComponent, useMemo } from 'react';
import { FormattedHTMLMessage, FormattedMessage, useIntl } from 'react-intl';
import {
  DeleteFilled,
  CloseCircleFilled,
  WarningFilled,
  CheckCircleFilled,
  ClockCircleFilled,
  StopFilled,
} from '@ant-design/icons';
import {
  formatToLongDateTime,
  getTimeDiff,
  isCustomErrorCode,
} from '../../helpers';
import { BackgroundJobsConstants } from '../../constants';
import { StyledJobItemContainer } from '../index.styles';

const { Paragraph } = Typography;

type BackgroundJobItemProps = {
  job: DTO.BackgroundJob;
  onCancel: (job: DTO.BackgroundJob) => void;
  onDelete: (job: DTO.BackgroundJob) => void;
};

const GetIcon: FC<{
  status;
}> = ({ status }) => {
  switch (status) {
    case 'Pending':
      return <ClockCircleFilled className="icon-pending" />;
    case 'Fail':
    case 'Timeout':
      return <WarningFilled className="icon-failed" />;
    case 'Success': {
      return <CheckCircleFilled className="icon-success" />;
    }
    case 'Cancelled': {
      return <StopFilled className="icon-cancelled" />;
    }
    default: {
      return <></>;
    }
  }
};

const progressStatus = status => {
  switch (status) {
    case 'Fail':
    case 'Timeout': {
      return 'exception';
    }
    case 'Cancelled': {
      return 'active';
    }
    case 'Success': {
      return 'success';
    }
    default: {
      return 'normal';
    }
  }
};

type FormatDetail = {
  name: string;
  serviceName: string;
  testbedName: string;
  revision: string;
};

const BackgroundJobItem: NamedExoticComponent<BackgroundJobItemProps> = memo(
  ({ job, onCancel, onDelete }) => {
    const intl = useIntl();
    const {
      progress,
      id,
      status,
      type,
      completedAt,
      createdAt,
      error,
      data,
    } = job;

    const formatDetail = (data): FormatDetail => {
      return {
        name: data.Name || data.Product || data.folder_name || '',
        serviceName: data.ServiceName || data.Engine || data.EngineName || '',
        testbedName: data?.TestBedName || data?.TestbedName || '',
        revision: data?.Revision || '',
      };
    };

    const desc = useMemo(() => {
      const diff = moment.duration(moment(completedAt).diff(moment(createdAt)));

      if (error === BackgroundJobsConstants.FILE_SIZE_LIMIT_EXCEEDED) {
        return intl.formatMessage({
          id: error,
        });
      }
      if (status === 'Fail' && isCustomErrorCode(error)) {
        return (
          <FormattedHTMLMessage
            tagName="span"
            id={`BackgroundJobItem.${status}.${type}.Generic`}
            values={{
              progress,
              errorCode: error,
              completedAt: formatToLongDateTime(completedAt),
              additionError: '',
            }}
          />
        );
      }

      let additionError = '';
      if (
        status === 'Fail' &&
        type === 'UploadFolder' &&
        typeof error === 'string'
      ) {
        additionError += ` (${intl.formatMessage({ id: error })})`;
      }

      return (
        <FormattedHTMLMessage
          tagName="span"
          id={
            status === 'Timeout'
              ? 'BackgroundJobItem.Timeout'
              : `BackgroundJobItem.${status}.${type}`
          }
          values={{
            progress,
            duration: `${getTimeDiff(diff)}`,
            completedAt: formatToLongDateTime(completedAt),
            startedAt: formatToLongDateTime(createdAt),
            additionError,
          }}
        />
      );
    }, [status, completedAt, progress, createdAt, type, error, intl]);

    return (
      <StyledJobItemContainer data-testid="job-item">
        <div className="heading">
          <Paragraph
            ellipsis={{
              rows: 2,
              tooltip: intl.formatMessage(
                {
                  id: `BackgroundJobsPopover.popup.desciption.${type}`,
                },
                {
                  productName: formatDetail(data).name,
                  service: formatDetail(data).serviceName,
                  testbedName: formatDetail(data).testbedName,
                  revision: formatDetail(data).revision,
                }
              ),
            }}
          >
            {intl.formatMessage(
              {
                id: `BackgroundJobsPopover.popup.desciption.${type}`,
              },
              {
                productName: formatDetail(data).name,
                service: formatDetail(data).serviceName,
                testbedName: formatDetail(data).testbedName,
                revision: formatDetail(data).revision,
              }
            )}
          </Paragraph>
        </div>
        <div className="progress-container">
          <Progress
            percent={
              status === 'Success' ||
              status === 'Fail' ||
              status === 'Cancelled' ||
              status === 'Timeout'
                ? 100
                : progress
            }
            showInfo={false}
            status={progressStatus(status)}
          />
          <div className="icon-container">
            {status === 'Success' ||
            status === 'Fail' ||
            status === 'Cancelled' ||
            status === 'Timeout' ? (
              <Tooltip
                title={
                  <FormattedMessage id="BackgroundJobsPopover.popup.deletejob.tooltip" />
                }
              >
                <DeleteFilled
                  data-testid={`delete-job-${id}`}
                  onClick={e => {
                    e.stopPropagation();
                    onDelete(job);
                  }}
                />
              </Tooltip>
            ) : (
              <Tooltip
                title={
                  <FormattedMessage id="BackgroundJobsPopover.popup.canceljob.tooltip" />
                }
              >
                <CloseCircleFilled
                  data-testid={`cancel-job-${id}`}
                  onClick={e => {
                    e.stopPropagation();
                    onCancel(job);
                  }}
                />
              </Tooltip>
            )}
          </div>
        </div>
        <div className="description">
          <GetIcon status={status} />
          {desc}
        </div>
      </StyledJobItemContainer>
    );
  }
);

export { BackgroundJobItem };
