import { EngineLogsAction } from '../actions/types';
import { EngineLogsConstant } from '../constants';

export const initialState: STATES.ViewLogsState = {
  isLoading: false,
  isLoadingLogData: false,
  logs: [],
  totalCount: 0,
  pagedRequest: null,
  executionHistoryLog: null,
  isLoadingCount: false,
  totalInputCount: 0,
};

export const engineViewLogs = (
  state = initialState,
  action: EngineLogsAction
): STATES.ViewLogsState => {
  switch (action.type) {
    case EngineLogsConstant.GET_ENGINE_VIEW_LOGS: {
      const { request } = action.payload;

      return {
        ...state,
        isLoading: true,
        pagedRequest: request,
      };
    }
    case EngineLogsConstant.GET_ENGINE_VIEW_LOGS_SUCCESS: {
      const { logs } = action.payload;
      return {
        ...state,
        isLoading: false,
        logs,
      };
    }
    case EngineLogsConstant.GET_ENGINE_VIEW_LOGS_COUNT: {
      return {
        ...state,
        isLoadingCount: true,
        totalCount: 0,
      };
    }
    case EngineLogsConstant.GET_ENGINE_VIEW_LOGS_COUNT_SUCCESS: {
      const { total_count } = action.payload;
      return {
        ...state,
        isLoadingCount: false,
        totalCount: total_count,
      };
    }
    case EngineLogsConstant.DOWNLOAD_ENGINE_LOGS_SUCCESS:
    case EngineLogsConstant.DOWNLOAD_ENGINE_LOGS_FAILURE:
    case EngineLogsConstant.GET_ENGINE_VIEW_LOGS_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case EngineLogsConstant.GET_ENGINE_VIEW_LOGS_COUNT_FAILURE: {
      return {
        ...state,
        isLoadingCount: false,
      };
    }
    case EngineLogsConstant.GET_LOG_BY_HISTORYID_FAILURE: {
      return {
        ...state,
        isLoadingLogData: false,
      };
    }
    case EngineLogsConstant.DOWNLOAD_ENGINE_LOGS_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case EngineLogsConstant.ENGINE_LOGS_RESET: {
      return {
        ...initialState,
      };
    }
    case EngineLogsConstant.GET_LOG_BY_HISTORYID_REQUEST: {
      return {
        ...state,
        isLoadingLogData: true,
      };
    }
    case EngineLogsConstant.GET_LOG_BY_HISTORYID_SUCCESS: {
      return {
        ...state,
        isLoadingLogData: false,
        executionHistoryLog: action.payload,
      };
    }
    case EngineLogsConstant.GET_TOTAL_INPUTS_BY_EXECUTIONHISTORYID_REQUEST: {
      return {
        ...state,
      };
    }
    case EngineLogsConstant.GET_TOTAL_INPUTS_BY_EXECUTIONHISTORYID_SUCCESS: {
      return {
        ...state,
        totalInputCount: action?.payload?.response_data?.total_input_count ?? 0,
      };
    }
    case EngineLogsConstant.GET_TOTAL_INPUTS_BY_EXECUTIONHISTORYID_FAILURE: {
      return {
        ...state,
      };
    }
    case EngineLogsConstant.RESET_TOTAL_INPUTS: {
      return {
        ...state,
        totalInputCount: 0,
      };
    }
    default:
      return state;
  }
};
