import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { CustomModal } from '../CustomModal';
import { JsonViewer } from '../JsonViewer';
import { ScrollContainer } from '../ScrollContainer';

interface MetadataModalProps {
  title: string;
  metadata: object | null;
  onClose: () => void;
}

export const StyledCodeContainer = styled.div`
  && {
    max-height: calc(100vh - 280px);
    overflow: auto;
    background-color: ${({ theme }) => theme.color.dark};
    ::-webkit-scrollbar {
      background-color: ${({ theme }) => theme.color.dark};
      width: 8px;
      height: 8px;
    }
    ::-webkit-scrollbar-thumb {
      background: ${({ theme }) => theme.color.dark20};
      border-radius: 20px;
      background-clip: content-box;
    }
  }
`;

const MetadataModal: FC<MetadataModalProps> = ({
  onClose,
  title,
  metadata,
}) => {
  return (
    <>
      <CustomModal
        visible={!!metadata}
        closable
        title={<FormattedMessage id={title} />}
        onCancel={onClose}
        getContainer={document.body}
        content={
          <ScrollContainer flexFill>
            <StyledCodeContainer>
              {metadata && <JsonViewer className="p-12" src={metadata} />}
            </StyledCodeContainer>
          </ScrollContainer>
        }
      />
    </>
  );
};

export { MetadataModal };
