export const TEST_BED_QUEUE_INTERVAL_TIME = 5000;
export const TEST_BED_PAGE_SIZE = 10;

export const TESTBED_RESULT_SOURCE = {
  USER_UPLOAD: 'User Upload',
  SPARK: 'Spark',
  SPARK_ASSISTANT: 'Spark Assistant',
};

export const GENERATED_TEST_TEMPLATE_REQUEST =
  'GENERATED_TEST_TEMPLATE_REQUEST';
export const GENERATED_TEST_TEMPLATE_SUCCESS =
  'GENERATED_TEST_TEMPLATE_SUCCESS';
export const GENERATED_TEST_TEMPLATE_FAILURE =
  'GENERATED_TEST_TEMPLATE_FAILURE';

export const GET_TEST_BEDS_REQUEST = 'GET_TEST_BEDS_REQUEST';
export const GET_TEST_BEDS_SUCCESS = 'GET_TEST_BEDS_SUCCESS';
export const GET_TEST_BEDS_FAILURE = 'GET_TEST_BEDS_FAILURE';
export const GET_TEST_BED_QUEUE_RESET = 'GET_TEST_BED_QUEUE_RESET';

export const GET_TEST_BED_QUEUE_REQUEST = 'GET_TEST_BED_QUEUE_REQUEST';
export const GET_TEST_BED_QUEUE_SUCCESS = 'GET_TEST_BED_QUEUE_SUCCESS';
export const GET_TEST_BED_QUEUE_FAILURE = 'GET_TEST_BED_QUEUE_FAILURE';

export const SAVE_TEST_BED_QUEUE_REQUEST = 'SAVE_TEST_BED_QUEUE_REQUEST';
export const SAVE_TEST_BED_QUEUE_SUCCESS = 'SAVE_TEST_BED_QUEUE_SUCCESS';
export const SAVE_TEST_BED_QUEUE_FAILURE = 'SAVE_TEST_BED_QUEUE_FAILURE';

export const GET_TEST_BED_RESULT_REQUEST = 'GET_TEST_BED_RESULT_REQUEST';
export const GET_TEST_BED_RESULT_SUCCESS = 'GET_TEST_BED_RESULT_SUCCESS';
export const GET_TEST_BED_RESULT_FAILURE = 'GET_TEST_BED_RESULT_FAILURE';

export const GENERATED_TESTBED_RESULT_REQUEST =
  'GENERATED_TESTBED_RESULT_REQUEST';
export const GENERATED_TESTBED_RESULT_SUCCESS =
  'GENERATED_TESTBED_RESULT_SUCCESS';
export const GENERATED_TESTBED_RESULT_FAILURE =
  'GENERATED_TESTBED_RESULT_FAILURE';

export const UPDATE_TEST_BED_REQUEST = 'UPDATE_TEST_BED_REQUEST';
export const UPDATE_TEST_BED_SUCCESS = 'UPDATE_TEST_BED_SUCCESS';
export const UPDATE_TEST_BED_FAILURE = 'UPDATE_TEST_BED_FAILURE';

export const REMOVE_TEST_BED_REQUEST = 'REMOVE_TEST_BED_REQUEST';
export const REMOVE_TEST_BED_SUCCESS = 'REMOVE_TEST_BED_SUCCESS';
export const REMOVE_TEST_BED_FAILURE = 'REMOVE_TEST_BED_FAILURE';

export const TEST_BED_UPLOAD_START = 'TEST_BED_UPLOAD_START';
export const TEST_BED_UPLOAD_PROGRESS = 'TEST_BED_UPLOAD_PROGRESS';
export const TEST_BED_UPLOAD_XHR_REF = 'TEST_BED_UPLOAD_XHR_REF';
export const TEST_BED_UPLOAD_ERROR = 'TEST_BED_UPLOAD_ERROR';
export const TEST_BED_UPLOAD_SUCCESS = 'TEST_BED_UPLOAD_SUCCESS';
export const TEST_BED_UPLOAD_RESET = 'TEST_BED_UPLOAD_RESET';
export const TEST_BED_LIST_HIGHLIGHT_RESET = 'TEST_BED_LIST_HIGHLIGHT_RESET';

export const GENERATED_TEST_COMPARE_REQUEST = 'GENERATED_TEST_COMPARE_REQUEST';
export const GENERATED_TEST_COMPARE_SUCCESS = 'GENERATED_TEST_COMPARE_SUCCESS';
export const GENERATED_TEST_COMPARE_FAILURE = 'GENERATED_TEST_COMPARE_FAILURE';

export const QUEUE_GENERATE_TESTCASES_REQUEST =
  'QUEUE_GENERATE_TESTCASES_REQUEST';
export const QUEUE_GENERATE_TESTCASES_SUCCESS =
  'QUEUE_GENERATE_TESTCASES_SUCCESS';
export const QUEUE_GENERATE_TESTCASES_FAILURE =
  'QUEUE_GENERATE_TESTCASES_FAILURE';

export const GET_GENERATE_TESTCASE_STATUS_REQUEST =
  'GET_GENERATE_TESTCASE_REQUEST';
export const GET_GENERATE_TESTCASE_STATUS_SUCCESS =
  'GET_GENERATE_TESTCASE_STATUS_SUCCESS';
export const GET_GENERATE_TESTCASE_STATUS_FAILURE =
  'GET_GENERATE_TESTCASE_STATUS_FAILURE';

export const RESET_TASK_QUEUE = 'RESET_TASK_QUEUE';
export const RESET_TASK_QUEUE_ID = 'RESET_TASK_QUEUE_ID';
export const RESET_FILE_URLS = 'RESET_FILE_URLS';
export const RESET_TEST_BED_RUN = 'RESET_TEST_BED_RUN';

export const DOWNLOAD_TESTBED_TESTCASES_REQUEST =
  'DOWNLOAD_TESTBED_TESTCASES_REQUEST';
export const DOWNLOAD_TESTBED_TESTCASES_SUCCESS =
  'DOWNLOAD_TESTBED_TESTCASES_SUCCESS';
export const DOWNLOAD_TESTBED_TESTCASES_FAILURE =
  'DOWNLOAD_TESTBED_TESTCASES_FAILURE';
export const BG_DOWNLOAD_TESTBED_TESTCASES_SUCCESS =
  'BG_DOWNLOAD_TESTBED_TESTCASES_SUCCESS';

export const CANCEL_TESTBED_QUEUE_REQUEST = 'CANCEL_TESTBED_QUEUE_REQUEST';
export const CANCEL_TESTBED_QUEUE_SUCCESS = 'CANCEL_TESTBED_QUEUE_SUCCESS';
export const CANCEL_TESTBED_QUEUE_FAILURE = 'CANCEL_TESTBED_QUEUE_FAILURE';

export const RESET_TESTBED_TEMPLATE = 'RESET_TESTBED_TEMPLATE';

export const PUBLISH_TESTBED_REQUEST = 'PUBLISH_TESTBED_REQUEST';
export const PUBLISH_TESTBED_SUCCESS = 'PUBLISH_TESTBED_SUCCESS';
export const PUBLISH_TESTBED_FAILURE = 'PUBLISH_TESTBED_FAILURE';

export const CANCEL_DRAFT_TESTBED_REQUEST = 'CANCEL_DRAFT_TESTBED_REQUEST';
export const CANCEL_DRAFT_TESTBED_SUCCESS = 'CANCEL_DRAFT_TESTBED_SUCCESS';
export const CANCEL_DRAFT_TESTBED_FAILURE = 'CANCEL_DRAFT_TESTBED_FAILURE';

export const CHECK_TESTBED_NAME_REQUEST = 'CHECK_TESTBED_NAME_REQUEST';
export const CHECK_TESTBED_NAME_SUCCESS = 'CHECK_TESTBED_NAME_SUCCESS';
export const CHECK_TESTBED_NAME_FAILURE = 'CHECK_TESTBED_NAME_FAILURE';

export const FAVOURITE_TESTBED_REQUEST = 'FAVOURITE_TESTBED_REQUEST';
export const FAVOURITE_TESTBED_SUCCESS = 'FAVOURITE_TESTBED_SUCCESS';
export const FAVOURITE_TESTBED_FAILURE = 'FAVOURITE_TESTBED_FAILURE';

export const GET_TESTBEDID_REQUEST = 'GET_TESTBEDID_REQUEST';
export const GET_TESTBEDID_SUCCESS = 'GET_TESTBEDID_SUCCESS';
export const GET_TESTBEDID_FAILURE = 'GET_TESTBEDID_FAILURE';

export const GET_TESTRUNS_REQUEST = 'GET_TESTRUNS_REQUEST';
export const GET_TESTRUNS_SUCCESS = 'GET_TESTRUNS_SUCCESS';
export const GET_TESTRUNS_FAILURE = 'GET_TESTRUNS_FAILURE';

export const DELETE_TESTRUN_REQUEST = 'DELETE_TESTRUN_REQUEST';
export const DELETE_TESTRUN_SUCCESS = 'DELETE_TESTRUN_SUCCESS';
export const DELETE_TESTRUN_FAILURE = 'DELETE_TESTRUN_FAILURE';

export const UPDATE_TESTBED_START = 'UPDATE_TESTBED_START';
export const UPDATE_TESTBED_XHR_REF = 'UPDATE_TESTBED_XHR_REF';
export const UPDATE_TESTBED_PROGRESS = 'UPDATE_TESTBED_PROGRESS';
export const UPDATE_TESTBED_SUCCESS = 'UPDATE_TESTBED_SUCCESS';
export const UPDATE_TESTBED_ERROR = 'UPDATE_TESTBED_ERROR';
export const UPDATE_TESTBED_RESET = 'UPDATE_TESTBED_RESET';

export const PUBLISH_TESTBED_TESTCASES_REQUEST =
  'PUBLISH_TESTBED_TESTCASES_REQUEST';
export const PUBLISH_TESTBED_TESTCASES_SUCCESS =
  'PUBLISH_TESTBED_TESTCASES_SUCCESS';
export const PUBLISH_TESTBED_TESTCASES_FAILURE =
  'PUBLISH_TESTBED_TESTCASES_FAILURE';
export const PUBLISH_TESTBED_TESTCASES_RESET =
  'PUBLISH_TESTBED_TESTCASES_RESET';
export const CHECK_TESTRUN_NAME_REQUEST = 'CHECK_TESTRUN_NAME_REQUEST';
export const CHECK_TESTRUN_NAME_SUCCESS = 'CHECK_TESTRUN_NAME_SUCCESS';
export const CHECK_TESTRUN_NAME_FAILURE = 'CHECK_TESTRUN_NAME_FAILURE';

export const HIGHLIGHT_TESTBEDLIST_ROW_REQUEST =
  'HIGHLIGHT_TESTBEDLIST_ROW_REQUEST';
export const HIGHLIGHT_TESTBEDLIST_ROW_RESET =
  'HIGHLIGHT_TESTBEDLIST_ROW_RESET';

export const UPLOAD_TESTRUNRESULT_START = 'UPLOAD_TESTRUNRESULT_START';
export const UPLOAD_TESTRUNRESULT_XHR_REF = 'UPLOAD_TESTRUNRESULT_XHR_REF';
export const UPLOAD_TESTRUNRESULT_PROGRESS = 'UPLOAD_TESTRUNRESULT_PROGRESS';
export const UPLOAD_TESTRUNRESULT_SUCCESS = 'UPLOAD_TESTRUNRESULT_SUCCESS';
export const UPLOAD_TESTRUNRESULT_ERROR = 'UPLOAD_TESTRUNRESULT_ERROR';
export const UPLOAD_TESTRUNRESULT_RESET = 'UPLOAD_TESTRUNRESULT_RESET';

export const UPDATE_TESTRUN_RESULT_REQUEST = 'UPDATE_TESTRUN_RESULT_REQUEST';
export const UPDATE_TESTRUN_RESULT_SUCCESS = 'UPDATE_TESTRUN_RESULT_SUCCESS';
export const UPDATE_TESTRUN_RESULT_FAILURE = 'UPDATE_TESTRUN_RESULT_FAILURE';

export const GET_TEST_COMPARE_RESULT_REQUEST =
  'GET_TEST_COMPARE_RESULT_REQUEST';
export const GET_TEST_COMPARE_RESULT_SUCCESS =
  'GET_TEST_COMPARE_RESULT_SUCCESS';
export const GET_TEST_COMPARE_RESULT_FAILURE =
  'GET_TEST_COMPARE_RESULT_FAILURE';

export const SCHEDULE_COMPARE_TEST = 'SCHEDULE_COMPARE_TEST';

export const QUEUE_COMPARE_RUN_REQUEST = 'QUEUE_COMPARE_RUN_REQUEST';
export const QUEUE_COMPARE_RUN_SUCCESS = 'QUEUE_COMPARE_RUN_SUCCESS';
export const QUEUE_COMPARE_RUN_FAILURE = 'QUEUE_COMPARE_RUN_FAILURE';

export const GET_TEST_COMPARE_STATUS_REQUEST =
  'GET_TEST_COMPARE_STATUS_REQUEST';
export const GET_TEST_COMPARE_STATUS_SUCCESS =
  'GET_TEST_COMPARE_STATUS_SUCCESS';
export const GET_TEST_COMPARE_STATUS_FAILURE =
  'GET_TEST_COMPARE_STATUS_FAILURE';

export const GET_TESTRUN_REQUEST = 'GET_TESTRUN_REQUEST';
export const GET_TESTRUN_SUCCESS = 'GET_TESTRUN_SUCCESS';
export const GET_TESTRUN_FAILURE = 'GET_TESTRUN_FAILURE';

export const OPEN_EXISTING_TESTRUN = 'OPEN_EXISTING_TESTRUN';
export const NEURON_BATCH_RUNNER_TIMEOUT = 'NEURON_BATCH_RUNNER_TIMEOUT';
