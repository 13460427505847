import { ReactComponent as ProductStatusDesign } from './ic_product_st_design.svg';
import { ReactComponent as ProductStatusArchive } from './ic_product_st_archive.svg';
import { ReactComponent as ProductStatusProduction } from './ic_product_st_production.svg';
import { ReactComponent as SideMenuCalculations } from './ic_sidemenu_calculations.svg';
import { ReactComponent as SideMenuProduct } from './ic_sidemenu_product.svg';
import { ReactComponent as SideMenuTesting } from './ic_sidemenu_testing.svg';
import { ReactComponent as SideMenuCatalogue } from './ic_sidemenu_catalogue.svg';
import { ReactComponent as ModalOpen } from './ic_modal_open.svg';
import { ReactComponent as Refresh } from './ic_refresh.svg';
import { ReactComponent as ExcelFile } from './ic_excel.svg';
import { ReactComponent as PdfFile } from './ic_pdf.svg';
import { ReactComponent as WordFile } from './ic_word.svg';
import { ReactComponent as EngineFile } from './ic_engine.svg';
import { ReactComponent as ImageFile } from './ic_image.svg';
import { ReactComponent as TutorialFile } from './ic_tutorial.svg';
import { ReactComponent as HtmlFile } from './ic_html.svg';
import { ReactComponent as PowerPointFile } from './ic_powerpoint.svg';
import { ReactComponent as ProductStatusProductionOutlined } from './ic_status_production_outlined.svg';
import { ReactComponent as ProductStatusArchiveOutlined } from './ic_status_archive_outlined.svg';
import { ReactComponent as ProductStatusDesignOutlined } from './ic_status_design_outlined.svg';
import { ReactComponent as FolderOpen } from './ic_folder_open.svg';
import { ReactComponent as FolderDefault } from './ic_folder_default.svg';
import { ReactComponent as Language } from './ic_language.svg';
import { ReactComponent as Logout } from './ic_logout.svg';
import { ReactComponent as Sync } from './ic_sync.svg';
import { ReactComponent as Edit } from './ic_edit.svg';
import { ReactComponent as Cockpit } from './ic_cockpit.svg';
import { ReactComponent as Alian } from './ic_alian.svg';
import { ReactComponent as LeftArrow } from './left-arrow.svg';
import { ReactComponent as ServiceDocIcon } from './service_doc_icon.svg';
import { ReactComponent as TestingCentreIcon } from './testing_centre_icon.svg';
import { ReactComponent as VersionOverviewIcon } from './version_overview_icon.svg';
import { ReactComponent as ViewLogsIcon } from './view_logs_icon.svg';
import { ReactComponent as BookmarkIcon } from './bookmark_icon.svg';
import { ReactComponent as GridView } from './grid_view.svg';
import { ReactComponent as ListView } from './list_view.svg';
import { ReactComponent as PlusIconCircularFill } from './plus_circular.svg';
import { ReactComponent as PlusIconCircularOutlined } from './plus_circular_outlined.svg';
import { ReactComponent as CloseIcon } from './closeCircle.svg';
import { ReactComponent as InfoCircle } from './ic_info.svg';
import { ReactComponent as CaretDownFilled } from './caret_down_filled.svg';
import { ReactComponent as CheckCircleOutlined } from './check_circle_outlined.svg';
import { ReactComponent as CheckCircleFilled } from './check_circle_filled.svg';
import { ReactComponent as ThreeDotVerticalIcon } from './three_dot_vertical_icon.svg';
import { ReactComponent as CaretUpFilled } from './caret_up_filled.svg';
import { ReactComponent as CaretUpDownFilled } from './caret_up_down.svg';
import { ReactComponent as Pencil } from './ic_pencil.svg';
import { ReactComponent as Star } from './ic_star.svg';
import { ReactComponent as SixDotLine } from './six_dot_icon.svg';
import { ReactComponent as WarningIcon } from './warning_icon.svg';
import { ReactComponent as Warning } from './ic_warning.svg';
import { ReactComponent as FullscreenExpand } from './ic_full_screen_expand.svg';
import { ReactComponent as CurlCommandError } from './ic_curl_command_error.svg';
import { ReactComponent as Link } from './ic_link.svg';
import { ReactComponent as Empty } from './ic_empty.svg';
import { ReactComponent as Expanded } from './ic_expand.svg';
import { ReactComponent as WarmIconRed } from './warning_icon_red.svg';
import { ReactComponent as Reduce } from './ic_reduce.svg';
import { ReactComponent as InfoCircleFill } from './ic_InfoCircle_fill.svg';
import { ReactComponent as Left } from './ic_left.svg';
import { ReactComponent as Right } from './ic_right.svg';
import { ReactComponent as ViewEye } from './ic_view_eye.svg';
import { ReactComponent as UpCaret } from './ic_table_up_caret.svg';
import { ReactComponent as DownCaret } from './ic_table_down_caret.svg';
import { ReactComponent as FontSize } from './ic_fontsize.svg';
import { ReactComponent as Bold } from './ic_bold.svg';
import { ReactComponent as Italic } from './ic_italic.svg';
import { ReactComponent as Underline } from './ic_underline.svg';
import { ReactComponent as FontColor } from './ic_fontcolor.svg';
import { ReactComponent as AlignLeft } from './ic_align_left.svg';
import { ReactComponent as AlignCenter } from './ic_align_center.svg';
import { ReactComponent as AlignRight } from './ic_align_right.svg';
import { ReactComponent as Code } from './ic_code.svg';
import { ReactComponent as LinkUrl } from './ic_link_url.svg';
import { ReactComponent as UnLinkUrl } from './ic_unlink_url.svg';
import { ReactComponent as OrderedList } from './ic_orderedlist.svg';
import { ReactComponent as UnOrderedList } from './ic_unorderedlist.svg';
import { ReactComponent as Undo } from './ic_undo.svg';
import { ReactComponent as Error } from './ic_error.svg';
import { ReactComponent as DeleteIcon } from './delete.svg';
import { ReactComponent as CautionIcon } from './ic_caution.svg';
import { ReactComponent as DefaultCoverImage } from './default_coverimage.svg';
import { ReactComponent as Download } from './ic_download.svg';
import { ReactComponent as TableIcon } from './table_icon.svg';
import { ReactComponent as CaretRightOutlined } from './caret_right_outlined.svg';
import { ReactComponent as SupportIcon } from './icon_support.svg';
import { ReactComponent as InfoCircleOutlined } from './ic_info_circle_outlined.svg';
import { ReactComponent as UserGuide } from './ic_question_circle.svg';
import { ReactComponent as Key } from './ic_key.svg';
import { ReactComponent as TestRun } from './ic_testrun.svg';
import { ReactComponent as Dot } from './ic_dot.svg';
import { ReactComponent as Campus } from './campus.svg';
import { ReactComponent as Triangle } from './ic_triangle.svg';
import { ReactComponent as UserGroups } from './ic_user_groups.svg';
import { ReactComponent as DescendingIconSort } from './descending-icon-sort.svg';
import { ReactComponent as AscendingIconSort } from './ascending-icon-sort.svg';

export default {
  ProductStatusDesign,
  ProductStatusArchive,
  ProductStatusProduction,
  SideMenuCalculations,
  SideMenuProduct,
  SideMenuTesting,
  SideMenuCatalogue,
  ModalOpen,
  Refresh,
  ExcelFile,
  PdfFile,
  WordFile,
  PowerPointFile,
  EngineFile,
  TutorialFile,
  HtmlFile,
  ImageFile,
  ProductStatusProductionOutlined,
  ProductStatusArchiveOutlined,
  ProductStatusDesignOutlined,
  FolderOpen,
  FolderDefault,
  Language,
  Logout,
  Sync,
  Edit,
  Cockpit,
  Alian,
  LeftArrow,
  ServiceDocIcon,
  TestingCentreIcon,
  VersionOverviewIcon,
  ViewLogsIcon,
  BookmarkIcon,
  GridView,
  ListView,
  PlusIconCircularFill,
  PlusIconCircularOutlined,
  CloseIcon,
  InfoCircle,
  CaretDownFilled,
  CaretUpFilled,
  CaretUpDownFilled,
  Pencil,
  Star,
  WarningIcon,
  FullscreenExpand,
  CurlCommandError,
  Link,
  Empty,
  Expanded,
  WarmIconRed,
  Reduce,
  InfoCircleFill,
  Right,
  Left,
  SixDotLine,
  ViewEye,
  UpCaret,
  DownCaret,
  FontSize,
  Bold,
  Italic,
  Underline,
  FontColor,
  AlignLeft,
  AlignCenter,
  AlignRight,
  Code,
  LinkUrl,
  UnLinkUrl,
  OrderedList,
  UnOrderedList,
  Undo,
  Warning,
  Error,
  DeleteIcon,
  CautionIcon,
  DefaultCoverImage,
  Download,
  CheckCircleOutlined,
  CheckCircleFilled,
  TableIcon,
  ThreeDotVerticalIcon,
  CaretRightOutlined,
  SupportIcon,
  InfoCircleOutlined,
  UserGuide,
  Key,
  TestRun,
  Dot,
  Campus,
  Triangle,
  UserGroups,
  DescendingIconSort,
  AscendingIconSort,
};
