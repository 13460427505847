import React, { FC } from 'react';
import Icon from '@ant-design/icons';
import Svgs from '../../../assets/Svgs';

interface IconSvgProps {
  type: string;
  className?: string;
  onClick?: (e) => void;
  title?: string;
  rotate?: number;
  style?: object;
}

const IconSvg: FC<IconSvgProps> = ({
  type,
  className,
  onClick,
  title,
  rotate,
  style,
  ...remaining
}) => {
  return (
    <Icon
      component={Svgs[type]}
      className={`${type} ${className || ''}`}
      onClick={onClick}
      title={title}
      rotate={rotate}
      style={style}
      {...remaining}
    />
  );
};

export { IconSvg };
