import { v4 as uuidv4 } from 'uuid';
import _ from 'lodash';
import { TenantConfigAction } from '../actions/types';
import { TenantConfigConstants } from '../constants';
import {
  defaultCompilerVersionForNewlyUploadedServices,
  neuronCompilationTypes,
} from '../constants/engine.constants';

export const initialState: STATES.TenantConfigState = {
  isLoadingProxyObject: false,
  isLoadingProxyObjectList: false,
  isLoadingAssignUserGroup: false,
  isLoadingRemoveUserGroup: false,
  proxyObjectList: [],
  isSaving: false,
  isLoadingGetAllConfig: false,
  isPreventNavigation: false,
  EnablePublicAPI_Default: false,
  EnablePublicAPI: false,
  EnablePublicAPI_shell: false,
  EnablePublicAPI_wasm: false,
  PublicAPI_SyntheticKey: '',
  EnableTags: false,
  IPWhitelistingConfiguration: [],
  CompilerVersion_NeuronServiceUpdate: '',
  CompilerVersion_NeuronTenantDefault: '',
  CompilerVersion_NeuronStableLatest: '',
  CompilerVersion_NeuronRC: '',
  CompilerVersions_Neuron: '',
  ExplainerConfiguration_DomainURL: undefined,
  EnableIPWhitelisting: false,
  EnableWebhooks: false,
  WebHooks: [],
  Tags: {},
  TagsSelected: '',
};

export const tenantConfig = (
  state = initialState,
  action: TenantConfigAction
): STATES.TenantConfigState => {
  switch (action.type) {
    case TenantConfigConstants.GET_PROXYOBJECTLIST_REQUEST: {
      return {
        ...state,
        isLoadingProxyObjectList: true,
      };
    }

    case TenantConfigConstants.GET_PROXYOBJECTLIST_SUCCESS: {
      const { proxyObjectList } = action.payload;

      return {
        ...state,
        isLoadingProxyObjectList: false,
        proxyObjectList,
      };
    }

    case TenantConfigConstants.GET_PROXYOBJECTLIST_FAILURE: {
      return {
        ...state,
        isLoadingProxyObjectList: false,
      };
    }

    case TenantConfigConstants.GET_PROXYOBJECT_REQUEST: {
      return {
        ...state,
        isLoadingProxyObject: true,
      };
    }

    case TenantConfigConstants.GET_PROXYOBJECT_SUCCESS: {
      const { proxyObject } = action.payload;

      return {
        ...state,
        isLoadingProxyObject: false,
        proxyObject,
      };
    }

    case TenantConfigConstants.GET_PROXYOBJECT_FAILURE: {
      return {
        ...state,
        isLoadingProxyObject: false,
      };
    }

    case TenantConfigConstants.ASSIGN_USERGROUPTOPROXYOBJECT_REQUEST: {
      return {
        ...state,
        isLoadingAssignUserGroup: true,
      };
    }
    case TenantConfigConstants.ASSIGN_USERGROUPTOPROXYOBJECT_FAILURE:
    case TenantConfigConstants.ASSIGN_USERGROUPTOPROXYOBJECT_SUCCESS: {
      return {
        ...state,
        isLoadingAssignUserGroup: false,
      };
    }

    case TenantConfigConstants.REMOVE_USERGROUPFROMPROXYOBJECT_REQUEST: {
      return {
        ...state,
        isLoadingRemoveUserGroup: true,
      };
    }
    case TenantConfigConstants.REMOVE_USERGROUPFROMPROXYOBJECT_FAILURE:
    case TenantConfigConstants.REMOVE_USERGROUPFROMPROXYOBJECT_SUCCESS: {
      return {
        ...state,
        isLoadingRemoveUserGroup: false,
      };
    }

    case TenantConfigConstants.SAVE_SPARKCONFIG_REQUEST: {
      return {
        ...state,
        isSaving: true,
      };
    }

    case TenantConfigConstants.SAVE_SPARKCONFIG_SUCCESS: {
      return {
        ...state,
        isSaving: false,
      };
    }

    case TenantConfigConstants.SAVE_SPARKCONFIG_FAILURE: {
      return {
        ...state,
        isSaving: false,
      };
    }

    case TenantConfigConstants.GET_ALL_SPARKCONFIG_REQUEST: {
      return {
        ...state,
        isLoadingGetAllConfig: true,
      };
    }

    case TenantConfigConstants.GET_ALL_SPARKCONFIG_SUCCESS: {
      const {
        IPWhitelistingConfiguration,
        EnableIPWhitelisting,
        ExplainerConfiguration_DomainURL,
        EnablePublicAPI_Default,
        EnablePublicAPI,
        EnablePublicAPI_shell,
        EnablePublicAPI_wasm,
        PublicAPI_SyntheticKey,
        EnableTags,
        Tags,
        TagsSelected,
        CompilerVersion_NeuronServiceUpdate,
        CompilerVersion_NeuronTenantDefault,
        CompilerVersion_NeuronStableLatest,
        CompilerVersion_NeuronRC,
        CompilerVersions_Neuron,
        EnableWebhooks,
        WebHooks,
      } = action.payload;
      const iA = (IPWhitelistingConfiguration || []).map(item => ({
        ...item,
        key: uuidv4(),
      }));
      const returnBoolValue = value => {
        if (typeof value === 'boolean') {
          return value;
        }
        if (typeof value === 'string') {
          return value.toLowerCase() === 'true';
        }
        return false;
      };
      return {
        ...state,
        isLoadingGetAllConfig: false,
        IPWhitelistingConfiguration: iA,
        EnableIPWhitelisting: returnBoolValue(EnableIPWhitelisting),
        ExplainerConfiguration_DomainURL,
        PublicAPI_SyntheticKey,
        EnablePublicAPI: returnBoolValue(EnablePublicAPI),
        EnablePublicAPI_Default: returnBoolValue(EnablePublicAPI_Default),
        EnablePublicAPI_shell: returnBoolValue(EnablePublicAPI_shell),
        EnablePublicAPI_wasm: returnBoolValue(EnablePublicAPI_wasm),
        EnableTags: returnBoolValue(EnableTags),
        Tags: !_.isNil(Tags) && typeof Tags !== 'string' ? Tags : {},
        TagsSelected,
        CompilerVersion_NeuronServiceUpdate:
          CompilerVersion_NeuronServiceUpdate ||
          neuronCompilationTypes.find(i => i.key === 'MaintainVersion')?.value,
        CompilerVersion_NeuronTenantDefault:
          CompilerVersion_NeuronTenantDefault ||
          defaultCompilerVersionForNewlyUploadedServices.find(
            i => i.key === 'StableLatest'
          )?.value,
        CompilerVersion_NeuronStableLatest,
        CompilerVersion_NeuronRC,
        CompilerVersions_Neuron,
        EnableWebhooks: returnBoolValue(EnableWebhooks),
        WebHooks:
          !_.isNil(WebHooks) && typeof WebHooks !== 'string' ? WebHooks : [],
      };
    }

    case TenantConfigConstants.GET_ALL_SPARKCONFIG_FAILURE: {
      return {
        ...state,
        isLoadingGetAllConfig: false,
      };
    }
    case TenantConfigConstants.UPDATE_NAVIGATION: {
      const { isPreventNavigation } = action.payload;
      return {
        ...state,
        isPreventNavigation: isPreventNavigation ?? false,
      };
    }

    default:
      return state;
  }
};
