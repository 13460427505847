import { Button, Table } from 'antd';
import React, { FC, useEffect, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { SystemActions } from '../actions';
import { CustomModal } from '../components/Common';
import { useLookupData } from '../hooks';
import { StyledAboutContent } from './index.styles';

interface AboutModalProps {
  visible: boolean;
  onClose: () => void;
}

const detailsTableColumn = [
  {
    dataIndex: 'label',
    key: 'label',
    width: 195,
  },
  {
    dataIndex: 'details',
    key: 'details',
  },
];

const mapStateToProps = ({
  system: { releaseDetails, isLoadingReleaseDetails },
}: STATES.AppState) => ({
  releaseDetails,
  isLoadingReleaseDetails,
});

const AboutModal: FC<AboutModalProps> = ({ visible, onClose }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { releaseNote, releaseVersion, isLoadingLookupData } = useLookupData();
  const { releaseDetails, isLoadingReleaseDetails } = useSelector(
    mapStateToProps
  );

  useEffect(() => {
    if (!releaseDetails) {
      dispatch(SystemActions.getReleaseDetails());
    }
  }, [dispatch, releaseDetails]);

  const data = useMemo(() => {
    return [
      {
        label: intl.formatMessage({ id: 'AboutModal.table.versionNumber' }),
        details: releaseDetails && releaseDetails.releaseVersion,
      },
      {
        label: intl.formatMessage({ id: 'AboutModal.table.versionDetails' }),
        details: releaseNote,
      },
      {
        label: intl.formatMessage({ id: 'AboutModal.table.releasedDate' }),
        details: releaseDetails && releaseDetails.releaseDate,
      },
      {
        label: intl.formatMessage({ id: 'AboutModal.table.releaseVersion' }),
        details: process.env.REACT_APP_VERSION ?? releaseVersion,
      },
      {
        label: intl.formatMessage({
          id: 'AboutModal.table.sparkConfigVersion',
        }),
        details:
          releaseDetails &&
          releaseDetails?.sparkConfigVersion.replace('.xlsx', ''),
      },
    ];
  }, [intl, releaseDetails, releaseVersion, releaseNote]);

  return (
    <>
      <CustomModal
        visible={visible}
        closable
        getContainer={document.body}
        showLoading
        isContentLoading={isLoadingReleaseDetails || isLoadingLookupData}
        title={<FormattedMessage id="AboutModal.heading" />}
        content={
          <StyledAboutContent>
            <Table
              showHeader={false}
              pagination={false}
              columns={detailsTableColumn}
              dataSource={data}
              rowKey="label"
              scroll={{ x: 450 }}
            />
          </StyledAboutContent>
        }
        footer={
          <Button
            data-testid="about-modal-ok-btn"
            type="primary"
            shape="round"
            size="large"
            className="mw-200"
            onClick={onClose}
          >
            <FormattedMessage id="AboutModal.table.ok" />
          </Button>
        }
        destroyOnClose
        onCancel={onClose}
      />
    </>
  );
};

export default AboutModal;
