import { EngineAction } from '../actions/types';
import { EngineConstants } from '../constants';

export const initialState: STATES.AddEngineState = {
  errorCode: 0,
  hasError: false,
  startUpload: false,
  uploadPercentage: 0,
  executePercentage: 0,
  errorMessage: '',
  isUploading: false,
  isUploaded: false,
  isMissingReleaseNote: false,
  isSubmmitReleaseNote: false,
  startUploadTime: 0,
  endUploadTime: 0,
  uploadProgress: 0,
  startExecuteTime: 0,
  endExecuteTime: 0,
  executeProgress: 0,
  addEngineError: null,
  addEngineStats: null,
  executeResult: null,
  xhrRef: null,
  isPublishing: false,
  publishedSuccess: false,
  publishingEngineName: null,
  lastAddEngineStats: null,
  currentStep: 0,
  activePeriod: null,
  originalFileDocumentId: '',
  engineFileDocumentId: '',
};

export const addEngine = (
  state = initialState,
  action: EngineAction
): STATES.AddEngineState => {
  switch (action.type) {
    case EngineConstants.ADD_ENGINE_EXECUTE_PROGRESS: {
      if (state.executeProgress >= 90) {
        return state;
      }
      return {
        ...state,
        executeProgress: state.executeProgress + 5,
      };
    }

    case EngineConstants.UPDATE_ACTIVE_PERIOD: {
      const { activePeriod } = action.payload;
      return {
        ...state,
        activePeriod,
      };
    }

    case EngineConstants.UPDATE_RELEASE_NOTE: {
      const { releaseNote } = action.payload;
      return {
        ...state,
        releaseNote,
      };
    }

    case EngineConstants.CANCEL_PUBLISH_ENGINE: {
      return {
        ...initialState,
        publishingEngineName: null,
      };
    }

    case EngineConstants.ADD_ENGINE_RESET:
    case EngineConstants.RESET_ENGINE_UPLOAD: {
      state.xhrRef?.abort();
      return {
        ...initialState,
        publishingEngineName: state.publishingEngineName,
        lastAddEngineStats: state.lastAddEngineStats,
      };
    }

    case EngineConstants.UPDATE_ENGINE_PROPS_FAILURE:
    case EngineConstants.UPDATE_ENGINE_PROPS_SUCCESS: {
      return {
        ...state,
        currentStep: 0,
      };
    }
    case EngineConstants.UPDATE_ENGINE_PROPS_REQUEST: {
      return {
        ...state,
        currentStep: 2,
      };
    }

    default:
      return state;
  }
};
