let jsonConfig: DTO.GlobalConfiguration = {
  excelEngineDomain: '',
  redirectUrl: '',
  defaultTenant: '',
  defaultTimeout: '',
  idpConfiguration: '',
  apiGatewayApiVersion: '',
  apiGatewaySubscriptionKey: '',
  skipLoginFlow: '',
  issuers: [],
  usersManagementUrl: '',
  reportSupportUrl: '',
  defaultAdminTenant: '',
  emailLifeSpan: '',
};

export const getBaseUrl = () => {
  let baseUrl = `${window.location.protocol}//${window.location.host}`;
  if (
    process.env.REACT_APP_PUBLIC_URL &&
    process.env.REACT_APP_PUBLIC_URL !== ''
  ) {
    baseUrl = `${window.location.protocol}//${window.location.host}${process.env.REACT_APP_PUBLIC_URL}`;
  }

  return baseUrl;
};

export const fetchJsonConfig = async () => {
  const baseUrl = getBaseUrl();

  await fetch(`${baseUrl}/config.json`)
    .then(response => response.text())
    .then(async text => {
      try {
        jsonConfig = JSON.parse(text);
        // eslint-disable-next-line no-empty
      } catch (err) {
      } finally {
        await fetch(`${baseUrl}/issuer.json`)
          .then(response => response.text())
          .then(text => {
            try {
              jsonConfig.issuers = JSON.parse(text);
              // eslint-disable-next-line no-empty
            } catch (err) {}
          });
      }
    });
};

const isValidConfigProperty = (propertyName: string | undefined): boolean => {
  return !(propertyName === '' || !propertyName);
};

export const getConfig = (): DTO.GlobalConfiguration => {
  return {
    excelEngineDomain: !isValidConfigProperty(jsonConfig.excelEngineDomain)
      ? process.env.REACT_APP_EXCELENGINE_DOMAIN
      : jsonConfig.excelEngineDomain,

    redirectUrl: !isValidConfigProperty(jsonConfig.redirectUrl)
      ? process.env.REACT_APP_REDIRECT_URL
      : jsonConfig.redirectUrl,

    defaultTenant: !isValidConfigProperty(jsonConfig.defaultTenant)
      ? process.env.REACT_APP_DEFAULT_TENANT
      : jsonConfig.defaultTenant,

    defaultTimeout: !isValidConfigProperty(jsonConfig.defaultTimeout)
      ? process.env.REACT_APP_DEFAULT_TIMEOUT_IN_MINUTE
      : jsonConfig.defaultTimeout,

    idpConfiguration: !isValidConfigProperty(jsonConfig.idpConfiguration)
      ? process.env.REACT_APP_IDP_CONFIGURATION_FILE
      : jsonConfig.idpConfiguration,

    apiGatewayApiVersion: !isValidConfigProperty(
      jsonConfig.apiGatewayApiVersion
    )
      ? process.env.REACT_APP_APIGATEWAY_API_VERSION
      : jsonConfig.apiGatewayApiVersion,

    apiGatewaySubscriptionKey: !isValidConfigProperty(
      jsonConfig.apiGatewaySubscriptionKey
    )
      ? process.env.REACT_APP_APIGATEWAY_SUBSCRIPTION_KEY
      : jsonConfig.apiGatewaySubscriptionKey,

    skipLoginFlow: !isValidConfigProperty(jsonConfig.skipLoginFlow)
      ? process.env.REACT_APP_SKIP_LOGIN_FLOW
      : jsonConfig.skipLoginFlow,

    version: !isValidConfigProperty(jsonConfig.version)
      ? process.env.REACT_APP_VERSION
      : jsonConfig.version,

    issuers: jsonConfig.issuers,

    usersManagementUrl: !isValidConfigProperty(jsonConfig.usersManagementUrl)
      ? process.env.REACT_APP_USERS_MANAGEMENT_URL
      : jsonConfig.usersManagementUrl,

    reportSupportUrl: !isValidConfigProperty(jsonConfig.reportSupportUrl)
      ? process.env.REACT_APP_REPORT_URL
      : jsonConfig.reportSupportUrl,

    defaultAdminTenant: !isValidConfigProperty(jsonConfig.defaultAdminTenant)
      ? process.env.REACT_APP_DEFAULT_ADMIN_TENANT
      : jsonConfig.defaultAdminTenant,
    // sentry config
    sentry: jsonConfig.sentry,
    // apm config
    elasticApm: jsonConfig.elasticApm,

    pendo: {
      apiKey: !isValidConfigProperty(jsonConfig?.pendo?.apiKey)
        ? process.env.REACT_APP_PENDO_API_KEY
        : jsonConfig?.pendo?.apiKey,
      enabled: jsonConfig?.pendo?.enabled?.toString()?.toLowerCase() === 'true',
    },

    // Email Life Span
    emailLifeSpan: !isValidConfigProperty(jsonConfig.emailLifeSpan)
      ? process.env.REACT_APP_EMAIL_LIFESPAN
      : jsonConfig.emailLifeSpan,

    csSsoBaseUrl: !isValidConfigProperty(jsonConfig.csSsoBaseUrl)
      ? process.env.REACT_APP_CS_SSO_BASE_URL
      : jsonConfig.csSsoBaseUrl,

    csSsoCampusClientId: !isValidConfigProperty(jsonConfig.csSsoCampusClientId)
      ? process.env.REACT_APP_CS_SSO_CAMPUS_CLIENT_ID
      : jsonConfig.csSsoCampusClientId,

    neuronConfigured: !isValidConfigProperty(jsonConfig.neuronConfigured)
      ? process.env.REACT_APP_NEURON_CONFIGURED
      : jsonConfig.neuronConfigured,

    sparkFormsDomain: !isValidConfigProperty(jsonConfig.sparkFormsDomain)
      ? process.env.REACT_APP_SPARK_FORMS_DOMAIN
      : jsonConfig.sparkFormsDomain,
  };
};
