import { getConfig } from './config';

type FileCategoryTypes =
  | 'Excel'
  | 'Html'
  | 'Image'
  | 'PDF'
  | 'PPT'
  | 'Doc'
  | string;

export const getDocFileExtensions = (
  fileCategory?: FileCategoryTypes
): string[] => {
  const fileType = fileCategory?.toLowerCase();
  switch (fileType) {
    case 'excel':
      return ['.xlsx', '.xlsm', '.xlsb', '.xltx', '.xltm', '.xls'];
    case 'html':
      return ['.xhtml', '.html', '.xml'];
    case 'image':
      return ['.jpg', '.jpeg', '.png', '.gif', '.tiff', '.webp'];
    case 'pdf':
      return ['.pdf'];
    case 'ppt':
      return ['.ppt', '.pptm', '.pptx', '.potx', '.pot', '.ppsx', '.pps'];
    case 'doc':
      return ['.doc', '.docx'];
    default:
      return [];
  }
};

export const ALL_DEFINED_DOC_TYPES =
  '.xlsx, .xlsm, .xlsb, .xltx, .xltm, .xls, .xhtml, .html, .xml, .jpg, .jpeg, .png, .gif, .tiff, .pdf, .ppt, .pptm, .pptx, .potx, .pot, .ppsx, .pps, .doc, .docx, .webp';

type ExtensionTypes =
  | '.xlsx'
  | '.xlsm'
  | '.xlsb'
  | '.xltx'
  | '.xltm'
  | '.xls'
  | '.xhtml'
  | '.html'
  | '.xml'
  | '.jpg'
  | '.jpeg'
  | '.png'
  | '.gif'
  | '.tiff'
  | '.ppt'
  | '.pptm'
  | '.pptx'
  | '.potx'
  | '.pot'
  | '.ppsx'
  | '.pps'
  | '.pdf'
  | '.doc'
  | '.docx'
  | '.webp'
  | string;

export const getExtensionName = (extension: ExtensionTypes): string => {
  if (
    ['.xlsx', '.xlsm', '.xlsb', '.xltx', '.xltm', '.xls'].includes(extension)
  ) {
    return 'Excel';
  }
  if (['.xhtml', '.html', '.xml'].includes(extension)) {
    return 'Html';
  }
  if (['.jpg', '.jpeg', '.png', '.gif', '.tiff', '.webp'].includes(extension)) {
    return 'Image';
  }
  if (['.pdf'].includes(extension)) {
    return 'PDF';
  }
  if (
    ['.ppt', '.pptm', '.pptx', '.potx', '.pot', '.ppsx', '.pps'].includes(
      extension
    )
  ) {
    return 'PPT';
  }
  if (['.doc', '.docx'].includes(extension)) {
    return 'Doc';
  }

  return '';
};

export const vaidateAcceptableFile = (
  fileName?: string,
  targetAcceptableTypes?: string[],
  acceptedSourceTypes?: string[]
): boolean => {
  if (!targetAcceptableTypes) {
    return false;
  }
  if (!fileName) {
    return !!acceptedSourceTypes?.every(type =>
      targetAcceptableTypes.includes(type)
    );
  }
  const nameSplitted = fileName?.split('.');
  const fileExt = `.${nameSplitted[nameSplitted.length - 1]}`;
  const ExtName = getExtensionName(fileExt);
  return targetAcceptableTypes.includes(ExtName);
};

export const getPageNoOnDelete = (list, page) => {
  if (list && list.length === 1) {
    return page === 1 ? page : page - 1;
  }
  return page;
};

export const getbaseUrl = () => {
  const config = getConfig();
  const tenant = localStorage.getItem('Tenant');
  return `${config.excelEngineDomain}/${tenant ||
    config.defaultTenant}/api/v3/`;
};

export const getbaseUrlWithTenant = () => {
  const config = getConfig();
  const tenant = localStorage.getItem('Tenant');
  return `${config.excelEngineDomain}/${tenant ||
    config.defaultTenant}`;
};

// IPv4 with CIDR
export const ipv4 = new RegExp(
  '^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?).){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(/(3[0-2]|2[0-9]|1[0-9]|[0-9]))?$'
);

// IPv6 with CIDR
export const ipv6 = new RegExp(
  '^(([0-9a-fA-F]{1,4}:){7,7}[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,7}:|([0-9a-fA-F]{1,4}:){1,6}:[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,5}(:[0-9a-fA-F]{1,4}){1,2}|([0-9a-fA-F]{1,4}:){1,4}(:[0-9a-fA-F]{1,4}){1,3}|([0-9a-fA-F]{1,4}:){1,3}(:[0-9a-fA-F]{1,4}){1,4}|([0-9a-fA-F]{1,4}:){1,2}(:[0-9a-fA-F]{1,4}){1,5}|[0-9a-fA-F]{1,4}:((:[0-9a-fA-F]{1,4}){1,6})|:((:[0-9a-fA-F]{1,4}){1,7}|:)|fe80:(:[0-9a-fA-F]{0,4}){0,4}%[0-9a-zA-Z]{1,}|::(ffff(:0{1,4}){0,1}:){0,1}((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9]).){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])|([0-9a-fA-F]{1,4}:){1,4}:((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9]).){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9]))(/((1(1[0-9]|2[0-8]))|([0-9][0-9])|([0-9])))?$'
);

export const isNumericString = (input: string) => {
  if (typeof input === 'number') {
    return true;
  }

  if (
    ['boolean', 'object', 'undefined', 'symbol', 'symbol'].includes(
      typeof input
    )
  ) {
    return false;
  }

  if (input.trim() === '') {
    return false;
  }

  // Test for numeric values
  if (!Number.isNaN(Number(input))) {
    return true;
  }

  // Test for non-numeric characters
  return false;
};
