import React, { FC, useRef } from 'react';
import { TimePicker } from 'antd';
import styled from 'styled-components';
import { TimePickerProps } from 'antd/lib/time-picker';

const StyledTimePicker = styled(props => <TimePicker {...props} />)`
  && {
    margin-right: 20px;
    width: 100%;

    .ant-time-picker-input {
      border: 1px solid ${({ theme }) => theme.color.purple} !important;
      background: ${({ theme }) => theme.color.white};
      padding-right: 30px;
      height: 40px;
      font-size: 16px;
      color: ${({ theme }) => theme.color.dark};
    }

    .anticon-close-circle {
      color: ${({ theme }) => theme.color.purple} !important;
    }
  }
`;

const PickerContainer = styled.div`
  .ant-time-picker-panel-combobox {
    display: flex;
    .ant-time-picker-panel-select {
      .ant-time-picker-panel-select-option-selected {
        background: #e6f7ff;
        color: ${({ theme }) => theme.color.dark};
      }

      li:focus,
      li:active,
      li:hover {
        background: ${({ theme }) => theme.color.purple};
      }
    }
  }
`;

const CustomTimePicker: FC<TimePickerProps> = props => {
  const popUpContainerRef = useRef<HTMLDivElement>(null);

  return (
    <div>
      <PickerContainer ref={popUpContainerRef} />

      <StyledTimePicker
        getPopupContainer={() => popUpContainerRef.current || document.body}
        {...props}
      />
    </div>
  );
};

export { CustomTimePicker };
