/* eslint-disable no-restricted-syntax */
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { flatten } from 'flat';
import styled from 'styled-components';
import _ from 'lodash';
import { OutputFormattedTable } from '../components/ApiTester/ApiTesterOutputFormattedModal';
import { EngineConstants } from '../constants';
import { arrayRemoveFirst } from './array';

const StyledEllipseAnchor = styled.a`
  width: 380px;
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const isJsonParsable = string => {
  try {
    JSON.parse(string).map(input => {
      const newKey = Object.keys(input)[0];
      return { [newKey]: '' };
    });
  } catch (e) {
    return false;
  }
  return true;
};

export const jsonParse = string => {
  try {
    return JSON.parse(string);
  } catch (e) {
    return string;
  }
};

export const convertListToDictionary = (
  engineInputs: DTO.XSingelCellNameRange[],
  shouldIncludeSubservice,
  subServiceName = ''
): {} => {
  const inputs: {
    [xInputs: string]: string | number | boolean | object | object[];
  } = {};
  engineInputs?.map(i => {
    if (shouldIncludeSubservice) {
      if (subServiceName === '') {
        if (isJsonParsable(i.value)) {
          inputs[i.name] = JSON.parse(i.value);
        } else {
          inputs[i.name] = i.value;
        }
      } else if (subServiceName !== '') {
        if (i.name.startsWith(`${subServiceName}.`)) {
          if (isJsonParsable(i.value)) {
            inputs[i.name] = JSON.parse(i.value);
          } else {
            inputs[i.name] = i.value;
          }
        }
      }
    } else if (!shouldIncludeSubservice) {
      // Note: Below Condition is for exclude subservice inputs and output
      if (
        i.name.indexOf('.') === -1 ||
        (i.name.indexOf('.') !== -1 &&
          (i.xjName === null || i.xjName === '') &&
          i.type === 'XJ')
      ) {
        if (isJsonParsable(i.value)) {
          inputs[i.name] = JSON.parse(i.value);
        } else {
          inputs[i.name] = i.value;
        }
      }
    }
  });
  return inputs;
};

export const convertListToDictionaryForOtherInputs = (
  selectedEngine: DTO.EngineData | null,
  subServiceNames = ''
) => {
  const inputs: {
    solve: DTO.XSolveInputRequest[];
    image: DTO.XImageInputRequest[];
    report: DTO.XReportInputRequest[];
  } = {
    solve: [],
    image: [],
    report: [],
  };

  ['solves', 'reports', 'images'].forEach(type => {
    if (type === 'reports') {
      const reportInputs = (selectedEngine?.reportInputs || []).map(report => {
        return {
          FileName: report.fileName,
          ReportName: report.reportName,
        };
      });

      inputs.report = [...reportInputs];
    }
    if (type === 'images') {
      const imageInputs = (selectedEngine?.imageInputs || []).map(image => {
        return {
          FileName: image.fileName,
          ImageName: image.imageName,
        };
      });
      inputs.image = [...imageInputs];
    }
    if (type === 'solves') {
      const solverInputs = (selectedEngine?.solveInputs || []).map(solve => {
        return {
          SolveName: solve.solveName,
          IntialGuess: solve.intialGuess,
          MaxChange: solve.maxChange,
          MaxIteration: solve.maxIteration,
          RunIfTrue: solve.runIfTrue,
          TargetValue: solve.targetValue,
        };
      });
      inputs.solve = [...solverInputs];
    }
  });

  if (subServiceNames === '') {
    return inputs;
  }

  const tempInputs: {
    solve: DTO.XSolveInputRequest[];
    image: DTO.XImageInputRequest[];
    report: DTO.XReportInputRequest[];
  } = {
    solve: [],
    image: [],
    report: [],
  };

  const serviceCategoryArray = subServiceNames.split(',');
  const defaultServiceCategoryIndex = serviceCategoryArray.indexOf('#Default#');
  if (defaultServiceCategoryIndex !== -1) {
    serviceCategoryArray.splice(defaultServiceCategoryIndex, 1);
  }

  Object.keys(inputs).forEach(key => {
    let keyName = '';
    if (key === 'solve') {
      keyName = 'SolveName';
    } else if (key === 'image') {
      keyName = 'ImageName';
    } else {
      keyName = 'ReportName';
    }
    inputs[key].forEach(item => {
      if (
        item[keyName].indexOf('.') === -1 &&
        defaultServiceCategoryIndex !== -1
      ) {
        tempInputs[key].push(item);
      }
      serviceCategoryArray.forEach(subServiceName => {
        if (
          item[keyName]
            .toLowerCase()
            .startsWith(`${subServiceName}.`.toLowerCase())
        ) {
          tempInputs[key].push(item);
        }
      });
    });
  });

  return tempInputs;
};

export const getInputsAfterExecuteEngineForV3 = (
  serviceCategory,
  selectedEngine,
  requestedInputs
) => {
  if (serviceCategory === '') {
    return requestedInputs;
  }
  const inputsList: object[] = [];
  const serviceCategoryArray = serviceCategory.split(',');
  const defaultServiceCategoryIndex = serviceCategoryArray.indexOf('#Default#');
  if (defaultServiceCategoryIndex !== -1) {
    serviceCategoryArray.splice(defaultServiceCategoryIndex, 1);
    const defaultInputs = convertListToDictionary(
      selectedEngine?.xlInputs ?? [],
      false,
      ''
    );
    inputsList.push(defaultInputs);
  }
  serviceCategoryArray.forEach(category => {
    const inputs = convertListToDictionary(
      selectedEngine?.xlInputs ?? [],
      true,
      category
    );
    inputsList.push(inputs);
  });

  let tempobj = {};
  inputsList.forEach(data => {
    tempobj = { ...tempobj, ...data };
  });

  Object.keys(tempobj).forEach(key => {
    if (requestedInputs[key] !== undefined) {
      tempobj[key] = requestedInputs[key];
    }
  });
  const otherInputsName = ['images', 'reports', 'solves'];
  otherInputsName.forEach(name => {
    selectedEngine.inputMetadata[name]?.forEach(key => {
      if (requestedInputs[key] !== undefined) {
        tempobj[key] = requestedInputs[key];
      }
    });
  });
  return tempobj;
};

export const removePrefix = (value, prefix) =>
  value.startsWith(prefix) ? value.slice(prefix.length) : value;

export const filterObject = (
  array: object,
  condition: (keys: string) => void
) => {
  return Object.keys(array)
    .filter(condition)
    .reduce((obj, key) => {
      return Object.assign(obj, {
        [key]: array[key],
      });
    }, {});
};

export const getInputsOutputsFromV1Result = (
  serviceCategory,
  selectedEngine,
  type: 'xlInputs' | 'oldXlInputs' | 'xOutput',
  requestedOutput: string | string[] | undefined = undefined
) => {
  const requestedOutputString =
    requestedOutput !== undefined && typeof requestedOutput === 'string'
      ? requestedOutput?.toLowerCase().trim() || ''
      : requestedOutput?.map(e => e.trim()).join(',') || '';
  if (serviceCategory === '') {
    if (type === 'xlInputs' || type === 'oldXlInputs') {
      return convertListToDictionary(selectedEngine[type], false);
    }
    let xOutputData = convertListToDictionary(selectedEngine[type], false);
    if (requestedOutput && requestedOutput !== '') {
      xOutputData = filterObject(xOutputData, key =>
        key.toLowerCase().startsWith(requestedOutputString)
      );
    }
    return xOutputData;
  }
  const inputsOutputList: object[] = [];
  const serviceCategoryArray = serviceCategory.split(',');
  const defaultServiceCategoryIndex = serviceCategoryArray.indexOf('#Default#');
  if (defaultServiceCategoryIndex !== -1) {
    serviceCategoryArray.splice(defaultServiceCategoryIndex, 1);
    const defaultInputsOutputs = convertListToDictionary(
      selectedEngine[type],
      false,
      ''
    );
    inputsOutputList.push(defaultInputsOutputs);
  }
  serviceCategoryArray.forEach(category => {
    const inputsOutputs = convertListToDictionary(
      selectedEngine[type],
      true,
      category
    );
    inputsOutputList.push(inputsOutputs);
  });

  let tempobj = {};
  inputsOutputList.forEach(data => {
    tempobj = { ...tempobj, ...data };
  });
  if (requestedOutput && requestedOutput !== '' && type === 'xOutput') {
    if (serviceCategory.split(',').length === 1) {
      tempobj = filterObject(tempobj, (key: string) => {
        if (
          requestedOutputString ===
            `${serviceCategory.split(',')[0]}.`.toLowerCase() &&
          key.toLowerCase().startsWith(requestedOutputString)
        ) {
          return true;
        }
        if (
          removePrefix(
            key.toLowerCase(),
            `${serviceCategory.split(',')[0]}.`.toLowerCase()
          ).startsWith(requestedOutputString)
        ) {
          return true;
        }
        if (
          removePrefix(
            key.toLowerCase(),
            `${serviceCategory.split(',')[0]}.`.toLowerCase()
          ).startsWith(
            removePrefix(
              requestedOutputString,
              `${serviceCategory.split(',')[0]}.`.toLowerCase()
            )
          )
        ) {
          return true;
        }
        return false;
      });
    }
    if (serviceCategory.split(',').length > 1) {
      tempobj = filterObject(tempobj, (key: string) =>
        key.toLowerCase().startsWith(requestedOutputString)
      );
    }
  }
  return tempobj;
};

export const getExcelObject = (
  engine: DTO.EngineData,
  productName: string,
  serviceName: string,
  requestedServiceCategories: string,
  requestedOutput: string | string[]
): DTO.XlObj => {
  const othersInputs = convertListToDictionaryForOtherInputs(
    engine,
    requestedServiceCategories
  );
  const result: DTO.XlObj = {
    Inputs: getInputsOutputsFromV1Result(
      requestedServiceCategories,
      engine,
      'xlInputs'
    ),
    Outputs: getInputsOutputsFromV1Result(
      requestedServiceCategories,
      engine,
      'xOutput',
      requestedOutput
    ),
    ImageInputs: othersInputs.image as {
      ImageName: string;
      FileName: string;
    }[],
    SolveInputs: othersInputs.solve as {
      SolveName: string;
      TargetValue: string;
      MaxChange: string;
      MaxIteration: string;
      IntialGuess: string;
      RunIfTrue: boolean;
    }[],
    ReportInputs: othersInputs.report as {
      ReportName: string;
      FileName: string;
    }[],
    Errors: engine.validationErrors?.map(v => {
      return {
        SourcePath: v.sourcePath,
        Message: v.message,
        AdditionalDetails: v.additionalDetails,
        ErrorType: v.errorType,
      };
    }),
    Warnings: [],
    FetchTimeUTC: engine.fetchTimeUTC,
    EngineID: engine.engineID,
    ServiceName: serviceName,
    Revision: engine.bookSummary?.bookRevision ?? '',
    Version: engine.bookSummary?.versionId ?? '',
    ReleaseDate: engine.bookSummary?.bookReleaseDate ?? '',
    ServiceCategories: engine.serviceCategories ?? undefined,
    Description: engine.bookSummary?.bookDescription ?? '',
    EngineHash: engine.engineHash,
    CalcTime: engine.lastCalcTime,
    TotalTime: engine.totalTime,
    ProductName: productName,
    EffectiveEndDate: engine.effectiveEndDate ?? null,
    EffectiveStartDate: engine.effectiveStartDate ?? null,
    EngineType: engine.engineType,
    CallGUID: '',
    parameterset_version_id: engine.parameterset_version_id,
  };
  return result;
};

export const getApiTesterInitialDataReq = (
  type?: string,
  version_id?: string,
  engine_type?: string,
  parametersetVersionId?: string
): Partial<DTO.ExcelEngineRequest | DTO.ExcelEngineRequestV3> => {
  if (type === 'V3') {
    const requestMeta = {
      version_id: version_id || '',
      call_purpose: EngineConstants.EXECUTE_API_CALL_PURPOSE_API_TESTER,
      source_system: 'SPARK',
      correlation_id: null,
      requested_output: null,
      service_category: '',
      compiler_type: engine_type,
    };

    if (parametersetVersionId) {
      requestMeta['parameterset_version_id'] = parametersetVersionId;
    }

    return {
      request_data: { inputs: {} } as DTO.ExcelEngineRequestRequestData,
      request_meta: requestMeta as DTO.ExcelEngineRequestRequestMeta,
    };
  }
  return { Inputs: {} };
};

export const getMappedSectionData = (data, mappedData, isOutput = true) => {
  const output: { [xOutput: string]: string | number | object[] } = {};
  const dataKeys = JSON.parse(JSON.stringify(data || {}));
  Object.keys(data || {}).forEach(key => {
    Object.keys(mappedData || []).forEach(map => {
      const result = mappedData[map] && mappedData[map]?.includes(key);
      if (result) {
        output[key] = map;
        if (Object.prototype.hasOwnProperty.call(dataKeys, key)) {
          delete dataKeys[key];
        }
      }
    });
  });
  if (isOutput) {
    Object.keys(dataKeys || {}).forEach(key => {
      output[key] = 'single';
    });
  }
  return output;
};

export const getFilteredData = (
  compateType,
  mappingData,
  filtersType,
  keyToAdd = ''
) => {
  const asArray = mappingData ? Object.entries(mappingData) : [];
  const filtered = asArray.filter(([key, value]) => {
    if (compateType === 'key') {
      return filtersType.includes(key);
    }

    return filtersType.includes(value);
  });
  if (keyToAdd !== '') {
    filtered.map(data => {
      (data[1] as object)[keyToAdd] = data[0];
      return data;
    });
  }
  return Object.fromEntries(filtered);
};

export const getOutputsData = (
  mappingData,
  outputData,
  filtersType: string[] = [],
  dataType = ''
) => {
  const filterdMetaData = getFilteredData('value', mappingData, filtersType);
  return getFilteredData(
    'key',
    outputData,
    Object.keys(filterdMetaData),
    dataType
  );
};

export const getApiTesterSingleOutputTables = (
  sortAlphabetically,
  xOutput,
  executApiVersion,
  result: DTO.ApiTestResponse | null | DTO.ApiTestResponseV3,
  outputMetadata,
  singleRowMulticellOutputs: string[]
) => {
  let dataSource: DTO.ApiTesterLabelDetailsTable[] = [];
  if (executApiVersion === 'V1') {
    dataSource = result
      ? Object.entries((result as DTO.ApiTestResponse).Outputs)
          .filter(
            ([outputName, outputValue]) =>
              (typeof outputValue !== 'object' || outputValue == null) &&
              outputMetadata[outputName] === 'single'
          )
          .map(
            ([outputName, outputValue]) =>
              ({
                label: outputName,
                details: `${outputValue}`,
              } as DTO.ApiTesterLabelDetailsTable)
          )
      : [];
  } else {
    const singleOutputs = result
      ? JSON.parse(
          JSON.stringify(
            (result as DTO.ApiTestResponseV3)?.response_data?.outputs
          )
        )
      : {};

    for (const key in singleOutputs) {
      if (singleRowMulticellOutputs.includes(key)) {
        delete singleOutputs[key];
      }
    }

    const outputDataField = getOutputsData(outputMetadata, singleOutputs, [
      'single',
    ]) as {
      [name: string]: string | number;
    };

    for (const property in outputDataField) {
      if (property) {
        dataSource.push({
          label: property,
          details: outputDataField[property],
        } as DTO.ApiTesterLabelDetailsTable);
      }
    }
  }

  return {
    name: 'ApiTester.outputs.singleValues',
    columns: [
      {
        title: <FormattedMessage id="ApiTester.outputs.col.label" />,
        dataIndex: 'label',
        key: 'label',
      },
      {
        title: <FormattedMessage id="ApiTester.outputs.col.details" />,
        dataIndex: 'details',
        key: 'details',
        render: data =>
          ['object', 'boolean'].includes(typeof data)
            ? JSON.stringify(data)
            : data,
      },
    ],
    scroll: {
      x: 'max-content',
      y: 275,
    },
    dataSource: dataSource.sort((a, b) =>
      sortInputOutput(sortAlphabetically, xOutput, a.label, b.label)
    ),
  };
};

export const getApiTesterTableOutputTables = (
  sortAlphabetically,
  xOutput,
  executApiVersion,
  result: DTO.ApiTestResponse | null | DTO.ApiTestResponseV3,
  outputMetadata,
  singleRowMulticellOutputs: string[]
) => {
  const outputTables: OutputFormattedTable[] = [];
  if (executApiVersion === 'V1') {
    Object.entries((result as DTO.ApiTestResponse).Outputs)
      .filter(
        ([outputName, outputValue]) =>
          Array.isArray(outputValue) &&
          outputValue.length > 0 &&
          singleRowMulticellOutputs.indexOf(outputName) < 0
      )
      .map(([name, ot]) => {
        const outputTable = (ot as unknown[]).map(data => {
          if (data) {
            return flatten(data);
          }
          return data;
        });
        if (typeof outputTable !== 'object') {
          outputTables.push({
            name,
            columns: [],
            dataSource: [],
          });
        } else if (outputTable && outputTable.length > 0 && outputTable[0]) {
          const columns = Object.keys(outputTable[0] as object[]).map(col => ({
            title: col.split('.').join('\n'),
            dataIndex: col,
            key: col,
            width: 200,
            className: 'just-line-break',
            render: data =>
              ['object', 'boolean'].includes(typeof data)
                ? JSON.stringify(data)
                : data,
          }));
          outputTables.push({
            name,
            columns,
            dataSource: outputTable as object[],
            scroll: {
              x: 'max-content',
              y: 275,
            },
          });
        }
      });
  } else {
    for (const property in outputMetadata) {
      if (
        outputMetadata[property] === 'tables' ||
        (outputMetadata[property] === 'single' &&
          singleRowMulticellOutputs.some(v => v === property))
      ) {
        const tempRes =
          (result as DTO.ApiTestResponseV3)?.response_data?.outputs[property] ||
          [];
        const finalRes =
          outputMetadata[property] === 'tables'
            ? (tempRes as object[]).map(data => {
                if (data) {
                  return flatten(data);
                }
                return data;
              })
            : getTableForSingleRowMuticell(tempRes);
        if (typeof finalRes !== 'object') {
          outputTables.push({
            name: property,
            columns: [],
            dataSource: [],
          } as OutputFormattedTable);
        } else if (finalRes && finalRes.length > 0 && finalRes[0]) {
          const columns = Object.keys(finalRes[0] as object[]).map(col => {
            return {
              title: col.split('.').join('\n'),
              dataIndex: col,
              key: col,
              width: 200,
              className: 'just-line-break',
              render: data =>
                ['object', 'boolean'].includes(typeof data)
                  ? JSON.stringify(data)
                  : data,
            };
          });
          outputTables.push(({
            name: property,
            columns,
            dataSource: finalRes,
            scroll: {
              x: 'max-content',
              y: 275,
            },
          } as unknown) as OutputFormattedTable);
        }
      }
    }
  }

  return outputTables.sort((a, b) =>
    sortInputOutput(sortAlphabetically, xOutput, a.name, b.name)
  );
};

export const getTableForSingleRowMuticell = value => {
  let tempValue;
  if (typeof value === 'string') {
    // This check is for values like "[\"A\", 1, null]" where IsJsonParsable() returns false inside convertListToDictionary()
    tempValue = JSON.parse(value);
  } else {
    tempValue = JSON.parse(JSON.stringify(value));
  }
  const flattenedArray1 = {};
  tempValue.forEach((item, index) => {
    flattenedArray1[index.toString()] = item;
  });
  const parsedValue: unknown[] = [];
  parsedValue.push(flattenedArray1);
  return parsedValue;
};

export const getApiTesterSolvesOutputTables = (
  sortAlphabetically,
  xOutput,
  executApiVersion,
  result: DTO.ApiTestResponse | null | DTO.ApiTestResponseV3,
  outputMetadata
) => {
  const solvesTables: OutputFormattedTable[] = [];
  const dataSource: DTO.SolveDataSource[] = [];
  let columns;
  if (executApiVersion === 'V1') {
    if (
      (result as DTO.ApiTestResponse) &&
      (result as DTO.ApiTestResponse)?.SolveOutputs &&
      (result as DTO.ApiTestResponse)?.SolveOutputs !== undefined
    ) {
      columns = Object.keys(
        (result as DTO.ApiTestResponse)?.SolveOutputs?.[0] || {}
      ).map(col => ({
        title: col,
        dataIndex: col,
        key: col,
        width: 200,
        render: value =>
          ['object', 'boolean'].includes(typeof value)
            ? JSON.stringify(value)
            : value,
      }));

      if (result && (result as DTO.ApiTestResponse).SolveOutputs?.length) {
        solvesTables.push({
          name: 'ApiTester.outputs.solves',
          columns,
          dataSource: (result as DTO.ApiTestResponse).SolveOutputs?.sort(
            (a, b) =>
              sortInputOutput(
                sortAlphabetically,
                xOutput,
                a.SolveName,
                b.SolveName
              )
          ),
          scroll: {
            x: 'max-content',
            y: 275,
          },
        });
      }
    }
  } else {
    for (const property in outputMetadata) {
      if (property) {
        const getSolveData = propertyName => {
          if (
            typeof (result as DTO.ApiTestResponseV3)?.response_data?.outputs?.[
              propertyName
            ] !== 'string'
          ) {
            if (
              isJsonParsable(
                (result as DTO.ApiTestResponseV3)?.response_data?.outputs?.[
                  propertyName
                ]
              )
            ) {
              return JSON.parse(
                (result as DTO.ApiTestResponseV3)?.response_data?.outputs?.[
                  propertyName
                ] as string
              ) as DTO.SolveDataSource;
            }
            return ((result as DTO.ApiTestResponseV3)?.response_data?.outputs?.[
              propertyName
            ] as unknown) as DTO.SolveDataSource;
          }
          return JSON.parse(
            (result as DTO.ApiTestResponseV3)?.response_data?.outputs?.[
              propertyName
            ] as string
          ) as DTO.SolveDataSource;
        };

        if (outputMetadata[property] === 'solves') {
          const data = getSolveData(property);
          const solveData = {
            SolveName: property,
            TargetValue: data.TargetValue,
            ByChangingCellValue: data.ByChangingCellValue,
            Comments: data.Comments,
          };
          columns = Object.keys(solveData).map(col => ({
            title: col,
            dataIndex: col,
            key: col,
            width: 200,
            render: data =>
              ['object', 'boolean'].includes(typeof data)
                ? JSON.stringify(data)
                : data,
          }));
          dataSource.push(solveData);
        }
      }
    }

    if (dataSource.length > 0) {
      solvesTables.push({
        name: 'ApiTester.outputs.solves',
        columns,
        dataSource: dataSource.sort((a, b) =>
          sortInputOutput(sortAlphabetically, xOutput, a.SolveName, b.SolveName)
        ),
        scroll: {
          x: 'max-content',
          y: 275,
        },
      });
    }
  }
  return solvesTables;
};

export const getApiTesterImageOutputTables = (
  sortAlphabetically,
  xOutput,
  executApiVersion,
  result: DTO.ApiTestResponse | null | DTO.ApiTestResponseV3,
  outputMetadata
) => {
  const imagesTables: OutputFormattedTable[] = [];
  const dataSource: DTO.ImageDataSource[] = [];
  const columns = [
    {
      title: <FormattedMessage id="ApiTester.outputs.images.col.name" />,
      dataIndex: 'ImageName',
      key: 'ImageName',
      width: '20%',
    },
    {
      title: <FormattedMessage id="ApiTester.outputs.images.col.url" />,
      dataIndex: 'ImageUrl',
      key: 'ImageUrl',
      width: '40%',
      render: text => (
        <div>
          {/^https?:\/\//i.test(text) ? (
            <a href={text} target="_blank" rel="noopener noreferrer">
              {text}
            </a>
          ) : (
            <span>{text}</span>
          )}
        </div>
      ),
    },
    {
      title: <FormattedMessage id="ApiTester.outputs.images.col.path" />,
      dataIndex: 'ImagePath',
      key: 'ImagePath',
      width: '20%',
    },
    {
      title: <FormattedMessage id="ApiTester.outputs.images.col.file" />,
      dataIndex: 'FileName',
      key: 'FileName',
      width: '20%',
    },
  ];
  if (executApiVersion === 'V1') {
    if (
      (result as DTO.ApiTestResponse) &&
      (result as DTO.ApiTestResponse)?.ImageOutputs &&
      (result as DTO.ApiTestResponse)?.ImageOutputs !== undefined &&
      (result as DTO.ApiTestResponse).ImageOutputs?.length
    ) {
      imagesTables.push({
        name: 'ApiTester.outputs.images',
        columns,
        dataSource: (result as DTO.ApiTestResponse).ImageOutputs?.sort((a, b) =>
          sortInputOutput(sortAlphabetically, xOutput, a.ImageName, b.ImageName)
        ),
        scroll: {
          x: 1000,
          y: 275,
        },
      });
    }
  } else {
    for (const property in outputMetadata) {
      if (property) {
        if (outputMetadata[property] === 'images') {
          if (
            typeof (result as DTO.ApiTestResponseV3)?.response_data?.outputs?.[
              property
            ] !== 'string'
          ) {
            let tempdata: DTO.ImageOutputsV3;
            if (
              isJsonParsable(
                (result as DTO.ApiTestResponseV3)?.response_data?.outputs?.[
                  property
                ]
              )
            ) {
              tempdata = JSON.parse(
                (result as DTO.ApiTestResponseV3)?.response_data?.outputs?.[
                  property
                ] as string
              ) as DTO.ImageOutputsV3;
            } else {
              tempdata = ((result as DTO.ApiTestResponseV3)?.response_data
                ?.outputs?.[property] as unknown) as DTO.ImageOutputsV3;
            }
            dataSource.push({
              ImageName: property,
              ImageUrl: tempdata.ImageUrl,
              ImagePath: '',
              FileName: tempdata.ImageName,
            } as DTO.ImageDataSource);
          } else {
            const tempdata = (result as DTO.ApiTestResponseV3)?.response_data
              ?.outputs?.[property];
            dataSource.push({
              ImageName: property,
              ImageUrl: tempdata,
              ImagePath: '',
              FileName: '',
            } as DTO.ImageDataSource);
          }
        }
      }
    }

    if (dataSource.length > 0) {
      imagesTables.push({
        name: 'ApiTester.outputs.images',
        columns,
        dataSource: dataSource.sort((a, b) =>
          sortInputOutput(sortAlphabetically, xOutput, a.ImageName, b.ImageName)
        ),
        scroll: {
          x: 1000,
          y: 275,
        },
      });
    }
  }
  return imagesTables;
};

export const getApiTesterEngineChainsOutputTables = (
  executApiVersion,
  result: DTO.ApiTestResponse | null | DTO.ApiTestResponseV3,
  _outputMetadata
) => {
  let dataSource: {
    ServiceName: string;
    RequestedReport: string[];
    RequestedReportOutputFileName: string[];
    RunIf: string;
  }[] = [];

  if (
    executApiVersion === 'V1' &&
    (result as DTO.ApiTestResponse)?.EngineChain
  ) {
    dataSource = (result as DTO.ApiTestResponse).EngineChain ?? [];
  }
  if (
    ((result as DTO.ApiTestResponseV3)?.response_data?.service_chain?.length ??
      0) > 0
  ) {
    dataSource =
      (result as DTO.ApiTestResponseV3).response_data.service_chain.map(
        chain => {
          return {
            ServiceName: chain.service_name,
            RunIf: chain.run_if.toString(),
            RequestedReport: chain.requested_report,
            RequestedReportOutputFileName: chain.requested_report_filename,
          };
        }
      ) ?? [];
  }

  if (dataSource.length > 0) {
    return [
      {
        name: 'ApiTester.outputs.engineChain',
        columns: [
          {
            title: (
              <FormattedMessage id="ApiTester.outputs.engineChain.col.serviceName" />
            ),
            dataIndex: 'ServiceName',
            key: 'ServiceName',
          },
        ],
        dataSource: dataSource.sort((a, b) =>
          a.ServiceName.toLowerCase().localeCompare(b.ServiceName.toLowerCase())
        ),
      },
    ];
  }

  return [];
};

export const getApiTesterCsvValuesOutputTables = (
  sortAlphabetically,
  xOutput,
  executApiVersion,
  result: DTO.ApiTestResponse | null | DTO.ApiTestResponseV3,
  outputMetadata
) => {
  const csvValuesTable: OutputFormattedTable[] = [];
  const dataSource: DTO.ApiTesterLabelDetailsTable[] = [];
  const columns = [
    {
      title: <FormattedMessage id="ApiTester.outputs.col.label" />,
      dataIndex: 'label',
      key: 'label',
      width: '100px',
    },
    {
      title: <FormattedMessage id="ApiTester.outputs.col.details" />,
      dataIndex: 'details',
      key: 'details',
      render: data =>
        ['object', 'boolean'].includes(typeof data)
          ? JSON.stringify(data)
          : data,
    },
  ];
  for (const property in outputMetadata) {
    if (property) {
      if (outputMetadata[property] === 'csv') {
        dataSource.push({
          label: property,
          details:
            executApiVersion === 'V1'
              ? ((result as DTO.ApiTestResponse)?.Outputs?.[property] as string)
              : ((result as DTO.ApiTestResponseV3)?.response_data?.outputs?.[
                  property
                ] as string),
        });
      }
    }
  }

  if (dataSource.length > 0) {
    csvValuesTable.push({
      name: 'ApiTester.outputs.csvValues',
      columns,
      dataSource: dataSource.sort((a, b) =>
        sortInputOutput(sortAlphabetically, xOutput, a.label, b.label)
      ),
      scroll: { x: 1000, y: 275 },
    });
  }
  return csvValuesTable;
};

export const getApiTesterReportOutputTables = (
  sortAlphabetically,
  xOutput,
  executApiVersion,
  result: DTO.ApiTestResponse | null | DTO.ApiTestResponseV3,
  outputMetadata
) => {
  const reportsTable: OutputFormattedTable[] = [];
  const dataSource: DTO.ReportOutputs[] = [];
  const columns = [
    {
      title: <FormattedMessage id="ApiTester.outputs.report.col.reportName" />,
      dataIndex: 'ReportName',
      key: 'ReportName',
      width: '250px',
    },
    {
      title: <FormattedMessage id="ApiTester.outputs.report.col.reportUrl" />,
      dataIndex: 'ReportUrl',
      key: 'ReportUrl',
      width: '400px',

      render: text => (
        <div>
          {/^https?:\/\//i.test(text) ? (
            <StyledEllipseAnchor
              href={text}
              target="_blank"
              rel="noopener noreferrer"
            >
              {text}
            </StyledEllipseAnchor>
          ) : (
            <span>{text}</span>
          )}
        </div>
      ),
    },
    {
      title: <FormattedMessage id="ApiTester.outputs.report.col.fileName" />,
      dataIndex: 'FileName',
      key: 'FileName',
      width: '400px',
    },
    {
      title: (
        <FormattedMessage id="ApiTester.outputs.report.col.tempDocGenerationTime" />
      ),
      dataIndex: 'TempDocumentGenerationTime',
      key: 'TempDocumentGenerationTime',

      width: '250px',
    },
    {
      title: (
        <FormattedMessage id="ApiTester.outputs.report.col.reportGenerationTime" />
      ),
      dataIndex: 'ReportGenerationTime',
      key: 'ReportGenerationTime',

      width: '250px',
    },
  ];
  if (executApiVersion === 'V1') {
    if (
      (result as DTO.ApiTestResponse) &&
      (result as DTO.ApiTestResponse)?.ReportOutputs &&
      (result as DTO.ApiTestResponse)?.ReportOutputs !== undefined &&
      (result as DTO.ApiTestResponse)?.ReportOutputs?.length
    ) {
      reportsTable.push({
        name: 'ApiTester.outputs.reports',
        columns,
        dataSource: (result as DTO.ApiTestResponse).ReportOutputs?.sort(
          (a, b) =>
            sortInputOutput(
              sortAlphabetically,
              xOutput,
              a.ReportName,
              b.ReportName
            )
        ),
        scroll: {
          x: 'max-content',
          y: 275,
        },
      });
    }
  } else {
    for (const property in outputMetadata) {
      if (property) {
        if (outputMetadata[property] === 'reports') {
          if (
            typeof (result as DTO.ApiTestResponseV3)?.response_data?.outputs?.[
              property
            ] !== 'string'
          ) {
            let tempdata: DTO.ReportOutputsV3;
            if (
              isJsonParsable(
                (result as DTO.ApiTestResponseV3)?.response_data?.outputs?.[
                  property
                ]
              )
            ) {
              tempdata = JSON.parse(
                (result as DTO.ApiTestResponseV3)?.response_data?.outputs?.[
                  property
                ] as string
              ) as DTO.ReportOutputsV3;
            } else {
              tempdata = ((result as DTO.ApiTestResponseV3)?.response_data
                ?.outputs?.[property] as unknown) as DTO.ReportOutputsV3;
            }
            dataSource.push({
              ReportName: property,
              ReportUrl: tempdata?.PDFUrl || '',
              ReportPath: '',
              FileName: tempdata?.PDFName || '',
              TempDocumentGenerationTime: tempdata?.DocumentGenerationTime,
              ReportGenerationTime: tempdata?.PDFGenerationTime || '',
            } as DTO.ReportOutputs);
          } else {
            const tempdata = (result as DTO.ApiTestResponseV3)?.response_data
              ?.outputs?.[property];
            dataSource.push({
              ReportName: property,
              ReportUrl: tempdata,
              ReportPath: '',
              FileName: '',
              TempDocumentGenerationTime: '',
              ReportGenerationTime: '',
            } as DTO.ReportOutputs);
          }
        }
      }
    }

    if (dataSource.length > 0) {
      reportsTable.push({
        name: 'ApiTester.outputs.reports',
        columns,
        dataSource: dataSource.sort((a, b) =>
          sortInputOutput(
            sortAlphabetically,
            xOutput,
            a.ReportName,
            b.ReportName
          )
        ),
        scroll: {
          x: 'max-content',
          y: 275,
        },
      });
    }
  }
  return reportsTable;
};

export const getApiTesterErrorOutputTables = (
  executApiVersion,
  result: DTO.ApiTestResponse | null | DTO.ApiTestResponseV3,
  _outputMetadata
) => {
  const ErrorsTable: OutputFormattedTable[] = [];
  let dataSource: DTO.ApiTesterErrorsTable[] = [];
  const columns = [
    {
      title: <FormattedMessage id="ApiTester.outputs.col.label" />,
      dataIndex: 'label',
      key: 'label',
    },
    {
      title: <FormattedMessage id="ApiTester.outputs.col.details" />,
      dataIndex: 'details',
      key: 'details',
      render: data =>
        ['object', 'boolean'].includes(typeof data)
          ? JSON.stringify(data)
          : data,
    },
    {
      title: <FormattedMessage id="ApiTester.outputs.col.additionalDetails" />,
      dataIndex: 'additionalDetails',
      render: text =>
        text && (
          <FormattedMessage
            id="ApiTester.outputs.errors.additionalDetails"
            values={{ serviceName: <b>{`"${text}"`}</b> }}
          />
        ),
    },
  ];
  if (executApiVersion === 'V1') {
    dataSource = result
      ? (result as DTO.ApiTestResponse)?.Errors.map(
          error =>
            ({
              label: error.SourcePath,
              details: error.Message,
              additionalDetails: error.AdditionalDetails,
            } as DTO.ApiTesterErrorsTable)
        )
      : [];
    ErrorsTable.push({
      name: 'ApiTester.outputs.errors',
      messageValues: { count: dataSource?.length || 0 },
      columns,
      dataSource,
      scroll: {
        y: 275,
      },
    });
  } else {
    dataSource = result
      ? (result as DTO.ApiTestResponseV3).response_data?.errors?.map(
          error =>
            ({
              label: error.source_path,
              details: error.message,
              additionalDetails: error.additional_details,
            } as DTO.ApiTesterErrorsTable)
        )
      : [];
    ErrorsTable.push({
      name: 'ApiTester.outputs.errors',
      messageValues: { count: dataSource?.length || 0 },
      columns,
      dataSource,
      scroll: {
        y: 275,
      },
    });
  }
  return ErrorsTable;
};

export const getApiTesterWaringsOutputTables = (
  executApiVersion,
  result: DTO.ApiTestResponse | null | DTO.ApiTestResponseV3,
  _outputMetadata
) => {
  const WaringsTable: OutputFormattedTable[] = [];
  let dataSource: DTO.ApiTesterLabelDetailsTable[] = [];
  const columns = [
    {
      title: <FormattedMessage id="ApiTester.outputs.col.label" />,
      dataIndex: 'label',
      key: 'label',
    },
    {
      title: <FormattedMessage id="ApiTester.outputs.col.details" />,
      dataIndex: 'details',
      key: 'details',
      render: data =>
        ['object', 'boolean'].includes(typeof data)
          ? JSON.stringify(data)
          : data,
    },
  ];
  if (executApiVersion === 'V1') {
    dataSource =
      result && (result as DTO.ApiTestResponse)?.Warnings
        ? (result as DTO.ApiTestResponse)?.Warnings.map(
            warning =>
              ({
                label: warning.Key,
                details: warning.Value,
              } as DTO.ApiTesterLabelDetailsTable)
          )
        : [];
    WaringsTable.push({
      name: 'ApiTester.outputs.warnings',
      messageValues: { count: dataSource?.length || 0 },
      columns,
      dataSource,
      scroll: {
        y: 275,
      },
    });
  } else {
    dataSource = result
      ? (result as DTO.ApiTestResponseV3).response_data?.warnings?.map(
          warning =>
            ({
              label: warning.source_path,
              details: warning.message,
            } as DTO.ApiTesterLabelDetailsTable)
        )
      : [];
    WaringsTable.push({
      name: 'ApiTester.outputs.warnings',
      messageValues: { count: dataSource?.length || 0 },
      columns,
      dataSource,
      scroll: {
        y: 275,
      },
    });
  }
  return WaringsTable;
};

export const getApiTesterTransactionsDetailsOutputTables = (
  executApiVersion,
  result: DTO.ApiTestResponse | null | DTO.ApiTestResponseV3,
  _outputMetadata
) => {
  const TransactionsDetailsTable: OutputFormattedTable[] = [];
  let dataSource: DTO.ApiTesterLabelDetailsTable[] = [];
  const columns = [
    {
      title: <FormattedMessage id="ApiTester.outputs.col.label" />,
      dataIndex: 'label',
      key: 'label',
    },
    {
      title: <FormattedMessage id="ApiTester.outputs.col.details" />,
      dataIndex: 'details',
      key: 'details',
      render: data =>
        ['object', 'boolean'].includes(typeof data)
          ? JSON.stringify(data)
          : data,
    },
  ];
  if (executApiVersion === 'V1') {
    dataSource = result
      ? Object.entries(result || {})
          .filter(([_, value]) => typeof value !== 'object')
          .sort((a, b) => {
            if (a < b) return -1;
            if (a > b) return 1;
            return 0;
          })
          .map(([key, value]) => ({
            label: key,
            details: value,
          }))
      : [];
    TransactionsDetailsTable.push({
      name: 'ApiTester.outputs.transaction',
      columns,
      dataSource,
      scroll: {
        y: 275,
      },
    });
  } else {
    dataSource = result
      ? Object.entries((result as DTO.ApiTestResponseV3).response_meta || {})
          .sort((a, b) => {
            if (a < b) return -1;
            if (a > b) return 1;
            return 0;
          })
          .map(
            ([key, value]) =>
              ({
                label: key,
                details: value,
              } as DTO.ApiTesterLabelDetailsTable)
          )
      : [];
    TransactionsDetailsTable.push({
      name: 'ApiTester.outputs.transaction',
      columns,
      dataSource,
      scroll: {
        y: 275,
      },
    });
  }
  return TransactionsDetailsTable;
};

export const isOutputPanelDisabled = (name: string, count?: number) => {
  return (
    (['ApiTester.outputs.warnings', 'ApiTester.outputs.errors'].includes(
      name
    ) && count === 0) === true
  );
};

export const getInputDataFromExecuteV3Result = (
  inputs: {
    [name: string]: string | number | {} | [];
  },
  mappedData: { [name: string]: string[] },
  filterType: string[]
) => {
  const tempData: (
    | object
    | DTO.XReportInputRequest
    | DTO.XImageInputRequest
    | DTO.XSolveInputRequest
  )[] = [];
  filterType.forEach(type => {
    if (mappedData[type]) {
      mappedData[type].forEach(datakey => {
        if (inputs[datakey]) {
          if (type === 'single' || type === 'csv' || type === 'tables') {
            const emptyObj = {};
            emptyObj[datakey] = inputs[datakey];
            tempData.push(emptyObj);
          }
          if (type === 'reports') {
            tempData.push({
              ReportName: datakey,
              FileName: (inputs[datakey] as DTO.XReportInputRequest).FileName,
            });
          }
          if (type === 'images') {
            tempData.push({
              ImageName: datakey,
              FileName: (inputs[datakey] as DTO.XImageInputRequest).FileName,
            });
          }
          if (type === 'solves') {
            tempData.push(inputs[datakey] as DTO.XSolveInputRequest);
          }
        }
      });
    }
  });
  return tempData;
};

export const getStrValue = (val, fieldDF) => {
  const type = Boolean(
    fieldDF && fieldDF['type'] === 'XJ' && fieldDF['xjName']
  );
  try {
    return type ? JSON.stringify(val) : val;
  } catch (e) {
    return val;
  }
};
export const getObjValue = (val, fieldDF) => {
  const type = Boolean(
    fieldDF && fieldDF['type'] === 'XJ' && fieldDF['xjName']
  );
  let newVal = val;
  let isUpdate = true;
  if (type) {
    try {
      newVal = JSON.parse(val);
      isUpdate = true;
    } catch (e) {
      isUpdate = false;
    }
  }
  return { newVal, isUpdate };
};

export const checkSubServiceInput = (input, subServiceList) => {
  const isSubserviceInput = input.name.includes('.');
  if (isSubserviceInput) {
    const subName = input.name.split('.')[0];
    return subServiceList.includes(subName);
  }
  return subServiceList.includes('#Default#');
};

export const detectChangeSubService = (
  reqServiceCategory,
  changeServiceCategory
) => {
  const changeObj: {
    add: string[];
    remove: string[];
  } = {
    add: [],
    remove: [],
  };

  if (
    reqServiceCategory !== undefined &&
    reqServiceCategory !== null &&
    changeServiceCategory !== undefined &&
    changeServiceCategory !== null
  ) {
    const reqSubArr = reqServiceCategory.split(',');
    const changeSubArr = changeServiceCategory.split(',');
    const add = _.difference(changeSubArr, reqSubArr);
    if (add && add.length) {
      changeObj.add = add as string[];
    }
    const remove = _.difference(reqSubArr, changeSubArr);
    if (remove && remove.length) {
      changeObj.remove = remove as string[];
    }
  }
  changeObj.add = changeObj.add.filter(item => item);
  changeObj.remove = changeObj.remove.filter(item => item);

  return changeObj;
};

export const subServiceBuildInputsV3 = (
  prevServiceCategory: string,
  currentServiceCategory: string,
  inputsList: { [key: string]: string | number | string[] | object | object[] },
  selectedEngine: DTO.EngineData | null
) => {
  const changeSubServiceObj: {
    add: string[];
    remove: string[];
  } = detectChangeSubService(prevServiceCategory, currentServiceCategory);

  let inputs: {
    [key: string]: string | number | string[] | object | object[];
  } = inputsList;

  if (changeSubServiceObj && changeSubServiceObj.add.length > 0) {
    const addInputs = getInputsOutputsFromV1Result(
      changeSubServiceObj.add.join(','),
      selectedEngine,
      'xlInputs'
    );
    const otherInputs: {
      solve: DTO.XSolveInputRequest[];
      image: DTO.XImageInputRequest[];
      report: DTO.XReportInputRequest[];
    } = convertListToDictionaryForOtherInputs(
      selectedEngine,
      changeSubServiceObj.add.join(',')
    );

    const addImageInput = otherInputs.image.reduce((a, v) => {
      return { ...a, ...{ [v.ImageName || '']: { FileName: v.FileName } } };
    }, {});

    const addReportInput = otherInputs.report.reduce(
      (a, v) => ({
        ...a,
        ...{ [v.ReportName || '']: { FileName: v.FileName } },
      }),
      {}
    );
    inputs = {
      ...inputs,
      ...addInputs,
      ...addImageInput,
      ...addReportInput,
    };
  }

  if (changeSubServiceObj && changeSubServiceObj.remove.length > 0) {
    const removeInputs = getInputsOutputsFromV1Result(
      changeSubServiceObj.remove.join(','),
      selectedEngine,
      'xlInputs'
    );
    for (const rm in removeInputs) {
      if (Object.prototype.hasOwnProperty.call(removeInputs, rm)) {
        delete inputs[rm];
      }
    }

    const otherInputs: {
      solve: DTO.XSolveInputRequest[];
      image: DTO.XImageInputRequest[];
      report: DTO.XReportInputRequest[];
    } = convertListToDictionaryForOtherInputs(
      selectedEngine,
      changeSubServiceObj.remove.join(',')
    );

    const removeImageInput = otherInputs.image.reduce(
      (a, v) => ({ ...a, [v.ImageName || '']: { FileName: v.FileName } }),
      {}
    );
    for (const rm in removeImageInput) {
      if (Object.prototype.hasOwnProperty.call(removeImageInput, rm)) {
        delete inputs[rm];
      }
    }

    const removeReportInput = otherInputs.report.reduce(
      (a, v) => ({ ...a, [v.ReportName || '']: { FileName: v.FileName } }),
      {}
    );
    for (const rm in removeReportInput) {
      if (Object.prototype.hasOwnProperty.call(removeReportInput, rm)) {
        delete inputs[rm];
      }
    }
  }

  return inputs;
};

export const subServiceBuildInputsV1 = (
  prevServiceCategory: string,
  currentServiceCategory: string,
  inputObj: Partial<DTO.ExcelEngineRequest>,
  selectedEngine: DTO.EngineData | null
) => {
  const changeSubServiceObj: {
    add: string[];
    remove: string[];
  } = detectChangeSubService(prevServiceCategory, currentServiceCategory);

  let inputV1: {
    Inputs: {
      [key: string]: string | number | boolean | string[] | object | object[];
    };
    Solves: DTO.XSolveInputRequest[];
    Images: DTO.XImageInputRequest[];
    Reports: DTO.XReportInputRequest[];
  } = {
    Inputs: _.cloneDeep(inputObj?.Inputs) || {},
    Solves: _.cloneDeep(inputObj?.Solves) || [],
    Images: _.cloneDeep(inputObj?.Images) || [],
    Reports: _.cloneDeep(inputObj?.Reports) || [],
  };

  if (changeSubServiceObj && changeSubServiceObj.add.length > 0) {
    const addInputs = getInputsOutputsFromV1Result(
      changeSubServiceObj.add.join(','),
      selectedEngine,
      'xlInputs'
    );
    const otherInputs: {
      solve: DTO.XSolveInputRequest[];
      image: DTO.XImageInputRequest[];
      report: DTO.XReportInputRequest[];
    } = convertListToDictionaryForOtherInputs(
      selectedEngine,
      changeSubServiceObj.add.join(',')
    );

    inputV1 = {
      Inputs: { ...inputV1.Inputs, ...addInputs },
      Solves: [...inputV1.Solves, ...otherInputs.solve],
      Images: [...inputV1.Images, ...otherInputs.image],
      Reports: [...inputV1.Reports, ...otherInputs.report],
    };
  }

  if (changeSubServiceObj && changeSubServiceObj.remove.length > 0) {
    const removeInputs = getInputsOutputsFromV1Result(
      changeSubServiceObj.remove.join(','),
      selectedEngine,
      'xlInputs'
    );
    for (const rm in removeInputs) {
      if (Object.prototype.hasOwnProperty.call(removeInputs, rm)) {
        delete inputV1.Inputs[rm];
      }
    }

    const otherInputs: {
      solve: DTO.XSolveInputRequest[];
      image: DTO.XImageInputRequest[];
      report: DTO.XReportInputRequest[];
    } = convertListToDictionaryForOtherInputs(
      selectedEngine,
      changeSubServiceObj.remove.join(',')
    );

    let Images = inputV1.Images;
    otherInputs.image.forEach(image => {
      Images = arrayRemoveFirst(
        Images,
        item => item.ImageName === image.ImageName
      );
    });

    let Solves = inputV1.Solves;
    otherInputs.solve.forEach(solve => {
      Solves = arrayRemoveFirst(
        Solves,
        item => item.SolveName === solve.SolveName
      );
    });

    let Reports = inputV1.Reports;
    otherInputs.report.forEach(report => {
      Reports = arrayRemoveFirst(
        Reports,
        item => item.ReportName === report.ReportName
      );
    });

    inputV1 = {
      Inputs: inputV1.Inputs,
      Solves,
      Images,
      Reports,
    };
  }
  return inputV1;
};

export const resetApiTesterInputV3 = (
  requestedServiceCategory: string,
  selectedEngine: DTO.EngineData | null
) => {
  const addInputs = getInputsOutputsFromV1Result(
    requestedServiceCategory,
    selectedEngine,
    selectedEngine?.oldXlInputs ? 'oldXlInputs' : 'xlInputs'
  );

  const otherInputs: {
    solve: DTO.XSolveInputRequest[];
    image: DTO.XImageInputRequest[];
    report: DTO.XReportInputRequest[];
  } = convertListToDictionaryForOtherInputs(
    selectedEngine,
    requestedServiceCategory
  );

  const addImageInput = otherInputs.image.reduce(
    (a, v) => ({ ...a, [v.ImageName || '']: { FileName: v.FileName } }),
    {}
  );
  const addReportInput = otherInputs.report.reduce(
    (a, v) => ({ ...a, [v.ReportName || '']: { FileName: v.FileName } }),
    {}
  );

  return {
    ...addInputs,
    ...addImageInput,
    ...addReportInput,
  };
};

export const resetApiTesterInputV1 = (
  requestedServiceCategory: string,
  selectedEngine: DTO.EngineData | null
) => {
  const addInputs = getInputsOutputsFromV1Result(
    requestedServiceCategory,
    selectedEngine,
    selectedEngine?.oldXlInputs ? 'oldXlInputs' : 'xlInputs'
  );
  const otherInputs: {
    solve: DTO.XSolveInputRequest[];
    image: DTO.XImageInputRequest[];
    report: DTO.XReportInputRequest[];
  } = convertListToDictionaryForOtherInputs(
    selectedEngine,
    requestedServiceCategory
  );

  return {
    Inputs: { ...addInputs },
    Solves: [...otherInputs.solve],
    Images: [...otherInputs.image],
    Reports: [...otherInputs.report],
  };
};

export const getInputData = (Inputs, persistInputs) => {
  const tempData = {};
  Object.keys(Inputs).forEach(input => {
    if (persistInputs && persistInputs[input] !== undefined) {
      const inputType = typeof Inputs[input];
      const persistInputValue = persistInputs[input];

      if (inputType === 'object') {
        if (Inputs[input] == null && persistInputValue === '') {
          tempData[input] = '';
        } else if (Array.isArray(Inputs[input])) {
          tempData[input] = Array.isArray(persistInputs[input])
            ? persistInputValue
            : [persistInputValue];
        } else {
          try {
            tempData[input] = JSON.parse(persistInputValue);
          } catch {
            tempData[input] = persistInputValue;
          }
        }
      } else if (inputType === 'string') {
        tempData[input] = persistInputs[input]?.toString();
      } else if (inputType === 'number') {
        tempData[input] = parseFloat(persistInputValue);
      } else if (inputType === 'boolean') {
        tempData[input] =
          persistInputValue === 'true' || persistInputValue === true;
      } else {
        tempData[input] = persistInputValue;
      }
    } else {
      tempData[input] = Inputs[input];
    }
  });
  return tempData;
};

export const sortInputOutput = (
  sortAlphabetically: boolean,
  inputData: DTO.XSingelCellNameRange[],
  a: string,
  b: string
) => {
  // Sort Alphabetically
  if (sortAlphabetically) {
    // If sorting alphabetically, use case-insensitive comparison of 'a' and 'b'
    return (a.toLowerCase() || '').localeCompare(b.toLowerCase() || '');
  }

  const firstPartA = a.split('.')[0];
  const firstPartB = b.split('.')[0];

  const hasDotA = a.includes('.');
  const hasDotB = b.includes('.');

  // Sort by the first part of the name if both names contain a dot
  if (hasDotA && hasDotB) {
    if (firstPartA < firstPartB) {
      return -1;
    }
    if (firstPartA > firstPartB) {
      return 1;
    }
  }

  // If 'a' has a dot, and 'b' does not, 'a' comes before 'b'
  if (hasDotA && !hasDotB) {
    return -1;
  }
  // If 'b' has a dot, and 'a' does not, 'b' comes before 'a'
  if (!hasDotA && hasDotB) {
    return 1;
  }

  const aInput = inputData.find(
    data => data.name.toLowerCase() === a.toLowerCase()
  );

  const bInput = inputData.find(
    data => data.name.toLowerCase() === b.toLowerCase()
  );

  // If 'a' is found in inputData and 'b' is not, 'a' comes before 'b'
  if (aInput && !bInput) {
    return -1;
  }

  // If 'b' is found in inputData and 'a' is not, 'b' comes before 'a'
  if (!aInput && bInput) {
    return 1;
  }

  if (aInput && bInput) {
    // If the first parts are equal, sort by sheet index
    if (aInput.sheetIndex !== bInput.sheetIndex) {
      return aInput.sheetIndex - bInput.sheetIndex;
    }

    // If the sheets are equal, sort by column index
    if (aInput.columnIndex !== bInput.columnIndex) {
      return aInput.columnIndex - bInput.columnIndex;
    }

    // If the columns are equal, sort by row index
    return aInput.rowIndex - bInput.rowIndex;
  }

  // Both 'a' and 'b' are not found in inputData
  // Sort them to appear last
  return 1; // Change to -1 if you want them to appear first
};

export const findObjectKeyChanges = (obj1, obj2) => {
  const result: {
    addedKeys: string[];
    removedKeys: string[];
    equalKeysWithoutEqualValue: string[];
    equalKeysWithEqualValue: string[];
  } = {
    addedKeys: [],
    removedKeys: [],
    equalKeysWithoutEqualValue: [],
    equalKeysWithEqualValue: [],
  };

  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  keys1.forEach(key => {
    if (!keys2.includes(key)) {
      result.removedKeys.push(key);
    } else if (!_.isEqual(obj1[key], obj2[key])) {
      result.equalKeysWithoutEqualValue.push(key);
    } else {
      result.equalKeysWithEqualValue.push(key);
    }
  });

  keys2.forEach(key => {
    if (!keys1.includes(key)) {
      result.addedKeys.push(key);
    }
  });

  return result;
};

export const IsInputsDataValidationUpdateRequired = (
  inputs1 = {},
  inputs2 = {},
  dataValidationV3: DTO.ApiTestResponseV3 | null
) => {
  if (!dataValidationV3?.response_data?.outputs) {
    return false;
  }
  const isInputEqual = _.isEqual(inputs1, inputs2);

  if (!isInputEqual) {
    const keyChanges = findObjectKeyChanges(inputs1, inputs2);

    let hasDependentInputs = false;
    [
      ...keyChanges.addedKeys,
      ...keyChanges.removedKeys,
      ...keyChanges.equalKeysWithoutEqualValue,
    ].forEach(inputKey => {
      const inputValidation = dataValidationV3?.response_data.outputs[
        inputKey
      ] as DTO.InputDataValidationDetails;

      if (
        inputValidation?.dependent_inputs &&
        inputValidation?.dependent_inputs?.length > 0
      ) {
        hasDependentInputs = true;
      }
    });
    if (hasDependentInputs) {
      return true;
    }
  }
  return false;
};
