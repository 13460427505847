import { UserConstants } from '../constants';
import { getConfig } from './config';
import { ApiError } from './errors';
import fetchNetworkErrorWrap from './fetchNetworkErrorWrap';
import { store } from './store';
import { decodeToken, refresh } from './user-auth';

const makeRequest = async <T = {}>(
  method: string,
  url: string,
  // Accept all types for body
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  body?: any,
  contentType = 'application/json',
  query?,
  options?,
  returnBlob?: boolean,
  ignoreTenantHeader?: boolean,
  origin?: string,
  isDataFormUrlEncoded?: boolean
): Promise<DTO.ApiResponse<T>> => {
  const config = getConfig();
  const { userAuth } = store.getState().auth;
  const tenant = localStorage.getItem('Tenant');
  const headerTenant = ignoreTenantHeader
    ? {}
    : {
        'x-tenant-name': tenant || config.defaultTenant || '',
      };
  const headerContentType = {
    'Content-Type': contentType,
  };
  const headerAuth =
    userAuth && userAuth.accessToken
      ? {
          Authorization: `Bearer ${userAuth.accessToken}`,
        }
      : {};
  const headerApiGatewayApiVersion = ignoreTenantHeader
    ? {}
    : {
        apiVersion: config.apiGatewayApiVersion,
      };

  const headerApiGatewaySubscriptionKey = ignoreTenantHeader
    ? {}
    : {
        'ocp-apim-subscription-key': config.apiGatewaySubscriptionKey,
      };

  const headerOrigin = origin
    ? {}
    : {
        Origin: origin,
      };

  const requestHeaders = {
    ...headerContentType,
    ...headerAuth,
    ...headerTenant,
    ...headerApiGatewayApiVersion,
    ...headerApiGatewaySubscriptionKey,
    ...headerOrigin,
  };
  const decodedJwt =
    (userAuth && userAuth.accessToken && decodeToken(userAuth.accessToken)) ||
    undefined;

  if (
    decodedJwt &&
    decodedJwt['exp'] &&
    decodedJwt['exp'] * 1000 < Date.now()
  ) {
    const newIdToken = await refresh(store);
    if (newIdToken) {
      requestHeaders.Authorization = `Bearer ${newIdToken}`;
    }
  }

  if (body && isDataFormUrlEncoded !== true) {
    if (body instanceof FormData) {
      delete requestHeaders['Content-Type'];
    } else {
      body = JSON.stringify(body);
    }
  }

  const requestOptions = {
    method,
    headers: requestHeaders,
    body,
    query,
    ...options,
  };

  let res: Response | null = null;
  try {
    console.log('make-request:: ' + url);
    res = await fetchNetworkErrorWrap(url, requestOptions);
  } catch (err) {
    // in case of network error or unable to connect to server
    res = null;
  }
  if (res) {
    const sortContentType = res.headers.get('Content-Type');
    const { status, headers } = res;

    if (
      (status === 200 || status === 304 || status === 201) &&
      (returnBlob ||
        requestHeaders['Content-Type'] === 'application/octet-stream' ||
        (sortContentType &&
          sortContentType.indexOf('application/pdf') !== -1) ||
        (sortContentType && sortContentType.indexOf('application/vnd.') !== -1))
    ) {
      const blob = await res.blob();
      const contentDisposition = headers.get('Content-Disposition');
      let fileName: string | null = null;
      if (
        contentDisposition &&
        contentDisposition.split('filename').length > 1
      ) {
        fileName = contentDisposition
          .split('filename')[1]
          .replace('"', '')
          .replace('"', '')
          .replace(';', '')
          .replace('=', '')
          .trim();
      }

      return {
        status,
        payload: { blob, blobName: fileName } as T & {
          blob: Blob;
          blobName: string;
        },
        headers,
      };
    }
    try {
      let payload;
      if (sortContentType && sortContentType.indexOf('text/html') !== -1) {
        payload = await res.text();
      } else {
        payload = await res.json();
      }
      return {
        status,
        payload,
        headers,
      };
    } catch (error) {
      if (status === 401) {
        throw new ApiError({
          error_code: UserConstants.UNAUTHORIZED_ACCESS,
        });
      } else if (status === 403) {
        throw new ApiError({
          error_code: UserConstants.FORBIDDEN_ERROR,
        });
      }
      throw new ApiError({
        error_code: UserConstants.UNABLE_TO_PARSE_RESPONSE,
      });
    }
  }
  throw new ApiError({
    error_code: UserConstants.UNABLE_TO_CONNECT,
  });
};

export default makeRequest;
