import React, { memo, useContext, useState, useEffect } from 'react';
import { ThemeContext } from 'styled-components';
import { colorHexToRgba } from '../../helpers';
import { ProgressBar } from '../Common';

enum ProgressState {
  Default,
  Progress,
  Success,
  Error,
}

type FileUploadProgressProps = {
  progress: number;
  hasError: boolean;
  defaultTitle?: string;
  defaultTitleOpacity?: number;
  defaultTitleColor?: string;
  defaultIconColor?: string;
  defaultIconColorOpacity?: number;
  showDefaultProgressIcon?: boolean;
  successProgressColor?: string;
  progressingColor?: string;
  progressTitle?: string;
  successTitle?: string;
  errorTitle?: string;
  vertical?: boolean;
};

const FileUploadProgress: React.FC<FileUploadProgressProps> = props => {
  const {
    defaultTitle = 'EngineUploader.convert.progress',
    progressTitle = 'FileUpload.progress.progress.label',
    successTitle = 'FileUpload.progress.success.label',
    errorTitle = 'FileUpload.progress.error.label',
    progress,
    hasError,
    vertical,
    defaultTitleOpacity,
    defaultTitleColor,
    progressingColor,
    showDefaultProgressIcon,
  } = props;
  const [progressState, setProgressState] = useState<ProgressState>(
    ProgressState.Default
  );
  const themeContext = useContext(ThemeContext);
  const [title, setTitle] = useState(defaultTitle);
  const [textColor, setTextColor] = useState(
    colorHexToRgba(themeContext.color.white, 0)
  );
  const [textColorOpacity, setTextColorOpacity] = useState<number>();
  const [progressColor, setProgressColor] = useState(
    colorHexToRgba(themeContext.color.purple, 0)
  );

  useEffect(() => {
    let nextProgressState = hasError
      ? ProgressState.Error
      : ProgressState.Progress;
    let resolvedTitle = hasError ? errorTitle : progressTitle;

    if (progress === 100) {
      if (hasError) {
        nextProgressState = ProgressState.Error;
        resolvedTitle = errorTitle;
      } else {
        nextProgressState = ProgressState.Success;
        resolvedTitle = successTitle;
      }
    } else if (progress === 0) {
      nextProgressState = ProgressState.Default;
      resolvedTitle = defaultTitle;
    }

    setProgressState(nextProgressState);
    setTitle(resolvedTitle);
  }, [
    progress,
    hasError,
    errorTitle,
    progressTitle,
    defaultTitle,
    successTitle,
  ]);

  React.useEffect(() => {
    let nextTextColor = '';
    let nextProgressColor = '';
    let nextTextColorOpacity = 0;

    switch (progressState) {
      case ProgressState.Success:
        nextTextColor = themeContext.color.success;
        nextProgressColor = themeContext.color.success;

        break;
      case ProgressState.Progress:
        nextTextColor = themeContext.color.purple;
        nextProgressColor = progressingColor || themeContext.color.purple;
        break;
      case ProgressState.Error:
        nextTextColor = themeContext.color.red;
        nextProgressColor = themeContext.color.red;

        break;
      default:
        nextTextColor =
          defaultTitleColor || colorHexToRgba(themeContext.color.dark, 0);
        nextTextColorOpacity = defaultTitleOpacity || 1;
        nextProgressColor = colorHexToRgba(themeContext.color.purple, 0);
        break;
    }
    setTextColor(nextTextColor);
    setProgressColor(nextProgressColor);
    setTextColorOpacity(nextTextColorOpacity);
  }, [progressState, themeContext]);
  return (
    <ProgressBar
      title={title}
      progress={progress}
      textColor={textColor}
      textColorOpacity={textColorOpacity}
      progressColor={progressColor}
      vertical={vertical}
      className="progress-bar"
      showDefaultProgressIcon={showDefaultProgressIcon}
    />
  );
};

export default memo(FileUploadProgress);
