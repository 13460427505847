export const GET_ENGINE_VIEW_LOGS = 'GET_ENGINE_VIEW_LOGS';
export const GET_ENGINE_VIEW_LOGS_SUCCESS = 'GET_ENGINE_VIEW_LOGS_SUCCESS';
export const GET_ENGINE_VIEW_LOGS_FAILURE = 'GET_ENGINE_VIEW_LOGS_FAILURE';
export const ENGINE_LOGS_RESET = 'ENGINE_LOGS_RESET';
export const DOWNLOAD_ENGINE_LOGS_REQUEST = 'DOWNLOAD_ENGINE_LOGS_REQUEST';
export const DOWNLOAD_ENGINE_LOGS_SUCCESS = 'DOWNLOAD_ENGINE_LOGS_SUCCESS';
export const DOWNLOAD_ENGINE_LOGS_FAILURE = 'DOWNLOAD_ENGINE_LOGS_FAILURE';
export const DOWNLOAD_MULTIPLE_LOGS_REQUEST = 'DOWNLOAD_MULTIPLE_LOGS_REQUEST';
export const DOWNLOAD_MULTIPLE_LOGS_SUCCESS = 'DOWNLOAD_MULTIPLE_LOGS_SUCCESS';
export const DOWNLOAD_MULTIPLE_LOGS_FAILURE = 'DOWNLOAD_MULTIPLE_LOGS_FAILURE';
export const ADD_DOWNLOAD_LOGS_IN_QUEUE = 'ADD_DOWNLOAD_LOGS_IN_QUEUE';

export const GET_ENGINE_VIEW_LOGS_COUNT = 'GET_ENGINE_VIEW_LOGS_COUNT';
export const GET_ENGINE_VIEW_LOGS_COUNT_SUCCESS =
  'GET_ENGINE_VIEW_LOGS_COUNT_SUCCESS';
export const GET_ENGINE_VIEW_LOGS_COUNT_FAILURE =
  'GET_ENGINE_VIEW_LOGS_COUNT_FAILURE';

export const NULL_OR_BLANK = '(Blank/Null)';

export const GET_LOG_BY_HISTORYID_REQUEST = 'GET_LOG_BY_HISTORYID_REQUEST';
export const GET_LOG_BY_HISTORYID_SUCCESS = 'GET_LOG_BY_HISTORYID_SUCCESS';
export const GET_LOG_BY_HISTORYID_FAILURE = 'GET_LOG_BY_HISTORYID_FAILURE';

export const GET_TOTAL_INPUTS_BY_EXECUTIONHISTORYID_REQUEST =
  'GET_TOTAL_INPUTS_BY_EXECUTIONHISTORYID_REQUEST';
export const GET_TOTAL_INPUTS_BY_EXECUTIONHISTORYID_SUCCESS =
  'GET_TOTAL_INPUTS_BY_EXECUTIONHISTORYID_SUCCESS';
export const GET_TOTAL_INPUTS_BY_EXECUTIONHISTORYID_FAILURE =
  'GET_TOTAL_INPUTS_BY_EXECUTIONHISTORYID_FAILURE';
export const RESET_TOTAL_INPUTS = 'RESET_TOTAL_INPUTS';
export const CONTEXTMENU_DOWNLOAD = 'Download';
export const CONTEXTMENU_DOWNLOAD_BETA = 'DownloadBeta';
export const CONTEXTMENU_RUNINAPITESTER = 'RunInAPITester';
