import moment from 'moment';
import { IntlShape } from 'react-intl';
import { SystemConstants } from '../constants';

// Default time units for date diff defination
const DATE_DIFF_DEFINATION = Object.freeze({
  day: 86400000,
  hour: 3600000,
  minute: 60000,
  second: 1000,
  millisecond: 1,
});

const formatToLongDateTime = (inp: moment.MomentInput) => {
  return moment
    .utc(inp)
    .local()
    .format(SystemConstants.SHORT_DATE_TIME_FORMAT);
};

const formatToDateTime = (inp: moment.MomentInput, format?: string) => {
  return moment
    .utc(inp)
    .local()
    .format(format || SystemConstants.SHORT_DATE_TIME_FORMAT);
};

const formatToShortDateTime = (inp?: moment.MomentInput) => {
  return moment
    .utc(inp)
    .local()
    .format(SystemConstants.SHORT_DATE_FORMAT);
};

const getDateListBetweenTwoDate = (startDate?: string, endDate?: string) => {
  const dates: string[] = [];
  if (startDate !== undefined && endDate !== undefined) {
    for (
      let m = moment(startDate);
      m.isSameOrBefore(endDate);
      m.add(1, 'days')
    ) {
      dates.push(
        m
          .hour(0)
          .minutes(0)
          .seconds(0)
          .format(SystemConstants.BASE_DATE_TIME_FORMAT)
      );
    }
    return dates;
  }
  return dates;
};

const customFormatToDateTime = (
  inp?: moment.MomentInput,
  outputFormat?: string,
  format?: string
) => {
  return moment(inp, format)
    .utc()
    .format(outputFormat);
};

const customFormatToLocalDateTime = (
  inp: string,
  outputFormat?: string,
  format?: string
) => {
  return moment
    .utc(inp, format)
    .local()
    .format(outputFormat);
};

const convertMinuteToHhMm = (timeZoneOffset: number) => {
  if (!timeZoneOffset) {
    return '+00:00';
  }
  const duration = moment.duration(timeZoneOffset, 'minutes');
  const hh = ensureDoubleDigitNumber(duration.hours());
  const mm = ensureDoubleDigitNumber(duration.minutes());
  const sign = timeZoneOffset < 0 ? '-' : '+';
  return `${sign}${hh}:${mm}`;
};

const ensureDoubleDigitNumber = (duration: number) => {
  let result: string | number = Math.abs(duration);
  result = result < 10 ? `0${result}` : result;
  return result;
};

const timeFormatString = timeInMillisec => {
  let stringtime = '';
  if (typeof timeInMillisec !== 'number') {
    return stringtime;
  }
  const timeMoment = moment.duration(timeInMillisec);
  switch (true) {
    case timeInMillisec < DATE_DIFF_DEFINATION.second:
      stringtime = `${timeMoment.milliseconds()}ms`;
      break;
    case timeInMillisec < DATE_DIFF_DEFINATION.minute:
      stringtime = `${timeMoment.seconds()}sec`;
      break;
    case timeInMillisec < DATE_DIFF_DEFINATION.hour:
      stringtime = `${timeMoment.minutes()}min`;
      if (timeMoment.seconds() > 0) {
        stringtime += ` ${timeMoment.seconds()}sec`;
      }
      break;
    case timeInMillisec < DATE_DIFF_DEFINATION.day:
      stringtime = `${timeMoment.hours()}h`;
      if (timeMoment.minutes() > 0) {
        stringtime += ` ${timeMoment.minutes()}min`;
      }
      break;
    case timeInMillisec >= DATE_DIFF_DEFINATION.day:
      stringtime = `${timeMoment.days()}d`;
      if (timeMoment.minutes() > 0) {
        stringtime += ` ${timeMoment.hours()}h ${timeMoment.minutes()}min`;
      } else if (timeMoment.hours() > 0) {
        stringtime += ` ${timeMoment.hours()}h`;
      }
      break;
    default:
  }
  return stringtime;
};

const getTimeDiff = (tmpDiff: moment.Duration) => {
  let text = '';
  if (tmpDiff.hours() > 0) {
    text = `${tmpDiff.hours()}hour`;
  }
  if (tmpDiff.minutes() > 0) {
    text = `${text} ${tmpDiff.minutes()}min`;
  }
  if (tmpDiff.seconds() > 0) {
    text = `${text} ${tmpDiff.seconds()}sec`;
  }
  if (tmpDiff.seconds() === 0 && tmpDiff.milliseconds() > 0) {
    text = `${text} ${tmpDiff.milliseconds()}ms`;
  }
  return text;
};

const removeSecAndMsFromDate = (date: moment.Moment | undefined) => {
  if (date) {
    return date.second(0).millisecond(0);
  }
  return date;
};

export {
  formatToDateTime,
  formatToLongDateTime,
  formatToShortDateTime,
  customFormatToDateTime,
  getDateListBetweenTwoDate,
  timeFormatString,
  customFormatToLocalDateTime,
  convertMinuteToHhMm,
  getTimeDiff,
  removeSecAndMsFromDate,
};
