import crypto from 'crypto';

const base64URLEncode = (str: Buffer): string => {
  return str
    .toString('base64')
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=/g, '');
};

const sha256 = (buffer: string): Buffer => {
  return crypto
    .createHash('sha256')
    .update(buffer)
    .digest();
};

const getRandomString = (): string => {
  return crypto.randomBytes(10).toString('hex');
};

const getVerifier = (): string => {
  return base64URLEncode(crypto.randomBytes(50));
};

const getChallenge = (verifier: string): string => {
  return base64URLEncode(sha256(verifier));
};

export { getVerifier, getChallenge, getRandomString };
