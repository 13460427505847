import { getbaseUrl } from '../helpers';
import makeRequest from '../helpers/make-request';

const compareServicesVersion = (
  folderName: string,
  serviceName: string,
  request: DTO.CompareServiceRequest
) => {
  const url = `${getbaseUrl() || ''}folders/${encodeURIComponent(
    folderName
  )}/services/${encodeURIComponent(serviceName)}/compareservices`;
  return makeRequest<DTO.ResponseV3<{ id: string }>>('POST', url, request);
};

export const VersionOverviewService = {
  compareServicesVersion,
};
