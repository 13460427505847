import { getConfig } from '../helpers/config';
import makeRequest from '../helpers/make-request';

const getCounterDefinitions = () => {
  const url = `${
    getConfig().excelEngineDomain
  }/api/v1/useranalytics/counterdefinitions`;
  return makeRequest<{ data: DTO.CounterDefinition[] }>('GET', url);
};

const getTotalByCounterName = (request: DTO.InsightFilter) => {
  const url = `${
    getConfig().excelEngineDomain
  }/api/v1/useranalytics/getTotalByCounterName`;
  return makeRequest<{ data: DTO.InsightMetric[] }>('POST', url, request);
};

const getAggregatedData = (request: DTO.InsightFilter) => {
  const url = `${
    getConfig().excelEngineDomain
  }/api/v1/useranalytics/AggregateData`;
  return makeRequest<{ data: DTO.CounterAggregateData[] }>(
    'POST',
    url,
    request
  );
};

const getNextMeasures = (request: DTO.InsightNextMeasure) => {
  const url = `${
    getConfig().excelEngineDomain
  }/api/v1/useranalytics/GetNextMeasures`;
  return makeRequest<{ data: DTO.InsightNextMeasureResponse }>(
    'POST',
    url,
    request
  );
};

const getTopMetrics = (retrieveClusterCounters: boolean, tenant: string) => {
  let url = `${
    getConfig().excelEngineDomain
  }/api/v1/useranalytics/GetTopMetrics/${retrieveClusterCounters}`;
  if (tenant) {
    url += `/${tenant}`;
  }
  return makeRequest<{ data: DTO.InsightTopMetricsResponse }>('GET', url);
};

const incrementMenuCounter = (menuName: string) => {
  const url = `${
    getConfig().excelEngineDomain
  }/api/v1/useranalytics/increment/menu/${menuName}`;
  return makeRequest('POST', url);
};

const incrementLoginCounter = () => {
  const url = `${
    getConfig().excelEngineDomain
  }/api/v1/useranalytics/increment/login`;
  return makeRequest('POST', url);
};

const incrementPageCounter = (pageName: string) => {
  const config = getConfig();
  const tenant = localStorage.getItem('Tenant');
  const url = `${config.excelEngineDomain}/${tenant ||
    config.defaultTenant}/api/v3/analytics/increment/page/${pageName}`;
  return makeRequest<DTO.ResponseV3<boolean>>('POST', url);
};

const getTenants = () => {
  const config = getConfig();
  const tenant = localStorage.getItem('Tenant');
  const url = `${config.excelEngineDomain}/${tenant ||
    config.defaultTenant}/api/v3/analytics/gettenants`;
  return makeRequest<DTO.NodegenApiResponse<string[], DTO.ResponseMeta>>(
    'GET',
    url
  );
};

const incrementFeatureCounter = (
  data: DTO.NodegenApiRequestEntity<DTO.IncrementFolderServiceCounter>
) => {
  const config = getConfig();
  const tenant = localStorage.getItem('Tenant');
  const obj = {
    request_data: data,
  };
  const url = `${config.excelEngineDomain}/${tenant ||
    config.defaultTenant}/api/v3/analytics/increment/folderservice`;
  return makeRequest<DTO.ResponseV3<boolean>>('POST', url, obj);
};

const dashboardAggregateData = (
  data: DTO.RequestV3<DTO.DashboardAggregatedDataRequestPayload>
) => {
  const config = getConfig();
  const tenant = localStorage.getItem('Tenant');
  const url = `${config.excelEngineDomain}/${tenant ||
    config.defaultTenant}/api/v3/analytics/aggregate`;
  return makeRequest<DTO.ResponseV3<DTO.DashboardAggregatedData[]>>(
    'POST',
    url,
    data
  );
};

export const InsightsService = {
  getCounterDefinitions,
  getTotalByCounterName,
  getAggregatedData,
  getNextMeasures,
  getTopMetrics,
  incrementLoginCounter,
  incrementMenuCounter,
  incrementPageCounter,
  getTenants,
  incrementFeatureCounter,
  dashboardAggregateData,
};
