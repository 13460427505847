import { useMemo, useRef } from 'react';
import { useLocation } from 'react-router-dom';

/**
 * Return current params using on file manager
 * folderPath: current parent folder (folder contains left side folders)
 * selectedFolder: the folder contains folders & files on right side.
 * fileId: the selected file Id (use while editting file)
 */
const useFileManagerParams = () => {
  const location = useLocation();

  let fileId = '';
  let isEditingFolder = false;
  let folderPath = '';
  let selectedFolder = '';
  let isFile = false;
  let fileName = '';

  const remainingPath = location.pathname.replace('/admin/explorer/home', '');
  const splittedPaths = (remainingPath || '/').split('/');

  if (splittedPaths[splittedPaths.length - 1].startsWith('file:')) {
    fileId = splittedPaths[splittedPaths.length - 1].replace('file:', '');
    splittedPaths.splice(splittedPaths.length - 1, 1);
  }

  if (splittedPaths[splittedPaths.length - 1].startsWith('document:')) {
    isFile = true;
    fileId = splittedPaths[splittedPaths.length - 1].replace('document:', '');
    fileName = decodeURIComponent(splittedPaths[splittedPaths.length - 2]);
  }

  if (splittedPaths[splittedPaths.length - 1].startsWith('folder:edit')) {
    isEditingFolder = true;
    splittedPaths.splice(splittedPaths.length - 1, 1);
  }

  if (splittedPaths[1] !== '') {
    selectedFolder = `${splittedPaths.join('/')}`;
    folderPath = selectedFolder.substring(
      0,
      selectedFolder.lastIndexOf('/') + 1
    );
  } else {
    selectedFolder = '';
    folderPath = `${splittedPaths.join('/')}`;
  }

  const caches = useRef<{
    folderPath: string;
    fileId: string;
    selectedFolder: string;
    isEditingFolder: boolean;
    isFile: boolean;
    fileName: string;
  }>({
    folderPath,
    fileId,
    selectedFolder,
    isEditingFolder,
    isFile,
    fileName,
  });

  const fileManagerParams = useMemo<{
    folderPath: string;
    fileId: string;
    selectedFolder: string;
    isEditingFolder: boolean;
    isFile: boolean;
    fileName: string;
  }>(() => {
    caches.current = {
      folderPath,
      fileId,
      selectedFolder,
      isEditingFolder,
      isFile,
      fileName,
    };

    return {
      folderPath,
      fileId,
      selectedFolder,
      isEditingFolder,
      isFile,
      fileName,
    };
  }, [folderPath, fileId, selectedFolder, isEditingFolder, isFile, fileName]);

  return fileManagerParams;
};

export { useFileManagerParams };
