import { InsightsAction } from '../actions/types';
import { InsightsConstants } from '../constants';
import { getDateListBetweenTwoDate } from '../helpers';

export const initialState: STATES.InsightsState = {
  isLoading: false,
  isFoldersLoading: false,
  counterDefinitions: [],
  counters: [],
  aggregatedData: [],
  isLoadingMetrics: false,
  foldersMeasure: null,
  servicesMeasure: null,
  drillDownMeasure: null,
  topMetrics: null,
  tenantList: null,
};

export const insights = (
  state = initialState,
  action: InsightsAction
): STATES.InsightsState => {
  switch (action.type) {
    case InsightsConstants.GET_COUNTER_DEFINITIONS_REQUEST:
    case InsightsConstants.GET_NEXT_MEASURES_REQUEST:
    case InsightsConstants.GET_TOP_METRICS_REQUEST:
    case InsightsConstants.GET_TENANTS_REQUEST:
    case InsightsConstants.GET_AGGREGATED_DATA_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case InsightsConstants.GET_COUNTER_DEFINITIONS_SUCCESS: {
      const { list } = action.payload;

      return {
        ...state,
        isLoading: false,
        counterDefinitions: list,
      };
    }
    case InsightsConstants.GET_COUNTER_DEFINITIONS_FAILURE:
    case InsightsConstants.GET_NEXT_MEASURES_FAILURE:
    case InsightsConstants.GET_TOP_METRICS_FAILURE:
    case InsightsConstants.GET_TENANTS_FAILURE:
    case InsightsConstants.GET_AGGREGATED_DATA_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case InsightsConstants.GET_TOTAL_BY_COUNTER_REQUEST: {
      return {
        ...state,
        isLoadingMetrics: true,
        isLoading: true,
      };
    }
    case InsightsConstants.GET_TOTAL_BY_COUNTER_FAILURE: {
      return {
        ...state,
        isLoadingMetrics: false,
        isLoading: false,
      };
    }
    case InsightsConstants.GET_AGGREGATED_DATA_SUCCESS: {
      const { list, startDate, endDate } = action.payload;
      let start = startDate;
      let end = endDate;
      const tempDataList: DTO.CounterAggregateData[] = [];
      if (start === undefined || endDate === undefined) {
        start = list.length > 0 ? list[0].date : undefined;
        end = list.length > 0 ? list[list.length - 1].date : undefined;
      }

      const dates = getDateListBetweenTwoDate(start, end);
      dates.forEach(date => {
        const tempData = list.find(aggData => aggData.date === date);
        if (tempData) {
          tempDataList.push(tempData);
        } else {
          tempDataList.push({ date, count: 0 });
        }
      });

      return {
        ...state,
        isLoading: false,
        aggregatedData: tempDataList,
      };
    }
    case InsightsConstants.GET_TOTAL_BY_COUNTER_SUCCESS: {
      const { list } = action.payload;
      return {
        ...state,
        isLoading: false,
        counters: list ?? [],
        isLoadingMetrics: false,
      };
    }
    case InsightsConstants.GET_NEXT_MEASURES_SUCCESS: {
      const { list } = action.payload;
      return {
        ...state,
        isLoading: false,
        drillDownMeasure: list,
      };
    }
    case InsightsConstants.GET_TENANTS_SUCCESS: {
      const { tenantList } = action.payload;
      return {
        ...state,
        isLoading: false,
        tenantList,
      };
    }
    case InsightsConstants.GET_INSIGHT_FOLDER_ENGINE_REQUEST: {
      return {
        ...state,
        isFoldersLoading: true,
      };
    }
    case InsightsConstants.GET_INSIGHT_FOLDER_ENGINE_SUCCESS: {
      const { list, type } = action.payload;
      const newState = { ...state, isFoldersLoading: false };
      if (type === 'product') {
        newState['foldersMeasure'] = { counterNames: list };
      }
      if (type === 'engine') {
        newState['servicesMeasure'] = { counterNames: list };
      }
      return newState;
    }
    case InsightsConstants.GET_INSIGHT_FOLDER_ENGINE_FAILURE: {
      return {
        ...state,
        isFoldersLoading: false,
      };
    }
    case InsightsConstants.GET_TOP_METRICS_SUCCESS: {
      const { topMetrics } = action.payload;

      return {
        ...state,
        isLoading: false,
        topMetrics,
      };
    }
    default:
      return state;
  }
};
